import './AccountPositionsOverview.scss';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getAssets } from '../../redux/actions/transactions';
import { queryPositions } from '../../redux/actions/websocket';
import { fetchStakingRewardsSummaryRequest } from '../../redux/actions/transactionsExternal';
import AccountPositionsOverview from './AccountPositionsOverview';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  assets: state.transactions.assets,
  cryptoRates: state.transactions.cryptoRates,
  rewards: state.externalTransactions.stakingRewardsSummary,
  userData: state.login.profileDetails.data
});

const mapDispatchToProps = {
  getAssets,
  fetchStakingRewardsSummaryRequest,
  queryPositions
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountPositionsOverview);
