import React, { PureComponent } from 'react';
import LoadingIndicator from '../../components/LoadingIndicator';
import i18next from 'i18next';
import { toast } from 'react-toastify';

class DeviceLocationGuard extends PureComponent {
  componentDidMount() {
    this.handleParseUrl();
  }

  handleParseUrl = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams) {
      const token = urlParams.get('token');

      this.props.validateDeviceLocationRequest({
        token: token,
        successCallback: () => {
          this.props.history.push('/login');
          toast.success(i18next.t('LOGIN.deviceLocationValidated'));
        },
        errorCallback: error => {
          this.props.history.push('/login');
          toast.error(error.reason);
        }
      });
    }
  };

  render() {
    return (
      <>
        <LoadingIndicator />
      </>
    );
  }
}

export default DeviceLocationGuard;
