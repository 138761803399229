import {
  getFirmAdminAccountsRequest,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  registerWalletForAccount
} from '../../redux/actions/administration';
import { updateRiskSettings } from '../../redux/actions/websocket';
import { getTradingAssets } from '../../redux/actions/transactions';
import { checkAccountName } from '../../redux/actions/settings';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Accounts from './Accounts';
import { getAccountDetailsRequest } from '../../redux/actions/administration';

const mapStateToProps = state => ({
  accounts: state.administration.firmAdminAccounts,
  websocket: state.websocket.websocket,
  socketConnected: state.websocket.socketConnected,
  user: state.login.userData.data,
  canEditAccounts: state.login.userPermissions.data.permissions?.canEditAccounts,
  accountDetails: state.administration.accountDetails
});

const mapDispatchToProps = {
  getFirmAdminAccountsRequest,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  registerWalletForAccount,
  checkAccountName,
  updateRiskSettings,
  getTradingAssets,
  getAccountDetailsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Accounts));
