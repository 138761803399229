import './SendAndRequest.scss';

import {
  getTradingAssets,
  getDepositAccounts,
  getRecipientFirms,
  resetFields,
  searchRecipientAccounts,
  toggleModal,
  updateTransactionsDB,
  getAccountTransactions
} from '../../redux/actions/transactions';
import { makeOrderRequest, makeTransferRequest } from '../../redux/actions/websocket';

import SendAndRequest from './SendAndRequest';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  assets: state.transactions.tradingAssets,
  firms: state.transactions.recipientFirms,
  firmAccounts: state.transactions.firmAccounts,
  websocket: state.websocket.websocket,
  positions: state.websocket.positions,
  updatingTransactionDb: state.transactions.updatingTransactionDb,
  contacts: state.administration.contacts.data,
  cryptoRates: state.transactions.cryptoRates,
  profileDetails: state.login.profileDetails,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getDepositAccounts,
  getTradingAssets,
  getRecipientFirms,
  searchRecipientAccounts,
  resetFields,
  makeTransferRequest,
  makeOrderRequest,
  toggleModal,
  updateTransactionsDB,
  getAccountTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(SendAndRequest);
