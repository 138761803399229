import { Escrow, SendAndRequest } from '..';
import { MAX_ROWS_IN_TABLE, MODAL_CODES, MODAL_STEPS, PAYMENTS_TABS } from '../../enums/validation';
import React, { PureComponent } from 'react';
import { checkHasAuths } from '../../utils/roleCheckers';

import PaymentsTransactionsContainer from '../../components/PaymentsTransactionsContainer';
import TransactionCancelScreen from '../../components/TransactionCancelScreen';
import TransactionDetailsModal from '../../components/modals/TransactionDetailsModal';
import TransactionSuccessScreen from '../../components/TransactionSuccessScreen';

class Payments extends PureComponent {
  state = {
    selectedTab: PAYMENTS_TABS.ALL,
    transaction: null,
    transactionSuccess: null
  };

  componentDidMount() {
    this.props.getAssetPairs();
  }

  toggleTransactionDetails = (transaction = null) => {
    if (!transaction) this.onCloseModal();
    this.setState(
      {
        transaction: transaction
      },
      () => {
        this.props.toggleModal(transaction ? MODAL_CODES.DETAILS : null);
        this.props.resetTransactionHash();
      }
    );
  };

  toggleOnConfirmationRequest = (transaction = null) => {
    if (!transaction) this.onCloseModal();
    this.setState({ transaction });
  };

  onCloseModal = () => this.props.toggleModal(null);

  render() {
    const { displayModal, user } = this.props;
    return (
      <React.Fragment>
        <div>
          {checkHasAuths(user) && (
            <PaymentsTransactionsContainer
              user={this.props.user}
              maxRowNumber={MAX_ROWS_IN_TABLE}
              showTabs={true}
              selectedTab={this.state.selectedTab}
              tabChange={this.tabChange}
              toggleTransactionDetails={this.toggleTransactionDetails}
              toggleOnConfirmationRequest={this.toggleOnConfirmationRequest}
              transaction={this.state.transaction || {}}
            />
          )}
          {(displayModal === MODAL_CODES.SEND || displayModal === MODAL_CODES.REQUEST) && (
            <SendAndRequest type={displayModal} history={this.props.history} />
          )}
          {displayModal === MODAL_CODES.ESCROW && <Escrow type={displayModal} history={this.props.history} />}
          {displayModal === MODAL_CODES.DETAILS && (
            <TransactionDetailsModal
              accountList={this.state.listOfAccounts}
              user={user}
              transaction={this.state.transaction || {}}
              onCloseModal={() => {
                this.toggleTransactionDetails(null);
              }}
            />
          )}
          {displayModal === MODAL_STEPS.ACCEPT__SUCCESS && (
            <TransactionSuccessScreen
              {...this.state.transaction.data}
              type={this.state.transaction.type}
              requestID={this.state.transaction.requestID}
              history={this.props.history}
              isAccepting
              specialRedirectFunction={this.onCloseModal}
              goToText={'Payments'}
            />
          )}
          {displayModal === MODAL_STEPS.CANCEL__SUCCESS && (
            <TransactionCancelScreen
              {...this.state.transaction.data}
              type={this.state.transaction.type}
              requestID={this.state.transaction.requestID}
              history={this.props.history}
              specialRedirectFunction={this.onCloseModal}
              goToText={'Payments'}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Payments;
