import * as CONSTANTS from '../constants/administration';

const initialState = {
  listOfFirms: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  firmDetails: {
    data: null,
    loaded: false,
    loading: false,
    error: ''
  },
  userDetails: {
    data: null,
    loaded: false,
    loading: false,
    error: ''
  },
  firmAdminAccounts: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  firmUsers: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  userSuggestions: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  accountSuggestions: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  userAccount: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  contacts: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  contactsRequests: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  createContactResponse: {},
  deleteContactResponse: {},
  acceptContactResponse: {},
  declineContactResponse: {},
  cancelContactResponse: {},
  onboardingUsers: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  pendingOnboardingUsers: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  accountDetails: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  createFirm: {
    message: '',
    reason: '',
    error: ''
  },
  currentAccountNumber: {
    data: ''
  },
  currentAccountsFirm: {
    data: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.GET_LIST_OF_FIRMS_REQUEST:
      return {
        ...state,
        listOfFirms: {
          ...state.listOfFirms,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_LIST_OF_FIRMS_SUCCESS:
      return {
        ...state,
        listOfFirms: {
          ...state.listOfFirms,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_LIST_OF_FIRMS_ERROR:
      return {
        ...state,
        listOfFirms: {
          ...state.listOfFirms,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_FIRM_DETAILS_REQUEST:
      return {
        ...state,
        firmDetails: {
          ...state.firmDetails,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_DETAILS_SUCCESS:
      return {
        ...state,
        firmDetails: {
          ...state.firmDetails,
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_DETAILS_ERROR:
      return {
        ...state,
        firmDetails: {
          ...state.firmDetails,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    case CONSTANTS.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        createContact: action.data
      };

    case CONSTANTS.CREATE_CONTACT_ERROR:
      return {
        ...state,
        createContact: action.data
      };

    ///////////////////////////////////////////////////////////////////////////

    case CONSTANTS.ACCEPT_CONTACT_SUCCESS:
      return {
        ...state,
        acceptContactResponse: action.data,
        contacts: { ...state.contacts, loaded: false }
      };

    case CONSTANTS.ACCEPT_CONTACT_ERROR:
      return {
        ...state,
        acceptContactResponse: action.data
      };

    case CONSTANTS.RESET_CREATE_CONTACT_RESPONSE:
      return { ...state, createContactResponse: {} };
    case CONSTANTS.RESET_DELETE_CONTACT_RESPONSE:
      return { ...state, deleteContactResponse: {} };
    case CONSTANTS.RESET_ACCEPT_CONTACT_RESPONSE:
      return { ...state, acceptContactResponse: {} };
    case CONSTANTS.RESET_DECLINE_CONTACT_RESPONSE:
      return { ...state, declineContactResponse: {} };
    case CONSTANTS.RESET_CANCEL_CONTACT_RESPONSE:
      return { ...state, cancelContactResponse: {} };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    case CONSTANTS.DELETE_CONTACT_ERROR:
      return {
        ...state,
        deleteContactResponse: action.data
      };

    case CONSTANTS.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        deleteContactResponse: action.data,
        contacts: { ...state.contacts, loaded: false }
      };

    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.DECLINE_CONTACT_ERROR:
      return {
        ...state,
        declineContactResponse: action.data
      };

    case CONSTANTS.DECLINE_CONTACT_SUCCESS:
      return {
        ...state,
        declineContactResponse: action.data,
        contacts: { ...state.contacts, loaded: false }
      };
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.CANCEL_CONTACT_ERROR:
      return {
        ...state,
        cancelContactResponse: action.data
      };

    case CONSTANTS.CANCEL_CONTACT_SUCCESS:
      return {
        ...state,
        cancelContactResponse: action.data,
        contacts: { ...state.contacts, loaded: false }
      };
    //////////////////////////////////////////////////////////////////////////////
    case CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_REQUEST:
      return {
        ...state,
        firmAdminAccounts: {
          data: {},
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_SUCCESS:
      return {
        ...state,
        firmAdminAccounts: {
          ...state.firmAdminAccounts,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_ERROR:
      return {
        ...state,
        firmAdminAccounts: {
          ...state.firmAdminAccounts,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_FIRM_USERS_REQUEST:
      return {
        ...state,
        firmUsers: {
          data: [],
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_USERS_SUCCESS:
      return {
        ...state,
        firmUsers: {
          ...state.firmUsers,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_USERS_ERROR:
      return {
        ...state,
        firmUsers: {
          ...state.firmUsers,
          loading: false,
          loaded: false,
          error: action.error,
          data: []
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_USER_SUGGESTIONS_REQUEST:
      return {
        ...state,
        userSuggestions: {
          data: [],
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        userSuggestions: {
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_SUGGESTIONS_ERROR:
      return {
        ...state,
        userSuggestions: {
          loading: false,
          loaded: false,
          error: action.error,
          data: []
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_ACCOUNT_SUGGESTIONS_REQUEST:
      return {
        ...state,
        accountSuggestions: {
          data: [],
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        accountSuggestions: {
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_SUGGESTIONS_ERROR:
      return {
        ...state,
        accountSuggestions: {
          loading: false,
          loaded: false,
          error: action.error,
          data: []
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.CLEAR_ALL_FIRM_INFO:
      return {
        ...state,
        firmDetails: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        },
        firmAdminAccounts: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        },
        firmUsers: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        }
      };

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_CONTACTS_REQUEST:
      return {
        ...state,
        contacts: {
          ...state.contacts,

          loading: true
        }
      };

    case CONSTANTS.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_CONTACTS_ERROR:
      return {
        ...state,
        contacts: {
          ...state.firmUsers,
          loading: false,
          loaded: false,
          error: action.data,
          data: []
        }
      };
    //////////////////////////////////////////////////////////////////////////////
    case CONSTANTS.GET_CONTACTS_REQUESTS_REQUEST:
      return {
        ...state,
        contactsRequests: {
          ...state.contactsRequests,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_CONTACTS_REQUESTS_SUCCESS:
      return {
        ...state,
        contactsRequests: {
          ...state.contactsRequests,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_CONTACTS_REQUESTS_ERROR:
      return {
        ...state,
        contactsRequests: {
          ...state.contactsRequests,
          loading: false,
          loaded: false,
          error: action.data,
          data: []
        }
      };
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.GET_ONBOARDING_USERS_REQUEST:
      return {
        ...state,
        onboardingUsers: {
          ...state.onboardingUsers,
          loading: true,
          loaded: false,
          error: ''
        }
      };
    case CONSTANTS.GET_PENDING_ONBOARDING_USERS_REQUEST:
      return {
        ...state,
        pendingOnboardingUsers: {
          ...state.pendingOnboardingUsers,
          loading: true,
          loaded: false,
          error: ''
        }
      };
    case CONSTANTS.GET_PENDING_ONBOARDING_USERS_SUCCESS:
      return {
        ...state,
        pendingOnboardingUsers: {
          ...state.pendingOnboardingUsers,
          loading: false,
          loaded: true,
          data: action.data?.data,
          error: ''
        }
      };

    case CONSTANTS.GET_ONBOARDING_USERS_SUCCESS:
      return {
        ...state,
        onboardingUsers: {
          ...state.onboardingUsers,
          loading: false,
          loaded: true,
          data: action.data?.data,
          error: ''
        }
      };

    case CONSTANTS.GET_PENDING_ONBOARDING_USERS_ERROR: {
      return {
        pendingOnboardingUsers: {
          ...state.pendingOnboardingUsers,
          loading: false,
          loaded: false,
          error: action.error
        }
      };
    }
    case CONSTANTS.GET_ONBOARDING_USERS_ERROR:
      return {
        ...state,
        onboardingUsers: {
          ...state.onboardingUsers,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    case CONSTANTS.DELETE_BTA_REPORT_SUCCESS: {
      const newUsers = state.pendingOnboardingUsers.data.map(user => {
        return user.email === action.data.userId ? { ...user, reportId: undefined } : user;
      });

      return {
        ...state,
        pendingOnboardingUsers: {
          ...state.pendingOnboardingUsers,

          data: newUsers
        }
      };
    }

    case CONSTANTS.UPLOAD_BTA_REPORT_SUCCESS: {
      const newUsers = state.pendingOnboardingUsers.data.map(user => {
        return user.email === action.data.data.userId ? { ...user, reportId: action.data.data.reportId } : user;
      });

      return {
        ...state,
        pendingOnboardingUsers: {
          ...state.pendingOnboardingUsers,

          data: newUsers
        }
      };
    }

    case CONSTANTS.GET_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: false,
          loaded: true,
          data: action.data,
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: false,
          loaded: false,
          error: action.error,
          data: {}
        }
      };

    case CONSTANTS.CREATE_FIRM_ERROR:
      return {
        ...state,
        createFirm: {
          error: action.error
        }
      };
    case CONSTANTS.SET_CURRENT_ACCOUNT_NUMBER:
      return {
        ...state,
        currentAccountNumber: {
          data: action.data
        }
      };
    case CONSTANTS.SET_CURRENT_ACCOUNTS_FIRM:
      return {
        ...state,
        currentAccountsFirm: {
          data: action.data
        }
      };

    default:
      return state;
  }
};

export default reducer;
