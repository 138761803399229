import './GraphDashboardContainer.scss';

import GraphDashboardContainer from './GraphDashboardContainer';
import { connect } from 'react-redux';
import { toggleModal } from '../../redux/actions/transactions';
import { getBalance } from '../../redux/actions/balance';

const mapStateToProps = state => ({
  balance: state.balance.balance,
  mode: state.settings.mode,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  toggleModal,
  getBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphDashboardContainer);
