import * as TRANSACTIONS_CONSTANTS from './transactionsExternal';

const URLS = {
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.FETCH_STAKING_REQUEST]: '/api/v1/stakes',
  [TRANSACTIONS_CONSTANTS.ADD_STAKING_REQUEST]: '/api/v1/stakes',
  [TRANSACTIONS_CONSTANTS.EXIT_STAKING_REQUEST]: '/api/v1/stakes/exit',
  [TRANSACTIONS_CONSTANTS.FETCH_STAKING_REWARDS_REQUEST]: '/api/v1/stakes/rewards',
  [TRANSACTIONS_CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_REQUEST]: '/api/v1/stakes/rewards',
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.FETCH_INTERNAL_WALLETS_REQUEST]: '/api/v1/wallets',
  [TRANSACTIONS_CONSTANTS.ADD_INTERNAL_WALLETS_REQUEST]: '/api/v1/wallets',
  [TRANSACTIONS_CONSTANTS.FETCH_SUPPORTED_ASSETS_REQUEST]: '/api/v1/wallets/supported-currencies',
  [TRANSACTIONS_CONSTANTS.CREATE_EXTERNAL_TRANSACTION_REQUEST]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE]: '/api/v1/transactions',
  [TRANSACTIONS_CONSTANTS.EXPORT_TRANSACTIONS_CRYPTO]: '/api/v1/reports/transactions',
  [TRANSACTIONS_CONSTANTS.EXPORT_TRANSACTIONS_STAKING]: '/api/v1/reports/stakes',
  [TRANSACTIONS_CONSTANTS.UPLOAD_CRYPTO_REPORT]: '/api/v1/reports/transactions',
  [TRANSACTIONS_CONSTANTS.DELETE_CRYPTO_REPORT]: '/api/v1/reports/transactions/reports',
  [TRANSACTIONS_CONSTANTS.DOWNLOAD_CRYPTO_REPORT]: '/api/v1/reports/transactions/download',
  [TRANSACTIONS_CONSTANTS.GET_CRYPTO_REPORT_LIST]: '/api/v1/reports/transactions',
  [TRANSACTIONS_CONSTANTS.TRANSACTIONS_FEE_REQUEST]: '/api/v1/transactions/fee'
};

export default URLS;
