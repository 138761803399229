import '../OpenOrdersTable/OpenOrdersTable.scss';

import IOIsTable from './IOIsTable';
import { connect } from 'react-redux';
import { rejectPaymentRequest, rejectPaymentRequestSuccess } from '../../../redux/actions/websocket';
import './IOIsTable.scss';
const mapStateToProps = state => ({
  openOrders: state.websocket?.openOrders,
  ioiInbound: state.websocket?.ioiInbound,
  socket: state.websocket.websocket
});

const mapDispatchToProps = {
  rejectPaymentRequest,
  rejectPaymentRequestSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(IOIsTable);
