const SvgIcon = ({ className, path, alt }) => {
  const loadImage = path => {
    let imageSrc = '';
    try {
      imageSrc = require(`../../assets/svg/${path}`);
    } catch (error) {
      imageSrc = require(`../../assets/png/svg-fallback/svg-fallback.png`);
    }

    return imageSrc;
  };

  return <img className={className} src={loadImage(path)} alt={alt} />;
};

export default SvgIcon;
