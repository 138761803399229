import VLButton from '../../Buttons/VLButton';
import './FailedTransaction.scss';

const FailedTransaction = ({ onBack }) => {
  const failedTransactionMessage = 'Unable to create transaction.';

  return (
    <div className="failed-transaction-container">
      <h3>{failedTransactionMessage}</h3>
      <VLButton size="l" onClick={() => onBack()} text="Back to Buy/Sell" />
    </div>
  );
};

export default FailedTransaction;
