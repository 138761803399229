import NegotiateOrder from './NegotiateOrder';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { makeIOIRequest } from '../../../redux/actions/websocket';
import { setSelectedLmt, setSelectedIoi } from '../../../redux/actions/trade';
const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  userData: state.login.profileDetails.data,
  ioiBrokers: state.trade.ioiAccountNumber.data
});

// TODO: makeIOIRequest should be renamed when IOI is removed from dashboard as it is in fact negotiation request
const mapDispatchToProps = {
  makeNegotiationRequest: makeIOIRequest,
  setSelectedIoi,
  setSelectedLmt
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(NegotiateOrder);
