import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SECURITY_CODES, SECURITY_CODE_NAME_MAP } from '../../enums/validation';
import BuySell from '../../components/BuySell';
import AdvancedTrading from '../../components/AdvancedTrading';
import CryptoAssetChart from '../../components/Charts/CryptoAssetChart';
import AdvancedChartRange from '../../components/Charts/AdvancedChart/AdvancedChartRange';
import i18next from 'i18next';
import './CryptoAssetDetails.scss';
import { shouldAllowTransactions } from '../../utils/roleCheckers';
import { getSelectedAccountId } from '../../contexts/AccountFilterContext';

const CryptoAssetDetails = ({
  chartData,
  fetchChartData,
  marketData24,
  fetch24hData,
  userData,
  accounts,
  currentAccountNumber
}) => {
  const [time, setTime] = useState(AdvancedChartRange.HOUR);

  const params = useParams();
  const asset = params.asset.toUpperCase();
  const security = asset + SECURITY_CODES.USD;

  const selectedAccountId = getSelectedAccountId(currentAccountNumber);
  const selectedAccount = accounts.data?.find(a => a.account === selectedAccountId);

  const canTransact = useMemo(() => {
    const user = userData.data;
    if (!selectedAccount) return false;
    if (!user) return false;

    return shouldAllowTransactions(user, selectedAccount);
  }, [selectedAccount, userData]);

  useEffect(() => {
    fetchChartData({ security, time: time.value });
  }, [asset, fetchChartData, security, time]);

  useEffect(() => {
    fetch24hData({ security });
  }, [fetch24hData, security]);

  useEffect(() => {
    let interval;

    if (!marketData24 || Object.keys(marketData24?.data).length === 0) {
      interval = setInterval(() => {
        fetch24hData({ security });
      }, 30000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [fetch24hData, marketData24, security]);

  return (
    <div className="crypto-asset-details-container">
      <div className="crypto-content">
        <div className="crypto-asset">
          <img
            src={require(`../../assets/svg/crypto/${params.asset}.svg`)}
            alt={SECURITY_CODE_NAME_MAP[asset]}
            width="40"
            height="40"
          />
          <span className="crypto-asset-name">
            {SECURITY_CODE_NAME_MAP[asset]} ({SECURITY_CODES[asset]})
          </span>
        </div>
      </div>
      <div className="graph-content">
        <div className="graph">
          <CryptoAssetChart
            loading={chartData.loading}
            data={chartData.data}
            cryptoAsset={params.asset}
            onTimeSelect={setTime}
            marketData24={marketData24}
          />
        </div>
      </div>
      <div className="buy-sell-content">
        <div className="buy-sell">
          <div className="buy-sell-title">
            <span>Buy/Sell</span>
          </div>
          <div>
            <BuySell fixedSendAsset={SECURITY_CODES.USD} fixedReceiveAsset={asset} isDisabled={!canTransact} />
          </div>
        </div>
      </div>
      <div className="overview-content">
        <div>
          <h4 className="overview-title">Overview</h4>
          <p className="description">{i18next.t('crypto-asset-description.' + params.asset)}</p>
        </div>
      </div>
      <div className="advanced-trading-content">
        <AdvancedTrading firstAsset={asset} secondAsset={SECURITY_CODES.USD} />
      </div>
    </div>
  );
};

export default CryptoAssetDetails;
