/* eslint-disable import/no-anonymous-default-export */
import * as CONSTANTS from '../constants/websocket';
import * as LOGIN_CONSTANTS from '../constants/login';
import { SECURITY_CODES } from '../../enums/validation';

const initialState = {
  requests: [],
  escrows: [],
  ioiInbound: [],
  ioiOutbound: [],
  openOrders: [],
  scheduled: [],
  positions: {
    //accountId: {
    //   asset1: curpos (int value)
    //   }
  },
  canceledOrders: [],
  timestamp: new Date().getMilliseconds(),
  allBooks: {},
  orders: {
    //accountId: {
    // data
    // }
  },
  connectionError: false,
  websocket: null,
  socketConnected: false,
  riskControlSettings: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  }
};

const isRequest = transaction => {
  return !!SECURITY_CODES[transaction.security];
};

const isEscrow = transaction => {
  return !SECURITY_CODES[transaction.security] && transaction.nonnegotiable;
};

const IsOutboundIoi = transaction => {
  return (
    !SECURITY_CODES[transaction.security] &&
    !transaction.nonnegotiable &&
    !transaction.inbound &&
    transaction.ordertype !== 'MKT'
  );
};

const IsInboundIoi = transaction => {
  return !SECURITY_CODES[transaction.security] && !transaction.nonnegotiable && transaction.inbound;
};
const IsLimitOrder = transaction => {
  return (
    !transaction.nonnegotiable &&
    !transaction.negotiate &&
    transaction.orderstatus !== 'Closed' &&
    transaction.orderstatus !== 'Executed' &&
    !SECURITY_CODES[transaction.security]
  );
};

//TODO: add scheduled_transfers to a separate list

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.QUERY_POSITION_SUCCESS: {
      const positionsMsgArrays = action.data.data;
      const accountId = positionsMsgArrays[0].account;
      let tempObject = {};
      positionsMsgArrays.forEach(pos => {
        tempObject[pos.security] = pos.curpos;
      });
      return {
        ...state,
        positions: {
          ...state.positions,
          [accountId]: {
            ...(state.positions[accountId] || {}),
            ...tempObject
          }
        }
      };
    }
    case CONSTANTS.POSITION_MSG: {
      const accountId = action.data.account;
      return {
        ...state,
        positions: {
          ...state.positions,
          [accountId]: {
            ...(state.positions[accountId] || {}),
            [action.data.asset]: action.data.amount
          }
        }
      };
    }
    case CONSTANTS.ORDER_UPDATE_MSG: {
      const orderRef = action.data.refno;
      const liveQuantity = Number(action.data.liveqty);
      let requests = state.requests.slice();
      let escrows = state.escrows.slice();
      let ioiInbound = state.ioiInbound.slice();
      let ioiOutbound = state.ioiOutbound.slice();
      let openOrders = state.openOrders.slice();
      // const isRequest = action.data.price === '0';

      if (isRequest(action.data)) {
        const exists = requests.findIndex(request => request.refno === orderRef);
        if (exists === -1 && liveQuantity > 0) {
          requests.push(action.data);
        }

        return {
          ...state,
          requests: [...requests]
        };
      }

      if (isEscrow(action.data)) {
        const exists = escrows.findIndex(escrow => escrow.refno === orderRef);
        if (exists === -1 && liveQuantity > 0) {
          escrows.push(action.data);
        }

        return {
          ...state,
          escrows: [...escrows]
        };
      }

      if (IsInboundIoi(action.data)) {
        const exists = ioiInbound.findIndex(ioi => ioi.refno === orderRef);
        if (exists === -1 && liveQuantity > 0) {
          action.data.type = 'QUOTE';
          ioiInbound.push(action.data);
        }

        return {
          ...state,
          ioiInbound: [...ioiInbound]
        };
      }

      if (IsLimitOrder(action.data)) {
        const exists = openOrders.findIndex(lmt => lmt.refno === orderRef);
        let timeStampUpdate = null;
        if (exists === -1 && liveQuantity > 0) {
          action.data.type = action.data.ioi ? 'IOI' : 'Limit';
          openOrders.push(action.data);
        } else if (exists !== -1 && liveQuantity > 0) {
          openOrders[exists].liveqty = action.data.liveqty;
          timeStampUpdate = new Date().getMilliseconds();
        }

        return {
          ...state,
          openOrders: [...openOrders],
          timestamp: timeStampUpdate ? timeStampUpdate : state.timestamp
        };
      }

      if (IsOutboundIoi(action.data)) {
        const exists = ioiOutbound.findIndex(ioi => ioi.refno === orderRef);
        if (exists === -1 && liveQuantity > 0) {
          ioiOutbound.push(action.data);
        }
        const existsNegotiated = openOrders.findIndex(ioi => ioi.refno === orderRef);
        if (existsNegotiated === -1 && liveQuantity > 0) {
          action.data.type = 'Negotiate';
          openOrders.push(action.data);
        }

        return {
          ...state,
          ioiOutbound: [...ioiOutbound],
          openOrders: [...openOrders]
        };
      }
      console.log('Ignoring transaction: ', action.data);

      return {
        ...state
      };
    }
    case CONSTANTS.BOOK_UPDATE_MSG: {
      const { security, books } = action.data;
      let existingBooks = state.allBooks[security] ?? [];

      books.forEach(book => {
        const index = existingBooks?.findIndex(existing => existing.key === book.key);
        // If the order book entry with the given key is not found,
        // add it to the list.
        // If the order book entry with the given key is found,
        // update the quantity in the case where quantity is not zero because
        // if the quantity is zero the order has been filled and should be removed.
        if (index === -1) {
          existingBooks.push(book);
        } else {
          if (book.qty > 0) {
            existingBooks[index].qty = book.qty;
          } else {
            existingBooks.splice(index, 1);
          }
        }
      });

      return {
        ...state,
        allBooks: {
          ...state.allBooks,
          [security]: existingBooks
        }
      };
    }

    case CONSTANTS.SCHEDULED_SEND_UPDATE_MSG: {
      const orderRef = action.data.refno;
      let scheduledMessages = state.scheduled.slice();
      const exists = scheduledMessages.findIndex(transfer => transfer.refno === orderRef);
      if (exists === -1) {
        scheduledMessages.push(action.data);
      }

      return {
        ...state,
        scheduled: [...scheduledMessages]
      };
    }

    case CONSTANTS.REJECT_REQUEST_SUCCESS: {
      const order = action.data;
      const canceledOrders = state.canceledOrders.slice();
      if (IsInboundIoi(action.data) || IsOutboundIoi(action.data) || IsLimitOrder(action.data)) {
        const exists = canceledOrders.findIndex(canceledOrder => canceledOrder.refno === action.data.refno);
        if (exists === -1) {
          canceledOrders.push(action.data);
        }
      }
      return {
        ...state,
        requests: state.requests.filter(t => t.refno !== order.refno),
        escrows: state.escrows.filter(t => t.refno !== order.refno),
        scheduled: state.scheduled.filter(t => t.refno !== order.refno),
        ioiInbound: state.ioiInbound.filter(ioi => ioi.refno !== order.refno),
        ioiOutbound: state.ioiOutbound.filter(ioi => ioi.refno !== order.refno),
        openOrders: state.openOrders.filter(lmt => lmt.refno !== order.refno),
        canceledOrders: [...canceledOrders],
        timestamp: new Date().getMilliseconds()
      };
    }

    case CONSTANTS.ACCEPT_REQUEST_SUCCESS: {
      const order = action.data;

      return {
        ...state,
        requests: state.requests.filter(t => t.refno !== order.refno),
        escrows: state.escrows.filter(t => t.refno !== order.refno),
        scheduled: state.scheduled.filter(t => t.refno !== order.refno),
        ioiInbound: state.ioiInbound.filter(ioi => ioi.refno !== order.refno),
        ioiOutbound: state.ioiOutbound.filter(ioi => ioi.refno !== order.refno),
        openOrders: state.openOrders.filter(lmt => lmt.refno !== order.refno),

        timestamp: new Date().getMilliseconds()
      };
    }

    case CONSTANTS.SET_WEBSOCKET_ERROR: {
      return {
        ...state,
        connectionError: true
      };
    }

    case CONSTANTS.REMOVE_WEBSOCKET_ERROR: {
      return {
        ...state,
        connectionError: false
      };
    }

    case LOGIN_CONSTANTS.LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    case LOGIN_CONSTANTS.LOGIN_SUCCESS: {
      return { ...initialState };
    }

    case CONSTANTS.SET_WEBSOCKET: {
      return {
        ...state,
        websocket: action.data
      };
    }

    case CONSTANTS.SOCKET_CONNECTED: {
      return {
        ...state,
        socketConnected: true
      };
    }
    case CONSTANTS.QUERY_RISK_SETTINGS:
      return {
        ...state,
        riskControlSettings: {
          data: {},
          loading: true,
          loaded: false
        }
      };

    case CONSTANTS.QUERY_RISK_SETTINGS_SUCCESS:
      return {
        ...state,
        riskControlSettings: {
          data: action.data,
          loading: false,
          loaded: true
        }
      };

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    default:
      return state;
  }
};
