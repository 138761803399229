import { Modal, Row } from 'react-bootstrap';
import { ERROR, FRACTIONS, PENDING, SUCCESS, WITHDRAW_TYPE } from '../../enums/validation';
import { MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { getLocalisedNumericString, replaceCommas } from '../../utils/generalUtils';
import { getDecimalByAsset, getDecimalByFraction, isUSDAsset } from '../../utils/transactionsHelpers';
import { onlyNumbers } from '../../utils/validators';
import { CurrencyStep, TransactionHashStep, WithdrawAddressStep } from '../../components/externalTransactionComponents';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import RoundButton from '../../components/RoundButton';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import AmountStep from '../../components/externalTransactionComponents/AmountStep';
import PreviewStep from '../../components/externalTransactionComponents/PreviewStep';

const initialState = {
  asset: '',
  maxDecimal: 0,
  amountObject: {
    value: '',
    display: '',
    error: false
  },
  fee: null,
  currentScreen: 1,
  transactionType: WITHDRAW_TYPE,
  walletAcc: '',
  positions: null,
  queryWalletInProgress: false,
  isSending: false,
  transactionStatus: null,
  checkTimeout: null,
  transactionId: null,
  balanceError: null,
  address: ''
};

class ExternalWithdraw extends PureComponent {
  static contextType = AccountFilterContext;

  state = initialState;

  componentDidMount() {
    this.props.getDepositAccounts();
    this.props.getAssetsByProduct();
    this.props.queryBalanceWalletPos({
      account: this.props.user.account,
      firm: this.props.user.firm,
      socket: this.props.websocket,
      successCallback: data => {
        this.setState({
          positions: data.balance || null
        });
      },
      errorCallback: data => {
        this.setState({
          // balanceError: toast.error(i18next.t('externalDepositWithdraw.issue'))
        });
      }
    });
    if (!this.props.supportedAssets.loaded && !this.props.supportedAssets.loading) {
      this.props.fetchExternalTransactionSupportedAssets();
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.currentScreen !== prevState.currentScreen) {
      if (this.state.checkTimeout) clearTimeout(this.state.checkTimeout);
    }
  }

  componentWillUnmount() {
    if (this.state.checkTimeout) {
      clearTimeout(this.state.checkTimeout);
    }
  }

  getSelectedAccount = () => {
    return this.props?.accounts?.data.find(accountObj => accountObj.account === this.props.currentAccountNumber);
  };
  calculateSteps = () => {
    let stepNo = 1;
    const steps = [];
    const account = this.getAccountInfo();
    const assets =
      this.props.assets.data?.filter(asset => this.props.supportedAssets.data.includes(asset.security)) ?? [];

    steps.push({
      id: stepNo,
      title: 'Details',
      render: () => (
        <CurrencyStep
          asset={this.state.asset}
          type={this.state.transactionType}
          assets={{ data: assets }}
          handleSelect={this.handleSelect}
          onCompleted={() => this.switchScreen(this.state.currentScreen + 1)}
          positions={this.state.positions}
          balanceError={this.state.balanceError}
        />
      )
    });
    stepNo++;

    if (!isUSDAsset(this.state.asset)) {
      steps.push({
        id: stepNo,
        title: 'Address',
        render: () => (
          <WithdrawAddressStep
            queryWalletInProgress={this.state.queryWalletInProgress}
            onClose={this.onCloseModal}
            setAddress={this.onClickSetAddress}
            asset={this.state.asset}
            address={this.state.address}
            account={this.getSelectedAccount()}
            onCompleted={() => this.switchScreen(this.state.currentScreen + 1)}
          />
        )
      });
      stepNo++;
    }

    steps.push({
      id: stepNo,
      title: 'Amount',
      render: () => (
        <AmountStep
          valueObject={this.state.amountObject}
          maxDecimal={this.state.maxDecimal}
          onCompleted={() => {
            this.switchScreen(this.state.currentScreen + 1);
          }}
          asset={this.state.asset}
          isLoading={this.state.queryWalletInProgress}
          availableAmount={this.getAvailableAmountForWithdrawal()}
          onChange={this.handleAmountChange}
          shouldValidateAmount
          transactionType={this.state.transactionType}
        />
      )
    });
    stepNo++;

    steps.push({
      id: stepNo,
      title: 'Preview',
      render: () => (
        <PreviewStep
          amount={this.state.amountObject.value}
          asset={this.state.asset}
          fee={this.state.fee}
          toAddress={this.state.address}
          fromAddress={account.account}
          isLoading={this.state.queryWalletInProgress}
          onSubmit={this.handleSubmit}
          onClose={this.onCloseModal}
          cryptoRates={this.props.cryptoRates}
          cryptoTransactionFee={this.props.cryptoTransactionFee}
        />
      )
    });

    const normalizedSteps = steps.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    return [steps, normalizedSteps];
  };

  onCloseModal = () => this.props.toggleModal(null);

  handleBackButton = () => {
    if (this.state.currentScreen !== 1) this.switchScreen(this.state.currentScreen - 1);
  };

  handleSelect = option => {
    const decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');
    // Reset state in case of change of asset to clean up other steps
    this.setState(
      {
        ...initialState,
        asset: option?.value || '',
        maxDecimal: decimal
      },
      () => {
        if (this.state.asset !== '' && this.state.asset !== 'USD')
          this.props.getTransactionFeeRequest({
            currency: this.state.asset,
            errorCallback: toast.error
          });
      }
    );
  };

  switchScreen = (screenType, callback = () => null) => this.setState({ currentScreen: screenType }, () => callback());

  successTrigger = data => {
    toast.success(i18next.t('externalDepositWithdraw.toast2'));
    this.setState({
      isSending: false,
      transactionStatus: SUCCESS,
      transactionId: data.id
    });
  };

  errorTrigger = error => {
    const security = this.state.asset;
    const autoClose = 4000;

    try {
      const decimal = getDecimalByFraction(FRACTIONS[security]) || 0;
      const assetLowerCase = security.toLowerCase();
      const amount = getLocalisedNumericString(this.state.positions[assetLowerCase], false, decimal);

      toast.error(`${error}\nAmount available: ${amount}`, { autoClose });
    } catch (error) {
      toast.error(i18next.t('externalDepositWithdraw.toastEror'), {
        autoClose
      });
    }

    this.setState({
      isSending: false,
      transactionStatus: ERROR
    });
  };

  sendCryptoRequest = () => {
    const amount = onlyNumbers(replaceCommas(this.state.amountObject.value));
    const account = this.props.currentAccountNumber;
    this.props.createExternalTransactionRequest({
      payload: {
        account,
        crypto: this.state.asset.toLowerCase(),
        amount,
        destination: this.state.address
      },
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger
    });
  };

  sendUsdRequest = () => {
    const amount = onlyNumbers(replaceCommas(this.state.amountObject.value));

    const data = {
      amount,
      account: this.props.currentAccountNumber
    };

    this.props.createExternalWithdrawUSDRequest({
      payload: data,
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger
    });
  };

  handleSubmit = () => {
    if (isUSDAsset(this.state.asset)) {
      this.sendUsdRequest();
    } else {
      this.sendCryptoRequest();
    }

    this.setState({
      isSending: true,
      transactionStatus: PENDING
    });
  };

  getAccountInfo = () => {
    return (
      this.props.accounts?.data?.find(account => this.props.account === account.account) ||
      this.props.accounts?.data?.[0]
    );
  };

  getAvailableAmountForWithdrawal = () => {
    const accountPositions = this.props.positions?.[this.props.account];
    if (!accountPositions) return 0;

    return accountPositions[this.state.asset] || 0;
  };

  handleAmountChange = amountObject => {
    this.setState({ amountObject });
  };

  onClickSetAddress = address => {
    this.setState({ address });
  };

  renderBody = () => {
    const [, normalizedSteps] = this.calculateSteps();
    return normalizedSteps[this.state.currentScreen].render();
  };

  render() {
    const isFirstStep = this.state.currentScreen === 1;

    const isLoading = !this.props.assets?.loaded || !this.props.supportedAssets?.loaded;
    let trType = i18next.t('transactionType.withdraw');
    const [steps] = this.calculateSteps();

    return (
      <Modal className="common-modal" centered show onHide={this.onCloseModal} backdrop="static">
        <Modal.Header closeButton>
          {!isFirstStep && !this.state.transactionStatus && (
            <MdKeyboardBackspace
              onClick={this.handleBackButton}
              className="external-deposit-back-button"
              size="2.5em"
            />
          )}
          <Modal.Title>{`${trType} ${this.state.asset}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingWrapper isLoading={isLoading}>
            <div className="deposit-container">
              {!this.state.transactionStatus && (
                <Row noGutters className="external-deposit-top-bar">
                  {steps.map(step => {
                    return (
                      <RoundButton
                        key={step.id}
                        isActive={step.id === this.state.currentScreen}
                        isDisabled={step.id > this.state.currentScreen - 1}
                        onClick={() => this.switchScreen(step.id)}
                        smaller
                        number={step.id}
                        size="sm"
                        text={step.title}
                      />
                    );
                  })}
                </Row>
              )}
              <div className="external-deposit-step-container">
                {this.state.transactionStatus ? (
                  <TransactionHashStep
                    hashStatus={this.state.transactionStatus}
                    asset={this.state.asset}
                    account={this.props.account}
                    amount={this.state.amountObject.value}
                    transactionId={this.state.transactionId}
                    transactionType={WITHDRAW_TYPE}
                    specialRedirectFunction={this.onCloseModal}
                  />
                ) : (
                  this.renderBody()
                )}
              </div>
            </div>
          </LoadingWrapper>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExternalWithdraw;
