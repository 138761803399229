import { useState } from 'react';

const FIRST_STEP_INDEX = 0;

export const useCreateAccountSteps = steps => {
  const [activeStepIndex, setActiveStepIndex] = useState(FIRST_STEP_INDEX);
  const numberOfSteps = steps.length;
  const isFirstStep = activeStepIndex === FIRST_STEP_INDEX;
  const isLastStep = activeStepIndex === numberOfSteps - 1;

  const handleNextStep = () => {
    if (isLastStep) return;

    setActiveStepIndex(s => s + 1);
  };

  const handlePreviousStep = () => {
    if (isFirstStep) return;

    setActiveStepIndex(s => s - 1);
  };

  return {
    currentStep: steps[activeStepIndex],
    handleNextStep,
    handlePreviousStep,
    isFirstStep,
    isLastStep
  };
};
