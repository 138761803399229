import { getPositionDataForSpecificAccount } from '../../../utils/generalUtils';
import { getAmountsNew } from '../../../utils/transactionsHelpers';

/**
 * Validates whether the account has enough funds to preform the transaction.
 *
 * @param transaction {{brokers:string, security:string, qty:string, price:string, side:'B'|'S'}} - transaction object
 * @param selectedAccount {string} - selected account
 * @param positionData {{assets:any[], positions:{[key:string]:number}, accounts:any[]}}  - position data
 * @param transactionType {string} - parsed transaction type
 * @param isPending {boolean} - whether the transaction is pending or not
 * @param assetPairs {any[]} - asset pairs provided by FI
 * @return {boolean} - whether the account has enough funds to preform the transaction
 */
const validateAcceptAmount = (transaction, selectedAccount, positionData, transactionType, isPending, assetPairs) => {
  // We validate only the transactions that are related to the selected account.
  // Should be the first thing we do because it is the cheapest operation.
  if (selectedAccount !== transaction.brokers) return false;

  const positions = getPositionDataForSpecificAccount(
    positionData.assets ?? [],
    positionData.positions ?? {},
    positionData.accounts,
    transaction.brokers
  );

  // We should try to avoid using getAmounts function.
  let { amount, sendSecurity } = getAmountsNew(transaction, transactionType, isPending, assetPairs);

  // Check whether the user even has the asset in his account positions.
  const array = Object.keys(positions.VLN);
  if (!array.includes(sendSecurity)) return false;

  // Finally we check whether the user has enough funds.
  const positionsAmount = parseFloat(positions.VLN?.[sendSecurity]);
  return positionsAmount >= amount;
};

export default validateAcceptAmount;
