import {
  capitalizeAll,
  capitalizeFirstLetter,
  formatDateFromString,
  formatDateFromTimestamp,
  getLocalisedDecimalString,
  getUsdFormattedValue
} from './generalUtils';
import {
  getAmounts,
  getExecutedBy,
  getTransactionID,
  isAsset,
  isCrypto,
  isCryptoDeposit,
  isCryptoWithdraw,
  isPair,
  isStaking
} from './transactionsHelpers';
import { onlyNumbers } from './validators';
import i18next from 'i18next';

const mapDepositWithdrawTransaction = transaction => {
  const cryptoAdditionalRows = !transaction.security
    ? [
        {
          value: transaction?.source,
          label: 'Source'
        },
        {
          label: 'Destination',
          value: transaction.destination
        },

        {
          label: 'Comment',
          value: transaction.comment || 'N/A'
        },
        {
          label: 'Risk Score',
          value: transaction?.riskScore
        }
      ]
    : [];

  return [
    {
      label: 'Status',
      value: capitalizeFirstLetter(transaction.status.toLowerCase())
    },
    {
      label: 'Type',
      value: capitalizeFirstLetter(transaction.type)
    },
    {
      label: 'Transaction ID',
      value: transaction?.id
    },
    {
      label: 'Hash',
      value: transaction.hash
    },
    ...cryptoAdditionalRows,
    {
      label: 'Send amount',
      value: getSendAmount(transaction)
    },
    {
      label: 'Received amount',
      value: getReceiveAmount(transaction)
    },
    {
      label: 'Send asset',
      value: getSendAsset(transaction)
    },
    {
      label: 'Received asset',
      value: getReceiveAsset(transaction)
    },
    {
      label: 'Entry date',
      value: formatDateFromString(transaction.created_date || transaction.time)
    },
    {
      label: 'Fee',
      value: transaction?.fee
    }
  ];
};

const getSendAmount = transaction => {
  if (isCryptoDeposit(transaction)) return '';

  return getLocalisedDecimalString(transaction.amount, getSendAsset(transaction));
};

const getSendAsset = transaction => {
  if (isCryptoDeposit(transaction)) return '';

  return transaction.security ?? transaction.crypto?.toUpperCase();
};

const getReceiveAmount = transaction => {
  if (isCryptoWithdraw(transaction)) return '';

  return getLocalisedDecimalString(transaction.amount, getReceiveAsset(transaction));
};

const getReceiveAsset = transaction => {
  if (isCryptoWithdraw(transaction)) return '';

  return transaction.security ?? transaction.crypto?.toUpperCase();
};

const getPendingTransactionType = transaction => {
  if (!transaction) return 'N/A';

  if (transaction.type === 'order') {
    if (isPair(transaction.security)) return 'Escrow';
    if (isAsset(transaction.security)) return 'Request';
  }

  return capitalizeAll(transaction?.type) ?? 'N/A';
};

const mapInternalTransaction = (transaction, props, isPending) => {
  // TODO: try not to use getAmounts an resolve isPending in the caller rather
  //  than passing function reference as a parameter
  const { amount, receiveAmount, sendSecurity, receiveSecurity, status } = getAmounts(
    transaction,
    transaction.type,
    isPending(),
    props.assetPairs
  );

  // TODO: pass selected account rather than props with all accounts.
  const account =
    props?.accounts[0]?.account !== transaction?.brokers ? props?.accounts[0]?.account : transaction?.brokers;
  const counteraccount = props?.accounts[0]?.account !== transaction?.brokers && transaction?.brokers;

  return [
    {
      label: 'Status',
      value: capitalizeFirstLetter(status.toLowerCase()) || transaction.orderstatus.toLowerCase() || '-'
    },
    {
      label: 'Type',
      value: getPendingTransactionType(transaction)
    },
    {
      label: 'Transaction ID',
      value: getTransactionID(transaction.traderefno, transaction.type)
    },
    {
      label: 'Wallet address',
      value: ''
    },
    {
      label: 'Account name',
      value: ''
    },
    {
      label: 'Transaction executed by',
      value: getExecutedBy(transaction)
    },
    {
      label: 'Account number',
      value: transaction.account || account
    },
    {
      label: 'Approved by',
      value: ''
    },
    {
      label: 'Counterparty account',
      value: transaction.counteraccount || counteraccount || transaction.fbo
    },
    {
      label: 'Counterparty message',
      value: transaction.comment
    },
    {
      label: 'Send amount',
      value: getLocalisedDecimalString(onlyNumbers(amount), sendSecurity)
    },
    {
      label: 'Received amount',
      value: getLocalisedDecimalString(onlyNumbers(receiveAmount), receiveSecurity)
    },
    {
      label: 'Send asset',
      value: sendSecurity
    },
    {
      label: 'Received asset',
      value: receiveSecurity
    },
    {
      label: 'Send amount price per asset',
      details: i18next.t('transactionDetailsModal.pricePerAssetInformation'),
      value: getUsdFormattedValue(transaction.pricePerAsset)
    },
    {
      label: 'Received amount price per asset',
      details: i18next.t('transactionDetailsModal.pricePerAssetInformation'),
      value: getUsdFormattedValue(transaction.pricePerAssetCounterparty)
    },
    {
      label: 'Send amount total value',
      value: getUsdFormattedValue(transaction.totalValue)
    },
    {
      label: 'Received amount total value',
      value: getUsdFormattedValue(transaction.totalValueCounterparty)
    },
    {
      label: 'Entry date',
      value: formatDateFromTimestamp(transaction.time || transaction.enttime)
    },
    {
      label: 'Fee',
      value: transaction?.fee
    },
    {
      label: 'Settlement date',
      value: formatDateFromTimestamp(transaction?.next_effectivetime)
    },
    {
      label: 'Price',
      value: ''
    }
  ];
};

const mapStakingTransaction = transaction => {
  return [
    {
      label: 'Status',
      value: 'N/A'
    },
    {
      label: 'Type',
      value: 'Staking'
    },
    {
      label: 'Amount',
      value: transaction?.amount
    },
    {
      label: 'Asset',
      value: 'ETH'
    },
    {
      label: 'Transaction ID',
      value: transaction?.id
    },
    {
      label: 'Account number',
      value: transaction?.account
    },
    {
      label: 'Entry Date',
      value: formatDateFromString(transaction?.created_date)
    },
    {
      label: 'Processing Status',
      value: transaction?.processingStatus
    }
  ];
};

const transactionDetailsGenerator = (transaction, props = null, isPending = null) => {
  if (isCrypto(transaction)) {
    return mapDepositWithdrawTransaction(transaction);
  } else if (isStaking(transaction)) {
    return mapStakingTransaction(transaction);
  } else {
    return mapInternalTransaction(transaction, props, isPending);
  }
};

export default transactionDetailsGenerator;
