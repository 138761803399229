import {
  getContactsRequest,
  getListOfFirmAccounts,
  getListOfFirmsRequest,
  setCurrentAccountNumber
} from '../../../redux/actions/administration';
import { queryPositions, queryPositionsSuccess, queryPositionsFirmDetails } from '../../../redux/actions/websocket';
import { getBalance } from '../../../redux/actions/balance';

import AccountDropdown from './AccountDropdown';
import { connect } from 'react-redux';
import { getDepositAccounts, getAccountTransactions } from '../../../redux/actions/transactions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  assets: state.transactions.tradingAssets,
  firms: state.administration.listOfFirms,
  currentAccountNumber: state.administration.currentAccountNumber.data,
  websocket: state.websocket.websocket,
  positions: state.websocket.positions,
  cryptoRates: state.transactions.cryptoRates,
  user: state.login.userData
});

const mapDispatchToProps = {
  getDepositAccounts,
  getListOfFirmsRequest,
  getListOfFirmAccounts,
  queryPositions,
  queryPositionsSuccess,
  queryPositionsFirmDetails,
  getAccountTransactions,
  getBalance,
  getContactsRequest,
  setCurrentAccountNumber
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(AccountDropdown);
