import { FormControl, Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../LoadingIndicator';
import VLButton from '../../Buttons/VLButton';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5Mb

const INITIAL_STATE = {
  error: '',
  value: null,
  isLoading: false
};

const MAX_SIZE_ERROR = 'Please choose a file with size smaller than 5MB';

const UploadReportModal = ({ id, isOpen, onClose, onCompleted, uploadCallback }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const handleOnChange = ({ target }) => {
    if (target.files[0].size > MAX_FILE_SIZE) {
      setState(s => ({
        ...s,
        error: MAX_SIZE_ERROR,
        value: null
      }));
      target.value = '';
      return;
    }

    setState(s => ({ ...s, error: '', value: target.files[0] }));
  };

  const handleOnClick = ({ target }) => {
    target.value = '';
    setState(INITIAL_STATE);
  };

  const handleOnSubmit = () => {
    setState(s => ({ ...s, isLoading: true }));
    uploadCallback({
      id: id,
      file: state.value,
      successCallback: () => {
        toast.success('Report uploaded successfully');
        onCompleted();
      },
      errorCallback: () => {
        setState(s => ({
          ...s,
          error: 'Something went wrong, please try again',
          isLoading: false
        }));
      }
    });
  };

  const uploadDisabled = state.error === MAX_SIZE_ERROR || !state.value || state.isLoading;

  return (
    <Modal className="common-modal" centered show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Upload report</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {state.isLoading && <LoadingIndicator />}
        <Row className="upload-modal-row">
          <div className="upload-modal-input-wrapper">
            <FormControl
              onChange={handleOnChange}
              onClick={handleOnClick}
              className="system-form-control"
              size="sm"
              name="upload-report"
              type="file"
              accept=".pdf, .csv"
            />
            <p className="upload-report-input-description">
              Maximum file size is 5Mb and allowed file types are pdf and csv
            </p>
            {state.error && <p className="upload-report-input--error">{state.error}</p>}
          </div>
          <VLButton onClick={handleOnSubmit} width="100%" disabled={uploadDisabled} text={'Upload'} />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UploadReportModal;
