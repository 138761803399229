import Sidebar from './Sidebar';
import { compose } from 'redux';
import { connect } from 'react-redux';

import './Sidebar.scss';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  contacts: state.administration.contacts,
  contactsRequests: state.administration.contactsRequests,
  profileDetails: state.login.profileDetails,
  currentAccountNumber: state.administration.currentAccountNumber.data,
  mode: state.settings.mode
});

export default compose(withTranslation('translations'), connect(mapStateToProps, null))(Sidebar);
