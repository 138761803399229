import * as CONSTANTS from '../constants/balance';

const initialState = {
  balance: {
    data: {},
    loading: true,
    loaded: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.GET_BALANCE:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_BALANCE_SUCCESS:
      const { balances, account } = action.data;
      return {
        ...state,
        balance: {
          ...state.balance,
          data: {
            ...state.balance.data,
            [account]: balances
          },
          loading: false,
          loaded: true,
          error: ''
        }
      };

    case CONSTANTS.GET_BALANCE_ERROR:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: false,
          loaded: false,
          error: action.error
        }
      };

    default:
      return state;
  }
};
