import * as TRANSACTIONS_CONSTANTS from './transactionsExternal';

const URLS = {
  [TRANSACTIONS_CONSTANTS.CREATE_USD_DEPOSIT_REQUEST]: '/api/vld/deposits',
  [TRANSACTIONS_CONSTANTS.CREATE_USD_WITHDRAW_REQUEST]: '/api/vld/withdrawals',
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS]: '/api/vld/transactions',
  [TRANSACTIONS_CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS]: '/api/vld',
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS]: '/api/vld/transactions',
  [TRANSACTIONS_CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS]: '/api/vld/transactions',
  [TRANSACTIONS_CONSTANTS.EXPORT_TRANSACTIONS_USD]: '/api/reports/transactions',
  [TRANSACTIONS_CONSTANTS.EXPORT_CTR]: '/api/reports/ctr'
};

export default URLS;
