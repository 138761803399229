import CurrencyStep from './CurrencyStep';
import { connect } from 'react-redux';
import { fetchExternalTransactionSupportedAssets } from '../../../redux/actions/transactionsExternal';

const mapStateToProps = state => ({
  VLNPositions: state.websocket.positions,
  accounts: state.transactions.accounts,
  supportedAssets: state.externalTransactions.supportedAssets,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  fetchExternalTransactionSupportedAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyStep);
