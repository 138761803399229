import { ROLES } from '../../enums/roles';
import { Row } from 'react-bootstrap';
import { MODAL_CODES } from '../../enums/validation';
import { MdAdd, MdArrowBack, MdCallMade, MdCallReceived, MdSwapHoriz } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { hasRole, shouldAllowTransactions } from '../../utils/roleCheckers';
import { GiReceiveMoney, GiPayMoney } from 'react-icons/gi';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import ExternalWithdraw from '../../containers/ExternalWithdraw';
import ExternalDeposit from '../../containers/ExternalDeposit';
import i18next from 'i18next';
import i18n from '../../i18n';
import VLButton from '../Buttons/VLButton';

class TransactionButtonsBar extends PureComponent {
  onNavigationClick = route => this.props.history.push(route);

  shouldDisplayVLNTransfers = () => hasRole(this.props.userData, ROLES.SUPER_USER);

  onShowModalClick = modalType => this.props.toggleModal(modalType);

  static contextType = AccountFilterContext;

  shouldDisableTransactionButton = (account, disabledBasedOnProduct) => {
    if (!account || disabledBasedOnProduct) return true;

    return !shouldAllowTransactions(this.props.userData, account);
  };

  render() {
    const account = this.props.accounts.data.find(account => account.account === this.props.currentAccountNumber);
    if (i18n.language === 'esp') {
      document.styleSheets[0].addRule('.bordered-button.transactions-bar', 'padding: 3px ');
      document.styleSheets[0].addRule(
        '.bordered-button.transactions-bar',

        'font-size: 15px '
      );
    } else {
      document.styleSheets[0].addRule(
        '.bordered-button.transactions-bar',

        'font-size: 16px '
      );
    }
    return (
      <Row noGutters>
        <div
          className={`${this.props.isPayments ? 'payments-pending-container' : ''} dashboard-buttonbar-border-div ${
            this.shouldDisplayVLNTransfers() ? 'dashboard-buttonbar-border-div--hide' : ''
          }`}
        >
          {this.props.title && (
            <h4 className={`${this.props.isPayments ? 'payments-pending-title' : ''} dashboard-transactions-header`}>
              {this.props.title}
            </h4>
          )}
          {this.props.isDashboard && !!!this.shouldDisplayVLNTransfers() && (
            <div className="dashboard-buttonbar-div">
              <h5 className="dashboard-buttonbar-header">{i18next.t('transactionButtonsBar.external')}</h5>
              <div className="dashboard-buttonbar-wrap">
                <VLButton
                  width="150px"
                  onClick={() => this.onShowModalClick(MODAL_CODES.DEPOSIT)}
                  disabled={this.shouldDisableTransactionButton(account, this.props.externalTransferDisabled)}
                  text={i18next.t('transactionButtonsBar.deposit')}
                  leftIcon={<MdAdd className="bordered-button-icon" />}
                />

                <VLButton
                  width="150px"
                  onClick={() => this.onShowModalClick(MODAL_CODES.WITHDRAW)}
                  disabled={this.shouldDisableTransactionButton(account, this.props.externalTransferDisabled)}
                  text={i18next.t('transactionButtonsBar.withdraw')}
                  leftIcon={<MdArrowBack className="bordered-button-icon" />}
                />
              </div>
            </div>
          )}

          <>
            {!!!this.shouldDisplayVLNTransfers() && (
              <div className="dashboard-buttonbar-div">
                {this.props.isDashboard && (
                  <h5 className="dashboard-buttonbar-header">{i18next.t('transactionButtonsBar.customerTransfer')}</h5>
                )}
                <div className="dashboard-buttonbar-wrap">
                  <VLButton
                    width="150px"
                    className="bordered-button transactions-bar"
                    onClick={() => this.onShowModalClick(MODAL_CODES.SEND)}
                    disabled={this.shouldDisableTransactionButton(account)}
                    text={i18next.t('transactionButtonsBar.send')}
                    leftIcon={<MdCallMade className="bordered-button-icon" />}
                  />

                  <VLButton
                    width="150px"
                    onClick={() => this.onShowModalClick(MODAL_CODES.REQUEST)}
                    disabled={this.shouldDisableTransactionButton(account)}
                    text={i18next.t('transactionButtonsBar.request')}
                    leftIcon={<MdCallReceived className="bordered-button-icon" />}
                  />

                  <VLButton
                    width="150px"
                    className="bordered-button transactions-bar"
                    onClick={() => this.onShowModalClick(MODAL_CODES.ESCROW)}
                    disabled={this.shouldDisableTransactionButton(account)}
                    text={i18next.t('transactionButtonsBar.escrow')}
                    leftIcon={<MdSwapHoriz className="bordered-button-icon escrow" />}
                  />
                </div>
                <div className="dashboard-buttonbar-wrap">
                  {this.props.isDashboard && (
                    <VLButton
                      width="150px"
                      onClick={() =>
                        this.onShowModalClick(MODAL_CODES.EXCHANGE)
                      }
                      disabled={this.shouldDisableTransactionButton(account)}
                      text={i18next.t('transactionButtonsBar.exchange')}
                      leftIcon={<MdSwapHoriz className="bordered-button-icon exchange" />}
                    />
                  )}
                  <VLButton
                    width="150px"
                    onClick={() => this.onShowModalClick(MODAL_CODES.STAKE)}
                    disabled={this.shouldDisableTransactionButton(account)}
                    text={i18next.t('transactionButtonsBar.stake')}
                    leftIcon={<GiPayMoney className="bordered-button-icon" />}
                  />

                  <VLButton
                    width="150px"
                    onClick={() =>
                      this.onShowModalClick(MODAL_CODES.EXIT_STAKE)
                    }
                    disabled={this.shouldDisableTransactionButton(account)}
                    text={i18next.t('transactionButtonsBar.exitStaking')}
                    leftIcon={<GiReceiveMoney className="bordered-button-icon" />}
                  />
                </div>
              </div>
            )}
          </>
        </div>
        {this.props.displayModal === MODAL_CODES.DEPOSIT ? (
          <ExternalDeposit
            transactionType={this.props.displayModal}
            account={this.props.currentAccountNumber || null}
            props={this.props}
            resetTransactionHash={this.props.resetTransactionHash}
            currentAccountNumber={this.props.currentAccountNumber}
          />
        ) : null}
        {this.props.displayModal === MODAL_CODES.WITHDRAW ? (
          <ExternalWithdraw
            transactionType={this.props.displayModal}
            account={this.props.currentAccountNumber || null}
            props={this.props}
            currentAccountNumber={this.props.currentAccountNumber}
          />
        ) : null}
      </Row>
    );
  }
}

export default TransactionButtonsBar;
