import OrderBlotter from './OrderBlotter';
import { connect } from 'react-redux';
import { getOrderHistory, toggleModal, resetTransactionHash, getTradingAssets } from '../../redux/actions/transactions';
import { getUserProfile } from '../../redux/actions/login';
const mapStateToProps = state => ({
  refreshOrderHistory: state.websocket.timestamp,
  orderHistory: state.transactions.orderHistory,
  user: state.login.userData.data,
  ioiInbound: state.websocket.ioiInbound,
  displayModal: state.transactions.displayModal,
  assets: state.transactions.tradingAssets
});

const mapDispatchToProps = {
  getOrderHistory,
  getUserProfile,
  toggleModal,
  resetTransactionHash,
  getTradingAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBlotter);
