import {
  getContactsRequest,
  getContactsRequestsRequest,
  markContactNotificationsAsReadRequest
} from '../../redux/actions/administration';
import PeriodicalContactUpdateComponent from './PeriodicalContactUpdateComponent';

import { connect } from 'react-redux';

const mapStateToProps = state => ({
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getContactsRequest,
  getContactsRequestsRequest,
  markContactNotificationsAsReadRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicalContactUpdateComponent);
