export const chartColors = {
  btc: {
    lineColor: 'rgb(200, 117, 15)',
    areaTopColor: 'rgb(239, 150, 41)',
    areaBottomColor: 'rgb(245, 192, 126)'
  },
  eth: {
    lineColor: 'rgb(34, 57, 221)',
    areaTopColor: 'rgb(108, 125, 232)',
    areaBottomColor: 'rgb(194, 201, 245)'
  },
  bch: {
    lineColor: 'rgb(85, 122, 44)',
    areaTopColor: 'rgb(144, 195, 85)',
    areaBottomColor: 'rgb(194, 223, 164)'
  },
  etc: {
    lineColor: 'rgb(37, 81, 32)',
    areaTopColor: 'rgb(59, 130, 52)',
    areaBottomColor: 'rgb(159, 215, 153)'
  },
  fil: {
    lineColor: 'rgb(44, 125, 135)',
    areaTopColor: 'rgb(89, 191, 201)',
    areaBottomColor: 'rgb(154, 216, 222)'
  },
  ltc: {
    lineColor: 'rgb(120, 114, 114)',
    areaTopColor: 'rgb(191, 187, 187)',
    areaBottomColor: 'rgb(232, 230, 230)'
  },
  usdc: {
    lineColor: 'rgb(44, 84, 143)',
    areaTopColor: 'rgb(59, 110, 190)',
    areaBottomColor: 'rgb(150, 179, 222)'
  }
};

/**
 * Formats chart data usable by the {@link CryptoAssetChart}.
 *
 * @param {Array<close: Number, high: Number, low: Number, open: Number, security: String, time: Number, volume: Number>} data - Data that is formatted for chart display.
 *
 * @return {Array<time: Number, value: Number>} - Chart usable data.
 */
export const formatData = data => {
  let formattedData = [];

  for (let i = 0; i < data.length; i++) {
    formattedData.push({ time: data[i].time, value: data[i].close });
  }

  return formattedData;
};
