import Select from 'react-select';
import PropTypes from 'prop-types';
import './VLSelect.scss';

/**
 * Select field using {@link Select} as a core and allows custom options
 *
 * @param {String} width - Value representing select field width. Default value is '100%'.
 * @param {String} height - Value representing select field height. Default value is 'auto'.
 * @param {String} size - Value representing select field size. This will affect both padding and font size of the select field control. Default size is small ('s').
 * @param {Boolean} borderless - Removes border from the select field. Select fields have bordered layout by default.
 * @param {Boolean} transparent - Removes background color from the select field. Default color depends on the set value and is not transparent.
 */
const VLSelect = ({
  width,
  height,
  size,
  borderless,
  transparent,
  ...props
}) => {
  const getBorderStyle = () => {
    if (borderless) {
      return "none";
    }
    
    return "1px solid var(--select-default-border)";
  };

  const styles = {
    control: (baseStyles, { menuIsOpen }) => ({
      ...baseStyles,
      cursor: "pointer",
      width,
      height,
      border: menuIsOpen ? "1px solid var(--select-default-border-hover)" : getBorderStyle(),
      borderColor: menuIsOpen ? "var(--select-default-border-hover)" : "var(--select-default-border)",
      borderBottomLeftRadius: menuIsOpen && "0",
      borderBottomRightRadius: menuIsOpen && "0",
      borderBottom: menuIsOpen && "none",
      backgroundColor: transparent ? "transparent" : "var(--select-default)",
      boxShadow: "none",
      "&:hover": {
        borderColor: !menuIsOpen && "var(--select-default-border-hover)"
      }
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      padding: state.selectProps.menuIsOpen ? "8px 0 8px 8px" : "8px 8px 8px 0",
      color: "var(--text)"
    }),
    indicator: (baseStyles) => ({
      ...baseStyles,
      color: "var(--text)"
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      width: "0"
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      margin: "-1px 0 0",
      boxShadow: "none",
      border: "1px solid var(--select-default-border-hover)",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderTop: "none",
      backgroundColor: "var(--select-default)"
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      zIndex: "3"
    }),
    option: (baseStyles, { isFocused, isSelected }) => ({
      ...baseStyles,
      cursor: "pointer",
      color: "var(--text)",
      backgroundColor: (isFocused || isSelected) && "var(--select-default-option)",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      width,
      paddingRight: "8px",
      color: "var(--text)"
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: "var(--text)"
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "var(--input-placeholder)"
    })
  };

  return <Select {...props} styles={styles} className="select" classNamePrefix={`size-${size}`} />
};

VLSelect.propTypes = {
  /** VLSelect field width */
  width: PropTypes.string,

  /** VLSelect field height */
  height: PropTypes.string,

  /** VLSelect field control size */
  size: PropTypes.oneOf(['s', 'm', 'l']),

  /** Remove borders from the VLSelect */
  borderless: PropTypes.bool,

  /** Remove background color from the VLSelect */
  transparent: PropTypes.bool
};

VLSelect.defaultProps = {
  width: "100%",
  height: 'auto',
  size: "s",
  borderless: false,
  transparent: false
};

export default VLSelect;