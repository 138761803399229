import { Row } from 'react-bootstrap';
import React from 'react';
import i18next from 'i18next';
import { mapValuesListToOptions, renderSelect } from '../helpers';
import { useCreateAccountContext } from '../context/CreateAccountContext';
import VLButton from '../../../Buttons/VLButton';

const AMOUNT_OPTIONS = mapValuesListToOptions([
  'Less than $10,000',
  '$10,000 - $100,000',
  '$100,000 - $999,999',
  '$1,000,000 - $10,000,000',
  'Greater than $10,000,000'
]);

const SOURCE_OPTIONS = mapValuesListToOptions([
  'Credit/Loan',
  'Crypto mining/Staking',
  'Gift',
  'Inheritance',
  'Initial Coin Offering (ICO)',
  'Investment',
  'Occupation',
  'Savings'
]);

const DEPOSITS_NUMBER_OPTIONS = mapValuesListToOptions(['Less than 25', '25 - 100', 'Greater than 100']);

const WITHDRAWALS_NUMBER_OPTIONS = mapValuesListToOptions(['Less than 10', '10 - 100', 'Greater than 100']);

const REQUIRED_FIELDS = ['depositsUsd', 'depositsNumber', 'depositsSource', 'withdrawalsUsd', 'withdrawalsNumber'];

const AccountDepositsWithdrawalsStep = ({ onNext, confirmButtonText, isValidationDisabled }) => {
  const { formState, actions } = useCreateAccountContext();
  const { errors } = formState;
  const { updateValue, setError, clearErrors } = actions;

  const handleOnDropdownChange = ({ value }, { name }) => {
    updateValue(name, value);
  };

  const validateForm = () => {
    let isValid = true;

    REQUIRED_FIELDS.forEach(field => {
      if (!formState[field]) {
        setError(field, 'Required');
        isValid = false;
      }
    });

    return isValid;
  };

  const handleNext = () => {
    clearErrors();

    if (!isValidationDisabled && !validateForm()) return;

    onNext();
  };

  const renderFormSelect = ({ name, label, options }) => {
    return renderSelect({
      name,
      label,
      value: options.find(o => o.value === formState[name]),
      onChange: handleOnDropdownChange,
      options,
      error: errors[name],
      isRequired: REQUIRED_FIELDS.find(r => r === name)
    });
  };

  return (
    <div>
      {renderFormSelect({
        name: 'depositsUsd',
        label: 'Anticipated deposits per month valued in USD?',
        options: AMOUNT_OPTIONS
      })}
      {renderFormSelect({
        name: 'depositsNumber',
        label: 'Anticipated number of deposits per month?',
        options: DEPOSITS_NUMBER_OPTIONS
      })}
      {renderFormSelect({
        name: 'depositsSource',
        label: 'Source of anticipated deposits?',
        options: SOURCE_OPTIONS
      })}
      {renderFormSelect({
        name: 'withdrawalsUsd',
        label: 'Anticipated withdrawals per month in USD?',
        options: AMOUNT_OPTIONS
      })}
      {renderFormSelect({
        name: 'withdrawalsNumber',
        label: 'Anticipated number of withdrawals per month?',
        options: WITHDRAWALS_NUMBER_OPTIONS
      })}

      <Row noGutters className="summary-row">
        <p className="mandatory-text">
          {i18next.t('createAccountModal.fields')}
          <span className="mandatory-star">*</span>' {i18next.t('createAccountModal.mandatory')}
        </p>
      </Row>
      <div className="external-deposit-footer-bar">
        <VLButton
          disabled={false}
          onClick={handleNext}
          width="100%"
          text={confirmButtonText}
        />
      </div>
    </div>
  );
};

export default AccountDepositsWithdrawalsStep;
