import { useEffect, useState } from 'react';

import { Button, ButtonGroup, ButtonToolbar, Col, Container, Row } from 'react-bootstrap';
import { MdRefresh } from 'react-icons/md';

import MarketsTable from '../../components/MarketsTable';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';
import { parseObjectWithSamePropertyForDropdown } from '../../utils/generalUtils';
import { getSeparatedAssetsFromConcatenatedPair } from '../../utils/transactionsHelpers';
import './Market.scss';
import {
  ALL_PAIRS_TABLE,
  CMT_SECURITIES,
  MODAL_CODES,
  SECURITY_CODES,
  SECURITY_CODE_NAME_MAP,
  USD_PAIRS_TABLE
} from '../../enums/validation';
import BuySellModal from '../../components/modals/BuySellModal';
import VLIconButton from '../../components/Buttons/VLIconButton';
import VLSelect from '../../components/VLSelect';

const ETH_STAKED = 'ETH STAKED';
const USD = 'USD';

const removeUnnecessaryValuesForFilter = assets => {
  return assets.filter(asset => {
    return asset.security !== ETH_STAKED && asset.security !== USD;
  });
};

const createOptionsMarketsSelect = assets => {
  const assetsMap = removeUnnecessaryValuesForFilter(assets);
  return assetsMap.map(asset => {
    return { value: asset.security, label: asset.security };
  });
};

const renderDropdownAssetOptions = ({ value, label }) => {
  return (
    <>
      <div className="form-select-label-wrap">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          alt={label}
        />
        <span className="form-select-text">{label}</span>
      </div>
    </>
  );
};

const isNotPairIgnored = security => !!CMT_SECURITIES[security];

const generateQueryForFetching24hAssetData = assetPairs => {
  return assetPairs
    .map(pair => pair.security)
    .filter(isNotPairIgnored)
    .join('-');
};

const Markets = ({
  getTradingAssets,
  getContactsRequest,
  getUserProfile,
  fetchAssetPairs,
  assetPairs,
  fetch24hDataForAllAssets,
  assets,
  getAssets,
  displayModal
}) => {
  useEffect(() => {
    getTradingAssets();
    fetchAssetPairs();
    getContactsRequest();
    getUserProfile();
    getAssets();
  }, []);

  const [selectedAssetUsdPairs, setSelectedAssetUsdPairs] = useState('');
  const [selectedAssetAllPairs, setSelectedAssetAllPairs] = useState('');
  const [selectedSecurity, setSelectedSecurity] = useState('');

  const [tableType, setTableType] = useState(USD_PAIRS_TABLE);
  const options = createOptionsMarketsSelect(assets.data);

  const first_asset = selectedSecurity.split('-')[0];

  useEffect(() => {
    const query = generateQueryForFetching24hAssetData(assetPairs);
    if (query !== '') {
      fetch24hDataForAllAssets({ security: query });
    }
  }, [assetPairs, fetch24hDataForAllAssets]);

  const renderComposeAssetPairOptionComponent = ({ value, label }) => {
    const assetData = getSeparatedAssetsFromConcatenatedPair(value, assetPairs);

    return (
      <>
        <div className="deposit-withdraw-currency-text">
          <img
            className="deposit-withdraw-currency-icon"
            src={require(`../../assets/svg/crypto/${assetData?.pair_first.toLowerCase()}.svg`)}
            alt={assetData?.pair_first}
          />
          <span className="form-select-text">
            {assetData?.pair_first}
            &nbsp; / &nbsp;
          </span>
          <img
            className="deposit-withdraw-currency-icon"
            src={require(`../../assets/svg/crypto/${assetData?.pair_second.toLowerCase() || 'btc'}.svg`)}
            alt={assetData?.pair_second}
          />
          <span className="form-select-text">{assetData?.pair_second}</span>
        </div>
      </>
    );
  };

  const triggerReload = () => {
    getTradingAssets();
    fetchAssetPairs();
    getContactsRequest();
    getUserProfile();
  };

  const clearSearchInput = () => {
    tableType === USD_PAIRS_TABLE ? setSelectedAssetUsdPairs('') : setSelectedAssetAllPairs('');
  };

  const pairsWithoutIgnoredPairs = assetPairs?.map(pair => pair).filter(pair => isNotPairIgnored(pair.security));

  const assetPairOptions = parseObjectWithSamePropertyForDropdown(pairsWithoutIgnoredPairs, 'security');

  const handleSelectChange = ({ value, label }) => {
    tableType === USD_PAIRS_TABLE
      ? setSelectedAssetUsdPairs({ value, label })
      : setSelectedAssetAllPairs({ value, label });
  };

  const customFilterUsdTable = (option, searchText) => {
    if (!searchText) return true;
    if (
      option.value.toLowerCase().includes(searchText.toLowerCase()) ||
      SECURITY_CODE_NAME_MAP[option.value].toLowerCase().includes(searchText.toLowerCase())
    )
      return true;
    return false;
  };

  const customFilterAllPairsTable = (option, searchText) => {
    if (!searchText) return true;
    if (option.value.toLowerCase().includes(searchText.toLowerCase())) return true;

    return false;
  };

  const isSelectedTableUsdPairs = tableType === USD_PAIRS_TABLE;

  return (
    <Card>
      {displayModal === MODAL_CODES.EXCHANGE && (
        <BuySellModal fixedSendAsset={SECURITY_CODES.USD} fixedReceiveAsset={SECURITY_CODES[first_asset]} />
      )}
      <Container fluid>
        <Row>
          <Col>
            <Row noGutters>
              <Col className="markets-tabs">
                <PageTitle text={'Markets'} />
                <ButtonToolbar className="button-container">
                  <ButtonGroup>
                    <Button
                      active={isSelectedTableUsdPairs}
                      onClick={() => setTableType(USD_PAIRS_TABLE)}
                      className="item-button-small"
                    >
                      USD
                    </Button>
                    <Button
                      active={tableType === ALL_PAIRS_TABLE}
                      onClick={() => setTableType(ALL_PAIRS_TABLE)}
                      className="item-button-small"
                    >
                      All markets
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="markets-select">
                  <VLSelect
                    isClearable={true}
                    isSearchable={true}
                    name="sourceAccount"
                    options={isSelectedTableUsdPairs ? options : assetPairOptions}
                    filterOption={isSelectedTableUsdPairs ? customFilterUsdTable : customFilterAllPairsTable}
                    onChange={(selectedOption, triggeredAction) => {
                      if (triggeredAction.action === "clear") {
                        clearSearchInput();
                        return;
                      }
                      handleSelectChange(selectedOption);
                    }}
                    value={isSelectedTableUsdPairs ? selectedAssetUsdPairs : selectedAssetAllPairs}
                    formatOptionLabel={
                      isSelectedTableUsdPairs ? renderDropdownAssetOptions : renderComposeAssetPairOptionComponent
                    }
                  />
                </div>
              </Col>
              <Col className="account-positions-left-col account-positions-left-col--total">
                <div className="dashboard-transactions-btn-bar inline-justifier">
                  <VLIconButton onClick={() => triggerReload()} Icon={MdRefresh} />
                  <div className="vl-button-out-text">Reload</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="overflowX-scroll-transactions">
                <MarketsTable
                  tableType={tableType}
                  selectedAsset={isSelectedTableUsdPairs ? selectedAssetUsdPairs : selectedAssetAllPairs}
                  class="markets-table"
                  clearSearchInput={clearSearchInput}
                  setSelectedSecurity={setSelectedSecurity}
                />
              </Col>
            </Row>
            <Row className="disclaimer-text-row">
              <p className="footer-disclaimer disclaimer-text">
                The data & information you see on this page is for informational purposes only.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default Markets;
