import React, { PureComponent } from 'react';

import { Container, Modal, Row } from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import i18next from 'i18next';

import {
  buildAccountDropdownWithDisabledField,
  getAccountNameFromId,
  handleLastUpdateTimestamp,
  parseObjectWithSamePropertyForDropdown,
  parseObjectWithValueAndComplexLabelForDropdown,
  replaceCommas
} from '../../utils/generalUtils';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import TransactionSuccessScreen from '../../components/TransactionSuccessScreen';
import RoundButton from '../../components/RoundButton';
import { getTransactionTitleByType } from '../../utils/DOMHelpers';
import { TRANSACTION_SCREENS } from '../../enums/paths';
import { EXIT_STAKE, STAKE, ZERO_ASSET_FORMAT } from '../../enums/validation';
import StakeAndExitStakeDetails from '../../components/StakeAndExitStakeComponents/StakeAndExitStakeDetails/StakeAndExitStakeDetails';
import StakeAndExitStakeSummary from '../../components/StakeAndExitStakeComponents/StakeAndExitStakeSummary/StakeAndExitStakeSummary';
import { toast } from 'react-toastify';
import { onlyNumbers } from '../../utils/validators';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import VLButton from '../../components/Buttons/VLButton';

const initialState = {
  sourceAccount: '',
  asset: 'ETH',
  amountObject: {
    value: '',
    display: '',
    error: false
  },
  maxDecimal: 0,
  firm: '',
  repeat: false,
  repeatDuration: '',
  positions: null,
  isSending: false,
  firmOptionObject: null,
  transactionID: null,
  isConfirmButtonAvailable: false
};

class StakeAndExitStake extends PureComponent {
  static contextType = AccountFilterContext;
  state = {
    ...initialState,
    currentScreen: TRANSACTION_SCREENS.DETAILS,
    transactionType: STAKE
  };

  componentDidMount() {
    this.setState({ sourceAccount: this.props.currentAccountNumber });
    //fetches accounts you have transaction access to
    this.props.getDepositAccounts();
    // fetches all the assets
    this.props.getAssets();
    //ispituje type i postavlja screen
    if (this.props.type === STAKE) {
      this.setState({
        transactionType: STAKE
      });
    } else {
      this.setState({
        transactionType: EXIT_STAKE
      });
    }
  }

  stakeRequest = amount => {
    const account = this.state.sourceAccount;
    this.props.addStakingRequest({
      payload: {
        amount,
        account
      },
      successCallback: data => {
        this.setState({
          isSending: false,
          transactionID: data.id
        });
        this.switchScreen(TRANSACTION_SCREENS.SUCCESS);
      },
      errorCallback: () => {
        this.setState({
          isSending: false
        });
        this.props.toggleModal(null);
        toast.error(i18next.t('stakingSummary.requestError'));
      }
    });
  };

  exitStakeRequest = amount => {
    const account = this.state.sourceAccount;
    this.props.exitStakingRequest({
      payload: {
        amount,
        account
      },
      successCallback: data => {
        this.setState({
          isSending: false,
          transactionID: data.id
        });
        this.switchScreen(TRANSACTION_SCREENS.SUCCESS);
      },
      errorCallback: () => {
        this.setState({
          isSending: false
        });
        this.props.toggleModal(null);
        toast.error(i18next.t('stakingSummary.requestError'));
      }
    });
  };

  submitForm = () => {
    this.setState({ isSending: true });
    const amount = onlyNumbers(replaceCommas(this.state.amountObject.value));

    if (this.state.transactionType === STAKE) {
      this.stakeRequest(amount);
    }
    if (this.state.transactionType === EXIT_STAKE) {
      this.exitStakeRequest(amount);
    }
  };

  switchScreen = screenType => this.setState({ currentScreen: screenType });

  onCloseModal = () => this.props.toggleModal(null); // ima u sebi data i type, zatvara prozor

  handleBackButton = () => {
    if (this.state.currentScreen === TRANSACTION_SCREENS.SUCCESS) this.switchScreen(TRANSACTION_SCREENS.CONFIRM);
    if (this.state.currentScreen === TRANSACTION_SCREENS.CONFIRM) this.switchScreen(TRANSACTION_SCREENS.DETAILS);
  };

  updateParent = (data, callback = null) =>
    this.setState(
      {
        ...data
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );

  renderDetailsForm = () => {
    // create dropdown options from asset data from api
    let filteredRecipientData = this.props.firmAccounts?.data?.filter(acc => acc.active === 'Y') || [];
    const assetOptions = parseObjectWithSamePropertyForDropdown(this.props.assets?.data, 'security');
    const assetOptionETH = assetOptions.filter(asset => asset.value === 'ETH');

    const recipientFirmOptions = parseObjectWithValueAndComplexLabelForDropdown(
      this.props.firms?.data,
      'firm',
      ['firm', 'description'], //properties from the object to use when building the label
      '${1}, (${0})', //firm label template
      ['No Firm Name', ''] //text to display when no data exists
    );
    const recipientAccountsOptions = parseObjectWithValueAndComplexLabelForDropdown(
      filteredRecipientData,
      'account',
      ['attr.name'],
      '${0}',
      ['No Account Name']
    );
    const accountsDropdownData = buildAccountDropdownWithDisabledField(
      this.props.accounts?.data,
      'account',
      'attr.name'
    );
    return (
      <StakeAndExitStakeDetails
        {...this.state}
        triggerSearch={this.triggerSearch}
        assetOptions={assetOptionETH}
        accountsDropdownData={accountsDropdownData}
        recipientAccountsOptions={recipientAccountsOptions}
        recipientFirmOptions={recipientFirmOptions}
        assets={this.props.assets}
        accounts={this.props.accounts}
        updateParent={this.updateParent}
        positions={this.props.positions?.[this.state.sourceAccount]}
        firmAccounts={this.props.firmAccounts?.data}
      />
    );
  };

  renderSummary = () => (
    <StakeAndExitStakeSummary
      {...this.state}
      makeAvailable={this.makeAvailable}
      transactionType={this.state.transactionType}
    />
  );

  makeAvailable = allChecked => {
    this.setState({
      isConfirmButtonAvailable: allChecked
    });
  };

  render() {
    const { sourceAccount, asset, amountObject, currentScreen } = this.state;
    const isDetailsDisplay = currentScreen === TRANSACTION_SCREENS.DETAILS;
    const isConfirmScreen = currentScreen === TRANSACTION_SCREENS.CONFIRM;
    const isSuccessScreen = currentScreen === TRANSACTION_SCREENS.SUCCESS;
    const isLoading = !this.props.assets?.loaded || !this.props.accounts?.loaded || this.state.isSending;
    const disabled =
      sourceAccount &&
      asset &&
      amountObject.value &&
      amountObject.value !== ZERO_ASSET_FORMAT[asset] &&
      !amountObject.error
        ? false
        : true;
    let type = '';

    if (getTransactionTitleByType(this.state.transactionType) === 'Stake') {
      type = i18next.t('transactionType.stake');
    } else {
      type = i18next.t('transactionType.exitStake');
    }

    return (
      <Modal
        className="common-modal"
        dialogClassName={`send-modal ${isSuccessScreen ? 'success' : ''} ${
          isDetailsDisplay ? 'confirm-screen-class' : ''
        }`}
        centered
        show
        onHide={this.onCloseModal}
        backdrop="static"
      >
        {!isSuccessScreen && (
          <Modal.Header closeButton>
            {!isDetailsDisplay && (
              <MdKeyboardBackspace
                onClick={this.handleBackButton}
                className="external-deposit-back-button"
                size="2.5em"
              />
            )}
            <Modal.Title>{`${type}`}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <LoadingWrapper isLoading={isLoading} waitDurationSeconds={60 * 2}>
            <div className="deposit-container">
              {isSuccessScreen ? (
                <TransactionSuccessScreen
                  type={this.state.transactionType}
                  amount={this.state.amountObject.value}
                  asset={this.state.asset}
                  history={this.props.history} // in case we need to use history.goBack() (legacy)
                  //counterparty is stored as an ID, but we need to display the name so we're going to extract it from the redux data
                  counterparty={getAccountNameFromId(this.props.firmAccounts?.data || [], this.state.recipient)}
                  requestID={this.state.transactionID}
                  specialRedirectFunction={this.onCloseModal}
                  notStandalone
                />
              ) : (
                <Container>
                  <Row className="deposit-header">
                    <RoundButton
                      isActive={isDetailsDisplay}
                      onClick={() => this.switchScreen(TRANSACTION_SCREENS.DETAILS)}
                      number={1}
                      text={i18next.t('sendAndRequestContainer.details')}
                      size="sm"
                    />
                    <RoundButton
                      isActive={!isDetailsDisplay}
                      // isDisabled={true}
                      number={2}
                      text={i18next.t('sendAndRequestContainer.preview')}
                      size="sm"
                    />
                  </Row>

                  {isDetailsDisplay ? this.renderDetailsForm() : this.renderSummary()}
                  <div className="sfcb-button-wrapper">
                    {isDetailsDisplay && (
                      <>
                        <VLButton
                          width="100%"
                          disabled={disabled}
                          onClick={() => this.switchScreen(TRANSACTION_SCREENS.CONFIRM)}
                          text={i18next.t('sendAndRequestContainer.confirm')}
                        />
                      </>
                    )}
                    {isConfirmScreen && (
                      <>
                        <div className="sfcb-button-inner">
                          <VLButton
                            width="100%"
                            onClick={this.submitForm}
                            //onClick={this.submitForm}
                            //to disable sending multiple requests at once
                            disabled={this.state.isSending || !this.state.isConfirmButtonAvailable}
                            text={
                              i18next.t('sendAndRequestContainer.confirm') + `  ${type.toLowerCase().split(' ')[0]}`
                            }
                          />

                          <p className="sfcb-button-text">{handleLastUpdateTimestamp()}</p>
                        </div>
                      </>
                    )}
                  </div>
                </Container>
              )}
            </div>
          </LoadingWrapper>
        </Modal.Body>
      </Modal>
    );
  }
}

export default StakeAndExitStake;
