import * as CONSTANTS from '../constants/balance';

export const getBalance = data => ({
  type: CONSTANTS.GET_BALANCE,
  data
});

export const getBalanceSuccess = data => ({
  type: CONSTANTS.GET_BALANCE_SUCCESS,
  data
});

export const getBalanceError = error => ({
  type: CONSTANTS.GET_BALANCE_ERROR,
  error
});
