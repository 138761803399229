import React, { PureComponent } from 'react';
import { ListGroup, Modal, Row, Col } from 'react-bootstrap';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';
class ListAccountsModal extends PureComponent {
  buildListItem = item => (
    <ListGroup.Item key={item.account} className="transactions-list-item">
      <Row className="transactions-item-row">
        <Col className="transactions-item-regular-text">{item.attr?.name || 'No Account Name'}</Col>
        <Col className="transactions-item-regular-text">{item.account}</Col>
      </Row>
    </ListGroup.Item>
  );

  render() {
    return (
      <Modal className="common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('listAccountModal.list')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-container all-accounts-container">
          <Row noGutters className="modal-multi-form-row all-accounts-list-row">
            <div className="system-form-control-box all-accounts-list-box">
              <ListGroup variant="flush" className="transactions-list-group">
                <ListGroup.Item key="headers-key" className="transactions-list-item">
                  <Row className="transactions-item-row">
                    <Col className="transactions-item-light-text">{i18next.t('listAccountModal.accName')}</Col>
                    <Col className="transactions-item-light-text">{i18next.t('listAccountModal.accNo')}</Col>
                  </Row>
                </ListGroup.Item>
                {this.props.accounts.map(item => this.buildListItem(item))}
              </ListGroup>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="modal-footer-container all-accounts-modal-footer">
          <VLButton onClick={this.props.onClose} text={i18next.t('listAccountModal.close')} size="l" />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ListAccountsModal;
