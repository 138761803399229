import { Col, Container, Overlay, Row, Tooltip } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import {
  findFiatCurrencySymbol,
  splitDateTime,
  getLocalisedNumericString,
  getSelectedOption
} from '../../utils/generalUtils';

import { isDollarAsset } from '../../utils/validators';
import i18next from 'i18next';
class EscrowSummary extends PureComponent {
  constructor() {
    super();
    this.state = {
      showMessage: false,
      showFullCounterpartyMessage: false
    };

    this.counterpartyMessage = null;

    this.setCounterpartyMessageRef = element => {
      this.counterpartyMessage = element;
    };

    this.showFullCounterpartyMessage = this.showFullCounterpartyMessage.bind(this);
    this.hideFullCounterpartyMessage = this.hideFullCounterpartyMessage.bind(this);
  }

  showFullCounterpartyMessage = () => {
    this.setState({ showMessage: true });
  };

  hideFullCounterpartyMessage = () => {
    this.setState({ showMessage: false });
  };

  getContactFilters = () => {
    let dropdownData =
      this.props?.accountContacts != null &&
      this.props?.accountContacts?.map(company => {
        return {
          group: 'Add a new Contact',
          value: company.accountNumber,
          label: company.fullName
        };
      });

    return dropdownData;
  };

  componentDidMount = () => {
    if (this.counterpartyMessage.offsetHeight < this.counterpartyMessage.scrollHeight) {
      this.setState({ showFullCounterpartyMessage: true });
    }
  };

  render() {
    const selectedCounterparty = getSelectedOption(this.getContactFilters(), this.props.counterparty);
    const expirationDate = this.props.expirationDate;
    const { day, month, year } = splitDateTime(expirationDate, true);
    // const time = formatTime(expirationDate);
    return (
      <div className="deposit-summary-container">
        <div className="ed-info-wrapper">
          <p className="ed-pretitle">{i18next.t('escrow.pretitle')}</p>
          <h2 className="external-deposit-h1-label external-deposit-h1-label--escrow">
            <span>
              {`
                ${this.props.sourceAsset}
                ${findFiatCurrencySymbol(this.props.sourceAsset)}
                ${
                  isDollarAsset(this.props.sourceAsset)
                    ? getLocalisedNumericString(this.props.sourceAmountObject.display)
                    : this.props.sourceAmountObject.display
                }
							`}
            </span>
            <span className="ed-pretitle ed-span"> {i18next.t('escrow.for')}</span>
            <span>
              {`
								${this.props.counterpartyAsset}
								${findFiatCurrencySymbol(this.props.counterpartyAsset)}
								${
                  isDollarAsset(this.props.counterpartyAsset)
                    ? getLocalisedNumericString(this.props.counterpartyAmount)
                    : this.props.counterpartyAmount
                }
							`}
            </span>
          </h2>
        </div>
        <Container>
          <Row className="summary-row">
            <Col>
              <p className="escrow-label"> {i18next.t('escrow.sourceAccount')}</p>
            </Col>
            <Col>
              <h3 className="summary-h3-text summary-darker-text">{this.props.source}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="escrow-label"> {i18next.t('escrow.destinationAccount')}</p>
            </Col>
            <Col>
              <h3 className="summary-h3-text summary-darker-text">{this.props.counterparty || 'N/A'}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="escrow-label"> {i18next.t('escrow.counterparty')}</p>
            </Col>
            <Col>
              <h3 className="summary-h3-text summary-darker-text">{selectedCounterparty.label || 'N/A'}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="escrow-label">{i18next.t('escrow.expirationDate')}</p>
            </Col>
            <Col>
              <h3 className="summary-h3-text summary-darker-text">
                {`${expirationDate ? `${month}/${day}/${year}` : 'N/A'}`}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="escrow-label">{i18next.t('escrow.counterpartyMsg')}</p>
            </Col>
            <Col className="dynamic-col">
              <h3
                ref={this.setCounterpartyMessageRef}
                className="summary-h3-text summary-darker-text"
                onMouseOver={this.state.showFullCounterpartyMessage ? this.showFullCounterpartyMessage : undefined}
                onMouseLeave={this.state.showFullCounterpartyMessage ? this.hideFullCounterpartyMessage : undefined}
              >
                {this.props.publicNote || 'N/A'}
              </h3>
              <Overlay target={this.counterpartyMessage} show={this.state.showMessage} placement="bottom">
                {props => <Tooltip {...props}>{this.props.publicNote}</Tooltip>}
              </Overlay>
            </Col>
          </Row>
        </Container>

        <hr className="horizontal-line" />
        <div className="external-deposit-central-text ed-text">
          <p>
            {i18next.t('escrow.paragraphContainer1')}
            <br /> <br />
            {i18next.t('escrow.paragraphContainer2')}
          </p>
        </div>
      </div>
    );
  }
}

export default EscrowSummary;
