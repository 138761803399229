import { Col, Row, Table } from 'react-bootstrap';
import { MdAdd, MdCallReceived, MdDelete, MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { calculateFirmAssetsUSD, getLocalisedNumericString, getUSDFromPositions } from '../../utils/generalUtils';
import ConfirmationModal from '../../components/modals/ConfirmationModal/ConfirmationModal';
import LoadingIndicator from '../../components/LoadingIndicator';
import i18next from 'i18next';
import Accounts from '../Accounts';
import Users from '../Users';
import { isWebsocketReady } from '../../utils/websocketHelper';
import VLButton from '../../components/Buttons/VLButton';
import PageTitle from '../../components/PageTitle';
import Card from '../Card';

const defaultConfirmationFunction = () => undefined;

class FirmDetails extends PureComponent {
  state = {
    showConfirmationModal: false,
    objectToDelete: null,
    confirmationOnSuccessFunk: defaultConfirmationFunction,
    positions: [],
    positionsLoading: false,
    positionsLoaded: false
  };
  componentDidMount() {
    this.props.getFirmDetailsRequest({
      firm: this.props.match.params.firmName
    });
    this.props.getFirmAdminAccountsRequest(this.props.match.params.firmName);
    this.props.getFirmUsersRequest(this.props.match.params.firmName);
    this.props.getTradingAssets();
  }

  componentDidUpdate() {
    if (this.state.positionsLoading || this.state.positionsLoaded) return;

    const accountIds = this.props.firmAdminAccounts.data?.data?.map(acc => acc.account);
    if (accountIds?.length) {
      this.loadAssetPos(accountIds);
    }
  }

  loadAssetPos = accountIds => {
    if (!isWebsocketReady(this.props.websocket)) return;

    this.setState({
      positionsLoading: true
    });

    this.props.queryPositionsFirmDetails({
      socket: this.props.websocket,
      userid: accountIds,
      firm: this.props.match.params.firmName,
      accounts: accountIds,
      successCallback: data => {
        this.setState({
          positions: data,
          positionsLoaded: true,
          positionsLoading: false
        });
      },
      errorCallback: err => {
        console.log('Error in loadAssetPos: ', err);
        this.setState({
          positionsLoaded: true,
          positionsLoading: false
        });
      }
    });
  };

  resetConfirmation = () => {
    this.setState({
      confirmationOnSuccessFunk: defaultConfirmationFunction,
      showConfirmationModal: false,
      objectToDelete: null
    });
  };

  handleReturnToFirmsList = () => {
    this.props.history.push('/firms');
  };

  handleDeleteFirm = () => {
    this.props.deleteFirmRequest({
      firm: this.state.objectToDelete
    });
    this.handleReturnToFirmsList();
  };

  toggleConfirmationModal = (data, type) => {
    this.setState({
      showConfirmationModal: true,
      confirmationType: type,
      objectToDelete: data,
      confirmationOnSuccessFunk: this.handleDeleteFirm
    });
  };

  renderFirmDetails = () => {
    const usdValue = getUSDFromPositions(this.state.positions || []);
    const { data, loaded, loading } = this.props.firmDetails;
    const totalAssets = calculateFirmAssetsUSD(
      this.state.positions?.length
        ? this.state.positions.map(p => ({
            security: p.security,
            value: p.curpos
          }))
        : [],
      this.props.cryptoRates.data
    );

    if (loaded) {
      return (
        <Row noGutters className="user-table">
          <Col sm={2}>
            <label className="administration-section-header firm-details-name-label">{data.details?.firm}</label>
            <p className="firm-details-description">{data.details?.description}</p>
          </Col>
          <Col>
            <Table className="chassis-table" borderless responsive>
              <tbody>
                <tr className="chassis-table-tr">
                  <td>
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width">{i18next.t('firmDetails.noAcc')}</div>
                      <div className="underscored-text">{data.accounts}</div>
                    </div>
                  </td>
                  <td>
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width">{i18next.t('firmDetails.totalAssets')}</div>
                      <div className="underscored-text">{`$ ${getLocalisedNumericString(totalAssets)}`}</div>
                    </div>
                  </td>
                  <td>
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width">{i18next.t('firmDetails.totalDollar')}</div>
                      <div className="underscored-text">{`$ ${getLocalisedNumericString(usdValue)}`}</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="firm-item-td">
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width">{i18next.t('firmDetails.nous')}</div>
                      <div className="underscored-text">{data.users}</div>
                    </div>
                  </td>
                  <td className="firm-item-td">
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width">{i18next.t('firmDetails.noFirm')}</div>
                      <div className="underscored-text">{data.admins}</div>
                    </div>
                  </td>
                  <td>
                    <div className="grey-td center-aligned-td">
                      <div className="text-with-width"></div>
                      <div></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      );
    } else if (loading) {
      return <LoadingIndicator />;
    }
  };

  render() {
    const { loaded, loading } = this.props.firmDetails;
    if (loading || !loaded) {
      return <LoadingIndicator />;
    }
    if (loaded) {
      return (
        <div className="firm-details chassis-interior-content">
          {this.state.showConfirmationModal && (
            <ConfirmationModal
              assetType={this.state.confirmationType}
              onSuccess={this.state.confirmationOnSuccessFunk}
              onClose={this.resetConfirmation}
            />
          )}

          <Row noGutters onClick={this.handleReturnToFirmsList} className="return-button">
            <MdKeyboardBackspace />
            {i18next.t('firmDetails.return')}
          </Row>
          <Row noGutters>
            <PageTitle text={i18next.t('firmDetails.title')} />
          </Row>
          <Card internalSpacing="0" spacing="0px 0px 5px 0px" borderless>
            {this.renderFirmDetails()}
          </Card>
          <Row noGutters className="dashboard-buttonbar">
            <VLButton
              onClick={() => {
                this.props.history.push(`/payments/deposit/${this.props.match.params.firmName}`);
              }}
              leftIcon={<MdAdd />}
              text={i18next.t('firmDetails.deposit')}
            />

            <VLButton
              onClick={() => {
                this.props.history.push(`/payments/withdraw/${this.props.match.params.firmName}`);
              }}
              text={i18next.t('firmDetails.withdraw')}
              leftIcon={<MdCallReceived />}
            />

            {this.props.canEditFirms && (
              <VLButton
                onClick={() => {
                  this.toggleConfirmationModal(this.props.match.params.firmName, 'firm');
                }}
                text={i18next.t('firmDetails.delete')}
                leftIcon={<MdDelete />}
              />
            )}
          </Row>
          <div>
            <Accounts firm={this.props.match.params.firmName} />
          </div>
          <div>
            <Users firm={this.props.match.params.firmName} />
          </div>
        </div>
      );
    }
  }
}

export default FirmDetails;
