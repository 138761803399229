import './Chassis.scss';

import { getUserProfile, logoutRequest, getUserPermissions } from '../../redux/actions/login';

import { setCurrentAccountNumber } from '../../redux/actions/administration';

import Administration from '../TransactionsAdmin';
import Chassis from './Chassis';
import Users from '../FirmUsers';
import { compose } from 'redux';
import { toggleDarkMode } from '../../redux/actions/settings';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  userData: state.login.userData,
  accountPermissions: state.login.userPermissions,
  connectionError: state.websocket.connectionError,
  mode: state.settings.mode,
  profileDetails: state.login.profileDetails,
  lastUpdateTimestamp: state.transactions.lastCheckedTimestamp,
  accounts: state.transactions.accounts,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  logoutRequest,
  toggleDarkMode,
  getUserProfile,
  getUserPermissions,
  setCurrentAccountNumber
};

export { Users, Administration };

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Chassis);
