import { Button, ButtonGroup, ButtonToolbar, Col, Container, Row } from 'react-bootstrap';

import React, { PureComponent } from 'react';
import TransactionsTableAdmin from '../../components/TransactionsTableAdmin/TransactionsTableAdmin';
import { MODAL_CODES, PENDING_TRANSACTIONS, NON_PENDING_TRANSACTIONS } from '../../enums/validation';
import TransactionDetailsModal from '../../components/modals/TransactionDetailsModal';
import StakingAndRewardsTableAdmin from '../../components/StakingAndRewardsTableAdmin';
import { sortByDateDescending } from '../../utils/generalUtils';
import PageTitle from '../../components/PageTitle';
import { formatTransactionsforTables } from '../../utils/transactionsHelpers';
import { toast } from 'react-toastify';
import SearchAccountsInput from '../../components/SearchAccountsInput';
import Card from '../Card';

const SCREEN_TYPES = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  PENDING_USD: 'pendingUSD',
  COMPLETED_USD: 'completedUSD',
  OPEN: 'open',
  OTHER: 'other',
  STAKING: 'staking',
  REWARDS: 'rewards',
  CANCELED: 'canceled'
};

const mapUsdTransactionsData = transactions => {
  return transactions.map(t => ({
    custodian_transaction_id: t.id,
    created_date: t.created_date,
    type: t.type.toLowerCase(),
    amount: t.amount,
    crypto: 'USD',
    status: t.status,
    userInfo: t.fiUserInfo,
    processing_status: t.processing_status,
    id: t.id,
    account: t.account
  }));
};

// TODO: Duplicated code from PaymentsTransactionsContainer
const mapRewardsData = transactions => {
  return transactions.map(t => ({
    ...t,
    created_date: t.createdDate,
    id: t.id,
    account: t.account,
    amount: t.amount,
    userid: t.userId
  }));
};

class TransactionsAdmin extends PureComponent {
  state = {
    otherTransactions: this.props.transactions.data,
    transaction: null,
    currentScreen: 'pending',
    accountSearch: null,
    selectedAccount: null
  };
  componentDidMount() {
    this.props.fetchAssetPairs();
    this.props.fetchAllAnalyticsData();
    this.props.fetchExternalPendingCryptoTrasactions(PENDING_TRANSACTIONS);
    this.props.fetchExternalReviewedCryptoTrasactions(NON_PENDING_TRANSACTIONS);
    this.props.fetchStakingRequest({ page: '0,1000,createdDate' });
    this.props.fetchStakingRewardsRequest();
    this.props.fetchExternalUSDPendingTransactions();
    this.props.fetchExternalUSDReviewedTransactions();
  }

  componentWillUnmount() {
    this.setState({ account: null, accountSearch: null });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.transactionDetails !== this.props.transactionDetails) {
      this.props.fetchExternalPendingCryptoTrasactions(PENDING_TRANSACTIONS);
      this.props.fetchExternalReviewedCryptoTrasactions(NON_PENDING_TRANSACTIONS);
    }

    if (
      prevProps.externalTransactionUsdUpdate.loaded === false &&
      this.props.externalTransactionUsdUpdate.loaded === true
    ) {
      this.props.fetchExternalUSDPendingTransactions();
    }
  }

  constructor(props) {
    super(props);
    this.triggerFilter = this.triggerFilter.bind(this);
  }

  updateStatusExternalCrypto = params => {
    this.props.updateStatusExternalCryptoTrasactions(params);
  };
  setSelectedAcc = acc => {
    this.setState(
      {
        selectedAccount: acc
      },
      () => {
        if (this.state.currentScreen === SCREEN_TYPES.OPEN) {
          this.triggerSearchByAccountOpen();
        } else if (this.state.currentScreen === SCREEN_TYPES.CANCELED) {
          this.triggerSearchByAccountCanceled();
        } else if (this.state.currentScreen === SCREEN_TYPES.OTHER) {
          this.triggerSearchByAccount();
        }
      }
    );
  };
  handleAccountInputChange = ({ target }) => {
    this.setState({
      accountSearch: target.value,
      selectedAccount: null,
      searchError: null
    });
  };
  updateStatusExternalUSD = params => {
    this.props.updateStatusExternalUSDTransactions(params);
  };

  triggerFilter(filters) {
    if (!this.state.selectedAccount) {
      toast.error('Please select account first');
      return;
    }
    this.props.getAccountTransactions({
      ...filters,
      account: this.state.selectedAccount
    });
  }

  triggerFilterPending = filters => {
    this.props.fetchExternalPendingCryptoTrasactions({
      filter: 'pending',
      ...filters
    });
  };
  triggerSearchByAccount = () => {
    if (!this.state.selectedAccount) {
      this.setState({
        searchError: 'Please select valid account'
      });
      return;
    }
    this.props.getAccountTransactions({ account: this.state.selectedAccount });
  };
  triggerSearchByAccountCanceled = filters => {
    if (!this.state.selectedAccount) {
      this.setState({
        searchError: 'Please select valid account'
      });
      return;
    }
    this.props.fetchCanceledOrders({
      ...filters,
      userid: this.state.selectedAccount
    });
  };
  triggerSearchByAccountOpen = filters => {
    if (!this.state.selectedAccount) {
      this.setState({
        searchError: 'Please select valid account'
      });
      return;
    }
    this.props.fetchOpenOrders({
      ...filters,
      userid: this.state.selectedAccount
    });
  };

  triggerFilterReviewed = filters => {
    this.props.fetchExternalReviewedCryptoTrasactions({
      filter: 'non-pending',
      ...filters
    });
  };

  triggerFilterDepositWithdrawUsdPending = filters => {
    this.props.fetchExternalUSDPendingTransactions({
      ...filters,
      page: '0,1000,requestedAt',
      transactionStatus: 'PENDING'
    });
  };

  triggerFilterDepositWithdrawUsdReviewed = filters => {
    this.props.fetchExternalUSDReviewedTransactions({
      ...filters,
      page: '0,1000,requestedAt',
      transactionStatus: 'COMPLETED'
    });
  };
  mapOtherTransactions = transactions => {
    transactions = transactions.map(transaction => {
      let { amount, receiveAmount, sendSecurity, receiveSecurity } = formatTransactionsforTables(
        transaction,
        transaction.type,
        this.props.assetPairs?.data
      );
      return {
        ...transaction,
        orderstatus: transaction.orderstatus,
        amount: amount,
        amountCounterparty: receiveAmount,
        traderefno: transaction?.rootref ?? transaction?.traderefno,
        account: this.state.selectedAccount,
        transactionType: transaction.type,
        sendSecurity: sendSecurity,
        security: transaction.security,
        tier: transaction?.tier,
        fbo: transaction.counteraccount ?? '-',
        assetCounterparty: receiveSecurity ?? '-',
        time: new Date(parseInt(transaction?.time ?? transaction?.enttime))
      };
    });
    return sortByDateDescending(transactions);
  };

  toggleTransactionDetails = (transaction = null) => {
    if (!transaction) this.onCloseModal();
    this.setState(
      {
        transaction: transaction
      },
      () => {
        this.props.toggleModal(transaction ? MODAL_CODES.DETAILS : null);
        this.props.resetTransactionHash();
      }
    );
  };

  onCloseModal = () => this.props.toggleModal(null);
  resetAccountSearch = () => {
    this.setState({
      selectedAccount: null,
      searchError: null
    });
  };

  changeStatusScreen = screen => {
    this.setState({
      currentScreen: screen
    });
    this.resetAccountSearch();
    this.props.clearAccountEvents();
    this.props.clearCanceledOrders();
    this.props.clearOpenOrders();
  };

  renderBody = () => {
    const isLoadingPending = this.props.transactionExternalPending?.loading || this.props.transactionDetails?.loading;
    const isLoadingReviewed = this.props.transactionExternalReviewed?.loading || this.props.transactionDetails?.loading;
    const isLoadingUsdPending =
      this.props.transactionsPendingUSD?.loading || this.props.externalTransactionUsdUpdate?.loading;
    const isLoadingUsdReviewed =
      this.props.transactionsReviewedUSD?.loading || this.props.externalTransactionUsdUpdate?.loading;
    const transactionsPendingUSD = mapUsdTransactionsData(this.props.transactionsPendingUSD.data);
    const transactionsReviewedUSD = mapUsdTransactionsData(this.props.transactionsReviewedUSD.data);
    const searchAccountInput = (
      <div className="search-accounts-wrapper">
        <SearchAccountsInput
          onAccountSelected={this.setSelectedAcc}
          selectedAccountId={this.state.selectedAccount}
          ignoreAccounts={[]}
        />
      </div>
    );
    switch (this.state.currentScreen) {
      case SCREEN_TYPES.PENDING:
        return (
          <TransactionsTableAdmin
            key={1}
            accounts={this.props.transactionExternalPending?.data}
            dropsDate={true}
            Header={'Pending Transactions Crypto'}
            transactionType="pending"
            filterData={this.triggerFilterPending}
            superAdminType={this.props.profileDetails}
            updateStatus={this.updateStatusExternalCrypto}
            isLoading={isLoadingPending}
            loaded={this.props.transactionExternalPending?.loaded}
          />
        );
      case SCREEN_TYPES.COMPLETED:
        return (
          <TransactionsTableAdmin
            key={2}
            accounts={this.props.transactionExternalReviewed?.data}
            dropsDate={false}
            transactionType="reviewed"
            filterData={this.triggerFilterReviewed}
            superAdminType={this.props.profileDetails}
            isLoading={isLoadingReviewed}
            loaded={this.props.transactionExternalReviewed?.loaded}
          />
        );
      case SCREEN_TYPES.PENDING_USD:
        return (
          <TransactionsTableAdmin
            key={3}
            accounts={transactionsPendingUSD}
            dropsDate={true}
            transactionType="pendingUSD"
            filterData={this.triggerFilterDepositWithdrawUsdPending}
            superAdminType={this.props.profileDetails}
            updateStatus={this.updateStatusExternalUSD}
            isLoading={isLoadingUsdPending}
            transactionUpdateDetails={this.props.externalTransactionUsdUpdate}
            toggleTransactionDetails={this.toggleTransactionDetails}
            loaded={this.props.transactionsPendingUSD?.loaded}
          />
        );
      case SCREEN_TYPES.COMPLETED_USD:
        return (
          <TransactionsTableAdmin
            key={4}
            accounts={transactionsReviewedUSD}
            dropsDate={false}
            transactionType="reviewedUSD"
            filterData={this.triggerFilterDepositWithdrawUsdReviewed}
            superAdminType={this.props.profileDetails}
            isLoading={isLoadingUsdReviewed}
            loaded={this.props.transactionsReviewedUSD.loaded}
            toggleTransactionDetails={this.toggleTransactionDetails}
          />
        );
      case SCREEN_TYPES.OPEN:
        return (
          <>
            <TransactionsTableAdmin
              key={5}
              searchAccountInput={searchAccountInput}
              accounts={this.mapOtherTransactions(this.props.openOrders?.data) || []}
              dropsDate={false}
              filterData={this.triggerSearchByAccountOpen}
              toggleTransactionDetails={this.toggleTransactionDetails}
              isLoading={this.props.openOrders?.loading}
              loaded={this.props.openOrders?.loaded}
              transactionType="otherOpen"
              userAccountNumber={this.state.selectedAccount && this.state.selectedAccount}
            />
          </>
        );
      case SCREEN_TYPES.OTHER:
        return (
          <>
            <TransactionsTableAdmin
              searchAccountInput={searchAccountInput}
              key={6}
              accounts={this.mapOtherTransactions(this.props.events?.data) || []}
              userAccountNumber={this.state.selectedAccount && this.state.selectedAccount}
              dropsDate={false}
              filterData={this.triggerFilter}
              toggleTransactionDetails={this.toggleTransactionDetails}
              isLoading={this.props.events?.loading}
              loaded={this.props.events?.loaded}
              transactionType="other"
            />
          </>
        );
      case SCREEN_TYPES.STAKING:
        return (
          <StakingAndRewardsTableAdmin
            key={7}
            accounts={sortByDateDescending(this.props.stakingTransactions.data)}
            isLoading={this.props.stakingTransactions.loading}
            isAdmin={true}
            toggleTransactionDetails={this.toggleTransactionDetails}
            loaded={this.props.stakingTransactions.loaded}
            type="STAKING"
          />
        );
      case SCREEN_TYPES.REWARDS:
        return (
          <StakingAndRewardsTableAdmin
            key={8}
            accounts={sortByDateDescending(mapRewardsData(this.props.rewardTransactions.data || []))}
            isLoading={this.props.rewardTransactions.loading}
            isAdmin={true}
            toggleTransactionDetails={this.toggleTransactionDetails}
            type="REWARDS"
          />
        );
      case SCREEN_TYPES.CANCELED:
        return (
          <>
            <TransactionsTableAdmin
              searchAccountInput={searchAccountInput}
              key={9}
              accounts={this.mapOtherTransactions(this.props.canceledOrders.data) || []}
              filterData={this.triggerSearchByAccountCanceled}
              toggleTransactionDetails={this.toggleTransactionDetails}
              isLoading={this.props.canceledOrders.loading}
              loaded={this.props.canceledOrders.loaded}
              transactionType="otherCanceled"
              userAccountNumber={this.state.selectedAccount && this.state.selectedAccount}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { displayModal, user } = this.props;
    return (
      <Card>
        <Container fluid>
          <Row>
            <Col>
              <div className="contacts-container-wrap">
                <div className="contacts-bar contacts-container">
                  <PageTitle text={'Transactions'} />
                </div>
              </div>
              <ButtonToolbar className="button-container">
                <ButtonGroup className="button-group-tabs">
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.PENDING}
                    type={'button btn btn-primary'}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.PENDING)}
                    className="item-button-small"
                  >
                    Pending crypto
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.COMPLETED}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.COMPLETED)}
                    className="item-button-small"
                  >
                    Completed crypto
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.PENDING_USD}
                    type={'button btn btn-primary'}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.PENDING_USD)}
                    className="item-button-small"
                  >
                    Pending USD
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.COMPLETED_USD}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.COMPLETED_USD)}
                    className="item-button-small"
                  >
                    Completed USD
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.OPEN}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.OPEN)}
                    className="item-button-small"
                  >
                    Orders
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.OTHER}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.OTHER)}
                    className="item-button-small"
                  >
                    Fills
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.STAKING}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.STAKING)}
                    className="item-button-small"
                  >
                    Staking
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.REWARDS}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.REWARDS)}
                    className="item-button-small"
                  >
                    Rewards
                  </Button>
                  <Button
                    active={this.state.currentScreen === SCREEN_TYPES.CANCELED}
                    onClick={() => this.changeStatusScreen(SCREEN_TYPES.CANCELED)}
                    className="item-button-small"
                  >
                    Cancels
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <Col className="dashboard-col chassis-interior-content">
                <Row noGutters>
                  <Container fluid>{this.renderBody()}</Container>
                  {displayModal === MODAL_CODES.DETAILS && (
                    <TransactionDetailsModal
                      accountList={this.state.listOfAccounts}
                      user={user}
                      transaction={this.state.transaction || {}}
                      onCloseModal={() => {
                        this.toggleTransactionDetails(null);
                      }}
                    />
                  )}
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }
}

export default TransactionsAdmin;
