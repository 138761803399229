import TransactionButtonsBar from './TransactionButtonsBar';
import { connect } from 'react-redux';
import { toggleModal } from '../../redux/actions/transactions';

const mapStateToProps = state => ({
  displayModal: state.transactions.displayModal,
  accounts: state.transactions.accounts,
  externalTransferDisabled: state.login.userPermissions.data.permissions?.externalTransferDisabled,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionButtonsBar);
