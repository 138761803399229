import {
  createAccountRequest,
  createAdminAccountRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  deleteFirmRequest,
  getFirmAdminAccountsRequest,
  getFirmDetailsRequest,
  getFirmUsersRequest,
  registerWalletForAccount
} from '../../redux/actions/administration';
import { queryPositionsFirmDetails, updateRiskSettings } from '../../redux/actions/websocket';
import {
  updateStatusExternalCryptoTrasactions,
  fetchExternalCryptoTransactionById,
  createExternalTransactionRiskScore
} from '../../redux/actions/transactionsExternal';

import TransactionDetailsAdmin from './TransactionDetailsAdmin';
import { checkAccountName } from '../../redux/actions/settings';
import { connect } from 'react-redux';
import { getAssets } from '../../redux/actions/transactions';
import './TransactionDetailsAdmin.scss';
import {
  uploadTransactionCryptoBtaReportRequest,
  deleteTransactionCryptoBtaReportRequest
} from '../../redux/actions/transactionsExternal';

const mapStateToProps = state => ({
  firmDetails: state.administration.firmDetails,
  firmAdminAccounts: state.administration.firmAdminAccounts,
  firmUsers: state.administration.firmUsers,
  assets: state.transactions.assets,
  websocket: state.websocket.websocket,
  socketConnected: state.websocket.socketConnected,
  users: state.settings.users,
  profileDetails: state.login.profileDetails,
  transactionDetails: state.externalTransactions.externalTransactionDetails.data
  //externalTransactionUpdated:
  //state.externalTransactions.externalTransactionUpdated.data
});

const mapDispatchToProps = {
  getFirmDetailsRequest,
  getFirmAdminAccountsRequest,
  getFirmUsersRequest,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAdminAccountRequest,
  deleteAccountRequest,
  deleteFirmRequest,
  getAssets,
  queryPositionsFirmDetails,
  checkAccountName,
  registerWalletForAccount,
  updateRiskSettings,
  updateStatusExternalCryptoTrasactions,
  fetchExternalCryptoTransactionById,
  createExternalTransactionRiskScore,
  uploadTransactionCryptoBtaReportRequest,
  deleteTransactionCryptoBtaReportRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsAdmin);
