import { ACCOUNT_DISPLAY, EMAIL_REGEX, PASSWORD_REGEX, USER_DISPLAY } from '../enums/validation';

import { formatMoney } from './generalUtils';
import { keccak256 } from 'js-sha3';
import patienceDiff from '../libs/PatienceDiff';
const SUCCESS = 'success';
export const isValidEmailFormat = email => EMAIL_REGEX.test(email);

export const isValidPasswordFormat = password => PASSWORD_REGEX.test(password);

export const isUserDisplay = type => type === USER_DISPLAY;

export const isAccountDisplay = type => type === ACCOUNT_DISPLAY;

export const isResponseSuccessful = resp => resp?.message === SUCCESS;

export const onlyNumbers = value => {
  if (!value) return value;
  const currentValue = value.toString().replace(/[^\d.]/g, '');

  return currentValue;
};

export const validateAmount = (
  value = '',
  balance = 0,
  maxDecimal,
  conditionToIgnoreBalanceCheck = false,
  asset,
  cryptoRates
) => {
  let returnObject = {
    display: '',
    value: '',
    error: false
  };

  let i = 0;
  let c = 2; // for flexibility c could be any number (3 for the third, ...)
  while (
    (i = value.indexOf('.', i + 1)) !== -1 &&
    --c // look for the fifth comma if any
  );

  if (i !== -1)
    // if there is a fifth comma
    value = value.substr(0, i) + value.substr(i + 1);
  if (!conditionToIgnoreBalanceCheck) {
    if (parseFloat(value) > parseFloat(balance)) {
      returnObject = {
        value: Number(value).toFixed(maxDecimal),
        display: formatMoney(value, value.includes('.'), maxDecimal),
        error: true
      };
    } else if (
      parseFloat(asset && cryptoRates?.data[asset?.toLowerCase()] * value) > 25000 ||
      (asset && value > 25000)
    ) {
      returnObject = {
        value: Number(value).toFixed(maxDecimal),
        display: formatMoney(value, value.includes('.'), maxDecimal),
        error: false,
        send25KError: true
      };
    } else {
      returnObject = {
        value: Number(value).toFixed(maxDecimal),
        display: formatMoney(value, value.includes('.'), maxDecimal),
        error: false
      };
    }
  } else {
    returnObject = {
      value: Number(value).toFixed(maxDecimal),
      display: formatMoney(value, value.includes('.'), maxDecimal),
      error: false
    };
  }

  return returnObject;
};

export const isDollarAsset = asset => asset?.toUpperCase() === 'USD';

export const formatAmount = (oldValue, newValue, zeroAmount) => {
  if (!oldValue || newValue.length < oldValue.length || oldValue === zeroAmount) return newValue;
  let difference = patienceDiff(oldValue.split(''), newValue.split(''));

  const indexesOfReplacements = difference.lines.filter(line => line.aIndex === -1)?.map(line => line?.bIndex);

  const indexesOfMissingValuesInNew = difference.lines.filter(line => line.bIndex === -1);

  const indexesOfDot = difference.lines.find(line => line.line === '.');
  let formattedValue = '';
  if (indexesOfReplacements.every(index => index <= indexesOfDot?.bIndex)) {
    formattedValue = newValue;
  } else if (indexesOfMissingValuesInNew.length !== 0) {
    formattedValue = oldValue;
    indexesOfMissingValuesInNew.forEach(indexNewValueMissing => {
      const string = difference.lines.find(line => line.bIndex === indexNewValueMissing.aIndex)?.line;
      formattedValue = `${formattedValue.slice(0, indexNewValueMissing.aIndex)}${string}${formattedValue.slice(
        indexNewValueMissing.aIndex + 1
      )}`;
    });
  } else {
    indexesOfReplacements.forEach(indexOfReplacement => {
      if (indexesOfDot?.aIndex === indexOfReplacement) {
        formattedValue = `${newValue.slice(0, indexOfReplacement + 1)}`;
      } else if (indexOfReplacement >= oldValue.length) {
        formattedValue = `${newValue.slice(0, indexOfReplacement - 1)}${newValue.slice(
          indexOfReplacement,
          newValue.length
        )}`;
      } else {
        formattedValue = `${newValue.slice(0, indexOfReplacement + 1)}${newValue.slice(
          indexOfReplacement + 2,
          newValue.length
        )}`;
      }
    });
  }

  return formattedValue;
};

// CRYPTO CHECKS

export const isAddress = address => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // check if it has the basic requirements of an address
    return false;
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
    // If it's all small caps or all all caps, return true
    return true;
  } else {
    // Otherwise check each case
    return isChecksumAddress(address);
  }
};

export const isChecksumAddress = address => {
  // Check each case
  address = address.replace('0x', '');
  var addressHash = keccak256(address.toLowerCase());
  for (var i = 0; i < 40; i++) {
    // the nth letter should be uppercase if the nth digit of casemap is 1
    if (
      (parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])
    ) {
      return false;
    }
  }
  return true;
};
