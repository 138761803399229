export const getFullName = user => {
  return user.attr?.first_name + ' ' + user.attr?.last_name;
};

export const filterByName = (users, query) => {
  const reg = new RegExp(query, 'i');
  return users.filter(userObj => reg.test(getFullName(userObj)));
};

export const filterByEmail = (users, query) => {
  return users.filter(u => u.userid.toLowerCase().includes(query.toLowerCase()));
};
