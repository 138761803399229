import { NavLink } from 'react-router-dom';
import {
  MdAccountBalanceWallet,
  MdMenu,
  MdPoll,
  MdOutlineTrendingUp,
  MdPayment,
  MdLibraryBooks,
  MdPieChart,
  MdFindInPage,
  MdCorporateFare,
  MdManageAccounts
} from 'react-icons/md';
import React, { PureComponent } from 'react';
import { APP_ROUTES_MAP, SIDEBAR_SECTIONS } from '../../enums/paths';
import { FaUsers } from 'react-icons/fa';
import { MdContactMail } from 'react-icons/md';
import { Col } from 'react-bootstrap';
import { ImLifebuoy } from 'react-icons/im';
import { ROLES } from '../../enums/roles';
import { hasRole } from '../../utils/roleCheckers';
import i18next from 'i18next';
import NotificationIndicator from '../NotificationIndicator/NotificationIndicator.js';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import SvgIcon from '../SvgIcon';
import { LIGHT_MODE } from '../../enums/validation';

const SIDEBAR = {
  ADMINISTRATION: 'ADMINISTRATION'
};

const NavLinkButton = ({ path, text, exact, hasAccess, icon, isOpen, onClick, pendingRequests }) => {
  const Icon = icon;
  return hasAccess ? (
    <>
      <NavLink
        onClick={e => {
          if (onClick) {
            e.preventDefault();
          }
        }}
        exact={!!exact}
        strict={path === '/settings'}
        to={`${path}`}
        activeClassName="active-item"
        className={`chassis-sidebar-item`}
      >
        {i18next.t('sidebar.' + text) === 'Contacts' ? (
          <>
            <div className="notifications-tab">
              <Icon className={`chassis-sidebar-icon ${isOpen ? 'menu-shrink' : ''}`} />
              {pendingRequests > 0 && <NotificationIndicator numberOfNotifications={pendingRequests} />}
            </div>
            <p className={`chassis-sidebar-text ${isOpen ? 'menu-shrink' : ''}`}>
              {i18next.t('sidebar.' + text) || SIDEBAR.ADMINISTRATION}
            </p>
          </>
        ) : (
          <>
            <Icon className={`chassis-sidebar-icon ${isOpen ? 'menu-shrink' : ''}`} />
            <p className={`chassis-sidebar-text ${isOpen ? 'menu-shrink' : ''}`}>
              {i18next.t('sidebar.' + text) || SIDEBAR.ADMINISTRATION}
            </p>
          </>
        )}
      </NavLink>
    </>
  ) : null;
};

class Sidebar extends PureComponent {
  state = {
    displaySidebar: true,
    sideMenuOpen: false
  };
  static contextType = AccountFilterContext;
  toggleSettings = () => {
    this.setState({
      settingsOpened: !this.state.settingsOpened
    });
  };

  navMap = [
    {
      path: SIDEBAR_SECTIONS.DASHBOARD,
      icon: MdPoll,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.DASHBOARD],

      exact: true
    },
    {
      path: SIDEBAR_SECTIONS.MARKETS,
      icon: MdPieChart,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.MARKETS],
      exact: true
    },
    {
      path: SIDEBAR_SECTIONS.ONBOARDING,
      icon: MdFindInPage,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.ONBOARDING],
      exact: true
    },
    {
      path: SIDEBAR_SECTIONS.TRANSACTIONS_BTA,
      icon: MdPayment,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.TRANSACTIONS_BTA]
    },
    {
      path: SIDEBAR_SECTIONS.ALL_USERS,
      icon: FaUsers,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.ALL_USERS]
    },
    {
      path: SIDEBAR_SECTIONS.ALL_ACCOUNTS,
      icon: MdManageAccounts,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.ALL_ACCOUNTS]
    },
    {
      path: SIDEBAR_SECTIONS.FIRMS,
      icon: MdCorporateFare,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.FIRMS]
    },

    {
      path: SIDEBAR_SECTIONS.PAYMENTS,
      icon: MdPayment,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.PAYMENTS]
    },
    {
      path: SIDEBAR_SECTIONS.CONTACTS,
      icon: MdContactMail,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.CONTACTS]
    },
    {
      path: SIDEBAR_SECTIONS.WALLETS,
      icon: MdAccountBalanceWallet,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.WALLETS]
    },
    {
      path: SIDEBAR_SECTIONS.REPORTS,
      icon: MdLibraryBooks,
      hasAccess: true
    },
    {
      path: SIDEBAR_SECTIONS.USERS,
      icon: FaUsers,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.USERS]
    },
    {
      path: SIDEBAR_SECTIONS.FIRM_ACCOUNTS,
      icon: MdManageAccounts,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.FIRM_ACCOUNTS]
    },
    {
      path: SIDEBAR_SECTIONS.TRADE,
      icon: MdOutlineTrendingUp,
      hasAccess: this.props.features?.[SIDEBAR_SECTIONS.TRADE]
    },
    {
      path: SIDEBAR_SECTIONS.HELP,
      icon: ImLifebuoy,
      hasAccess: true
    }
  ];

  shouldDisplaySidebar = () => {
    const { pathname } = this.props.location;
    const isSuperAdmin = hasRole(this.props.userData?.data, ROLES.SUPER_USER);
    if (pathname.includes('/transactions') && isSuperAdmin) {
      return false;
    } else {
      return true;
    }
  };

  handleMenuClick = () => {
    this.props.handleMenuShrink();
  };

  buildNavigation = () => {
    let pendingRequests = 0;

    this.props.contactsRequests.data?.forEach(contact => {
      if (contact?.requesterAccount.toString() !== this.props.currentAccountNumber) {
        pendingRequests++;
      }
    });
    this.props.contacts.data?.forEach(contact => {
      if (contact?.markAsRead === false) {
        pendingRequests++;
      }
    });

    return this.navMap.map((item, index) => {
      return (
        <NavLinkButton
          key={index}
          text={item.path?.split?.(' ')?.[0]}
          path={APP_ROUTES_MAP[item.path]}
          exact={item?.exact}
          drop={item?.droppable}
          isOpen={this.props.sideMenuOpen}
          icon={item.icon}
          hasAccess={item.hasAccess}
          onClick={item.onClick}
          isOpenSettings={this.state.settingsOpened}
          dropabletext={item?.droppable?.path}
          pendingRequests={pendingRequests}
        />
      );
    });
  };

  render() {
    const { displaySidebar } = this.state;
    const logoIconPath = this.props.mode === LIGHT_MODE ? 'logo/VL_logo_blue.svg' : 'logo/VL_logo_white.png';

    return (
      <>
        {displaySidebar ? (
          <div className={`chassis-sidebar ${this.props.sideMenuOpen ? 'menu-shrink' : ''}`}>
            <Col className="chassis-sidebar-col">
              <NavLink id="RouterNavLink" to={`/`} className="chassis-sidebar-item chassis-sidebar-item-logo">
                <SvgIcon className="chassis-sidebar-logo-icon" path={logoIconPath} alt="VaultLink logo" />
              </NavLink>
              <div className={`chassis-sidebar-item chassis-sidebar-item-menu`} onClick={this.handleMenuClick}>
                <MdMenu className={`chassis-sidebar-icon`} />
              </div>
              {this.buildNavigation()}
            </Col>
          </div>
        ) : null}
      </>
    );
  }
}

export default Sidebar;
