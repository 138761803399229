import { Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import SearchUsersInput from '../../SearchUsersInput';
import VLButton from '../../Buttons/VLButton';

const SearchUsersModal = ({ isOpen, onClose, onComplete, title, submitText, firm, ignoreUsers }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const handleConfirm = () => {
    onComplete?.(selectedUser);
  };

  const handleSelectedUser = user => {
    setSelectedUser(user);
  };

  return (
    <Modal className="common-modal" centered show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="">
          <div style={{ width: '100%' }}>
            <div className="sfcb-transparent">
              <SearchUsersInput
                firm={firm}
                ignoreUsers={ignoreUsers}
                onUserSelected={handleSelectedUser}
                selectedUser={selectedUser}
              />
            </div>
          </div>
          <VLButton
            disabled={!selectedUser}
            onClick={handleConfirm}
            text={submitText}
            width="100%"
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SearchUsersModal;
