import React from 'react';
import './VLIconButton.scss';
import PropTypes from 'prop-types';

const CUSTOM_SIZE = {
  s: { width: '25px', height: '25px' },
  m: { width: '35px', height: '35px' },
  l: { width: '45px', height: '45px' }
};

const VLIconButton = ({ onClick, disabled, Icon, size, variant }) => {
  return (
    <button disabled={disabled} onClick={onClick} className={`vl-button-wrap`}>
      <div
        style={{
          width: CUSTOM_SIZE[size]?.width,
          height: CUSTOM_SIZE[size]?.height,
          opacity: disabled && '0.5'
        }}
        className={`vl-button${variant ? '__' + variant : ''}`}
      >
        <Icon size={CUSTOM_SIZE[size].width} className={`vl-button-icon`} />
      </div>
    </button>
  );
};

VLIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.element,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  disabled: PropTypes.bool,

  variant: PropTypes.oneOf(['borderless', 'success', 'danger', 'warning', 'switch'])
};

VLIconButton.defaultProps = {
  size: 'm'
};

export default VLIconButton;
