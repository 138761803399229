import { Modal, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import VLSelect from '../../VLSelect';
import { toast } from 'react-toastify';
import { SECURITY_CODE_NAME_MAP, SECURITY_CODES } from '../../../enums/validation';
import { localeCompare } from '../../../utils/generalUtils';
import './CreateWalletModal.scss';
import { AccountFilterContext } from '../../../contexts/AccountFilterContext';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput/VLInput';
const IGNORED_WALLET_SECURITIES = [SECURITY_CODES.VLC$, SECURITY_CODES.USD];
const isNotIgnored = code => !IGNORED_WALLET_SECURITIES.includes(code);

const walletAssets = Object.keys(SECURITY_CODES)
  .filter(isNotIgnored)
  .sort(localeCompare)
  .map(asset => ({
    value: asset.toLowerCase(),
    asset,
    name: SECURITY_CODE_NAME_MAP[asset],
    img: require(`../../../assets/svg/crypto/${asset.toLowerCase()}.svg`)
  }));

class CreateWalletModal extends PureComponent {
  state = {
    crypto: '',
    wallet: '',
    name: '',
    address: ''
  };
  static contextType = AccountFilterContext;

  componentDidUpdate(prevProps) {
    if (prevProps.errorCreateWallet !== this.props.errorCreateWallet) {
      if (this.props.errorCreateWallet?.message === 'error') {
        toast.error(this.props.errorCreateWallet?.reason);
      }
    }
    if (this.props.successCreatingWallet !== prevProps.successCreatingWallet) {
      this.props.onClose();
      this.props.getDepositAccounts();
      this.props.accountNum &&
        this.props.getAccountDetailsRequest({
          account: this.props.accountNum
        });
      toast.success(this.props.successCreatingWallet.reason);
    }
  }

  handleOnChange = (target, option) =>
    this.setState({
      [option.name]: {
        value: target.value,
        label: target.label
      }
    });
  handleOnType = ({ target }) =>
    this.setState({
      [target.name]: target.value
    });
  handleCreateWallet = () => {
    const accountNum = this.props.accountNum ?? this.props.currentAccountNumber;

    this.props.createWallet({
      'accountNum': accountNum,
      'Address': this.state.address,
      'Name': this.state.name,
      'Wallet-type': this.state.wallet.value,
      'Crypto-asset': this.state.crypto.value || this.state.crypto
    });
  };

  isDisabled = () => {
    if (this.state.wallet.value === 'Exchange' && this.state.name !== '') {
      return false;
    }

    return this.state.crypto === '' || this.state.wallet === '' || this.state.name === '' || this.state.address === '';
  };

  getCryptoDropdownData = () => [
    {
      options: walletAssets.map(({ value, asset, name, img }) => ({
        value,
        label: (
          <div className="create-wallet-asset-option" key={asset}>
            <img src={img} alt={name + ' icon'}></img>
            <span className="option-asset">{asset} - </span>
            <span className="option-name">{name}</span>
          </div>
        )
      }))
    }
  ];

  getWalletTypeDropdownData = () => [
    {
      options: [
        {
          value: 'Public Address',
          label: 'Public address'
        },
        {
          value: 'Hardware wallet',
          label: 'Hardware wallet'
        },
        {
          value: 'Other',
          label: 'Other'
        },

        {
          value: 'Exchange',
          label: 'Exchange'
        }
      ]
    }
  ];

  render() {
    return (
      <Modal className="common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>New wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <div className="system-form-control-placeholder">Wallet type</div>
            <div className="create-wallet-select-field">
              <VLSelect
                options={this.getWalletTypeDropdownData()}
                onChange={this.handleOnChange}
                value={this.state.wallet}
                name="wallet"
                placeholder="Type to find wallet/exchange"
              />
            </div>
            {this.state.wallet !== '' && this.state.wallet.value !== 'Exchange' && (
              <React.Fragment>
                <div className="system-form-control-placeholder">Crypto-asset</div>
                <div className="create-wallet-select-field">
                  <VLSelect
                    data={this.getCryptoDropdownData()}
                    options={this.getCryptoDropdownData()}
                    onChange={this.handleOnChange}
                    value={this.state.crypto}
                    name="crypto"
                    placeholder="Choose your crypto-asset"
                  />
                </div>
              </React.Fragment>
            )}

            <div className="system-form-control-placeholder">Name</div>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput onChange={this.handleOnType} value={this.state.name} size="s" name="name" type="text" />
            </div>

            {this.state.wallet !== '' && this.state.wallet.value !== 'Exchange' && (
              <React.Fragment>
                <div className="system-form-control-placeholder">Address</div>
                <div className="system-form-control-box sfcb-transparent">
                  <VLInput
                    onChange={this.handleOnType}
                    value={this.state.address}
                    size="s"
                    name="address"
                    type="text"
                  />
                </div>
              </React.Fragment>
            )}
          </Row>

          <div noGutters style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <VLButton
              width="100%"
              disabled={this.isDisabled()}
              onClick={this.handleCreateWallet}
              className="system-button-constrained external-deposit-footer-bar-primary-btn-createWallet"
              text={'Submit'}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateWalletModal;
