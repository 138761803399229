import * as ACTIONS from '../../actions/login';
import * as CONSTANTS from '../../constants/login';
import * as SOCKET_ACTIONS from '../../actions/websocket';
import { put, takeLatest } from 'redux-saga/effects';
import URLS from '../../constants/urls';
import { history } from '../../../configureStore';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import RequestBuilder from '../../../request/RequestBuilder';

function* getAccount() {
  try {
    const response = yield RequestBuilder.default()
      .get('/account')
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAccountSuccess(response));
    yield put(SOCKET_ACTIONS.connectSocket(response));
  } catch (err) {
    yield put(ACTIONS.getAccountError(err));
  }
}

function* login(action) {
  try {
    const response = yield RequestBuilder.default()
      .post('/account/login')
      .data(action.data.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.loginSuccess(response));
    yield put(SOCKET_ACTIONS.connectSocket(response.data));
    action.data.successCallback();
  } catch (err) {
    action.data.errorCallback?.(err.reason);
    if (err.response.data.reason !== 'invalid 2FA token') {
      toast.error(err.response.data.reason || 'Login currently unavailable.');
    }
    yield put(ACTIONS.loginError(err));
  }
}

function* logout() {
  try {
    yield RequestBuilder.default()
      .post('/account/logout')
      .build()
      .map(res => res.data)
      .toEffect();

    history.push('/login');
  } catch (err) {
    toast.error(err.reason);
  }
}

function* forgotPassword(action) {
  try {
    const response = yield RequestBuilder.default()
      .post('/account/forgot')
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.forgotPasswordSuccess(response));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.forgotPasswordError(err));
  }
}

function* resetPassword(action) {
  try {
    const response = yield RequestBuilder.default()
      .post('/account/reset')
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.resetPasswordSuccess(response));
    history.push('/login');
    toast.info(i18next.t('LOGIN.passwordChanged'));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.resetPasswordError(err));
  }
}

function* updateUser(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updateUserSuccess(response));
    yield put(ACTIONS.getUserProfile());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.updateUserError(err));
  }
}

function* getUserDetails(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getUserProfileSuccess(response));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getUserProfileError(err));
  }
}

function* enableTwoFactorAuth(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response);
    yield put(ACTIONS.enableTwoFactorSuccess());
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.enableTwoFactorError());
  }
}

function* disableTwoFactorAuth(action) {
  try {
    yield RequestBuilder.default()
      .post(URLS[action.type])
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback();
    yield put(ACTIONS.disableTwoFactorSuccess());
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.disableTwoFactorError());
  }
}

function* contactSupport(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data.data)
      .build()
      .map(res => res.data)
      .toEffect();

    if (response.error) {
      action.data.errorCallback(response.error);
      yield put(ACTIONS.contactSupportError());
    } else {
      action.data.successCallback(response);
      yield put(ACTIONS.contactSupportSuccess());
    }
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.contactSupportError());
  }
}

function* getUserPermissions(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getUserPermissionsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getUserPermissionsError(err));
  }
}

function* validateDeviceLocation(action) {
  try {
    const response = yield RequestBuilder.default()
      .post('/account/validateDeviceLocation')
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response);
    yield put(ACTIONS.validateDeviceLocationSuccess(response));
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.validateDeviceLocationError(err));
  }
}

export default function* loginSaga() {
  yield takeLatest(CONSTANTS.GET_ACCOUNT_REQUEST, getAccount);
  yield takeLatest(CONSTANTS.LOGIN_REQUEST, login);
  yield takeLatest(CONSTANTS.LOGOUT_REQUEST, logout);
  yield takeLatest(CONSTANTS.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(CONSTANTS.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(CONSTANTS.EDIT_PROFILE, updateUser);
  yield takeLatest(CONSTANTS.GET_USER_PROFILE, getUserDetails);
  yield takeLatest(CONSTANTS.ENABLE_TWO_FACTOR_REQUEST, enableTwoFactorAuth);
  yield takeLatest(CONSTANTS.DISABLE_TWO_FACTOR_REQUEST, disableTwoFactorAuth);
  yield takeLatest(CONSTANTS.CONTACT_SUPPORT_REQUEST, contactSupport);
  yield takeLatest(CONSTANTS.GET_USER_PERMISSIONS, getUserPermissions);
  yield takeLatest(CONSTANTS.VALIDATE_DEVICE_LOCATION_REQUEST, validateDeviceLocation);
}
