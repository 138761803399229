import { Table } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { MdDelete } from 'react-icons/md';
import GeneralConfirmModal from '../modals/GeneralConfirmModal/GeneralConfirmModal';
import { getContactTypeDisplayName } from '../../utils/contacts';
import VLButton from '../Buttons/VLButton';
import VLIconButton from '../Buttons/VLIconButton';

class ContactRequestsTable extends PureComponent {
  state = {
    showConfirmationCancelModal: false,
    showConfirmationAcceptModal: false,
    showConfirmationDeclineModal: false,
    emailToDelete: '',
    contactRequest: {}
  };
  renderHeadings = () => {
    return (
      <thead>
        <tr className="transactions-header">
          <th className="center-aligned">Name</th>
          <th className="center-aligned">Account number</th>
          <th className="center-aligned">Firm</th>
          <th className="center-aligned">Type</th>
          <th className="center-aligned">Owner name </th>
          <th className="center-aligned">Request status</th>
          <th className="center-aligned">Action</th>
        </tr>
      </thead>
    );
  };
  mapPendingContactRequests = pendingContacts => {
    const accountNumber = this.props.accountNumber;

    return pendingContacts.map(contact => {
      const type = contact?.requesterAccount.toString() === accountNumber ? 'receiver' : 'requester';
      return {
        ...contact,
        id: contact.id,
        requesterAccount: contact.requesterAccount,
        receiverAccount: contact.receiverAccount,
        accountNumber: contact?.[`${type}Account`],
        fullName: contact?.[`${type}FullName`],
        email: contact?.[`${type}Email`],
        accountName: contact?.[`${type}AccountName`],
        firm: contact?.[`${type}AccountFirm`],
        accountType: contact?.[`${type}AccountType`]
      };
    });
  };

  toggleModal = (fieldName, contactRequest) => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`],
      contactRequest
    });
  };
  getContactsTable = contacts => {
    const accountNumber = this.props.accountNumber;
    let contactsData = contacts.data;
    if (this.props.onAcceptContact) {
      contactsData = this.mapPendingContactRequests(contactsData);
    }
    return (
      <React.Fragment>
        {contactsData.length > 0 ? (
          <Table borderless responsive size="sm" className="vl-table">
            {this.renderHeadings(this.props.onAcceptContact)}
            <tbody>
              {contactsData?.map(contact => {
                const isOutbound = contact?.requesterAccount?.toString() === accountNumber;

                return (
                  <tr key={contact.accountNumber}>
                    <td>{contact?.accountName || '-'}</td>
                    <td>{contact?.accountNumber}</td>
                    <td>{contact?.firm}</td>
                    <td>{getContactTypeDisplayName(contact)}</td>
                    <td>{contact?.fullName}</td>

                    {isOutbound ? <td>Sent</td> : <td>Received</td>}
                    {isOutbound ? (
                      <React.Fragment>
                        <td>
                          <div className="contacts-delete-btn-align">
                            <VLIconButton
                              Icon={MdDelete}
                              onClick={() => this.toggleModal('ConfirmationCancel', contact)}
                              variant="borderless"
                            />
                          </div>
                        </td>
                      </React.Fragment>
                    ) : (
                      <td style={{ width: '10%' }}>
                        <div className="confrimation-button-wrapper">
                          <VLButton
                            onClick={() => this.toggleModal('ConfirmationAccept', contact)}
                            size="m"
                            variant="success"
                            text={'Accept'}
                          />

                          <VLButton
                            size="m"
                            onClick={() => this.toggleModal('ConfirmationDecline', contact)}
                            variant="danger"
                            text={'Decline'}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : null}

        {this.state.showConfirmationCancelModal && (
          <GeneralConfirmModal
            isOpen={this.state.showConfirmationCancelModal}
            title={'Cancel contact request'}
            text={'Are you sure you want to cancel this request?'}
            onConfirm={() => {
              this.toggleModal('ConfirmationCancel');
              this.props.onCancelRequest(this.state.contactRequest);
            }}
            onCancel={() => this.toggleModal('ConfirmationCancel')}
          />
        )}
        {this.state.showConfirmationAcceptModal && (
          <GeneralConfirmModal
            isOpen={this.state.showConfirmationAcceptModal}
            title={'Accept contact request'}
            text={'Are you sure you want to accept this contact request?'}
            onConfirm={() => {
              this.toggleModal('ConfirmationAccept');
              this.props.onAcceptContact(this.state.contactRequest);
            }}
            onCancel={() => this.toggleModal('ConfirmationAccept')}
          />
        )}
        {this.state.showConfirmationDeclineModal && (
          <GeneralConfirmModal
            isOpen={this.state.showConfirmationDeclineModal}
            title={'Decline Contact Request'}
            text={'Are you sure you want to decline this contact request?'}
            onConfirm={() => {
              this.toggleModal('ConfirmationDecline');
              this.props.onDeclineContact(this.state.contactRequest);
            }}
            onCancel={() => this.toggleModal('ConfirmationDecline')}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    return this.getContactsTable(this.props.contacts);
  }
}

export default ContactRequestsTable;
