import './DepositRedeem.scss';

import {
  getTradingAssets,
  getDepositAccounts,
  searchRecipientAccounts,
  sendDeposit,
  sendRedeem
} from '../../redux/actions/transactions';
import {
  makeDepositRequest,
  makeRedeemRequest,
  makeToBankRequest,
  makeToNetworkRequest,
  queryPositionsFirmDetails
} from '../../redux/actions/websocket';
import { getFirmAdminAccountsRequest } from '../../redux/actions/administration';
import DepositRedeem from './DepositRedeem';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  assets: state.transactions.tradingAssets,
  websocket: state.websocket.websocket,
  firmAccounts: state.administration.firmAdminAccounts,
  positions: state.websocket.positions
});

const mapDispatchToProps = {
  getDepositAccounts,
  getTradingAssets,
  sendDeposit,
  sendRedeem,
  makeDepositRequest,
  makeRedeemRequest,
  searchRecipientAccounts,
  makeToBankRequest,
  makeToNetworkRequest,
  queryPositionsFirmDetails,
  getFirmAdminAccountsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositRedeem);
