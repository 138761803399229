import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER } from '../../../enums/validation';
import { isWebsocketReady } from '../../../utils/websocketHelper';
import VLButton from '../../Buttons/VLButton';
import './RiskControlSettingsModal.scss';
import VLInput from '../../VLInput';

const RiskControlSettingsModal = ({
  toggleModal,
  selectedRiskSetting,
  websocket,
  accountDetails,
  updateRiskSettings,
  updateRiskSettingsTable
}) => {
  const [input, setInput] = useState(selectedRiskSetting.settingValue || '');

  const onCloseModal = () => {
    toggleModal();
  };

  const onChange = ({ target }) => {
    setInput(target.value);
  };

  const submitRiskSetting = field => {
    const inputValue = input === '' ? '0' : input;
    const dataObject = {
      [field]: inputValue
    };
    const { account, firm } = accountDetails;
    if (!isWebsocketReady(websocket)) {
      return;
    }
    updateRiskSettings({
      firm,
      account,
      socket: websocket,
      dataObject: dataObject,
      successCallback: () => {
        updateRiskSettingsTable();
        toggleModal();
      }
    });
  };

  return (
    <Modal className="common-modal" centered show onHide={onCloseModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit {selectedRiskSetting.settingName} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="risk-control-setting-input-field">
          <VLInput
            type="text"
            name={selectedRiskSetting.settingName}
            placeholder={selectedRiskSetting.settingName}
            value={input}
            maxLength={20}
            autoComplete={'off'}
            onChange={onChange}
            size="s"
          />
        </div>
        <div
          className="sfcb-button-wrapper risk-control-setting-button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <VLButton
            text={'Confirm'}
            width="100%"
            onClick={() => {
              submitRiskSetting(RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER[selectedRiskSetting.settingName]);
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RiskControlSettingsModal;
