import { Button, ButtonGroup, ButtonToolbar, Col, Row, Table } from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { formatDate, getLocalisedNumericString } from '../../utils/generalUtils';
import LoadingIndicator from '../../components/LoadingIndicator';
import Wallets from '../Wallets';
import './AccountDetails.scss';
import AccountUsers from '../AccountUser/AccountUsers';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';
import AccountPositionsOverview from '../../components/AccountPositionsOverview';
import { getDecimalByFraction } from '../../utils/transactionsHelpers';
import { FRACTIONS } from '../../enums/validation';
import RiskControlSettings from '../../components/RiskControlSettings';

const TAB_SEARCH_PARAM = 'tab';
const TABS = {
  DETAILS: 'DETAILS',
  USERS: 'USERS',
  WALLETS: 'WALLETS',
  RISK: 'RISK'
};

const normalizePositions = (positions, account, fieldName) => {
  return positions.reduce((acc, curr) => {
    const newAcc = { ...acc };
    if (!newAcc[account]) {
      newAcc[account] = {};
    }
    newAcc[account][curr.security] = curr[fieldName] ?? 0;
    return newAcc;
  }, {});
};

class AccountDetails extends PureComponent {
  state = {
    currentScreen: TABS.DETAILS
  };

  componentDidMount() {
    const tabFromUrl = new URLSearchParams(this.props.location.search).get(TAB_SEARCH_PARAM);

    this.setState(
      {
        currentScreen: tabFromUrl ?? this.state.currentScreen
      },
      () => {
        this.props.getAccountDetailsRequest({
          account: this.props.match.params.accountId
        });
        this.props.getTradingAssets();
      }
    );
  }

  handleReturn = () => {
    this.props.history.goBack();
  };

  calculateUsdTotal() {
    const { data: positionData } = this.props.accountDetails;
    const { data: assetsData } = this.props.assets;
    const positions = positionData.positions;
    const assets = assetsData;

    let total = 0;
    positions?.forEach(p => {
      const amount = p.curpos;
      const price = assets.find(a => a.security === p.security)?.currUSD ?? 0;
      total += amount * price;
    });

    return getLocalisedNumericString(total, true, getDecimalByFraction(FRACTIONS.USD));
  }

  renderAccountDetails = () => {
    const { data, loading, loaded } = this.props.accountDetails;

    if (loaded && data) {
      return (
        <Card internalSpacing="0" spacing="0px 0px 16px 0px" borderless>
          <div className="user-table">
            <Row noGutters>
              <Col className="header-col">
                <label className="section-header">Balances</label>
              </Col>

              <Col></Col>
              <Col className="account-details-balance-container">
                <div className="account-details-usd-total">
                  <div>USD Equivalent Total</div>
                  <div className="amount">{`$ ${this.calculateUsdTotal()}`}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="account-details-positions">
                <AccountPositionsOverview
                  account={data.account}
                  positions={normalizePositions(data?.positions ?? [], data.account.account, 'curpos')}
                  openPositions={normalizePositions(data?.positions ?? [], data.account.account, 'managedpos')}
                />
              </div>
            </Row>
          </div>
        </Card>
      );
    } else if (loading) {
      return <LoadingIndicator />;
    }
  };

  changeStatusScreen = screen => {
    this.setState({ currentScreen: screen });
  };

  renderAccountUser = () => {
    const { data, loaded, loading } = this.props.accountDetails;

    if (!loaded || loading) return null;

    const accountDetails = data.account;

    return (
      <div>
        <Row noGutters>
          <Col md={8} lg={8}>
            <Card internalSpacing="0" borderless>
              <div className="user-table account-details-section">
                <label className="section-header">Key details</label>
                <Table className="body" borderless responsive>
                  <tbody>
                    <tr>
                      <th className="td-main">
                        <div className="td-main">Pseudo account number</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.name}</label>
                      </td>
                      <th className="td-main">
                        <div className="td-main">Tier</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.bta_tier ?? '-'}</label>
                      </td>
                    </tr>

                    <tr>
                      <th className="td-main">
                        <div className="td-main">Type</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.type}</label>
                      </td>
                      <th className="td-main">
                        <div className="td-main">Risk score</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.bta_risk_score ?? '-'}</label>
                      </td>
                    </tr>

                    <tr>
                      <th className="td-main">
                        <div className="td-main">No. of users</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{data.users.length}</label>
                      </td>
                      <th className="td-main">
                        <div className="td-main">Crypto-Asset net worth</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.cryptoNetWorth ?? '-'}</label>
                      </td>
                    </tr>

                    <tr>
                      <th className="td-main">
                        <div className="td-main">Product</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.product}</label>
                      </td>
                      <th className="td-main">
                        <div className="td-main">Experience owning Crypto-Assets</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.expOwningCrypto ?? '-'}</label>
                      </td>
                    </tr>

                    <tr>
                      <th className="td-main">
                        <div className="td-main">Opened date</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">
                          {accountDetails.attr.dateCreated ? formatDate(accountDetails.attr.dateCreated) : '-'}
                        </label>
                      </td>
                      <th className="td-main">
                        <div className="td-main">Primary use</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.primaryUse ?? '-'}</label>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>

          <Col md={4} lg={4}>
            <Card internalSpacing="0" borderless>
              <div className="user-table account-details-section">
                <label className="section-header">Anticipated activity</label>
                <Table className="body" borderless responsive>
                  <tbody>
                    <tr>
                      <th className="td-main">
                        <div className="td-main">Deposits per month valued in USD</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.monthlyDepositValue ?? '-'}</label>
                      </td>
                    </tr>
                    <tr>
                      <th className="td-main">
                        <div className="td-main">Number of deposits per month</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.monthlyDepositNumber ?? '-'}</label>
                      </td>
                    </tr>
                    <tr>
                      <th className="td-main">
                        <div className="td-main">Withdrawals per month valued in USD</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.monthlyWithdrawalValue ?? '-'}</label>
                      </td>
                    </tr>
                    <tr>
                      <th className="td-main">
                        <div className="td-main">Number of withdrawals per month</div>
                      </th>
                      <td className="td-content">
                        <label className="td-content">{accountDetails.attr.monthlyWithdrawalNumber ?? '-'}</label>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  renderBody = () => {
    const { data, loading } = this.props.accountDetails;

    switch (this.state.currentScreen) {
      case TABS.DETAILS:
        return (
          <Row>
            <Col md={1} lg={1}></Col>
            <Col md={10} lg={10}>
              <div className="firm-details-firm">{this.renderAccountDetails()}</div>
              <div className="firm-details-user">{this.renderAccountUser()}</div>
            </Col>
            <Col md={1} lg={1}></Col>
          </Row>
        );

      case TABS.USERS:
        return (
          <AccountUsers
            account={data?.account}
            users={data?.users || []}
            loading={loading}
            onChange={() => {
              this.props.getAccountDetailsRequest({
                account: this.props.match.params.accountId
              });
            }}
          />
        );

      case TABS.WALLETS:
        return <Wallets accountObj={this.props.accountDetails?.data?.account} />;

      case TABS.RISK:
        return <RiskControlSettings />;
      default:
        return <></>;
    }
  };

  render() {
    const { data, loading } = this.props.accountDetails;
    if (data) {
      return (
        <React.Fragment>
          <Row noGutters onClick={this.handleReturn} className="return-button ">
            <MdKeyboardBackspace />
            {'Return'}
          </Row>
          <div className="account-details-container">
            <div className="firm-details chassis-interior-content">
              <div className="account-details-title">
                <PageTitle text={`Account no. ${data?.account?.account ?? ''}`} />
              </div>
              <div className="account-details-tabs">
                <ButtonToolbar className="button-container">
                  <ButtonGroup className="button-group">
                    <Button
                      active={this.state.currentScreen === TABS.DETAILS}
                      type={'button btn btn-primary'}
                      onClick={() => this.changeStatusScreen(TABS.DETAILS)}
                      className="item-button-small"
                    >
                      Details
                    </Button>
                    <Button
                      className="item-button-small"
                      active={this.state.currentScreen === TABS.USERS}
                      onClick={() => this.changeStatusScreen(TABS.USERS)}
                    >
                      Users
                    </Button>
                    <Button
                      className="item-button-small"
                      active={this.state.currentScreen === TABS.WALLETS}
                      onClick={() => this.changeStatusScreen(TABS.WALLETS)}
                    >
                      Wallets
                    </Button>
                    <Button
                      className="item-button-small"
                      active={this.state.currentScreen === TABS.RISK}
                      onClick={() => this.changeStatusScreen(TABS.RISK)}
                    >
                      Risk settings
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
              {this.renderBody()}
              <div className="account-details-loading">
                {loading && this.state.currentScreen === TABS.DETAILS && <LoadingIndicator />}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <LoadingIndicator />;
    }
  }
}

export default AccountDetails;
