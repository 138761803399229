export const FETCH_ANALYTICS_DATA_ALL = 'FETCH_ANALYTICS_DATA_ALL';
export const FETCH_ANALYTICS_DATA_ALL_SUCCESS = 'FETCH_ANALYTICS_DATA_ALL_SUCCESS';
export const FETCH_ANALYTICS_DATA_ALL_ERROR = 'FETCH_ANALYTICS_DATA_ALL_ERROR';

export const FETCH_SPECIFIC_ANALYTICS_DATA = 'FETCH_SPECIFIC_ANALYTICS_DATA';
export const EXPORT_TRANSACTIONS = 'EXPORT_TRANSACTIONS';
export const FETCH_SPECIFIC_ANALYTICS_DATA_SUCCESS = 'FETCH_SPECIFIC_ANALYTICS_DATA_SUCCESS';
export const FETCH_SPECIFIC_ANALYTICS_DATA_ERROR = 'FETCH_SPECIFIC_ANALYTICS_DATA_ERROR';
export const GET_TRANSACTION_BY_ID = 'GET_TRANSACTION_BY_ID';
export const GET_TRANSACTION_BY_ID_SUCCESS = 'GET_TRANSACTION_BY_ID_SUCCESS';
export const GET_TRANSACTION_BY_ID_ERROR = 'GET_TRANSACTION_BY_ID_ERROR';
