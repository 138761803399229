import * as CONSTANTS from '../constants/trade';

export const fetchChartData = data => {
  return {
    type: CONSTANTS.FETCH_CHART_DATA,
    data
  };
};

export const fetchChartDataSuccess = data => ({
  type: CONSTANTS.FETCH_CHART_DATA_SUCCESS,
  data
});

export const fetchChartDataError = error => ({
  type: CONSTANTS.FETCH_CHART_DATA_ERROR,
  error
});

export const fetch24hData = data => {
  return {
    type: CONSTANTS.FETCH_24H_MARKET_DATA,
    data
  };
};

export const fetch24hDataSuccess = data => ({
  type: CONSTANTS.FETCH_24H_MARKET_DATA_SUCCESS,
  data
});

export const fetch24hDataError = error => ({
  type: CONSTANTS.FETCH_24H_MARKET_DATA_ERROR,
  error
});

export const fetch24hDataForAllAssets = data => {
  return {
    type: CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST,
    data
  };
};

export const fetch24hDataForAllAssetsSuccess = data => ({
  type: CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_SUCCESS,
  data
});

export const fetch24hDataForAllAssetsError = data => ({
  type: CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_ERROR,
  data
});

export const updateAssetPair = data => ({
  type: CONSTANTS.UPDATE_ASSET_PAIR,
  data
});
export const fetchAssetPairs = data => {
  return {
    type: CONSTANTS.FETCH_TRADE_ASSET_PAIRS,
    data: {
      type: 'PAIRS'
    }
  };
};

export const fetchAssetPairsSuccess = data => ({
  type: CONSTANTS.FETCH_TRADE_ASSET_PAIRS_SUCCESS,
  data
});

export const fetchAssetPairsError = error => ({
  type: CONSTANTS.FETCH_TRADE_ASSET_PAIRS_ERROR,
  error
});

export const setSelectedIoi = data => ({
  type: CONSTANTS.SET_SELECTED_IOI,
  data
});

export const setSelectedLmt = data => ({
  type: CONSTANTS.SET_SELECTED_LMT,
  data
});

export const addIOI = data => ({
  tyoe: CONSTANTS.ADD_IOI,
  data
});

export const getIOI = data => ({
  type: CONSTANTS.GET_IOI,
  data
});
export const getIOISuccess = data => ({
  type: CONSTANTS.GET_IOI_SUCCESS,
  data
});
export const getIOIError = error => ({
  type: CONSTANTS.GET_IOI_ERROR,
  error
});
