import { connect } from 'react-redux';
import { fetchChartData } from '../../redux/actions/trade';
import TradeChart from './TradeChart';

const mapStateToProps = state => ({
  mode: state.settings.mode,
  chartData: state.trade.chartData,
  selectedPair: state.trade.selectedPair
});

const mapDispatchToProps = {
  fetchChartData
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeChart);
