/* eslint-disable import/no-anonymous-default-export */
import * as CONSTANTS from '../constants/trade';

const DEFAULT_ASSET_PAIR = 'BTCUSD';

const initialState = {
  selectedPair: undefined,
  selectedIOI: null,
  ioiAccountNumber: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  selectedLMT: null,
  chartData: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  marketData24: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  marketData24All: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  assetPairs: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_CHART_DATA:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          data: [...action.data.data.prices],
          loading: false,
          loaded: true,
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_CHART_DATA_ERROR:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST:
      return {
        ...state,
        marketData24All: {
          ...state.marketData24All,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        marketData24All: {
          ...state.marketData24All,
          data: action.data.resultFinal,
          loading: false,
          loaded: true,
          error: ''
        }
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_ERROR:
      return {
        ...state,
        marketData24All: {
          ...state.marketData24All,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA:
      return {
        ...state,
        marketData24: {
          ...state.marketData24,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA_SUCCESS:
      return {
        ...state,
        marketData24: {
          ...state.marketData24,
          data: action.data.data,
          loading: false,
          loaded: true,
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_24H_MARKET_DATA_ERROR:
      return {
        ...state,
        marketData24: {
          ...state.marketData24,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    case CONSTANTS.UPDATE_ASSET_PAIR:
      return {
        ...state,
        selectedPair: action.data
      };
    case CONSTANTS.FETCH_TRADE_ASSET_PAIRS:
      return {
        ...state,
        assetPairs: {
          ...state.assetPairs,
          loading: true,
          loaded: false,
          error: ''
        }
      };
    case CONSTANTS.FETCH_TRADE_ASSET_PAIRS_SUCCESS:
      return {
        ...state,
        selectedPair: state.selectedPair
          ? state.selectedPair
          : action.data.data.find(pair => pair.security === DEFAULT_ASSET_PAIR) ?? action.data.data[0],
        assetPairs: {
          ...state.assetPairs,
          data: [...action.data.data],
          loading: false,
          loaded: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_TRADE_ASSET_PAIRS_ERROR:
      return {
        ...state,
        assetPairs: {
          ...state.assetPairs,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    case CONSTANTS.SET_SELECTED_IOI:
      return {
        ...state,
        selectedIOI: action.data
      };
    case CONSTANTS.SET_SELECTED_LMT:
      return {
        ...state,
        selectedLMT: action.data
      };
    case CONSTANTS.GET_IOI:
      return {
        ...state,
        ioiAccountNumber: {
          ...state.ioiAccountNumber,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_IOI_SUCCESS:
      return {
        ...state,
        ioiAccountNumber: {
          ...state.ioiAccountNumber,
          data: action.data.data.accountNumber,
          loading: false,
          loaded: true,
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.GET_IOI_ERROR:
      return {
        ...state,
        ioiAccountNumber: {
          ...state.ioiAccountNumber,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    default:
      return state;
  }
};
