import React, { useEffect, useState } from 'react';
import { getTotalUsdEquivalent } from '../../../utils/generalUtils';
import './DashboardAccountUsdEquivalent.scss';
import AdvancedChartRange from '../../Charts/AdvancedChart/AdvancedChartRange';
import { FaArrowUp as UpArrow, FaArrowDown as DownArrow } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';
import MoneyFormat, { Currency } from '../../../utils/MoneyFormat';

const formatter = MoneyFormat.builder().currency(Currency.USD).build();

const DashboardAccountUsdEquivalent = ({
  timeframe,
  preview,
  positions,
  assets,
  balance,
  accounts = [],
  currentAccountNumber
}) => {
  const [totalUsd, setTotalUsd] = useState(0);
  const [changeData, setChangeData] = useState({});
  const [previewChangeData, setPreviewChangeData] = useState({});

  /**
   * Finds the datapoint in `data` array that is first before the start of the timeframe.
   * E.g. timeframe is 1 day, and the data is 1 hour apart, then this method returns
   * the datapoint that is first after the date 24 hours (1 day) ago from now.
   *
   * @param {{ts: string, totalUSD: number}[]} data - Array of data points.
   * @param {AdvancedChartRange} timeframe - timeframe interval.
   * @return {{ts: string, totalUSD: number} | undefined} - The datapoint first before the start of the timeframe.
   */
  const getClosestDataPoint = (data, timeframe) => {
    if (!data || data.length === 0) return undefined;

    const reversed = [...(data ?? [])].reverse();
    return (
      reversed.find(elem => {
        const elemTime = new Date(elem.ts).getTime();
        return elemTime <= timeframe.getStart().getTime();
      }) ?? { ts: timeframe.getStart(), totalUSD: 0 }
    );
  };

  /**
   * Calculates the change in USD value between the start and current USD values.
   *
   * @param {number} start
   * @param {number} current
   * @return {{change: number, percent: number}}
   */
  const getChange = (start, current) => {
    // If the start is 0 we assume that we can just show 100% change.
    const percent = start === 0 ? 100 : ((current - start) / start) * 100;
    const change = current - start;
    return { percent, change };
  };

  useEffect(() => {
    if (!currentAccountNumber) {
      setChangeData({});
      return;
    }

    const closestDataPoint = getClosestDataPoint(balance.balance.data[currentAccountNumber], timeframe);
    setChangeData(getChange(closestDataPoint?.totalUSD ?? 0, totalUsd));
  }, [timeframe, balance, currentAccountNumber, totalUsd]);

  useEffect(() => {
    if (!currentAccountNumber || !preview) {
      setPreviewChangeData({});
      return;
    }

    setPreviewChangeData(getChange(preview?.value ?? 0, totalUsd));
  }, [preview]);

  useEffect(() => {
    setTotalUsd(getTotalUsdEquivalent(positions, accounts, assets, currentAccountNumber));
  }, [positions, accounts, assets, currentAccountNumber]);

  return (
    <div className="DashboardAccountUsdEquivalent">
      <h5>Your balance</h5>
      <h2 className="amount">{formatter.format(preview?.value ?? totalUsd)}</h2>
      <Change
        percent={preview ? previewChangeData.percent : changeData.percent}
        change={preview ? previewChangeData.change : changeData.change}
        timeframe={preview?.time ?? timeframe}
      />
    </div>
  );
};

const Change = ({ change, percent, timeframe }) => {
  if (!change || !percent) return null;

  /**
   * @typedef IconType = React.Element | () => any
   * @param value {number} - the value to format.
   * @return {{icon: IconType, className: string}} - color class for change value.
   */
  const getFormat = value => {
    if (value < 0) {
      return { className: 'red', icon: DownArrow };
    }
    if (value > 0) {
      return { className: 'green', icon: UpArrow };
    }

    return { className: '', icon: () => null };
  };

  /**
   * Returns a formatted text representing the timeframe for the given balance change.
   *
   * @param timeframe {AdvancedChartRange|number} - The timeframe to get the label for.
   * Can Be a AdvancedChartRange object or a number representing the timeframe in milliseconds.
   * @return {string} - The label for the timeframe.
   */
  const getText = timeframe => {
    if (typeof timeframe === 'number') return moment(new Date(timeframe)).fromNow(true) + ' change';

    switch (timeframe.value) {
      case AdvancedChartRange.DAY.value:
        return 'One day change';
      case AdvancedChartRange.WEEK.value:
        return 'One week change';
      case AdvancedChartRange.MONTH.value:
        return 'One month change';
      case AdvancedChartRange.THREE_MONTHS.value:
        return 'Three month change';
      case AdvancedChartRange.SIX_MONTHS.value:
        return 'Six months change';
      case AdvancedChartRange.YEAR.value:
        return 'One year change';
      case AdvancedChartRange.ALL.value:
        return 'All time';
      default:
        return '';
    }
  };

  const { icon: Icon, className: color } = getFormat(change);

  return (
    <div className="change">
      <h6>
        <span className={'change__icon ' + color}>
          <Icon />
        </span>
        &nbsp;
        <span className={'change__change ' + color}>{formatter.format(change)}</span>
        &nbsp;
        <span className={'change__percent ' + color}>
          ({MoneyFormat.round(percent)}%)
        </span>
        <span className="change__text">&nbsp;{getText(timeframe)}</span>
      </h6>
    </div>
  );
};

Change.propTypes = {
  /** The change in USD value. */
  change: PropTypes.number,

  /** The change in percent. */
  percent: PropTypes.number,

  /** The timeframe for the change. */
  timeframe: PropTypes.oneOfType([PropTypes.instanceOf(AdvancedChartRange), PropTypes.number])
};

DashboardAccountUsdEquivalent.propTypes = {
  /** The timeframe to use for calculating the change. */
  timeframe: PropTypes.instanceOf(AdvancedChartRange).isRequired,

  /** The preview data to use for the USD equivalent when a certain datapoint
   * in the chart is being hovered. */
  preview: PropTypes.shape({
    value: PropTypes.number,
    time: PropTypes.instanceOf(AdvancedChartRange)
  }),

  /** The positions stored in redux. */
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** The assets stored in redux. */
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** The balance stored in redux. */
  balance: PropTypes.object.isRequired,

  /** The accounts stored in redux. */
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DashboardAccountUsdEquivalent;
