import './LoadingWrapper.scss';

import { MdErrorOutline } from 'react-icons/md';
import React, { PureComponent } from 'react';

import LoadingIndicator from '../LoadingIndicator';
import i18next from 'i18next';
import VLIconButton from '../Buttons/VLIconButton';
const DEFAULT_WAIT_SECONDS = 60;
class LoadingWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.intervalCall = null;
  }
  state = {
    timerDone: false
  };
  componentDidUpdate() {
    if (this.props.isLoading && !this.intervalCall) {
      this.setState({ timerDone: false }, () =>
        this.startTimer(this.props.waitDurationSeconds || DEFAULT_WAIT_SECONDS)
      );
    }

    if (!this.props.isLoading) {
      clearInterval(this.intervalCall);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalCall);
  }

  startTimer = duration => {
    let timer = duration,
      minutes,
      seconds;
    clearInterval(this.intervalCall);
    this.intervalCall = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      console.log(`TIMER ${minutes}:${seconds} ${this.intervalCall}`);
      if (--timer < 0) {
        timer = duration;
        this.setState(
          {
            timerDone: true
          },
          () => clearInterval(this.intervalCall)
        );
      }
    }, 1000);
  };

  renderIndicatorOrMessage = (forceErrorOverlay = false) => {
    const { withReloadBtn, reloadFunction } = this.props;

    if (forceErrorOverlay || this.state.timerDone) {
      return (
        <div className="loading-indicator">
          <div className="loading-indicator-icon-wrap">
            <MdErrorOutline className="loading-indicator-icon loading-indicator-icon--alert" />
            <h2 className="loading-indicator-title">{i18next.t('loadingWrapper.errorMsg')}</h2>
          </div>
          <p className="loading-indicator-text">{i18next.t('loadingWrapper.issue')}</p>
          {withReloadBtn && reloadFunction && (
            <div>
              <p className="loading-indicator-text">{i18next.t('loadingWrapper.button')}</p>
              <VLIconButton onClick={reloadFunction} />
            </div>
          )}
        </div>
      );
    } else if (!this.state.timerDone) return <LoadingIndicator styleClass={this.props.styleClass} />;
  };

  render() {
    const { isLoading, children } = this.props;
    if (this.props.debugTimeout) {
      return this.renderIndicatorOrMessage(true);
    }
    return isLoading ? this.renderIndicatorOrMessage() : <>{children}</>;
  }
}

export default LoadingWrapper;
