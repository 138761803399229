import React, { useState } from 'react';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { Row, Col, Table, Form } from 'react-bootstrap';
import i18next from 'i18next';
import { BsPlus } from 'react-icons/bs';
import CssFilterTransition from '../../../components/CssFilterTransition';
import {
  getTimeLock,
  hasApproveRights,
  hasTimeLock,
  hasTransactRights,
  hasViewRights
} from '../../../utils/roleCheckers';
import { AUTHORIZATION_KEYS } from '../../../enums/roles';
import TimeLockModal from '../../../components/modals/TimeLockModal';
import { TiDelete } from 'react-icons/ti';
import GeneralConfirmModal from '../../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import { useAccountPermissions } from '../useAccountPermissions';
import SearchAccountsModal from '../../../components/modals/SearchAccountsModal';
import { getAccountTypeDisplayName } from '../../../utils/account';
import './UserAccounts.scss';
import VLButton from '../../../components/Buttons/VLButton';
import VLIconButton from '../../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import { AiOutlineClockCircle } from 'react-icons/ai';
import Card from '../../Card';
import PageTitle from '../../../components/PageTitle';
import { IoMdAdd } from 'react-icons/io';
import VLInput from '../../../components/VLInput';

const filterByName = (accounts, query) => {
  return accounts.filter(a => {
    if (!a.attr?.name) return false;

    return a.attr.name.toLowerCase().includes(query.toLowerCase());
  });
};

const filterByNumber = (accounts, query) => accounts.filter(a => a.account.toLowerCase().includes(query.toLowerCase()));

const UserAccounts = ({ user, accounts, firm, onChange, updatePermissions, history, canEditAccounts }) => {
  const {
    timelockAccessData,
    removeAccessData,
    handleAccessSwitch,
    handleRemoveAllPermissions,
    handleAddViewPermission,
    setTimelockAccessData,
    removeTimeLock,
    setRemoveAccessData,
    updateTimeLock
  } = useAccountPermissions({
    onPermissionsUpdated: onChange,
    updatePermissionsFn: updatePermissions
  });
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);

  const clearFilters = () => {
    setFilters({});
  };

  const redirectToAccountDetails = (account, tab = null) => {
    const newEntry = {
      pathname: `/accounts/${account.account}`,
      search: tab ? 'tab=RISK' : undefined
    };

    history.push(newEntry);
  };

  const getFilteredAccounts = accounts => {
    let filteredAccounts = [...accounts];

    if (filters.nameSearch) {
      filteredAccounts = filterByName(filteredAccounts, filters.nameSearch);
    }
    if (filters.numberSearch) {
      filteredAccounts = filterByNumber(filteredAccounts, filters.numberSearch);
    }

    return filteredAccounts;
  };

  const handleSearchFilter = e => {
    setFilters({
      [e.target.name]: e.target.value
    });
  };

  const resetConfirmation = () => {
    setRemoveAccessData(null);
  };

  const handleAddAccount = accountId => {
    setShowAddAccount(false);
    handleAddViewPermission(user.userid, accountId);
  };

  const renderHeaderBar = () => {
    return (
      <Row className="user-control-container transactions-filters-container row no-gutters">
        <PageTitle text="Accounts" styleClass="dashboard-transactions-header" />{' '}
        <div className="inline-justifier">
          <VLIconButton
            Icon={FaFilter}
            onClick={() => {
              setShowFilters(s => !s);
            }}
          />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>
      </Row>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        <div className="administration-filter">
          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Pseudo account number</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="nameSearch"
                value={filters.nameSearch}
                onChange={handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>

          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Number</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="numberSearch"
                value={filters.number}
                onChange={handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>
        </div>

        <VLButton
          text={i18next.t('transactionsFilters.clearFilter')}
          variant="clear"
          size={'m'}
          rightIcon={<BsPlus />}
          onClick={clearFilters}
        />
      </div>
    );
  };

  const renderAccount = account => {
    const type = getAccountTypeDisplayName(account);

    return (
      <tr key={account.account}>
        <td>
          <div className="align-left">{account.attr?.name}</div>
        </td>
        <td>
          <div className="align-left">{account.account}</div>
        </td>
        <td>
          <div>{type || '-'}</div>
        </td>
        <td>
          <div className="align-left">{account.restricted_attr?.access_list?.length || 0}</div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              id={`${AUTHORIZATION_KEYS.VIEW}|${user.userid}|${account.account}`}
              checked={hasViewRights(account, user)}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              checked={hasTransactRights(account, user)}
              id={`${AUTHORIZATION_KEYS.TRANSACTION}|${user.userid}|${account.account}`}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              checked={hasApproveRights(account, user)}
              id={`${AUTHORIZATION_KEYS.APPROVE}|${user.userid}|${account.account}`}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div className="align-center">
            <VLIconButton
              Icon={AiOutlineClockCircle}
              onClick={() => {
                setTimelockAccessData({ user, account });
              }}
            />
            {hasTimeLock(user, account) ? (
              <VLIconButton
                Icon={TiDelete}
                onClick={() => removeTimeLock(user.userid, account.account)}
                variant="borderless"
              />
            ) : null}
          </div>
        </td>
        <td>
          <div className="align-center">
            <VLIconButton
              Icon={MdRemoveRedEye}
              variant="borderless"
              onClick={() => redirectToAccountDetails(account)}
            />
          </div>
        </td>
        {canEditAccounts && (
          <td>
            <div className="align-center">
              <VLIconButton
                onClick={() => setRemoveAccessData({ user, account })}
                Icon={MdDelete}
                variant="borderless"
              />
            </div>
          </td>
        )}
      </tr>
    );
  };

  const accountsList = getFilteredAccounts(accounts);

  return (
    <Card>
      <Row noGutters className="firm-item">
        <Col>
          {renderHeaderBar()}
          <CssFilterTransition component={renderFilters} isOpen={showFilters} />
          <div>
            {accountsList.length ? (
              <Table className="vl-table" borderless responsive>
                <thead>
                  <tr>
                    <th className="align-left">Pseudo Account Number</th>
                    <th className="align-left">Number</th>
                    <th>Type</th>
                    <th className="align-left">No. of Users</th>
                    <th>View</th>
                    <th>Transact</th>
                    <th>Approve</th>
                    <th>{i18next.t('firmDetails.timeLock')}</th>
                    <th>{i18next.t('firmDetails.details')}</th>
                    {canEditAccounts && <th>Delete</th>}
                  </tr>
                </thead>
                <tbody>{accountsList.map(user => renderAccount(user))}</tbody>
              </Table>
            ) : null}

            {!accountsList.length ? (
              <Row noGutters className="centered-row">
                <span className="accounts-table-empty-text">There are no accounts that match the criteria</span>
              </Row>
            ) : null}
          </div>
          <Row noGutters className="centered-row">
            {canEditAccounts && (
              <VLButton
                onClick={() => {
                  setShowAddAccount(true);
                }}
                size="l"
                text="Add account
               "
                rightIcon={<IoMdAdd />}
              />
            )}
          </Row>
          {removeAccessData && (
            <GeneralConfirmModal
              title={'Remove Account'}
              text={'Are you sure you want to remove user rights for this account?'}
              onConfirm={() => {
                handleRemoveAllPermissions({
                  userId: removeAccessData.user.userid,
                  accountId: removeAccessData.account.account
                });
              }}
              onCancel={resetConfirmation}
              isOpen={removeAccessData}
            />
          )}
          {timelockAccessData && (
            <TimeLockModal
              onClose={() => setTimelockAccessData(null)}
              timeString={getTimeLock(timelockAccessData.user, timelockAccessData.account)}
              onSaveClick={(start, end, days) => updateTimeLock(start, end, days)}
            />
          )}
          {showAddAccount && (
            <SearchAccountsModal
              title="Search accounts"
              submitText={'Add'}
              ignoreAccounts={accounts}
              firm={firm}
              isOpen={showAddAccount}
              onClose={() => {
                setShowAddAccount(false);
              }}
              onComplete={handleAddAccount}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
export default UserAccounts;
