import QMS from './QMS';
import { connect } from 'react-redux';
import { setSelectedIoi, getIOI } from '../../../redux/actions/trade';
const mapStateToProps = state => ({
  allBooks: state.websocket.allBooks,
  openOrders: state.websocket.openOrders,
  assetPair: state.trade.selectedPair
});

const mapDispatchToProps = {
  setSelectedIoi: setSelectedIoi,
  getIOI
};

export default connect(mapStateToProps, mapDispatchToProps)(QMS);
