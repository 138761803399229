import BuySellModal from './BuySellModal';
import { connect } from 'react-redux';
import { toggleModal } from '../../../redux/actions/transactions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(BuySellModal);
