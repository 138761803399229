import { Table } from 'react-bootstrap';
import {
  formatDate,
  sortByDateDescending,
  getLocalisedNumericString,
  extractAssetsFromSecurityCode
} from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import './OrderHistoryTable.scss';

const OrderHistoryTable = ({ orderHistory }) => {
  return (
    <div className="overflow">
      {orderHistory.length > 0 ? (
        <Table className="order-history-table" responsive borderless>
          <thead>
            <tr className="order-blotter-tables__th">
              <th>Asset</th>
              <th>Side</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Maker/Taker</th>
              <th>Fee</th>
              <th>Time</th>
            </tr>
          </thead>

          <tbody>
            {sortByDateDescending(orderHistory)?.map(order => {
              const assets = extractAssetsFromSecurityCode(order.security);

              return (
                <tr key={order.refno} className="order-blotter-tables__td">
                  <td>{order.security}</td>
                  <td className={`order-blotter-tables__${order.side}`}>{order.side}</td>
                  <td>
                    {getLocalisedNumericString(order.execqty, false, getDecimalByFraction(FRACTIONS[assets?.base]))}
                  </td>
                  <td>
                    {' '}
                    {getLocalisedNumericString(order.execprice, false, getDecimalByFraction(FRACTIONS[assets?.quote]))}
                  </td>
                  <td>{order.ismaker ? 'Maker' : 'Taker'}</td>
                  <td>{order.fee || 'N/A'}</td>
                  <td>{formatDate(new Date(parseInt(order.time)))}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="no-orders-msg">No order history</div>
      )}
    </div>
  );
};
export default OrderHistoryTable;
