/* eslint-disable import/no-anonymous-default-export */
import * as CONSTANTS from '../constants/settings';
import * as LOGIN_CONSTANTS from '../constants/login';

import { DARK_MODE, LIGHT_MODE } from '../../enums/validation';
import { getColorMode, setColorMode } from '../../utils/generalUtils';

const initialState = {
  errorCreatingWallet: {},
  successCreatingWallet: {},
  deleteWalletResponse: {},
  users: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  userDetails: {
    data: null,
    loading: false,
    loaded: false,
    error: ''
  },
  accounts: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  accountSearchResults: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  firms: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  mode: !getColorMode() ? LIGHT_MODE : getColorMode()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.FETCH_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.FETCH_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.GET_FIRM_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_FIRM_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.SEARCH_ACCOUNTS:
      return {
        ...state,
        accountSearchResults: {
          ...state.accountSearchResults,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.SEARCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountSearchResults: {
          ...state.accountSearchResults,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };
    case CONSTANTS.DELETE_WALLET_SUCCESS:
      return {
        ...state,
        deleteWalletResponse: action.data
      };

    case CONSTANTS.DELETE_WALLET_ERROR:
      return {
        ...state,
        deleteWalletResponse: action.err
      };

    case CONSTANTS.SEARCH_ACCOUNTS_ERROR:
      return {
        ...state,
        accountSearchResults: {
          ...state.accountSearchResults,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.SEARCH_FIRMS:
      return {
        ...state,
        firms: {
          ...state.firms,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.SEARCH_FIRMS_SUCCESS:
      return {
        ...state,
        firms: {
          ...state.firms,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.SEARCH_FIRMS_ERROR:
      return {
        ...state,
        firms: {
          ...state.firms,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    case CONSTANTS.CREATE_WALLET_ERROR:
      return {
        ...state,
        errorCreatingWallet: action.err
      };

    case CONSTANTS.CREATE_WALLET_SUCCESS:
      return {
        ...state,
        successCreatingWallet: action.data
      };
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.TOGGLE_DARK_MODE:
      setColorMode(state.mode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE);
      return {
        ...state,
        mode: state.mode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE
      };

    case LOGIN_CONSTANTS.LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
