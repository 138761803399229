import { Modal, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { isValidEmailFormat, onlyNumbers } from '../../../utils/validators';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput/VLInput';
class EditUserProfile extends PureComponent {
  state = {
    data: {
      email: '',
      first: '',
      last: '',
      phone: '',
      image: ''
    },
    emailError: ''
  };

  componentDidMount() {
    this.setState({
      data: {
        ...this.props.data
      }
    });
  }

  handleOnChange = ({ target }) => {
    const isPhoneNumber = target.name === 'phone';
    const value = isPhoneNumber ? onlyNumbers(target.value) : target.value;
    this.setState({
      data: { ...this.state.data, [target.name]: value }
    });
  };

  checkEmailFormat = () => {
    this.setState({ emailError: !isValidEmailFormat(this.state.data.email) });
  };

  getFiles = image => {
    console.log('EditUserProfile: getFiles', image);
    this.setState({
      data: {
        ...this.state.data,
        image: image.base64
      }
    });
  };

  handleUpdateUser = () => {
    this.props.onSuccess(this.state.data);
  };

  render() {
    const { email, first, last, phone } = this.state.data;
    const disabled = email && first && last && !this.state.emailError ? false : true;
    return (
      <Modal className="common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('editProfile.updateProfile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <p className="system-form-control-placeholder">
              {i18next.t('editProfile.eMail')} <span className="system-form-control-error-text">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput
                onChange={this.handleOnChange}
                value={email}
                size="s"
                name="email"
                type="email"
                onBlur={this.checkEmailFormat}
                error={this.state.emailError}
              />
              {this.state.emailError && (
                <span className="modal-form-control-error-text">{i18next.t('editProfile.invalidEmail')}</span>
              )}
            </div>
            <p className="system-form-control-placeholder">{i18next.t('editProfile.phoneNo')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput onChange={this.handleOnChange} value={phone} size="s" name="phone" type="text" />
            </div>
          </Row>
          <Row noGutters>
            <p className="system-form-control-placeholder">
              {i18next.t('editProfile.firstName')} <span className="system-form-control-error-text">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput onChange={this.handleOnChange} value={first} size="s" name="first" type="text" />
            </div>
            <p className="system-form-control-placeholder">
              {i18next.t('editProfile.lastName')} <span className="system-form-control-error-text">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput onChange={this.handleOnChange} value={last} size="s" name="last" type="text" />
            </div>
          </Row>
          <Row className="external-deposit-footer-bar row">
            <VLButton
              disabled={disabled}
              onClick={this.handleUpdateUser}
              text={i18next.t('editProfile.update')}
              width="100%"
            />
            {/* external-deposit-footer-bar-primary-btn */}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditUserProfile;
