import CryptoAssetChart from './CryptoAssetChart';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  mode: state.settings.mode
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CryptoAssetChart);
