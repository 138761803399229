import React, { PureComponent } from 'react';
import { Row, Spinner } from 'react-bootstrap';

class LoadingIndicator extends PureComponent {
  render() {
    return (
      <Row
        noGutters
        className={`app-spinner ${this.props.styleClass ? this.props.styleClass : ''}
      `}
      >
        <Spinner animation="border" variant="secondary" />
      </Row>
    );
  }
}

export default LoadingIndicator;
