export const mapFilterDepositWithdrawalUSD = state => {
  const startRange = state.dates !== undefined && state.dates.split('&')[0];

  const endRange = state.dates !== undefined && state.dates.split('&')[1];

  return {
    ...(state.txnid !== '' && {
      id: state.txnid
    }),
    ...(state.accountnumber !== '' && {
      account: state.accountnumber
    }),
    ...(state.BM_Status?.value !== '' && {
      status: state.BM_Status?.value?.toUpperCase()
    }),
    ...(state.Type.value !== '' && {
      type: state.Type.value?.toUpperCase()
    }),
    ...(startRange !== undefined &&
      endRange !== undefined && {
        from: startRange,
        to: endRange
      }),
    page: '0,1000,requestedAt'
  };
};
export const mapFilterExternalTransactionsData = state => {
  return {
    ...(state.txnid !== '' && {
      transaction_id: state.txnid
    }),

    ...(state.accountnumber !== '' && {
      account: state.accountnumber
    }),
    ...(state.Type !== '' && {
      type: state.Type.value
    }),
    ...(state.Asset !== '' && {
      asset: state.Asset.value.toLowerCase()
    }),
    ...(state.BM_Status !== '' && { status: state.BM_Status.value }),
    ...(state?.BTA_Status !== '' &&
      state?.BTA_Status.value !== undefined && {
        'bta-status': state?.BTA_Status.value
      }),
    ...(state.dates !== '' &&
      state.dates.split('&')[0] !== 'undefined' && {
        from: state.dates.split('&')[0],
        to: state.dates.split('&')[1]
      }),
    page: '0,1000,createdDate' //TO DO
  };
};

export const mapFilterInternalTransactionsData = state => {
  return {
    ...(state.txnid !== '' && { txnid: state.txnid }),
    ...(state.accountnumber !== '' && {
      account: state.accountnumber
    }),
    ...(state.Type !== '' && {
      type: state.Type ? state.Type.value?.toLowerCase() : ''
    }),
    ...(state.Asset !== '' && {
      asset: state.Asset ? state.Asset.value : ''
    }),
    ...(state.dates !== '' &&
      state.dates.split('&')[0] !== 'undefined' && {
        from: state.dates.split('&')[0],
        to: state.dates.split('&')[1]
      }),
    page: '0,1000,createdDate' //TO DO
  };
};
