import { connect } from 'react-redux';

import { updateAssetPair } from '../../redux/actions/trade';
import { toggleModal } from '../../redux/actions/transactions';
import MarketsTable from './MarketsTable';

const mapStateToProps = state => ({
  marketData24All: state.trade.marketData24All,
  assetPairs: state.trade.assetPairs.data,
  userData: state.login.userData.data,
  accounts: state.transactions.accounts,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  updateAssetPair,
  toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketsTable);
