import { Badge, Table } from 'react-bootstrap';

import React, { PureComponent } from 'react';
import { capitalizeFirstLetter, formatDate } from '../../utils/generalUtils';
import { formatTransactionsforTables, getTransactionID } from '../../utils/transactionsHelpers';
import './TransactionsTable.scss';

class TransactionsTable extends PureComponent {
  componentDidMount() {
    this.props.getAssetPairs();
  }

  buildDashboardRow = (transaction, index) => {
    let date = new Date(parseInt(transaction.time) || transaction.created_date || parseInt(transaction.trdtime));

    date = formatDate(date);

    let { amount, receiveAmount, sendSecurity, receiveSecurity, transactionType, executedBy } =
      formatTransactionsforTables(transaction, transaction.type, this.props.assetsPairs.data);

    return (
      <tr
        key={`${transaction.traderefno}-${index}`}
        onClick={() => {
          this.props.toggleTransactionDetails(transaction);
        }}
      >
        <td>{date}</td>
        <td>{executedBy}</td>
        <td>{getTransactionID(transaction.traderefno, transaction.type) || getTransactionID(transaction.id)}</td>
        <td>{transactionType ? capitalizeFirstLetter(transactionType) : capitalizeFirstLetter(transaction.type)}</td>
        <td>{sendSecurity}</td>
        <td>{sendSecurity === 'USD' ? '$ ' + amount : amount}</td>
        {this.props.screenType === 'Other' && (
          <>
            <td>{transaction.counteraccount || 'N/A'}</td>
            <td>{receiveSecurity || 'N/A'}</td>
            <td>
              {receiveSecurity === 'USD' ? (receiveAmount && '$ ' + receiveAmount) || 'N/A' : receiveAmount || 'N/A'}
            </td>
          </>
        )}
        {(this.props.screenType === 'DepositWithdrawalCrypto' || this.props.screenType === 'DepositWithdrawalUSD') && (
          <td>
            <Badge pill className={'status-badge-' + transaction?.['status'].toLowerCase()}>
              {capitalizeFirstLetter(transaction.status.toLowerCase()) || 'N/A'}
            </Badge>
          </td>
        )}
      </tr>
    );
  };

  renderHeadings = () => {
    const { t } = this.props;
    if (this.props.screenType === 'DepositWithdrawalCrypto' || this.props.screenType === 'DepositWithdrawalUSD') {
      return (
        <>
          <tr>
            <th>{t('payments.dateTime')}</th>
            <th>Executed by</th>
            <th className="vl-table-header-large">TXN ID</th>
            <th>Type</th>
            <th>{t('payments.asset')}</th>
            <th>{t('payments.amount')}</th>
            <th>Status</th>
          </tr>
        </>
      );
    } else {
      return (
        <>
          <tr>
            <th>{t('payments.dateTime')}</th>
            <th>Executed by</th>
            <th className="vl-table-header-large">TXN ID</th>
            <th>Type</th>
            <th className="vl-table-header-small">{t('payments.asset')}</th>
            <th>{t('payments.amount')}</th>
            <th>FBO</th>
            <th>{t('payments.counterParty') + ' ' + t('payments.asset').toLowerCase()}</th>
            <th>{t('payments.counterParty') + ' ' + t('payments.amount').toLowerCase()}</th>
          </tr>
        </>
      );
    }
  };

  render() {
    const transactions = this.props.transactions;
    return (
      <Table borderless responsive size="sm" className="vl-table">
        <thead>{this.renderHeadings()}</thead>
        <tbody>{transactions.map((transaction, index) => this.buildDashboardRow(transaction, index))}</tbody>
      </Table>
    );
  }
}

export default TransactionsTable;
