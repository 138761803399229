import * as React from 'react';
import { PureComponent } from 'react';
import './PriceVolumeBar.scss';
import { Col, Row } from 'react-bootstrap';
import { getLocalisedDecimalString } from '../../utils/generalUtils';
import LoadingIndicator from '../LoadingIndicator';
import { FIAT_SYMBOLS, isCmtSupported } from '../../enums/validation';
import VLSelect from '../VLSelect';

const PAIR_GROUPS_LIST = [
  { label: 'USD', value: 'USD', order: 1 },
  { label: 'USD (Stable)', value: 'USDC', order: 2 },
  { label: 'BTC', value: 'BTC', order: 3 },
  { label: 'ETH', value: 'ETH', order: 4 }
];

const pairToGroup = (acc, { security, pair_second }) => {
  for (const group of PAIR_GROUPS_LIST) {
    if (pair_second === group.value) {
      if (!acc[group.label]) {
        acc[group.label] = [];
      }
      acc[group.label].push(security);
    }
  }
  return acc;
};

const groupToOrderedOption = ([label, options]) => ({
  label,
  options: options.map(value => ({ label: value, value })),
  order: PAIR_GROUPS_LIST.find(group => group.label === label).order
});

export const byOrder = (a, b) => {
  return a.order - b.order;
};

/**
 * PriceVolumeBar component that is used in the Trade view. This component
 * shows the price and volume information of the selected asset.
 */
class PriceVolumeBar extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.props.fetchAssetPairs();
    if (this.props.selectedPair) {
      this.props.fetch24hData(this.props.selectedPair);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedPair?.security !== prevProps.selectedPair?.security) {
      this.props.fetch24hData(this.props.selectedPair);
    }
  }

  handleSelect(option) {
    // if the selected option is unchanged no need to fetch the same data again.
    if (option.value === this.props.selectedPair?.security) return;

    // We want to set the actual object as the selected value, not the string
    // representation.
    const selectedAssetPair = this.props.assetPairs.find(assetPair => assetPair.security === option.value);

    // If in any case something goes wrong, and it is not found, do nothing.
    if (!selectedAssetPair) return;

    this.props.updateAssetPair(selectedAssetPair);
  }

  render() {
    if (!this.props.selectedPair) {
      return null;
    }

    const round = (value, decimals) => {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    };

    const groupedOptions = Object.entries(this.props.assetPairs.filter(isCmtSupported).reduce(pairToGroup, {}))
      .map(groupToOrderedOption)
      .sort(byOrder);

    return (
      <div className="price-volume-bar">
        <Row>
          <Col sm={12} lg={2} className="pr-0">
            <div className="pair-select">
              <VLSelect
                size="m"
                borderless
                transparent
                defaultValue={{
                  value: this.props.selectedPair?.security,
                  label: this.props.selectedPair?.security
                }}
                isSearchable={true}
                options={groupedOptions}
                onChange={this.handleSelect}
              />
            </div>
          </Col>
          <Col sm={12} lg={10} className="pl-0">
            {this.props.marketData24.loading ? (
              <LoadingIndicator styleClass="price-volume-bar-spinner" />
            ) : Object.keys(this.props.marketData24.data).length > 0 ? (
              <div className="price-volume-bar-value-container">
                <div className="price-volume-bar-value">
                  <div className="price-volume-bar-value__title price-volume-bar-value__green price-volume-bar-value__large">
                    {getLocalisedDecimalString(
                      this.props.marketData24.data.closePrice,
                      this.props.selectedPair?.pair_second
                    )}
                  </div>
                  <div className="price-volume-bar-value__value">
                    {FIAT_SYMBOLS[this.props.selectedPair?.pair_second]}
                    {getLocalisedDecimalString(
                      this.props.marketData24.data.closePrice,
                      this.props.selectedPair?.pair_second
                    )}
                  </div>
                </div>
                <PriceVolumeBarValue
                  title="24h change"
                  value={`${round(this.props.marketData24.data.change, 2)} ${round(
                    this.props.marketData24.data.changePercent,
                    2
                  )}%`}
                  up={this.props.marketData24.data.change < 0}
                  down={this.props.marketData24.data.change > 0}
                />
                <PriceVolumeBarValue
                  title="24h high"
                  value={getLocalisedDecimalString(
                    this.props.marketData24.data.highPrice,
                    this.props.selectedPair?.pair_second
                  )}
                />
                <PriceVolumeBarValue
                  title="24h low"
                  value={getLocalisedDecimalString(
                    this.props.marketData24.data.lowPrice,
                    this.props.selectedPair?.pair_second
                  )}
                />
                <PriceVolumeBarValue
                  title="24h volume"
                  value={
                    getLocalisedDecimalString(
                      this.props.marketData24.data.volume,
                      this.props.selectedPair?.pair_second
                    ) +
                    ' ' +
                    this.props.selectedPair.pair_second
                  }
                />
              </div>
            ) : (
              <div className="price-volume-bar-value-container">
                <div className="price-volume-bar-value">
                  <div className="price-volume-bar-value">
                    <div className="price-volume-bar-value__title no-data-text">No data available</div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

class PriceVolumeBarValue extends PureComponent {
  render() {
    let accent = '';
    if (this.props.up) {
      accent += ' price-volume-bar-value__red';
    }
    if (this.props.down) {
      accent += ' price-volume-bar-value__green';
    }

    return (
      <div className="price-volume-bar-value">
        <div className="price-volume-bar-value__title">{this.props.title}</div>
        <div className={'price-volume-bar-value__value' + accent}>{this.props.value}</div>
      </div>
    );
  }
}

export default PriceVolumeBar;
