import BuySell from './BuySell';

import {
  getAssetPairs,
  getAssets,
  getTradingAssets,
  toggleModal,
  updateTransactionsDB,
  getAccountTransactions
} from '../../redux/actions/transactions';
import { connect } from 'react-redux';
import { exchangeAssets } from '../../redux/actions/websocket';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getUserProfile } from '../../redux/actions/login';

const mapStateToProps = state => ({
  assets: state.transactions.tradingAssets,
  assetsPairs: state.transactions.assetsPairs,
  accounts: state.transactions.accounts,
  positions: state.websocket.positions,
  socket: state.websocket.websocket,

  allBooks: state.websocket.allBooks,
  cryptoRates: state.transactions.cryptoRates,
  updatingTransactionDb: state.transactions.updatingTransactionDb,
  userData: state.login.userData,
  profileDetails: state.login.profileDetails,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  toggleModal,
  exchangeAssets,
  updateTransactionsDB,
  getTradingAssets,
  getUserProfile,
  getAssets,
  getAssetPairs,
  getAccountTransactions
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(BuySell);
