import React, { PureComponent } from 'react';

class PeriodicalContactUpdateComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.interval = null;
  }
  contactsUpdate = () => {
    const currentAccount = this.props.currentAccountNumber;
    if (!currentAccount) {
      return;
    }

    this.props.getContactsRequestsRequest({ currentAccount });
    this.props.getContactsRequest({ currentAccount });
  };
  componentDidMount() {
    if (!process.env.REACT_APP_CONTACT_INTERVAL_UPDATE) {
      console.log('REACT_APP_CONTACT_INTERVAL_UPDATE is not set ');
      return;
    }

    setTimeout(() => {
      this.contactsUpdate();
    }, 2000);

    this.interval = setInterval(() => {
      this.contactsUpdate();
    }, process.env.REACT_APP_CONTACT_INTERVAL_UPDATE);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <></>;
  }
}

export default PeriodicalContactUpdateComponent;
