import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Users from './FirmUsers';
import './FirmUsers.scss';

import {
  getUsersRequest,
  createUserRequest,
  createNewAccount,
  checkAccountName,
  queryExistingUser
} from '../../redux/actions/settings';

const mapStateToProps = state => ({
  users: state.settings.users,
  accounts: state.settings.accounts,
  canEditUsers: state.login.userPermissions.data.permissions?.canEditUsers
});

const mapDispatchToProps = {
  getUsersRequest,
  createUserRequest,
  createNewAccount,
  checkAccountName,
  queryExistingUser
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Users);
