import { SECURITY_CODES } from '../../../../enums/validation';
import { getLocalisedDecimalString } from '../../../../utils/generalUtils';
import './CryptoAssetChartDetails.scss';
import '../../AdvancedChart/AdvancedChart.scss';
import VLButton from '../../../Buttons/VLButton';

const CryptoAssetChartDetails = ({ rangeChoices, selectedRange, onTimeSelect, cryptoData }) => {
  if (!cryptoData || Object.keys(cryptoData).length === 0) {
    return null;
  }

  const getPriceInformation = () => {
    const priceParts = getLocalisedDecimalString(cryptoData?.closePrice, SECURITY_CODES.USD).toString().split('.');

    return (
      <div className="price-information">
        <div>
          <span className="price-symbol">$</span>
          <span>{priceParts[0]}</span>
          <span className="price-decimal">.{priceParts[1]}</span>
          <span className={`percentage${Number(cryptoData?.changePercent) > 0 ? '-positive' : '-negative'}`}>
            {cryptoData?.changePercent.toFixed(2)}%
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="details-container AdvancedChartWrapper">
      <div className="price-information-details">
        {getPriceInformation()}
        <div className="time-select timeframe-buttons">
          {rangeChoices.map(range => (
            <VLButton
              size="m"
              width="40px"
              variant={!selectedRange.equals(range) ? 'outline' : ''}
              className={'time-select-btn ' + (selectedRange.equals(range) ? 'selected' : '')}
              onClick={() => onTimeSelect(range)}
              text={range.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CryptoAssetChartDetails;
