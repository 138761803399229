import React from 'react';
import './PageTitle.scss';

const PageTitle = ({ text, type, aligned, styleClass }) => {
  return (
    <h2
      className={`
    page-title ${styleClass ? styleClass : ''}
    ${type ? ` page-title--${type}` : ''}
    ${aligned ? ` page-title-aligned--${aligned}` : ''}
    `}
    >
      {text}
    </h2>
  );
};

export default PageTitle;
