import FirmDetails from './FirmDetails';
import {
  deleteFirmRequest,
  getFirmAdminAccountsRequest,
  getFirmDetailsRequest,
  getFirmUsersRequest
} from '../../redux/actions/administration';
import { queryPositionsFirmDetails } from '../../redux/actions/websocket';
import { connect } from 'react-redux';
import { getTradingAssets } from '../../redux/actions/transactions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  firmDetails: state.administration.firmDetails,
  firmAdminAccounts: state.administration.firmAdminAccounts,
  firmUsers: state.administration.firmUsers,
  assets: state.transactions.tradingAssets,
  websocket: state.websocket.websocket,
  socketConnected: state.websocket.socketConnected,
  users: state.settings.users,
  cryptoRates: state.transactions.cryptoRates,
  canEditFirms: state.login.userPermissions.data.permissions?.canEditFirms
});

const mapDispatchToProps = {
  getFirmDetailsRequest,
  getFirmAdminAccountsRequest,
  getFirmUsersRequest,
  deleteFirmRequest,
  getTradingAssets,
  queryPositionsFirmDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmDetails));
