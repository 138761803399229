import React, { Component } from 'react';

import { MdDateRange } from 'react-icons/md';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FormControl } from 'react-bootstrap';
class DatePicker extends Component {
  state = {
    date: [],
    isVisible: false
  };

  handleCallback = (s, e, l) => {
    const startDate = s._d;
    const EndDate = e._d;

    this.setState({ date: [s.format('MM-DD-YYYY'), e.format('MM-DD-YYYY')] });
    this.props.onClick([startDate, EndDate]);
  };

  render() {
    return (
      <div key={Math.random()} className="user-form-control-wrap system-form-control-box date-range-parent ">
        <div className={`date-range-container ${!this.state.isVisible ? 'date-hide' : ''}`}>
          <DateRangePicker
            onHideCalendar={!this.state.isVisible}
            initialSettings={{
              ...this.props.options,
              minDate: this.props.minDate,
              maxDate: this.props.maxDate,

              startDate: this.props.date?.length ? this.state.date[0] : undefined,
              endDate: this.props.date?.length ? this.state.date[1] : undefined,
              date: this.state.date
            }}
            setStartDate={this.props.selected}
            onCallback={(start, end, label) => this.handleCallback(start, end, label)}
          >
            <FormControl className="date-range-input" type="text" />
          </DateRangePicker>

          <MdDateRange size="1.5em" className="date-range-icon" />
        </div>
      </div>
    );
  }
}

export default DatePicker;
