import OrderBookTab from './OrderBookTab';
import { connect } from 'react-redux';
import { setSelectedLmt } from '../../../redux/actions/trade';
const mapStateToProps = state => ({
  allBooks: state.websocket.allBooks,
  assetPair: state.trade.selectedPair,
  marketData24: state.trade.marketData24,
  openOrders: state.websocket.openOrders
});

const mapDispatchToProps = {
  setSelectedLmt
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBookTab);
