import { Row } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { isUSDAsset } from '../../../utils/transactionsHelpers';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import LoadingIndicator from '../../LoadingIndicator';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput';

const DepositAddressStep = ({
  addInternalWalletsRequest,
  fetchInternalWalletsRequest,
  depositWallets,
  asset,
  onClose,
  currentAccountNumber
}) => {
  const [createWalletsLoading, setCreateWalletsLoading] = useState(false);
  const walletInputRef = useRef(null);

  useEffect(() => {
    fetchInternalWalletsRequest({
      account: currentAccountNumber,
      errorCallback: () => {
        toast.error('Error fetching wallets');
      }
    });
  }, [fetchInternalWalletsRequest]);

  const handleCopyToClipboard = () => {
    const element = walletInputRef.current;

    if (element) {
      navigator.clipboard.writeText(element.value);
      toast.success(i18next.t('externalDepositWithdraw.toast'));
      element.focus();
    }
  };

  const handleCreateWallets = () => {
    setCreateWalletsLoading(true);
    addInternalWalletsRequest({
      payload: { account: currentAccountNumber },
      successCallback: () => {
        fetchInternalWalletsRequest({ account: currentAccountNumber });
        toast.success('Wallet created successfully');
        setCreateWalletsLoading(false);
      },
      errorCallback: () => {
        toast.error('Error creating wallet, please try again');
        setCreateWalletsLoading(false);
      }
    });
  };

  const extractAssetWalletAddress = (wallets, asset) => {
    if (!wallets || !asset) return '';
    // ethAddress, filAddress etc.
    return wallets[asset.toLowerCase() + 'Address'] || '';
  };

  const createWalletsVisible = depositWallets.loaded && !depositWallets.data && !depositWallets.error;

  return (
    <>
      <p className="external-deposit-title-label title-label-step2">Review and complete deposit</p>
      {depositWallets.loading || createWalletsLoading ? <LoadingIndicator /> : null}
      <Row className="small-text-wrapper">
        <p className="external-deposit-central-text">
          {asset} deposit {isUSDAsset(asset) ? 'account' : 'address'}
        </p>
        <VLInput
          name="walletAcc"
          value={extractAssetWalletAddress(depositWallets?.data, asset)}
          maxLength="20"
          autoComplete="off"
          disabled="true"
          width="90%"
          noBorder
          hasBackground
          innerRef={walletInputRef}
        />{' '}
        {!isUSDAsset(asset) && <FiCopy className="copy-icon-address" size="2.4em" onClick={handleCopyToClipboard} />}
        {isUSDAsset(asset) ? null : (
          <React.Fragment>
            {createWalletsVisible ? (
              <div className="external-deposit-button-position">
                <VLButton onClick={handleCreateWallets} disabled={createWalletsLoading} text="Create a new address" />
              </div>
            ) : null}
            <p className="confirmation-modal-text">
              <br />
              {i18next.t(`secondStep.disclaimer${asset}`)}
            </p>
          </React.Fragment>
        )}
      </Row>
      <Row className="external-deposit-footer-bar">
        <VLButton width="100%" onClick={onClose} text="Done" />
      </Row>
    </>
  );
};

export default DepositAddressStep;
