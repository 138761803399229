import {
  getOnboardingUsersRequest,
  acceptOnboardingUserRequest,
  declineOnboardingUserRequest,
  deleteBtaReportRequest
} from '../../redux/actions/administration';
import { connect } from 'react-redux';
import AccountsTableAdmin from './AccountsTableAdmin';
import { uploadBtaReportRequest } from '../../redux/actions/administration';

const mapDispatchToProps = {
  getOnboardingUsersRequest,
  acceptOnboardingUserRequest,
  declineOnboardingUserRequest,
  deleteBtaReportRequest,
  uploadBtaReportRequest
};
const mapStateToProps = state => ({
  onboardingUsers: state.administration.onboardingUsers,
  profileDetails: state.login.profileDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTableAdmin);
