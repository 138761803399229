import MarketOrder from './MarketOrder';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { makeMarketOrderRequest } from '../../../redux/actions/websocket';
import { updateTransactionsDB } from '../../../redux/actions/transactions';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  userData: state.login.profileDetails.data,
  cryptoRates: state.transactions.cryptoRates
});

const mapDispatchToProps = {
  makeMarketOrderRequest,
  updateTransactionsDB
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(MarketOrder);
