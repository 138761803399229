import { connect } from 'react-redux';
import Login from './Login';
import { loginRequest, loginReset } from '../../redux/actions/login';
import './Login.scss';

const mapStateToProps = state => ({
  userData: state.login.userData
});

const mapDispatchToProps = {
  loginRequest,
  loginReset
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
