import { extractAssetsFromSecurityCode, formatDate, getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import LoadingIndicator from '../../LoadingIndicator';

const TradeHistory = ({ tradeHistory, dataLoading }) => {
  return (
    <div>
      <div>
        {' '}
        <div className="order-book-headers">Price</div>
        <div className="order-book-headers">Amount</div>
        <div className="order-book-headers">Date</div>
      </div>
      <div className="order-book-body trade-history-body">
        {tradeHistory.map(trade => {
          const assets = extractAssetsFromSecurityCode(trade.security);

          return (
            <div>
              {' '}
              <div className={`order-book-details${trade.side === 'B' ? '__buy' : '__sell'}`}>
                {' '}
                {getLocalisedNumericString(trade.execprice, false, getDecimalByFraction(FRACTIONS[assets?.quote]))}
              </div>
              <div className="order-book-details">
                {' '}
                {getLocalisedNumericString(trade.execqty, false, getDecimalByFraction(FRACTIONS[assets?.base]))}
              </div>
              <div className="order-book-details">
                {formatDate(new Date(parseInt(trade.time)), {
                  dateOnly: true
                })}
              </div>
            </div>
          );
        })}
      </div>
      {!dataLoading && <LoadingIndicator />}
      {!dataLoading && (
        <div className="loader-wrapper">
          <div className="loading-txt">{'Loading'}</div>

          <div className="dot-elastic loading-dots"></div>
        </div>
      )}
    </div>
  );
};
export default TradeHistory;
