import { Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import SearchAccountsInput from '../../SearchAccountsInput';
import VLButton from '../../Buttons/VLButton';

const SearchAccountsModal = ({ isOpen, onClose, onComplete, title, submitText, firm, ignoreAccounts }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleConfirm = () => {
    onComplete?.(selectedAccount);
  };

  const handleSelectedAccount = accountId => {
    setSelectedAccount(accountId);
  };

  return (
    <Modal className="common-modal" centered show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="">
          <div style={{ width: '100%' }}>
            <div className="sfcb-transparent">
              <SearchAccountsInput
                firm={firm}
                ignoreAccounts={ignoreAccounts}
                onAccountSelected={handleSelectedAccount}
                selectedAccount={selectedAccount}
              />
            </div>
          </div>
          <VLButton
            disabled={!selectedAccount}
            onClick={handleConfirm}
            text={submitText}
            width="100%"
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SearchAccountsModal;
