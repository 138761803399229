import { connect } from 'react-redux';
import Support from './Support';
import { contactSupportRequest } from '../../redux/actions/login';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  contactSupportRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
