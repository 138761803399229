import React, { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import VLSelect from '../VLSelect';

const MIN_CHARS_FOR_SEARCH = 3;
const MAX_OPTIONS_SHOWN = 50;

const mapUsersToOptionsFn = account => ({
  label: `${account.account} (${account.attr?.name})`,
  value: account.account
});

/**
 * SearchAccountsInput component
 * Component based on React Select with purpose to search for existing accounts
 *
 * @param {string} selectedAccountId Selected account id
 * @param {fn} onAccountSelected Callback function that accepts account id
 * @param {Array<user>} ignoreAccounts Optional param if search results should be filtered by firm code
 * @param {fn} getAccountSuggestionsRequest Passed automatically by redux (see index.js)
 * @param {Array<user>} accountSuggestions Passed automatically by redux (see index.js)
 * @param {string} firm Optional param if search results should be filtered by firm code
 */

const SearchAccountsInput = ({
  selectedAccountId,
  onAccountSelected,
  ignoreAccounts,
  getAccountSuggestionsRequest,
  accountSuggestions,
  firm
}) => {
  const [value, setValue] = useState('');

  const options = useMemo(() => {
    let suggestions = accountSuggestions.data;

    if (!suggestions?.length) return [];

    if (!value || value.length < MIN_CHARS_FOR_SEARCH) {
      return [];
    }

    return suggestions
      .filter(s => !ignoreAccounts.map(i => i.account).includes(s.account)) // filter out ignored users
      .slice(0, MAX_OPTIONS_SHOWN)
      .map(mapUsersToOptionsFn);
  }, [ignoreAccounts, accountSuggestions.data, value]);

  const handleSelectChange = option => {
    onAccountSelected(option.value);
  };

  const getFilteredUsers = val => {
    const payload = {
      q: val,
      firm
    };

    getAccountSuggestionsRequest(payload);
  };

  const handleSearchDebounced = debounce(getFilteredUsers, 300);

  const handleSearch = value => {
    console.log('value', value);
    setValue(value);

    if (value.length < MIN_CHARS_FOR_SEARCH) return;
    handleSearchDebounced(value);
  };

  return (
    <VLSelect
      placeholder={`Search by account name or number (min ${MIN_CHARS_FOR_SEARCH} characters)`}
      options={options}
      onInputChange={handleSearch}
      onChange={handleSelectChange}
      value={options.find(o => o.value === selectedAccountId) ?? undefined}
      isOptionDisabled={false}
      isLoading={accountSuggestions.loading}
    />
  );
};

export default SearchAccountsInput;
