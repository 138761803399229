import './Escrow.scss';

import {
  getTradingAssets,
  getDepositAccounts,
  getRecipientFirms,
  resetFields,
  searchRecipientAccounts,
  toggleModal,
  getAssetPairs
} from '../../redux/actions/transactions';

import Escrow from './Escrow';
import { connect } from 'react-redux';
import { makeEscrowRequest } from '../../redux/actions/websocket';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  accounts: state.transactions.accounts,
  assets: state.transactions.tradingAssets,
  positions: state.websocket.positions,
  firms: state.transactions.recipientFirms,
  firmAccounts: state.transactions.firmAccounts,
  contacts: state.administration.contacts.data,
  cryptoRates: state.transactions.cryptoRates,
  assetsPairs: state.transactions.assetsPairs,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getDepositAccounts,
  getTradingAssets,
  resetFields,
  makeEscrowRequest,
  toggleModal,
  getRecipientFirms,
  searchRecipientAccounts,
  getAssetPairs
};

export default connect(mapStateToProps, mapDispatchToProps)(Escrow);
