import './ExternalDeposit.scss';
import { queryBalanceWalletPos } from '../../redux/actions/websocket';
import { getAssets, getAssetsByProduct, toggleModal } from '../../redux/actions/transactions';
import {
  createExternalDepositUSDRequest,
  fetchExternalTransactionSupportedAssets
} from '../../redux/actions/transactionsExternal';
import ExternalDeposit from './ExternalDeposit';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  user: state.login.userData?.data,
  accounts: state.transactions.accounts,
  assets: state.transactions.assetsByProduct,
  websocket: state.websocket.websocket,
  positions: state.websocket.positions,
  supportedAssets: state.externalTransactions.supportedAssets
});

const mapDispatchToProps = {
  getAssets,
  toggleModal,
  queryBalanceWalletPos,
  getAssetsByProduct,
  createExternalDepositUSDRequest,
  fetchExternalTransactionSupportedAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalDeposit);
