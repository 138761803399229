import SearchUsersInput from './SearchAccountsInput';
import { getAccountSuggestionsRequest } from '../../redux/actions/administration';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  accountSuggestions: state.administration.accountSuggestions
});

const mapDispatchToProps = {
  getAccountSuggestionsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsersInput);
