import { DateTime } from 'luxon';
import {
  AUTHORIZATIONS,
  AUTHORIZATION_KEYS,
  ROLES,
  SUPER_USER_TYPES,
  PRODUCT_TYPES,
  ACCOUNT_TYPES
} from '../enums/roles';
import { TIME_ZONE } from '../enums/time';
import { DAYS_MAP, NO_TIMELOCK } from '../enums/validation';

export const hasRole = (userData, role) => userData?.role === role;

export const decodeRole = role => {
  const roleDecoded = {};
  if (!role || !role.length) {
    return roleDecoded;
  }

  const roleChar = role.charAt(0);

  if (roleChar === 'S') {
    roleDecoded.role = ROLES.SUPER_USER;
    roleDecoded.superAdminType = SUPER_USER_TYPES.VL_ADMIN;

    const superAdminType = role.charAt(3);
    if (superAdminType === 'B') roleDecoded.superAdminType = SUPER_USER_TYPES.BANK_MANAGER;
    if (superAdminType === 'T') roleDecoded.superAdminType = SUPER_USER_TYPES.BTA_MANAGER;
  }

  if (roleChar === 'F') roleDecoded.role = ROLES.ADMIN;
  if (roleChar === 'X') roleDecoded.role = ROLES.USER;

  const productChar = role.charAt(4);

  if (productChar === 'P') roleDecoded.product = PRODUCT_TYPES.PCA;
  if (productChar === 'S') roleDecoded.product = PRODUCT_TYPES.SCA;

  return roleDecoded;
};

export const getWhiteListOfEntity = entity => entity?.restricted_attr?.white_list || [];

export const getAccessListOfEntity = entity => entity?.restricted_attr?.access_list || [];
export const getAccountAccess = (entity, childEntity) =>
  getAccessListOfEntity(entity).find(
    listItem => listItem.account === childEntity.userid
    //   ||listItem.secondary_account === childEntity.userid ||
    //   listItem.account === childEntity.secondary_account
  );

export const userHasAuth = (accountData, auth) => accountData?.access === AUTHORIZATIONS[auth];

export const checkAccessListForOneAuthInstance = (user, auth, accountId = null) => {
  const accessList = getAccessListOfEntity(user);
  if (accountId) {
    let accountAccess = accessList.find(object => object.account === accountId);

    return accountAccess?.access === AUTHORIZATIONS[auth];
  } else {
    const hasAuth = accessList.find(object => object.access === AUTHORIZATIONS[auth] || user.role === 'superadmin');

    return hasAuth;
  }
};

export const checkHasAuths = (user, account = null, ignoreView = false) => {
  const hasViewAccess = checkAccessListForOneAuthInstance(user, AUTHORIZATION_KEYS.VIEW, account);

  if (hasViewAccess || user.role === 'superadmin') return true;

  const hasTransact = checkAccessListForOneAuthInstance(user, AUTHORIZATION_KEYS.TRANSACTION, account);

  if (hasTransact) return true;

  const hasApprove = checkAccessListForOneAuthInstance(user, AUTHORIZATION_KEYS.APPROVE, account);

  if (hasApprove) return true;

  return false;
};

export const hasViewRights = (user, account) => {
  const access = getAccountAccess(account, user).access;
  return access === AUTHORIZATIONS.VIEW || access === AUTHORIZATIONS.TRANSACTION || access === AUTHORIZATIONS.APPROVE;
};

export const hasTransactRights = (user, account) => {
  const access = getAccountAccess(account, user).access;
  return access === AUTHORIZATIONS.TRANSACTION || access === AUTHORIZATIONS.APPROVE;
};
export const isCorporateAccount = account => {
  return account?.attr?.type === ACCOUNT_TYPES.CORPORATE;
};
export const regularCorporateUserCheck = (role, account) => {
  return decodeRole(role).role === ROLES.USER && isCorporateAccount(account);
};

export const hasApproveRights = (user, account) => {
  const access = getAccountAccess(account, user).access;
  return access === AUTHORIZATIONS.APPROVE;
};

export const hasTimeLock = (user, account) => {
  const timeLock = getAccountAccess(account, user)?.daily_unlock_time;

  return timeLock && timeLock !== NO_TIMELOCK;
};

export const getTimeLock = (user, account) => {
  return getAccountAccess(account, user)?.daily_unlock_time;
};

export const checkValueOfAuth = (parentData, childElement, isRead = false, includeTransact = false, e) => {
  const userAccountAccess = getAccountAccess(parentData, childElement);
  if (!userAccountAccess) return false;

  if (userAccountAccess.access === AUTHORIZATIONS.APPROVE) return true;
  if (userAccountAccess.access === AUTHORIZATIONS.TRANSACTION && includeTransact) return true;
  if (userAccountAccess.access === AUTHORIZATIONS.VIEW && isRead) return true;

  return false;
};

export const checkLimitAuth = (parentData, childElement) => {
  const userAccountAccess = getAccountAccess(parentData, childElement);

  if (!userAccountAccess) return false;

  if (!userAccountAccess.max_transfer) return false;

  return true;
};

export const isAccountTimelocked = (userData, accountID) => {
  const accountRestrictions = userData?.restricted_attr?.access_list.find(({ account }) => account === accountID);

  if (!accountRestrictions) return false;

  if (!accountRestrictions.daily_unlock_time || accountRestrictions.daily_unlock_time === NO_TIMELOCK) return false;

  const unlockTimeBits = accountRestrictions.daily_unlock_time.split('|');
  const days = unlockTimeBits[2] ? unlockTimeBits[2]?.split(',') : [];
  const zone = {
    zone: TIME_ZONE
  };
  const startTime = DateTime.fromJSDate(new Date(), zone).set({
    hour: unlockTimeBits[0].split(':')[0],
    minute: unlockTimeBits[0].split(':')[1]
  });
  const endTime = DateTime.fromJSDate(new Date(), zone).set({
    hour: unlockTimeBits[1].split(':')[0],
    minute: unlockTimeBits[1].split(':')[1]
  });
  const currentTime = DateTime.fromJSDate(new Date(), zone);

  const hasUnlockedPeriodToday = days.find(day => {
    return DAYS_MAP[day] === currentTime.weekday;
  });

  if (!hasUnlockedPeriodToday) return true;

  // If current time is outside startTime - endTime window
  return currentTime < startTime || currentTime > endTime;
};

export const shouldAllowTransactions = (userData, account) => {
  if (!account || !userData) return false;

  const activeAccount = account.active_account === 'Y';
  if (!activeAccount) return false;

  if (
    account.access !== AUTHORIZATIONS[AUTHORIZATION_KEYS.TRANSACTION] &&
    account.access !== AUTHORIZATIONS[AUTHORIZATION_KEYS.APPROVE]
  )
    return false;

  return !isAccountTimelocked(userData, account.account);
};
