import React from 'react';

import './TabGroup.scss';

const TabGroup = ({ onTabClick, activeTab, tabs, mode = TAB_GROUP_MODE.DEFAULT }) => {
  const handleKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onTabClick(id);
    }
  };

  return (
    <div className={mode === TAB_GROUP_MODE.DEFAULT ? 'tab-group__left-aligned' : `tab-group`}>
      {tabs.map(tab => (
        <div
          key={tab.id}
          aria-label={tab.label}
          tabIndex="0"
          className={`tab-group__item ${tab.id === activeTab ? `tab-group__item--active` : ''}`}
          onClick={() => {
            onTabClick(tab.id);
          }}
          onKeyPress={e => {
            handleKeyPress(e, tab.id);
          }}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
export const TAB_GROUP_MODE = { DEFAULT: 'DEFAULT', STRETCH: 'STRETCH' };
export default TabGroup;
