import './Analytics.scss';

import Analytics from './Analytics';
import { connect } from 'react-redux';
import { fetchAllAnalyticsData } from '../../redux/actions/analytics';

const mapStateToProps = state => ({
  pieAssets: state.analytics.pieAssets,
  pieTransactions: state.analytics.pieTransactions
});

const mapDispatchToProps = {
  fetchAllAnalyticsData
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
