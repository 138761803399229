import * as ACTIONS from '../../actions/trade';
import * as CONSTANTS from '../../constants/trade';
import { put, takeLatest } from 'redux-saga/effects';
import URLS from '../../constants/urls';
import { toast } from 'react-toastify';
import RequestBuilder from '../../../request/RequestBuilder';

function* fetchChartData(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchChartDataSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchChartDataError(err));
  }
}

function* fetch24hData(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetch24hDataSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetch24hDataError(err));
  }
}

function* fetch24hDataAllAssets(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetch24hDataForAllAssetsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetch24hDataForAllAssetsError(err));
  }
}

function* fetchAssetPairs(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchAssetPairsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchAssetPairsError(err));
  }
}
function* getIOI(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getIOISuccess(response));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getIOIError(err));
  }
}
function* addIOI(action) {
  try {
    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();
  } catch (err) {
    toast.error(err.reason);
  }
}

export default function* tradeSaga() {
  yield takeLatest(CONSTANTS.FETCH_CHART_DATA, fetchChartData);
  yield takeLatest(CONSTANTS.FETCH_TRADE_ASSET_PAIRS, fetchAssetPairs);
  yield takeLatest(CONSTANTS.FETCH_24H_MARKET_DATA, fetch24hData);
  yield takeLatest(CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST, fetch24hDataAllAssets);
  yield takeLatest(CONSTANTS.GET_IOI, getIOI);
  yield takeLatest(CONSTANTS.ADD_IOI, addIOI);
}
