import { getUserDetailsRequest } from '../../redux/actions/administration';
import { getFirmUserDetailsRequest } from '../../redux/actions/settings';
import { updateRiskSettings } from '../../redux/actions/websocket';

import { connect } from 'react-redux';
import UserDetails from './UserDetails';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  socketConnected: state.websocket.socketConnected,
  userDetails: state.administration.userDetails,
  firmUserDetails: state.settings.userDetails,
  me: state.login.userData
});

const mapDispatchToProps = {
  updateRiskSettings,
  getUserDetailsRequest,
  getFirmUserDetailsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDetails));
