import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import DatePicker, { dateSinglePickerStart } from '../../DatePicker';
import { AMOUNT_REGEX, REQUEST_TYPE, SEND_TYPE } from '../../../enums/validation';
import { splitDateTime, getSelectedOption, moneyFormat, replaceCommas } from '../../../utils/generalUtils';
import { onlyNumbers, validateAmount } from '../../../utils/validators';
import { getDecimalByAsset } from '../../../utils/transactionsHelpers';
import { getTotalBalanceFormatting } from '../../../utils/DOMHelpers';
import AccountNameCodeOption from '../../AccountNameCodeOption/AccountNameCodeOption';
import ListTransactionsContainer from '../../ListTransactionsContainer';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput';
import VLSelect from '../../VLSelect';

const repeatDurationOptions = [
  { label: '', value: 'daily' },
  { label: '', value: 'weekly' }
  //   { label: 'Monthly', value: 'monthly' }
];
const { day, month, year } = splitDateTime(new Date(), true);
const todaysDate = `${month}/${day}/${year}`;

class SendAndRequestDetails extends PureComponent {
  state = {
    sourceAccount: this.props.sourceAccount,
    amountObject: { ...this.props.amountObject },
    asset: this.props.asset,
    firm: this.props.firm,
    recipient: this.props.recipient,
    repeat: this.props.repeat,
    repeatDuration: this.props.repeatDuration || 'daily',
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    publicNote: this.props.publicNote,
    privateNote: this.props.privateNote,
    maxDecimal: this.props.maxDecimal,
    minDate: todaysDate
  };

  composeAssetOptionComponent = ({ value, label }) => {
    return (
      <div className="form-select-label-wrap">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          // src={assetData?.attr.image}
          alt={label}
        />
        <span className="form-select-text">{label}</span>
      </div>
    );
  };

  handleRepeatChange = ({ target }) =>
    this.setState(
      {
        repeat: target.checked,
        repeatDuration: target.checked ? this.state.repeatDuration : ''
      },
      () => {
        this.props.updateParent(this.state);
      }
    );

  handleDateChange = (date, type) => {
    this.setState(
      {
        [`${type}Date`]: date[0]
      },
      () => {
        this.props.updateParent(this.state);
      }
    );
  };

  handleFormControlChange = (a, b) => {
    const handleInputChange = (name, value) => {
      this.setState(
        {
          [name]: value
        },
        () => {
          this.props.updateParent(this.state);
        }
      );
    };

    if (a.target) {
      handleInputChange(a.target.name, a.target.value);
    } else {
      handleInputChange(b.name, a.value);
    }
    this.setState({ lng: i18next.language });
  };

  handleSelect = (option, name) => {
    let decimal = this.state.maxDecimal;
    let validationObject = { ...this.state.amountObject };
    if (name === 'asset') {
      decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');
      validationObject = validateAmount(
        replaceCommas(this.state.amountObject.value) || '0',
        this.props.positions?.[option?.value],
        decimal,
        this.props.transactionType === REQUEST_TYPE
      );
    }

    this.setState(
      {
        [name]: option?.value || '',
        maxDecimal: decimal,
        amountObject: {
          ...validationObject,
          value: moneyFormat(validationObject.value),
          error: validationObject.error
        }
      },
      () => {
        this.props.updateParent(this.state);
      }
    );
  };

  validateAmount = () => {
    const value = onlyNumbers(replaceCommas(this.state.amountObject.value));
    let validationObject = validateAmount(
      value,
      this.props.positions?.[this.state.asset],
      this.state.maxDecimal,
      this.props.transactionType === REQUEST_TYPE
    );
    this.setState(
      {
        amountObject: {
          ...validationObject,
          value: moneyFormat(validationObject.value)
        }
      },
      () => {
        this.props.updateParent(this.state);
      }
    );
  };

  handleAmountChange = ({ target }) => {
    if (AMOUNT_REGEX.test(target.value)) {
      this.setState({
        amountObject: {
          ...this.state.amountObject,
          value: moneyFormat(replaceCommas(target.value))
        }
      });
    }
  };

  formatOptionLabel = ({ value, label }) => {
    return <AccountNameCodeOption label={label} key={value} />;
  };
  redirectToContacts = () => {
    this.props.history?.push('/contacts');
  };

  getContactFilters = () => {
    let dropdownData =
      this.props?.accountContacts != null &&
      this.props?.accountContacts?.map(company => {
        return {
          group: 'Add a new Contact',
          value: company.accountNumber,
          label: company.accountNumber
        };
      });

    return dropdownData;
  };

  renderSendForm = () => {
    const { assetOptions } = this.props;

    const selectedAsset = getSelectedOption(assetOptions, this.state.asset);
    const selectedRecipient = getSelectedOption(this.getContactFilters(), this.state.recipient);

    if (repeatDurationOptions[0].value === 'daily') {
      repeatDurationOptions[0].label = i18next.t('send&requestDetails.daily');
    }
    if (repeatDurationOptions[1].value === 'weekly') {
      repeatDurationOptions[1].label = i18next.t('send&requestDetails.weekly');
    }

    return (
      <>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.selectedAccount')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput name="sourceAccount" value={this.state.sourceAccount} size="s" />
            </div>
          </Col>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.amountToTransfer')}</p>
            <div
              className={`system-form-control-box-custom ${
                this.state.amountObject.error ? 'system-form-control-box--error' : ''
              } sfcb-ammount sfcb-transparent`}
            >
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('send&requestDetails.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="asset"
                options={assetOptions}
                onChange={option => this.handleSelect(option, 'asset')}
                value={selectedAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
                // menuIsOpen={true}
              />
              <VLInput
                name="amount"
                placeholder=""
                value={this.state.amountObject.value}
                onChange={this.handleAmountChange}
                onBlur={this.validateAmount}
                maxLength="20"
                noBorder
                hasBackground
                width="70%"
              />
            </div>
            {this.state.amountObject.error && (
              <div className="system-form-control-box-error-text">
                <span className="system-form-control-error-text">{i18next.t('send&requestDetails.eror-text')}</span>
              </div>
            )}
            {this.state.asset && (
              <div className="system-form-control-box sfcb-amount-transfer-info">
                <div className="placeholder">{i18next.t('send&requestDetails.totalAmount')}</div>
                <div className="sfcb-amount-number">
                  {getTotalBalanceFormatting(this.props.positions?.[this.state.asset], this.state.asset)}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props?.accountContacts.length > 0 ? (
              <React.Fragment>
                <div className="system-form-control-placeholder">
                  {i18next.t('send&requestDetails.selectRecepientAccount')}
                </div>
                <VLSelect
                  isClearable={true}
                  options={this.getContactFilters()}
                  onChange={option => this.handleSelect(option, 'recipient')}
                  value={selectedRecipient}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="system-form-control-placeholder">You do not have any contacts yet.</div>

                <div className="system-form-control-box relative-box sfcb-transparent">
                  <VLButton
                    onClick={() => {
                      this.redirectToContacts();
                    }}
                    text={'Add a new contact'}
                    size="l"
                  />
                </div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.sendDate')}</p>
            <div className="system-form-control-box sfcb-transparent sfcb-date send-date-parent--send date-picker--small date-picker--modal">
              <DatePicker
                options={dateSinglePickerStart({
                  drops: 'up',
                  parentEl: '.send-date-parent--send'
                })}
                onClick={date => this.handleDateChange(date, 'start')}
                minDate={this.state.minDate}
                date={this.state.startDate}

                // value={this.props.startDate}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="sfcb-flex">
              <div>
                <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.counterpartyMsg')}</p>
                <div className="system-form-control-box sfcb-transparent">
                  <VLInput
                    name="publicNote"
                    value={this.state.publicNote}
                    onChange={this.handleFormControlChange}
                    maxLength="128"
                    size="s"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            {' '}
            <ListTransactionsContainer accountNumber={this.state.sourceAccount} maxLength={3} />
          </Col>
        </Row>
        <Row></Row>
      </>
    );
  };

  renderRequestForm = () => {
    const { assetOptions } = this.props;

    const selectedAsset = getSelectedOption(assetOptions, this.state.asset);

    const selectedRecipient = getSelectedOption(this.getContactFilters(), this.state.recipient);

    return (
      <>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.selectedAccount')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput name="sourceAccount" value={this.state.sourceAccount} size="s" />
            </div>
          </Col>
          <Col>
            {this.props?.accountContacts.length > 0 ? (
              <React.Fragment>
                <div className="system-form-control-placeholder">
                  {i18next.t('send&requestDetails.selectRecepientAccount')}
                </div>
                <VLSelect
                  isClearable={true}
                  options={this.getContactFilters()}
                  onChange={option => this.handleSelect(option, 'recipient')}
                  value={selectedRecipient}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="system-form-control-placeholder">You do not have any contacts yet.</div>

                <div className="system-form-control-box relative-box sfcb-transparent">
                  <VLButton
                    onClick={() => {
                      this.redirectToContacts();
                    }}
                    text={'Add a new contact'}
                    size="l"
                  />
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.amountToTransfer')}</p>
            <div className="system-form-control-box-custom sfcb-ammount sfcb-transparent">
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('send&requestDetails.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="asset"
                options={assetOptions}
                onChange={option => this.handleSelect(option, 'asset')}
                value={selectedAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
              />
              <VLInput
                name="amount"
                placeholder=""
                value={this.state.amountObject.value}
                onChange={this.handleAmountChange}
                onBlur={this.validateAmount}
                maxLength="20"
                noBorder
                hasBackground
                width="70%"
              />
            </div>
          </Col>
          <Col>
            {' '}
            <div className="sfcb-flex">
              <div>
                <p className="system-form-control-placeholder">{i18next.t('send&requestDetails.counterpartyMsg')}</p>
                <div className="system-form-control-box sfcb-transparent">
                  <VLInput
                    name="publicNote"
                    value={this.state.publicNote}
                    onChange={this.handleFormControlChange}
                    maxLength="128"
                    size="s"
                  />
                </div>
              </div>
              {/* <div>
                <p className="system-form-control-placeholder">
                  {i18next.t('send&requestDetails.internalMsg')}
                </p>
                <div className="system-form-control-box sfcb-transparent">
                  <FormControl
                    type="text"
                    name="privateNote"
                    className="system-form-control"
                    value={this.state.privateNote}
                    onChange={this.handleFormControlChange}
                    maxLength={30}
                  />
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
        <div className="horizontal-line-div"></div>
        <Row>
          <Col></Col>
          <Col>
            <ListTransactionsContainer accountNumber={this.state.sourceAccount} maxLength={3} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        {this.props.transactionType === SEND_TYPE && this.renderSendForm()}
        {this.props.transactionType === REQUEST_TYPE && this.renderRequestForm()}
      </>
    );
  }
}

export default SendAndRequestDetails;
