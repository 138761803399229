import { Col, ListGroup, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import {
  getDecimalByFraction,
  getProcessedBy,
  getProcessedByFirm,
  getTransactionID
} from '../../utils/transactionsHelpers';

import { FRACTIONS } from '../../enums/validation';
import { getLocalisedNumericString, sortByDateDescending } from '../../utils/generalUtils';
import i18next from 'i18next';
class ListTransactionsContainer extends PureComponent {
  state = {
    events: []
  };

  componentDidMount() {
    if (this.props.accountNumber) {
      this.props.getAccountTransactions(
        {
          account: this.props.accountNumber,
          firm: this.props?.firm
        },
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountNumber !== this.props.accountNumber) {
      if (this.props.accountNumber) {
        this.props.getAccountTransactions(
          {
            account: this.props.accountNumber,
            firm: this.props?.firm
          },
          true
        );
      }

      this.setState({
        events: []
      });
    }
    if (prevProps.events.data !== this.props.events.data) {
      this.setState({
        events: sortByDateDescending(this.props.events.data)
      });
    }
  }

  getTransactionList = () => {
    console.log('this.state.eve', this.state.events);
    return this.state.events.slice(0, this.props.maxLength);
  };

  getDummyTransactions = () => {
    return [
      {
        date: '04/25/2020',
        time: '08:58:05',
        user: 'Enterprise Inc.',
        processedBy: 'Joana Anderson',
        userid: '2485863458528',
        asset: 'USD',
        amount: '5005054'
      },
      {
        date: '03/02/2020',
        time: '10:15:18',
        user: 'The Galactic Empire',
        processedBy: 'Darth Vader',
        userid: '58402005932',
        asset: 'ETH',
        amount: '1239507'
      },
      {
        date: '01/02/2020',
        time: '21:40:57',
        user: 'The Republic',
        processedBy: 'Padme Amidala',
        userid: '0235255555',
        asset: 'BTC',
        amount: '5239507'
      }
    ];
  };

  buildListItem = item => {
    const itemDate = new Date();
    itemDate.setTime(item.time);
    const processedBy = getProcessedBy(item);
    const firm = getProcessedByFirm(item);
    const decimal = getDecimalByFraction(FRACTIONS[item.security]) || 0;

    return (
      <ListGroup.Item key={item.hash} className="transactions-list-item">
        <Row className="transactions-item-row">
          <Col className="transactions-item-regular-text">
            <p>
              {itemDate.getMonth() + 1}/{itemDate.getDate()}/{itemDate.getFullYear()}
            </p>
            {/* <p>
							{itemDate.getHours()}:{itemDate.getMinutes()}:
              {itemDate.getSeconds()}
						</p> */}
          </Col>
          <Col className="transactions-item-regular-text">
            <p>{firm || i18next.t('send&requestDetails.noCompanyName')}</p>
            <p className="transactions-item-light-text">{processedBy || ''}</p>
          </Col>
          <Col className="transactions-item-regular-text">
            {item.traderefno ? `ID: ${getTransactionID(item.traderefno, item.transactionType)}` : ''}
          </Col>
          <Col className="transactions-item-regular-text">
            {item.security} {getLocalisedNumericString(item.amount, false, decimal)}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  render() {
    const listItems = this.getTransactionList();
    console.log('listitems', listItems);

    return (
      <div className="transactions-list-container">
        <p className="system-form-control-placeholder">
          {i18next.t('send&requestDetails.last') +
            ` ${listItems.length ? listItems.length : i18next.t('send&requestDetails.few')} ` +
            i18next.t('send&requestDetails.transactions')}
        </p>
        <ListGroup variant="flush" className="transactions-list-group">
          {this.props.accountNumber && listItems.map(item => this.buildListItem(item))}
        </ListGroup>
      </div>
    );
  }
}

export default ListTransactionsContainer;
