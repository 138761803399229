import { Row } from 'react-bootstrap';
import React from 'react';
import i18next from 'i18next';
import { renderInput, renderSelect } from '../helpers';
import { TiDelete } from 'react-icons/ti';
import { useCreateAccountContext, EXCHANGES_LIST_NAME, HAS_EXCHANGES_VALUES } from '../context';
import VLButton from '../../../Buttons/VLButton';
import { IoMdAdd } from 'react-icons/io';

const HAS_EXCHANGES_OPTIONS = [
  { value: HAS_EXCHANGES_VALUES.YES, label: 'Yes' },
  { value: HAS_EXCHANGES_VALUES.NO, label: 'No' }
];

const AccountExchangesStep = ({ onNext, confirmButtonText }) => {
  const { formState, actions } = useCreateAccountContext();
  const { exchanges, hasExchangesOption } = formState;
  const {
    addListItem,
    removeListItem,
    updateListItemError,
    updateListItemValue,
    clearListItemErrors,
    setHasExchanges
  } = actions;

  const handleOnChange = ({ target: { name, value } }) => {
    const [index, field] = name.split('-');
    updateListItemValue(EXCHANGES_LIST_NAME, +index, field, value);
  };

  const handleHasExchangesSelectChange = ({ value }) => {
    setHasExchanges(value);
  };

  const handleAddExchange = () => {
    addListItem(EXCHANGES_LIST_NAME);
  };

  const handleRemoveExchange = index => {
    removeListItem(EXCHANGES_LIST_NAME, index);
  };

  const handleComplete = () => {
    clearListItemErrors(EXCHANGES_LIST_NAME);

    if (!validateForm()) return;

    onNext();
  };

  const validateForm = () => {
    if (!hasExchangesOption) return true;

    let isValid = true;

    exchanges.forEach(({ nickname }, index) => {
      if (!nickname) {
        updateListItemError(EXCHANGES_LIST_NAME, index, 'nickname', 'Required');
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <div className="add-wallets-step">
      {renderSelect({
        name: 'hasExchanges',
        label: 'Do you have any exchange accounts?',
        value: HAS_EXCHANGES_OPTIONS.find(o => o.value === hasExchangesOption),
        onChange: handleHasExchangesSelectChange,
        options: HAS_EXCHANGES_OPTIONS,
        isRequired: true
      })}

      {hasExchangesOption === HAS_EXCHANGES_VALUES.YES ? (
        <>
          {exchanges.map((e, index) => (
            <ExchangeForm
              key={index}
              exchange={e}
              index={index}
              onChange={handleOnChange}
              onRemove={handleRemoveExchange}
            />
          ))}
          <Row noGutters className="account-wallets-step-add-button-row">
            <VLButton text={'Another exchange'} leftIcon={<IoMdAdd />} size={'l'} onClick={handleAddExchange} />
          </Row>
        </>
      ) : null}

      <Row noGutters className="summary-row">
        <p className="mandatory-text">
          {i18next.t('createAccountModal.fields')}
          <span className="mandatory-star">*</span>
          {i18next.t('createAccountModal.mandatory')}
        </p>
      </Row>
      <div className="external-deposit-footer-bar">
        <VLButton disabled={!hasExchangesOption} onClick={handleComplete} width="100%" text={confirmButtonText} />
      </div>
    </div>
  );
};

const ExchangeForm = ({ exchange: { nickname, errors }, index, onRemove, onChange }) => {
  return (
    <div className="account-wallets-step-wallet-form" key={index}>
      <span className="account-wallets-step-wallet-form-title">{`Exchange ${index + 1}`}</span>
      {index !== 0 ? (
        <TiDelete
          onClick={() => onRemove(index)}
          className="hourglass common-icon account-wallets-step-delete"
          size="1.5em"
        />
      ) : null}

      {renderInput({
        name: `${index}-nickname`,
        label: 'Nickname',
        value: nickname,
        onChange: onChange,
        isRequired: true,
        error: errors.nickname
      })}
    </div>
  );
};

export default AccountExchangesStep;
