import * as CONSTANTS from '../constants/transactions';

export const resetFields = () => ({
  type: CONSTANTS.RESET_FIELDS
});
///////////////////////////////////////
export const getDepositAccounts = data => ({
  type: CONSTANTS.FETCH_DEPOSIT_ACCOUNTS,
  data: data
});

export const getDepositAccountsSuccess = data => ({
  type: CONSTANTS.FETCH_DEPOSIT_ACCOUNTS_SUCCESS,
  data: data
});

export const getDepositAccountsError = error => ({
  type: CONSTANTS.FETCH_DEPOSIT_ACCOUNTS_ERROR,
  error
});

/////////////////////////////////////////////////
////////////////////////////////////////////////

export const getAssets = data => ({
  type: CONSTANTS.FETCH_ASSETS,
  data: data
});

export const getAssetsSuccess = data => ({
  type: CONSTANTS.FETCH_ASSETS_SUCCESS,
  data: data
});

export const getAssetsError = error => ({
  type: CONSTANTS.FETCH_ASSETS_ERROR,
  error
});
export const getAssetsByProduct = data => ({
  type: CONSTANTS.FETCH_ASSETS_BY_PRODUCT,
  data: data
});

export const getAssetsByProductSuccess = data => ({
  type: CONSTANTS.FETCH_ASSETS_BY_PRODUCT_SUCCESS,
  data: data
});

export const getAssetsByProductError = error => ({
  type: CONSTANTS.FETCH_ASSETS_BY_PRODUCT_ERROR,
  error
});

export const getAssetPairs = data => ({
  type: CONSTANTS.FETCH_ASSET_PAIRS,
  data: {
    type: 'PAIRS'
  }
});

export const getAssetPairsSuccess = data => ({
  type: CONSTANTS.FETCH_ASSET_PAIRS_SUCCESS,
  data: data
});

export const getAssetPairsError = error => ({
  type: CONSTANTS.FETCH_ASSET_PAIRS_ERROR,
  error
});

/////////////////////////////
export const getTradingAssets = data => ({
  type: CONSTANTS.FETCH_TRADING_ASSETS,
  data: {
    type: 'TRADING'
  }
});

export const getTradingAssetsSuccess = data => ({
  type: CONSTANTS.FETCH_TRADING_ASSETS_SUCCESS,
  data: data
});

export const getTradingAssetsError = error => ({
  type: CONSTANTS.FETCH_TRADING_ASSETS_ERROR,
  error
});

///////////////////////////
export const getOrderHistory = data => ({
  type: CONSTANTS.FETCH_ORDER_HISTORY,
  data: data
});

export const getOrderHistorySuccess = data => ({
  type: CONSTANTS.FETCH_ORDER_HISTORY_SUCCESS,
  data: data
});

export const getOrderHistoryError = error => ({
  type: CONSTANTS.FETCH_ORDER_HISTORY_ERROR,
  error
});
/////////////////////////////////////////////////////////////////
export const getTradeHistory = data => ({
  type: CONSTANTS.FETCH_TRADE_HISTORY,
  data: data
});

export const getTradeHistorySuccess = data => ({
  type: CONSTANTS.FETCH_TRADE_HISTORY_SUCCESS,
  data: data
});

export const getTradeHistoryError = error => ({
  type: CONSTANTS.FETCH_TRADE_HISTORY_ERROR,
  error
});
//////////////////////////////////////////////////////////////////

export const sendDeposit = data => ({
  type: CONSTANTS.SEND_DEPOSIT,
  data: data
});

export const sendDepositSuccess = data => ({
  type: CONSTANTS.SEND_DEPOSIT_SUCCESS,
  data: data
});

export const sendDepositError = error => ({
  type: CONSTANTS.SEND_DEPOSIT_ERROR,
  error
});

/////////////////////////////

export const sendRedeem = data => ({
  type: CONSTANTS.SEND_REDEEM,
  data: data
});

export const sendRedeemSuccess = data => ({
  type: CONSTANTS.SEND_REDEEM_SUCCESS,
  data: data
});

export const sendRedeemError = error => ({
  type: CONSTANTS.SEND_REDEEM_ERROR,
  error
});

//////////////////////////////////////////
export const getAccountTransactions = (data, forMiniList = false) => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS,
  data: data,
  forMiniList: forMiniList
});
export const getAccountEventsSuccess = (data, forMiniList = true) => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS_SUCCESS,
  data: data,
  forMiniList: forMiniList
});

export const getAccountEventsError = error => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS_ERROR,
  error
});

export const getAccountEventsAll = data => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL,
  data: data
});

export const getAccountEventsAllSuccess = data => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL_SUCCESS,
  data: data
});

export const getAccountEventsAllError = error => ({
  type: CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL_ERROR,
  error
});

/////////////////////////////////////////
export const getRecipientFirms = data => ({
  type: CONSTANTS.FETCH_FIRMS,
  data: data
});
export const getRecipientFirmsSuccess = data => ({
  type: CONSTANTS.FETCH_FIRMS_SUCCESS,
  data: data
});

export const getRecipientFirmsError = error => ({
  type: CONSTANTS.FETCH_FIRMS_ERROR,
  error
});

///////////////////////////////////////////
export const searchRecipientAccounts = data => ({
  type: CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM,
  data: data
});
export const searchRecipientAccountsSuccess = data => ({
  type: CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM_SUCCESS,
  data: data
});

export const searchRecipientAccountsError = error => ({
  type: CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM_ERROR,
  error
});

export const updateTransactionsDB = data => ({
  type: CONSTANTS.UPDATE_TRANSACTION_DB,
  data: data
});

export const updateTransactionsDBSuccess = data => ({
  type: CONSTANTS.UPDATE_TRANSACTION_DB_SUCCESS,
  data: data
});

export const updateTransactionsDBError = error => ({
  type: CONSTANTS.UPDATE_TRANSACTION_DB_ERROR,
  error
});

export const resetTransactionHash = () => ({
  type: CONSTANTS.RESET_HASH
});

/////////////// FE ONLY

export const toggleModal = data => ({
  type: CONSTANTS.TOGGLE_DISPLAY_MODAL_DASHBOARD,
  data
});

export const updateCryptoRates = data => ({
  type: CONSTANTS.UPDATE_CRYPTO_RATES,
  data
});
///////////////////////////////////////////
export const getPrices = data => ({
  type: CONSTANTS.GET_PRICES,
  data
});
export const getPricesSuccess = data => ({
  type: CONSTANTS.GET_PRICES_SUCCESS,
  data
});
export const getPricesError = error => ({
  type: CONSTANTS.GET_PRICES_ERROR,
  error
});

export const updatePrices = data => ({
  type: CONSTANTS.UPDATE_PRICES,
  data
});
export const updatePricesSuccess = data => ({
  type: CONSTANTS.UPDATE_PRICES_SUCCESS,
  data
});
export const updatePricesError = error => ({
  type: CONSTANTS.UPDATE_PRICES_ERROR,
  error
});

///////////////////////////////////////
export const getAllPrices = data => ({
  type: CONSTANTS.GET_ALL_PRICES,
  data
});
export const getAllPricesSuccess = data => ({
  type: CONSTANTS.GET_ALL_PRICES_SUCCESS,
  data
});
export const getAllPricesError = error => ({
  type: CONSTANTS.GET_ALL_PRICES_ERROR,
  error
});

///////////////////////////////////////

export const cleanTradeHistory = () => ({
  type: CONSTANTS.CLEAN_TRADE_HISTORY
});
export const clearAccountEvents = () => ({
  type: CONSTANTS.CLEAR_ACCOUNT_EVENTS
});

export const fetchCanceledOrders = data => ({
  type: CONSTANTS.FETCH_CANCELED_ORDERS,
  data
});
export const fetchCanceledOrdersSuccess = data => ({
  type: CONSTANTS.FETCH_CANCELED_ORDERS_SUCCESS,
  data
});
export const fetchCanceledOrdersError = error => ({
  type: CONSTANTS.FETCH_CANCELED_ORDERS_ERROR,
  error
});

export const clearCanceledOrders = () => ({
  type: CONSTANTS.CLEAR_CANCELED_ORDERS
});

export const fetchOpenOrders = data => ({
  type: CONSTANTS.FETCH_OPEN_ORDERS,
  data
});
export const fetchOpenOrdersSuccess = data => ({
  type: CONSTANTS.FETCH_OPEN_ORDERS_SUCCESS,
  data
});
export const fetchOpenOrdersError = error => ({
  type: CONSTANTS.FETCH_OPEN_ORDERS_ERROR,
  error
});

export const clearOpenOrders = () => ({
  type: CONSTANTS.CLEAR_OPEN_ORDERS
});
