import { useState } from 'react';
import { AUTHORIZATIONS, AUTHORIZATION_KEYS, TURN_OFF_AUTH_KEY } from '../../enums/roles';

export const useAccountPermissions = ({ updatePermissionsFn, onPermissionsUpdated }) => {
  const [removeAccessData, setRemoveAccessData] = useState(null);
  const [timelockAccessData, setTimelockAccessData] = useState(null);

  const handleRemoveAllPermissions = ({ userId, accountId }) => {
    let updateData = {
      userid: userId,
      account: accountId,
      access: TURN_OFF_AUTH_KEY[AUTHORIZATION_KEYS.VIEW]
    };

    updatePermissionsFn({
      payload: updateData,
      successCallback: () => {
        onPermissionsUpdated();
        setRemoveAccessData(null);
      }
    });
  };

  const handleAccessSwitch = ({ target }) => {
    const splittedId = target.id.split('|');
    const authorizationCode = splittedId[0];
    const userId = splittedId[1];
    const accountId = splittedId[2];

    let updateData = {
      userid: userId,
      account: accountId,
      access: target.checked ? AUTHORIZATIONS[authorizationCode] : TURN_OFF_AUTH_KEY[authorizationCode]
    };

    updatePermissionsFn({
      payload: updateData,
      successCallback: () => {
        onPermissionsUpdated();
      }
    });
  };

  const updateTimeLock = (startRange = '', endRange = '', days = []) => {
    const formattedDays = days.join();

    const updateData = {
      userid: timelockAccessData.user.userid,
      account: timelockAccessData.account.account,
      timelock_start: startRange,
      timelock_end: endRange,
      timelock_days: formattedDays
    };
    setTimelockAccessData(null);

    updatePermissionsFn({
      payload: updateData,
      successCallback: () => {
        onPermissionsUpdated();
      }
    });
  };

  const removeTimeLock = (userId, accountId) => {
    let updateData = {
      userid: userId,
      account: accountId,
      timelock: 'D'
    };

    updatePermissionsFn({
      payload: updateData,
      successCallback: () => {
        onPermissionsUpdated();
      }
    });
  };

  const handleAddViewPermission = (userId, accountId) => {
    let updateData = {
      userid: userId,
      account: accountId,
      access: AUTHORIZATIONS.VIEW
    };

    updatePermissionsFn({
      payload: updateData,
      successCallback: () => {
        onPermissionsUpdated();
      }
    });
  };

  return {
    removeAccessData,
    timelockAccessData,
    setRemoveAccessData,
    setTimelockAccessData,
    handleRemoveAllPermissions,
    handleAccessSwitch,
    updateTimeLock,
    removeTimeLock,
    handleAddViewPermission
  };
};
