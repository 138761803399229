import { useReducer } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { createActions } from './actions';
import { createAccountReducer, defaultState } from './reducer';

const CreateAccountContext = createContext();

const CreateAccountContextProvider = ({ children }) => {
  const [formState, dispatch] = useReducer(createAccountReducer, defaultState);

  const value = {
    formState,
    actions: useMemo(() => createActions(dispatch), [dispatch])
  };

  return <CreateAccountContext.Provider value={value}>{children}</CreateAccountContext.Provider>;
};

const useCreateAccountContext = () => {
  const context = useContext(CreateAccountContext);

  if (!context?.formState) {
    throw Error('useAccountContext has to be used under the CreateAccountContextProvider');
  }

  const value = useMemo(
    () => ({
      formState: context.formState,
      actions: context.actions
    }),
    [context.actions, context.formState]
  );

  return value;
};

const CreateAccountContextConsumer = CreateAccountContext.Consumer;

export { CreateAccountContext, CreateAccountContextProvider, CreateAccountContextConsumer, useCreateAccountContext };
