import './DatePicker.scss';

import DatePicker from './DatePicker';

export const dateTransactionsOptions = {
  drops: 'up',
  parentEl: '.date-parent',

  locale: {
    format: 'MM/DD/YYYY',
    maxDate: 'today'
  }
};

const singleDatePickerConfig = {
  locale: {
    format: 'MM/DD/YYYY',
    maxDate: 'today'
  },
  singleDatePicker: true,
  autoApply: true
};

export const dateSinglePickerStart = ({ drops = 'up', parentEl = '', date }) => {
  return {
    ...singleDatePickerConfig,
    drops,
    parentEl,
    startDate: date
  };
};
export const dateSinglePickerEnd = ({ drops = 'up', parentEl = '', date }) => {
  return {
    ...singleDatePickerConfig,
    drops,
    parentEl,
    startDate: date,
    endDate: date
  };
};

export default DatePicker;
