import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { cleanTradeHistory, getTradeHistory } from '../../redux/actions/transactions';
const useTradeHistory = () => {
  const PAGE_SIZE = 100;

  const tradeHistory = useSelector(state => state.transactions.tradeHistory);
  const refreshOrderHistory = useSelector(state => state.websocket.timestamp);
  const dispatch = useDispatch();
  const fromRef = React.useRef(tradeHistory?.data?.length);
  useEffect(() => {
    fromRef.current = tradeHistory?.data?.length;
  }, [tradeHistory?.data?.length]);

  useEffect(() => {
    dispatch(cleanTradeHistory());

    // Necessary delay in order for FI to actually store transaction
    setTimeout(() => {
      dispatch(getTradeHistory({ from: 0, size: fromRef.current + PAGE_SIZE }));
    }, 4000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOrderHistory]);

  const loadTradeHistory = () => {
    if (tradeHistory.data.length === tradeHistory.totalLength) return;
    dispatch(
      getTradeHistory({
        from: fromRef.current,
        size: PAGE_SIZE
      })
    );
  };

  return { loadTradeHistory, tradeHistory };
};

export default useTradeHistory;
