import { connect } from 'react-redux';
import { updateAssetPair } from '../../redux/actions/trade';
import AdvancedTrading from './AdvancedTrading';

const mapStateToProps = state => ({
  assetPairs: state.trade.assetPairs.data
});

const mapDispatchToProps = {
  updateAssetPair
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedTrading);
