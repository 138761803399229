function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n
    .replace(/\D/g, '') // strip non numeric chars
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // add comma where needed
    .replace(/^0{2}/, '0'); // remove leading zero
}

function digitsOnly(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '');
}

/**
 * Used to handle onChange and onBlur events for the currency input
 * add decimals is true for onBlur and false for onChange
 *
 * @param {*} value decimal value for some currency
 * @param {*} numberOfDecimals number of decimals desired in the output
 * @param {*} addDecimals should missing decimals be added
 * @returns formatted currency value
 */
function formatCurrency(value, numberOfDecimals, addDecimals) {
  // get input value
  var input_val = value;

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);
    if (addDecimals && !left_side) {
      left_side = '0';
    }

    // validate right side
    right_side = digitsOnly(right_side);

    // On blur make sure 2 numbers after decimal
    if (addDecimals) {
      right_side += Array(numberOfDecimals).fill('0').join('');
    }

    // Cut off extra decimals
    right_side = right_side.substring(0, numberOfDecimals);

    // join number by .
    input_val = left_side + '.' + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);

    // final formatting
    if (addDecimals) {
      input_val += '.' + Array(numberOfDecimals).fill('0').join('');
    }
  }

  return input_val;
}

const parseCurrency = value => {
  if (!value) return 0;

  return parseFloat(value.replaceAll(',', ''));
};

export { formatCurrency, parseCurrency, formatNumber, digitsOnly };
