import * as ACTIONS from '../../actions/settings';
import { getUserProfile } from '../../actions/login';
import * as CONSTANTS from '../../constants/settings';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { default as URLS } from '../../constants/urls';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import RequestBuilder from '../../../request/RequestBuilder';

function* getUsers(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getUsersSuccess(response));

    yield put(ACTIONS.getAccountsRequest({ ...action.data }));
  } catch (err) {
    yield put(ACTIONS.getUsersError(err));
  }
}

function* getAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data?.accountSearchData)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAccountsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getAccountsError(err));
  }
}

function* searchAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.searchAccountsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.searchAccountsError(err));
  }
}

function* getFirmUserDetails(action) {
  try {
    const url = `${URLS[action.type]}/${action.data.userId}`;

    const response = yield RequestBuilder.default()
      .get(url)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getFirmUserDetailsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getFirmUserDetailsError(err));
  }
}

function* createWallet(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.createWalletSuccess(response));
    yield put(getUserProfile());
  } catch (err) {
    yield put(ACTIONS.createWalletError(err));
  }
}

function* deleteWallet(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.deleteWalletSuccess(response));
  } catch (err) {
    yield put(ACTIONS.deleteWalletError(err));
  }
}

function* searchFirms(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.searchFirmsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.searchFirmsError(err));
  }
}

function* updateUserPermissions(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data?.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updatePermissionsSuccess(response));
    yield put(ACTIONS.getUsersRequest(action.data?.searchQuery));
    action.data?.successCallback?.();
  } catch (err) {
    action.data?.errorCallback?.();
    yield put(ACTIONS.updatePermissionsError(err));
  }
}

function* updateWhitelist(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data?.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updateWhitelistSuccess(response));
    yield put(ACTIONS.getUsersRequest(action.data?.searchQuery));
    if (action.data?.callback) {
      action.data.callback();
    }
  } catch (err) {
    yield put(ACTIONS.updateWhitelistError(err));
  }
}

function* toggleUserActive(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data?.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.toggleUserActiveSuccess(response));
    action.data?.successCallback?.();
  } catch (err) {
    action.data?.errorCallback?.();
    yield put(ACTIONS.toggleUserActiveError(err));
  }
}

function* createUser(action, a) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data?.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getUsersRequest(action.data?.searchQuery));

    if (response.message === 'success') {
      toast.success(i18next.t('toasts.successUser'));
      action.data.successCallback();
    }
  } catch (err) {
    toast.error(err.reason);
    action.data.errorCallback();
    yield put(ACTIONS.createUserError(err));
  }
}

function* createAccount(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data?.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    if (response.message === 'success') {
      action.data.successCallback();
      toast.success(i18next.t('toasts.successAccount'));
    }

    yield put(ACTIONS.getAccountsRequest(action.data?.searchQuery));
  } catch (err) {
    yield put(ACTIONS.createUserError(err));
  }
}

function* checkAccountName(action) {
  try {
    yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    // This must not be okay
    if (action.data?.q?.length <= 0) {
      action.data.errorCallback();
    } else {
      action.data.successCallback();
    }
  } catch (err) {
    action.data.errorCallback();
  }
}

function* queryExistingUser(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    // This must not be okay
    if (response.data?.length > 0) {
      action.data.errorCallback();
    } else {
      action.data.successCallback();
    }
  } catch (err) {
    action.data.errorCallback();
  }
}

export default function* settingsSaga() {
  yield takeEvery(CONSTANTS.CREATE_WALLET, createWallet);
  yield takeLatest(CONSTANTS.DELETE_WALLET, deleteWallet);
  yield takeLatest(CONSTANTS.FETCH_USERS, getUsers);
  yield takeLatest(CONSTANTS.FETCH_ACCOUNTS, getAccounts);
  yield takeLatest(CONSTANTS.SEARCH_ACCOUNTS, searchAccounts);
  yield takeLatest(CONSTANTS.SEARCH_FIRMS, searchFirms);
  yield takeLatest(CONSTANTS.UPDATE_USER_PERMISSIONS, updateUserPermissions);
  yield takeLatest(CONSTANTS.TOGGLE_USER_ACTIVE, toggleUserActive);
  yield takeLatest(CONSTANTS.CREATE_USER, createUser);
  yield takeLatest(CONSTANTS.CREATE_ACCOUNT, createAccount);
  yield takeLatest(CONSTANTS.UPDATE_USER_WHITELIST, updateWhitelist);
  yield takeLatest(CONSTANTS.CHECK_ACCOUNT_NAME, checkAccountName);
  yield takeLatest(CONSTANTS.QUERY_EXISTING_USER, queryExistingUser);
  yield takeLatest(CONSTANTS.GET_FIRM_USER_DETAILS_REQUEST, getFirmUserDetails);
}
