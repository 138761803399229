import { getAllPrices } from '../../redux/actions/transactions';

import PeriodicalPriceUpdateComponent from './PeriodicalPriceUpdateComponent';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  cryptoRates: state.transactions.cryptoRates
});

const mapDispatchToProps = {
  getAllPrices
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicalPriceUpdateComponent);
