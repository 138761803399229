import { Col, Container, Overlay, Row, Tooltip } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { capitalizeFirstLetter, findFiatCurrencySymbol, splitDateTime } from '../../../utils/generalUtils';

import { REQUEST_TYPE, SEND_TYPE } from '../../../enums/validation';
import { getSecurityLabel } from '../../../utils/DOMHelpers';

import './SendAndRequestSummary.scss';
import i18next from 'i18next';
class SendAndRequestSummary extends PureComponent {
  constructor() {
    super();
    this.state = {
      showMessage: false,
      showFullCounterpartyMessage: false
    };

    this.counterpartyMessage = null;

    this.setCounterpartyMessageRef = element => {
      this.counterpartyMessage = element;
    };

    this.showFullCounterpartyMessage = this.showFullCounterpartyMessage.bind(this);
    this.hideFullCounterpartyMessage = this.hideFullCounterpartyMessage.bind(this);
  }

  showFullCounterpartyMessage = () => {
    this.setState({ showMessage: true });
  };

  hideFullCounterpartyMessage = () => {
    this.setState({ showMessage: false });
  };

  getSpecificText = () => {
    let text = {
      titleType: i18next.t('send&requestSummary.titleTypeTransfer'),
      sourceAccountType: i18next.t('send&requestSummary.sourceAccountType'),
      destinationAccountType: i18next.t('send&requestSummary.destinationAccountType'),
      counterpartyFullName: i18next.t('send&requestSummary.counterparty'),
      lastParagraph2: i18next.t('send&requestSummary.lastParagraph2a')
    };
    if (this.props.transactionType === REQUEST_TYPE) {
      text = {
        titleType: i18next.t('send&requestSummary.titleTypeRequest'),
        sourceAccountType: i18next.t('send&requestSummary.sourceAccountTypeReq'),
        destinationAccountType: i18next.t('send&requestSummary.destinationAccountTypeReq'),
        counterpartyFullName: i18next.t('send&requestSummary.counterparty'),
        lastParagraph2: i18next.t('send&requestSummary.lastParagraph2b')
      };
    }

    return text;
  };

  getFormattedDate = date => {
    const { day, month, year } = splitDateTime(date, true);
    return `${month}/${day}/${year}`;
  };

  componentDidMount = () => {
    if (this.counterpartyMessage.offsetHeight < this.counterpartyMessage.scrollHeight) {
      this.setState({ showFullCounterpartyMessage: true });
    }
  };

  render() {
    const { titleType, sourceAccountType, destinationAccountType, counterpartyFullName, lastParagraph2 } =
      this.getSpecificText();

    const selectedCounterparty = this.props.accountContacts?.find(
      contact => contact.accountNumber === this.props.recipient
    );

    return (
      <div className="send-request-container">
        <div className="ed-info-wrapper">
          <p className="ed-pretitle">
            {i18next.t('send&requestSummary.paragraphInTitleType') +
              `  ${titleType} ` +
              i18next.t('send&requestSummary.paragraphInTitleType2')}
          </p>
          <h2 className="external-deposit-h1-label">
            <span className="ed-asset">{getSecurityLabel(this.props.asset)}</span>
            {findFiatCurrencySymbol(this.props.asset)}
            {this.props.amountObject.display}
          </h2>
        </div>
        <Container>
          <Row className="summary-row">
            <Col>
              <p className="ed-pretitle-small">{sourceAccountType}</p>
            </Col>
            <Col>
              <h3 className="external-deposit-title-label">{this.props.sourceAccount}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="ed-pretitle-small">{destinationAccountType}</p>
            </Col>
            <Col>
              <h3 className="external-deposit-title-label">{this.props.recipient}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="ed-pretitle-small">{counterpartyFullName}</p>
            </Col>
            <Col>
              <h3 className="external-deposit-title-label">
                {selectedCounterparty.fullName || selectedCounterparty.ownerName}
              </h3>
            </Col>
          </Row>
          {this.props.transactionType === SEND_TYPE && (
            <Row>
              <Col>
                <p className="ed-pretitle-small">{i18next.t('send&requestSummary.sendDate')}</p>
              </Col>
              <Col>
                <h3 className="external-deposit-title-label" onClick={this.showModal}>
                  {this.getFormattedDate(new Date(this.props.startDate))}
                </h3>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <p className="ed-pretitle-small">{i18next.t('send&requestSummary.counterpartyMsg')}</p>
            </Col>
            <Col className="dynamic-col">
              <h3
                ref={this.setCounterpartyMessageRef}
                className="external-deposit-title-label"
                onMouseOver={this.state.showFullCounterpartyMessage ? this.showFullCounterpartyMessage : undefined}
                onMouseLeave={this.state.showFullCounterpartyMessage ? this.hideFullCounterpartyMessage : undefined}
              >
                {this.props.publicNote || 'N/A'}
              </h3>
              <Overlay target={this.counterpartyMessage} show={this.state.showMessage} placement="bottom">
                {props => <Tooltip {...props}>{this.props.publicNote}</Tooltip>}
              </Overlay>
            </Col>
          </Row>
        </Container>
        <hr className="horizontal-line" />

        {this.props.repeat && (
          <>
            <h3 className="send-request-summary-title  summary-label-text">
              {i18next.t('send&requestSummary.repeatingPayment')}
            </h3>
            <Row className="summary-row">
              <Col>
                <p className="send-request-summary-label summary-label-text">
                  {i18next.t('send&requestSummary.paymentInterval')}
                </p>
                <h3 className="send-request-summary-text summary-h3-text summary-darker-text">
                  {capitalizeFirstLetter(this.props.repeatDuration)}
                </h3>
              </Col>
              <Col>
                <p className="send-request-summary-label summary-label-text">
                  {i18next.t('send&requestSummary.endDate')}
                </p>
                <h3 className="send-request-summary-text summary-h3-text summary-darker-text">
                  {this.getFormattedDate(new Date(this.props.endDate))}
                </h3>
              </Col>
            </Row>
          </>
        )}
        <Row className="summary-last-paragraph-container">
          <p className="external-deposit-central-text ed-text">
            {i18next.t('send&requestSummary.Msg1')}
            <br />
            <br />
            {lastParagraph2}
          </p>
        </Row>
      </div>
    );
  }
}

export default SendAndRequestSummary;
