import { ACCOUNT_DISPLAY, USER_DISPLAY } from '../../enums/validation';
import { Container, Form, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { createUserSearchQueryObject } from '../../utils/requestHelpers';

import { BsPlus } from 'react-icons/bs';
import CreateUserModal from '../../components/modals/CreateUserModal';
import CssFilterTransition from '../../components/CssFilterTransition';
import Pagination from '../../components/Pagination/Pagination';
import { ROLES } from '../../enums/roles';
import RegularBtn from '../../components/Buttons/VLButton';
import { SearchQueryContext } from '../../contexts/SearchQueryContext';
import Select from 'react-select';
import { getTopInputStringsBasedOnDisplayType } from '../../utils/DOMHelpers';
import { isUserDisplay } from '../../utils/validators';
import i18next from 'i18next';
import VLIconButton from '../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import VLButton from '../../components/Buttons/VLButton';
import { IoMdAdd } from 'react-icons/io';
import VLInput from '../../components/VLInput';
const ROW_LIMIT = 10;

const defaultState = {
  totalPages: 0,
  searchQuery: '',
  roleFilter: '',
  dataPageNumber: 0,
  showNewUserModal: false,
  showNewAccountModal: false
};
class FirmUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      accounts: [],
      selectedRow: null,
      searchQuery: '',
      roleFilter: '',
      showNewUserModal: false,
      showNewAccountModal: false,
      dataPageNumber: 0,
      totalPages: 0,
      isFilterOpen: false
    };
    this.timer = null;
  }

  static contextType = SearchQueryContext;

  componentDidMount() {
    let { setSearchQueryType } = this.context;
    const searchType = isUserDisplay(this.props.type) ? USER_DISPLAY : ACCOUNT_DISPLAY;
    setSearchQueryType(searchType);
    this.props.getUsersRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let { setSearchQueryType } = this.context;
      const searchType = isUserDisplay(this.props.type) ? USER_DISPLAY : ACCOUNT_DISPLAY;
      // this.props.getUsersRequest();
      this.setState({
        ...defaultState
      });
      setSearchQueryType(searchType);
    }
    if (
      this.props.users.loaded !== prevProps.users.loaded ||
      this.props.accounts.loaded !== prevProps.accounts.loaded
    ) {
      this.setState({
        users: this.props.users.data,
        accounts: this.props.accounts.data,
        totalPages: isUserDisplay(this.props.type)
          ? Math.ceil(this.props.users.data.length / ROW_LIMIT)
          : Math.ceil(this.props.accounts.data.length / ROW_LIMIT)
        // dataPageNumber: this.state.searchQuery ? this.state.dataPageNumber : 0
      });
    }
    if (
      prevState.searchQuery !== this.state.searchQuery ||
      prevState.roleFilter.value !== this.state.roleFilter.value
    ) {
      this.handleSearchData();
    }
  }

  changeExpandedRow = id => {
    this.setState({
      selectedRow: id === this.state.selectedRow ? null : id,
      accountsPageNumber: 0
    });
  };

  toggleModal = fieldName => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`]
    });
  };

  handleTopInputChange = (event, data) => {
    const { target } = event;

    if (target) {
      console.log(`${[target.name]}: ${target.value}`);
      this.setState(
        {
          [target.name]: target.value
        },
        () => {
          let { setSearchQuery, setRoleQuery } = this.context;
          setSearchQuery(this.state.searchQuery);
          setRoleQuery(this.state.roleFilter.value);
        }
      );
    } else {
      const { id, label } = event;
      const { name } = data;
      const value = ROLES.All === id ? undefined : id;
      this.setState(
        {
          [name]: {
            label,
            name,
            value
          }
        },
        () => {
          let { setSearchQuery, setRoleQuery } = this.context;
          setSearchQuery(this.state.searchQuery);
          setRoleQuery(this.state.roleFilter.value);
        }
      );
    }
  };

  handleSearchData = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.state.searchQuery) {
        const searchField = isUserDisplay(this.context.searchQueryType) ? 'userSearchData' : 'accountSearchData';
        this.props.getUsersRequest({
          [searchField]: createUserSearchQueryObject(this.state.searchQuery, this.state.roleFilter.value)
        });
      } else if (this.state.roleFilter.value) {
        this.props.getUsersRequest({
          userSearchData: createUserSearchQueryObject(undefined, this.state.roleFilter.value)
        });
      } else {
        this.props.getUsersRequest();
      }
    }, 750);
  };

  handleCreateNewUser = data => {
    const actionData = {
      payload: data,
      successCallback: data => {
        this.toggleModal('NewUser');
      },
      errorCallback: () => {
        console.log('error');
      }
    };
    this.props.createUserRequest(actionData);
  };

  handlePageClick = data => {
    this.setState({
      dataPageNumber: data.selected
    });
  };

  getPagedData = () => {
    const startIndex = this.state.dataPageNumber * ROW_LIMIT;
    if (isUserDisplay(this.props.type)) {
      return this.state.users.slice(startIndex, startIndex + ROW_LIMIT);
    } else {
      return this.state.accounts.slice(startIndex, startIndex + ROW_LIMIT);
    }
  };

  buildTopInputs = () => {
    const { t } = this.props;

    const { searchInputPlaceholder, selectInputPlaceholder, searchLabel, selectLabel, dropdownOptions } =
      getTopInputStringsBasedOnDisplayType(this.props.type, t);
    return (
      <React.Fragment>
        <div
          className={`user-form-control-container
        ${!isUserDisplay(this.props.type) ? 'settings-search-input-container--account' : ''}`}
        >
          <Form.Label className="search-user-form-label">{searchLabel}</Form.Label>
          <div className="user-form-control-row">
            <VLInput
              size="s"
              type="text"
              name="searchQuery"
              value={this.state.searchQuery}
              onChange={this.handleTopInputChange}
              placeholder={searchInputPlaceholder}
              isSearch
            />
          </div>
        </div>

        {isUserDisplay(this.props.type) && (
          <div className="user-form-control-container">
            <Form.Label className="search-user-form-label">{selectLabel}</Form.Label>
            <Select
              name="roleFilter"
              className="setting-dropdown--role"
              classNamePrefix="user-form-select"
              placeholder={selectInputPlaceholder}
              defaultValue={selectInputPlaceholder}
              options={dropdownOptions}
              onChange={this.handleTopInputChange}
              value={this.state.roleFilter}
            />
          </div>
        )}
        <RegularBtn
          text={i18next.t('transactionsFilters.clearFilter')}
          styleType="clear"
          iconRight
          icon={BsPlus}
          onClick={this.clearFilters}
        />
      </React.Fragment>
    );
  };

  handleToggle = () => {
    this.setState(prev => ({
      isFilterOpen: !prev.isFilterOpen
    }));
  };

  clearFilters = () => {
    this.setState({
      roleFilter: '',
      searchQuery: ''
    });
  };

  render() {
    const pagedData = this.getPagedData();
    const TableComponent = this.props.tableComponent;
    const data = {
      users: isUserDisplay(this.props.type) ? pagedData : this.state.users,
      accounts: !isUserDisplay(this.props.type) ? pagedData : this.state.accounts
    };

    return (
      <div className="chassis-interior-content settings">
        {this.state.showNewUserModal && (
          <CreateUserModal onClose={() => this.toggleModal('NewUser')} onCreateUser={this.handleCreateNewUser} />
        )}
        <Row noGutters className="user-table">
          <Row noGutters className="chassis-content-header-settings chassis-content-header">
            <h5>
              {isUserDisplay(this.props.type)
                ? i18next.t('users/accounts.usersManagement')
                : i18next.t('users/accounts.accountsManagement')}
            </h5>
            <div className="settings-button-wrap">
              {this.props.canEditUsers && (
                <VLButton text={'Create User'} onClick={() => this.toggleModal('NewUser')} leftIcon={<IoMdAdd />} />
              )}
              <div className="inline-justifier">
                <VLIconButton Icon={FaFilter} onClick={this.handleToggle} />
                <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
              </div>
            </div>
          </Row>
          <Container
            fluid
            className="chassis-content-table-container chasis-content-table-height chassis-content--no-border"
          >
            <CssFilterTransition component={this.buildTopInputs} isOpen={this.state.isFilterOpen} />

            <>
              {this.state.users && (
                <TableComponent
                  {...data}
                  type={this.props.type}
                  changeExpandedRow={this.changeExpandedRow}
                  selectedRow={this.state.selectedRow}
                />
              )}
            </>
          </Container>
        </Row>
        <div className="pagination">
          <Pagination
            totalPages={this.state.totalPages}
            handlePageClick={this.handlePageClick}
            dataPageNumber={this.state.dataPageNumber}
          />
        </div>
      </div>
    );
  }
}

export default FirmUsers;
