import React from 'react';
import './NotificationIndicator.scss';

const NotificationIndicator = ({ numberOfNotifications }) => {
  return (
    <div className="notification-indicator-pending-requests">
      {numberOfNotifications > 99 ? '99+' : numberOfNotifications}
    </div>
  );
};

export default NotificationIndicator;
