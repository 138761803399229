import Users from './Users';
import { getFirmUsersRequest, deleteAdminAccountRequest } from '../../redux/actions/administration';
import { toggleUserActive } from '../../redux/actions/settings';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createUserRequest } from '../../redux/actions/settings';

const mapStateToProps = state => ({
  users: state.administration.firmUsers,
  canEditUsers: state.login.userPermissions.data.permissions?.canEditUsers
});

const mapDispatchToProps = {
  getFirmUsersRequest,
  deleteAdminAccountRequest,
  toggleUserActive,
  createUserRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
