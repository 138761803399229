import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Row } from 'react-bootstrap';

import DatePicker, { dateTransactionsOptions } from '../DatePicker';
import React, { PureComponent } from 'react';
import { capitalizeFirstLetter, sortByDateDescending } from '../../utils/generalUtils';

import CssFilterTransition from '../CssFilterTransition';
import TransactionsTable from '../TransactionsTable';
import i18next from 'i18next';
import MediaQuery from 'react-responsive';
import Pagination from '../Pagination/Pagination';
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper';
import { FILTER_DROPDOWN_OPTIONS, FILTER_LIST_OPTIONS } from '../../utils/transactionsFilterOptions';
import StakingAndRewardsTableAdmin from '../StakingAndRewardsTableAdmin';
import { decodeRole } from '../../utils/roleCheckers';
import { PRODUCT_TYPES } from '../../enums/roles';
import ExportButton from '../ExportButton';
import { BsPlus } from 'react-icons/bs';
import VLButton from '../Buttons/VLButton';
import VLIconButton from '../Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import Card from '../../containers/Card';
import PageTitle from '../PageTitle';
import VLSelect from '../VLSelect';

const ALL_PAGES = '0,1000,createdDate';

const initialState = {
  statusDepositWithdrawalCrypto: '',
  statusDepositWithdrawalUSD: '',
  typeOther: '',
  typeDepositWithdrawalCrypto: '',
  typeDepositWithdrawalUSD: '',
  datesOther: '',
  datesDepositWithdrawalCrypto: '',
  datesDepositWithdrawalUSD: '',
  displayFiltersOther: false,
  displayFiltersDepositWithdrawalCrypto: false,
  displayFiltersDepositWithdrawalUSD: false
};

const SCREENS = {
  DEPOSIT_WITHDRAWAL_CRYPTO: 'DepositWithdrawalCrypto',
  DEPOSIT_WITHDRAWAL_USD: 'DepositWithdrawalUSD',
  OTHER: 'Other',
  STAKING: 'Staking',
  REWARDS: 'Rewards'
};

const mapRewardsData = transactions => {
  return transactions.map(t => ({
    ...t,
    created_date: t.createdDate,
    userid: t.userId
  }));
};

const mapUsdTransactionsData = transactions => {
  return transactions.map(t => ({
    id: t.id,
    created_date: t.created_date,
    type: t.type.toLowerCase(),
    amount: t.amount,
    security: 'USD',
    status: t.status,
    userInfo: t.fiUserInfo
  }));
};

const ROW_LIMIT = 12;
class PaymentsTransactionsContainer extends PureComponent {
  state = {
    ...initialState,
    totalPagesOther: this.props.events.data ? Math.ceil(this.props.events.data.length / ROW_LIMIT) : 0,
    totalPagesDepositWithdrawalCrypto: Math.ceil(this.props.transactionsCTS?.data.length / ROW_LIMIT),
    totalPagesDepositWithdrawalUSD: Math.ceil(this.props.transactionsUSD?.data.length / ROW_LIMIT),
    currentScreen: SCREENS.OTHER,
    dataPageNumberOther: 0,
    dataPageNumberDepositWithdrawalCrypto: 0,
    dataPageNumberDepositWithdrawalUSD: 0,
    externalTransactionsSelected: false
  };

  componentDidMount() {
    this.fetchTransactions();
  }

  accountChanged(prevProps) {
    return this.props.currentAccountNumber !== prevProps.currentAccountNumber;
  }

  /**
   * Fetches all data required for this component
   */
  fetchTransactions() {
    const account = this.props.currentAccountNumber;

    this.props.fetchStakingRequest({
      account,
      page: ALL_PAGES
    });
    this.props.fetchStakingRewardsRequest({ account });
    this.props.fetchAssetPairs();

    this.props.fetchExternalPendingCryptoTrasactions({
      page: ALL_PAGES,
      account
    });
    this.props.fetchExternalUSDTransactions({
      page: ALL_PAGES,
      account
    });
    this.props.getDepositAccounts({
      access: 'R'
    });

    this.props.getAssets();
    this.props.getAccountTransactions({
      account,
      firm: this.props.user.firm
    });
  }

  handlePageClick = (data, type) => {
    this.setState({
      [`dataPageNumber${type}`]: data.selected
    });
  };

  componentDidUpdate(prevProps) {
    if (this.accountChanged(prevProps)) {
      this.fetchTransactions();
    }

    if (this.props.events.data !== prevProps.events.data && prevProps.events.data === []) {
      this.setState({
        totalPagesOther: Math.ceil(this.props.events.data?.length / ROW_LIMIT) || 0
      });
      this.props.getAccountTransactions({
        account: this.props.currentAccountNumber,
        firm: this.props.user?.firm
      });
    }
    if (this.props.transactionsCTS.data !== prevProps.transactionsCTS.data) {
      this.setState({
        totalPagesDepositWithdrawalCrypto: Math.ceil(this.props.transactionsCTS.data?.length / ROW_LIMIT) || 0
      });
    }
    if (this.props.transactionsUSD.data !== prevProps.transactionsUSD.data) {
      this.setState({
        totalPagesDepositWithdrawalUSD: Math.ceil(this.props.transactionsUSD.data?.length / ROW_LIMIT) || 0
      });
    }
  }

  changeStatusScreen = screen => {
    this.setState({ currentScreen: screen });
    if (screen !== SCREENS.DEPOSIT_WITHDRAWAL_USD && screen !== SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO) {
      this.setState({ externalTransactionsSelected: false });
    }
  };

  expandExternalTransactions() {
    if (
      this.state.currentScreen !== SCREENS.DEPOSIT_WITHDRAWAL_USD &&
      this.state.currentScreen !== SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO
    ) {
      this.setState({
        externalTransactionsSelected: !this.state.externalTransactionsSelected
      });
    }
  }

  handleFilterChange = (target, option, screenType) => {
    this.setState(
      {
        [option.name]: {
          value: target.value,
          label: target.label
        }
      },
      () => {
        this[`triggerFilter${screenType}`]();
      }
    );
  };
  handleDateChange = (date, screenType) => {
    var dateFrom = new Date(date[0]);
    var dateTo = new Date(date[1]);
    var isoDateFrom = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString();
    var isoDateTo = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString();
    this.setState(
      {
        [`dates${screenType}`]: isoDateFrom + '&' + isoDateTo
      },
      () => {
        this[`triggerFilter${screenType}`]();
      }
    );
  };
  triggerFilterOther = () => {
    const startRange = this.state.datesOther !== undefined && this.state.datesOther.split('&')[0];

    const endRange = this.state.datesOther !== undefined && this.state.datesOther.split('&')[1];

    this.props.getAccountTransactions({
      type: this.state.typeOther.value,
      from: startRange,
      to: endRange,
      account: this.props.accounts?.data[0]?.account,
      firm: this.props.accounts?.data[0]?.firm
    });
  };
  triggerFilterDepositWithdrawalUSD = () => {
    const startRange =
      this.state.datesDepositWithdrawalUSD !== undefined && this.state.datesDepositWithdrawalUSD.split('&')[0];

    const endRange =
      this.state.datesDepositWithdrawalUSD !== undefined && this.state.datesDepositWithdrawalUSD.split('&')[1];

    this.props.fetchExternalUSDTransactions({
      page: '0,1000,requestedAt',
      account: this.props.currentAccountNumber,
      from: startRange || undefined,
      to: endRange || undefined,
      status: this.state.statusDepositWithdrawalUSD.value?.toUpperCase(),
      type: this.state.typeDepositWithdrawalUSD.value?.toUpperCase()
    });
  };
  triggerFilterDepositWithdrawalCrypto = () => {
    const startRange =
      this.state.datesDepositWithdrawalCrypto !== undefined && this.state.datesDepositWithdrawalCrypto.split('&')[0];

    const endRange =
      this.state.datesDepositWithdrawalCrypto !== undefined && this.state.datesDepositWithdrawalCrypto.split('&')[1];

    this.props.fetchExternalPendingCryptoTrasactions({
      page: '0,1000,createdDate',
      from: startRange || undefined,
      to: endRange || undefined,
      status: this.state.statusDepositWithdrawalCrypto.value,
      type: this.state.typeDepositWithdrawalCrypto.value,
      account: this.props.currentAccountNumber
    });
  };

  clearFilters = type => {
    this.setState(
      {
        ...initialState,
        account: '',
        [`displayFilters${type}`]: this.state[`displayFilters${type}`]
      },
      () => {
        this[`triggerFilter${type}`]();
      }
    );
  };

  buildFilters = type => {
    const paymentFilter = FILTER_LIST_OPTIONS[type];

    const filters = paymentFilter.map(filter => {
      const filterOptionsLong = FILTER_DROPDOWN_OPTIONS[filter](type);
      return (
        <div className="filter-control">
          <MediaQuery maxWidth={1960}>
            <div className="filter-field-expand" key={filter}>
              <Form.Label className="search-user-form-label">
                {capitalizeFirstLetter(filter)}
              </Form.Label>
              <VLSelect
                placeholder={i18next.t('payments.select') + ' ' + filter}
                isSearchable={true}
                name={`${filter}${type}`}
                options={filterOptionsLong.slice(1)}
                onChange={(target, option) => this.handleFilterChange(target, option, type)}
                value={this.state[`${filter}${type}`]}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1961}>
            <div key={filter}>
              <Form.Label className="search-user-form-label">
                {capitalizeFirstLetter(filter)}
              </Form.Label>
              <VLSelect
                placeholder={i18next.t('payments.select') + ' ' + filter}
                isSearchable={true}
                name={`${filter}${type}`}
                options={filterOptionsLong.slice(1)}
                onChange={(target, option) => this.handleFilterChange(target, option, type)}
                value={this.state[`${filter}${type}`]}
              />
            </div>
          </MediaQuery>
        </div>
      );
    });
    filters.unshift(
      <>
        <div className="transactions-date-range-container row-width">
          <Form.Label className="search-user-form-label">{i18next.t('transactionsFilters.dataRange')}</Form.Label>
          <div className="system-form-control-box transactions-flatpickr-container payments-date-parent filter-date-expand">
            <DatePicker
              options={{
                ...dateTransactionsOptions,
                drops: 'down',
                parentEl: '.payments-date-parent'
              }}
              date={this.state[`dates${type}`]}
              onClick={date => this.handleDateChange(date, type)}
            />
          </div>
        </div>
        <div className="user-form-control-container">
          <VLButton
            text={i18next.t('transactionsFilters.clearFilter')}
            variant="clear"
            size={'m'}
            rightIcon={<BsPlus />}
            onClick={() => this.clearFilters(type)}
          />{' '}
        </div>
      </>
    );
    return filters;
  };

  transactionTableWrapper = (transactions, screenType, isLoading) => {
    return (
      <Container fluid>
        <Row className="filter-alignment transactions-filters-container row no-gutters">
          <div className="inline-justifier">
            <VLIconButton Icon={FaFilter} onClick={() => this.toggleFilters(screenType)} />{' '}
            <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
          </div>

          <ExportButton
            isExportTitle
            title="transactions"
            userAccountNumber={this.props.user.restricted_attr?.access_list?.[0].account}
            type={screenType.toLowerCase() + 'UserSide'}
            params={{
              type: this.state[`type${screenType}`].value,
              from: this.state[`dates${screenType}`].split('&')?.[0] || '',
              to: this.state[`dates${screenType}`].split('&')?.[1] || '',

              status: this.state?.[`status${screenType}`]?.value
            }}
          />
        </Row>
        <CssFilterTransition
          isOpen={this.state[`displayFilters${screenType}`]}
          component={() => this.buildFilters(screenType)}
        ></CssFilterTransition>
        <div className="overflowX-scroll-transactions">
          <LoadingWrapper
            isLoading={isLoading}
            withReloadBtn
            reloadFunction={this[`triggerFilter${screenType}`]}
            waitDurationSeconds={60}
            styleClass={'spinner-position-payments'}
          >
            {' '}
            {transactions?.length > 0 ? (
              <React.Fragment>
                <TransactionsTable
                  transactions={sortByDateDescending(transactions)}
                  screenType={this.state.currentScreen}
                  toggleTransactionDetails={this.props.toggleTransactionDetails}
                  toggleOnConfirmationRequest={this.props.toggleOnConfirmationRequest}
                  transaction={this.props.transaction}
                />
                <div className="pagination">
                  <Pagination
                    totalPages={this.state[`totalPages${screenType}`]}
                    handlePageClick={data => this.handlePageClick(data, screenType)}
                    dataPageNumber={this.state[`dataPageNumber${screenType}`]}
                  />{' '}
                </div>
              </React.Fragment>
            ) : (
              <div className="no-existing-transactions-message">There are no existing transactions</div>
            )}
          </LoadingWrapper>
        </div>
      </Container>
    );
  };

  getPagedData = (data, transactionsTab, pageSize) => {
    const items = data || [];

    if (
      (this.state[`dataPageNumber${transactionsTab}`] > 0) &
      (this.state[`dataPageNumber${transactionsTab}`] >= this.state[`totalPages${transactionsTab}`])
    ) {
      this.setState({
        [`dataPageNumber${transactionsTab}`]: this.state[`dataPageNumber${transactionsTab}`] - 1
      });
    }
    const startIndex = this.state[`dataPageNumber${transactionsTab}`] * pageSize;
    return items.slice(startIndex, startIndex + pageSize);
  };

  renderBody = () => {
    const data = sortByDateDescending(this.props.events.data);
    const sortedTransactionsData = this.getPagedData(data, SCREENS.OTHER, ROW_LIMIT);

    const transactionsCTS = this.getPagedData(
      this.props.transactionsCTS.data,
      SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO,
      ROW_LIMIT
    );
    const transactionsUSD = this.getPagedData(
      this.props.transactionsUSD.data,
      SCREENS.DEPOSIT_WITHDRAWAL_USD,
      ROW_LIMIT
    );

    switch (this.state.currentScreen) {
      case SCREENS.DEPOSIT_WITHDRAWAL_USD:
        return this.transactionTableWrapper(
          mapUsdTransactionsData(transactionsUSD),
          this.state.currentScreen,
          this.props.transactionsUSD?.loading
        );
      case SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO:
        return this.transactionTableWrapper(
          transactionsCTS,
          this.state.currentScreen,
          this.props.transactionsCTS?.loading
        );
      case SCREENS.OTHER:
        return this.transactionTableWrapper(
          sortedTransactionsData,
          this.state.currentScreen,
          this.props.events?.loading
        );
      case SCREENS.STAKING:
        return (
          <Container fluid>
            <StakingAndRewardsTableAdmin
              type="STAKING"
              accounts={sortByDateDescending(this.props.stakingTransactions.data)}
              isLoading={this.props.stakingTransactions.loading}
              toggleTransactionDetails={this.props.toggleTransactionDetails}
            />
          </Container>
        );

      case SCREENS.REWARDS:
        return (
          <Container fluid>
            <StakingAndRewardsTableAdmin
              type="REWARDS"
              accounts={sortByDateDescending(mapRewardsData(this.props.rewardTransactions.data || []))}
              isLoading={this.props.rewardTransactions.loading}
              toggleTransactionDetails={this.props.toggleTransactionDetails}
            />
          </Container>
        );

      default:
        return <></>;
    }
  };

  toggleFilters = filter => {
    this.setState(prev => ({
      [`displayFilters${filter}`]: !prev[`displayFilters${filter}`]
    }));
  };

  render() {
    return (
      <Card>
        <Container fluid>
          <Row>
            <Col>
              <div className="contacts-container-wrap">
                <div className="contacts-bar contacts-container">
                  <PageTitle text={'Transactions'} />
                </div>
              </div>
              <ButtonToolbar className="button-container">
                <ButtonGroup>
                  <Button
                    onClick={() => this.expandExternalTransactions()}
                    className={`item-button-medium ${
                      this.state.externalTransactionsSelected
                        ? 'extend-animation-close extended'
                        : 'extend-animation-open'
                    }`}
                  >
                    <span>External</span>
                  </Button>
                  {this.state.externalTransactionsSelected && (
                    <>
                      <Button
                        active={this.state.currentScreen === SCREENS.DEPOSIT_WITHDRAWAL_USD}
                        onClick={() => this.changeStatusScreen(SCREENS.DEPOSIT_WITHDRAWAL_USD)}
                        className="item-button-medium extended"
                      >
                        <span className="tab-item-name">Deposit & withdrawal USD</span>
                      </Button>
                      {decodeRole(this.props?.user.roles).product === PRODUCT_TYPES.PCA && (
                        <Button
                          active={this.state.currentScreen === SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO}
                          onClick={() => this.changeStatusScreen(SCREENS.DEPOSIT_WITHDRAWAL_CRYPTO)}
                          className="item-button-medium extended"
                        >
                          <span className="tab-item-name">Deposit & withdrawal crypto</span>
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    className="item-button-medium"
                    active={this.state.currentScreen === SCREENS.OTHER}
                    onClick={() => this.changeStatusScreen(SCREENS.OTHER)}
                  >
                    <span>Internal</span>
                  </Button>
                  <Button
                    className="item-button-medium"
                    active={this.state.currentScreen === SCREENS.STAKING}
                    onClick={() => this.changeStatusScreen(SCREENS.STAKING)}
                  >
                    <span>Staking</span>
                  </Button>
                  <Button
                    className="item-button-medium"
                    active={this.state.currentScreen === SCREENS.REWARDS}
                    onClick={() => this.changeStatusScreen(SCREENS.REWARDS)}
                  >
                    <span>Rewards</span>
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <Col className="dashboard-col chassis-interior-content">
                <Row noGutters>{this.renderBody()}</Row>
              </Col>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }
}

export default PaymentsTransactionsContainer;
