import { Col, Modal, Row } from 'react-bootstrap';
import { TRANSACTION_SCREENS } from '../../../enums/paths';
import { PENDING_API_TYPES, TRANSACTION_API_TYPES } from '../../../enums/validation';
import { MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { isAsset, isInbound } from '../../../utils/transactionsHelpers';
import AcceptTransactionModal from '../AcceptTransactionModal/AcceptTransactionModal';
import CancelTransactionModal from '../CancelTransactionModal';
import LoadingWrapper from '../../LoadingWrapper/LoadingWrapper';
import TransactionCancelScreen from '../../TransactionCancelScreen';
import TransactionSuccessScreen from '../../TransactionSuccessScreen';
import { checkHasAuths } from '../../../utils/roleCheckers';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { AccountFilterContext } from '../../../contexts/AccountFilterContext';
import getAcceptScreenProps from './GetAcceptScreenProps';
import validateAcceptAmount from './ValidateAcceptAmount';
import transactionDetailsGenerator from '../../../utils/transactionDetailsGenerator';
import './TransactionDetailsModal.scss';
import { allFieldsNonNull, sublistCallbackFactory } from '../../../utils/generalUtils';
import { shouldAllowTransactions } from '../../../utils/roleCheckers';
import InformationPopover from '../../InformationPopover';
import VLButton from '../../Buttons/VLButton';

const MODAL_STEPS = {
  DETAILS: 'details',
  ACCEPT: 'accept',
  CANCEL: 'cancel'
};

const createLabelWithPopover = (label, text) => {
  return (
    <div className="information-popover-content">
      <span>{label}</span>
      <InformationPopover placement="right" text={text} />
    </div>
  );
};

const buildSingleRowData = pair => {
  return (
    <Row className="transaction-details-outer-row">
      {pair[0] && (
        <Col xl={6} className="transaction-details-wrapper-col">
          <Row className="transaction-details-inner-row">
            <Col xl={8} className="transaction-details-label">
              {pair[0].details ? createLabelWithPopover(pair[0].label, pair[0].details) : <span>{pair[0].label}</span>}
            </Col>
            <Col xl={4} className="transaction-details-value">
              <span>{pair[0].value}</span>
            </Col>
          </Row>
        </Col>
      )}
      {pair[1] && (
        <Col xl={6} className="transaction-details-wrapper-col">
          <Row className="transaction-details-inner-row">
            <Col xl={7} className="transaction-details-label">
              {pair[1].details ? createLabelWithPopover(pair[1].label, pair[1].details) : <span>{pair[1].label}</span>}
            </Col>
            <Col xl={5} className="transaction-details-value">
              <span>{pair[1].value}</span>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

class TransactionDetailsModal extends PureComponent {
  state = {
    currentScreen: MODAL_STEPS.DETAILS,
    cancelDescription: '',
    transactionType: '',
    walletAccount: '',
    walletError: '',
    fees: '',
    feesError: '',
    acceptTransactionData: {
      amount: '',
      asset: '',
      history: '',
      requestID: ''
    },
    listOfAccounts: [],
    selectedAccount: '',
    requestSent: false
  };
  static contextType = AccountFilterContext;

  componentDidMount() {
    if (this.context !== undefined) {
      if (this.props.currentAccountNumber !== undefined) {
        this.setState({ selectedAccount: this.props.currentAccountNumber });
      }
    }
    const isPendingTransaction = this.getPendingTransactionType();

    const isExchangeTransaction = this.props.transaction.type === TRANSACTION_API_TYPES.EXCHANGE;

    const isFinishedEscrowTransaction = this.props.transaction.type === TRANSACTION_API_TYPES.ESCROW;

    let transactionType = '';
    if (isFinishedEscrowTransaction) {
      transactionType = 'Finished Escrow';
    } else if (!isPendingTransaction || isExchangeTransaction) {
      transactionType = this.props.transaction.type;
    } else {
      transactionType = isPendingTransaction;
    }

    if (transactionType === TRANSACTION_API_TYPES.DEPOSIT || transactionType === TRANSACTION_API_TYPES.WITHDRAW) {
      this.generateWalletCode();
      this.getFees();
    }

    this.setState({
      transactionType
    });
  }

  generateWalletCode = () => {
    this.props.getAssetWalletAddress({
      security: this.props.transaction.security,
      account: this.props.transaction.account,
      firm: this.props.transaction.firm,
      socket: this.props.socket,
      successCallback: data => {
        this.setState({
          walletAccount: data.extaddress
        });
      },
      errorCallback: data => {
        this.setState({
          walletError: i18next.t('toasts.walletEror')
        });
      }
    });
  };

  getFees = () => {
    this.props.getFeesPerAsset({
      account: this.props.transaction.account,
      firm: this.props.transaction.firm,
      socket: this.props.socket,
      successCallback: data => {
        this.setState({
          fees: JSON.parse(data.fees)
        });
      },
      errorCallback: data => {
        this.setState({
          feesError: 'There was an issue fetching fees data'
        });
      }
    });
  };

  getPendingTransactionType = () => {
    let pendingTransactionType = '';
    if (
      this.props.escrows.find(
        escrow => escrow.refno === this.props.transaction.refno || escrow.refno === this.props.transaction.traderefno
      )
    ) {
      pendingTransactionType = PENDING_API_TYPES.ESCROW;
    } else if (
      this.props.requests.find(
        request => request.refno === this.props.transaction.refno || request.refno === this.props.transaction.traderefno
      )
    ) {
      pendingTransactionType = PENDING_API_TYPES.REQUEST;
    }
    return pendingTransactionType;
  };

  isPendingTransactionType = () =>
    this.state.transactionType === PENDING_API_TYPES.ESCROW ||
    (this.props.transaction.type === 'order' && isAsset(this.props.transaction.security)) ||
    this.state.transactionType === PENDING_API_TYPES.SCHEDULED_TRANSFER;

  switchScreen = screenType => this.setState({ currentScreen: screenType });

  handleDescriptionChange = ({ target }) => this.setState({ cancelDescription: target.value });

  cancelTransactionSwitch = () => {
    this.switchScreen(MODAL_STEPS.CANCEL);
  };

  acceptTransactionSwitch = () => {
    this.switchScreen(MODAL_STEPS.ACCEPT);
  };

  cancelSuccessTrigger = () => {
    this.switchScreen(TRANSACTION_SCREENS.CANCEL_SUCCESS);
  };

  renderBody = () => {
    switch (this.state.currentScreen) {
      case MODAL_STEPS.DETAILS: {
        return transactionDetailsGenerator(this.props.transaction, this.props, this.isPendingTransactionType)
          .filter(allFieldsNonNull)
          .reduce(sublistCallbackFactory(), [])
          .map(buildSingleRowData);
      }
      case MODAL_STEPS.CANCEL: {
        return <div></div>;
      }
      case TRANSACTION_SCREENS.SUCCESS: {
        return (
          <TransactionSuccessScreen
            {...getAcceptScreenProps(this.props.transaction, this.props.assetPairs, this.state.transactionType)}
            history={this.props.history}
            requestID={this.props.transaction.refno}
            specialRedirectFunction={this.props.onCloseModal}
            noSummary
            isAccepting
            notStandalone
            isInbound={this.isInboundHandler()}
          />
        );
      }
      case TRANSACTION_SCREENS.CANCEL_SUCCESS: {
        return (
          <TransactionCancelScreen
            {...getAcceptScreenProps(this.props.transaction, this.props.assetPairs, this.state.transactionType)}
            // counterparty={this.props.firms.data[0].firm}
            history={this.props.history}
            requestID={this.props.transaction.refno}
            specialRedirectFunction={this.props.onCloseModal}
            noSummary
            notStandalone
          />
        );
      }
      default:
        return <></>;
    }
  };

  confirmChoice = () => {
    const transaction = this.props.transaction;
    const pendingType = this.getPendingTransactionType();
    const { transactionType } = this.state;
    if (this.state.currentScreen === MODAL_STEPS.CANCEL) {
      const data = {
        type: transaction.type === 'scheduled_transfer' ? 'cancelorder' : 'declineorder',
        security: transaction.security,
        refno: transaction.refno,
        socket: this.props.socket,
        userid: transaction.brokers,
        successCallback: data => {
          this.props.rejectPaymentRequestSuccess(transaction);
          this.cancelSuccessTrigger();
          toast.success(i18next.t('toasts.canceled'));
        },
        errorCallback: () => {
          toast.error(i18next.t('toasts.error'));
        }
      };
      this.props.rejectPaymentRequest(data);
    } else {
      const data = {
        security: transaction.security,
        refno: transaction.refno,
        socket: this.props.socket,
        qty: transaction.qty,
        userid: transaction.brokers,
        price: transaction.price,
        side: transaction.side === 'S' ? 'B' : 'S',

        nonnegotiable: transactionType === 'escrow' ? true : null,
        successCallback: data => {
          this.setState(
            {
              requestSent: false
            },
            () => {
              this.props.acceptPaymentRequestSuccess(transaction);
              this.props.updateTransactionsDB({
                ref: transaction.refno,
                type: pendingType === PENDING_API_TYPES.ESCROW ? PENDING_API_TYPES.ESCROW : PENDING_API_TYPES.REQUEST,
                cryptoRates: this.props.cryptoRates.data
              });
              setTimeout(() => {
                this.props.getAccountTransactions({
                  account: this.props.currentAccountNumber,
                  firm: this.props.profileDetails.data.firm
                });
              }, 5000);
              this.switchScreen(TRANSACTION_SCREENS.SUCCESS);
            }
          );
        },
        errorCallback: () => {
          toast.error(i18next.t('toasts.error'));
          this.setState({
            requestSent: false
          });
        }
      };

      this.props.acceptPaymentRequest(data);
      this.setState({
        requestSent: true
      });
    }
  };

  getTitle = () => {
    if (this.state.currentScreen === MODAL_STEPS.DETAILS)
      return i18next.t('transactionDetailsModal.transactionDetails');
    else if (this.state.currentScreen === MODAL_STEPS.CANCEL)
      return i18next.t('transactionDetailsModal.cancelTransaction');
    else return i18next.t('transactionDetailsModal.acceptTransaction');
  };

  isInboundHandler = () => this.isPendingTransactionType() || isInbound(this.props.transaction);

  render() {
    const isDetailsView = this.state.currentScreen === MODAL_STEPS.DETAILS;
    const isCancelView = this.state.currentScreen === MODAL_STEPS.CANCEL;
    const isAcceptView = this.state.currentScreen === MODAL_STEPS.ACCEPT;

    const isAcceptSuccessView = this.state.currentScreen === TRANSACTION_SCREENS.SUCCESS;
    const isCancelSuccessView = this.state.currentScreen === TRANSACTION_SCREENS.CANCEL_SUCCESS;

    const selectedAccount = this.props.accounts.find(account => account.account === this.props.currentAccountNumber);
    const canUserExecuteTransaction = shouldAllowTransactions(this.props.userData, selectedAccount);
    console.log('is accept view', isAcceptView);
    return (
      <Modal
        className="common-modal transaction-details-wrapper"
        centered
        show
        onHide={this.props.onCloseModal}
        dialogClassName={`transaction-modal ${isDetailsView ? 'transaction-details-modal' : ''}`}
        backdrop="static"
      >
        {!isAcceptSuccessView && !isCancelSuccessView && (
          <Modal.Header closeButton>
            {!isDetailsView && (
              <MdKeyboardBackspace
                onClick={() => this.switchScreen(MODAL_STEPS.DETAILS)}
                className="external-deposit-back-button"
                size="2.5em"
              />
            )}
            <Modal.Title>{`${this.getTitle()}`}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <LoadingWrapper isLoading={false}>
            <div className="deposit-container">
              <div className="external-deposit-step-container">
                {(isDetailsView || isAcceptSuccessView || isCancelSuccessView) && this.renderBody()}
                {isCancelView && (
                  <CancelTransactionModal notStandalone handleDescriptionChange={this.handleDescriptionChange} />
                )}
                {(isAcceptView && (
                  <AcceptTransactionModal
                    notStandalone
                    {...getAcceptScreenProps(this.props.transaction, this.props.assetPairs, this.state.transactionType)}
                    isInbound={this.isInboundHandler()}
                  />
                )) ||
                  ''}
              </div>
            </div>
            {isDetailsView && canUserExecuteTransaction && (
              <div style={{ marginTop: '1rem' }}>
                {isDetailsView && this.isInboundHandler() && this.props.user.role !== 'superadmin' && (
                  <VLButton
                    size={'l'}
                    variant="success"
                    visibility={
                      validateAcceptAmount(
                        this.props.transaction,
                        this.props.currentAccountNumber,
                        {
                          assets: this.props.assets.data,
                          positions: this.props.positions,
                          accounts: this.props.accounts
                        },
                        this.state.transactionType,
                        this.isPendingTransactionType(),
                        this.props.assetPairs
                      )
                        ? 'visible'
                        : 'hidden'
                    }
                    onClick={this.acceptTransactionSwitch}
                    text={i18next.t('transactionDetailsModal.acceptTransaction')}
                  />
                )}

                {isDetailsView &&
                  this.isPendingTransactionType() &&
                  checkHasAuths(this.props.user, this.props.transaction.account) &&
                  this.props.user.role !== 'superadmin' && (
                    <div style={{ float: 'right' }}>
                      <VLButton
                        size={'l'}
                        variant="danger"
                        onClick={this.cancelTransactionSwitch}
                        text={i18next.t('transactionDetailsModal.cancelTransaction')}
                      />
                    </div>
                  )}
              </div>
            )}
            {!isDetailsView && !isAcceptSuccessView && !isCancelSuccessView && (
              <div className="sfcb-button-wrapper">
                <VLButton
                  width="100%"
                  onClick={this.confirmChoice}
                  disabled={this.state.requestSent}
                  text={'Confirm'}
                />
              </div>
            )}
          </LoadingWrapper>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(TransactionDetailsModal);
