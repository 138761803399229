import React from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
const COLORS = {
  BTC: '#f2a900',
  ETH: '#4747df',
  USD: '#07a538',
  BCH: '#8dc351',
  ETC: '#236b19',
  FIL: '#4bace1',
  LTC: '#00aeff',
  USDC: '#076da5'
};

const DonutChart = props => {
  const data = props.series.map(d => {
    d.color = COLORS[d.name];
    d.dataLabels = { x: 47, y: 0 };
    return d;
  });
  const getMockDonutOptions = () => {
    const options = {
      chart: {
        height: 50,
        width: 120,
        margin: [0, 0, 0, 0],
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        marginLeft: -24
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            distance: -12,
            style: {
              textOutline: 0
            },
            formatter: function () {
              const point = this.point;

              return point.name === 'Total (USD)'
                ? ''
                : '<span style="color: ' +
                    point.color +
                    '; font-size: 14px">' +
                    (point.y * 100).toFixed(2) +
                    '%</span>';
            }
          }
        },
        series: {
          states: {
            hover: {
              enabled: false,
              enableMouseTracking: false
            }
          }
        }
      },
      series: [
        {
          colorByPoint: false,
          color: 'rgba(8, 8, 7, 0.1)',
          data: data,
          innerSize: '70%',
          states: {
            hover: {
              enabled: false,
              enableMouseTracking: false
            }
          }
        }
      ],
      credits: {
        enabled: false
      }
    };

    return options;
  };

  return <HighchartsReact options={getMockDonutOptions()} highcharts={Highcharts} {...props} />;
};

export default DonutChart;
