import './DepositAddressStep.scss';
import { addInternalWalletsRequest, fetchInternalWalletsRequest } from '../../../redux/actions/transactionsExternal';
import DepositAddressStep from './DepositAddressStep';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  assets: state.transactions.assets,
  websocket: state.websocket.websocket,
  VLNPositions: state.websocket.positions,
  depositWallets: state.externalTransactions.internalWallets,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  fetchInternalWalletsRequest,
  addInternalWalletsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositAddressStep);
