import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from './configureStore';
import Routes from './routes';
import './i18n';
import './App.scss';
// this import is disturbing but necessary as a lot of components in the app rely on css defined here;
// needs to be refactored so component either use their own css or move common classes to main css file;
import './components/UsersTable/UsersTable.scss';

const mapStateToProps = state => ({
  authentication: state.login.authentication
});

const mapDispatchToProps = {};

class App extends PureComponent {
  render() {
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
