import * as React from 'react';
import TabGroup, { TAB_GROUP_MODE, useTabs } from '../TradeOrder/TabGroup';
import QMS from './QMS';
import TradeHistory from './TradeHistory';
import OrderBookTab from './OrderBook/index';
import './OrderBook.scss';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';

import useTradeHistory from './useTradeHistory';
const tabs = [
  { id: 1, label: 'Order book' },
  { id: 2, label: 'QMS' },
  { id: 3, label: 'Trade history' }
];

const OrderBook = () => {
  const { activeTab, handleTabClick } = useTabs(tabs, tabs[0].id);
  const { loadTradeHistory, tradeHistory } = useTradeHistory();

  const renderOrder = activeTab => {
    if (activeTab === 1) return <OrderBookTab />;
    if (activeTab === 2) return <QMS />;

    if (activeTab === 3)
      return (
        <InfiniteScroll loadMore={loadTradeHistory}>
          <TradeHistory tradeHistory={tradeHistory.data} dataLoading={tradeHistory.loaded} />
        </InfiniteScroll>
      );

    return 'Unknown order type';
  };
  return (
    <div className="order-book">
      <div className="tab-group-wrapper">
        <TabGroup onTabClick={handleTabClick} activeTab={activeTab} tabs={tabs} mode={TAB_GROUP_MODE.STRETCH} />
      </div>

      {renderOrder(activeTab)}
    </div>
  );
};

export default OrderBook;
