import CreateUserModal from './CreateUserModal';
import './CreateUserModal.scss';
import {
  createAccountRequest,
  createAdminAccountRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  deleteFirmRequest,
  getFirmAdminAccountsRequest,
  getFirmDetailsRequest,
  getFirmUsersRequest,
  registerWalletForAccount
} from '../../../redux/actions/administration';
import { queryPositionsFirmDetails, updateRiskSettings, checkIfUserExists } from '../../../redux/actions/websocket';

import { checkAccountName } from '../../../redux/actions/settings';
import { connect } from 'react-redux';
import { getAssets } from '../../../redux/actions/transactions';
const mapDispatchToProps = {
  getFirmDetailsRequest,
  getFirmAdminAccountsRequest,
  getFirmUsersRequest,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAdminAccountRequest,
  deleteAccountRequest,
  deleteFirmRequest,
  getAssets,
  queryPositionsFirmDetails,
  checkAccountName,
  registerWalletForAccount,
  updateRiskSettings,
  checkIfUserExists
};
const mapStateToProps = state => ({
  socket: state.websocket.websocket
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
