import * as CONSTANTS from '../constants/settings';

export const getUsersRequest = data => ({
  type: CONSTANTS.FETCH_USERS,
  data
});

export const getUsersSuccess = data => ({
  type: CONSTANTS.FETCH_USERS_SUCCESS,
  data
});

export const getUsersError = error => ({
  type: CONSTANTS.FETCH_USERS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getFirmUserDetailsRequest = data => ({
  type: CONSTANTS.GET_FIRM_USER_DETAILS_REQUEST,
  data
});

export const getFirmUserDetailsSuccess = data => ({
  type: CONSTANTS.GET_FIRM_USER_DETAILS_SUCCESS,
  data
});

export const getFirmUserDetailsError = error => ({
  type: CONSTANTS.GET_FIRM_USER_DETAILS_ERROR,
  error
});

/////////////////////////////////////////////////
////////////////////////////////////////////////

export const createUserRequest = data => ({
  type: CONSTANTS.CREATE_USER,
  data
});

export const createUserError = err => ({
  type: CONSTANTS.CREATE_USER_ERROR,
  err
});
export const createWallet = data => ({
  type: CONSTANTS.CREATE_WALLET,
  data
});
export const createWalletSuccess = data => ({
  type: CONSTANTS.CREATE_WALLET_SUCCESS,
  data
});
export const createWalletError = err => ({
  type: CONSTANTS.CREATE_WALLET_ERROR,
  err
});

export const deleteWallet = data => ({
  type: CONSTANTS.DELETE_WALLET,
  data
});
export const deleteWalletSuccess = data => ({
  type: CONSTANTS.DELETE_WALLET_SUCCESS,
  data
});
export const deleteWalletError = data => ({
  type: CONSTANTS.DELETE_WALLET_ERROR,
  data
});
///////////////////////////////////////////////
///////////////////////////////////////////////

export const createNewAccount = data => ({
  type: CONSTANTS.CREATE_ACCOUNT,
  data
});
export const queryExistingUser = data => ({
  type: CONSTANTS.QUERY_EXISTING_USER,
  data
});
/////////////////////////////////////////////

export const getAccountsRequest = data => ({
  type: CONSTANTS.FETCH_ACCOUNTS,
  data
});

export const getAccountsSuccess = data => ({
  type: CONSTANTS.FETCH_ACCOUNTS_SUCCESS,
  data
});

export const getAccountsError = error => ({
  type: CONSTANTS.FETCH_ACCOUNTS_ERROR,
  error
});

//////////////////////////////////////

export const checkAccountName = data => ({
  type: CONSTANTS.CHECK_ACCOUNT_NAME,
  data
});

export const searchAccountsRequest = data => ({
  type: CONSTANTS.SEARCH_ACCOUNTS,
  data
});

export const searchAccountsSuccess = data => ({
  type: CONSTANTS.SEARCH_ACCOUNTS_SUCCESS,
  data
});

export const searchAccountsError = error => ({
  type: CONSTANTS.SEARCH_ACCOUNTS_ERROR,
  error
});

export const searchFirmsRequest = data => ({
  type: CONSTANTS.SEARCH_FIRMS,
  data
});

export const searchFirmsSuccess = data => ({
  type: CONSTANTS.SEARCH_FIRMS_SUCCESS,
  data
});

export const searchFirmsError = error => ({
  type: CONSTANTS.SEARCH_FIRMS_ERROR,
  error
});

////////////////////////////////////////

export const updatePermissions = data => ({
  type: CONSTANTS.UPDATE_USER_PERMISSIONS,
  data
});

export const updatePermissionsSuccess = data => ({
  type: CONSTANTS.UPDATE_USER_PERMISSIONS_SUCCESS,
  data
});
export const updatePermissionsError = data => ({
  type: CONSTANTS.UPDATE_USER_PERMISSIONS_ERROR,
  data
});

export const updateWhitelist = data => ({
  type: CONSTANTS.UPDATE_USER_WHITELIST,
  data
});

export const updateWhitelistSuccess = data => ({
  type: CONSTANTS.UPDATE_USER_WHITELIST_SUCCESS,
  data
});
export const updateWhitelistError = data => ({
  type: CONSTANTS.UPDATE_USER_WHITELIST_ERROR,
  data
});

export const toggleUserActive = data => ({
  type: CONSTANTS.TOGGLE_USER_ACTIVE,
  data
});
export const toggleUserActiveSuccess = data => ({
  type: CONSTANTS.TOGGLE_USER_ACTIVE_SUCCESS,
  data
});
export const toggleUserActiveError = data => ({
  type: CONSTANTS.TOGGLE_USER_ACTIVE_ERROR,
  data
});

export const toggleDarkMode = () => ({
  type: CONSTANTS.TOGGLE_DARK_MODE
});
