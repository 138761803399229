import * as React from 'react';
import './OrderBookTab.scss';
import { padArray, BOOK_SIZE, byOrderPriceAsc, byOrderPriceDesc, mapTroughBelongingOrder } from '../orderBookHelpers';
import { getLocalisedDecimalString } from '../../../utils/generalUtils';
import { FIAT_SYMBOLS } from '../../../enums/validation';
import { toast } from 'react-toastify';
const round = (value, decimals) => {
  return parseFloat(value).toFixed(decimals);
};

/**
 * Pads the array with dummy objects to fill the order book to its maximum size.
 * @param array The array to padded.
 * @returns {Array} The padded array.
 */

/**
 * Displays the "Order Book" tab in the order book.
 *
 * @param allBooks {Array} All trades in the order book.
 * @param assetPair {object} Selected asset pair.
 * @param assetPair.security {string} Name of the pair (eg. ETCUSD).
 * @param assetPair.pair_first {string} First asset of the pair (eg. ETCUSD -> ETC).
 * @param assetPair.pair_second {string} Second asset of the pair (eg. ETCUSD -> USD).
 * @param marketData24 {object} 24 hour market data.
 * @param marketData24.data.closePrice {number} 24 hour close price.
 * @param marketData24.data.change {number} 24 hour change in percentage.
 */
const OrderBookTab = ({ allBooks, assetPair, marketData24, setSelectedLmt, openOrders }) => {
  const sell = mapTroughBelongingOrder(
    padArray(
      allBooks[assetPair?.security]
        ?.filter(o => o.side === 'S' && !o.ioi)

        .sort(byOrderPriceAsc)
        .slice(0, BOOK_SIZE) ?? []
    ),
    openOrders
  );
  // Sell orders grow up
  sell.reverse();

  const buy = mapTroughBelongingOrder(
    padArray(allBooks[assetPair?.security]?.filter(o => o.side === 'B' && !o.ioi))
      ?.sort(byOrderPriceDesc)
      .slice(0, BOOK_SIZE) ?? [],
    openOrders
  );

  //
  const maxSellQty = Math.max(...sell.map(o => (o ? o.qty : 0)));
  const maxBuyQty = Math.max(...buy.map(o => (o ? o.qty : 0)));
  const orderClick = lmt => {
    if (lmt?.belongs) {
      toast.error('Invalid selection: Must select counterparty order');
      setSelectedLmt(null);
      return;
    }
    setSelectedLmt(lmt);
  };
  return (
    <div>
      <div className="order-book-header">
        <div className="order-book-headers">Price</div>
        <div className="order-book-headers">Amount</div>
        <div className="order-book-headers">Total</div>
      </div>
      <div className="order-book-body">
        {sell.map(order => (
          <OrderBookRow handleOrderClick={orderClick} {...order} keyId={order?.key} max={maxSellQty} type="sell" />
        ))}
      </div>
      <SpotPriceLine
        price={getLocalisedDecimalString(marketData24?.data.closePrice ?? 0, assetPair?.pair_second)}
        symbol={FIAT_SYMBOLS[assetPair?.pair_second]}
        change={marketData24?.data.change}
      />
      <div className="order-book-body">
        {buy.map(order => (
          <OrderBookRow handleOrderClick={orderClick} {...order} keyId={order?.key} max={maxBuyQty} type="buy" />
        ))}
      </div>
    </div>
  );
};

/**
 * Displays a row in the order book.
 *
 * @param props {object} Order to display.
 * @param props.keyId {string} Unique id of the order.
 * @param props.price {number} Price of the order.
 * @param props.qty {number}  Quantity of the order.
 * @param props.type {string} Type used to change the color of the row text.
 * @param props.max {number} Maximum quantity of the order book.
 */
const OrderBookRow = props => {
  if (!props.price) return <div className="order-book-item" />;

  const { price, qty, type = 'buy' } = props;
  return (
    <div
      onClick={() => {
        props.handleOrderClick(props);
      }}
      className="order-book-item"
    >
      <OrderBar {...props} />
      {props.belongs && <span className="user-submitted-order-indicator">&#11044;</span>}
      <div className={'order-book-content__' + type}>{price}</div>
      <div className="order-book-content">{round(qty, 6)}</div>
      <div className="order-book-content">{round(price * qty, 6)}</div>
    </div>
  );
};

const CHANGES = {
  up: {
    color: 'green',
    arrow: '↑'
  },
  down: {
    color: 'red',
    arrow: '↓'
  }
};

/**
 * Displays the spot price line between the order book pages.
 * @param price {string} Trading price of the pair
 * @param change {number} Change of the price in percentage
 * @param symbol {string} Symbol of the fiat currency
 */
const SpotPriceLine = ({ price, change, symbol }) => {
  const changeStyle = change > 0 ? CHANGES.up : CHANGES.down;

  return (
    <div className="spot-price-line">
      <span className={'spot-price-line__price ' + changeStyle.color}>
        {price ?? ''} {changeStyle.arrow}
      </span>
      {symbol}
      {price ?? ''}
    </div>
  );
};

/**
 * Displays the order bar in the order book. Order bar displays the relative
 * quantity of the order in the order book.
 *
 * @param qty {number} Quantity of the order.
 * @param type {string} Type of the order.
 * @param max {number} Maximum quantity of the order book.
 */
const OrderBar = ({ qty, type = 'buy', max }) => {
  // -1 is just so that the bar doesn't exactly reach the end of the line
  // in order for bar border radius to be seen properly
  return <div className={'order-book-order-bar__' + type} style={{ width: (qty / max) * 95 + '%' }} />;
};

export default OrderBookTab;
