import { Col, Row, Form, Container } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { findFiatCurrencySymbol, splitDateTime } from '../../../utils/generalUtils';

import { EXIT_STAKE, STAKE } from '../../../enums/validation';
import { getSecurityLabel } from '../../../utils/DOMHelpers';

import './StakeAndExitStakeSummary.scss';
import i18next from 'i18next';

class StakeAndExitStakeSummary extends PureComponent {
  state = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false
  };
  getSpecificText = () => {
    let text = {
      titleType: i18next.t('stakingSummary.stake'),
      sourceAccountType: i18next.t('stakingSummary.sourceAccount'),
      lastParagraph: i18next.t('stakingSummary.lastParagraphStake')
    };
    if (this.props.transactionType === EXIT_STAKE) {
      text = {
        titleType: i18next.t('stakingSummary.exitStaking'),
        sourceAccountType: i18next.t('stakingSummary.sourceAccount'),
        lastParagraph: i18next.t('stakingSummary.lastParagraphExit'),
        disclaimer1: i18next.t('stakingSummary.disclaimer1'),
        disclaimer2: i18next.t('stakingSummary.disclaimer2')
      };
    }

    return text;
  };

  changeCheckbox(name) {
    switch (name) {
      case 'checkbox1':
        this.setState({ checkbox1: !this.state.checkbox1 }, () => {
          this.props.makeAvailable(this.state.checkbox1 && this.state.checkbox2 && this.state.checkbox3);
        });
        break;
      case 'checkbox2':
        this.setState({ checkbox2: !this.state.checkbox2 }, () => {
          this.props.makeAvailable(this.state.checkbox1 && this.state.checkbox2 && this.state.checkbox3);
        });
        break;
      case 'checkbox3':
        this.setState({ checkbox3: !this.state.checkbox3 }, () => {
          this.props.makeAvailable(this.state.checkbox1 && this.state.checkbox2 && this.state.checkbox3);
        });
        break;
      default:
        return;
    }
  }

  getFormattedDate = date => {
    const { day, month, year } = splitDateTime(date, true);
    return `${month}/${day}/${year}`;
  };

  render() {
    const { titleType, sourceAccountType, lastParagraph, disclaimer1, disclaimer2 } = this.getSpecificText();

    return (
      <Container>
        <div className="ed-info-wrapper">
          <p className="ed-pretitle">
            {i18next.t('stakingSummary.paragraphInTitleType') +
              `  ${titleType} ` +
              i18next.t('stakingSummary.paragraphInTitleType2')}
          </p>
          <h2 className="external-deposit-h1-label">
            <span className="ed-asset">{getSecurityLabel(this.props.asset)}</span>
            {findFiatCurrencySymbol(this.props.asset)}
            {this.props.amountObject.value}
          </h2>
        </div>
        <Row className="summary-row">
          <Col>
            <p className="ed-pretitle-small">{sourceAccountType}</p>
          </Col>
          <Col>
            <h3 className="external-deposit-title-label source-account">{this.props.sourceAccount}</h3>
          </Col>
        </Row>

        <hr className="horizontal-line" />

        <Row className="summary-last-paragraph-container">
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={
              this.props.transactionType === STAKE
                ? `${i18next.t('stakingSummary.checkbox1')}`
                : `${i18next.t('stakingSummary.checkbox1ExitStaking')}`
            }
            onClick={() => {
              this.changeCheckbox('checkbox1');
            }}
          />
          <br />
          <Form.Check
            type="checkbox"
            id={`default-checkbox2`}
            label={`${i18next.t('stakingSummary.checkbox2')}`}
            onClick={() => {
              this.changeCheckbox('checkbox2');
            }}
          />
          <br />
          <Form.Check
            type="checkbox"
            id={`default-checkbox3`}
            label={`${i18next.t('stakingSummary.checkbox3')}`}
            onClick={() => {
              this.changeCheckbox('checkbox3');
            }}
          />
          <p className="external-deposit-central-text ed-text">
            <br />
            {lastParagraph}
          </p>
          {this.props.transactionType === EXIT_STAKE && (
            <p className="external-deposit-central-text ed-text" style={{ color: 'red' }}>
              {disclaimer1}
              <a href="mailto: support@vault.link"> support@vault.link </a>
              {disclaimer2}
            </p>
          )}
        </Row>
      </Container>
    );
  }
}

export default StakeAndExitStakeSummary;
