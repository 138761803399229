import { Col, Row } from 'react-bootstrap';
import {
  REQUEST_TYPE,
  SEARCH_TYPE_ACCOUNTS,
  STAKE,
  EXIT_STAKE,
  AMOUNT_REGEX,
  STAKING_ERROS
} from '../../../enums/validation';
import React, { PureComponent } from 'react';
import { onlyNumbers, validateAmount } from '../../../utils/validators';
import { getClosestMultiple32, getSelectedOption, isZeroAssetFormat } from '../../../utils/generalUtils';

import Select from 'react-select';
import { getDecimalByAsset } from '../../../utils/transactionsHelpers';
import { getTotalBalanceFormatting } from '../../../utils/DOMHelpers';
import i18next from 'i18next';
import { moneyFormat, replaceCommas } from '../../../utils/generalUtils';
import VLInput from '../../VLInput';
const repeatDurationOptions = [
  { label: '', value: 'daily' },
  { label: '', value: 'weekly' }
];

class StakeAndExitStakeDetails extends PureComponent {
  state = {
    sourceAccount: this.props.sourceAccount,
    amountObject: { ...this.props.amountObject },
    asset: this.props.asset,
    firm: this.props.firm,
    recipient: this.props.recipient,
    repeat: this.props.repeat,
    repeatDuration: this.props.repeatDuration || 'daily',
    maxDecimal: '',
    firmOptionObject: this.props.firmOptionObject,
    errorType: '',
    roundedValue: 32
  };

  composeAssetOptionComponent = ({ value, label }) => {
    return (
      <div className="form-select-label-wrap">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          // src={assetData?.attr.image}
          alt={label}
        />
        <span className="form-select-text">{label}</span>
      </div>
    );
  };

  handleFormControlChange = (a, b) => {
    const handleInputChange = (name, value) => {
      this.setState(
        {
          [name]: value
        },
        () => {
          this.props.updateParent(this.state);
        }
      );
    };

    if (a.target) {
      handleInputChange(a.target.name, a.target.value);
    } else {
      handleInputChange(b.name, a.value);
    }
  };

  handleSelect = (option, name) => {
    let decimal = this.state.maxDecimal;
    let validationObject = { ...this.state.amountObject };
    if (name === 'asset') {
      const assetAccountBalance = this.props.positions?.[option?.value];
      decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');
      validationObject = validateAmount(
        replaceCommas(this.state.amountObject.value),
        assetAccountBalance,
        decimal,
        this.props.transactionType === REQUEST_TYPE
      );
    }

    this.setState(
      {
        [name]: option?.value || '',
        maxDecimal: decimal,
        amountObject: {
          ...validationObject,
          value: moneyFormat(validationObject.value),
          error: !(!validationObject.value && !isZeroAssetFormat(this.state.amountObject.value))
            ? null
            : validationObject.error
        },
        firmOptionObject: name === 'firm' ? option : this.state.firmOptionObject
      },
      () => {
        this.props.updateParent(this.state, () => {
          if (name === 'firm') {
            this.props.triggerSearch(null, SEARCH_TYPE_ACCOUNTS);
          }
        });
      }
    );
  };

  validateAmount = () => {
    this.setState({ errorType: '' });
    const assetToVerify = this.props.transactionType === STAKE ? 'ETH' : 'ETH STAKED';

    const assetAccountBalance = this.props.positions?.[assetToVerify];
    const value = onlyNumbers(replaceCommas(this.state.amountObject.value));

    let validationObject = validateAmount(value, assetAccountBalance, this.state.maxDecimal || 8, false, assetToVerify);

    if (validationObject.error) {
      this.setState({
        errorType: STAKING_ERROS.INSUFFICIENT_FUNDS
      });
    } else {
      if (parseFloat(value) % 32 !== 0) {
        let roundedValue = 32;
        validationObject.error = true;
        const type = value > 32 ? 'down' : 'up';
        if (parseFloat(value) > 32) {
          roundedValue = getClosestMultiple32(parseFloat(value));
        }
        this.setState({
          roundedValue: roundedValue,
          errorType: type === 'down' ? STAKING_ERROS.NON_MULTIPLE_32_DOWN : STAKING_ERROS.NON_MULTIPLE_32_UP
        });
      }
    }

    this.setState(
      {
        amountObject: {
          ...validationObject,
          value: moneyFormat(validationObject.value)
        }
      },
      () => {
        this.props.updateParent(this.state);
      }
    );
  };

  handleAmountChange = ({ target }) => {
    if (AMOUNT_REGEX.test(target.value)) {
      this.setState({
        amountObject: {
          ...this.state.amountObject,
          value: moneyFormat(replaceCommas(target.value))
        }
      });
    }
  };

  renderStakeForm = () => {
    const { assetOptions } = this.props;

    const selectedAsset = getSelectedOption(assetOptions, 'ETH');

    if (repeatDurationOptions[0].value === 'daily') {
      repeatDurationOptions[0].label = i18next.t('send&requestDetails.daily');
    }
    if (repeatDurationOptions[1].value === 'weekly') {
      repeatDurationOptions[1].label = i18next.t('send&requestDetails.weekly');
    }
    const isInputDisabled = !this.state.asset || !this.state.sourceAccount;

    return (
      <>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('stakingDetails.selectedAccount')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput name="sourceAccount" value={this.state.sourceAccount} size="s" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('stakingDetails.stake.amount')}</p>
            <div
              className={`system-form-control-box-custom ${
                this.state.amountObject.error ? 'system-form-control-box--error' : ''
              } sfcb-ammount sfcb-transparent`}
            >
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('stakingDetails.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="asset"
                options={assetOptions}
                disabled
                onChange={option => this.handleSelect(option, 'asset')}
                defaultValue={selectedAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
                // menuIsOpen={true}
              />
              <VLInput
                name="amount"
                placeholder=""
                value={this.state.amountObject.value}
                onChange={this.handleAmountChange}
                onBlur={this.validateAmount}
                maxLength="20"
                disabled={isInputDisabled}
                noBorder
                hasBackground
                width="90%"
              />
            </div>
            {this.state.amountObject.error && (
              <div className="system-form-control-box-error-text">
                <span className="system-form-control-error-text">
                  {this.state.errorType === STAKING_ERROS.INSUFFICIENT_FUNDS
                    ? i18next.t('stakingDetails.error-text-insufficient-funds')
                    : (this.state.errorType === STAKING_ERROS.NON_MULTIPLE_32_DOWN
                        ? i18next.t('stakingDetails.error-text-multiple-32-down')
                        : i18next.t('stakingDetails.error-text-multiple-32-up')) + this.state.roundedValue}
                </span>
              </div>
            )}
            {this.state.asset && (
              <div className="system-form-control-box sfcb-amount-transfer-info">
                <div className="placeholder">{i18next.t('stakingDetails.totalAmount')}</div>
                <div className="sfcb-amount-number">
                  {getTotalBalanceFormatting(this.props.positions?.['ETH'], 'ETH')}
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
      </>
    );
  };

  renderExitStakingForm = () => {
    let { assetOptions } = this.props;

    const selectedAsset = getSelectedOption(assetOptions, this.state.asset);

    const isInputDisabled = !this.state.asset || !this.state.sourceAccount;

    return (
      <>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('stakingDetails.selectedAccount')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput name="sourceAccount" value={this.state.sourceAccount} size="s" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('stakingDetails.exitStaking.amount')}</p>
            <div className="system-form-control-box-custom sfcb-ammount sfcb-transparent">
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('stakingDetails.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="asset"
                options={assetOptions}
                onChange={option => this.handleSelect(option, 'asset')}
                value={selectedAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
              />
              <VLInput
                name="amount"
                placeholder=""
                value={this.state.amountObject.value}
                onChange={this.handleAmountChange}
                onBlur={this.validateAmount}
                maxLength="20"
                disabled={isInputDisabled}
                noBorder
                hasBackground
                width="90%"
                error={this.state.amountObject.error}
              />
            </div>
            {this.state.amountObject.error && (
              <div className="system-form-control-box-error-text">
                <span className="system-form-control-error-text">
                  {this.state.errorType === STAKING_ERROS.INSUFFICIENT_FUNDS
                    ? i18next.t('stakingDetails.error-text-insufficient-funds')
                    : (this.state.errorType === STAKING_ERROS.NON_MULTIPLE_32_DOWN
                        ? i18next.t('stakingDetails.error-text-multiple-32-down')
                        : i18next.t('stakingDetails.error-text-multiple-32-up')) + this.state.roundedValue}
                </span>
              </div>
            )}
            {this.state.asset && (
              <div className="system-form-control-box sfcb-amount-transfer-info">
                <div className="placeholder">{i18next.t('stakingDetails.totalStakedAmount')}</div>
                <div className="sfcb-amount-number">
                  {getTotalBalanceFormatting(this.props.positions?.['ETH STAKED'], 'ETH STAKED')}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        {this.props.transactionType === STAKE && this.renderStakeForm()}
        {this.props.transactionType === EXIT_STAKE && this.renderExitStakingForm()}
      </>
    );
  }
}

export default StakeAndExitStakeDetails;
