import './Administration.scss';

import {
  clearAllFirmInfo,
  createAccountRequest,
  createAdminAccountRequest,
  createFirmRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  deleteFirmRequest,
  getFirmAdminAccountsRequest,
  getFirmDetailsRequest,
  getFirmUsersRequest,
  getListOfFirmsRequest
} from '../../redux/actions/administration';

import Administration from './Administration';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  listOfFirms: state.administration.listOfFirms,
  firmDetails: state.administration.firmDetails,
  firmAdminAccounts: state.administration.firmAdminAccounts,
  firmUsers: state.administration.firmUsers,
  createFirmError: state.administration.createFirm,
  canEditFirms: state.login.userPermissions.data.permissions?.canEditFirms
});

const mapDispatchToProps = {
  getListOfFirmsRequest,
  createFirmRequest,
  getFirmDetailsRequest,
  getFirmAdminAccountsRequest,
  getFirmUsersRequest,
  clearAllFirmInfo,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAdminAccountRequest,
  deleteAccountRequest,
  deleteFirmRequest
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Administration);
