import {
  getAccountTransactions,
  getAccountEventsAll,
  getAssetPairs,
  resetTransactionHash,
  toggleModal,
  searchRecipientAccounts
} from '../../redux/actions/transactions';

import Payments from './Payments';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  user: state.login.userData.data,
  accounts: state.transactions.accounts,
  displayModal: state.transactions.displayModal
});

const mapDispatchToProps = {
  getAccountTransactions,
  getAccountEventsAll,
  toggleModal,
  getAssetPairs,
  resetTransactionHash,
  searchRecipientAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
