import {
  AMOUNT,
  ASSET,
  BTA_STATUS,
  FILTER_STATUS,
  FILTER_TYPE,
  PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_CRYPTO,
  PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_USD,
  PAYMENT_REQUESTS_FILTER_LIST_OTHER,
  SECURITY_CODES,
  SHOW_ALL_ACCOUNTS_FLAG,
  STATUS,
  TIER,
  TYPE,
  VALUE
} from '../enums/validation';
import { AiOutlineClose, AiOutlineExclamation } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import i18next from 'i18next';
import { localeCompare } from './generalUtils';

const getStatusDropdownData = () => [
  {
    value: '',
    label: i18next.t('transactionsFilters.selectStatus')
  },

  {
    value: '',
    label: i18next.t('transactionsFilters.allStatuses')
  },
  {
    value: 'pending',
    label: (
      <div className="transaction-status">
        <span className="transaction-status-icon pending-status">
          <AiOutlineExclamation />
        </span>
        <span>{i18next.t('transactionsFilters.pending')}</span>
      </div>
    )
  },
  {
    value: 'approved',
    label: (
      <div className="transaction-status">
        <span className="transaction-status-icon approved-status">
          <MdDone />
        </span>
        <span>{i18next.t('transactionsFilters.approved')}</span>
      </div>
    )
  },
  {
    value: 'rejected',
    label: (
      <div className="transaction-status">
        <span className="transaction-status-icon cancel-status">
          <AiOutlineClose />
        </span>
        <span>{i18next.t('transactionsFilters.canceled')}</span>
      </div>
    )
  }
];

const getTypeDropdownData = screenType => {
  if (screenType !== 'Other') {
    return [
      {
        value: SHOW_ALL_ACCOUNTS_FLAG,
        label: i18next.t('transactionsFilters.selectTypes')
      },
      {
        value: '',
        label: i18next.t('transactionsFilters.allTypes')
      },
      {
        value: 'deposit',
        label: 'Deposit'
      },
      {
        value: 'withdraw',
        label: i18next.t('transactionsFilters.withdraw')
      }
    ];
  } else {
    return [
      {
        value: SHOW_ALL_ACCOUNTS_FLAG,
        label: i18next.t('transactionsFilters.selectTypes')
      },
      {
        value: null,
        label: i18next.t('transactionsFilters.allTypes')
      },

      {
        value: 'send',
        label: i18next.t('transactionsFilters.send')
      },
      {
        value: 'ioi',
        label: i18next.t('transactionsFilters.ioi')
      },
      {
        value: 'receive',
        label: i18next.t('transactionsFilters.recieve')
      },
      {
        value: 'escrow',
        label: i18next.t('transactionsFilters.escrow')
      },
      {
        value: 'exchange',
        label: 'Exchange'
      },
      { value: 'request', label: 'Request' }
    ];
  }
};

export const getStatusDataReviewedOptions = () => [
  { value: '', label: 'Select' },
  { value: '', label: 'All' },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  }
];

const getBtaStatusData = () => [
  { value: '', label: 'Select' },
  { value: '', label: 'All' },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'critical',
    label: 'Critical'
  },
  {
    value: 'more-info',
    label: 'More info'
  },
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  }
];

const getCryptoReviewedBtaStatusOptions = () => [
  { value: '', label: 'Select' },
  { value: '', label: 'All' },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'critical',
    label: 'Critical'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  }
];

const getBtaStatusDataCryptoPending = () => [
  { value: '', label: 'Select' },
  { value: '', label: 'All' },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'more-info',
    label: 'More info'
  },
  {
    value: 'pending',
    label: 'Pending'
  }
];

export const getTableStatusData = () => [
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  }
];

const getFilterTier = () => [
  { value: '', label: 'Select' },

  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  }
];
const getFilterType = () => [
  { value: '', label: 'Select' },

  {
    value: 'DEPOSIT',
    label: 'Deposit'
  },
  {
    value: 'WITHDRAWAL',
    label: 'Withdrawal'
  },
  {
    value: 'SEND',
    label: 'Send'
  },
  {
    value: 'REQUEST',
    label: 'Request'
  },
  {
    value: 'ESCROW',
    label: 'Escrow'
  },
  {
    value: 'EXCHANGE',
    label: 'Exchange'
  }
];

const getFilterTypeDepositWithdraw = () => [
  { value: '', label: 'Select' },

  {
    value: 'DEPOSIT',
    label: 'Deposit'
  },
  {
    value: 'WITHDRAW',
    label: 'Withdraw'
  }
];

const getFilterTypeOtherTransactions = transactionType => {
  if (transactionType.includes('Open')) {
    return [
      { value: '', label: 'Select' },

      {
        value: 'escrow',
        label: 'Escrow'
      },
      {
        value: 'limit',
        label: 'Limit'
      },
      {
        value: 'request',
        label: 'Request'
      },

      {
        value: 'ioi',
        label: 'IOI'
      }
    ];
  } else if (transactionType.includes('Canceled')) {
    return [
      { value: '', label: 'Select' },
      { value: null, label: 'All' },
      {
        value: 'escrow',
        label: 'Escrow'
      },

      {
        value: 'limit',
        label: 'Limit'
      },
      {
        value: 'exchange',
        label: 'Exchange'
      },
      {
        value: 'request',
        label: 'Request'
      },
      {
        value: 'ioi',
        label: 'IOI'
      }
    ];
  } else {
    return [
      { value: '', label: 'Select' },

      {
        value: 'escrow',
        label: 'Escrow'
      },
      {
        value: 'exchange',
        label: 'Exchange'
      },
      {
        value: 'request',
        label: 'Request'
      },
      {
        value: 'send',
        label: 'Send'
      },
      {
        value: 'ioi',
        label: 'IOI'
      }
    ];
  }
};

const getAmountDropdownData = () => [
  {
    value: 'selectRange',
    label: i18next.t('transactionsFilters.selectRange')
  },
  {
    value: [],
    label: i18next.t('transactionsFilters.allRanges')
  },
  {
    value: [0, 100],
    label: '0-100'
  },
  {
    value: [100, 500],
    label: '100-500'
  },
  {
    value: [500, 1000],
    label: '500-1000'
  },
  {
    value: [1000],
    label: '>1000'
  }
];
const getValueDropdownData = () => [
  {
    value: 'selectRange',
    label: i18next.t('transactionsFilters.selectRange')
  },
  {
    value: [],
    label: i18next.t('transactionsFilters.allRanges')
  },
  {
    value: [0, 1000],
    label: '$0-$1000'
  },
  {
    value: [1000, 5000],
    label: '$1000-$5000'
  },
  {
    value: [5000, 10000],
    label: '$5000-$10000'
  },
  {
    value: [10000],
    label: '>$10000'
  }
];
const getAssetType = () => [
  {
    value: '',
    label: 'Select'
  },
  { value: SECURITY_CODES.USD, label: SECURITY_CODES.USD },
  ...Object.keys(SECURITY_CODES)
    .filter(key => key !== SECURITY_CODES.VLC$ && key !== SECURITY_CODES.USD)
    .sort(localeCompare)
    .map(key => ({ value: key, label: key }))
];

const getAssetTypeDepositWithdraw = () => [
  {
    value: '',
    label: 'Select'
  },
  ...Object.keys(SECURITY_CODES)
    .filter(key => key !== SECURITY_CODES.VLC$ && key !== SECURITY_CODES.USD)
    .sort(localeCompare)
    .map(key => ({ value: key, label: key }))
];

export const FILTER_DROPDOWN_OPTIONS_SUPERADMIN = {
  [TIER]: getFilterTier,
  [TYPE]: getFilterType,
  [AMOUNT]: getAmountDropdownData,
  [FILTER_STATUS]: getStatusDataReviewedOptions,
  [ASSET]: getAssetType,
  [VALUE]: getValueDropdownData,
  [STATUS]: getStatusDataReviewedOptions,
  [BTA_STATUS]: getBtaStatusData
};

export const FILTER_DROPDOWN_OPTIONS_OTHER_TRANSACTIONS = transactionType => {
  return {
    [TYPE]: () => getFilterTypeOtherTransactions(transactionType),
    [ASSET]: getAssetType
  };
};

export const FILTER_DROPDOWN_OPTIONS_DEPOSIT_WITHDRAW_TRANSACTIONS = transactionType => {
  return {
    [TYPE]: getFilterTypeDepositWithdraw,
    [ASSET]: getAssetTypeDepositWithdraw,
    [STATUS]: getStatusDataReviewedOptions,
    [BTA_STATUS]: transactionType === 'pending' ? getBtaStatusDataCryptoPending : getCryptoReviewedBtaStatusOptions
  };
};

export const FILTER_DROPDOWN_OPTIONS_DEPOSIT_WITHDRAW_TRANSACTIONS_USD_PENDING = {
  [TYPE]: getFilterTypeDepositWithdraw,
  [ASSET]: getAssetTypeDepositWithdraw,
  [STATUS]: getStatusDataReviewedOptions
};

export const FILTER_DROPDOWN_OPTIONS_DEPOSIT_WITHDRAW_TRANSACTIONS_USD_REVIEWED = {
  [TYPE]: getFilterTypeDepositWithdraw,
  [ASSET]: getAssetTypeDepositWithdraw,
  [STATUS]: getStatusDataReviewedOptions
};

export const FILTER_LIST_OPTIONS = {
  DepositWithdrawalUSD: PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_USD,
  DepositWithdrawalCrypto: PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_CRYPTO,
  Other: PAYMENT_REQUESTS_FILTER_LIST_OTHER
};

export const FILTER_DROPDOWN_OPTIONS = {
  [FILTER_TYPE]: getTypeDropdownData,
  [FILTER_STATUS]: getStatusDropdownData
};
