export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const QUERY_EXISTING_USER = 'QUERY_EXISTING_USER';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_ERROR = 'UPDATE_USER_PERMISSIONS_ERROR';

export const UPDATE_USER_WHITELIST = 'UPDATE_USER_WHITELIST';
export const UPDATE_USER_WHITELIST_SUCCESS = 'UPDATE_USER_WHITELIST_SUCCESS';
export const UPDATE_USER_WHITELIST_ERROR = 'UPDATE_USER_WHITELIST_ERROR';

export const GET_FIRM_USER_DETAILS_REQUEST = 'GET_FIRM_USER_DETAILS_REQUEST';
export const GET_FIRM_USER_DETAILS_SUCCESS = 'GET_FIRM_USER_DETAILS_SUCCESS';
export const GET_FIRM_USER_DETAILS_ERROR = 'GET_FIRM_USER_DETAILS_ERROR';

export const TOGGLE_USER_ACTIVE = 'TOGGLE_USER_ACTIVE';
export const TOGGLE_USER_ACTIVE_SUCCESS = 'TOGGLE_USER_ACTIVE_SUCCESS';
export const TOGGLE_USER_ACTIVE_ERROR = 'TOGGLE_USER_ACTIVE_ERROR';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_WALLET = 'CREATE_WALLET';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_ERROR = 'CREATE_WALLET_ERROR';
export const DELETE_WALLET = 'DELETE_WALLET';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_ERROR = 'DELETE_WALLET_ERROR';

export const SEARCH_ACCOUNTS = 'SEARCH_ACCOUNTS';
export const SEARCH_ACCOUNTS_SUCCESS = 'SEARCH_ACCOUNTS_SUCCESS';
export const SEARCH_ACCOUNTS_ERROR = 'SEARCH_ACCOUNTS_ERROR';

export const CHECK_ACCOUNT_NAME = 'CHECK_ACCOUNT_NAME';

///////////////////////////////////
export const SEARCH_FIRMS = 'SEARCH_FIRMS';
export const SEARCH_FIRMS_SUCCESS = 'SEARCH_FIRMS_SUCCESS';
export const SEARCH_FIRMS_ERROR = 'SEARCH_FIRMS_ERROR';

export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
