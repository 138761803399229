import { useHistory } from 'react-router-dom';
import './AdvancedTrading.scss';

const AdvancedTrading = ({ firstAsset, secondAsset, assetPairs, updateAssetPair }) => {
  const history = useHistory();
  const security = firstAsset + secondAsset;

  const handleTradeRedirect = selectedAsset => {
    const pair = assetPairs.find(asset => asset.security === selectedAsset);
    updateAssetPair(pair);

    history.push('/trade');
  };

  return (
    <div className="advanced-trading">
      <img
        className="advanced-trading-image"
        src={require('../../assets/png/advanced-trading/advanced-trading.png')}
        alt={security}
        onClick={() => handleTradeRedirect(security)}
      />
    </div>
  );
};

export default AdvancedTrading;
