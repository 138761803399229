import { Row, Table } from 'react-bootstrap';
import { capitalizeFirstLetter, formatDate, getLocalisedNumericString } from '../../utils/generalUtils';
import React, { PureComponent } from 'react';
import Pagination from '../Pagination/Pagination';
import { withRouter } from 'react-router';
import i18next from 'i18next';
import CssFilterTransition from '../CssFilterTransition';
import DatePicker, { dateTransactionsOptions } from '../DatePicker';
import { Form } from 'react-bootstrap';
import VLButton from '../Buttons/VLButton';
import { BsPlus } from 'react-icons/bs';
import { FRACTIONS, STAKING_SEARCH_LIST } from '../../enums/validation';
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper';
import ExportButton from '../ExportButton';
import { getDecimalByFraction, getExecutedBy } from '../../utils/transactionsHelpers';
import VLIconButton from '../Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import VLInput from '../VLInput';

const itemPerTable = 12;
const initialState = { txnid: '', accountnumber: '', range: [], dates: '' };

class StakingAndRewardsTableAdmin extends PureComponent {
  state = {
    ...initialState,
    dataPageNumber: 0,
    totalPages: 0,
    displayFilters: false
  };

  handleDateChange = date => {
    var dateFrom = new Date(date[0]);
    var dateTo = new Date(date[1]);
    var isoDateFrom = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString();
    var isoDateTo = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString();
    this.setState(
      {
        range: date,
        dates: isoDateFrom + '&' + isoDateTo
      },
      () => {
        this.triggerFilter();
      }
    );
  };
  clearFilters = () => {
    this.setState(
      {
        ...initialState
      },
      () => {
        this.triggerFilter();
      }
    );
  };
  triggerFilter = () => {
    const from = this.state.dates !== '' ? this.state.dates.split('&')[0] : '';
    const to = this.state.dates !== '' ? this.state.dates.split('&')[1] : '';

    if (this.props.type === 'STAKING') {
      this.props.fetchStakingRequest({
        page: '0,1000,createdDate',
        transaction_id: this.state.txnid,
        account: this.state.accountnumber,
        from,
        to
      });
    } else {
      this.props.fetchStakingRewardsRequest({
        page: '0,1000,createdDate',
        transaction_id: this.state.txnid,
        account: this.state.accountnumber,
        from,
        to
      });
    }
  };
  handleSearchChanges = ({ target }) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      [target.name]: target.value,
      typingTimeout: setTimeout(() => {
        this.triggerFilter();
      }, 1000)
    });
  };
  handlePageClick = data => {
    this.setState({
      dataPageNumber: data.selected
    });
  };
  FILTER_DROPDOWN_OPTIONS = {};
  buildFilters = type => {
    const stakingFilter = this.props.isAdmin ? STAKING_SEARCH_LIST : [];

    const filters = stakingFilter.map((filter, index) => {
      return (
        <div className="user-form-control-container row-width filter-field-expand" key={index}>
          <Form.Label className="search-user-form-label search">{filter}</Form.Label>
          <div className="user-form-control-wrap">
            <VLInput
              size="s"
              type="text"
              name={filter.toLowerCase().replace(/\s+/g, '')}
              placeholder={'Search'}
              value={this.state[filter.replace(/\s+/g, '').toLowerCase()]}
              onChange={this.handleSearchChanges}
              isSearch
            />
          </div>
        </div>
      );
    });
    filters.unshift(
      <>
        <div className="transactions-date-range-container row-width">
          <Form.Label className="search-user-form-label">{i18next.t('transactionsFilters.dataRange')}</Form.Label>
          <div className="system-form-control-box transactions-flatpickr-container payments-date-parent filter-date-expand">
            <DatePicker
              options={{
                ...dateTransactionsOptions,
                drops: 'down',
                parentEl: '.payments-date-parent'
              }}
              date={this.state.range}
              onClick={date => this.handleDateChange(date)}
            />
          </div>
        </div>
        <div className="user-form-control-container">
          <VLButton
            text={i18next.t('transactionsFilters.clearFilter')}
            variant="clear"
            size={'m'}
            rightIcon={<BsPlus />}
            onClick={() => this.clearFilters()}
          />
        </div>
      </>
    );
    return filters;
  };
  getPagedData = () => {
    const startIndex = this.state.dataPageNumber * itemPerTable;
    return this.props.accounts.slice(startIndex, startIndex + itemPerTable);
  };
  toggleFilters = filter => {
    this.setState(prev => ({
      displayFilters: !prev.displayFilters
    }));
  };
  filterEventDataByCategory = () => {
    return this.props.accounts;
  };
  toggleModal = transaction => {
    const transactionDetails = {
      ...transaction,
      type: 'staking',
      asset: 'ETH'
    };
    this.props.toggleTransactionDetails(transactionDetails);
  };
  returnButtonBar = () => {
    return (
      <Row className="filter-alignment transactions-filters-container row no-gutters">
        <div className="inline-justifier">
          <VLIconButton Icon={FaFilter} onClick={() => this.toggleFilters()} />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>

        <div className="transactions-filter-button-container">
          <ExportButton
            isExportTitle
            title="transactions"
            transactionListData={this.filterEventDataByCategory}
            transactionTableType="staking"
            type="staked"
          />
        </div>
      </Row>
    );
  };

  render() {
    const pagedData = this.getPagedData();
    return (
      <React.Fragment>
        {this.returnButtonBar()}
        <CssFilterTransition
          isOpen={this.state.displayFilters}
          component={() => this.buildFilters()}
        ></CssFilterTransition>
        <div className="overflowX-scroll-transactions">
          <LoadingWrapper
            isLoading={this.props.isLoading}
            withReloadBtn
            reloadFunction={this.triggerFilter}
            waitDurationSeconds={60}
          >
            {pagedData.length > 0 ? (
              <Table borderless responsive size="sm">
                <thead>
                  <tr className="transactions-header">
                    <th className="transactions-header-medium">Date</th>
                    <th className="transactions-header-medium">Executed by</th>
                    {this.props.isAdmin && <th className="transactions-header-medium">Account number</th>}
                    <th className="transactions-header-medium">TXN ID</th>
                    <th className="transactions-header-medium">Type</th>
                    <th className="transactions-header-medium">Asset</th>
                    <th className="transactions-header-medium">Amount</th>
                  </tr>
                </thead>
                {pagedData.map(m => {
                  return (
                    <tbody key={m.id} className="transaction-content">
                      <tr className="transactions-table-tr" onClick={() => this.toggleModal(m)}>
                        <td>{formatDate(m.created_date)}</td>
                        <td>{getExecutedBy(m)}</td>
                        {this.props.isAdmin && <td>{m.account}</td>}
                        {this.props.isAdmin ? (
                          <td>{m.id.substring(0, 4) + '...' + m.id.substring(m.id.length - 4)}</td>
                        ) : (
                          <td>{m.id}</td>
                        )}
                        <td>{capitalizeFirstLetter(this.props.type.toLowerCase())}</td>
                        <td>{'ETH'}</td>
                        <td>{getLocalisedNumericString(m.amount, true, getDecimalByFraction(FRACTIONS.ETH))}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            ) : (
              <div className="no-existing-transactions-message">There are no existing transactions</div>
            )}
            {pagedData.length > 0 ? (
              <div className="pagination pagination-pending">
                <Pagination
                  totalPages={Math.ceil(this.props.accounts.length / itemPerTable)}
                  handlePageClick={this.handlePageClick}
                  dataPageNumber={this.state.dataPageNumber}
                />
              </div>
            ) : null}
          </LoadingWrapper>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(StakingAndRewardsTableAdmin);
