import { useState } from 'react';
import Select from 'react-select';
import i18next from 'i18next';
import {
  getSelectedOption,
  moneyFormat,
  parseObjectWithSamePropertyForDropdown,
  replaceCommas
} from '../../../utils/generalUtils';
import {
  AMOUNT_REGEX,
  FIAT_SYMBOLS,
  SECURITY_CODES,
  SECURITY_CODE_NAME_MAP,
  USD_MAX_AMOUNT,
  isCmtSupported
} from '../../../enums/validation';
import { getDecimalByAsset, getDifferenceOfAssets, getSideByAssetPair } from '../../../utils/transactionsHelpers';
import { validateAmount } from '../../../utils/validators';
import './BuySellForm.scss';
import VLButton from '../../Buttons/VLButton';
import { TbArrowsUpDown } from 'react-icons/tb';
import VLIconButton from '../../Buttons/VLIconButton';
import VLInput from '../../VLInput';

const BuySellForm = ({
  isDisabled,
  accountNumber,
  sendAssetData,
  receiveAssetData,
  onSendAssetDataChange,
  onReceiveAssetDataChange,
  onPreview,
  hasFixedAssets,
  assets,
  positions,
  assetsPairs,
  cryptoRates,
  allBooks
}) => {
  const [sendErrorMessage, setSendErrorMessage] = useState('');

  const switchAssets = () => {
    if (!(sendAssetData.sendAsset && receiveAssetData.receiveAsset)) {
      return;
    }

    onSendAssetDataChange(prev => ({
      ...prev,
      sendAsset: receiveAssetData.receiveAsset
    }));
    onReceiveAssetDataChange(prev => ({
      ...prev,
      receiveAsset: sendAssetData.sendAsset
    }));
    onSendAssetDataChange(prev => ({ ...prev, sendAmount: '' }));
    setSendErrorMessage('');
  };

  const getAvailableBalance = asset => {
    return positions?.[accountNumber]?.[asset] || '0';
  };

  const composeAssetOptionComponent = ({ value, label }) => {
    const assetData = assets.data?.find(asset => asset.security === value);

    return (
      <div className="deposit-withdraw-currency-text">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          alt={label}
        />
        {label} -<span>{SECURITY_CODE_NAME_MAP[assetData?.security] || assetData?.security}</span>
      </div>
    );
  };

  const validateInputAmount = (value, field) => {
    let validationObject = validateAmount(
      replaceCommas(value),
      field === 'send' ? getAvailableBalance(sendAssetData.sendAsset) : null,
      getDecimalByAsset(assets?.data, sendAssetData.sendAsset || ''),
      field !== 'send',
      sendAssetData.sendAsset,
      cryptoRates
    );

    let difference = 1;
    const { side, pair } = getSideByAssetPair(assetsPairs.data, sendAssetData.sendAsset, receiveAssetData.receiveAsset);

    difference = getDifferenceOfAssets(
      sendAssetData.sendAsset,
      receiveAssetData.receiveAsset,
      pair,
      cryptoRates,
      side,
      allBooks,
      validationObject.value
    );

    onReceiveAssetDataChange(prev => ({ ...prev, receiveAmount: difference }));

    setSendErrorMessage(checkForErrors(value, difference, sendAssetData.sendAsset));
  };

  const checkForErrors = (value, difference, asset) => {
    if (Number(replaceCommas(value)) > Number(positions[accountNumber][asset])) {
      return `Amount cannot exceed ${moneyFormat(positions[accountNumber][asset]) || 0} ${sendAssetData.sendAsset}.`;
    }

    if (
      (receiveAssetData.receiveAsset === SECURITY_CODES.USD && difference > USD_MAX_AMOUNT) ||
      (asset === SECURITY_CODES.USD && Number(replaceCommas(value)) > USD_MAX_AMOUNT)
    ) {
      return `Amount cannot exceed ${moneyFormat(USD_MAX_AMOUNT)} ${FIAT_SYMBOLS[SECURITY_CODES.USD]}.`;
    }

    return '';
  };

  const isAssetPairSupported = (current, asset) => {
    if (!asset) {
      return true;
    }

    return assetsPairs.data
      .filter(isCmtSupported)
      .some(
        p =>
          (p.pair_first === current && p.pair_second === asset) || (p.pair_first === asset && p.pair_second === current)
      );
  };

  const assetOptions = parseObjectWithSamePropertyForDropdown(assets.data, 'security');

  const sendAssetOptions = assetOptions
    .filter(asset => asset.value !== receiveAssetData.receiveAsset)
    .filter(asset => isAssetPairSupported(asset.value, receiveAssetData.receiveAsset));

  const receiveAssetOptions = assetOptions
    .filter(asset => asset.value !== sendAssetData.sendAsset)
    .filter(asset => isAssetPairSupported(asset.value, sendAssetData.sendAsset));

  const selectedSendAsset = getSelectedOption(sendAssetOptions, sendAssetData.sendAsset);
  const selectedReceiveAsset = getSelectedOption(receiveAssetOptions, receiveAssetData.receiveAsset);

  const handleSelect = (option, fieldName) => {
    if (fieldName === 'send') {
      onSendAssetDataChange(prev => ({
        ...prev,
        sendAsset: option?.value || ''
      }));
    }

    if (fieldName === 'receive') {
      onReceiveAssetDataChange(prev => ({
        ...prev,
        receiveAsset: option?.value || ''
      }));
    }

    setSendErrorMessage('');
    onSendAssetDataChange(prev => ({ ...prev, sendAmount: '' }));
  };

  const handleInputChange = (value, field) => {
    if (sendAssetData.sendAsset === '' || receiveAssetData.receiveAsset === '') {
      return;
    }

    validateInputAmount(value, field);

    if (AMOUNT_REGEX.test(value)) {
      if (field === 'send') {
        onSendAssetDataChange(prev => ({
          ...prev,
          sendAmount: moneyFormat(replaceCommas(value))
        }));
      }

      if (field === 'receive') {
        onReceiveAssetDataChange(prev => ({
          ...prev,
          receiveAmount: moneyFormat(replaceCommas(value))
        }));
      }
    }
  };

  return (
    <div className="buy-sell-input-values-container">
      <div className="send-asset-label">
        <span className="asset-label">{i18next.t('exchangeDetailsForm.assetToSend')}</span>
        <span className="available-amount">
          {sendAssetData.sendAsset && (
            <div>
              {`${sendAssetData.sendAsset} ` +
                'balance' +
                ` = ${sendAssetData.sendAsset === SECURITY_CODES.USD ? '$' : ''} 
                      ${moneyFormat(getAvailableBalance(sendAssetData.sendAsset))}`}
            </div>
          )}
        </span>
      </div>
      <div className="select-send-asset">
        <div className={`select-send-asset-input ${sendErrorMessage ? 'error' : ''}`}>
          <Select
            placeholder={i18next.t('exchangeDetailsForm.select')}
            className="select-dropdown"
            classNamePrefix="deposit-asset"
            isClearable={!hasFixedAssets}
            isSearchable={!hasFixedAssets}
            isDisabled={hasFixedAssets}
            name="sendAsset"
            options={sendAssetOptions}
            onChange={option => handleSelect(option, 'send')}
            value={selectedSendAsset}
            formatOptionLabel={option => composeAssetOptionComponent(option, 'receive')}
          />
          <VLInput
            placeholder="Please enter amount"
            name="sendAmount"
            value={sendAssetData.sendAmount}
            onChange={e => handleInputChange(e.target.value, 'send')}
            maxLength="20"
            noBorder
            width="50%"
          />
        </div>
      </div>
      <div className="send-error">
        {sendErrorMessage && <span className="system-form-control-error-text">{sendErrorMessage}</span>}
      </div>
      <div className="switch-assets">
        <h1>
          <VLIconButton
            className="switch-assets-button"
            variant={'switch'}
            onClick={() => switchAssets()}
            Icon={TbArrowsUpDown}
          />
        </h1>
      </div>
      <div className="receive-asset-label">
        <span className="asset-label">{i18next.t('exchangeDetailsForm.assetToReceive')}</span>
      </div>
      <div className="select-receive-asset">
        <div className={`select-send-asset-input ${sendErrorMessage ? 'error' : ''}`}>
          <Select
            placeholder={i18next.t('exchangeDetailsForm.select')}
            className="select-dropdown"
            classNamePrefix="deposit-asset"
            isClearable={!hasFixedAssets}
            isSearchable={!hasFixedAssets}
            isDisabled={hasFixedAssets}
            name="receiveAsset"
            options={receiveAssetOptions}
            onChange={option => handleSelect(option, 'receive')}
            value={selectedReceiveAsset}
            formatOptionLabel={option => composeAssetOptionComponent(option, 'send')}
          />
        </div>
      </div>
      <div className="buttons">
        <VLButton
          width="100%"
          disabled={isDisabled || sendErrorMessage || sendAssetData.sendAmount === ''}
          onClick={() => onPreview()}
          text={i18next.t('exchangeDetailsForm.preview')}
        />
      </div>
    </div>
  );
};

export default BuySellForm;
