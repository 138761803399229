import 'flatpickr/dist/themes/material_blue.css';

import { Container, Modal, Row } from 'react-bootstrap';
import { REQUEST_TYPE, SEARCH_TYPE_FIRMS, SEND_TYPE, ZERO_ASSET_FORMAT } from '../../enums/validation';
import React, { PureComponent } from 'react';
import {
  buildAccountDropdownWithDisabledField,
  splitDateTime,
  handleLastUpdateTimestamp,
  parseObjectWithSamePropertyForDropdown,
  parseObjectWithValueAndComplexLabelForDropdown
} from '../../utils/generalUtils';

import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import { MdKeyboardBackspace } from 'react-icons/md';
import RoundButton from '../../components/RoundButton';
import SendAndRequestDetails from '../../components/sendAndRequestComponents/SendAndRequestDetails/SendAndRequestDetails';
import SendAndRequestSummary from '../../components/sendAndRequestComponents/SendAndRequestSummary/SendAndRequestSummary';
import { TRANSACTION_SCREENS } from '../../enums/paths';
import TransactionSuccessScreen from '../../components/TransactionSuccessScreen';
import { getTransactionTitleByType } from '../../utils/DOMHelpers';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { replaceCommas } from '../../utils/generalUtils';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import VLButton from '../../components/Buttons/VLButton';
const { day, month, year } = splitDateTime(new Date(), true);
const todaysDate = `${month}/${day}/${year}`;

const REPEAT_CODE_MILLISECONDS = {
  daily: 1000 * 60 * 60 * 24,
  weekly: 1000 * 60 * 60 * 24 * 7
};

const initialState = {
  sourceAccount: '',
  asset: '',
  amountObject: {
    value: '',
    display: '',
    error: false
  },
  maxDecimal: 0,
  firm: '',
  recipient: '',
  publicNote: '',
  privateNote: '',
  startDate: todaysDate,
  endDate: todaysDate,
  repeat: false,
  repeatDuration: '',
  isSending: false,
  transactionID: null
};

class SendAndRequest extends PureComponent {
  static contextType = AccountFilterContext;

  state = {
    ...initialState,
    currentScreen: TRANSACTION_SCREENS.DETAILS,
    //TODO: for debugging just success screen with no data
    // currentScreen: TRANSACTION_SCREENS.SUCCESS,
    transactionType: SEND_TYPE
  };

  componentDidMount() {
    this.setState({ sourceAccount: this.props.currentAccountNumber });
    //fetches accounts you have transaction access to
    this.props.getDepositAccounts();
    // fetches all the assets
    this.props.getTradingAssets();
    //prefetches some firms so the options aren't empty by default
    this.triggerSearch(undefined, SEARCH_TYPE_FIRMS);
    if (this.props.type === SEND_TYPE) {
      this.setState({
        transactionType: SEND_TYPE
      });
    } else {
      this.setState({
        transactionType: REQUEST_TYPE
      });
    }
  }

  switchScreen = screenType => this.setState({ currentScreen: screenType });

  onCloseModal = () => this.props.toggleModal(null);

  handleBackButton = () => {
    if (this.state.currentScreen === TRANSACTION_SCREENS.SUCCESS) this.switchScreen(TRANSACTION_SCREENS.CONFIRM);
    if (this.state.currentScreen === TRANSACTION_SCREENS.CONFIRM) this.switchScreen(TRANSACTION_SCREENS.DETAILS);
  };

  updateParent = (data, callback = null) => {
    this.setState(
      {
        ...data
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };
  triggerSearch = (data, type) => {
    // TODO: For contacts searching
  };

  successTrigger = data => {
    this.setState(
      {
        transactionID: data.refno,
        currentScreen: TRANSACTION_SCREENS.SUCCESS,
        isSending: false
      },
      () => {
        if (this.state.transactionType === SEND_TYPE) {
          this.props.updateTransactionsDB({
            ref: this.state.transactionID,
            type: 'send',
            cryptoRates: this.props.cryptoRates.data
          });
          setTimeout(() => {
            this.props.getAccountTransactions({
              account: this.props.currentAccountNumber,
              firm: this.props.profileDetails.data.firm
            });
          }, 5000);
        }

        toast.success(i18next.t('toasts.success'));
      }
    );
  };

  errorTrigger = error => {
    toast.error(i18next.t('toasts.error2') + `  ${error.result}`);
    this.setState({
      isSending: false
    });
  };

  //build message object send message to the websocket
  submitForm = () => {
    let data = {
      source: this.state.sourceAccount,
      asset: this.state.asset,
      amount: replaceCommas(this.state.amountObject.value),
      recipient: this.state.recipient,
      publicNote: this.state.publicNote,
      privateNote: this.state.privateNote,
      successCallback: this.successTrigger, // call this function on a success response
      errorCallback: this.errorTrigger, //function for an error response
      socket: this.props.websocket
    };
    if (this.state.transactionType === SEND_TYPE) {
      const date = new Date(this.state.startDate);
      const flag = date.getTime() > new Date().getTime();

      if (flag) {
        data.effectiveTime = this.state.startDate.getTime();
      }
      data.endDate = this.state.endDate !== todaysDate ? this.state.endDate : null;
      data.repeat = this.state.repeat;
      let repeatDuration = REPEAT_CODE_MILLISECONDS[this.state.repeatDuration];
      data.repeatFrequency = repeatDuration;
      this.props.makeTransferRequest(data);
    } else {
      let orderData = {
        ...data,
        clientorderid: this.props.currentAccountNumber
      };
      this.props.makeOrderRequest(orderData);
    }
    this.setState({
      isSending: true
    });
  };

  renderDetailsForm = () => {
    // create dropdown options from asset data from api
    let filteredRecipientData = this.props.firmAccounts?.data?.filter(acc => acc.active === 'Y') || [];
    const assetOptions = parseObjectWithSamePropertyForDropdown(this.props.assets?.data, 'security');

    const recipientAccountsOptions = parseObjectWithValueAndComplexLabelForDropdown(
      filteredRecipientData,
      'account',
      ['attr.name'],
      '${0}',
      ['No Account Name']
    );
    const accountsDropdownData = buildAccountDropdownWithDisabledField(
      this.props.accounts?.data,
      'account',
      'attr.name'
    );
    return (
      <SendAndRequestDetails
        {...this.state}
        triggerSearch={this.triggerSearch}
        assetOptions={assetOptions}
        accountsDropdownData={accountsDropdownData}
        recipientAccountsOptions={recipientAccountsOptions}
        assets={this.props.assets}
        history={this.props.history}
        accounts={this.props.accounts}
        updateParent={this.updateParent}
        positions={this.props.positions?.[this.state.sourceAccount]}
        accountContacts={this.props.contacts}
      />
    );
  };

  renderSummary = () => <SendAndRequestSummary {...this.state} accountContacts={this.props.contacts} />;

  render() {
    const { sourceAccount, asset, amountObject, recipient, currentScreen } = this.state;
    const isDetailsDisplay = currentScreen === TRANSACTION_SCREENS.DETAILS;
    const isConfirmScreen = currentScreen === TRANSACTION_SCREENS.CONFIRM;
    const isSuccessScreen = currentScreen === TRANSACTION_SCREENS.SUCCESS;
    const isLoading = !this.props.assets?.loaded && !this.props.accounts?.loaded;

    const disabled =
      sourceAccount &&
      asset &&
      amountObject.value &&
      amountObject.value !== ZERO_ASSET_FORMAT[asset] &&
      recipient &&
      !amountObject.error
        ? false
        : true;
    let type = '';

    if (getTransactionTitleByType(this.state.transactionType) === 'Send Payment') {
      type = i18next.t('transactionType.send');
    } else if (getTransactionTitleByType(this.state.transactionType) === 'Request Payment') {
      type = i18next.t('transactionType.request');
    } else if (getTransactionTitleByType(this.state.transactionType) === 'Deposit Asset') {
      type = i18next.t('transactionType.deposit');
    } else {
      type = i18next.t('transactionType.withdraw');
    }
    return (
      <Modal
        className="common-modal"
        dialogClassName={`send-modal ${isSuccessScreen ? 'success' : ''} ${
          isDetailsDisplay ? 'confirm-screen-class' : ''
        }`}
        centered
        show
        onHide={this.onCloseModal}
        backdrop="static"
      >
        {!isSuccessScreen && (
          <Modal.Header closeButton>
            {!isDetailsDisplay && (
              <MdKeyboardBackspace
                onClick={this.handleBackButton}
                className="external-deposit-back-button"
                size="2.5em"
              />
            )}
            <Modal.Title>{`${type} ${this.state.asset}`}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <LoadingWrapper isLoading={isLoading} waitDurationSeconds={60 * 2}>
            <div className="deposit-container">
              {isSuccessScreen ? (
                <TransactionSuccessScreen
                  type={this.state.transactionType}
                  amount={this.state.amountObject.display}
                  asset={this.state.asset}
                  history={this.props.history} // in case we need to use history.goBack() (legacy)
                  //counterparty is stored as an ID, but we need to display the name so we're going to extract it from the redux data
                  counterparty={this.state.recipient}
                  requestID={this.state.transactionID}
                  specialRedirectFunction={this.onCloseModal}
                  notStandalone
                />
              ) : (
                <Container>
                  <Row className="deposit-header">
                    <RoundButton
                      isActive={isDetailsDisplay}
                      onClick={() => this.switchScreen(TRANSACTION_SCREENS.DETAILS)}
                      number={1}
                      text={i18next.t('sendAndRequestContainer.details')}
                      size="sm"
                    />
                    <RoundButton
                      isActive={!isDetailsDisplay}
                      // isDisabled={true}
                      number={2}
                      text={i18next.t('sendAndRequestContainer.preview')}
                      size="sm"
                    />
                  </Row>

                  {isDetailsDisplay && (
                    <div className="info-text">{i18next.t('sendAndRequestContainer.info-text')}</div>
                  )}

                  {isDetailsDisplay ? this.renderDetailsForm() : this.renderSummary()}
                  <div className="sfcb-button-wrapper">
                    {isDetailsDisplay && (
                      <>
                        <VLButton
                          width="100%"
                          disabled={disabled}
                          onClick={() => this.switchScreen(TRANSACTION_SCREENS.CONFIRM)}
                          text={i18next.t('sendAndRequestContainer.confirm')}
                        />
                      </>
                    )}
                    {isConfirmScreen && (
                      <>
                        <div className="sfcb-button-inner">
                          <VLButton
                            width="100%"
                            onClick={this.submitForm}
                            //to disable sending multiple requests at once
                            disabled={this.state.isSending}
                            text={
                              i18next.t('sendAndRequestContainer.confirm') + `  ${type.toLowerCase().split(' ')[0]}`
                            }
                          />

                          <p className="sfcb-button-text">{handleLastUpdateTimestamp()}</p>
                        </div>
                      </>
                    )}
                  </div>
                </Container>
              )}
            </div>
          </LoadingWrapper>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SendAndRequest;
