import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './CssFilterTransition.scss';

const CssFilterTransition = ({ component, isOpen, children }) => (
  <CSSTransition classNames="ft" in={isOpen} timeout={400}>
    <div className="ft user-control-container vl-filters-container">
      {component && component()}
      {children && children}
    </div>
  </CSSTransition>
);

export default CssFilterTransition;
