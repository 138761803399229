import queryString from 'query-string';

export class HttpMethod {
  static GET = new HttpMethod('GET');
  static POST = new HttpMethod('POST');
  static PUT = new HttpMethod('PUT');
  static DELETE = new HttpMethod('DELETE');
  static PATCH = new HttpMethod('PATCH');
  static OPTIONS = new HttpMethod('OPTIONS');
  static HEAD = new HttpMethod('HEAD');

  constructor(method) {
    this._method = method;
  }

  get value() {
    return this._method;
  }
}

/**
 * Abstract class representing a wrapper around a callable function used to make API requests.
 *
 * @abstract
 */
export default class Request {
  constructor(fn) {
    if (this.constructor === Request) {
      throw new TypeError('Abstract class "Request" cannot be instantiated directly.');
    }

    this._fn = fn;
    this._method = HttpMethod.GET;
    this._url = '';
    this._urlParams = {};
    this._data = undefined;
    this._headers = {};
    this._mappers = [];
  }

  /**
   * Adds a mapper function to the Request object. After returning a response from the API, the
   * response will be passed through each mapper function in the order they were added.
   * Mapping is implementation dependent. This is useful for transforming the response
   * ad hoc without having to change the underlying apiRequest function.
   *
   * @param fn {(any) => any} - mapper function
   * @returns {Request} - this
   */
  map(fn) {
    this._mappers.push(fn);
    return this;
  }

  /**
   * Transforms the Request object onto a promise.
   *
   * @abstract
   * @returns {Promise<any>} - promise
   */
  toPromise() {
    throw new Error("Called abstract method 'toPromise'.");
  }

  /**
   * Transforms the Request object onto an effect that can be used with redux-saga.
   *
   * @abstract
   * @returns *{CallEffect<SagaReturnType<any>>} - redux-saga effect
   */
  *toEffect() {
    throw new Error("Called abstract method 'toPromise'.");
  }

  get fn() {
    return this._fn;
  }

  set fn(value) {
    this._fn = value;
  }

  get method() {
    return this._method;
  }

  set method(value) {
    this._method = value;
  }

  get url() {
    let url = this._url;

    if (Object.keys(this._urlParams).length > 0) url += '?' + queryString.stringify(this._urlParams);

    return url;
  }

  set url(value) {
    this._url = value;
  }

  get urlParams() {
    return this._urlParams;
  }

  set urlParams(value) {
    this._urlParams = value;
  }

  get data() {
    return this._data;
  }

  set data(value) {
    this._data = value;
  }

  get headers() {
    return this._headers;
  }

  set headers(value) {
    this._headers = value;
  }
}
