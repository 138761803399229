import React, { useEffect, useState } from 'react';
import './TradeOrder.scss';
import LimitOrder from './LimitOrder';
import MarketOrder from './MarketOrder';
import IOI from './IOI';
import NegotiateOrder from './NegotiateOrder';
import TradeSideSwitch from './TradeSideSwitch';
import TabGroup, { TAB_GROUP_MODE, useTabs } from './TabGroup';
import LoadingIndicator from '../LoadingIndicator';
import { flipSide } from '../../utils/transactionsHelpers';
import { shouldAllowTransactions } from '../../utils/roleCheckers';

const tabs = [
  { id: 'LIMIT', label: 'Limit' },
  { id: 'MARKET', label: 'Market' },
  { id: 'IOI', label: 'IOI' },
  { id: 'NEGOTIATE', label: 'Negotiate' }
];

const TradeOrder = ({
  userData,
  accounts,
  positions,
  selectedPair,
  selectedIOI,
  selectedLMT,
  setSelectedIoi,
  setSelectedLmt,
  currentAccountNumber
}) => {
  const { activeTab, handleTabClick } = useTabs(tabs, tabs[0].id);
  const [tradeSide, setTradeSide] = useState('B');

  const positionsForAccount = positions[currentAccountNumber];
  const selectedIoiId = selectedIOI?.key;
  const selectedIoiSide = selectedIOI?.side;
  const selectedLmtId = selectedLMT?.keyId;
  const selectedLmtSide = selectedLMT?.side;

  const selectedAccount = accounts.data.find(({ account }) => account === currentAccountNumber);

  const canTransact = shouldAllowTransactions(userData, selectedAccount);

  // When ioi is selected negotiate tab should become active and populated
  useEffect(() => {
    if (selectedIoiId) {
      handleTabClick('NEGOTIATE');
      setTradeSide(flipSide(selectedIoiSide));
    }
  }, [selectedIoiId, selectedIoiSide, handleTabClick]);
  // When lmt is selected limit tab should become active and populated
  useEffect(() => {
    if (selectedLmtId) {
      handleTabClick('LIMIT');
      setTradeSide(flipSide(selectedLmtSide));
    }
  }, [selectedLmtSide, selectedLmtId, handleTabClick]);
  useEffect(() => {
    if (activeTab !== 'LIMIT') {
      setSelectedLmt(null);
    }
    if (activeTab !== 'NEGOTIATE') {
      setSelectedIoi(null);
    }
  }, [activeTab, setSelectedIoi, setSelectedLmt]);

  const getBalance = security => {
    if (!security) return null;

    if (!positionsForAccount) return null;

    return {
      [security.pair_first]: positionsForAccount[security.pair_first] || 0,
      [security.pair_second]: positionsForAccount[security.pair_second] || 0
    };
  };

  const renderOrder = (activeTab, security) => {
    if (activeTab === 'LIMIT')
      return (
        <LimitOrder
          side={tradeSide}
          pair={[security.pair_first, security.pair_second]}
          balance={getBalance(security)}
          account={currentAccountNumber}
          lmt={selectedLMT}
          key={selectedLMT?.keyId + tradeSide} // to reset component's state on side switch
          isDisabled={!canTransact}
        />
      );
    if (activeTab === 'MARKET')
      return (
        <MarketOrder
          side={tradeSide}
          pair={[security.pair_first, security.pair_second]}
          balance={getBalance(security)}
          account={currentAccountNumber}
          key={activeTab + tradeSide} // to reset component's state on side switch
          isDisabled={!canTransact}
        />
      );
    if (activeTab === 'IOI')
      return (
        <IOI
          side={tradeSide}
          pair={[security.pair_first, security.pair_second]}
          balance={getBalance(security)}
          account={currentAccountNumber}
          key={activeTab + tradeSide} // to reset component's state on side switch
          isDisabled={!canTransact}
        />
      );
    if (activeTab === 'NEGOTIATE')
      return (
        <NegotiateOrder
          side={tradeSide}
          pair={[security.pair_first, security.pair_second]}
          balance={getBalance(security)}
          account={currentAccountNumber}
          ioi={selectedIOI}
          key={selectedIOI?.key + tradeSide} // to reset component's state on side switch or ioi change
          isDisabled={!canTransact}
        />
      );

    return 'Unknown order type';
  };

  if (!selectedPair)
    return (
      <div className="trade-order">
        <div className="loading-wrapper">
          <LoadingIndicator />
        </div>
      </div>
    );

  return (
    <div className="trade-order">
      <h4 className="trade-header">Place Order</h4>
      <TradeSideSwitch side={tradeSide} onSideChange={setTradeSide} />
      <TabGroup onTabClick={handleTabClick} activeTab={activeTab} tabs={tabs} mode={TAB_GROUP_MODE.STRETCH} />
      {renderOrder(activeTab, selectedPair)}
      {/*TEMPORARY EXCLUDED/*}
      {/* <NegotiateOrdersWatcher /> */}
    </div>
  );
};

export default TradeOrder;
