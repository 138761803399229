import './TransactionsTable.scss';
import { rejectPaymentRequest, rejectPaymentRequestSuccess } from '../../redux/actions/websocket';
import TransactionsTable from './TransactionsTable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getAssetPairs } from '../../redux/actions/transactions';
import { getTransactionById } from '../../redux/actions/analytics';

const mapStateToProps = state => ({
  assetsPairs: state.transactions.assetsPairs,
  accounts: state.transactions.accounts,
  socket: state.websocket.websocket
});

const mapDispatchToProps = {
  getAssetPairs,
  rejectPaymentRequest,
  rejectPaymentRequestSuccess,
  getTransactionById
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(TransactionsTable);
