import './Contacts.scss';
import Contacts from './Contacts';
import { connect } from 'react-redux';
import {
  getContactsRequest,
  deleteContactRequest,
  getContactsRequestsRequest,
  acceptContactRequest,
  declineContactRequest,
  cancelContactRequest,
  markContactNotificationsAsReadRequest,
  resetCreateContactResponse,
  resetDeleteContactResponse,
  resetAcceptContactResponse,
  resetDeclineContactResponse,
  resetCancelContactResponse
} from '../../redux/actions/administration';
import { getUserProfile } from '../../redux/actions/login';
import { getDepositAccounts } from '../../redux/actions/transactions';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,

  contacts: state.administration.contacts,
  contactsRequests: state.administration.contactsRequests,
  deleteContactResponse: state.administration.deleteContactResponse,
  profileDetails: state.login.profileDetails,
  acceptContactResponse: state.administration.acceptContactResponse,
  declineContactResponse: state.administration.declineContactResponse,
  cancelContactResponse: state.administration.cancelContactResponse,
  currentAccountNumber: state.administration.currentAccountNumber?.data
});

const mapDispatchToProps = {
  getContactsRequest,
  getContactsRequestsRequest,
  deleteContactRequest,
  getUserProfile,
  acceptContactRequest,
  declineContactRequest,
  cancelContactRequest,
  markContactNotificationsAsReadRequest,
  getDepositAccounts,
  resetCreateContactResponse,
  resetDeleteContactResponse,
  resetAcceptContactResponse,
  resetDeclineContactResponse,
  resetCancelContactResponse
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
