import React, { PureComponent } from 'react';
import { TO_BANK, TO_NETWORK, WITHDRAW_TYPE } from '../../enums/validation';

import { Row } from 'react-bootstrap';
import { formatTodaysDate } from '../../utils/generalUtils';
import i18next from 'i18next';

class DepositRedeemSummary extends PureComponent {
  getSpecificText = () => {
    let text = {
      titleType: i18next.t('depositReedemSummary.titleType'),
      accountType: i18next.t('depositReedemSummary.accountType'),
      lastParagraph1: i18next.t('depositReedemSummary.lastParagraph1'),
      lastParagraph2: i18next.t('depositReedemSummary.lastParagraph2')
    };
    if (this.props.transactionType === WITHDRAW_TYPE) {
      text = {
        titleType: i18next.t('depositReedemSummary.titleTypeWithdraw'),
        accountType: i18next.t('depositReedemSummary.accountTypeWithdraw'),
        lastParagraph1: i18next.t('depositReedemSummary.lastParagraph1Withdraw'),
        lastParagraph2: i18next.t('depositReedemSummary.lastParagraph2Withdraw')
      };
    } else if (this.props.transactionType === TO_NETWORK) {
      text = {
        ...text,
        titleType: i18next.t('depositReedemSummary.titleTypeToNetwork'),
        accountType: i18next.t('depositReedemSummary.accountTypeToNetwork')
      };
    } else if (this.props.transactionType === TO_BANK) {
      text = {
        ...text,
        titleType: i18next.t('depositReedemSummary.titleTypeToNetwork'),
        accountType: i18next.t('depositReedemSummary.accountTypeToBank')
      };
    }

    return text;
  };

  render() {
    const { titleType, accountType, lastParagraph1, lastParagraph2 } = this.getSpecificText();
    return (
      <div className="transaction-success-container">
        <Row className="summary-top-container">
          <p className="summary-label-text summary-small-text">{titleType}</p>
          <p className="summary-label-text summary-small-text">{formatTodaysDate()}</p>
        </Row>
        <Row>
          <h2 className="external-deposit-h1-label">
            {this.props.asset} {this.props.amountObject.display}
          </h2>
        </Row>
        <Row>
          <p className="ed-pretitle">{`${accountType} ` + i18next.t('depositReedemSummary.account')}</p>
        </Row>
        <Row>
          <h3 className="external-deposit-h1-label">{this.props.accountData?.attr?.name}</h3>
        </Row>
        <Row>
          <p className="ed-pretitle">{i18next.t('depositReedemSummary.private')}</p>
        </Row>
        <Row>
          <h3 className="external-deposit-h1-label">{this.props.description || 'N/A'}</h3>
        </Row>
        <Row className="summary-last-paragraph-container">
          <p>
            {lastParagraph1} <br /> <br />
            {lastParagraph2}
          </p>
        </Row>
      </div>
    );
  }
}

export default DepositRedeemSummary;
