import { connect } from 'react-redux';
import CreateAccountModal from './CreateAccountModal';
import { createAccountRequest } from '../../../redux/actions/administration';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createAccountRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountModal);
