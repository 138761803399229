import './Profile.scss';

import { getUserProfile, updateUser } from '../../redux/actions/login';
import Profile from './Profile';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  userData: state.login.userData,
  profileDetails: state.login.profileDetails
});

const mapDispatchToProps = {
  updateUser,
  getUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
