import SearchUsersInput from './SearchUsersInput';
import { getUserSuggestionsRequest } from '../../redux/actions/administration';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  userSuggestions: state.administration.userSuggestions
});

const mapDispatchToProps = {
  getUserSuggestionsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsersInput);
