import { connect } from 'react-redux';
import RiskControlSettings from './RiskControlSettings';

import { queryRiskSettings, queryAlerts } from '../../redux/actions/websocket';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  accountDetails: state.administration.accountDetails.data.account,
  riskControlSettings: state.websocket.riskControlSettings
});

const mapDispatchToProps = {
  queryRiskSettings,
  queryAlerts
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskControlSettings);
