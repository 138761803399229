import './QMS.scss';
import { getMarketData } from '../../../utils/transactionsHelpers';
import { sortByDateDescending } from '../../../utils/generalUtils';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import {
  padArray,
  BOOK_SIZE,
  filterOutNonIOI,
  byOrderPriceDesc,
  byOrderPriceAsc,
  mapTroughBelongingOrder
} from '../orderBookHelpers';
import { toast } from 'react-toastify';

const QMS = ({ allBooks, assetPair, setSelectedIoi, openOrders, getIOI }) => {
  const filteredListS = filterOutNonIOI(getMarketData('B', allBooks?.[assetPair?.security]));
  let sellIOIs = padArray(sortByDateDescending(filteredListS).slice(0, BOOK_SIZE)).reverse();
  sellIOIs = mapTroughBelongingOrder(sellIOIs, openOrders).sort(byOrderPriceAsc);
  const filteredListB = filterOutNonIOI(getMarketData('S', allBooks?.[assetPair?.security]));
  let buyIOIs = sortByDateDescending(filteredListB).slice(0, BOOK_SIZE);
  buyIOIs = mapTroughBelongingOrder(buyIOIs, openOrders).sort(byOrderPriceDesc);
  const handleOrderClick = ioi => {
    if (ioi?.belongs) {
      toast.error('Invalid selection: Must select counterparty order');
      setSelectedIoi(null);
      return;
    }
    getIOI({ refno: ioi.key });
    setSelectedIoi(ioi);
  };
  const maxSellQty = Math.max(...sellIOIs.map(o => (o ? o.qty : 0)));
  const maxBuyQty = Math.max(...buyIOIs.map(o => (o ? o.qty : 0)));
  return (
    <div>
      <div>
        {' '}
        <div className="order-book-headers">Price</div>
        <div className="order-book-headers">Amount</div>
        <div className="order-book-headers">Total</div>
      </div>
      <div id="sellSide" className="order-book-body-sell qms-body">
        {sellIOIs.map(sellIOI => {
          return (
            <div className="qms-item" onClick={() => handleOrderClick(sellIOI)}>
              {' '}
              <div
                className={'order-book-order-bar__sell'}
                style={{
                  width: sellIOI?.qty ? (sellIOI?.qty / maxSellQty) * 95 + '%' : '0%'
                }}
              />
              {sellIOI?.belongs && <span className="user-submitted-order-indicator">&#11044;</span>}
              <div className="order-book-details__sell">
                {sellIOI?.price &&
                  getLocalisedNumericString(
                    sellIOI?.price,
                    false,
                    getDecimalByFraction(FRACTIONS[assetPair.pair_second])
                  )}
              </div>
              <div className="order-book-details">
                {' '}
                {sellIOI?.qty &&
                  getLocalisedNumericString(sellIOI?.qty, false, getDecimalByFraction(FRACTIONS[assetPair.pair_first]))}
              </div>
              <div className="order-book-details">
                {' '}
                {sellIOI?.price && parseFloat(sellIOI?.price * sellIOI?.qty).toFixed(6)}
              </div>
            </div>
          );
        })}
      </div>
      <hr className="qms-horizonal-line" />
      <div id="buySide" className="order-book-body qms-body">
        {buyIOIs.map(buyIOI => {
          return (
            <div className="qms-item" onClick={() => handleOrderClick(buyIOI)}>
              {' '}
              <div
                className={'order-book-order-bar__buy'}
                style={{
                  width: buyIOI?.qty && (buyIOI?.qty / maxBuyQty) * 95 + '%'
                }}
              />
              {buyIOI?.belongs && <span className="user-submitted-order-indicator">&#11044;</span>}
              <div className="order-book-details__buy">
                {getLocalisedNumericString(buyIOI.price, false, getDecimalByFraction(FRACTIONS[assetPair.pair_second]))}
              </div>
              <div className="order-book-details">
                {' '}
                {getLocalisedNumericString(buyIOI.qty, false, getDecimalByFraction(FRACTIONS[assetPair.pair_first]))}
              </div>
              <div className="order-book-details"> {parseFloat(buyIOI?.price * buyIOI?.qty).toFixed(6)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default QMS;
