import * as ACTIONS from '../../actions/administration';
import * as CONSTANTS from '../../constants/administration';
import { takeLatest, takeEvery, put } from 'redux-saga/effects';
import { default as URLS } from '../../constants/urls';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import RequestBuilder from '../../../request/RequestBuilder';

function* getListOfFirms(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getListOfFirmsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getListOfFirmsError(err));
  }
}

function* getListOfFirmAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getListOfFirmAccountsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getListOfFirmAccountsError(err));
  }
}

function* createFirm(action) {
  try {
    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.createFirmError(null));
    yield put(ACTIONS.getListOfFirmsRequest({ q: '' }));
  } catch (err) {
    yield put(ACTIONS.createFirmError(err));
  }
}

function* getContacts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getContactsSuccess(response.data));
  } catch (err) {
    yield put(ACTIONS.getContactsError(err));
  }
}

function* getContactsRequests(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getContactsRequestsSuccess(response.data));
  } catch (err) {
    yield put(ACTIONS.getContactsRequestsError(err));
  }
}

function* markContactNotificationsAsRead(action) {
  try {
    const response = yield RequestBuilder.default()
      .patch(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.markContactNotificationsAsReadSuccess(response));
  } catch (err) {
    yield put(ACTIONS.markContactNotificationsAsReadError(err));
  }
}

function* createContact(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .post()
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.createContactSuccess(response));
  } catch (err) {
    yield put(ACTIONS.createContactError(err));
  }
}

function* acceptContact(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.acceptContactSuccess(response));
  } catch (err) {
    yield put(ACTIONS.acceptContactError(err));
  }
}

function* deleteContact(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.deleteContactSuccess(response));
  } catch (err) {
    yield put(ACTIONS.deleteContactError(err));
  }
}

function* cancelContact(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.cancelContactSuccess(response));
  } catch (err) {
    yield put(ACTIONS.cancelContactError(err));
  }
}

function* declineContact(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.declineContactSuccess(response));
  } catch (err) {
    yield put(ACTIONS.declineContactError(err));
  }
}

function* getFirmDetails(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getFirmDetailsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getFirmDetailsError(err));
  }
}

function* getFirmAdminAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getFirmAdminAccountsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getFirmAdminAccountsError(err));
  }
}

function* getFirmUsers(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getFirmUsersSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getFirmUsersError(err));
  }
}

function* getUserSuggestions(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    action.onSuccess?.();
    yield put(ACTIONS.getUserSuggestionsSuccess(response));
  } catch (err) {
    action.onError?.();
    yield put(ACTIONS.getUserSuggestionsError(err));
  }
}

function* getAccountSuggestions(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback?.(response);
    yield put(ACTIONS.getAccountSuggestionsSuccess(response));
  } catch (err) {
    action.data.errorCallback?.(err);
    yield put(ACTIONS.getAccountSuggestionsError(err));
  }
}

function* createAccount(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    if (response.message === 'success') {
      yield put(ACTIONS.createAccountSuccess());
      action.data.successCallback?.();
    } else {
      action.data.errorCallback?.(response.message);
    }
  } catch (err) {
    action.data.errorCallback?.(err);
    yield put(ACTIONS.createAccountError(err));
  }
}

function* createAdminAccount(action) {
  try {
    const body = {
      ...action.data.data,
      firm: action.data.firm
    };

    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    if (response.message === 'success') {
      action.data.successCallback(response.message);
      toast.success(i18next.t('toasts.successUser'));
    }

    yield put(ACTIONS.getFirmUsersRequest(action.firm));
    yield put(ACTIONS.getFirmDetailsRequest(action.firm));
  } catch (err) {
    action.data.errorCallback();
    yield put(ACTIONS.createAdminAccountError(err));
  }
}

function* deleteFirm(action) {
  try {
    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getListOfFirmsRequest({ q: '' }));
  } catch (err) {
    yield put(ACTIONS.deleteFirmError(err));
  }
}

function* deleteAccount(action) {
  try {
    const body = {
      account: action.data.account
    };

    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback();
  } catch (err) {
    yield put(ACTIONS.deleteAccountError(err));
    action.data.errorCallback();
  }
}

function* deleteUserAdmin(action) {
  try {
    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getFirmUsersRequest(action.firm));
  } catch (err) {
    yield put(ACTIONS.deleteAdminAccountError(err));
  }
}

function* registerWallet(action) {
  try {
    const body = {
      account: action.data.account,
      firm: action.data.firm
    };

    yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.registerWalletForAccountSuccess({ account: action.data.account }));
    action.data.successCallback(action.data.account);
  } catch (err) {
    yield put(ACTIONS.registerWalletForAccountError({ account: action.data.account }));
    action.data.errorCallback(action.data.account);
  }
}

function* getPendingOnboardingUsers(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getPendingOnboardingUsersSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getPendingOnboardingUsersError(err));
  }
}

function* getOnboardingUsers(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getOnboardingUsersSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getOnboardingUsersError(err));
  }
}

function* acceptOnboardingUsers(action) {
  try {
    const body = {
      userId: action.data.userId
    };

    yield RequestBuilder.default()
      .put(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback();
    yield put(ACTIONS.acceptOnboardingUserSuccess({ userId: action.data.userId }));
  } catch (err) {
    action.data.errorCallback(err);
  }
}

function* declineOnboardingUsers(action) {
  try {
    const body = { userId: action.data.userId };

    yield RequestBuilder.default()
      .put(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback();
    yield put(ACTIONS.declineOnboardingUserSuccess({ userId: action.data.userId }));
  } catch (err) {
    action.data.errorCallback(err);
  }
}

function* getAccountDetails(action) {
  try {
    const url = `${URLS[action.type]}/${action.data.account}`;

    const response = yield RequestBuilder.default()
      .get(url)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAccountDetailsSuccess(response));
    if (action.data.successCallback) {
      action.data.successCallback();
    }
  } catch (err) {
    yield put(ACTIONS.getAccountDetailsError(err));
  }
}

function* getUserDetails(action) {
  try {
    const url = `${URLS[action.type]}/${action.data.userId}`;

    const response = yield RequestBuilder.default()
      .get(url)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getUserDetailsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getUserDetailsError(err));
  }
}

function* uploadBtaReport(action) {
  try {
    const body = {
      userId: action.data.id,
      reportFile: action.data.file
    };

    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .formData(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response.message);
    yield put(ACTIONS.uploadBtaReportSuccess(response));
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.uploadBtaReportError(err));
  }
}

function* deleteBtaReport(action) {
  try {
    const body = {
      userId: action.data.userId
    };

    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response.message);
    yield put(ACTIONS.deleteBtaReportSuccess({ userId: action.data.userId }));
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.deleteBtaReportError(err));
  }
}

export default function* loginSaga() {
  yield takeLatest(CONSTANTS.GET_LIST_OF_FIRMS_REQUEST, getListOfFirms);
  yield takeLatest(CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS, getListOfFirmAccounts);
  yield takeLatest(CONSTANTS.CREATE_FIRM_REQUEST, createFirm);
  yield takeLatest(CONSTANTS.GET_FIRM_DETAILS_REQUEST, getFirmDetails);
  yield takeLatest(CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_REQUEST, getFirmAdminAccounts);
  yield takeLatest(CONSTANTS.GET_FIRM_USERS_REQUEST, getFirmUsers);
  yield takeLatest(CONSTANTS.CREATE_ACCOUNT_REQUEST, createAccount);
  yield takeLatest(CONSTANTS.CREATE_ADMIN_ACCOUNT_REQUEST, createAdminAccount);
  yield takeLatest(CONSTANTS.DELETE_FIRM_REQUEST, deleteFirm);
  yield takeLatest(CONSTANTS.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(CONSTANTS.DELETE_ADMIN_ACCOUNT_REQUEST, deleteUserAdmin);
  yield takeLatest(CONSTANTS.REGISTER_WALLET_FOR_ACCOUNT, registerWallet);
  yield takeEvery(CONSTANTS.GET_ONBOARDING_USERS_REQUEST, getOnboardingUsers);
  yield takeEvery(CONSTANTS.GET_PENDING_ONBOARDING_USERS_REQUEST, getPendingOnboardingUsers);
  yield takeLatest(CONSTANTS.CREATE_CONTACT_REQUEST, createContact);
  yield takeLatest(CONSTANTS.ACCEPT_CONTACT_REQUEST, acceptContact);
  yield takeLatest(CONSTANTS.DELETE_CONTACT_REQUEST, deleteContact);
  yield takeLatest(CONSTANTS.MARK_CONTACT_NOTIFICATIONS_AS_READ_REQUEST, markContactNotificationsAsRead);
  yield takeLatest(CONSTANTS.DECLINE_CONTACT_REQUEST, declineContact);
  yield takeLatest(CONSTANTS.CANCEL_CONTACT_REQUEST, cancelContact);
  yield takeLatest(CONSTANTS.ACCEPT_ONBOARDING_USER_REQUEST, acceptOnboardingUsers);
  yield takeLatest(CONSTANTS.DECLINE_ONBOARDING_USER_REQUEST, declineOnboardingUsers);
  yield takeLatest(CONSTANTS.GET_ACCOUNT_DETAILS_REQUEST, getAccountDetails);
  yield takeLatest(CONSTANTS.GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeEvery(CONSTANTS.GET_CONTACTS_REQUEST, getContacts);
  yield takeEvery(CONSTANTS.GET_CONTACTS_REQUESTS_REQUEST, getContactsRequests);

  yield takeLatest(CONSTANTS.UPLOAD_BTA_REPORT_REQUEST, uploadBtaReport);
  yield takeLatest(CONSTANTS.DELETE_BTA_REPORT_REQUEST, deleteBtaReport);
  yield takeLatest(CONSTANTS.GET_USER_SUGGESTIONS_REQUEST, getUserSuggestions);
  yield takeLatest(CONSTANTS.GET_ACCOUNT_SUGGESTIONS_REQUEST, getAccountSuggestions);
}
