import { Col, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import ListAccountsModal from '../modals/ListAccountsModal/ListAccountsModal';
import AccountDropdown from './AccountDropdown';

class DashboardAccountDropdown extends PureComponent {
  state = {
    displayAccountList: false
  };

  static contextType = AccountFilterContext;

  toggleAccountListDisplay = () => this.setState({ displayAccountList: !this.state.displayAccountList });

  render() {
    return (
      <Row noGutters className="dashboard-dropdown-row" id="js-accountWrap">
        <ListAccountsModal accounts={this.props.accounts?.data || []} onClose={this.toggleAccountListDisplay} />

        <Row noGutters className="account-positions-div">
          <Col className="account-positions-left-col">
            <AccountDropdown />
          </Col>
        </Row>
      </Row>
    );
  }
}

export default DashboardAccountDropdown;
