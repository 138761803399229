import { useCallback, useState } from 'react';

const useTabs = (tabItems, initialTab) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabClick = useCallback(
    id => {
      if (!tabItems.find(tab => tab.id === id)) {
        throw Error('Invalid tab id');
      }

      setActiveTab(id);
    },
    [setActiveTab, tabItems]
  );

  return { activeTab, handleTabClick };
};

export default useTabs;
