import { Row } from 'react-bootstrap';
import React from 'react';
import { ACCOUNT_NAME_REGEX, USA_STATES } from '../../../../enums/validation';
import i18next from 'i18next';
import { mapValuesListToOptions, renderInput, renderSelect } from '../helpers';
import { useCreateAccountContext } from '../context/CreateAccountContext';
import { PRODUCT_TYPES } from '../../../../enums/roles';
import VLButton from '../../../Buttons/VLButton';

const STATE_OPTIONS = mapValuesListToOptions(USA_STATES);
const COUNTRY_OPTIONS = mapValuesListToOptions(['United States']);

const PRIMARY_USE_OPTIONS = mapValuesListToOptions([
  'Investing/Holding',
  'Online Purchases',
  'Payment to an individual business',
  'Trading Buy/Sell',
  'Other'
]);

const PRODUCT_OPTIONS = mapValuesListToOptions([PRODUCT_TYPES.PCA, PRODUCT_TYPES.SCA]);

const REQUIRED_FIELDS = ['accountName', 'address1', 'city', 'country', 'state', 'postalCode', 'primaryUse', 'product'];

const AccountDetailsStep = ({ onNext, confirmButtonText }) => {
  const { formState, actions } = useCreateAccountContext();
  const { errors, accountName, isProductReadOnly } = formState;
  const { updateValue, setError, clearErrors } = actions;

  const handleOnChange = ({ target: { value, name } }) => {
    updateValue(name, value);
  };

  const handleOnDropdownChange = ({ value }, { name }) => {
    updateValue(name, value);
  };

  const validateForm = () => {
    let isValid = true;

    REQUIRED_FIELDS.forEach(field => {
      if (!formState[field]) {
        setError(field, 'Required');
        isValid = false;
      }
    });

    if (!ACCOUNT_NAME_REGEX.test(accountName)) {
      setError('accountName', 'Account name cannot contain special characters');
      isValid = false;
    }

    return isValid;
  };

  const handleNext = () => {
    clearErrors();

    if (!validateForm()) return;

    onNext();
  };

  const renderFormInput = ({ name, label }) => {
    return renderInput({
      name,
      label,
      value: formState[name],
      onChange: handleOnChange,
      error: errors[name],
      isRequired: REQUIRED_FIELDS.find(r => r === name)
    });
  };

  const renderFormSelect = ({ name, label, options }) => {
    return renderSelect({
      name,
      label,
      value: options.find(o => o.value === formState[name]),
      onChange: handleOnDropdownChange,
      options,
      error: errors[name],
      isRequired: REQUIRED_FIELDS.find(r => r === name)
    });
  };

  return (
    <div>
      {renderFormInput({
        name: 'accountName',
        label: 'Account name'
      })}
      {!isProductReadOnly
        ? renderFormSelect({
            name: 'product',
            label: 'Product type',
            options: PRODUCT_OPTIONS
          })
        : null}
      {renderFormInput({
        name: 'address1',
        label: 'Address 1'
      })}
      {renderFormInput({
        name: 'address2',
        label: 'Address 2'
      })}
      {renderFormInput({
        name: 'city',
        label: 'City'
      })}
      {renderFormSelect({
        name: 'state',
        label: 'State',
        options: STATE_OPTIONS
      })}
      {renderFormInput({
        name: 'postalCode',
        label: 'Postal code'
      })}
      {renderFormSelect({
        name: 'country',
        label: 'Country',
        options: COUNTRY_OPTIONS
      })}
      {renderFormSelect({
        name: 'primaryUse',
        label: 'Primary use for crypto-assets?',
        options: PRIMARY_USE_OPTIONS
      })}

      <Row noGutters className="summary-row">
        <p className="mandatory-text">
          {i18next.t('createAccountModal.fields')}
          <span className="mandatory-star">*</span>' {i18next.t('createAccountModal.mandatory')}
        </p>
      </Row>
      <div className="external-deposit-footer-bar">
        <VLButton disabled={false} onClick={handleNext} text={confirmButtonText} width="100%" />
      </div>
    </div>
  );
};

export default AccountDetailsStep;
