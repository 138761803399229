import { TIME_MILLISECONDS } from '../enums/time';

/**
 * Extracts days from time in milliseconds.
 * @param {Number} time - Time in milliseconds.
 * @returns {Number} - Number of days.
 */
export const extractDaysFromMillisecondTime = time => {
  return Math.floor(time / TIME_MILLISECONDS.DAY);
};

/**
 * Extracts hours from time in milliseconds.
 * @param {Number} time - Time in milliseconds.
 * @returns {Number} - Number of hours.
 */
export const extractHoursFromMillisecondTime = time => {
  return Math.floor((time % TIME_MILLISECONDS.DAY) / TIME_MILLISECONDS.HOUR);
};

/**
 * Extracts minutes from time in milliseconds.
 * @param {Number} time - Time in milliseconds.
 * @returns {Number} - Number of minutes.
 */
export const extractMinutesFromMillisecondTime = time => {
  return Math.floor((time % TIME_MILLISECONDS.HOUR) / TIME_MILLISECONDS.MINUTE);
};

/**
 * Extracts seconds from time in milliseconds.
 * @param {Number} time - Time in milliseconds.
 * @returns {Number} - Number of seconds.
 */
export const extractSecondsFromMillisecondTime = time => {
  return Math.floor((time % TIME_MILLISECONDS.MINUTE) / TIME_MILLISECONDS.SECOND);
};
