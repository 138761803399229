/* eslint-disable import/no-anonymous-default-export */
import * as CONSTANTS from '../constants/transactionsExternal';
import { TRANSACTION_API_TYPES } from '../../enums/validation';

const initialState = {
  externalTransactionsPending: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionsReviewed: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionDetails: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionsUsd: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionsUsdPending: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionsUsdReviewed: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  staking: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  stakingRewardsSummary: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  stakingRewards: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  externalTransactionUsdUpdate: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  internalWallets: {
    data: null,
    loaded: false,
    loading: false,
    error: ''
  },
  supportedAssets: {
    data: ['USD'],
    loaded: false,
    loading: false,
    error: ''
  },
  cryptoTransactionFee: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS:
      return {
        ...state,
        externalTransactionsPending: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionsPending: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionsPending: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    case CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS:
      return {
        ...state,
        externalTransactionsReviewed: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionsReviewed: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionsReviewed: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    case CONSTANTS.FETCH_STAKING_REQUEST:
      return {
        ...state,
        staking: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_STAKING_REQUEST_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        staking: {
          data: [...data.map(t => ({ ...t, type: TRANSACTION_API_TYPES.STAKING }))],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_STAKING_REQUEST_ERROR:
      return {
        ...state,
        staking: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS:
      return {
        ...state,
        externalTransactionsUsd: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };

    case CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        stakingRewardsSummary: {
          data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_ERROR:
      return {
        ...state,
        stakingRewardsSummary: {
          data: {},
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    case CONSTANTS.FETCH_STAKING_REWARDS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        stakingRewards: {
          // This endpoint is polymorphic, for admins it returns a list but for
          // users it returns an object with rewards and total
          data: [
            ...(data.rewards ?? data).map(t => ({
              ...t,
              type: TRANSACTION_API_TYPES.STAKING_REWARD
            }))
          ],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_STAKING_REWARDS_ERROR:
      return {
        ...state,
        stakingRewards: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    case CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionsUsd: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionsUsd: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS:
      return {
        ...state,
        externalTransactionUsdUpdate: {
          data: {},
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionUsdUpdate: {
          data: {},
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionUsdUpdate: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS:
      console.log(state.externalTransactionDetails.data);
      return {
        ...state,
        externalTransactionDetails: {
          data: state.externalTransactionDetails.data,
          loading: true
        }
      };
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionDetails: {
          data: data,
          loading: false
        }
      };
    }
    case CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_ERROR:
      return {
        ...state
      };
    case CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID:
      return {
        ...state,
        externalTransactionDetails: {
          data: state.externalTransactionDetails.data,
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionDetails: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_ERROR:
      return {
        ...state,
        externalTransactionDetails: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    case CONSTANTS.FETCH_SUPPORTED_ASSETS_REQUEST:
      return {
        ...state,
        supportedAssets: {
          ...state.supportedAssets,
          loaded: false,
          loading: true
        }
      };
    case CONSTANTS.FETCH_SUPPORTED_ASSETS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        supportedAssets: {
          data: ['USD', ...data],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_SUPPORTED_ASSETS_ERROR:
      return {
        ...state,
        supportedAssets: {
          data: ['USD'],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.FETCH_INTERNAL_WALLETS_REQUEST:
      return {
        ...state,
        internalWallets: {
          ...state.internalWallets,
          loading: true
        }
      };
    case CONSTANTS.FETCH_INTERNAL_WALLETS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        internalWallets: {
          data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_INTERNAL_WALLETS_ERROR:
      return {
        ...state,
        internalWallets: {
          data: null,
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE:
      const object = action.data.comment
        ? { comment: action.data.comment }
        : { riskScore: action.data?.['risk-score'] };
      //TO DO
      console.log(state.externalTransactionDetails.data);
      console.log(object);
      return {
        ...state,
        externalTransactionDetails: {
          data: {
            ...state.externalTransactionDetails.data,
            ...object
          }
        }
      };
    case CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_SUCCESS: {
      const data = action.data;
      console.log(state.externalTransactionDetails.data);
      console.log(action.data);
      //TO DO
      return {
        ...state,
        externalTransactionDetails: {
          data: data
        }
      };
    }
    case CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_ERROR:
      return {
        ...state
      };
    case CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS:
      return {
        ...state,
        externalTransactionsUsdPending: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionsUsdPending: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionsUsdPending: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS:
      return {
        ...state,
        externalTransactionsUsdReviewed: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        externalTransactionsUsdReviewed: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_ERROR:
      return {
        ...state,
        externalTransactionsUsdReviewed: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.TRANSACTIONS_FEE_REQUEST:
      return {
        ...state,
        cryptoTransactionFee: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    case CONSTANTS.TRANSACTIONS_FEE_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        cryptoTransactionFee: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.TRANSACTIONS_FEE_ERROR:
      return {
        ...state,
        cryptoTransactionFee: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
