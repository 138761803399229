import { usdApi } from '../axios';

/**
 * Generic Axios wrapper for making requests to the USD API.
 *
 * @param method {"GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD" | "OPTIONS"} - HTTP method
 * @param url {string} - url to send request to
 * @param data {object} - data to send with request
 * @param config {{headers: Record<string, string|string[]>} | {}} - config object to pass to axios
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function axiosUsdApiRequest(method, url, data, config = {}) {
  return usdApi.request({
    method,
    url,
    data,
    ...config
  });
}
