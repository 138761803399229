import { Row } from 'react-bootstrap';
import {
  DEPOSIT_TYPE,
  FRACTIONS,
  SECURITY_CODE_NAME_MAP,
  SECURITY_CODES,
  WITHDRAW_TYPE
} from '../../../enums/validation';
import React, { PureComponent } from 'react';
import {
  findFiatCurrencySymbol,
  getLocalisedNumericString,
  getPositionDataForSpecificAccount,
  getSelectedOption,
  parseObjectWithSamePropertyForDropdown
} from '../../../utils/generalUtils';

import { AccountFilterContext } from '../../../contexts/AccountFilterContext';
import VLSelect from '../../VLSelect';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import i18next from 'i18next';
import ConfirmationSourceModal from '../../modals/ConfirmationSourceModal/ConfirmationSourceModal';
import VLButton from '../../Buttons/VLButton';

class CurrencyStep extends PureComponent {
  static contextType = AccountFilterContext;
  state = {};
  getDataAmountForSpecificAccount = security => {
    const { VLN } = getPositionDataForSpecificAccount(
      this.props.assets.data,
      this.props.VLNPositions || {},
      this.props.accounts.data,
      this.props.currentAccountNumber
    );
    return +VLN[security] || 0;
  };

  composeAssetOptionComponent = ({ value, label }) => {
    const assets = this.props.assets.data;
    const assetData = assets?.find(asset => asset.security === value);
    const VLN = this.getDataAmountForSpecificAccount(assetData?.security);
    // const VLN = this.getBalanceFromPosition(assetData?.security);
    const decimal = getDecimalByFraction(FRACTIONS[assetData?.security]) || 0;

    return (
      <div className="deposit-withdraw-currency-text">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          // src={assetData?.attr.image}
          alt={`${assetData?.security}`}
        />
        {label} -<span>{SECURITY_CODE_NAME_MAP[assetData?.security] || assetData?.security}</span>
        <span className="deposit-withdraw-currency-amount">
          {`
            ${assetData?.security}
            ${findFiatCurrencySymbol(assetData?.security)}
            ${getLocalisedNumericString(VLN, true, decimal)}
          `}
        </span>
      </div>
    );
  };

  handleSelectAsset = event => {
    this.setState({ asset: event?.value });
    this.props.handleSelect(event);
  };

  handleConfirmation = () => {
    this.setState({ displayModal: '' });
  };

  onClose = () => {
    this.setState({ displayModal: '' });
  };

  buildSelectAssets = ({ text }) => {
    let assets = this.props.assets.data;
    if (this.props.type === DEPOSIT_TYPE) {
      assets = this.props.assets.data.filter(data => data.security !== SECURITY_CODES.VLC$);
    }

    let assetOptions = parseObjectWithSamePropertyForDropdown(assets, 'security');

    const selectedAsset = getSelectedOption(assetOptions, this.props.asset);

    return (
      <>
        <p className="external-deposit-title-label">{text}</p>
          <VLSelect
            placeholder={i18next.t('exchangeDetailsForm.select')}
            isClearable={true}
            name="asset"
            options={assetOptions}
            onChange={e => this.handleSelectAsset(e)}
            value={selectedAsset}
            formatOptionLabel={this.composeAssetOptionComponent}
          />
        <Row className="external-deposit-footer-bar">
          <VLButton
            disabled={!this.props.asset}
            width="100%"
            onClick={this.props.onCompleted}
            text={i18next.t('firstStep.confirm')}
          />
        </Row>
        {this.state.displayModal === 'confirm_source' &&
          this.props.type === DEPOSIT_TYPE &&
          this.state.asset !== 'USD' &&
          this.state.asset && (
            <ConfirmationSourceModal
              asset={this.state.asset}
              handleSelect={this.handleConfirmation}
              onClose={this.onClose}
            />
          )}
      </>
    );
  };

  render() {
    switch (this.props.type) {
      case DEPOSIT_TYPE: {
        return this.buildSelectAssets({
          text: i18next.t('firstStep.depositType')
        });
      }
      case WITHDRAW_TYPE: {
        return this.buildSelectAssets({
          text: i18next.t('firstStep.withdrawType')
        });
      }
      default:
        break;
    }
  }
}

export default CurrencyStep;
