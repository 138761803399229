import { Row, Form } from 'react-bootstrap';
import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';
import ContactsTable from '../../components/ContactsTable/ContactsTable';
import ContactRequestsTable from '../../components/ContactRequestsTable/ContactRequestsTable';
import { isResponseSuccessful } from '../../utils/validators';
import CssFilterTransition from '../../components/CssFilterTransition';
import i18next from 'i18next';
import { BsPlus } from 'react-icons/bs';
import { ACCOUNT_TYPE, CONTACT_FILTER_OPTION, CONTACT_SEARCH_OPTIONS } from '../../enums/validation';
import VLButton from '../../components/Buttons/VLButton';
import VLIconButton from '../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import VLSelect from '../../components/VLSelect';
import VLInput from '../../components/VLInput';

const Contacts = ({
  getContactsRequest,
  getContactsRequestsRequest,
  declineContactResponse,
  acceptContactResponse,
  deleteContactResponse,
  cancelContactResponse,
  cancelContactRequest,
  contacts,
  contactsRequests,
  deleteContactRequest,
  declineContactRequest,
  getUserProfile,
  profileDetails,
  acceptContactRequest,
  getDepositAccounts,
  accounts,
  markContactNotificationsAsReadRequest,
  resetDeleteContactResponse,
  resetAcceptContactResponse,
  resetDeclineContactResponse,
  resetCancelContactResponse,
  currentAccountNumber
}) => {
  const [displayFilters, setDisplayFilters] = useState(false);
  const [accountSearch, setAccountSearch] = useState('');
  const [accountType, setAccountType] = useState(null);
  const shouldUpdateContent = useCallback(() => {
    getContactsRequest({
      currentAccount: currentAccountNumber,
      accountNumber: accountSearch,
      accountType: accountType?.value
    });
    getContactsRequestsRequest({ currentAccount: currentAccountNumber });
    getDepositAccounts({ access: 'R' });
    getUserProfile();
    markContactNotificationsAsReadRequest({
      currentAccount: currentAccountNumber
    });
  }, [
    currentAccountNumber,
    accountSearch,
    accountType,
    getContactsRequest,
    getContactsRequestsRequest,
    getDepositAccounts,
    getUserProfile,
    markContactNotificationsAsReadRequest
  ]);

  useEffect(() => {
    shouldUpdateContent();
    if (isResponseSuccessful(deleteContactResponse)) {
      toast.success(deleteContactResponse?.reason);
      resetDeleteContactResponse();
    }
  }, [deleteContactResponse, resetDeleteContactResponse, shouldUpdateContent]);

  useEffect(() => {
    shouldUpdateContent();

    if (isResponseSuccessful(acceptContactResponse)) {
      toast.success(acceptContactResponse?.reason);
      resetAcceptContactResponse();
    } else {
      toast.error(acceptContactResponse?.reason);
    }
  }, [acceptContactResponse, resetAcceptContactResponse, shouldUpdateContent]);

  useEffect(() => {
    shouldUpdateContent();

    if (isResponseSuccessful(declineContactResponse)) {
      toast.success(declineContactResponse?.reason);
      resetDeclineContactResponse();
    } else {
      toast.success(declineContactResponse?.reason);
    }
  }, [shouldUpdateContent, declineContactResponse, resetDeclineContactResponse]);

  useEffect(() => {
    shouldUpdateContent();

    if (!isResponseSuccessful(cancelContactResponse)) return;

    resetCancelContactResponse();
    toast.success(cancelContactResponse?.reason);
  }, [shouldUpdateContent, cancelContactResponse, resetCancelContactResponse]);

  const handleRemoveContact = contact =>
    deleteContactRequest({
      requesterAccount: contact?.accountNumber,
      receiverAccount: currentAccountNumber
    });

  const clearFilters = () => {
    setAccountSearch('');
    setAccountType(null);
  };
  const handleCancelRequest = contact =>
    cancelContactRequest({
      id: contact?.id,
      contactEmail: contact?.email,
      contactName: contact?.fullName
    });
  const handleSearchChange = ({ target }) => {
    setAccountSearch(target.value);
  };
  const toggleFilters = () => {
    setDisplayFilters(!displayFilters);
  };

  const getTypeFilters = () => [
    {
      value: '',
      label: i18next.t('contacts.allTypes')
    },
    {
      value: 'Individual',
      label: i18next.t('contacts.individual')
    },
    {
      value: 'Corporate',
      label: i18next.t('contacts.corporate')
    }
  ];

  const FILTER_DROPDOWN_OPTIONS = {
    [ACCOUNT_TYPE]: getTypeFilters
  };
  const handleFilterChange = (target, option) => {
    setAccountType({
      label: target.label,
      name: target.name,
      value: target.value
    });
  };

  const buildFilters = () => {
    const filters = CONTACT_FILTER_OPTION;

    const fitlerOptions = filters.map(filter => {
      let filterSearch = i18next.t('payments.type');

      return (
        <div className="row-width filter-field-expand" key={filter}>
          <Form.Label className="search-user-form-label">{filterSearch}</Form.Label>
          <div className="sfcb-transparent">
            <VLSelect
              isSearchable={true}
              name={filter}
              options={FILTER_DROPDOWN_OPTIONS[filter]()}
              onChange={handleFilterChange}
              value={accountType}
            />
          </div>
        </div>
      );
    });
    let searchFilters = CONTACT_SEARCH_OPTIONS;

    const searches = searchFilters.map((filter, index) => {
      return (
        <div className="contacts-form-control-container" key={index}>
          <Form.Label className="search-user-form-label search">{filter}</Form.Label>
          <div>
            <VLInput
              name={filter.toLowerCase().replace(/\s+/g, '')}
              placeholder="Search"
              value={accountSearch}
              onChange={handleSearchChange}
              size="s"
              isSearch
            />
          </div>
        </div>
      );
    });
    fitlerOptions.unshift(searches);

    return fitlerOptions;
  };

  const contactFilters = () => {
    return (
      <CssFilterTransition isOpen={displayFilters}>
        <Row noGutters className="contacts-filter-bar">
          {buildFilters()}
          <div className="contacts-form-control-container">
            <VLButton
              text={i18next.t('transactionsFilters.clearFilter')}
              variant="clear"
              size="m"
              rightIcon={<BsPlus />}
              onClick={clearFilters}
            />
          </div>
        </Row>
      </CssFilterTransition>
    );
  };

  return (
    <Card>
      <LoadingWrapper
        isLoading={!contacts.loaded}
        withReloadBtn
        waitDurationSeconds={5}
        styleClass={'spinner-position-contacts'}
      >
        <div className="contacts-container-wrap">
          <div className="contacts-bar contacts-container">
            <PageTitle text={'Contacts'} />
            <div className="inline-justifier">
              <VLIconButton Icon={FaFilter} onClick={() => toggleFilters()} />
              <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
            </div>
          </div>
          <div className="contacts-container">{contactFilters()}</div>
        </div>

        <ContactsTable
          contacts={contacts}
          userRole={profileDetails?.data?.roles}
          accounts={accounts?.data}
          currentAccount={currentAccountNumber}
          onRemoveContact={handleRemoveContact}
          accountNumber={currentAccountNumber}
        />

        {contactsRequests?.data?.length > 0 && (
          <React.Fragment>
            <div className="horizontal-line" />
            <PageTitle text={'Pending Contacts'} />
            <ContactRequestsTable
              isPending={true}
              onCancelRequest={handleCancelRequest}
              onAcceptContact={acceptContactRequest}
              onDeclineContact={declineContactRequest}
              contacts={contactsRequests}
              accountNumber={currentAccountNumber}
            />
          </React.Fragment>
        )}
      </LoadingWrapper>
    </Card>
  );
};

export default Contacts;
