import './VerificationInput.scss';
import { useEffect, useRef } from 'react';

const VerificationInput = ({ onChange, hasError = false, digits = 6 }) => {
  const itemsRef = useRef([]);

  useEffect(() => {
    itemsRef.current[0].focus();
  }, []);

  const handleChange = e => {
    onChange(
      itemsRef.current
        .map(a => a.value)
        .join('')
        .trim()
    );
    if (parseInt(e.target.name) >= digits - 1) return;
    if (e.target.value && e.target.value.length) {
      itemsRef.current[parseInt(e.target.name) + 1].focus();
      itemsRef.current[parseInt(e.target.name) + 1].select();
    }
  };

  const validate = e => {
    const event = e || window.event;
    let key = '';
    // Handle paste
    if (event.type === 'paste') {
      key = navigator.clipboardData.read();
    } else {
      // Handle key press
      key = event.keyCode || event.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < digits; i++) {
      inputs.push(
        <input
          name={i}
          key={i}
          onChange={handleChange}
          onKeyPress={validate}
          ref={el => (itemsRef.current[i] = el)}
          maxLength="1"
          type="text"
        />
      );
    }
    return inputs;
  };

  return (
    <div className="verification-inputs-wrapper">
      {renderInputs()}
      {hasError ? <span className="verification-input--error">{'Please enter a valid 6-digit code'}</span> : null}
    </div>
  );
};

export default VerificationInput;
