import './Reports.scss';
import Reports from './Reports';
import { getAssets } from '../../redux/actions/transactions';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  assets: state.transactions.assets,
  assetsPairs: state.transactions.assetsPairs.data,
  userData: state.login.userData
});

const mapDispatchToProps = {
  getAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
