import administrationReducer from './administration';
import analyticsReducer from './analytics';
import { combineReducers } from 'redux';
import loginReducer from './login';
import settingsReducer from './settings';
import tradeReducer from './trade';
import transactionsReducer from './transactions';
import websocketReducer from './websocket';
import balanceReducer from './balance';
import transactionsExternal from './transactionsExternal';

const appReducer = combineReducers({
  login: loginReducer,
  settings: settingsReducer,
  trade: tradeReducer,
  transactions: transactionsReducer,
  websocket: websocketReducer,
  administration: administrationReducer,
  analytics: analyticsReducer,
  externalTransactions: transactionsExternal,
  balance: balanceReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_REQUEST' || action.type === 'CLEAR_STATE') {
    state?.websocket?.websocket?.close(); // make websocket connection is closed before state cleanup
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
