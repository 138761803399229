export const getContactTypeDisplayName = contact => {
  if ((contact.accountType ?? contact.type).toLowerCase() === 'corporate') {
    //only for ui purposes institutional will be displayed instead corporate;
    // behind the scenes corporate value is going to be used
    return 'Institutional';
  }
  if ((contact.accountType ?? contact.type).toLowerCase() === 'individual') {
    return 'Individual';
  }

  return '';
};
