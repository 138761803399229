import './PaymentsTransactionsContainer.scss';

import {
  getAssets,
  getDepositAccounts,
  getAccountTransactions,
  getAccountEventsAll
} from '../../redux/actions/transactions';
import {
  fetchExternalPendingCryptoTrasactions,
  fetchStakingRequest,
  fetchStakingRewardsRequest,
  fetchExternalUSDTransactions
} from '../../redux/actions/transactionsExternal';
import PaymentsTransactionsContainer from './PaymentsTransactionsContainer';
import { connect } from 'react-redux';
import { fetchAssetPairs } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  events: state.transactions.events,
  accounts: state.transactions.accounts,
  assets: state.transactions.assets,
  stakingTransactions: state.externalTransactions.staking,
  rewardTransactions: state.externalTransactions.stakingRewards,
  transactionsCTS: state.externalTransactions.externalTransactionsPending,
  transactionsUSD: state.externalTransactions.externalTransactionsUsd,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getAccountTransactions,
  getAccountEventsAll,
  getDepositAccounts,
  getAssets,
  fetchExternalPendingCryptoTrasactions,
  fetchStakingRequest,
  fetchStakingRewardsRequest,
  fetchExternalUSDTransactions,
  fetchAssetPairs
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTransactionsContainer);
