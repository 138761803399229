import { Button, ButtonGroup, ButtonToolbar, Col, Container, Row, Table } from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { formatDate } from '../../utils/generalUtils';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getRoleDisplayName } from '../../utils/generalUtils';
import { hasRole } from '../../utils/roleCheckers';
import './UserDetails.scss';
import { ROLES } from '../../enums/roles';
import { getFullName } from '../../utils/user';
import UserAccounts from '../AccountUser/UserAccounts';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';

const TABS = {
  DETAILS: 'DETAILS',
  ACCOUNTS: 'ACCOUNTS'
};

class AccountDetails extends PureComponent {
  state = { currentScreen: TABS.DETAILS };

  componentDidMount() {
    this.fetchUserDetails();
  }
  fetchUserDetails = () => {
    const payload = {
      userId: encodeURI(this.props.match.params.userId)
    };
    if (hasRole(this.props.me.data, ROLES.SUPER_USER)) {
      this.props.getUserDetailsRequest(payload);
    } else {
      this.props.getFirmUserDetailsRequest(payload);
    }
  };

  resolveUserDetails = () => {
    const data = hasRole(this.props.me.data, ROLES.SUPER_USER) ? this.props.userDetails : this.props.firmUserDetails;

    return data ?? {};
  };

  handleReturn = () => {
    this.props.history.goBack();
  };

  changeStatusScreen = screen => {
    this.setState({ currentScreen: screen });
  };

  renderAccountUser = () => {
    const { data, loaded, loading } = this.resolveUserDetails();
    if (!loaded || loading) return null;
    const user = data.details;

    return (
      <div className="user-table">
        <Container fluid className="user-details-container">
          <Row>
            <Col>
              <Table className="body" borderless responsive>
                <tbody>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Name</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">{getFullName(user)}</label>
                    </td>
                  </tr>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Email</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">{user.userid}</label>
                    </td>
                  </tr>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Role</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">{getRoleDisplayName(user.roles) || '-'}</label>
                    </td>
                  </tr>
                  {/* <tr>
                    <th className="td-main">
                      <div className="td-main">Type</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">
                        {decodeRole(user.roles)?.product || '-'}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Kind</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">
                        {user.attr?.address.kind || '-'}
                      </label>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>

            <Col>
              <Table className="body" borderless responsive>
                <tbody>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Firm</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">{user.firm || '-'}</label>
                    </td>
                  </tr>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Created date</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">
                        {user.attr?.date_created ? formatDate(user.attr.date_created) : '-'}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <th className="td-main">
                      <div className="td-main">Last login</div>
                    </th>
                    <td className="td-content">
                      <label className="td-content">{user.lastLogin ? formatDate(user.lastLogin) : '-'}</label>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  renderBody = () => {
    const { data } = this.resolveUserDetails();

    switch (this.state.currentScreen) {
      case TABS.DETAILS:
        return (
          <Card internalSpacing="0" borderless>
            {this.renderAccountUser()}
          </Card>
        );

      case TABS.ACCOUNTS:
        return (
          <UserAccounts
            user={data?.details}
            accounts={data?.accounts || []}
            loading={false}
            firm={data?.details?.firm}
            onChange={() => {
              this.fetchUserDetails();
            }}
          />
        );

      default:
        return <></>;
    }
  };

  render() {
    const { data, loading } = this.resolveUserDetails();
    const user = data?.details;

    return (
      <React.Fragment>
        <Row noGutters onClick={this.handleReturn} className="return-button">
          <MdKeyboardBackspace />
          {'Return'}
        </Row>
        <div className="container-user-details">
          <div className="firm-details chassis-interior-content">
            <div className="UserDetails-row-buttons">
              <PageTitle text={`User: ${user ? getFullName(user) : '-'}`} styleClass="user-details-title" />
              <div className="user-details-tabs">
                <ButtonToolbar className="button-container">
                  <ButtonGroup className="button-group">
                    <Button
                      className="item-button-small"
                      active={this.state.currentScreen === TABS.DETAILS}
                      type={'button btn btn-primary'}
                      onClick={() => this.changeStatusScreen(TABS.DETAILS)}
                    >
                      Details
                    </Button>
                    <Button
                      className="item-button-small"
                      active={this.state.currentScreen === TABS.ACCOUNTS}
                      onClick={() => this.changeStatusScreen(TABS.ACCOUNTS)}
                    >
                      Accounts
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            {this.renderBody()}
            {loading && <LoadingIndicator />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountDetails;
