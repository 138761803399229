import { HttpMethod } from './Request';
import { AxiosUsdRequest, AxiosBackendRequest, AxiosCtsRequest } from './AxiosRequest';

export class RequestBuilderType {
  static Backend = new RequestBuilderType(() => new AxiosBackendRequest());
  static Cts = new RequestBuilderType(() => new AxiosCtsRequest());
  static Usd = new RequestBuilderType(() => new AxiosUsdRequest());

  constructor(factory) {
    this._factory = factory;
  }

  get factory() {
    return this._factory;
  }
}

/**
 * Generic builder for request parameters to be used in Redux Saga's `call` method.
 */
export default class RequestBuilder {
  constructor(type) {
    this._instance = type.factory();
  }

  /**
   * Factory method for creating a new RequestBuilder instance tied to
   * a provided client. Client is a method responsible for making the API
   * request.
   *
   * @param type {RequestBuilderType} - HTTP client type
   * @returns {RequestBuilder} - new RequestBuilder instance
   */
  static type(type) {
    return new RequestBuilder(type);
  }

  /**
   * Factory method that builds the Axios Cts type request.
   *
   * @return {RequestBuilder} - new Axios RequestBuilder instance
   */
  static cts() {
    return new RequestBuilder(RequestBuilderType.Cts);
  }

  /**
   * Factory method that builds the Axios Usd type request.
   *
   * @return {RequestBuilder} - new Axios RequestBuilder instance
   */
  static usd() {
    return new RequestBuilder(RequestBuilderType.Usd);
  }

  /**
   * Default factory method.
   *
   * @returns {RequestBuilder} - new default AxiosRequest instance
   */
  static default() {
    return new RequestBuilder(RequestBuilderType.Backend);
  }

  /**
   * Sets the client method to use for making the request.
   *
   * @param client {function} - HTTP client method
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  client(client) {
    this._instance._fn = client;
    return this;
  }

  /**
   * Sets the HTTP method to use for the request.
   *
   * @param method {HttpMethod} - HTTP method
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  method(method) {
    this._instance._method = method;
    return this;
  }

  /**
   * Builder method for configuring GET request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  get(url) {
    let builder = this.method(HttpMethod.GET);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Builder method for configuring POST request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  post(url) {
    let builder = this.method(HttpMethod.POST);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Builder method for configuring PUT request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  put(url) {
    let builder = this.method(HttpMethod.PUT);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Builder method for configuring DELETE request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  delete(url) {
    let builder = this.method(HttpMethod.DELETE);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Builder method for configuring PATCH request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  patch(url) {
    let builder = this.method(HttpMethod.PATCH);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Builder method for configuring HEAD request with optional url path.
   *
   * @param url {string?} - optional url path
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  head(url) {
    let builder = this.method(HttpMethod.HEAD);
    if (url) {
      builder = builder.url(url);
    }
    return builder;
  }

  /**
   * Sets the URL to use for the request.
   *
   * @param url {string} - URL to use for the request. Relative or absolute.
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  url(url) {
    this._instance._url = url;
    return this;
  }

  /**
   * Request body. Typically, JSON data.
   *
   * @param data {object} - request body
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  data(data) {
    this._instance._data = data;
    return this;
  }

  /**
   * Single urlParam to be added to the request url. Duplicated keys are added
   * to the url as an array as per spec.
   *
   * @param key {string} - urlParam key
   * @param value {string} - urlParam value
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  urlParam(key, value) {
    if (this._instance._urlParams[key] !== undefined) {
      if (Array.isArray(this._instance._urlParams[key])) {
        this._instance._urlParams[key].push(value);
      } else {
        this._instance._urlParams[key] = [this._instance._urlParams[key], value];
      }
    } else {
      this._instance._urlParams[key] = value;
    }
    return this;
  }

  /**
   * Sets the urlParams object to be used in the request.
   *
   * @param data
   * @returns {RequestBuilder}
   * @see urlParam
   */
  urlParams(data) {
    this._instance._urlParams = data || {};
    return this;
  }

  /**
   * Sets the data of the request as a FormData object. FormData objects are
   * used for multipart/form-data requests.
   *
   * @param data {object} - request body
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  formData(data) {
    this._instance._data = Object.keys(data).reduce((acc, key) => {
      const value = data[key];
      if (value !== undefined) {
        acc.append(key, value);
      }
      return acc;
    }, new FormData());
    return this.header('Content-Type', 'multipart/form-data');
  }

  /**
   * Sets the headers to be used in the request.
   *
   * @param headers {{[string]: string?}} - request headers
   * @returns {RequestBuilder}
   */
  headers(headers) {
    this._instance._headers = { ...this._instance.headers, ...headers };
    return this;
  }

  /**
   * Sets the header to be used in the request.
   *
   * @param header {string} - header name
   * @param value {string} - header value
   * @returns {RequestBuilder} - this RequestBuilder instance
   */
  header(header, value) {
    this._instance._headers[header] = value;
    return this;
  }

  /**
   * Builds the request params into an array to be used in Redux Saga's `call` method.
   * Parameters are ordered as follows:
   * 0. client method
   * 1. http method
   * 2. url
   * 3. body
   * 4. headers
   *
   * @returns {Request}
   */
  build() {
    return this._instance;
  }
}
