import { Row } from 'react-bootstrap';
import React from 'react';
import i18next from 'i18next';
import { renderInput, renderSelect } from '../helpers';
import { TiDelete } from 'react-icons/ti';
import { useCreateAccountContext, WALLETS_LIST_NAME, HAS_WALLETS_VALUES } from '../context';
import { SECURITY_CODE_NAME_MAP } from '../../../../enums/validation';
import VLButton from '../../../Buttons/VLButton';
import { IoMdAdd } from 'react-icons/io';

const EXCLUDE_ASSETS = [SECURITY_CODE_NAME_MAP.USD, SECURITY_CODE_NAME_MAP.VLC$];

/**
 * sortAssetOptions - sorts alphabetically by the option label
 *
 * @param {*} a - option 1
 * @param {*} b  - option 2
 * @returns
 */
const sortAssetOptions = (a, b) => {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  return labelA.localeCompare(labelB);
};

const CRYPTO_ASSET_OPTIONS = Object.keys(SECURITY_CODE_NAME_MAP)
  .filter(key => !EXCLUDE_ASSETS.includes(SECURITY_CODE_NAME_MAP[key]))
  .map(key => ({
    value: key,
    label: `${key} - ${SECURITY_CODE_NAME_MAP[key]}`
  }))
  .sort(sortAssetOptions);

const HAS_WALLETS_OPTIONS = [
  { value: HAS_WALLETS_VALUES.YES, label: 'Yes' },
  { value: HAS_WALLETS_VALUES.NO, label: 'No' }
];

const AccountWalletsStep = ({ onNext, confirmButtonText }) => {
  const { formState, actions } = useCreateAccountContext();
  const { wallets, hasWalletsOption } = formState;
  const { addListItem, removeListItem, updateListItemError, updateListItemValue, clearListItemErrors, setHasWallets } =
    actions;

  const handleOnChange = ({ target: { name, value } }) => {
    const [index, field] = name.split('-');
    updateListItemValue(WALLETS_LIST_NAME, +index, field, value);
  };

  const handleHasWalletsSelectChange = ({ value }) => {
    setHasWallets(value);
  };

  const handleOnDropdownChange = ({ value }, { name }) => {
    const [index, field] = name.split('-');
    updateListItemValue(WALLETS_LIST_NAME, +index, field, value);
  };

  const handleAddWallet = () => {
    addListItem(WALLETS_LIST_NAME);
  };

  const handleRemoveWallet = index => {
    removeListItem(WALLETS_LIST_NAME, index);
  };

  const handleComplete = () => {
    clearListItemErrors(WALLETS_LIST_NAME);

    if (!validateForm()) return;

    onNext();
  };

  const validateForm = () => {
    if (!hasWalletsOption) return true;

    let isValid = true;

    wallets.forEach(({ cryptoAsset, address, nickname }, index) => {
      if (!cryptoAsset) {
        updateListItemError(WALLETS_LIST_NAME, index, 'cryptoAsset', 'Required');
        isValid = false;
      }
      if (!address) {
        updateListItemError(WALLETS_LIST_NAME, index, 'address', 'Required');
        isValid = false;
      }
      if (!nickname) {
        updateListItemError(WALLETS_LIST_NAME, index, 'nickname', 'Required');
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <div className="add-wallets-step">
      {renderSelect({
        name: 'hasWallets',
        label: 'Do you have any non-exchange wallets (e.g. MetaMask, Ledger, Exodus, Trezor, Multi-sig)?',
        value: HAS_WALLETS_OPTIONS.find(o => o.value === hasWalletsOption),
        onChange: handleHasWalletsSelectChange,
        options: HAS_WALLETS_OPTIONS,
        isRequired: true
      })}

      {hasWalletsOption === HAS_WALLETS_VALUES.YES ? (
        <>
          {wallets.map((w, index) => (
            <WalletForm
              key={index}
              wallet={w}
              index={index}
              onChange={handleOnChange}
              onSelect={handleOnDropdownChange}
              onRemove={handleRemoveWallet}
            />
          ))}
          <Row noGutters className="account-wallets-step-add-button-row">
            <VLButton
              text={'Another wallet'}
              onClick={handleAddWallet}
              size={'l'}
              leftIcon={<IoMdAdd />}
            />
          </Row>
        </>
      ) : null}

      <Row noGutters className="summary-row">
        <p className="mandatory-text">
          {i18next.t('createAccountModal.fields')}
          <span className="mandatory-star">*</span>
          {i18next.t('createAccountModal.mandatory')}
        </p>
      </Row>
      <div className="external-deposit-footer-bar">
        <VLButton disabled={!hasWalletsOption} onClick={handleComplete} width="100%" text={confirmButtonText} />
      </div>
    </div>
  );
};

const WalletForm = ({ wallet: { cryptoAsset, address, nickname, errors }, index, onRemove, onChange, onSelect }) => {
  return (
    <div className="account-wallets-step-wallet-form" key={index}>
      <span className="account-wallets-step-wallet-form-title">{`Wallet ${index + 1}`}</span>
      {index !== 0 ? (
        <TiDelete
          onClick={() => onRemove(index)}
          className="hourglass common-icon account-wallets-step-delete"
          size="1.5em"
        />
      ) : null}
      {renderSelect({
        name: `${index}-cryptoAsset`,
        label: 'Crypto-Asset?',
        value: CRYPTO_ASSET_OPTIONS.find(f => f === cryptoAsset),
        onChange: onSelect,
        options: CRYPTO_ASSET_OPTIONS,
        isRequired: true,
        error: errors.cryptoAsset
      })}
      {renderInput({
        name: `${index}-address`,
        label: 'Blockchain address',
        value: address,
        onChange: onChange,
        isRequired: true,
        error: errors.address
      })}
      {renderInput({
        name: `${index}-nickname`,
        label: 'Nickname',
        value: nickname,
        onChange: onChange,
        isRequired: true,
        error: errors.nickname
      })}
    </div>
  );
};

export default AccountWalletsStep;
