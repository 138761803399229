import { Row, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import i18next from 'i18next';
import { mapValuesListToOptions, renderSelect } from '../helpers';
import './AcknowledgementStep.scss';
import VLButton from '../../../Buttons/VLButton';

const DISCLAIMER_TEXT =
  'I affirm that all information submitted in response to this Application, including information in any attachments or exhibits, is complete and accurate. I understand that [bank name] will rely on the above information in determining whether to enter into any contractual agreement or other arrangement, and that any false or misleading information provided would be grounds for the immediate termination of any such contractual agreement or other arrangement. I authorize [bank name] and its employees or contractors to conduct due diligence, to the extent legally permissible and as [bank name] deems appropriate. I understand that [bank name] will undertake reasonable efforts to ensure any information it gathers is kept confidential. I expressly consent to the transfer of information provided in response to this Application, including any personally identifiable information, and further agree to forever release, discharge, and hold harmless [bank name], its agents, directors, employees, subsidiaries, affiliates, and representatives from any claims, damages, losses, liabilities, costs, and expenses (including any attorney and/or legal fees) or any other charge or complaint filed, arising from any legally appropriate due diligence efforts undertaken and the use of any information gathered during such efforts. I represent and acknowledge that I have the authority to sign this Application on my or my company’s behalf.';

const AGREEMENT_SELECT = mapValuesListToOptions(['I agree']);

const AcknowledgmentStep = ({ onNext, confirmButtonText }) => {
  const [confirmation, setConfirmation] = useState(null);

  const handleOnDropdownChange = option => {
    setConfirmation(option);
  };

  return (
    <div>
      <div className="acknowledgment-step-disclaimer">{DISCLAIMER_TEXT}</div>
      {renderSelect({
        name: 'agreement',
        label: 'I hereby certify that, to the best of my knowledge, the provided information is true and accurate',
        value: confirmation,
        onChange: handleOnDropdownChange,
        options: AGREEMENT_SELECT,
        isRequired: true
      })}

      <Row noGutters className="summary-row">
        <p className="mandatory-text">
          {i18next.t('createAccountModal.fields')}
          <span className="mandatory-star">*</span>
          {i18next.t('createAccountModal.mandatory')}
        </p>
      </Row>
      <div className="external-deposit-footer-bar">
        <VLButton
          disabled={!confirmation}
          onClick={onNext}
          width="100%"
          text={confirmButtonText}
        />
        {false ? (
          <Spinner
            animation="border"
            variant="secondary"
            className="cases-graph-spinner"
          />
        ) : null}
      </div>
    </div>
  );
};

export default AcknowledgmentStep;
