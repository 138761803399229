import TradeOrder from './TradeOrder';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { setSelectedIoi, setSelectedLmt } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  userData: state.login.userData.data,
  accounts: state.transactions.accounts,
  positions: state.websocket.positions,
  selectedPair: state.trade.selectedPair,
  selectedIOI: state.trade.selectedIOI,
  selectedLMT: state.trade.selectedLMT,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = { setSelectedIoi, setSelectedLmt };

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(TradeOrder);
