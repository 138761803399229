import React, { PureComponent } from 'react';

class PeriodicalPriceUpdateComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.interval = null;
  }
  componentDidMount() {
    this.props.getAllPrices();

    if (!process.env.REACT_APP_PRICE_INTERVAL_UPDATE) {
      console.log('REACT_APP_PRICE_INTERVAL_UPDATE is not set');
      return;
    }
    this.interval = setInterval(() => {
      this.props.getAllPrices();
    }, process.env.REACT_APP_PRICE_INTERVAL_UPDATE);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <></>;
  }
}

export default PeriodicalPriceUpdateComponent;
