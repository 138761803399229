import {
  getAssets,
  getDepositAccounts,
  getRecipientFirms,
  resetFields,
  searchRecipientAccounts,
  toggleModal,
  updateTransactionsDB
} from '../../redux/actions/transactions';
import { addStakingRequest, exitStakingRequest } from '../../redux/actions/transactionsExternal';
import { makeOrderRequest, makeTransferRequest } from '../../redux/actions/websocket';

import { connect } from 'react-redux';
import StakeAndExitStake from './StakeAndExitStake';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  assets: state.transactions.assets,
  firms: state.transactions.recipientFirms,
  firmAccounts: state.transactions.firmAccounts,
  websocket: state.websocket.websocket,
  positions: state.websocket.positions,
  updatingTransactionDb: state.transactions.updatingTransactionDb,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getDepositAccounts,
  getAssets,
  getRecipientFirms,
  searchRecipientAccounts,
  resetFields,
  makeTransferRequest,
  makeOrderRequest,
  toggleModal,
  updateTransactionsDB,
  addStakingRequest,
  exitStakingRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeAndExitStake);
