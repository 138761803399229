export const letsGetStartedOptions = [
  {
    label: 'Account compromised',
    value: 'Account Compromised',
    subOptions: [
      {
        label: 'My account is compromised or has been hacked',
        value: 'My account is compromised or has been hacked'
      },
      {
        label: 'My device was lost or stolen',
        value: 'My device was lost or stolen'
      },
      {
        label: 'Unauthorized account creation',
        value: 'Unauthorized account creation'
      }
    ]
  },
  {
    label: 'Managing my account',
    value: 'Managing my Account',
    subOptions: [
      {
        label: 'Account information and identity verification',
        value: 'Account information and identity verification'
      },
      { label: 'Closing my account', value: 'Closing my account' },
      { label: 'Data privacy', value: 'Data privacy' },
      {
        label: 'I am unable to use my account',
        value: 'I am unable to use my account'
      },
      { label: 'Linking a payment method', value: 'Linking a payment method' },
      { label: 'Recovering my account', value: 'Recovering my account' },
      {
        label: 'The VaultLink user agreement',
        value: 'The VaultLink user agreement'
      }
    ]
  },
  {
    label: 'Taxes, reports & financial services',
    value: 'Taxes, Reports & Financial Services',
    subOptions: [
      {
        label: 'Account statement or report',
        value: 'Account statement or report'
      },
      { label: 'Performance data', value: 'Performance data' },
      { label: 'Rewards and staking', value: 'Rewards and staking' },
      { label: 'Tax documents', value: 'Tax documents' }
    ]
  },
  {
    label: 'Trading & funding',
    value: 'Trading & Funding',
    subOptions: [
      { label: 'Advanced trading', value: 'Advanced trading' },
      {
        label: 'Buying, selling, or converting crypto',
        value: 'Buying, selling, or converting crypto'
      },
      {
        label: 'Depositing or withdrawing fiat money',
        value: 'Depositing or withdrawing fiat money'
      },
      {
        label: 'Sending or receiving crypto',
        value: 'Sending or receiving crypto'
      }
    ]
  },
  {
    label: 'Other issues',
    value: 'Other Issues',
    subOptions: [
      { label: 'Fees and Limits', value: 'Fees and Limits' },
      {
        label: "I'm requesting access for a deceased account holder",
        value: "I'm requesting access for a deceased account holder"
      },
      { label: 'None of the above', value: 'None of the above' }
    ]
  }
];
