import React, { PureComponent } from 'react';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { Row, Col, Table, Form } from 'react-bootstrap';
import i18next from 'i18next';
import Pagination from '../../components/Pagination/Pagination';
import LoadingIndicator from '../../components/LoadingIndicator';
import CreateAccountModal from '../../components/modals/CreateAccountModal';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/modals/ConfirmationModal/ConfirmationModal';
import debounce from 'lodash.debounce';
import CssFilterTransition from '../../components/CssFilterTransition';
import { getAccountTypeDisplayName } from '../../utils/account';
import { decodeRole } from '../../utils/roleCheckers';
import { ACCOUNT_TYPES, PRODUCT_TYPES, ROLES } from '../../enums/roles';
import { calculateTotalPages } from '../../utils/paging';
import PageTitle from '../../components/PageTitle';
import Card from '../Card';
import './Accounts.scss';
import VLButton from '../../components/Buttons/VLButton';
import { BsPlus } from 'react-icons/bs';
import VLIconButton from '../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import VLInput from '../../components/VLInput';

const ACCOUNT_ROW_LIMIT = 8;

class Accounts extends PureComponent {
  state = {
    accountsPageNumber: 0,
    showCreateAccountModal: false
  };

  rowLimit = this.props.rowSize || ACCOUNT_ROW_LIMIT;

  componentDidMount() {
    this.fetchAccounts();
  }

  componentDidUpdate(prevProps) {
    this.rowLimit = this.props.pageSize || ACCOUNT_ROW_LIMIT;

    if (prevProps.accounts.data?.data?.length !== this.props.accounts.data?.data?.length) {
      const newTotalPages = calculateTotalPages(this.props.accounts.data?.data, this.rowLimit);

      // Make sure current page in range after filtering
      if (this.state.accountsPageNumber >= newTotalPages) {
        this.setState({ accountsPageNumber: 0 });
      }
    }
  }

  canCreateAccount = () => {
    const role = decodeRole(this.props.user.roles).role;
    if (role === ROLES.ADMIN) return true;
    if (role === ROLES.SUPER_USER && this.props.canEditAccounts) return !!this.props.firm;

    return false;
  };

  inferProductType = () => {
    const { data } = this.props.accounts;
    const accounts = data.data;
    if (!accounts?.length) return null;

    const individualAccount = accounts.find(a => a.attr?.type === ACCOUNT_TYPES.INDIVIDUAL);
    if (individualAccount) return null; // Not possible to infer product for firm that has Individual accounts

    const accountWithProduct = accounts.find(a => a.attr?.product);

    if (!accountWithProduct) return null;
    if (accountWithProduct.attr.product !== PRODUCT_TYPES.PCA && accountWithProduct.attr.product !== PRODUCT_TYPES.SCA)
      return null;

    return accountWithProduct.attr.product;
  };

  fetchAccounts = (payload = {}) => {
    const role = decodeRole(this.props.user.roles).role;
    let firm = this.props.firm;

    if (!firm) {
      // Firm admins will see only account from their firm, and superadmins will see all
      firm = role === ROLES.SUPER_USER ? undefined : this.props.user.firm;
    }

    this.props.getFirmAdminAccountsRequest({
      ...payload,
      firm
    });
  };

  handlePageClick = (data, field) => {
    this.setState({
      [`${field}PageNumber`]: data.selected
    });
  };

  redirectToAccountDetails = (account, tab = null) => {
    const newEntry = {
      pathname: `/accounts/${account.account}`,
      search: tab ? 'tab=RISK' : undefined
    };

    this.props.history.push(newEntry);
  };

  toggleModal = fieldName => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`]
    });
  };

  resetConfirmation = () => {
    this.setState({
      confirmationOnSuccessFunk: () => undefined,
      confirmationType: 'firm',
      showConfirmationModal: false,
      objectToDelete: null
    });
  };

  handleDeleteAccount = () => {
    this.props.deleteAccountRequest({
      account: this.state.objectToDelete.account,
      successCallback: this.getFilteredAccounts,
      errorCallback: () => {
        toast.error('Error deleting account');
      }
    });
    this.resetConfirmation();
  };

  updateAccountActive = account => {
    const newActiveStatus = account.active === 'Y' ? null : 'Y';
    const data = {
      activeAccount: newActiveStatus,
      washCheck: account.washcheck,
      duplicateOrder: account.DupOrd,
      maxDailyTransaction: account.totalallowamount,
      maxPerTransaction: account.maxdollaramount
    };
    this.updateRiskSettings(data, account);
  };

  toggleConfirmationModal = (data, type) => {
    this.setState({
      showConfirmationModal: true,
      confirmationType: type,
      objectToDelete: data,
      confirmationOnSuccessFunk: this.handleDeleteAccount
    });
  };

  handleAccountCreated = () => {
    this.setState({
      showCreateAccountModal: false
    });
    this.getFilteredAccounts();
  };

  renderHeaderBar = () => {
    return (
      <Row className="user-control-container transactions-filters-container row no-gutters">
        <PageTitle text="Accounts" styleClass="dashboard-transactions-header" />

        <div className="inline-justifier">
          <VLIconButton
            Icon={FaFilter}
            onClick={() => {
              this.setState(s => ({
                displayFilters: !s.displayFilters
              }));
            }}
          />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>
      </Row>
    );
  };

  clearFilters = () => {
    this.setState(
      {
        nameSearch: '',
        numberSearch: '',
        firmSearch: ''
      },
      () => {
        this.handleSearchDebounced();
      }
    );
  };

  getFilteredAccounts = () => {
    const payload = {};
    if (this.state.nameSearch) {
      payload.nameSearch = this.state.nameSearch;
    }
    if (this.state.numberSearch) {
      payload.numberSearch = this.state.numberSearch;
    }
    if (this.state.firmSearch) {
      payload.firmSearch = this.state.firmSearch;
    }

    this.fetchAccounts(payload);
  };

  handleSearchDebounced = debounce(this.getFilteredAccounts, 500);

  handleSearchFilter = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.handleSearchDebounced();
  };

  getAccountDetails = account => {
    this.props.getAccountDetailsRequest({
      account: account.account,
      successCallback: () => {
        this.redirectToAccountDetails(account, 'risk');
      }
    });
  };

  renderFilters = () => {
    return (
      <div>
        <div className="administration-filter">
          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Pseudo account number</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                name="nameSearch"
                placeholder="Search"
                value={this.state.nameSearch}
                onChange={this.handleSearchFilter}
                size="s"
                isSearch
              />
            </div>
          </div>

          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Number</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                name="numberSearch"
                placeholder="Search"
                value={this.state.numberSearch}
                onChange={this.handleSearchFilter}
                size="s"
                isSearch
              />
            </div>
          </div>

          {!this.props.firm ? (
            <div className={`user-form-control-container`}>
              <Form.Label className="search-user-form-label">Firm</Form.Label>
              <div className="user-form-control-row">
                <VLInput
                  name="firmSearch"
                  placeholder="Search"
                  value={this.state.firmSearch}
                  onChange={this.handleSearchFilter}
                  size="s"
                  isSearch
                />
              </div>
            </div>
          ) : null}
        </div>
        <VLButton
          text={i18next.t('transactionsFilters.clearFilter')}
          variant="clear"
          size="m"
          rightIcon={<BsPlus />}
          onClick={this.clearFilters}
        />
      </div>
    );
  };

  renderFirmAccount = account => {
    const numberOfUsers = account.restricted_attr?.access_list?.length || 0;
    const type = getAccountTypeDisplayName(account);

    return (
      <tr key={account.account}>
        <td>
          <div className="align-left">{account.attr.name}</div>
        </td>
        <td>
          <div className="align-left">{account.account}</div>
        </td>
        <td>
          <div className="align-left">{account.firm}</div>
        </td>
        <td>
          <div className="align-left">{type || '-'}</div>
        </td>
        <td>
          <div>{numberOfUsers}</div>
        </td>
        <td>
          <div className="center-aligned-complex">
            <Form.Switch
              className="table-switch"
              label=""
              checked={account.active === 'Y'}
              onChange={() => this.updateAccountActive(account)}
              id={`${account.account}`}
            />
          </div>
        </td>

        <td>
          <div className="align-center">
            <VLButton
              size={'m'}
              onClick={() => this.getAccountDetails(account)}
              text={i18next.t('firmDetails.setRisks')}
            />
          </div>
        </td>
        <td>
          <div className="align-center">
            <VLIconButton
              Icon={MdRemoveRedEye}
              variant="borderless"
              onClick={() => this.redirectToAccountDetails(account)}
            />
          </div>
        </td>
        <td>
          <div className="align-center">
            {this.props.canEditAccounts && (
              <VLIconButton
                Icon={MdDelete}
                variant="borderless"
                onClick={() => this.toggleConfirmationModal(account, 'account')}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  render = () => {
    const { data, loading } = this.props.accounts;
    const { loading: accountDetailsLoading } = this.props.accountDetails;
    const startIndex = this.state.accountsPageNumber * this.rowLimit;
    const accountList = (data?.data || []).slice(startIndex, startIndex + this.rowLimit);

    return (
      <Card>
        <Row noGutters className="firm-item">
          <Col>
            {this.renderHeaderBar()}
            <CssFilterTransition component={this.renderFilters} isOpen={this.state.displayFilters} />
            <div>
              {loading || accountDetailsLoading ? (
                <div className="accounts-loading">
                  <LoadingIndicator />
                </div>
              ) : null}
              {!loading && !accountDetailsLoading && accountList.length ? (
                <Table className="vl-table" borderless responsive>
                  <thead>
                    <tr>
                      <th className="align-left">{i18next.t('firmDetails.accountName')}</th>
                      <th className="align-left">{i18next.t('firmDetails.accountNumber')}</th>
                      <th className="align-left">{i18next.t('firmDetails.firm')}</th>
                      <th className="align-left">{i18next.t('firmDetails.accountType')}</th>
                      <th className="center-aligned">{i18next.t('firmDetails.nous')}</th>
                      <th className="center-aligned">{i18next.t('firmDetails.active')}</th>
                      <th className="center-aligned">{i18next.t('firmDetails.risk')}</th>
                      <th className="center-aligned">{i18next.t('firmDetails.details')}</th>
                      {this.props.canEditAccounts && <th className="center-aligned">Delete</th>}
                    </tr>
                  </thead>
                  <tbody>{accountList.map(account => this.renderFirmAccount(account))}</tbody>
                </Table>
              ) : null}

              {!loading && !accountList.length ? (
                <Row noGutters className="centered-row">
                  <span className="accounts-table-empty-text">There are no accounts that match the criteria</span>
                </Row>
              ) : null}
            </div>
            <div className="pagination">
              <Pagination
                totalPages={Math.ceil(this.props.accounts.data?.data?.length / this.rowLimit)}
                handlePageClick={data => this.handlePageClick(data, 'accounts')}
                dataPageNumber={this.state.accountsPageNumber}
              />
            </div>
            {this.canCreateAccount() ? (
              <Row noGutters className="centered-row">
                <VLButton
                  onClick={() => this.toggleModal('CreateAccount')}
                  className="bordered-button firm-item-btn"
                  text={i18next.t('firmDetails.createAccount')}
                  rightIcon={<IoMdAdd />}
                />
              </Row>
            ) : null}
          </Col>

          {this.state.showCreateAccountModal && (
            <CreateAccountModal
              firm={this.props.match.params.firmName || this.props.user?.firm}
              fixedProduct={this.inferProductType()}
              onClose={() => this.toggleModal('CreateAccount')}
              onSuccess={this.handleAccountCreated}
              checkingAccountName={this.state.checkingAccountName}
            />
          )}
          {this.state.showConfirmationModal && (
            <ConfirmationModal
              assetType={this.state.confirmationType}
              onSuccess={this.state.confirmationOnSuccessFunk}
              onClose={this.resetConfirmation}
            />
          )}
        </Row>
      </Card>
    );
  };
}
export default Accounts;
