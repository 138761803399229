import { Modal, Row } from 'react-bootstrap';
import { DEPOSIT_TYPE, ERROR, PENDING, SUCCESS } from '../../enums/validation';
import { MdKeyboardBackspace } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { getDecimalByAsset, isUSDAsset } from '../../utils/transactionsHelpers';

import {
  CurrencyStep,
  DepositAddressStep,
  PreviewDepositStep,
  TransactionHashStep
} from '../../components/externalTransactionComponents';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import RoundButton from '../../components/RoundButton';
import i18next from 'i18next';
import AmountStep from '../../components/externalTransactionComponents/AmountStep';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import { onlyNumbers } from '../../utils/validators';
import { replaceCommas } from '../../utils/generalUtils';

class ExternalDeposit extends PureComponent {
  static contextType = AccountFilterContext;

  state = {
    asset: '',
    maxDecimal: 0,
    amountObject: {
      value: '',
      display: '',
      error: false
    },
    currentScreen: 1,
    transactionType: DEPOSIT_TYPE,
    transactionStatus: null,
    transactionId: ''
  };

  componentDidMount() {
    this.props.getAssetsByProduct();
    if (!this.props.supportedAssets.loaded) {
      this.props.fetchExternalTransactionSupportedAssets();
    }
  }

  handleAmountChange = amountObject => {
    this.setState({ amountObject });
  };

  getAvailableAmountForWithdrawal = () => {
    const accountPositions = this.props.positions?.[this.props.account];
    if (!accountPositions) return 0;

    return accountPositions[this.state.asset] || 0;
  };

  handleSubmit = () => {
    const amount = onlyNumbers(replaceCommas(this.state.amountObject.value));
    const payload = {
      amount,
      account: this.props.currentAccountNumber
    };

    this.setState({ transactionStatus: PENDING });

    this.props.createExternalDepositUSDRequest({
      payload,
      successCallback: data => {
        this.setState({
          transactionStatus: SUCCESS,
          transactionId: data.id
        });
      },
      errorCallback: err => {
        this.setState({ transactionStatus: ERROR });
      }
    });
  };

  calculateSteps = () => {
    const steps = [];
    const assets =
      this.props.assets.data?.filter(asset => this.props.supportedAssets.data.includes(asset.security)) ?? [];

    steps.push({
      id: 1,
      title: 'Details',
      render: () => (
        <CurrencyStep
          asset={this.state.asset}
          type={this.state.transactionType}
          assets={{ data: assets }}
          handleSelect={this.handleSelect}
          onCompleted={() => this.switchScreen(2)}
          positions={this.state.positions}
          balanceError={this.state.balanceError}
        />
      )
    });
    if (isUSDAsset(this.state.asset)) {
      steps.push({
        id: 2,
        title: 'Amount',
        render: () => (
          <AmountStep
            valueObject={this.state.amountObject}
            maxDecimal={this.state.maxDecimal}
            onCompleted={() => {
              this.switchScreen(3);
            }}
            asset={this.state.asset}
            isLoading={this.state.queryWalletInProgress}
            availableAmount={this.getAvailableAmountForWithdrawal()}
            onChange={this.handleAmountChange}
            shouldValidateAmount={false}
            transactionType={this.state.transactionType}
          />
        )
      });
      steps.push({
        id: 3,
        title: 'Preview',
        render: () => (
          <PreviewDepositStep
            asset={this.state.asset}
            account={this.props.account}
            amount={this.state.amountObject.value}
            isLoading={this.state.queryWalletInProgress}
            onSubmit={this.handleSubmit}
            onClose={this.onCloseModal}
          />
        )
      });
    } else {
      steps.push({
        id: 2,
        title: 'Address',
        render: () => <DepositAddressStep asset={this.state.asset} onClose={this.onCloseModal} />
      });
    }

    const normalizedSteps = steps.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    return [steps, normalizedSteps];
  };

  onCloseModal = () => this.props.toggleModal(null);

  handleBackButton = () => {
    if (this.state.currentScreen !== 1) this.switchScreen(this.state.currentScreen - 1);
  };

  handleSelect = option => {
    const decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');
    this.setState({
      asset: option?.value || '',
      maxDecimal: decimal
    });
  };

  switchScreen = (stepNo, callback = () => null) => {
    this.setState({ currentScreen: stepNo }, () => callback());
  };

  renderBody = () => {
    const [, normalizedSteps] = this.calculateSteps();
    return normalizedSteps[this.state.currentScreen].render();
  };

  render() {
    const isLoading = !this.props.assets?.loaded || !this.props.supportedAssets?.loaded || this.state.transactionStatus;
    let trType = i18next.t('transactionType.deposit');

    const [steps] = this.calculateSteps();

    return (
      <Modal className="common-modal" centered show onHide={this.onCloseModal} backdrop="static">
        <Modal.Header closeButton>
          {this.state.currentScreen !== 1 && !this.state.transactionStatus ? (
            <MdKeyboardBackspace
              onClick={this.handleBackButton}
              className="external-deposit-back-button"
              size="2.5em"
            />
          ) : null}
          <Modal.Title>{`${trType} ${this.state.asset}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.transactionStatus ? (
            <div className="external-deposit-step-container">
              {this.state.transactionStatus ? (
                <TransactionHashStep
                  hashStatus={this.state.transactionStatus}
                  asset={this.state.asset}
                  amount={this.state.amountObject.value}
                  transactionId={this.state.transactionId}
                  account={this.props.account}
                  transactionType={DEPOSIT_TYPE}
                  specialRedirectFunction={this.onCloseModal}
                />
              ) : (
                this.renderBody()
              )}
            </div>
          ) : (
            <LoadingWrapper isLoading={isLoading}>
              <div className="deposit-container">
                <Row noGutters className="external-deposit-top-bar">
                  {steps.map(step => (
                    <RoundButton
                      isActive={step.id === this.state.currentScreen}
                      isDisabled={step.id > this.state.currentScreen - 1}
                      onClick={() => this.switchScreen(step.id)}
                      smaller
                      number={step.id}
                      size="sm"
                      text={step.title}
                    />
                  ))}
                </Row>
                <div className="external-deposit-step-container">{this.renderBody()}</div>
              </div>
            </LoadingWrapper>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExternalDeposit;
