/* eslint-disable import/no-anonymous-default-export */
import { getFirstAssetFromPair } from '../../utils/transactionsHelpers';
import * as ADMINISTRATION_CONSTANTS from '../constants/administration';
import * as CONSTANTS from '../constants/transactions';

const initialState = {
  assets: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  orderHistory: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  tradeHistory: {
    data: [],
    loading: false,
    loaded: false,
    from: 0,

    error: ''
  },
  assetsByProduct: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  assetsPairs: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  tradingAssets: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },

  accounts: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  canceledOrders: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  openOrders: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  miniListEvents: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  events: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  recipientFirms: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  firmAccounts: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  displayModal: null,
  cryptoRates: {
    data: {
      btc: 0,
      eth: 0
    },
    error: false,
    loaded: false,
    loading: false
  },
  graphPrices: {
    btc: {
      data: null,
      error: false,
      loaded: false,
      loading: false
    },
    eth: {
      data: null,
      error: false,
      loaded: false,
      loading: false
    }
  },
  lastCheckedTimestamp: new Date(),
  updatingTransactionDb: {
    hash: null,
    success: false,
    error: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ASSETS:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_ASSETS_ERROR:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    //////////////////////////////////////////////////////
    case CONSTANTS.FETCH_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_ORDER_HISTORY_ERROR:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    /////////////////////////////////////////////////////
    case CONSTANTS.FETCH_TRADE_HISTORY:
      return {
        ...state,
        tradeHistory: {
          ...state.tradeHistory,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        tradeHistory: {
          ...state.tradeHistory,
          loading: false,
          loaded: true,
          totalLength: action.data.total_rec,
          data: state.tradeHistory.data.concat(action.data.data),
          error: '',
          from: parseInt(state.tradeHistory.from) + parseInt(100)
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_TRADE_HISTORY_ERROR:
      return {
        ...state,
        tradeHistory: {
          ...state.tradeHistory,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    case CONSTANTS.CLEAN_TRADE_HISTORY: {
      return {
        ...state,
        tradeHistory: {
          data: [],
          loading: false,
          loaded: false,
          error: '',
          from: state.tradeHistory.from
        }
      };
    }

    /////////////////////////////////////////////////////
    case CONSTANTS.FETCH_ASSETS_BY_PRODUCT:
      return {
        ...state,
        assetsByProduct: {
          ...state.assetsByProduct,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_ASSETS_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        assetsByProduct: {
          ...state.assetsByProduct,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        },
        lastCheckedTimestamp: new Date()
      };

    case CONSTANTS.FETCH_ASSETS_BY_PRODUCT_ERROR:
      return {
        ...state,
        assetsByProduct: {
          ...state.assetsByProduct,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_ASSET_PAIRS:
      return {
        ...state,
        assetsPairs: {
          ...state.assetsPairs,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_ASSET_PAIRS_SUCCESS:
      return {
        ...state,
        assetsPairs: {
          ...state.assetsPairs,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.FETCH_ASSET_PAIRS_ERROR:
      return {
        ...state,
        assetsPairs: {
          ...state.assetsPairs,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    ////////////////////////////////////////////////////
    case CONSTANTS.FETCH_TRADING_ASSETS:
      return {
        ...state,
        tradingAssets: {
          ...state.tradingAssets,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.FETCH_TRADING_ASSETS_SUCCESS:
      return {
        ...state,
        tradingAssets: {
          ...state.tradingAssets,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.FETCH_TRADING_ASSETS_ERROR:
      return {
        ...state,
        tradingAssets: {
          ...state.tradingAssets,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    ///////////////////////////////////////////////////

    case ADMINISTRATION_CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS:
    case CONSTANTS.FETCH_DEPOSIT_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case ADMINISTRATION_CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS_SUCCESS:
    case CONSTANTS.FETCH_DEPOSIT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case ADMINISTRATION_CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS_ERROR:
    case CONSTANTS.FETCH_DEPOSIT_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL:
    case CONSTANTS.FETCH_ACCOUNT_EVENTS: {
      if (action.forMiniList) {
        return {
          ...state,
          miniListEvents: {
            ...state.miniListEvents,
            loading: true,
            loaded: false,
            error: ''
          }
        };
      } else {
        return {
          ...state,
          events: {
            ...state.events,
            loading: true,
            loaded: false,
            error: ''
          }
        };
      }
    }

    case CONSTANTS.FETCH_ACCOUNT_EVENTS_SUCCESS: {
      if (action.forMiniList) {
        return {
          ...state,
          miniListEvents: {
            data: [...action.data.data],
            loading: false,
            loaded: true,
            error: ''
          }
        };
      } else {
        return {
          ...state,
          events: {
            ...state.events,
            loading: false,
            loaded: true,
            data: [...action.data.data],
            error: ''
          }
        };
      }
    }

    case CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL_ERROR:
    case CONSTANTS.FETCH_ACCOUNT_EVENTS_ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          loaded: true,
          data: [...action.data],
          error: ''
        }
      };

    case CONSTANTS.CLEAR_ACCOUNT_EVENTS: {
      return {
        ...state,
        events: {
          data: []
        }
      };
    }

    case CONSTANTS.CLEAR_CANCELED_ORDERS: {
      return {
        ...state,
        canceledOrders: {
          data: []
        }
      };
    }

    case CONSTANTS.FETCH_FIRMS_SUCCESS:
      return {
        ...state,
        recipientFirms: {
          ...state.recipientFirms,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.FETCH_FIRMS_ERROR:
      return {
        ...state,
        recipientFirms: {
          ...state.recipientFirms,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM_SUCCESS:
      return {
        ...state,
        firmAccounts: {
          ...state.firmAccounts,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };

    case CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM_ERROR:
      return {
        ...state,
        firmAccounts: {
          ...state.firmAccounts,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.RESET_FIELDS:
      return {
        ...initialState
      };

    case CONSTANTS.UPDATE_TRANSACTION_DB:
      return {
        ...state,
        updatingTransactionDb: {
          hash: null,
          success: false,
          error: false
        }
      };

    case CONSTANTS.UPDATE_TRANSACTION_DB_SUCCESS:
      return {
        ...state,
        updatingTransactionDb: {
          hash: action.data.ref,
          success: true,
          error: false
        }
      };

    case CONSTANTS.UPDATE_TRANSACTION_DB_ERROR:
      return {
        ...state,
        updatingTransactionDb: {
          hash: action.data.ref,
          success: false,
          error: true
        }
      };

    case CONSTANTS.RESET_HASH:
      return {
        ...state,
        updatingTransactionDb: {
          hash: null,
          success: false,
          error: false
        }
      };

    case CONSTANTS.TOGGLE_DISPLAY_MODAL_DASHBOARD:
      return {
        ...state,
        displayModal: action.data
      };

    case CONSTANTS.UPDATE_CRYPTO_RATES: {
      return {
        ...state,
        cryptoRates: {
          ...state.cryptoRates,
          data: {
            ...state.cryptoRates.data,
            [action.data.crypto]: action.data.rate
          }
        },
        lastCheckedTimestamp: new Date()
      };
    }

    case CONSTANTS.UPDATE_PRICES:
    case CONSTANTS.GET_PRICES: {
      return {
        ...state,
        graphPrices: {
          ...state.graphPrices,
          [action.data.crypto]: {
            ...state.graphPrices[action.data.crypto],
            loading: true,
            error: false,
            loaded: false
          }
        }
      };
    }
    case CONSTANTS.GET_PRICES_SUCCESS: {
      return {
        ...state,
        graphPrices: {
          ...state.graphPrices,
          [action.data.crypto]: {
            ...state.graphPrices[action.data.crypto],
            loading: false,
            loaded: true,
            data: {
              ...action.data.data
            }
          }
        }
      };
    }
    case CONSTANTS.UPDATE_PRICES_SUCCESS: {
      const tempArray = [...state.graphPrices[action.data.crypto].prices];
      const tempTotal = state.graphPrices[action.data.crypto].total_rec;
      return {
        ...state,
        graphPrices: {
          ...state.graphPrices,
          [action.data.crypto]: {
            ...state.graphPrices[action.data?.crypto],
            loading: false,
            loaded: true,
            data: {
              prices: tempArray.concat(action.data.data.prices),
              total_rec: tempTotal + action.data.data.total_rec
            }
          }
        }
      };
    }
    case CONSTANTS.UPDATE_PRICES_ERROR:
    case CONSTANTS.GET_PRICES_ERROR: {
      return {
        ...state,
        graphPrices: {
          ...state.graphPrices,
          [action.error?.crypto]: {
            ...state.graphPrices[action.error?.crypto],
            loading: false,
            loaded: false,
            error: action.error?.message
          }
        }
      };
    }
    //////////////////////////////////////////////////////
    case CONSTANTS.GET_ALL_PRICES: {
      return {
        ...state,
        cryptoRates: {
          ...state.cryptoRates,
          loading: true,
          error: false,
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_ALL_PRICES_SUCCESS: {
      return {
        ...state,
        cryptoRates: {
          ...state.cryptoRates,
          loading: false,
          loaded: true,
          data: mapPricesResponseToCryptoRates(action.data)
        }
      };
    }
    case CONSTANTS.GET_ALL_PRICES_ERROR: {
      return {
        ...state,
        cryptoRates: {
          ...state.cryptoRates,
          loading: false,
          loaded: false,
          error: action.error?.message
        }
      };
    }

    //////////////////////////////////////////////////////

    case CONSTANTS.FETCH_CANCELED_ORDERS: {
      return {
        ...state,
        canceledOrders: {
          ...state.canceledOrders,
          loading: true,
          loaded: false,
          error: ''
        }
      };
    }

    case CONSTANTS.FETCH_CANCELED_ORDERS_SUCCESS: {
      return {
        ...state,
        canceledOrders: {
          ...state.canceledOrders,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };
    }

    case CONSTANTS.FETCH_CANCELED_ORDERS_ERROR:
      return {
        ...state,
        canceledOrders: {
          ...state.canceledOrders,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.FETCH_OPEN_ORDERS: {
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: true,
          loaded: false,
          error: ''
        }
      };
    }

    case CONSTANTS.FETCH_OPEN_ORDERS_SUCCESS: {
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
          loaded: true,
          data: [...action.data.data],
          error: ''
        }
      };
    }

    case CONSTANTS.FETCH_OPEN_ORDERS_ERROR:
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    case CONSTANTS.CLEAR_OPEN_ORDERS: {
      return {
        ...state,
        openOrders: {
          data: []
        }
      };
    }
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    default:
      return state;
  }
};

const mapPricesResponseToCryptoRates = data => {
  if (!data) {
    return {};
  }
  const ratesMap = new Map();
  data?.prices?.forEach(price => {
    const asset = getFirstAssetFromPair(price.security)?.toLowerCase();
    if (asset) {
      ratesMap.set(asset, price.price);
    }
  });

  return Object.fromEntries(ratesMap);
};
