import { Col, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { formatDate, getLocalisedNumericString, handleLastUpdateTimestamp } from '../../../utils/generalUtils';
import { isUSDAsset, getCryptoName, getDecimalByFraction } from '../../../utils/transactionsHelpers';
import LoadingWrapper from '../../../components/LoadingWrapper/LoadingWrapper';
import GeneralConfirmModal from '../../modals/GeneralConfirmModal/GeneralConfirmModal';
import { FRACTIONS } from '../../../enums/validation';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';

class PreviewStep extends PureComponent {
  constructor({ isLoading, onSubmit, onClose, asset, amount, fee, fromAddress, toAddress }) {
    super({
      isLoading,
      onSubmit,
      onClose,
      asset,
      amount,
      fee,
      fromAddress,
      toAddress
    });
    this.state = {
      showGeneralConfirmationModal: false
    };
  }
  toggleModal = (fieldName, wallet) => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`],
      wallet: wallet
    });
  };

  render() {
    return (
      <>
        <LoadingWrapper isLoading={this.props.isLoading}>
          <div>
            <div className="ed-info-wrapper">
              <p className="ed-pretitle">
                {i18next.t('externalDepositWithdraw.paragraphInTitleType') +
                  'withdrawal' +
                  i18next.t('externalDepositWithdraw.paragraphInTitleType2')}
              </p>
              <h2 className="external-deposit-h1-label">
                <span className="ed-asset">{this.props.asset}</span>
                {isUSDAsset(this.props.asset)
                  ? '$' + this.props.amount
                  : getLocalisedNumericString(
                      this.props.amount - this.props.cryptoTransactionFee?.value,
                      true,
                      getDecimalByFraction(FRACTIONS[this.props.asset])
                    )}{' '}
              </h2>
            </div>
            <div className="submit-data-div">
              {!isUSDAsset(this.props.asset) && (
                <>
                  <Row>
                    <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.vlFee')}</Col>
                    <Col className="external-deposit-title-label col-6">
                      {getLocalisedNumericString(
                        this.props.cryptoTransactionFee?.value,
                        true,
                        getDecimalByFraction(FRACTIONS[this.props.asset])
                      )}{' '}
                      {this.props.asset}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ed-pretitle-small">Total</Col>
                    <Col className="external-deposit-title-label col-6">
                      {this.props.amount} {this.props.asset}
                    </Col>
                  </Row>
                </>
              )}
              {this.props.fromAddress ? (
                <Row>
                  <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.fromAccount')}</Col>
                  <Col className="external-deposit-title-label col-6">{this.props.fromAddress}</Col>
                </Row>
              ) : null}
              {this.props.toAddress ? (
                <Row>
                  <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.destinationWallet')}</Col>
                  <Col className="external-deposit-title-label col-6">{this.props.toAddress}</Col>
                </Row>
              ) : null}

              <Row>
                <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.withdrawalDate')}</Col>
                <Col className="external-deposit-title-label col-6">
                  {formatDate(new Date(), {
                    dateOnly: true
                  })}
                </Col>
              </Row>

              <hr className="horizontal-line" />
              {isUSDAsset(this.props.asset) ? (
                <p className="external-deposit-central-text ed-text">
                  <br />
                  {i18next.t('externalDepositWithdraw.disclaimer') +
                    ' withdrawal' +
                    i18next.t('externalDepositWithdraw.disclaimer2')}
                </p>
              ) : (
                <p className="external-deposit-central-text ed-text">
                  <br />
                  {i18next.t('externalDepositWithdraw.disclaimerCrypto') +
                    getCryptoName(this.props.asset) +
                    i18next.t('externalDepositWithdraw.disclaimerCrypto2') +
                    getCryptoName(this.props.asset) +
                    i18next.t('externalDepositWithdraw.disclaimerCrypto3')}
                </p>
              )}
            </div>

            <Row className="external-deposit-footer-bar">
              <VLButton
                width="100%"
                disabled={this.props.isLoading}
                onClick={() => this.toggleModal('GeneralConfirmation')}
                text="Request withdrawal"
              />
              <p className="sfcb-button-text">{handleLastUpdateTimestamp()}</p>
            </Row>
            {this.state.showGeneralConfirmationModal && (
              <GeneralConfirmModal
                style={{ marginTop: '-4.1%' }}
                isOpen={this.state.showGeneralConfirmationModal}
                title={'Confirm Withdraw'}
                text={'Are you sure you want to withdraw ?'}
                onConfirm={() => this.props.onSubmit()}
                onCancel={() => this.toggleModal('GeneralConfirmation')}
                contentClassName="confirmation-modal-content confirmation-modal-content-width80"
              />
            )}
          </div>
        </LoadingWrapper>
      </>
    );
  }
}

export default PreviewStep;
