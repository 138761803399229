import React, { useEffect } from 'react';
import OrderBlotter from '../../components/OrderBlotter';
import TradeOrder from '../../components/TradeOrder';
import PriceVolumeBar from '../../components/PriceVolumeBar/index';
import OrderBook from '../../components/OrderBook';
import { getStartEndTime } from '../../utils/generalUtils';
import TradeChart from '../../components/TradeChart';
import Card from '../Card';
import './Trade.scss';

const timeframe = '24h';
const BTCUSD = 'BTCUSD';
const btc = 'btc';
const eth = 'eth';
const ETHUSD = 'ETHUSD';

const Trade = ({ getTradingAssets, prices, getPrices, updateCryptoRates }) => {
  useEffect(() => {
    getTradingAssets();
  }, [getTradingAssets]);

  useEffect(() => {
    const { starttime } = getStartEndTime(timeframe);
    getPrices({
      crypto: btc,
      security: BTCUSD,
      query: {
        fromtime: starttime,
        timeframe: timeframe
      }
    });
    getPrices({
      crypto: eth,
      security: ETHUSD,
      query: {
        fromtime: starttime,
        timeframe: timeframe
      }
    });
  }, [getPrices]);

  useEffect(() => {
    if (prices.eth.data?.prices.length > 1) {
      updateCryptoRates({
        crypto: eth,
        rate: prices.eth.data?.prices[prices.eth.data?.prices.length - 1].price
      });
    }

    if (prices.eth.data?.prices.length > 1) {
      updateCryptoRates({
        crypto: btc,
        rate: prices.btc.data?.prices[prices.btc.data?.prices.length - 1].price
      });
    }
  }, [prices, updateCryptoRates]);

  return (
    <Card borderless transparent internalSpacing="0">
      <div className="Trading">
        <div className="Trading__header">
          <Card internalSpacing="3" width="100%">
            <PriceVolumeBar />
          </Card>
        </div>
        <div className="Trading__main">
          <div className="Trading__main-left">
            <Card internalSpacing="0">
              <div className="Trading__graph-section">
                <TradeChart />
              </div>
            </Card>
            <Card internalSpacing="0">
              <div className="Trading__blotter-section">
                <OrderBlotter />
              </div>
            </Card>
          </div>
          <div className="Trading__main-right">
            <div className="Trading__book-section">
              <Card internalSpacing="0" height="100%">
                <OrderBook />
              </Card>
            </div>
            <div className="Trading__order-section">
              <Card internalSpacing="0" height="100%">
                <TradeOrder />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Trade;
