import { connect } from 'react-redux';
import Forgot from './Forgot';
import { forgotPasswordRequest } from '../../redux/actions/login';
import './Forgot.scss';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  forgotPasswordRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
