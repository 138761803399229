import { Row, Table, Col, Container } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { IoMdAdd } from 'react-icons/io';
import CreateWalletModal from '../../components/modals/CreateWalletModal';
import { MdDelete } from 'react-icons/md';
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import { toast } from 'react-toastify';
import { getCryptoName } from '../../utils/transactionsHelpers';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import VLButton from '../../components/Buttons/VLButton';
import VLIconButton from '../../components/Buttons/VLIconButton';
import PageTitle from '../../components/PageTitle';
import Card from '../Card';

const cryptoAssetName = asset => {
  if (!asset) return '-';

  const shortName = asset.toUpperCase();
  const longName = getCryptoName(shortName);

  if (shortName === longName) return shortName;

  return `${longName} (${shortName})`;
};

const initialState = {
  showCreateWalletModal: false,
  showConfirmationModal: false,

  wallet: {}
};

class Wallets extends PureComponent {
  state = {
    ...initialState
  };
  static contextType = AccountFilterContext;

  onRemoveWallet = wallet => {
    const selectedAccountObj = this.getSelectedAccount();

    if (wallet['Wallet-type'] === 'Exchange') {
      this.props.deleteWallet({
        accountNum: selectedAccountObj.account,
        Name: wallet.Name,
        isExchange: true
      });
    } else {
      this.props.deleteWallet({
        Name: wallet.Name,
        accountNum: selectedAccountObj.account
      });
    }
  };
  getSelectedAccount = () => {
    let selectedAccountObj = this.props?.accountObj;

    if (this.props.currentAccountNumber) {
      selectedAccountObj = this.props.accounts.data.find(
        accountObj => accountObj.account === this.props.currentAccountNumber
      );
    }
    return selectedAccountObj;
  };
  getWalletsFromAccount = () => {
    const selectedAccountObj = this.getSelectedAccount();

    let allWallets = [];
    if (selectedAccountObj?.attr?.wallets) {
      allWallets = allWallets.concat(
        selectedAccountObj.attr?.wallets.filter(wallet => {
          return !wallet.deleted;
        })
      );
    }
    if (selectedAccountObj?.attr?.exchangeWallets) {
      allWallets = allWallets?.concat(
        selectedAccountObj.attr?.exchangeWallets.filter(wallet => {
          return !wallet.deleted;
        })
      );
    }

    return allWallets;
  };

  componentDidMount() {
    this.props.getDepositAccounts();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deleteWalletResponse === this.props.deleteWalletResponse) {
      return;
    }
    if (this.props.deleteWalletResponse.message === 'success') {
      toast.success(this.props.deleteWalletResponse.reason);
      this.toggleModal('Confirmation');
      this.props.getDepositAccounts();
      this.props.accountObj &&
        this.props.getAccountDetailsRequest({
          account: this.props?.accountObj?.account
        });
    }
  }
  returnButtonBar = () => {
    return (
      <Row className="user-control-container row no-gutters">
        <PageTitle text="My wallets" styleClass="dashboard-transactions-header" />
      </Row>
    );
  };
  renderWalletsTable = () => {
    const wallets = this.getWalletsFromAccount();
    return wallets?.length > 0 ? (
      <React.Fragment>
        <Table borderless responsive size="sm" className="vl-table">
          <thead>
            <tr className="transactions-header">
              <th>Crypto-asset</th>
              <th>Type</th>
              <th>Name</th>
              <th>Wallet Address</th>
              {this.props.canEditWallets && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {wallets.map(wallet => {
              return (
                <tr key={Math.random()}>
                  <td>{cryptoAssetName(wallet?.['Crypto-asset'])}</td>
                  <td>{wallet?.['Wallet-type'] || wallet?.['Exchange']}</td>
                  <td>{wallet?.Name || '-'}</td>
                  <td style={{ width: '45%' }}>{wallet?.Address || '-'}</td>
                  {this.props.canEditWallets && (
                    <td
                      style={{
                        borderTop: '0px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <div>
                        <VLIconButton
                          Icon={MdDelete}
                          variant="borderless"
                          onClick={() => this.toggleModal('Confirmation', wallet)}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </React.Fragment>
    ) : (
      <div className="no-existing-wallets-message">
        <span>There are no existing wallets</span>
      </div>
    );
  };

  toggleModal = (fieldName, wallet) => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`],
      wallet: wallet
    });
  };

  render() {
    return (
      <Card>
        <Container fluid>
          <Row>
            {this.state.showCreateWalletModal && (
              <CreateWalletModal
                accountNum={this.props?.accountObj?.account}
                onClose={() => this.toggleModal('CreateWallet')}
              />
            )}
            <Col>
              {this.returnButtonBar()}
              {this.renderWalletsTable()}
              {this.props.canEditWallets && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <VLButton
                    onClick={() => this.toggleModal('CreateWallet')}
                    text="Create wallet"
                    rightIcon={<IoMdAdd />}
                  />
                </div>
              )}
            </Col>

            {this.state.showConfirmationModal && (
              <GeneralConfirmModal
                isOpen={this.state.showConfirmationModal}
                title={'Delete this wallet'}
                text={'Are you sure you want to delete this wallet ?'}
                onConfirm={() => this.onRemoveWallet(this.state.wallet)}
                onCancel={() => this.toggleModal('Confirmation')}
              />
            )}
          </Row>
        </Container>
      </Card>
    );
  }
}

export default Wallets;
