import Axios from 'axios';
import { getAuthToken } from '../utils/generalUtils';

// Stub
const authInterceptor = config => config;

/**
 * Interceptor that adds the auth bearer token to the request.
 *
 * @param config - The request config.
 * @returns {*} - The config with the auth token added.
 */
const bearerAuthInterceptor = config => {
  const token = getAuthToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${encodeURIComponent(token)}`;
  }
  return config;
};

// Stub
const rejectInterceptor = error => Promise.reject(error);

/**
 * Standard headers used across all apis.
 */
const HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const backendApi = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: HEADERS
});

const ctsApi = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_CRYPTO,
  withCredentials: true,
  headers: HEADERS
});

const usdApi = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_USD,
  withCredentials: true,
  headers: HEADERS
});

backendApi.interceptors.request.use(authInterceptor, rejectInterceptor);
ctsApi.interceptors.request.use(bearerAuthInterceptor, rejectInterceptor);
usdApi.interceptors.request.use(bearerAuthInterceptor, rejectInterceptor);

export { backendApi, ctsApi, usdApi };
