import './Wallets.scss';
import Wallets from './Wallets';
import { connect } from 'react-redux';
import { getUserProfile } from '../../redux/actions/login';
import { deleteWallet } from '../../redux/actions/settings';
import { getDepositAccounts } from '../../redux/actions/transactions';
import { getAccountDetailsRequest } from '../../redux/actions/administration';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  user: state.login.userData.data,
  profileDetails: state.login.profileDetails,
  deleteWalletResponse: state.settings.deleteWalletResponse,
  canEditWallets: state.login.userPermissions.data.permissions?.canEditUsers,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getUserProfile,
  deleteWallet,
  getDepositAccounts,
  getAccountDetailsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallets);
