import React, { useEffect, useState } from 'react';
import { FRACTIONS } from '../../../enums/validation';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import AssetPositions from '../AssetPositions';
import { formatCurrency, parseCurrency } from '../trade-helpers';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../LoadingIndicator';
import TradeButton from '../TradeButton';
import './MarketOrder.scss';
import VLSelect from '../../VLSelect';
import VLInput from '../../VLInput/VLInput';

const initialFormState = {
  isLoading: false,
  isSubmitted: false,
  amount: '',
  errors: {},
  tif: { label: 'IOC', value: 'IOC' }
};

const MarketOrder = ({
  userData,
  account,
  side,
  pair,
  balance,
  makeMarketOrderRequest,
  websocket,
  cryptoRates,
  updateTransactionsDB,
  isDisabled
}) => {
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    setFormState(initialFormState);
  }, [side]);

  const handleChange = e => {
    if (!e.target.dataset.asset) {
      throw new Error('Missing asset attribute on input');
    }

    const formattedValue = formatCurrency(
      e.target.value,
      getDecimalByFraction(FRACTIONS[e.target.dataset.asset]),
      false
    );

    setFormState({
      ...formState,
      [e.currentTarget.name]: formattedValue,
      errors: {
        ...formState.errors,
        [e.currentTarget.name]: '',
        total: ''
      }
    });
  };

  const handleBlur = e => {
    if (!e.target.dataset.asset) {
      throw new Error('Missing asset attribute on input');
    }

    const formattedValue = formatCurrency(
      e.target.value,
      getDecimalByFraction(FRACTIONS[e.target.dataset.asset]),
      true
    );

    setFormState({
      ...formState,
      [e.currentTarget.name]: formattedValue
    });
  };

  const validateForm = () => {
    const errors = {};

    if (side === 'B') {
      if (!formState.total || parseCurrency(formState.total) === 0) {
        errors.total = 'Please enter an amount';
      } else if (parseCurrency(formState.total) > balance[pair[1]]) {
        errors.total = 'Value is more than available balance';
      }
    }

    if (side === 'S') {
      if (!formState.amount || parseCurrency(formState.amount) === 0) {
        errors.amount = 'Please enter an amount';
      } else if (parseCurrency(formState.amount) > balance[pair[0]]) {
        errors.amount = 'Value is more than available balance';
      }
    }

    setFormState({
      ...formState,
      errors
    });

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = e => {
    if (!validateForm()) return;

    let data = {
      socket: websocket,
      account,
      firm: userData.firm,
      pair: pair.join(''),
      side,
      asset: pair[0],
      amount: parseCurrency(formState.amount),
      total: parseCurrency(formState.total),
      tif: 'IOC',
      successCallback,
      errorCallback
    };

    setFormState(s => ({
      ...s,
      isLoading: true
    }));

    makeMarketOrderRequest(data);
  };

  const successCallback = data => {
    toast.success(i18next.t('toasts.success'));
    setFormState(initialFormState);
    updateTransactionsDB({
      ref: data.refno,
      type: 'exchange',
      cryptoRates: cryptoRates.data
    });
  };

  const errorCallback = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.text}`);

    setFormState(s => ({
      ...s,
      isLoading: false
    }));
  };

  return (
    <div className="market-order">
      <AssetPositions
        positions={[
          {
            asset: pair[0],
            value: getLocalisedNumericString(balance?.[pair?.[0]], true, getDecimalByFraction(FRACTIONS[pair[0]]))
          },
          {
            asset: pair[1],
            value: getLocalisedNumericString(balance[pair[1]], true, getDecimalByFraction(FRACTIONS[pair[1]]))
          }
        ]}
      />
      <form>
        <div className="system-form-control-box">
          <p className="system-form-control-label">Price:</p>
          <VLInput
            type="price"
            name="price"
            placeholder="Price"
            onChange={handleChange}
            value=""
            maxLength={20}
            autoComplete={'off'}
            disabled
            size="s"
            assetLabel={`Market ${pair[1]}`}
          />
        </div>
        {side === 'S' ? (
          <div className="system-form-control-box">
            <p className="system-form-control-label">Amount:</p>
            <VLInput
              type="amount"
              name="amount"
              placeholder="Amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['amount'] || ''}
              maxLength="20"
              autoComplete="off"
              autoFocus
              dataAsset={pair[0]}
              assetLabel={pair[0]}
              size="s"
              error={formState.errors?.amount}
            />

            {formState.errors?.amount ? (
              <span className="system-form-control-error-text">{formState.errors?.amount}</span>
            ) : null}
          </div>
        ) : null}
        {side === 'B' ? (
          <div className="system-form-control-box">
            <p className="system-form-control-label">Approximate total:</p>
            <VLInput
              type="total"
              name="total"
              placeholder="Total"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['total'] || ''}
              maxLength="20"
              autoComplete="off"
              autoFocus
              dataAsset={pair[1]}
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.total}
            />
            {formState.errors?.total ? (
              <span className="system-form-control-error-text">{formState.errors?.total}</span>
            ) : null}
          </div>
        ) : null}
        <div>
          <div className="select-wrapper">
            <label>TIF:</label>
            <div className="select-field">
              <VLSelect
                borderless
                transparent
                aria-labelledby="tif"
                name="tif"
                options={[{ label: 'IOC', value: 'IOC' }]}
                value={formState.tif}
                isDisabled
                isOptionDisabled={false}
              />
            </div>
          </div>
        </div>
        <TradeButton
          onClick={handleSubmit}
          isDisabled={formState.isLoading || isDisabled}
          asset={pair[0]}
          side={side}
        />
        {formState.isLoading ? <LoadingIndicator /> : null}
      </form>
    </div>
  );
};

export default MarketOrder;
