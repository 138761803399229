// If this is changed, change the value in the stylesheet as well.
export const BOOK_SIZE = 14;
// Array used to provide undefined values to fill the order book.
const PADDING_ARRAY = Array.from({ length: BOOK_SIZE }).fill(undefined);

export const padArray = array => {
  array?.push(...PADDING_ARRAY.slice(array?.length));
  return array;
};

export const filterOutNonIOI = qmsList => {
  return qmsList?.filter(q => q.ioi);
};
export const mapTroughBelongingOrder = (ordersList, openOrders) => {
  return ordersList?.map(q => {
    if (openOrders?.findIndex(o => o.refno === q?.key) > -1) {
      q.belongs = true;
    }
    return q;
  });
};

export const byOrderPriceAsc = (a, b) => a.price - b.price;
export const byOrderPriceDesc = (a, b) => b.price - a.price;
