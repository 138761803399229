import React, { useMemo, useState } from 'react';
import VLSelect from '../VLSelect';
import debounce from 'lodash.debounce';

const MIN_CHARS_FOR_SEARCH = 3;
const MAX_OPTIONS_SHOWN = 50;

const mapUsersToOptionsFn = user => ({
  label: `${user.attr?.first_name} ${user.attr?.last_name} (${user.userid})`,
  value: user.userid
});

/**
 * SearchInput component
 * Component based on React Select with purpose to search for existing users
 *
 * @param {string} selectedUserId Selected user id
 * @param {fn} onUserSelected Callback function that accepts user id
 * @param {Array<user>} ignoreUsers Optional param if search results should be filtered by firm code
 * @param {fn} getUserSuggestionsRequest Passed automatically by redux (see index.js)
 * @param {Array<user>} userSuggestions Passed automatically by redux (see index.js)
 * @param {string} firm Optional param if search results should be filtered by firm code
 */

const SearchUsersInput = ({
  selectedUserId,
  onUserSelected,
  ignoreUsers,
  getUserSuggestionsRequest,
  userSuggestions,
  firm
}) => {
  const [value, setValue] = useState('');

  const options = useMemo(() => {
    let suggestions = userSuggestions.data;

    if (!suggestions?.length) return [];

    if (!value || value.length < MIN_CHARS_FOR_SEARCH) {
      return [];
    }

    return suggestions
      .filter(s => !ignoreUsers.map(i => i.userid).includes(s.userid)) // filter out ignored users
      .slice(0, MAX_OPTIONS_SHOWN)
      .map(mapUsersToOptionsFn);
  }, [ignoreUsers, userSuggestions.data, value]);

  const handleSelectChange = option => {
    onUserSelected(option.value);
  };

  const getFilteredUsers = val => {
    const payload = {
      q: val,
      firm
    };

    getUserSuggestionsRequest(payload);
  };

  const handleSearchDebounced = debounce(getFilteredUsers, 300);

  const handleSearch = value => {
    setValue(value);

    if (value.length < MIN_CHARS_FOR_SEARCH) return;

    handleSearchDebounced(value);
  };

  return (
    <VLSelect
      placeholder={`Search by user name or email (min ${MIN_CHARS_FOR_SEARCH} characters)`}
      options={options}
      onInputChange={handleSearch}
      onChange={handleSelectChange}
      value={options.find(o => o.value === selectedUserId) ?? undefined}
      isOptionDisabled={false}
      isLoading={userSuggestions.loading}
      isClearable
    />
  );
};

export default SearchUsersInput;
