import {
  DEPOSIT_TYPE,
  REQUEST_TYPE,
  SECURITY_CODES,
  SEND_TYPE,
  TO_BANK,
  TO_NETWORK,
  WITHDRAW_TYPE,
  STAKE,
  EXIT_STAKE
} from '../enums/validation';
import { isAccountDisplay, isDollarAsset, isUserDisplay } from './validators';

import { ROLES } from '../enums/roles';
import { getLocalisedNumericString } from './generalUtils';
import i18next from 'i18next';
const dropdownRoles = [
  { id: ROLES.All, label: i18next.t('users/accounts.allRoles') },
  { id: ROLES.ADMIN, label: i18next.t('users/accounts.admin') },
  { id: ROLES.USER, label: i18next.t('users/accounts.firmUser') }
];

const dropdownAssets = [
  { id: 'USD', label: 'USD' },
  { id: 'EUR', label: 'EUR' },
  { id: 'GBP', label: 'GBP' },
  { id: 'ETH', label: 'ETH' },
  { id: 'BTC', label: 'BTC' }
];

export const toggleShowPassword = className => {
  const passInput = document.getElementsByClassName(className)[0];
  if (!passInput) {
    return;
  }
  if (passInput.type !== 'password') {
    passInput.setAttribute('type', 'password');
  } else {
    passInput.setAttribute('type', 'text');
  }
};

export const getTopInputStringsBasedOnDisplayType = type => {
  let data = {};
  if (isUserDisplay(type)) {
    data = {
      searchInputPlaceholder: i18next.t('users.topInputs.searchPlaceholder'),
      selectInputPlaceholder: 'users.topInputs.selectPlaceholder',
      searchLabel: i18next.t('users.topInputs.searchLabel'),
      selectLabel: i18next.t('users.topInputs.selectLabel'),
      dropdownOptions: dropdownRoles,
      dropdownDefaultOption: i18next.t('users.topInputs.selectDefault')
    };
  } else if (isAccountDisplay(type)) {
    data = {
      searchInputPlaceholder: i18next.t('accounts.topInputs.searchPlaceholder'),
      selectInputPlaceholder: i18next.t('accounts.topInputs.selectPlaceholder'),
      searchLabel: i18next.t('accounts.topInputs.searchLabel'),
      selectLabel: i18next.t('accounts.topInputs.selectLabel'),
      dropdownOptions: dropdownAssets,
      dropdownDefaultOption: i18next.t('accounts.topInputs.selectDefault')
    };
  }
  return data;
};

export const getTransactionTitleByType = transactionType => {
  if (transactionType === DEPOSIT_TYPE) return 'Deposit asset';
  if (transactionType === WITHDRAW_TYPE) return 'Withdraw Asset';
  if (transactionType === SEND_TYPE) return 'Send Payment';
  if (transactionType === REQUEST_TYPE) return 'Request Payment';
  if (transactionType === STAKE) return 'Stake';
  if (transactionType === EXIT_STAKE) return 'Exit Staking';
};

export const getConfirmButtonTextByType = transactionType => {
  if (transactionType === DEPOSIT_TYPE) return 'DEPOSIT';
  if (transactionType === WITHDRAW_TYPE) return 'WITHDRAWAL';
  if (transactionType === TO_BANK) return '';
  if (transactionType === TO_NETWORK) return '';
};

export const getTotalBalanceFormatting = (balance, asset) => {
  if (!balance === undefined) {
    return '0';
  }

  if (isDollarAsset(asset)) {
    return `$ ${getLocalisedNumericString(balance) !== 'NaN' ? getLocalisedNumericString(balance) : '0'}`;
  } else {
    return balance || '0';
  }
};

export const getSecurityLabel = asset => {
  if (asset?.security) {
    return asset?.security === SECURITY_CODES.VLC$ ? 'VL$' : asset?.security;
  } else if (typeof asset === 'string') {
    //In case we send asset.security directly instead of the asset object
    return asset === SECURITY_CODES.VLC$ ? 'VL$' : asset;
  }
};
