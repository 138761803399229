import { Col, Container, Row } from 'react-bootstrap';
import { DARK_MODE, LIGHT_MODE } from '../../enums/validation';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { isValidEmailFormat } from '../../utils/validators';
import i18next from 'i18next';
import i18n from '../../i18n';
import { history } from '../../configureStore';
import VerifyTwoFactorModal from '../../components/modals/VerifyTwoFactorModal/VerifyTwoFactorModal';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import VLButton from '../../components/Buttons/VLButton';
import VLInput from '../../components/VLInput';

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emailError: false,
      params: {
        email: '',
        password: '',
        twoFactorCode: ''
      }
    };
  }
  changeLanguage = language => {
    this.setState({ lng: language });
    i18n.changeLanguage(language);
  };
  componentDidMount() {
    document.body.classList.add(LIGHT_MODE);
    document.body.classList.remove(DARK_MODE);
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({
      params: {
        ...this.state.params,
        [name]: value,
        lng: ''
      }
    });
  };

  handleLogin = () => {
    const actionData = {
      data: this.state.params,
      successCallback: () => {
        history.push({ pathname: '/' });
      }
    };
    this.props.loginRequest(actionData);
  };

  handleTwoFactorLogin = code => {
    this.setState(
      {
        params: {
          ...this.state.params,
          twoFactorCode: code
        }
      },
      this.handleLogin
    );
  };

  handleLoginOnKeyDown = e => {
    this.checkEmailFormat();
    if (e.key === 'Enter') {
      if (!this.state.emailError) {
        this.handleLogin();
      }
    }
  };

  checkEmailFormat = () => {
    this.setState({ emailError: !isValidEmailFormat(this.state.params.email) });
  };

  render() {
    const { email, password } = this.state.params;
    const disabled = email && password && !this.state.emailError ? false : true;
    const twoFactorRequired = this.props.userData?.error === 'invalid 2FA token';

    return (
      <LoadingWrapper isLoading={this.props.userData.loading}>
        <Container fluid className="system-container">
          <Row noGutters className="system-row">
            <Col md={4} className="system-side-image-col login">
              <div className="system-slogan-container">
                <div className="system-vaultLink">VaultLink</div>
                <div className="system-slogan">{i18next.t('LOGIN.real-time')}</div>
              </div>
            </Col>

            <Col className="system-control-column">
              <div className="system-card-container">
                <Row noGutters className="system-top-header">
                  {i18next.t('LOGIN.welcome')},
                </Row>
                <Row noGutters className="system-bottom-header">
                  {i18next.t('LOGIN.loginTo')} <b>VaultLink</b>
                </Row>
                <Row noGutters>
                  <div className="system-form-control-box system-form-control-box--login">
                    <div className="placeholder"> {i18next.t('LOGIN.eMail')}</div>
                    <VLInput
                      onChange={this.handleOnChange}
                      onKeyDown={this.handleLoginOnKeyDown}
                      value={email}
                      type="email"
                      name="email"
                      onBlur={this.checkEmailFormat}
                      size="s"
                      error={this.state.emailError}
                    />
                    {this.state.emailError && (
                      <span className="system-form-control-error-text login-error-text">
                        {i18next.t('LOGIN.invalidEmail')}
                      </span>
                    )}
                  </div>
                </Row>
                <Row noGutters>
                  <div className="system-form-control-box system-form-control-box--login">
                    <div className="placeholder">{i18next.t('LOGIN.password')}</div>
                    <Row noGutters className="form-control-row">
                      <VLInput
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleLoginOnKeyDown}
                        value={password}
                        type="password"
                        name="password"
                        className="password"
                        size="s"
                        width="100%"
                      />
                    </Row>
                    <VerifyTwoFactorModal
                      isOpen={twoFactorRequired}
                      onClose={this.props.loginReset}
                      onVerify={this.handleTwoFactorLogin}
                      hasError={this.state.params.twoFactorCode}
                    />
                  </div>
                </Row>
                <Row noGutters className="system-button-row">
                  <VLButton
                    disabled={disabled}
                    onClick={this.handleLogin}
                    text={i18next.t('LOGIN.login')}
                    width="100%"
                  />
                </Row>

                <div className="system-bottom-text">
                  <Link to="/forgot"> {i18next.t('LOGIN.forgotPassword')}?</Link>
                </div>
              </div>
            </Col>
            <div className="contactSupport">
              <div className="login-header-link">
                <Link to="/support">Contact support</Link>
              </div>
            </div>
          </Row>
        </Container>
      </LoadingWrapper>
    );
  }
}

export default Login;
