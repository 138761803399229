import CreateWalletModal from './CreateWalletModal';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createWallet } from '../../../redux/actions/settings';
import { getUserProfile } from '../../../redux/actions/login';
import { getDepositAccounts } from '../../../redux/actions/transactions';
import { getAccountDetailsRequest } from '../../../redux/actions/administration';

const mapStateToProps = state => ({
  errorCreateWallet: state.settings.errorCreatingWallet,
  successCreatingWallet: state.settings.successCreatingWallet,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  createWallet,
  getUserProfile,
  getDepositAccounts,
  getAccountDetailsRequest
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateWalletModal);
