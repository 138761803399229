import { Row } from 'react-bootstrap';
import { ZERO_ASSET_FORMAT, AMOUNT_REGEX, WITHDRAW_TYPE } from '../../../enums/validation';
import { onlyNumbers, validateAmount } from '../../../utils/validators';
import i18next from 'i18next';
import { moneyFormat, replaceCommas } from '../../../utils/generalUtils';
import LoadingWrapper from '../../LoadingWrapper/LoadingWrapper';
import { getTotalBalanceFormatting } from '../../../utils/DOMHelpers';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput';

const AmountStep = ({
  onCompleted,
  valueObject,
  availableAmount,
  shouldValidateAmount,
  asset,
  onChange,
  isLoading,
  maxDecimal,
  transactionType
}) => {
  const disabled = !valueObject.value || valueObject.value === ZERO_ASSET_FORMAT[asset] || valueObject.error;

  const formatAndValidate = value => {
    const numberValue = onlyNumbers(replaceCommas(value));

    let validationObject = validateAmount(numberValue, availableAmount, maxDecimal, !shouldValidateAmount);

    return {
      ...validationObject,
      value: moneyFormat(validationObject.value)
    };
  };

  const handleOnChange = ({ target }) => {
    if (AMOUNT_REGEX.test(target.value)) {
      onChange({
        ...valueObject,
        value: moneyFormat(replaceCommas(target.value))
      });
    }
  };

  const handleOnMaxClick = () => {
    const newValue = formatAndValidate(moneyFormat(availableAmount));
    onChange(newValue);
  };

  const handleOnBlur = () => {
    const newValue = formatAndValidate(valueObject.value);
    onChange(newValue);
  };

  return (
    <>
      <LoadingWrapper isLoading={isLoading} waitDurationSeconds={60 * 2}>
        <Row className="withdraw-amount-step-row">
          <div className="withdraw-amount-label-wrapper">
            <p className="external-deposit-title-label exchange-label">Amount</p>
            {transactionType === WITHDRAW_TYPE ? (
              <div className="withdraw-max-text" onClick={handleOnMaxClick}>
                Max
              </div>
            ) : null}
          </div>
          <div className="withdraw-amount-input-wrapper">
            <VLInput
              name="amountWithdrawal"
              placeholder="0.00"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={valueObject.value}
              maxLength="20"
              autoComplete="off"
              autoFocus
              assetLabel={asset}
              width="100%"
              noBorder
              hasBackground
              size="l"
            />
          </div>
          {valueObject.error && (
            <div className="system-form-control-box-error-text">
              <span className="system-form-control-error-text">{i18next.t('send&requestDetails.eror-text')}</span>
            </div>
          )}
          <p className="withdraw-helper-text">
            Total amount of selected asset: &nbsp;
            {getTotalBalanceFormatting(availableAmount, asset)}
          </p>
        </Row>

        <Row className="external-deposit-footer-bar">
          <VLButton width="100%" disabled={disabled} onClick={onCompleted} text="Continue" />
        </Row>
      </LoadingWrapper>
    </>
  );
};

export default AmountStep;
