import { connect } from 'react-redux';
import RiskControlSettingsModal from './RiskControlSettingsModal';

import { updateRiskSettings, addAlert } from '../../../redux/actions/websocket';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  accountDetails: state.administration.accountDetails.data.account
});

const mapDispatchToProps = {
  updateRiskSettings,
  addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskControlSettingsModal);
