import { ERROR, PENDING, SUCCESS } from '../../../enums/validation';
import { MdErrorOutline } from 'react-icons/md';
import React, { PureComponent } from 'react';
import LoadingIndicator from '../../LoadingIndicator';
import './TransactionHashStep.scss';
import TransactionSuccessScreen from '../../TransactionSuccessScreen';

class TransactionHashStep extends PureComponent {
  buildStatusScreen = () => {
    switch (this.props.hashStatus) {
      case PENDING:
        return (
          <>
            <div className="external-transaction-title-wrapper">
              <p className="external-transaction-title">Creating transaction request...</p>
            </div>
            <div className="external-transaction-loading-indicator-wrapper">
              <LoadingIndicator />
            </div>
            <h2 className="external-transaction-h1-label">
              <span>{this.props.asset}</span>
              {this.props.amount}
            </h2>
          </>
        );
      case SUCCESS: {
        return (
          <TransactionSuccessScreen
            type={this.props.transactionType}
            amount={this.props.amount}
            asset={this.props.asset}
            requestID={this.props.transactionId}
            specialRedirectFunction={this.props.specialRedirectFunction}
            counterparty={this.props.account}
          />
        );
      }
      case ERROR:
        return (
          <>
            <div className="external-transaction-success-container">
              <div className="external-transaction-error-icon-container">
                <MdErrorOutline size="3em" />
              </div>
            </div>
            <div className="external-transaction-title-wrapper">
              <p className="external-transaction-title">There seems to have been an issue with your transfer of:</p>
              <h2 className="external-transaction-h1-label">
                <span>{this.props.asset} </span>&nbsp;
                <span>{this.props.amount}</span>
              </h2>
            </div>
            <div className="external-transaction-centered">
              <span>Pease try again later or contact support if the problem persists</span>
            </div>
          </>
        );
      default: {
        return null;
      }
    }
  };

  redirectToDashboard = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div>
        <>{this.buildStatusScreen()}</>
      </div>
    );
  }
}

export default TransactionHashStep;
