import React from 'react';
import i18next from 'i18next';
import { calculateFirmAssetsUSD, getLocalisedNumericString } from '../../../utils/generalUtils';
import './AccountUsdEquivalent.scss';

const AccountUsdEquivalent = ({ positions, cryptoRates }) => {
  const totalUSD =
    !positions || !cryptoRates?.data
      ? getLocalisedNumericString(0)
      : getLocalisedNumericString(calculateFirmAssetsUSD(positions || [], cryptoRates.data));

  return (
    <div className="AccountUsdEquivalent">
      <div>{i18next.t('dashboardAccountDropdown.usdEquivalent')}:</div>
      <div className="amount">{`$ ${totalUSD}`}</div>
    </div>
  );
};

export default AccountUsdEquivalent;
