import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../components/LoadingIndicator';

const PrivateRoute = ({
  productCheck,
  component: Component,
  hasAccess,
  withContextProvider = null,
  componentProps,
  ...rest
}) => {
  const user = useSelector(state => state.login.userData);

  if (!hasAccess) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!user.loaded) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        if (user.loading) {
          return <LoadingIndicator />;
        }

        if (withContextProvider) {
          const ContextProvider = withContextProvider;
          return (
            <ContextProvider>
              <Component {...props} {...componentProps} />
            </ContextProvider>
          );
        }

        return <Component {...props} {...componentProps} />;
      }}
    />
  );
};

export default PrivateRoute;
