import Trade from './Trade';

import { compose } from 'redux';
import { toggleDarkMode } from '../../redux/actions/settings';
import { getTradingAssets, getPrices, updateCryptoRates } from '../../redux/actions/transactions';
import { setSelectedIoi } from '../../redux/actions/trade';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  userData: state.login.userData,
  connectionError: state.websocket.connectionError,
  mode: state.settings.mode,
  profileDetails: state.login.profileDetails,
  lastUpdateTimestamp: state.transactions.lastCheckedTimestamp,
  books: state.websocket.allBooks,
  selectedIoi: state.trade.selectedIoi,
  prices: state.transactions.graphPrices
});

const mapDispatchToProps = {
  toggleDarkMode,
  getTradingAssets,
  setSelectedIoi,
  getPrices,
  updateCryptoRates
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Trade);
