import { Container, Row } from 'react-bootstrap';
import { DEPOSIT_TYPE, TO_BANK, TO_NETWORK, WITHDRAW_TYPE } from '../../enums/validation';
import React, { PureComponent } from 'react';
import {
  getPositionDataForSpecificAccount,
  getSelectedOption,
  parseObjectWithSamePropertyForDropdown
} from '../../utils/generalUtils';
import { filterAssetsBasedOnPositionData, getAccountData, getDecimalByAsset } from '../../utils/transactionsHelpers';
import { getTotalBalanceFormatting } from '../../utils/DOMHelpers';
import { onlyNumbers, validateAmount } from '../../utils/validators';

import DepositRedeemSummary from '../../components/DepositRedeemSummary';
import ListTransactionsContainer from '../../components/ListTransactionsContainer';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import { MdKeyboardBackspace } from 'react-icons/md';
import RoundButton from '../../components/RoundButton';
import Select from 'react-select';
import { TRANSACTION_SCREENS } from '../../enums/paths';
import TransactionSuccessScreen from '../../components/TransactionSuccessScreen';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import SearchAccountsInput from '../../components/SearchAccountsInput';
import VLButton from '../../components/Buttons/VLButton';
import VLInput from '../../components/VLInput';
class DepositRedeem extends PureComponent {
  state = {
    account: '',
    asset: '',
    maxDecimal: 0,
    amountObject: {
      display: '',
      value: 0,
      error: false
    },
    description: '',
    currentScreen: TRANSACTION_SCREENS.DETAILS,
    transactionType: DEPOSIT_TYPE,
    transactionID: '',
    isSuperAdminView: false,
    positions: {},
    accountSearch: null,
    selectedAcc: null
  };

  composeAssetOptionComponent = ({ value, label }) => {
    return (
      <div className="form-select-label-wrap">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          alt={label}
        />
        <span className="form-select-text">{label}</span>
      </div>
    );
  };

  redirectBackToFirmView = () => {
    this.props.history.push(`/administration/${this.props.match.params.id}`);
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const firmQuery = {
        firm: this.props.match.params.id
      };
      this.props.getFirmAdminAccountsRequest(firmQuery);
    } else {
    }
    this.props.getTradingAssets();
    if (this.props.location.pathname?.includes(DEPOSIT_TYPE)) {
      this.setState({
        transactionType: DEPOSIT_TYPE,
        isSuperAdminView: this.props.match.params.id ? true : false
      });
    } else if (this.props.location.pathname?.includes(TO_BANK)) {
      this.setState({
        transactionType: TO_BANK,
        isSuperAdminView: this.props.match.params.id ? true : false
      });
    } else if (this.props.location.pathname?.includes(WITHDRAW_TYPE)) {
      this.setState({
        transactionType: WITHDRAW_TYPE,
        isSuperAdminView: this.props.match.params.id ? true : false
      });
    } else if (this.props.location.pathname?.includes(TO_NETWORK)) {
      this.setState({
        transactionType: TO_NETWORK,
        isSuperAdminView: this.props.match.params.id ? true : false
      });
    }
  }

  onClearClick = () =>
    this.setState({
      account: '',
      asset: '',
      amountObject: {
        display: '',
        value: '',
        error: false
      },
      description: '',
      currentScreen: TRANSACTION_SCREENS.DETAILS
    });

  handleAccountInputChange = ({ target }) => {
    this.setState({ accountSearch: target.value, account: null });
  };
  handleFormControlChangeDesc = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };
  handleSelect = option => {
    const decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');
    this.setState(
      {
        asset: option?.value || '',
        maxDecimal: decimal
      },
      () => {
        this.validatePositionAmount(onlyNumbers(this.state?.amountObject?.display));
      }
    );
  };

  validatePositionAmount = value => {
    let assetAccountBalance = this.state.positions?.VLN?.[this.state.asset];
    let flag = true;
    if (this.state.transactionType !== DEPOSIT_TYPE) {
      flag = false;
    }
    let validationObject = validateAmount(
      value,
      assetAccountBalance,
      this.state.maxDecimal,

      flag
    );
    this.setState({
      amountObject: { ...validationObject }
    });
  };

  handleInputChange = ({ target }) => {
    let value = onlyNumbers(target.value);

    this.validatePositionAmount(value);
  };

  switchScreen = screenType => this.setState({ currentScreen: screenType });

  handleBackButton = () => {
    this.props.history.goBack();
  };

  successTrigger = data => {
    this.setState({
      currentScreen: TRANSACTION_SCREENS.SUCCESS,
      transactionID: data.traderefno
    });
  };

  errorTrigger = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error.result}`);
  };

  submitForm = () => {
    let data = {
      account: this.state.account,
      symbol: this.state.asset,
      amount: onlyNumbers(this.state.amountObject.value),
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger,
      description: this.state.description,
      socket: this.props.websocket
    };
    if (this.state.amountObject.error) {
      return;
    }
    if (this.state.transactionType === DEPOSIT_TYPE) {
      this.props.sendDeposit(data);
      // this.props.makeDepositRequest(data);
    } else if (this.state.transactionType === WITHDRAW_TYPE) {
      this.props.sendRedeem(data);
      // this.props.makeRedeemRequest(data);
    } else if (this.state.transactionType === TO_BANK) {
      const accountData = getAccountData(this.state.account, this.props.firmAccounts.data.data);
      data = {
        ...data,
        source: this.state.account,
        recipient: accountData?.secondary_account,
        asset: data.symbol
      };
      this.props.makeToBankRequest(data);
    } else {
      const accountData = getAccountData(this.state.account, this.props.firmAccounts.data.data);
      data = {
        ...data,
        source: this.state.account,
        recipient: accountData?.secondary_account,
        asset: data.symbol
      };
      this.props.makeToNetworkRequest(data);
    }
  };

  getPositionDataBasedOnTransactionType = () => {
    switch (this.state?.transactionType) {
      case TO_NETWORK:
        if (this.state?.positions?.VLB === undefined) {
          return '0';
        } else {
          return this.state?.positions?.VLB;
        }

      case TO_BANK:
        if (this.state.positions?.VLN === undefined) {
          return '0';
        } else {
          return this.state.positions?.VLN;
        }
      default:
        return this.state.positions?.VLB;
    }
  };

  getAllOrFilteredAssets = () => {
    if (this.state.transactionType === TO_NETWORK || this.state.transactionType === TO_BANK) {
      return filterAssetsBasedOnPositionData(
        this.props.assets?.data || [],
        this.getPositionDataBasedOnTransactionType()
      );
    } else {
      return this.props.assets?.data || [];
    }
  };
  setSelectedAcc = acc => {
    const position = this.props.firmAccounts.data.data.map(account => account.account);

    let posData = {};
    let asset = {};

    this.props.queryPositionsFirmDetails({
      socket: this.props.websocket,
      userid: position,
      firm: this.props.match.params.id,
      accounts: position,
      successCallback: data => {
        data.forEach(position => {
          if (position.account === acc) {
            asset[position?.security] = position.curpos;
            posData = {
              [acc]: asset
            };
          }
        });

        const positions = getPositionDataForSpecificAccount(
          this.props.assets?.data || [],
          posData,
          this.props.firmAccounts?.data?.data,
          acc
        );

        this.setState({
          account: acc,

          positions
        });
      },
      errorCallback: data => {
        console.log('querypos error data', data);
      }
    });
  };

  renderDetailsForm = () => {
    const assets = this.getAllOrFilteredAssets();
    const assetOptions = parseObjectWithSamePropertyForDropdown(assets, 'security');

    const selectedAsset = getSelectedOption(assetOptions, this.state.asset);

    return (
      <>
        <div className="deposit-input-field-full-width">
          <p className="system-form-control-placeholder">Account</p>
          <SearchAccountsInput
            ignoreAccounts={[]}
            onAccountSelected={this.setSelectedAcc}
            selectedAccountId={this.state.account}
          />
        </div>
        <div className="deposit-input-field-full-width">
          <p className="system-form-control-placeholder"> {i18next.t('depositRedeem.amount')}</p>
          <div className="system-form-control-box-custom sfcb-ammount sfcb-transparent">
            <Select
              className="deposit-form-select-blue"
              placeholder={i18next.t('depositRedeem.placeholder')}
              classNamePrefix="deposit-asset"
              isClearable={true}
              isSearchable={true}
              name="asset"
              options={assetOptions}
              onChange={this.handleSelect}
              value={selectedAsset}
              formatOptionLabel={this.composeAssetOptionComponent}
            />
            <VLInput
              type="text"
              name="amount"
              value={this.state.amountObject.display}
              onChange={this.handleInputChange}
              placeholder=""
              readOnly={!this.state.asset}
              pattern="^\d+(\.\d*)*$"
              error={this.state.amountObject.error && this.state.amountObject.display > 0}
              noBorder
              hasBackground
              width="80%"
            />
          </div>
        </div>
        {this.state.amountObject.error &&
          this.state.amountObject.display !== 0 &&
          this.state.transactionType !== DEPOSIT_TYPE && (
            <div className="system-form-control-box">
              <span className="system-form-control-error-text">{i18next.t('depositRedeem.eror-text')}</span>
            </div>
          )}
        {this.state.transactionType !== DEPOSIT_TYPE && this.state.asset && (
          <div className="system-form-control-box sfcb-amount-transfer-info">
            <div className="placeholder">{i18next.t('depositRedeem.totalAmount')}</div>
            <div className="sfcb-amount-number">
              {getTotalBalanceFormatting(this.state.positions?.VLN?.[this.state.asset], this.state?.asset)}
            </div>
          </div>
        )}
        <div className="deposit-input-field-full-width">
          <p className="system-form-control-placeholder profile-details-container">
            {i18next.t('depositRedeem.privateDesc')}
          </p>
        </div>
        <div className="system-form-control-box sfcb-transparent">
          <VLInput
            type="text"
            maxLength={128}
            name="description"
            value={this.state.description}
            onChange={this.handleFormControlChangeDesc}
          />
        </div>
        <ListTransactionsContainer accountNumber={this.state.account} firm={this.props.match.params.id} maxLength={3} />
      </>
    );
  };

  renderSummary = () => (
    <DepositRedeemSummary
      {...this.state}
      accountData={getAccountData(
        this.state.account,
        this.state.isSuperAdminView ? this.props.firmAccounts.data.data : this.props.accounts.data
      )}
    />
  );

  render() {
    const { account, asset, amountObject } = this.state;
    const isDetailsDisplay = this.state.currentScreen === TRANSACTION_SCREENS.DETAILS;
    const isConfirmScreen = this.state.currentScreen === TRANSACTION_SCREENS.CONFIRM;
    const isSuccessScreen = this.state.currentScreen === TRANSACTION_SCREENS.SUCCESS;
    const isLoading = !this.props.assets?.loaded && !this.props.accounts?.loaded;

    const disabled = account && asset && amountObject.value && !amountObject.error ? false : true;
    let title = '';
    let titleConfirm = '';
    if (this.state.transactionType === 'deposit') {
      title = i18next.t('depositRedeem.deposit');
      titleConfirm = i18next.t('transactionButtonsBar.deposit');
    }
    if (this.state.transactionType === 'withdraw') {
      title = i18next.t('depositRedeem.withdraw');
      titleConfirm = i18next.t('transactionButtonsBar.withdraw');
    }

    return (
      <LoadingWrapper isLoading={isLoading}>
        <div className="deposit-container">
          {isSuccessScreen ? (
            <TransactionSuccessScreen
              type={this.state.transactionType}
              amount={this.state.amountObject.display}
              asset={this.state.asset}
              history={this.props.history}
              requestID={this.state.transactionID}
              specialRedirectFunction={this.state.isSuperAdminView ? this.redirectBackToFirmView : null}
              notStandalone
            />
          ) : (
            <>
              <Row className="deposit-header">
                <MdKeyboardBackspace onClick={this.handleBackButton} className="deposit-header-item" />
                <span className="deposit-header-item" onClick={this.handleBackButton}>
                  {title}
                </span>
              </Row>
              <Container fluid className="deposit-body-container">
                <Row noGutters className="deposit-body-top-container">
                  <RoundButton
                    isActive={isDetailsDisplay}
                    onClick={() => this.switchScreen(TRANSACTION_SCREENS.DETAILS)}
                    number={1}
                    text={i18next.t('depositRedeem.details')}
                  />
                  <RoundButton
                    isActive={!isDetailsDisplay}
                    // isDisabled={true}
                    number={2}
                    text={i18next.t('depositRedeem.confirm')}
                  />
                </Row>
                <div className="info-text">{i18next.t('depositRedeem.enter')}</div>
                {isDetailsDisplay ? this.renderDetailsForm() : this.renderSummary()}
                <div className="sfcb-button-wrapper">
                  {isDetailsDisplay && (
                    <>
                      <VLButton
                        width="100%"
                        disabled={disabled || this.state.amountObject.display == 0}
                        onClick={() => this.switchScreen(TRANSACTION_SCREENS.CONFIRM)}
                        text={i18next.t('depositRedeem.next')}
                      />
                    </>
                  )}
                  {isConfirmScreen && (
                    <React.Fragment>
                      <div
                        style={{
                          display: 'inline-flex',
                          justifyContent: 'center'
                        }}
                      >
                        <VLButton
                          size="l"
                          variant="outline"
                          onClick={() => this.switchScreen(TRANSACTION_SCREENS.DETAILS)}
                          text={i18next.t('depositRedeem.back')}
                        />
                      </div>

                      <VLButton
                        width="100%"
                        onClick={this.submitForm}
                        text={i18next.t('depositRedeem.confirm') + ' ' + titleConfirm.toLowerCase()}
                      />
                    </React.Fragment>
                  )}
                </div>
              </Container>
            </>
          )}
        </div>
      </LoadingWrapper>
    );
  }
}

export default DepositRedeem;
