import { Container, Modal, Row } from 'react-bootstrap';
import {
  DEPOSIT_TYPE,
  ESCROW_TYPE,
  REQUEST_TYPE,
  SEND_TYPE,
  WITHDRAW_TYPE,
  STAKE,
  EXIT_STAKE,
  NEGOTIATION_TYPE
} from '../../enums/validation';
import React, { PureComponent } from 'react';

import { MdDoneAll } from 'react-icons/md';
import { findFiatCurrencySymbol } from '../../utils/generalUtils';
import i18next from 'i18next';
import VLButton from '../Buttons/VLButton';
class TransactionSuccessScreen extends PureComponent {
  getSpecificData = () => {
    switch (this.props.type) {
      case DEPOSIT_TYPE:
        return {
          middleRow: (
            <Row className="summary-big-text summary-darker-text">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.asset)} {this.props.asset} {this.props.amount}
              </div>
              {this.props.counterparty && (
                <>
                  <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.to')}</span>
                  <div className="external-deposit-h1-label">{this.props.counterparty}</div>
                </>
              )}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.deposit'),
          lastRowStart: i18next.t('transactionSuccessScreen.deposits')
        };
      case WITHDRAW_TYPE:
        return {
          middleRow: (
            <Row className="summary-big-text summary-darker-text">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.asset)} {this.props.asset} {this.props.amount}
              </div>
              {this.props.counterparty && (
                <>
                  <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.from')}</span>
                  <div className="external-deposit-h1-label">{this.props.counterparty}</div>
                </>
              )}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.withdrawal'),
          lastRowStart: i18next.t('transactionSuccessScreen.withdrawals')
        };
      case SEND_TYPE:
        return {
          middleRow: (
            <Row className="ed-transaction-wrapper">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.asset)} {this.props.asset} {this.props.amount}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.to')}</span>
              <div className="external-deposit-h1-label">{this.props.counterparty}</div>
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.transfer'),
          lastRowStart: i18next.t('transactionSuccessScreen.Transfer')
        };
      case REQUEST_TYPE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {this.props.asset || this.props.sendAsset} {this.props.amount || this.props.sendAmount}
              </div>
              {this.props.counterparty && (
                <>
                  <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.to')}</span>
                  <div className="external-deposit-h1-label">{this.props.counterparty}</div>
                </>
              )}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.request'),
          lastRowStart: i18next.t('transactionsFilters.requests')
        };
      case ESCROW_TYPE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.sendAsset)} {this.props.sendAmount} {this.props.sendAsset}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.for')}</span>
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.receiveAsset)} {this.props.receiveAmount} {this.props.receiveAsset}
              </div>
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.escrow'),
          lastRowStart: i18next.t('transactionSuccessScreen.escrow')
        };

      case NEGOTIATION_TYPE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.amount)} {this.props.amount} {this.props.asset}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.for')}</span>
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.counterpartyAsset)} {this.props.counterpartyAmount}{' '}
                {this.props.counterpartyAsset}
              </div>
            </Row>
          ),
          transferType:
            this.props.side === 'B'
              ? i18next.t('transactionSuccessScreen.negotiatedBuy')
              : i18next.t('transactionSuccessScreen.negotiatedSell'),
          lastRowStart: i18next.t('transactionSuccessScreen.negotiation')
        };
      case STAKE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.asset)} {this.props.amount} {this.props.asset}
              </div>
            </Row>
          )
        };
      case EXIT_STAKE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(this.props.asset)} {this.props.amount} {this.props.asset}
              </div>
            </Row>
          )
        };
      default:
        return {
          middleRow: (
            <>
              <Row className="ed-transaction-wrapper">
                <div className="external-deposit-h1-label">
                  {findFiatCurrencySymbol(this.props.asset)} {this.props.amount} {this.props.asset}
                </div>
                <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.for')}</span>
                <div className="external-deposit-h1-label">
                  {findFiatCurrencySymbol(this.props.counterpartyAsset)} {this.props.counterpartyAmount}{' '}
                  {this.props.counterpartyAsset}
                </div>
              </Row>
            </>
          ),
          transferType: i18next.t('transactionSuccessScreen.Escrow'),
          lastRowStart: i18next.t('transactionSuccessScreen.Escrow')
        };
    }
  };

  buildTransactionSummary = () => {
    const { middleRow, transferType, lastRowStart } = this.getSpecificData();

    return this.props.type === 'stake' || this.props.type === EXIT_STAKE ? (
      <>
        <div className="ed-pretitle">
          {i18next.t('transactionSuccessScreen.ed-pretitle') + ' ' + i18next.t('transactionSuccessScreen.submitted')}{' '}
          {this.props.type === 'stake'
            ? i18next.t('transactionSuccessScreen.stake')
            : i18next.t('transactionSuccessScreen.exitStake')}{' '}
          {this.props.type === STAKE
            ? i18next.t('transactionSuccessScreen.of')
            : i18next.t('transactionSuccessScreen.exitStakeRequest')}
        </div>
        <div className="ed-success">{middleRow}</div>
      </>
    ) : (
      <>
        <div className="ed-pretitle">
          {i18next.t('transactionSuccessScreen.ed-pretitle') + ' '}
          {this.props.isAccepting
            ? i18next.t('transactionSuccessScreen.accepted')
            : i18next.t('transactionSuccessScreen.submitted')}
          {' ' +
            i18next.t('transactionSuccessScreen.a') +
            ` ${transferType} ` +
            i18next.t('transactionSuccessScreen.of')}
        </div>
        <div className="ed-success">{middleRow}</div>
        {!this.props.noSummary && (
          <Row className="transaction-success-disclaimer summary-label-text">
            {`${lastRowStart} ` + i18next.t('transactionSuccessScreen.submittedAndApproved')}
          </Row>
        )}
      </>
    );
  };

  redirectToDashboard = () => {
    this.props.resetTransactionHash();
    this.props.history.push('/');
  };

  buildModalWrapper = children => {
    return (
      <Modal
        className="common-modal"
        centered
        show
        onHide={this.props.onCloseModal}
        backdrop="static"
        animation={this.props.notStandalone ? true : false}
      >
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  };

  render() {
    const content = (
      <Container className="transaction-success-container">
        <Row className="transaction-success-icon-container">
          <MdDoneAll size="3em" />
        </Row>
        {this.buildTransactionSummary()}
        <Row className="transaction-success-disclaimer summary-label-text">
          {/* //{i18next.t('TransactionSuccessScreen.viewDetails')} */}
        </Row>
        <Row className="summary-button-wrapper">
          <VLButton
            size="l"
            onClick={this.props.specialRedirectFunction ? this.props.specialRedirectFunction : this.redirectToDashboard}
            text={i18next.t('transactionSuccessScreen.back-to') + i18next.t('transactionSuccessScreen.dashboard')}
          />
        </Row>
        <Row className="summary-label-text">
          <span className="ed-row-label">{i18next.t('transactionSuccessScreen.requestId')}</span>{' '}
          <span className="summary-darker-text">{this.props.requestID || ''}</span>
        </Row>
      </Container>
    );
    if (this.props.notStandalone) {
      return content;
    } else {
      return this.buildModalWrapper(content);
    }
  }
}

export default TransactionSuccessScreen;
