import { Modal, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import {
  AMOUNT_REGEX,
  NEGOTIATION_TYPE,
  SECURITY_CODES,
  SECURITY_CODE_NAME_MAP,
  ZERO_ASSET_FORMAT
} from '../../../enums/validation';
import {
  flipSide,
  getDecimalByAsset,
  getSeparatedAssetsFromConcatenatedPair
} from '../../../utils/transactionsHelpers';
import { onlyNumbers, validateAmount } from '../../../utils/validators';
import { toast } from 'react-toastify';
import Select from 'react-select';
import i18next from 'i18next';
import {
  replaceCommas,
  getLocalisedNumericString,
  getSelectedOption,
  parseObjectWithSamePropertyForDropdown,
  moneyFormat,
  extractAssetsFromSecurityCode
} from '../../../utils/generalUtils';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { Col } from 'react-bootstrap';
import TransactionSuccessScreen from '../../TransactionSuccessScreen';
import LoadingWrapper from '../../LoadingWrapper/LoadingWrapper';
import { TRANSACTION_API_TYPES } from '../../../enums/validation';
import '../../../components/TradeOrder/TradeOrder.scss';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import SvgIcon from '../../SvgIcon';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput/VLInput';

const initialErrorState = {
  assetPairError: '',
  quantityError: '',
  pricePerAssetError: '',
  generalError: ''
};
const TIF_OPTIONS = Object.freeze([
  { label: 'GTC', value: 'GTC' },

  { label: 'GTT', value: 'GTT' }
]);
const EXP_TIME_OPTIONS = Object.freeze([
  { label: '30 s', value: 30 },
  { label: '60 s', value: 60 },
  { label: '90 s', value: 90 },
  { label: '120 s', value: 120 },
  { label: 'Custom', value: 'custom' }
]);
const MODES = {
  CREATE: 'CREATE',
  COUNTER: 'COUNTER',
  VIEW: 'VIEW'
};

const parseCurrency = value => {
  if (!value) return 0;

  return parseFloat(replaceCommas(value));
};

class IOIModal extends PureComponent {
  state = {
    ...initialErrorState,
    asset: this.extractAssets()?.base,
    quantity: '',
    quantityDecimal: '',
    pricePerAssetSecurity: this.extractAssets()?.quote,
    pricePerAsset: '',
    pricePerAssetDecimal: '',
    side: 'B',
    assetPair: this.props?.transaction?.security,
    totalAmount: '',
    transactionID: '',
    mode: MODES.CREATE,
    isLoading: false,
    duration: EXP_TIME_OPTIONS[0],
    expTime: null,
    expTimeError: null,
    tif: TIF_OPTIONS[0]
  };
  handleTifChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleExpTime(e) {
    this.setState({
      expTime: e === null ? moment().set({ hours: '0', minutes: '0', seconds: '0' }) : e,
      expTimeError: null
    });
  }

  extractAssets() {
    return extractAssetsFromSecurityCode(this.props?.transaction?.security);
  }

  composeAssetPairOptionComponent = ({ value, label }) => {
    const assets = this.props.assetsPairs.data;
    const assetData = getSeparatedAssetsFromConcatenatedPair(value, assets);

    return (
      <>
        <div className="deposit-withdraw-currency-text">
          <SvgIcon
            className="deposit-withdraw-currency-icon"
            path={`crypto/${assetData?.pair_first.toLowerCase()}.svg`}
            alt={assetData?.pair_first}
          />
          {assetData?.pair_first} -<span>{SECURITY_CODE_NAME_MAP[assetData?.pair_first]}</span>
          &nbsp; / &nbsp;
          <SvgIcon
            className="deposit-withdraw-currency-icon"
            path={`crypto/${assetData?.pair_second.toLowerCase()}.svg`}
            alt={assetData?.pair_second}
          />
          {assetData?.pair_second} -<span>{SECURITY_CODE_NAME_MAP[assetData?.pair_second]}</span>
        </div>
      </>
    );
  };
  composeAssetOptionComponent = ({ value, label }) => {
    if (!value) {
      return;
    }
    const assets = this.props.assets.data;
    const assetData = getSeparatedAssetsFromConcatenatedPair(value, assets);

    return (
      <>
        <div className="deposit-withdraw-currency-text">
          <SvgIcon className="deposit-withdraw-currency-icon" path={`crypto/${value?.toLowerCase()}.svg`} alt={label} />
          {label} -<span>{SECURITY_CODE_NAME_MAP[assetData?.security] || assetData?.security}</span>
        </div>
      </>
    );
  };
  componentDidMount() {
    this.props.getUserProfile();
    this.props.getAssetPairs();

    const transaction = this.props.transaction;

    if (!transaction) {
      this.setState({
        mode: MODES.CREATE
      });
      return;
    }

    // set initial input values for existing transaction
    if (transaction) {
      this.setState({
        mode: transaction.inbound ? MODES.COUNTER : MODES.VIEW,
        side: transaction.inbound ? flipSide(transaction.side) : transaction.side
      });

      this.formatValues(
        transaction.qty,
        'quantity',
        getDecimalByAsset(this.props.assets.data, transaction.security.slice(0, 3))
      );
      this.formatValues(
        transaction.price,
        'pricePerAsset',
        getDecimalByAsset(this.props.assets.data, transaction.security.slice(3, 6))
      );
    }
  }

  handleInputChange = ({ target }) => {
    if (AMOUNT_REGEX.test(target.value)) {
      this.setState({
        [target.name]: moneyFormat(replaceCommas(target.value))
      });
    }
  };

  validatePriceAndAmount = () => {
    const accountNumber = this.props.userData?.restricted_attr?.access_list[0].account;
    let hasError = false;

    if (!this.state.quantity || parseFloat(this.state.quantity) === 0) {
      this.setState({
        quantityError: 'Please enter desired amount'
      });
      hasError = true;
    }

    if (!this.state.pricePerAsset || parseFloat(this.state.pricePerAsset) === 0) {
      this.setState({
        pricePerAssetError: 'Please enter desired price'
      });
      hasError = true;
    }

    if (hasError) {
      return false;
    }

    let qtyValidationObject = validateAmount(
      replaceCommas(this.state.quantity),
      this.props.positions?.[accountNumber]?.[this.state.asset],
      this.state.quantityDecimal,
      this.state.side === 'B',
      this.state.asset,
      this.props.cryptoRates
    );

    let priceValidationObject = validateAmount(
      (parseCurrency(this.state.quantity) * parseCurrency(this.state.pricePerAsset)).toString(),
      this.props.positions?.[accountNumber]?.[this.state.pricePerAssetSecurity],
      this.state.pricePerAssetDecimal,
      this.state.side === 'S',
      this.state.pricePerAssetSecurity,
      this.props.cryptoRates
    );

    this.setState({
      quantityError: qtyValidationObject.error ? 'The amount is more than available balance' : false,
      pricePerAssetError: priceValidationObject.error ? 'The amount is more than available balance' : false
    });

    return !qtyValidationObject.error && !priceValidationObject.error;
  };

  handleSelect = (option, fieldName) => {
    const assets = this.props.assetsPairs.data;
    const assetData = assets?.find(asset => asset.security === option.value);
    this.setState(
      {
        [fieldName]: option.value,
        asset: assetData.pair_first,
        pricePerAssetSecurity: assetData.pair_second,
        pricePerAssetDecimal: getDecimalByAsset(this.props.assets.data, assetData.pair_second),
        pricePerAsset: ZERO_ASSET_FORMAT[assetData.pair_second],
        quantity: ZERO_ASSET_FORMAT[assetData.pair_first],
        quantityDecimal: getDecimalByAsset(this.props.assets.data, assetData.pair_first)
      },
      () => {
        if (this.state.pricePerAsset) {
          this.formatValues(this.state.pricePerAsset, 'pricePerAsset', this.state.pricePerAssetDecimal);
        }
      }
    );
  };

  validateTotalAmount = () => {
    if (this.state.totalAmount === ZERO_ASSET_FORMAT[this.state.pricePerAssetSecurity]) {
      this.setState({
        generalError: `Total amount can't be ${ZERO_ASSET_FORMAT[this.state.pricePerAssetSecurity]}`
      });
      return false;
    }
    return true;
  };

  validateForm = () => {
    const { assetPair } = this.state;

    if (!assetPair) {
      this.setState({ assetPairError: 'Please select asset pair' });
      return false;
    }

    if (!this.validatePriceAndAmount()) {
      return false;
    }

    if (!this.validateTotalAmount()) {
      return false;
    }
    if (
      (this.state.tif.value === 'GTT' && !this.state.expTime) ||
      (this.state.tif.value === 'GTT' &&
        this.state.expTime.format('hh:mm:ss') ===
          moment().set({ hours: '0', minutes: '0', seconds: '0' }).format('hh:mm:ss'))
    ) {
      this.setState({ expTimeError: 'Please choose expire time' });
      return false;
    }

    return true;
  };

  successTrigger = data => {
    toast.success(i18next.t('toasts.success'));
    this.setState({
      transactionID: data.refno,
      isLoading: false
    });
  };

  errorTrigger = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.result}`);
    this.setState({
      isLoading: false
    });
  };

  handleCounter = () => {
    if (!this.validateForm()) return;
    const expTime = new Date().setHours(
      new Date().getHours() + parseInt(this.state.expTime?.get('hours')),
      new Date().getMinutes() + parseInt(this.state.expTime?.get('minutes')),
      new Date().getSeconds() + parseInt(this.state.expTime?.get('seconds')),
      0
    );
    const accountNumber = this.props.userData?.restricted_attr?.access_list[0].account;
    let data = {
      socket: this.props.websocket,
      refno: this.props.transaction.refno,
      security: this.state.assetPair,
      userId: accountNumber,
      side: this.state.side,
      qty: onlyNumbers(this.state.quantity),
      price: onlyNumbers(this.state.pricePerAsset),
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger,
      exptime: this.state.tif.value === 'GTT' && expTime
    };

    this.setState({ isLoading: true });
    this.props.makeCounterOrderRequest(data);
  };

  handleConfirm = () => {
    if (!this.validateForm()) return;

    const accountNumber = this.props.userData?.restricted_attr?.access_list[0].account;

    // REACT_APP_IOI_ACCOUNT is considered a bot and creating orders is not permitted
    if (accountNumber === process.env.REACT_APP_IOI_ACCOUNT) {
      return;
    }

    let data = {
      socket: this.props.websocket,
      userId: accountNumber,
      recipientAccount: process.env.REACT_APP_IOI_ACCOUNT, // IOI requests always target market maker
      pair: this.state.assetPair,
      side: this.state.side,
      asset: this.state.asset,
      quantity: onlyNumbers(this.state.quantity),
      price: onlyNumbers(this.state.pricePerAsset),
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger
    };

    this.setState({ isLoading: true });
    this.props.makeIOIRequest(data);
  };

  resetErrorState = () => {
    this.setState({ ...initialErrorState });
  };
  getAvailableTotal = asset => {
    const accountNumber = this.props.userData?.restricted_attr?.access_list[0].account;

    const value = this.props.positions?.[accountNumber]?.[asset] || 0;

    return getLocalisedNumericString(value, true, getDecimalByAsset(this.props.assets.data, asset));
  };
  getTotalAmount = () => {
    if (this.state.quantity && this.state.pricePerAsset) {
      const totalAmount = getLocalisedNumericString(
        replaceCommas(this.state.quantity) * replaceCommas(this.state.pricePerAsset),
        true,
        getDecimalByAsset(this.props.assets.data, this.state.pricePerAssetSecurity)
      );
      this.setState({
        totalAmount: totalAmount
      });
    }
  };
  onChangeSide = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  formatValues = (value, name, maxDecimal) => {
    let validationObject = validateAmount(replaceCommas(value), null, maxDecimal, false);

    this.setState({
      [name]: moneyFormat(validationObject.value),
      [`${name}Decimal`]: maxDecimal
    });
  };

  onCloseModal = () => {
    this.props.toggleModal(null);
  };

  handleCancelOrder = () => {
    this.setState({
      isLoading: true
    });
    const transaction = this.props.transaction;
    const data = {
      type: transaction.type.toLowerCase() === TRANSACTION_API_TYPES.IOI ? 'cancelorder' : 'declineorder',
      security: transaction.security,
      refno: transaction.refno,
      socket: this.props.websocket,
      userid: transaction.brokers,
      successCallback: () => {
        this.props.rejectPaymentRequestSuccess(transaction);
        toast.success(i18next.t('toasts.canceled'));
        this.setState({
          isLoading: false
        });
        this.onCloseModal();
      },
      errorCallback: () => {
        toast.error(i18next.t('toasts.error'));
        this.setState({
          isLoading: false
        });
      }
    };
    this.props.rejectPaymentRequest(data);
  };

  handleAcceptOrder = () => {
    const accountNumber = this.props.userData?.restricted_attr?.access_list[0].account;
    const transaction = this.props.transaction;
    this.setState({
      isLoading: true
    });
    const data = {
      security: transaction.security,
      refno: transaction.refno,
      socket: this.props.websocket,
      qty: transaction.qty,
      price: transaction.price,
      side: transaction.side === 'S' ? 'B' : 'S',
      userid: accountNumber,
      successCallback: data => {
        this.setState(
          {
            isLoading: false
          },
          () => {
            toast.success('Order accepted successfully');
            this.props.acceptPaymentRequestSuccess(transaction);
            this.props.updateTransactionsDB({
              ref: transaction.refno,
              type: 'ioi',
              cryptoRates: this.props.cryptoRates.data
            });
            this.onCloseModal();
          }
        );
      },
      errorCallback: () => {
        toast.error(i18next.t('toasts.error'));
        this.setState({
          isLoading: false
        });
      }
    };

    this.props.acceptPaymentRequest(data);
  };
  handleTimeChange = time => {
    this.setState({
      expTime: time.target.value
    });
  };

  didUserChangeAmounts = () => {
    const transaction = this.props.transaction;
    if (!transaction) return false;

    const oldQty = parseFloat(transaction.qty);
    const oldPrice = parseFloat(transaction.price);
    const newQty = parseFloat(onlyNumbers(this.state.quantity || 0));
    const newPrice = parseFloat(onlyNumbers(this.state.pricePerAsset || 0));

    if (oldQty !== newQty || oldPrice !== newPrice) return true;
    return false;
  };

  render() {
    if (!this.props.assets || !this.props.assetsPairs) return null;

    const {
      quantity,
      pricePerAsset,
      balanceError,
      asset,
      pricePerAssetSecurity,
      assetPair,
      totalAmount,
      assetPairError,
      quantityError,
      pricePerAssetError,
      generalError
    } = this.state;
    this.getTotalAmount();
    const assetPairOptions = parseObjectWithSamePropertyForDropdown(this.props.assetsPairs.data, 'security');
    const side = this.state.side === 'B' ? 'Buy' : 'Sell';

    const selectedAssetPair = getSelectedOption(assetPairOptions, assetPair?.value || assetPair);

    const canEditValues = this.state.mode === MODES.COUNTER || this.state.mode === MODES.CREATE;

    const isLoading = this.state.isLoading || this.props.assetsPairs.loading;
    const isExecuteDisabled = this.didUserChangeAmounts();
    const isCounterDisabled = !this.didUserChangeAmounts();

    return (
      <Modal className="common-modal" centered show onHide={this.onCloseModal} backdrop="static">
        <ModalHeader closeButton className="header">
          {this.props.transaction?.type === 'IOI' ? 'IOI' : `Negotiated ${side} Order`}
        </ModalHeader>
        <LoadingWrapper isLoading={isLoading}>
          {this.state.transactionID ? (
            <ModalBody>
              <TransactionSuccessScreen
                type={NEGOTIATION_TYPE}
                amount={quantity}
                asset={asset}
                side={this.state.side}
                counterpartyAmount={this.state.pricePerAsset}
                counterpartyAsset={this.state.pricePerAssetSecurity}
                requestID={this.state.transactionID}
                specialRedirectFunction={this.onCloseModal}
                notStandalone
                noSummary
              />
            </ModalBody>
          ) : (
            <>
              <ModalBody>
                {this.state.mode === MODES.CREATE && (
                  <Row style={{ height: '50px' }}>
                    <Col>
                      <div className="div-input-radio">
                        <input
                          className="input-radio"
                          value={'B'}
                          name="side"
                          onChange={e => this.onChangeSide(e)}
                          type="radio"
                          checked={this.state.side === 'B'}
                        />
                        <label className="label-input-radio" htmlFor="flexCheckDefault">
                          Buy
                        </label>
                      </div>
                    </Col>
                    <Col>
                      {' '}
                      <div className={`div-input-radio${this.state.side === 'S' ? ' selected' : ''}`}>
                        <input
                          onChange={e => this.onChangeSide(e)}
                          className="input-radio"
                          value={'S'}
                          name="side"
                          size={'30px'}
                          type="radio"
                          checked={this.state.side === 'S'}
                        />{' '}
                        <label className="label-input-radio" htmlFor="flexCheckDefault">
                          Sell
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}

                <div>
                  <div className="exchange-asset-balance">
                    <p className="external-deposit-title-label exchange-label">Select asset pair</p>
                    {assetPairError && <span className="system-form-control-error-text">{assetPairError}</span>}
                  </div>
                  <div
                    className={`system-form-control-box exchange-input-wrapper ${
                      assetPairError ? 'system-form-control-box--error' : ''
                    }`}
                  >
                    <Select
                      components={{ ClearIndicator: null }}
                      placeholder={i18next.t('exchangeDetailsForm.select')}
                      className="exchange-dropdown-asset-pair"
                      classNamePrefix="deposit-asset"
                      isClearable={true}
                      isSearchable={true}
                      name="assetPair"
                      isDisabled={this.state.mode !== MODES.CREATE}
                      options={assetPairOptions}
                      onChange={option => this.handleSelect(option, 'assetPair')}
                      value={selectedAssetPair}
                      formatOptionLabel={option => this.composeAssetPairOptionComponent(option)}
                    />
                  </div>

                  <React.Fragment>
                    <div className="exchange-asset-balance">
                      <p className="external-deposit-title-label exchange-label">
                        {'Price per asset to ' + side.toLowerCase()}
                      </p>
                      {pricePerAssetError && (
                        <React.Fragment>
                          <span className="system-form-control-error-text">{pricePerAssetError}</span>{' '}
                        </React.Fragment>
                      )}
                      {pricePerAssetSecurity && (
                        <span style={{ float: 'right' }}>
                          {' '}
                          {`${pricePerAssetSecurity} ` +
                            i18next.t('exchangeDetailsForm.balance') +
                            ` = ${this.getAvailableTotal(pricePerAssetSecurity)} ${
                              pricePerAssetSecurity === SECURITY_CODES.USD ? '$' : ''
                            }`}
                        </span>
                      )}
                    </div>

                    <div
                      className={`system-form-control-box exchange-input-wrapper ${
                        pricePerAssetError ? 'system-form-control-box--error' : ''
                      }`}
                    >
                      <Select
                        components={{ DropdownIndicator: null }}
                        placeholder={i18next.t('exchangeDetailsForm.select')}
                        className="exchange-dropdown"
                        classNamePrefix="deposit-asset"
                        isDisabled={true}
                        value={{
                          value: pricePerAssetSecurity,
                          label: pricePerAssetSecurity
                        }}
                        formatOptionLabel={() =>
                          this.composeAssetOptionComponent({
                            value: pricePerAssetSecurity,
                            label: pricePerAssetSecurity
                          })
                        }
                      />
                      <VLInput
                        onBlur={() =>
                          this.formatValues(pricePerAsset, 'pricePerAsset', this.state.pricePerAssetDecimal)
                        }
                        type="text"
                        name="pricePerAsset"
                        value={pricePerAsset}
                        onChange={e => this.handleInputChange(e)}
                        maxLength="20"
                        disabled={!canEditValues}
                        autoComplete="off"
                        noBorder
                        size="m"
                      />
                    </div>

                    <div className="exchange-asset-balance">
                      <p className="external-deposit-title-label exchange-label">{'Amount to ' + side.toLowerCase()}</p>
                      {balanceError && (
                        <span style={{ float: 'left' }} className="system-form-control-error-text">
                          {balanceError} {asset === SECURITY_CODES.USD ? '$' : ''}
                        </span>
                      )}
                      {quantityError && (
                        <span style={{ float: 'left' }} className="system-form-control-error-text">
                          {quantityError}
                        </span>
                      )}
                      {asset && (
                        <span style={{ float: 'right' }}>
                          {' '}
                          {`${asset} ` +
                            i18next.t('exchangeDetailsForm.balance') +
                            ` = ${this.getAvailableTotal(asset)} ${asset === SECURITY_CODES.USD ? '$' : ''}`}
                        </span>
                      )}
                    </div>

                    <div
                      className={`system-form-control-box exchange-input-wrapper ${
                        balanceError || quantityError ? 'system-form-control-box--error' : ''
                      }`}
                    >
                      <Select
                        components={{ DropdownIndicator: null }}
                        className="exchange-dropdown"
                        classNamePrefix="deposit-asset"
                        name="sendAsset"
                        isDisabled={true}
                        value={{
                          value: asset,
                          label: asset
                        }}
                        formatOptionLabel={() =>
                          this.composeAssetOptionComponent({
                            value: asset,
                            label: asset
                          })
                        }
                      />

                      <VLInput
                        onBlur={() => this.formatValues(quantity, 'quantity', this.state.quantityDecimal)}
                        type="text"
                        name="quantity"
                        value={quantity}
                        onChange={e => this.handleInputChange(e, 'send')}
                        maxLength="20"
                        disabled={!canEditValues}
                        autoComplete="off"
                        noBorder
                        size="m"
                        error={quantityError || balanceError}
                      />
                    </div>

                    <div>
                      {pricePerAsset && quantity && (
                        <span className={`span-total ${generalError ? 'ioi-error' : ''}`}>
                          {'Approximate total: ' + totalAmount + ' ' + pricePerAssetSecurity}
                        </span>
                      )}
                      {this.props.transaction && !isCounterDisabled && (
                        <div className="expire-time-section">
                          <div className="select-wrapper">
                            <label className="tif-label">TIF:</label>
                            <Select
                              aria-labelledby="tif"
                              classNamePrefix="order-select-ioi-modal"
                              name="tif"
                              options={TIF_OPTIONS}
                              onChange={value => this.handleTifChange('tif', value)}
                              value={this.state.tif}
                              isOptionDisabled={false}
                              menuPlacement="auto"
                            />
                          </div>
                          {this.state.tif?.value === 'GTT' && (
                            <div>
                              <p className="system-form-control-label">Expires in</p>
                              <TimePicker
                                value={
                                  !this.state.expTime
                                    ? moment().set({
                                        hours: '0',
                                        minutes: '0',
                                        seconds: '0'
                                      })
                                    : this.state.expTime
                                }
                                onChange={e => this.handleExpTime(e)}
                                showSecond={true}
                                allowEmpty
                              />
                            </div>
                          )}
                          {this.state?.expTimeError ? (
                            <span className="system-form-control-error-text">{this.state?.expTimeError}</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                </div>
              </ModalBody>
              {this.state.mode === MODES.COUNTER && (
                <ModalFooter className="modal-footer">
                  <VLButton
                    variant={isExecuteDisabled ? 'outline' : ''}
                    onClick={this.handleAcceptOrder}
                    disabled={isExecuteDisabled}
                    text="Execute"
                  />

                  <VLButton
                    variant={isCounterDisabled ? 'outline' : ''}
                    onClick={this.handleCounter}
                    disabled={isCounterDisabled}
                    text="Counter"
                  />

                  <VLButton variant="danger" onClick={this.handleCancelOrder} text="Decline" />
                </ModalFooter>
              )}
              {this.state.mode === MODES.VIEW && (
                <ModalFooter className="modal-footer">
                  <VLButton variant="danger" onClick={this.handleCancelOrder} text="Cancel" />
                </ModalFooter>
              )}
            </>
          )}
        </LoadingWrapper>
      </Modal>
    );
  }
}

export default IOIModal;
