const InfiniteScroll = ({ loadMore, children, className = '' }) => {
  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && e.target.scrollTop > 0) {
      loadMore();
    }
  };

  return (
    <div className={className} onScroll={handleScroll}>
      {children}
    </div>
  );
};

export default InfiniteScroll;
