import { Button, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';

class RoundButton extends PureComponent {
  render() {
    return (
      <Button
        // size={`${this.props.size || 'lg'}`}
        className={`round-button-base ${this.props.isActive ? 'round-button-active' : 'round-button'} ${
          this.props.transparent ? 'round-button-no-bg' : ''
        }
        ${this.props.smaller ? 'round-button-smaller-width' : ''}`}
        onClick={() => {
          this.props.onClick();
        }}
        disabled={this.props.isDisabled ? this.props.isDisabled : false}
      >
        <Row noGutters className="round-button-row">
          {!this.props.rightBubble && <div className="number">{this.props.number}</div>}
          <div className="text">{this.props.text}</div>
          {this.props.rightBubble && <div className="number">{this.props.number}</div>}
        </Row>
      </Button>
    );
  }
}

export default RoundButton;
