import Request from './Request';
import axiosCtsApiRequest from '../utils/axiosCtsApiRequest';
import axiosApiRequest from '../utils/axiosApiRequest';
import axiosUsdApiRequest from '../utils/axiosUsdApiRequest';
import { call } from 'redux-saga/effects';

/**
 * Axios Request implementation. Used to encapsulate the logic behind formatting
 * Request object parameters into valid Axios request calls. Other implementations
 * should override the `api` constructor parameter to provide access to different
 * backend APIs.
 *
 * @abstract
 */
class AxiosRequest extends Request {
  constructor(api) {
    super(api);
    if (this.constructor === AxiosRequest) {
      throw new TypeError('Abstract class "AxiosRequest" cannot be instantiated directly.');
    }
  }

  toPromise() {
    const config = {
      headers: this.headers
    };

    const response = this._fn.call(this.fn, this.method.value, this.url, this.data, config);

    return this._mappers.reduce((acc, fn) => acc.then(fn), response);
  }

  *toEffect() {
    const config = {
      headers: this.headers
    };

    const response = yield call(this.fn, this.method.value, this.url, this.data, config);

    return this._mappers.reduce((acc, fn) => fn(acc), response);
  }
}

/**
 * Axios Request implementation for communication with CTS API.
 */
export class AxiosCtsRequest extends AxiosRequest {
  constructor() {
    super(axiosCtsApiRequest);
  }
}

/**
 * Axios Request implementation for communication with USD API.
 */
export class AxiosUsdRequest extends AxiosRequest {
  constructor() {
    super(axiosUsdApiRequest);
  }
}

/**
 * Axios Request implementation for communication with Backend API.
 */
export class AxiosBackendRequest extends AxiosRequest {
  constructor() {
    super(axiosApiRequest);
  }
}
