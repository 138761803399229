import * as ACTIONS from '../../actions/balance';
import * as CONSTANTS from '../../constants/balance';
import { put, takeLatest } from 'redux-saga/effects';
import URLS from '../../constants/urls';
import RequestBuilder from '../../../request/RequestBuilder';

function* getBalance(action) {
  try {
    const responseData = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getBalanceSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getBalanceError(err));
  }
}

export default function* balanceSaga() {
  yield takeLatest(CONSTANTS.GET_BALANCE, getBalance);
}
