import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Timer from '../../Timer';
import i18next from 'i18next';
import GeneralConfirmModal from '../../modals/GeneralConfirmModal/GeneralConfirmModal';
import { useState } from 'react';
import {
  extractAssetsFromSecurityCode,
  formatDate,
  isValidDate,
  sortByDateDescending,
  getLocalisedNumericString
} from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import { TIME_ZONE } from '../../../enums/time';
import VLButton from '../../Buttons/VLButton';
import VLIconButton from '../../Buttons/VLIconButton';
import { MdCancel } from 'react-icons/md';

const OpenOrdersTable = props => {
  const [orderToDecline, setOrderToDecline] = useState(null);
  const onCancelOrder = order => {
    const data = {
      type: order.type === 'Negotiate' ? 'declineorder' : 'cancelorder',
      security: order.security,
      refno: order.refno,
      socket: props.socket,
      userid: order.userid || order.brokers,
      successCallback: data => {
        props.rejectPaymentRequestSuccess(order);
        toggleModal();
        toast.success('Order successfully canceled');
      },
      errorCallback: () => {
        toast.error(i18next.t('toasts.error'));
      }
    };
    props.rejectPaymentRequest(data);
  };
  const toggleModal = order => {
    setOrderToDecline(order);
  };
  return (
    <div className="overflow">
      {props.openOrders.length > 0 ? (
        <Table responsive borderless>
          <thead>
            <tr className="order-blotter-tables__th">
              <th>Asset</th>
              <th>Side</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Stop price</th>
              <th>Txn ID</th>
              <th>TIF</th>
              <th>Expire date</th>
              <th>Expires in</th>
              <th>Type</th>
              <th>Cancel</th>
            </tr>
          </thead>

          <tbody>
            {sortByDateDescending(props.openOrders)
              ?.filter(o => !o.ioi)
              .map(order => {
                const expdate = formatDate(new Date(parseInt(order.exptime)));
                const assets = extractAssetsFromSecurityCode(order.security);
                return (
                  <tr key={order.refno} className="order-blotter-tables__td">
                    <td>{order.security}</td>
                    <td className={`order-blotter-tables__${order.side}`}>{order.side}</td>

                    <td>
                      {getLocalisedNumericString(order.liveqty, false, getDecimalByFraction(FRACTIONS[assets?.base]))}
                    </td>
                    <td>
                      {' '}
                      {getLocalisedNumericString(order.price, false, getDecimalByFraction(FRACTIONS[assets?.quote]))}
                    </td>

                    <td>{order?.stopprice || 'N/A'}</td>
                    <td>{order.refno}</td>
                    <td>{order.tif}</td>
                    <td>{isValidDate(new Date(parseInt(order.exptime))) ? expdate : 'N/A'}</td>
                    <td>
                      {isValidDate(new Date(parseInt(order.exptime))) ? (
                        <Timer startTime={parseInt(order.exptime)} changeInterval={1000} timezone={TIME_ZONE} />
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>{order.type}</td>
                    <td style={{ display: 'inline', textAlign: '-webkit-center' }}>
                      <VLIconButton onClick={() => toggleModal(order)} size="s" Icon={MdCancel} variant="outline" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <div className="no-orders-msg">No open orders</div>
      )}

      {orderToDecline && (
        <GeneralConfirmModal
          isOpen={orderToDecline}
          title={'Cancel order'}
          text={'Are you sure you want to cancel this order ?'}
          onConfirm={() => onCancelOrder(orderToDecline)}
          onCancel={() => toggleModal(null)}
        />
      )}
    </div>
  );
};
export default OpenOrdersTable;
