import { Col, Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { isValidEmailFormat, isValidPasswordFormat } from '../../../utils/validators';
import Select from 'react-select';
import { FILTER_ROLE, ROLE_API_TYPES } from '../../../enums/validation';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput/VLInput';
const CreateUserModal = ({ onCreateUser, onClose }) => {
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    role: 'firmuser'
  });
  const [errors, setErrors] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);

  const handleOnChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value
    });
    setErrors({
      ...errors,
      [target.name]: null
    });
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleCreateUser = () => {
    if (!checkFormOnSubmit()) return;

    try {
      onCreateUser(form);
    } catch (error) {
      toast(error);
    }
  };

  const getRoleDropdown = () => [
    {
      value: ROLE_API_TYPES.USER,
      label: i18next.t('createUser.firmUser')
    }
  ];
  const checkFormOnSubmit = () => {
    const errors = {};
    if (!form.lastName) {
      errors.lastName = 'Last name is mandatory';
    }
    if (!form.firstName) {
      errors.firstName = 'First name is mandatory';
    }
    if (!form.password) {
      errors.password = 'Password is mandatory';
    }
    if (!form.email) {
      errors.email = 'Email is mandatory';
    }
    if (form.password && !isValidPasswordFormat(form.password)) {
      errors.password =
        'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one special character and one number.';
    }
    if (form.email && !isValidEmailFormat(form.email)) {
      errors.email = 'Invalid email format';
    }
    if (form.lastName.length >= 50) {
      errors.lastName = 'Last name has to many characters (max 50)';
    }
    if (form.firstName.length >= 50) {
      errors.firstName = 'First name has to many characters (max 50)';
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <Modal className="common-modal create-new-user-modal" centered show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{i18next.t('createUser.create')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">
              {i18next.t('createUser.firstName')} <span className="sfc-mandatory-field-rel">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput
                onChange={handleOnChange}
                value={form.firstName}
                size="s"
                name="firstName"
                type="text"
                placeholder={i18next.t('createUser.placeholderName')}
                error={errors.firstName}
              />
            </div>
            {errors.firstName && (
              <span className="modal-form-control-error-text system-form-control-error-text--wd">
                {errors.firstName}
              </span>
            )}
          </Col>

          <Col>
            {' '}
            <p className="system-form-control-placeholder">{i18next.t('createUser.role')} </p>
            <div className="create-new-user-control-box system-form-control-box sfcb-transparent">
              <Select
                placeholder={i18next.t('createUser.selectPlaceholder')}
                className="system-form-control"
                classNamePrefix="send-request-select"
                isDisabled={true}
                name={FILTER_ROLE}
                value={getRoleDropdown()[0]}
                components={{ DropdownIndicator: null }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">
              {i18next.t('createUser.lastName')} <span className="sfc-mandatory-field-rel">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput
                onChange={handleOnChange}
                value={form.lastName}
                size="s"
                name="lastName"
                type="text"
                placeholder={i18next.t('createUser.placeholderLastName')}
                error={errors.lastName}
              />
            </div>
            {errors.lastName && (
              <span className="modal-form-control-error-text system-form-control-error-text--wd">
                {errors.lastName}
              </span>
            )}
          </Col>
          <Col>
            <p className="system-form-control-placeholder">
              {i18next.t('createUser.password')} <span className="sfc-mandatory-field-rel">*</span>
            </p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput
                onChange={handleOnChange}
                value={form.password}
                className="password"
                size="s"
                name="password"
                type="password"
                placeholder={i18next.t('createUser.placeholderPassword')}
                error={errors.password}
              />
            </div>
            {errors.password && (
              <span className="modal-form-control-error-text system-form-control-error-text--wd">
                {errors.password}
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">
              {i18next.t('createUser.eMail')} <span className="sfc-mandatory-field-rel">*</span>
            </p>
            <div className={`system-form-control-box sfcb-transparent`}>
              <VLInput
                onChange={handleOnChange}
                value={form.email}
                size="s"
                name="email"
                type="email"
                placeholder={i18next.t('createUser.placeholderEmail')}
                error={errors.email}
              />
            </div>
            {errors.email && (
              <span className={`modal-form-control-error-text ${errors.email && 'system-form-control-error-text--wd'}`}>
                {errors.email}
              </span>
            )}
          </Col>

          <Col></Col>
        </Row>
        <Row noGutters>
          <p className="mandatory-text">
            {i18next.t('createUser.fields')} '<span className="mandatory-star">*</span>'{' '}
            {i18next.t('createUser.mandatory')}
          </p>
        </Row>
        <div className="sfcb-button-wrapper">
          <VLButton onClick={handleCreateUser} text={'Submit'} width="100%" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
