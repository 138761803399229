import { connect } from 'react-redux';
import PriceVolumeBar from './PriceVolumeBar';
import { updateAssetPair, fetchAssetPairs, fetch24hData } from '../../redux/actions/trade';

const mapStateToProps = state => {
  return {
    assetPairs: state.trade.assetPairs.data,
    chartData: state.trade.chartData,
    selectedPair: state.trade.selectedPair,
    marketData24: state.trade.marketData24
  };
};

const mapDispatchToProps = {
  fetch24hData,
  updateAssetPair,
  fetchAssetPairs
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceVolumeBar);
