export const FETCH_DEPOSIT_ACCOUNTS = 'FETCH_DEPOSIT_ACCOUNTS';
export const FETCH_DEPOSIT_ACCOUNTS_SUCCESS = 'FETCH_DEPOSIT_ACCOUNTS_SUCCESS';
export const FETCH_DEPOSIT_ACCOUNTS_ERROR = 'FETCH_DEPOSIT_ACCOUNTS_ERROR';
export const FETCH_ASSETS_BY_PRODUCT = 'GET_ASSETS_WITHDEP';
export const FETCH_ASSETS_BY_PRODUCT_SUCCESS = 'GET_ASSETS_WITHDEP_SUCCESS';
export const FETCH_ASSETS_BY_PRODUCT_ERROR = 'GET_ASSETS_WITHDEP_ERROR';

export const FETCH_ASSETS = 'GET_ASSETS';
export const FETCH_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const FETCH_ASSETS_ERROR = 'GET_ASSETS_ERROR';
export const FETCH_ASSET_PAIRS = 'FETCH_ASSET_PAIRS';
export const FETCH_ASSET_PAIRS_SUCCESS = 'FETCH_ASSET_PAIRS_SUCCESS';
export const FETCH_ASSET_PAIRS_ERROR = 'FETCH_ASSET_PAIRS_ERROR';

export const FETCH_TRADING_ASSETS = 'FETCH_TRADING_ASSETS';
export const FETCH_TRADING_ASSETS_SUCCESS = 'FETCH_TRADING_ASSETS_SUCCESS';
export const FETCH_TRADING_ASSETS_ERROR = 'FETCH_TRADING_ASSETS_ERROR';

export const SEND_DEPOSIT = 'SEND_DEPOSIT';
export const SEND_DEPOSIT_SUCCESS = 'SEND_DEPOSIT_SUCCESS';
export const SEND_DEPOSIT_ERROR = 'SEND_DEPOSIT_ERROR';

export const SEND_REDEEM = 'SEND_REDEEM';
export const SEND_REDEEM_SUCCESS = 'SEND_REDEEM_SUCCESS';
export const SEND_REDEEM_ERROR = 'SEND_REDEEM_ERROR';

export const FETCH_ACCOUNT_EVENTS = 'FETCH_ACCOUNT_EVENTS';
export const FETCH_ACCOUNT_EVENTS_SUCCESS = 'FETCH_ACCOUNT_EVENTS_SUCCESS';
export const FETCH_ACCOUNT_EVENTS_ERROR = 'FETCH_ACCOUNT_EVENTS_ERROR';
export const FETCH_ACCOUNT_EVENTS_ALL = 'FETCH_ACCOUNT_EVENTS_ALL';
export const FETCH_ACCOUNT_EVENTS_ALL_SUCCESS = 'FETCH_ACCOUNT_EVENTS_ALL_SUCCESS';
export const FETCH_ACCOUNT_EVENTS_ALL_ERROR = 'FETCH_ACCOUNT_EVENTS_ALL_ERROR';

export const FETCH_FIRMS = 'FETCH_FIRMS';
export const FETCH_FIRMS_SUCCESS = 'FETCH_FIRMS_SUCCESS';
export const FETCH_FIRMS_ERROR = 'FETCH_FIRMS_ERROR';

export const SEARCH_ACCOUNTS_OF_FIRM = 'SEARCH_ACCOUNTS_OF_FIRM';
export const SEARCH_ACCOUNTS_OF_FIRM_SUCCESS = 'SEARCH_ACCOUNTS_OF_FIRM_SUCCESS';
export const SEARCH_ACCOUNTS_OF_FIRM_ERROR = 'SEARCH_ACCOUNTS_OF_FIRM_ERROR';

export const UPDATE_TRANSACTION_DB = 'UPDATE_TRANSACTION_DB';
export const UPDATE_TRANSACTION_DB_SUCCESS = 'UPDATE_TRANSACTION_DB_SUCCESS';
export const UPDATE_TRANSACTION_DB_ERROR = 'UPDATE_TRANSACTION_DB_ERROR';
export const RESET_HASH = 'RESET_HASH';

export const GET_TRANSACTIONS_REPORT = 'GET_TRANSACTIONS_REPORT';

export const RESET_FIELDS = 'RESET_FIELDS';

export const TOGGLE_DISPLAY_MODAL_DASHBOARD = 'TOGGLE_DISPLAY_MODAL_DASHBOARD';

export const UPDATE_CRYPTO_RATES = 'UPDATE_CRYPTO_RATES';

export const GET_PRICES = 'GET_PRICES';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR';
export const UPDATE_PRICES = 'UPDATE_PRICES';
export const UPDATE_PRICES_SUCCESS = 'UPDATE_PRICES_SUCCESS';
export const UPDATE_PRICES_ERROR = 'UPDATE_PRICES_ERROR';

///////////////////////////////////////////////////////////////////////////

export const GET_ALL_PRICES = 'GET_ALL_PRICES';
export const GET_ALL_PRICES_SUCCESS = 'GET_ALL_PRICES_SUCCESS';
export const GET_ALL_PRICES_ERROR = 'GET_ALL_PRICES_ERROR';

///////////////////////////////////////////////////////////////////////////
export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY';
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_ERROR = 'FETCH_ORDER_HISTORY_ERROR';
///////////////////////////////////////////////////////////////////////////
export const FETCH_TRADE_HISTORY = 'FETCH_TRADE_HISTORY';
export const FETCH_TRADE_HISTORY_SUCCESS = 'FETCH_TRADE_HISTORY_SUCCESS';
export const FETCH_TRADE_HISTORY_ERROR = 'FETCH_TRADE_HISTORY_ERROR';

export const CLEAN_TRADE_HISTORY = 'CLEAN_TRADE_HISTORY';

export const FETCH_CANCELED_ORDERS = 'FETCH_CANCELED_ORDERS';
export const FETCH_CANCELED_ORDERS_SUCCESS = 'FETCH_CANCELED_ORDERS_SUCCESS';
export const FETCH_CANCELED_ORDERS_ERROR = 'FETCH_CANCELED_ORDERS_ERROR';

export const FETCH_OPEN_ORDERS = 'FETCH_OPEN_ORDERS';
export const FETCH_OPEN_ORDERS_SUCCESS = 'FETCH_OPEN_ORDERS_SUCCESS';
export const FETCH_OPEN_ORDERS_ERROR = 'FETCH_OPEN_ORDERS_ERROR';

export const CLEAR_ACCOUNT_EVENTS = 'CLEAR_ACCOUNT_EVENTS';

export const CLEAR_CANCELED_ORDERS = 'CLEAR_CANCELED_ORDERS';

export const CLEAR_OPEN_ORDERS = 'CLEAR_OPEN_ORDERS';

export const OPEN_ORDERS_REPORT = 'OPEN_ORDERS_REPORT';

export const CANCELED_ORDERS_REPORT = 'CANCELED_ORDERS_REPORT';
