import * as CONSTANTS from '../../constants/websocket';
import * as TRADE_CONSTANTS from '../../constants/trade';
import { SOCKET_MESSAGE_STATUS, SOCKET_MESSAGE_TYPES } from '../../../enums/websocketMessages';
import {
  buildAcceptPayment,
  buildAddAlert,
  buildAssetWalletAddress,
  buildBalanceQueryPos,
  buildBalanceWithdrawalPayload,
  buildCounterOrderPayload,
  buildDepositPayload,
  buildEscrowPayload,
  buildExchangePayload,
  buildFeeForAssetPayload,
  buildIOIPayload,
  buildIOIV2Payload,
  buildLimitOrderPayload,
  buildMarketOrderPayload,
  buildQueryAlerts,
  buildQueryIfUserExists,
  buildQueryPos,
  buildQueryRiskPayload,
  buildRedeemPayload,
  buildRejectPayment,
  buildRequestPayload,
  buildToBankPayment,
  buildToNetworkPayment,
  buildTransactionHashPayload,
  buildTransferPayload,
  buildUpdateRiskPayload
} from '../../../utils/websocketHelper';
import { cancelled, put, take, takeEvery } from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';
import { setSocket } from '../../actions/websocket';
import { v4 as uuidv4 } from 'uuid';

function* initializeWebSocketsChannel(action) {
  if (!action.data?.url) {
    return;
  }
  let socketUrl;
  if (action.data?.url.includes('wss')) {
    socketUrl = action.data?.url;
  } else {
    socketUrl = `wss://${action.data?.url}`;
  }
  const socket = new WebSocket(socketUrl);
  // sets the websocket in redux
  yield put(setSocket(socket));
  while (true) {
    try {
      const channel = yield take(createEventChannel(socket, action.data));
      //creates a cannel for listening for the websocket in redux-saga
      yield put(channel);
    } catch (err) {
      if (yield cancelled()) {
        // channel.close();
        return;
      } else {
        console.error('Error on fetching socket message in saga ', err);
      }
    }
  }
}

function createEventChannel(mySocket, data) {
  return eventChannel(emit => {
    // immediately send a challenge message to the FI websocket
    mySocket.onopen = () => {
      console.log('websocket connection open/send challenge');
      mySocket.send(JSON.stringify({ type: SOCKET_MESSAGE_TYPES.CHALLENGE }));
    };

    mySocket.onmessage = e => {
      const message = JSON.parse(e.data) || '';
      if (!message) {
        return;
      }
      messageHandler(emit, message, mySocket, data);
    };

    mySocket.onerror = evt => {
      console.log('error in websocket', evt);
      emit({ type: CONSTANTS.SET_WEBSOCKET_ERROR });
    };

    mySocket.onclose = e => console.log("whooops we're closed bub", e);

    return () => {
      console.log('closing websocket');
      emit({ type: CONSTANTS.REMOVE_WEBSOCKET_ERROR });
      mySocket.close();
    };
  });
}

function messageHandler(emitter, message, socket, userData) {
  if (message.type === SOCKET_MESSAGE_TYPES.CHALLENGE && message.result === SOCKET_MESSAGE_STATUS.OK) {
    // console.log('userData', userData);
    //Login user with specific token you got from the login api endpoint
    let loginData = JSON.stringify({
      type: SOCKET_MESSAGE_TYPES.LOGIN,
      token: userData.token
    });
    socket.send(loginData);
    emitter({ type: CONSTANTS.SOCKET_CONNECTED });
  } else if (message.type === SOCKET_MESSAGE_TYPES.LOGIN) {
    if (message.result === SOCKET_MESSAGE_STATUS.OK) {
      console.log('login success');
      //subscribe to orders after successful login (this gets us position information for accounts)
      let orderSubscriptionData = JSON.stringify({
        type: SOCKET_MESSAGE_TYPES.SUBSCRIBE,
        request: [
          {
            msg: 'ibook',
            security: '*'
          }
        ]
      });
      socket.send(orderSubscriptionData);
    } else {
      console.log('message login failed', message);
      emitter({ type: CONSTANTS.SET_WEBSOCKET_ERROR });
    }
    console.log('message', message);
  } else if (message.type === SOCKET_MESSAGE_TYPES.POSITION) {
    //action data -> {account: accountID, asset: assetID, amount: cost}
    const posData = {
      account: message.account,
      amount: message.curpos,
      asset: message.security
    };
    emitter({ type: CONSTANTS.POSITION_MSG, data: posData });
  } else if (message.type === SOCKET_MESSAGE_TYPES.ORDER) {
    //console.log('order message', message);
    //got an escrow or request message (they are order messages in FI backend)
    if (message.orderstatus === 'Executed') {
      emitter({
        type: CONSTANTS.ACCEPT_REQUEST_SUCCESS,
        data: message
      });
    } else if (message.orderstatus === 'Canceled' || message.orderstatus === 'Expired') {
      emitter({
        type: CONSTANTS.REJECT_REQUEST_SUCCESS,
        data: message
      });
    } else {
      emitter({ type: CONSTANTS.ORDER_UPDATE_MSG, data: message });
    }
  } else if (message.type === SOCKET_MESSAGE_TYPES.SCHEDULED_TRANSFER) {
    console.log('SCHEDULED_TRANSFER message', message);
    if (message.orderstatus === 'Canceled') {
      emitter({
        type: CONSTANTS.REJECT_REQUEST_SUCCESS,
        data: message
      });
    } else {
      emitter({
        type: CONSTANTS.SCHEDULED_SEND_UPDATE_MSG,
        data: message
      });
    }
  } else if (message.type === SOCKET_MESSAGE_TYPES.HEARTBEAT) {
    return;
  } else if (message.type === SOCKET_MESSAGE_TYPES.BOOK) {
    emitter({
      type: CONSTANTS.BOOK_UPDATE_MSG,
      data: message
    });
  } else if (message.ioi && message.result === 'OK') {
    emitter({
      type: TRADE_CONSTANTS.ADD_IOI,
      data: { refno: message.refno, accountNumber: message.userid }
    });
  } else if (message.type === SOCKET_MESSAGE_TYPES.RISK_SETTINGS && message.result === SOCKET_MESSAGE_STATUS.OK) {
    emitter({
      type: CONSTANTS.QUERY_RISK_SETTINGS_SUCCESS,
      data: { data: message.data }
    });
  }
}

//map object -> redux action type => function that builds specific messages for websocket
const ACTION_TYPE_PAYLOAD = {
  [CONSTANTS.SEND_TRANSFER]: buildTransferPayload,
  [CONSTANTS.REQUEST_TRANSFER]: buildRequestPayload,
  [CONSTANTS.MAKE_DEPOSIT]: buildDepositPayload,
  [CONSTANTS.MAKE_REDEEM]: buildRedeemPayload,
  [CONSTANTS.MAKE_ESCROW]: buildEscrowPayload,
  [CONSTANTS.MAKE_IOI]: buildIOIPayload,
  [CONSTANTS.MAKE_IOI_V2]: buildIOIV2Payload,
  [CONSTANTS.MAKE_COUNTER_ORDER]: buildCounterOrderPayload,
  [CONSTANTS.MAKE_REQUEST_ORDER]: buildRequestPayload,
  [CONSTANTS.TO_BANK_REQUEST]: buildToBankPayment,
  [CONSTANTS.TO_NETWORK_REQUEST]: buildToNetworkPayment,
  [CONSTANTS.ACCEPT_REQUEST]: buildAcceptPayment,
  [CONSTANTS.REJECT_REQUEST]: buildRejectPayment,
  [CONSTANTS.QUERY_POSITION]: buildQueryPos,
  [CONSTANTS.QUERY_BALANCE_WALLET_POS]: buildBalanceQueryPos,
  [CONSTANTS.GET_ASSET_WALLET_ADDRESS]: buildAssetWalletAddress,
  [CONSTANTS.GET_FEES_PER_ASSET]: buildFeeForAssetPayload,
  [CONSTANTS.SEND_BALANCE_WITHDRAWAL]: buildBalanceWithdrawalPayload,
  [CONSTANTS.EXCHANGE_CURRENCY]: buildExchangePayload,
  [CONSTANTS.SEND_TRANSACTION_HASH]: buildTransactionHashPayload,
  [CONSTANTS.UPDATE_RISK_SETTINGS]: buildUpdateRiskPayload,
  [CONSTANTS.QUERY_RISK_SETTINGS]: buildQueryRiskPayload,
  [CONSTANTS.CHECK_IF_USER_EXISTS]: buildQueryIfUserExists,
  [CONSTANTS.MAKE_LIMIT_ORDER]: buildLimitOrderPayload,
  [CONSTANTS.MAKE_MARKET_ORDER]: buildMarketOrderPayload,
  [CONSTANTS.ADD_ALERT]: buildAddAlert,
  [CONSTANTS.QUERY_ALERTS]: buildQueryAlerts
};

function* sendTransactionMessage(action) {
  const transfID = uuidv4();
  const socket = action.data.socket;

  const messagePayload = ACTION_TYPE_PAYLOAD[action.type]?.(action.data, transfID) || null;
  if (!messagePayload || !socket) {
    console.warn('no message data or no socket set', socket, messagePayload);
    yield;
  }

  socket.send(JSON.stringify(messagePayload));
  let externalWithdrawalTransaction = null;

  const onTransactionMessage = message => {
    const messageData = JSON.parse(message.data);
    // to filter out all the messages that aren't a response to our message

    if (messageData.orderstatus === 'Canceled') {
      action.data.errorCallback(messageData);
      socket.removeEventListener('message', onTransactionMessage);
      return;
    }
    if (messageData.orderstatus === 'Executed') {
      action.data.successCallback({
        ...messageData,
        extraData: externalWithdrawalTransaction
      });
      socket.removeEventListener('message', onTransactionMessage);
    }
    if (messageData.CLT_REQID === transfID || messageData.CLT_REQID === 'Cancel-Order-523a') {
      if (messageData.result === SOCKET_MESSAGE_STATUS.OK) {
        action.type !== CONSTANTS.MAKE_MARKET_ORDER &&
          action.data.successCallback({
            ...messageData,
            extraData: externalWithdrawalTransaction
          });
      } else {
        action.type !== CONSTANTS.MAKE_MARKET_ORDER && action.data.errorCallback(messageData);
      }
      action.type !== CONSTANTS.MAKE_MARKET_ORDER && socket.removeEventListener('message', onTransactionMessage);
    } else {
      if (messageData.external_address === action.data.walletID) {
        externalWithdrawalTransaction = messageData;
      }

      console.log('onTransactionMessage message random message', message);
    }
  };

  //add specific listener for this message
  socket.addEventListener('message', onTransactionMessage);
}

//so superadmin can fetch position data for a specific firm and all its accounts
function* fetchMultipleAccountMessages(action) {
  const socket = action.data.socket;
  let promises = [];
  let positions = [];

  if (!socket) {
    console.warn('no message data or no socket set', socket);
    yield;
  }

  action.data.accounts.forEach(item => {
    promises.push(queryAccountPos(action.data.firm, item, socket));
  });
  Promise.all(promises).then(positionData => {
    if (positionData.length) {
      positions = positionData.filter(position => position.length).flat();
      action.data.successCallback(positions);
    }
  });
}

// fetching position data for a single account manually
//normally subscribing is enough, but this is for superadmin use
function queryAccountPos(firm, account, ws) {
  return new Promise((resolve, reject) => {
    let reqId = uuidv4();
    let results = [];
    let wsMessage = {
      type: 'querypos',
      userid: account,
      firm: firm,
      CLT_REQID: reqId
    };

    ws.send(JSON.stringify(wsMessage));
    const posHandler = message => {
      const data = JSON.parse(message.data);
      if (data.CLT_REQID === reqId) {
        //this is the correct request
        if (data.result === 'OK') {
          // result is "OK"
          if (data.data) results = results.concat(data.data);
          resolve(results);
          ws.removeEventListener('message', posHandler);
        } else {
          //the result is not "OK"
          console.log('queryAccountPos error', data);
          //   reject({ message: "error", reason: "search error" });
          //on error, return empty array for data
          resolve([]);
          ws.removeEventListener('message', posHandler);
        }
      }
    };
    ws.addEventListener('message', posHandler);
  });
}

// trigger specific functions for specific actions
export default function* websocketSaga() {
  yield takeEvery(CONSTANTS.INIT_WEBSOCKET, initializeWebSocketsChannel);
  yield takeEvery(CONSTANTS.SEND_TRANSFER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.REQUEST_TRANSFER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_DEPOSIT, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_REDEEM, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_ESCROW, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_IOI, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_IOI_V2, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_COUNTER_ORDER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_REQUEST_ORDER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.TO_BANK_REQUEST, sendTransactionMessage);
  yield takeEvery(CONSTANTS.TO_NETWORK_REQUEST, sendTransactionMessage);
  yield takeEvery(CONSTANTS.ACCEPT_REQUEST, sendTransactionMessage);
  yield takeEvery(CONSTANTS.REJECT_REQUEST, sendTransactionMessage);
  yield takeEvery(CONSTANTS.QUERY_POSITION, sendTransactionMessage);
  yield takeEvery(CONSTANTS.QUERY_BALANCE_WALLET_POS, sendTransactionMessage);
  yield takeEvery(CONSTANTS.GET_ASSET_WALLET_ADDRESS, sendTransactionMessage);
  yield takeEvery(CONSTANTS.GET_FEES_PER_ASSET, sendTransactionMessage);
  yield takeEvery(CONSTANTS.SEND_BALANCE_WITHDRAWAL, sendTransactionMessage);
  yield takeEvery(CONSTANTS.EXCHANGE_CURRENCY, sendTransactionMessage);
  yield takeEvery(CONSTANTS.SEND_TRANSACTION_HASH, sendTransactionMessage);
  yield takeEvery(CONSTANTS.UPDATE_RISK_SETTINGS, sendTransactionMessage);
  yield takeEvery(CONSTANTS.QUERY_RISK_SETTINGS, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_LIMIT_ORDER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.MAKE_MARKET_ORDER, sendTransactionMessage);
  yield takeEvery(CONSTANTS.QUERY_POSITION_FIRM_DETAILS, fetchMultipleAccountMessages);
  yield takeEvery(CONSTANTS.CHECK_IF_USER_EXISTS, sendTransactionMessage);
  yield takeEvery(CONSTANTS.ADD_ALERT, sendTransactionMessage);
  yield takeEvery(CONSTANTS.QUERY_ALERTS, sendTransactionMessage);
}
