import * as React from 'react';

let SearchQueryContext = React.createContext();

function SearchQueryProvider(props) {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [roleQuery, setRoleQuery] = React.useState('');
  const [searchQueryType, setSearchQueryType] = React.useState('');

  let value = {
    searchQuery,
    searchQueryType,
    roleQuery,
    setSearchQuery,
    setSearchQueryType,
    setRoleQuery
  };

  return <SearchQueryContext.Provider value={value}>{props.children}</SearchQueryContext.Provider>;
}

let SearchQueryConsumer = SearchQueryContext.Consumer;

export { SearchQueryContext, SearchQueryProvider, SearchQueryConsumer };
