import 'flatpickr/dist/themes/material_green.css';

import { Modal, Row } from 'react-bootstrap';
import { DEFAULT_END_TIMELOCK, DEFAULT_START_TIMELOCK, NO_TIMELOCK } from '../../../enums/validation';
import React, { PureComponent } from 'react';

import Flatpickr from 'react-flatpickr';
import { from12hourTimeToDateTime } from '../../../utils/generalUtils';
import VLButton from '../../Buttons/VLButton';

const timeOptions = {
  mode: 'time',
  dateFormat: 'H:i',
  time_24hr: true
};

const DEFAULT_DAYS_SETUP = {
  mon: {
    name: 'mon',
    isChecked: false
  },
  tue: {
    name: 'tue',
    isChecked: false
  },
  wed: {
    name: 'wed',
    isChecked: false
  },
  thu: {
    name: 'thu',
    isChecked: false
  },
  fri: {
    name: 'fri',
    isChecked: false
  },
  sat: {
    name: 'sat',
    isChecked: false
  },
  sun: {
    name: 'sun',
    isChecked: false
  }
};

class TimeLockModal extends PureComponent {
  state = {
    startRange: '09:00', //default
    endRange: '17:00', //default
    days: { ...DEFAULT_DAYS_SETUP },
    error: ''
  };

  componentDidMount() {
    if (this.props.timeString && this.props.timeString !== NO_TIMELOCK) {
      const startRange = this.props.timeString.split('|')[0];
      const endRange = this.props.timeString.split('|')[1];
      const daysArray = this.props.timeString.split('|')[2];
      let updatedDays = this.state.days;
      Object.keys(this.state.days).forEach(day => {
        if (daysArray?.includes(day)) {
          updatedDays[day].isChecked = true;
        } else {
          updatedDays[day].isChecked = false;
        }
      });
      this.setState({
        startRange,
        endRange,
        days: { ...updatedDays }
      });
    } else {
      this.setState({
        startRange: DEFAULT_START_TIMELOCK,
        endRange: DEFAULT_END_TIMELOCK,
        days: { ...DEFAULT_DAYS_SETUP }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.timeString !== prevProps.timeString) {
      if (this.props.timeString === NO_TIMELOCK) {
        this.setState({
          startRange: '',
          endRange: ''
        });
      } else {
        const startRange = this.props.timeString.split('|')[0];
        const endRange = this.props.timeString.split('|')[1];
        const daysArray = this.props.timeString.split('|')[2];
        let updatedDays = this.state.days;
        Object.keys(this.state.days).forEach(day => {
          if (daysArray?.includes(day)) {
            updatedDays[day].isChecked = true;
          } else {
            updatedDays[day].isChecked = false;
          }
        });
        this.setState({
          startRange,
          endRange,
          days: { ...updatedDays }
        });
      }
    }
  }

  onTimeRangeChange = (timeString, property) => {
    let error = '';
    let start = null;
    let end = null;
    if (property === 'endRange') {
      start = from12hourTimeToDateTime(this.state.startRange);
      end = from12hourTimeToDateTime(timeString);
    } else {
      start = from12hourTimeToDateTime(timeString);
      end = from12hourTimeToDateTime(this.state.endRange);
    }
    if (start.getTime() >= end.getTime()) {
      error = 'Start time cannot be later than end time';
    }
    this.setState({
      [property]: timeString,
      error
    });
  };

  handleDayOnClick = day => {
    this.setState(prev => ({
      ...prev,
      days: {
        ...prev.days,
        [day]: {
          isChecked: !prev.days[day].isChecked
        }
      }
    }));
  };

  buildSelectDaysButtons = () => {
    let daysArr = [];
    for (const day in this.state.days) {
      daysArr.push(
        <VLButton size="m" width="80px" variant={'outline'} onClick={() => this.handleDayOnClick(day)} text={day} />
      );
    }
    return daysArr;
  };

  selectedDays = () => {
    let checkedDaysArr = [];
    for (const day in this.state.days) {
      if (this.state.days[day].isChecked) {
        checkedDaysArr.push(
          <span key={day} className="timelock-selected-days">
            {day}
          </span>
        );
      }
    }
    return checkedDaysArr;
  };

  selectedDaysName = () => {
    const daysSelected = Object.keys(this.state.days).filter(day => this.state.days[day].isChecked);
    return daysSelected;
  };

  isDisabled = () => {
    if (!!!this.state.startRange) return true;
    if (!!!this.state.endRange) return true;
    if (this.state.error) return true;
    if (this.selectedDays().length === 0) return true;
    return false;
  };

  render() {
    const selectedDaysForSend = this.selectedDaysName();
    return (
      <Modal className="common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Time lock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="timelock-title">Configure daily unlock window</h3>
          <Row className="timelock-days-bar" noGutters>
            <h4 className="timelock-subtitle">Select days of week</h4>
            <div className="timelock-days-wrap">{this.buildSelectDaysButtons()}</div>
          </Row>
          <Row className="timelock-time-bar" noGutters>
            <h4 className="timelock-subtitle">Select time of day</h4>
            <div className="timelock-time-wrap">
              <div className="timelock-time-input">
                <p className="timelock-time-input-title">Start</p>
                <div className="system-form-control-box sfcb-transparent">
                  <Flatpickr
                    className="flatpickr"
                    data-enable-time
                    value={this.state.startRange}
                    options={timeOptions}
                    onValueUpdate={(_, timeString) => this.onTimeRangeChange(timeString, 'startRange')}
                  />
                </div>
              </div>
              <div className="timelock-time-input">
                <p className="timelock-time-input-title">End</p>
                <div className="system-form-control-box sfcb-transparent">
                  <Flatpickr
                    className="flatpickr"
                    data-enable-time
                    value={this.state.endRange}
                    options={timeOptions}
                    onValueUpdate={(_, timeString) => this.onTimeRangeChange(timeString, 'endRange')}
                  />
                </div>
              </div>
            </div>
          </Row>
          {this.state.error && (
            <Row className="timelock-selected" noGutters>
              <p className="timelock-selected-text-error">{this.state.error}</p>
            </Row>
          )}
          <Row className="timelock-selected" noGutters>
            <p className="timelock-selected-text">Selected daily unlock window:</p>
            <div>
              {this.selectedDays().length < 1 ? (
                <span className="timelock-selected-unselected-text">Select days</span>
              ) : (
                this.selectedDays()
              )}
            </div>
            {!this.selectedDays().length < 1 && (
              <span className="timelock-selected-time">
                {this.state.startRange} - {this.state.endRange}
              </span>
            )}
          </Row>
          <div className="external-deposit-footer-bar timelock-btn-bar">
            <VLButton size="l" variant="danger-outline" onClick={this.props.onClose} text="Cancel" />
            <VLButton
              disabled={this.isDisabled()}
              onClick={() => this.props.onSaveClick(this.state.startRange, this.state.endRange, selectedDaysForSend)}
              size="l"
              text={'Save'}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TimeLockModal;
