export const GET_LIST_OF_FIRMS_REQUEST = 'GET_LIST_OF_FIRMS_REQUEST';
export const GET_LIST_OF_FIRMS_SUCCESS = 'GET_LIST_OF_FIRMS_SUCCESS';
export const GET_LIST_OF_FIRMS_ERROR = 'GET_LIST_OF_FIRMS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const GET_LIST_OF_FIRMS_ACCOUNTS = 'GET_LIST_OF_FIRMS_ACCOUNTS';
export const GET_LIST_OF_FIRMS_ACCOUNTS_SUCCESS = 'GET_LIST_OF_FIRMS_ACCOUNTS_SUCCESS';
export const GET_LIST_OF_FIRMS_ACCOUNTS_ERROR = 'GET_LIST_OF_FIRMS_ACCOUNTS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const CREATE_FIRM_REQUEST = 'CREATE_FIRM_REQUEST';
export const CREATE_FIRM_SUCCESS = 'CREATE_FIRM_SUCCESS';
export const CREATE_FIRM_ERROR = 'CREATE_FIRM_ERROR';

export const DELETE_FIRM_REQUEST = 'DELETE_FIRM_REQUEST';
export const DELETE_FIRM_REQUEST_SUCCESS = 'DELETE_FIRM_REQUEST_SUCCESS';
export const DELETE_FIRM_REQUEST_ERROR = 'DELETE_FIRM_REQUEST_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_FIRM_DETAILS_REQUEST = 'GET_FIRM_DETAILS_REQUEST';
export const GET_FIRM_DETAILS_SUCCESS = 'GET_FIRM_DETAILS_SUCCESS';
export const GET_FIRM_DETAILS_ERROR = 'GET_FIRM_DETAILS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_FIRM_ADMIN_ACCOUNTS_REQUEST = 'GET_FIRM_ADMIN_ACCOUNTS_REQUEST';
export const GET_FIRM_ADMIN_ACCOUNTS_SUCCESS = 'GET_FIRM_ADMIN_ACCOUNTS_SUCCESS';
export const GET_FIRM_ADMIN_ACCOUNTS_ERROR = 'GET_FIRM_ADMIN_ACCOUNTS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_FIRM_USERS_REQUEST = 'GET_FIRM_USERS_REQUEST';
export const GET_FIRM_USERS_SUCCESS = 'GET_FIRM_USERS_SUCCESS';
export const GET_FIRM_USERS_ERROR = 'GET_FIRM_USERS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_USER_SUGGESTIONS_REQUEST = 'GET_USER_SUGGESTIONS_REQUEST';
export const GET_USER_SUGGESTIONS_SUCCESS = 'GET_USER_SUGGESTIONS_SUCCESS';
export const GET_USER_SUGGESTIONS_ERROR = 'GET_USER_SUGGESTIONS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_ACCOUNT_SUGGESTIONS_REQUEST = 'GET_ACCOUNT_SUGGESTIONS_REQUEST';
export const GET_ACCOUNT_SUGGESTIONS_SUCCESS = 'GET_ACCOUNT_SUGGESTIONS_SUCCESS';
export const GET_ACCOUNT_SUGGESTIONS_ERROR = 'GET_ACCOUNT_SUGGESTIONS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_REQUEST_SUCCESS = 'DELETE_ACCOUNT_REQUEST_SUCCESS';
export const DELETE_ACCOUNT_REQUEST_ERROR = 'DELETE_ACCOUNT_REQUEST_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const CREATE_ADMIN_ACCOUNT_REQUEST = 'CREATE_ADMIN_ACCOUNT_REQUEST';
export const CREATE_ADMIN_ACCOUNT_SUCCESS = 'CREATE_ADMIN_ACCOUNT_SUCCESS';
export const CREATE_ADMIN_ACCOUNT_ERROR = 'CREATE_ADMIN_ACCOUNT_ERROR';

export const DELETE_ADMIN_ACCOUNT_REQUEST = 'DELETE_ADMIN_ACCOUNT_REQUEST';
export const DELETE_ADMIN_ACCOUNT_SUCCESS = 'DELETE_ADMIN_ACCOUNT_SUCCESS';
export const DELETE_ADMIN_ACCOUNT_ERROR = 'DELETE_ADMIN_ACCOUNT_ERROR';

export const REGISTER_WALLET_FOR_ACCOUNT = 'REGISTER_WALLET_FOR_ACCOUNT';
export const REGISTER_WALLET_FOR_ACCOUNT_SUCCESS = 'REGISTER_WALLET_FOR_ACCOUNT_SUCCESS';
export const REGISTER_WALLET_FOR_ACCOUNT_ERROR = 'REGISTER_WALLET_FOR_ACCOUNT_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const CLEAR_ALL_FIRM_INFO = 'CLEAR_ALL_FIRM_INFO';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_ONBOARDING_USERS_REQUEST = 'GET_ONBOARDING_USERS_REQUEST';
export const GET_ONBOARDING_USERS_SUCCESS = 'GET_ONBOARDING_USERS_SUCCESS';

export const GET_ONBOARDING_USERS_ERROR = 'GET_ONBOARDING_USERS_ERROR';

export const GET_PENDING_ONBOARDING_USERS_REQUEST = 'GET_PENDING_ONBOARDING_USERS_REQUEST';
export const GET_PENDING_ONBOARDING_USERS_ERROR = 'GET_PENDING_ONBOARDING_USERS_ERROR';
export const GET_PENDING_ONBOARDING_USERS_SUCCESS = 'GET_PENDING_ONBOARDING_USERS_SUCCESS';
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const ACCEPT_ONBOARDING_USER_REQUEST = 'ACCEPT_ONBOARDING_USER_REQUEST';
export const ACCEPT_ONBOARDING_USER_SUCCESS = 'ACCEPT_ONBOARDING_USER_SUCCESS';
export const DECLINE_ONBOARDING_USER_REQUEST = 'DECLINE_ONBOARDING_USER_REQUEST';
export const DECLINE_ONBOARDING_USER_SUCCESS = 'DECLINE_ONBOARDING_USER_SUCCESS';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const GET_ACCOUNT_DETAILS_REQUEST = 'GET_ACCOUNT_DETAILS_REQUEST';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_ERROR = 'GET_ACCOUNT_DETAILS_ERROR';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_CONTACTS_REQUESTS_REQUEST = 'GET_CONTACTS_REQUESTS_REQUEST';
export const GET_CONTACTS_REQUESTS_SUCCESS = 'GET_CONTACTS_REQUESTS_SUCCESS';
export const GET_CONTACTS_REQUESTS_ERROR = 'GET_CONTACTS_REQUESTS_ERROR';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const ACCEPT_CONTACT_REQUEST = 'ACCEPT_CONTACT_REQUEST';
export const ACCEPT_CONTACT_SUCCESS = 'ACCEPT_CONTACT_SUCCESS';
export const ACCEPT_CONTACT_ERROR = 'ACCEPT_CONTACT_ERROR';

export const MARK_CONTACT_NOTIFICATIONS_AS_READ_REQUEST = 'MARK_CONTACT_NOTIFICATIONS_AS_READ_REQUEST';
export const MARK_CONTACT_NOTIFICATIONS_AS_READ_SUCCESS = 'MARK_CONTACT_NOTIFICATIONS_AS_READ_SUCCESS';
export const MARK_CONTACT_NOTIFICATIONS_AS_READ_ERROR = 'MARK_CONTACT_NOTIFICATIONS_AS_READ_ERROR';

/////////////////////////////////////////////////////////////////////////////////
export const DECLINE_CONTACT_REQUEST = 'DECLINE_CONTACT_REQUEST';
export const DECLINE_CONTACT_SUCCESS = 'DECLINE_CONTACT_SUCCESS';
export const DECLINE_CONTACT_ERROR = 'DECLINE_CONTACT_ERROR';
////////////////////////////////////////////////////////////////////////////////
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

////////////////////////////////////////////////////////////////////////////////
export const CANCEL_CONTACT_REQUEST = 'CANCEL_CONTACT_REQUEST';
export const CANCEL_CONTACT_SUCCESS = 'CANCEL_CONTACT_SUCCESS';
export const CANCEL_CONTACT_ERROR = 'CANCEL_CONTACT_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const UPLOAD_BTA_REPORT_REQUEST = 'UPLOAD_BTA_REPORT_REQUEST';
export const UPLOAD_BTA_REPORT_SUCCESS = 'UPLOAD_BTA_REPORT_SUCCESS';
export const UPLOAD_BTA_REPORT_ERROR = 'UPLOAD_BTA_REPORT_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const DOWNLOAD_BTA_REPORT_REQUEST = 'DOWNLOAD_BTA_REPORT_REQUEST';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const DELETE_BTA_REPORT_REQUEST = 'DELETE_BTA_REPORT_REQUEST';
export const DELETE_BTA_REPORT_SUCCESS = 'DELETE_BTA_REPORT_SUCCESS';
export const DELETE_BTA_REPORT_ERROR = 'DELETE_BTA_REPORT_ERROR';
///////////////////////////////////////////////////////////////////////////
export const GET_USERS_REPORT = 'GET_USERS_REPORT';

export const RESET_CREATE_CONTACT_RESPONSE = 'RESET_CREATE_CONTACT_RESPONSE';
export const RESET_DELETE_CONTACT_RESPONSE = 'RESET_DELETE_CONTACT_RESPONSE';
export const RESET_ACCEPT_CONTACT_RESPONSE = 'RESET_ACCEPT_CONTACT_RESPONSE';
export const RESET_DECLINE_CONTACT_RESPONSE = 'RESET_DECLINE_CONTACT_RESPONSE';
export const RESET_CANCEL_CONTACT_RESPONSE = 'RESET_CANCEL_CONTACT_RESPONSE';

/////////////////////////////////////////////////////////////////////////////

export const SET_CURRENT_ACCOUNT_NUMBER = 'SET_CURRENT_ACCOUNT_NUMBER';
export const SET_CURRENT_ACCOUNTS_FIRM = 'SET_CURRENT_ACCOUNTS_FIRM';
