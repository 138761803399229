import { SHOW_ALL_ACCOUNTS_FLAG, USER_DISPLAY } from '../enums/validation';

import { isUserDisplay } from './validators';

export const resolveUserAndAccountForPermissionUpdate = (type, parentData, childData) => {
  if (type === USER_DISPLAY) {
    return {
      userid: parentData?.userid,
      account: childData.userid
      //   secondary: childData.secondary_account
    };
  } else {
    return {
      userid: childData.userid,
      account: parentData?.userid
      //   secondary: parentData?.secondary_account
    };
  }
};

export const createMultipleFirmQueryString = firms => firms?.map(firm => firm.firm).join(',') || undefined;

export const createUserSearchQueryObject = (query, roleFilter) => ({
  q: query || undefined,
  role: roleFilter || undefined
});

export const createUserOrAccountActionObjectWithSearch = (query, queryType, payload, roleFilter) => {
  const searchField = isUserDisplay(queryType) ? 'userSearchData' : 'accountSearchData';
  return {
    payload: payload,
    searchQuery: {
      [searchField]: createUserSearchQueryObject(query, isUserDisplay(queryType) ? roleFilter : undefined)
    }
  };
};

export const createFirmAndSearchParamsObject = (firm, search) => ({
  firm: firm || undefined,
  q: search || undefined
});

export const createFirmQueryObject = firm => ({
  firm: firm || undefined
});

export const createEventsQueryObject = (
  security,
  type,
  from,
  to,
  amountRangeStart,
  amountRangeEnd,
  tradeID,
  description = undefined,
  processedBy = undefined
) => ({
  security: security === SHOW_ALL_ACCOUNTS_FLAG ? undefined : security || undefined,
  type: type === SHOW_ALL_ACCOUNTS_FLAG ? undefined : type || undefined,
  from: from || undefined,
  to: to || undefined,
  amountStart: amountRangeStart !== null ? amountRangeStart : undefined,
  amountEnd: amountRangeEnd || undefined,
  tradeID: tradeID || undefined,
  description: description || undefined,
  processedBy: processedBy || undefined
});

export const createRiskSettingObject = (washCheck, activeCheck, duplicateCheck, maxDaily, maxDailyTransaction) => ({
  active_account: activeCheck ? 'Y' : 'N',
  washcheck: washCheck ? 'Y' : '',
  DupOrd: duplicateCheck ? 'Y' : '',
  totalallowamount: maxDaily ? maxDaily : '',
  maxdollaramount: maxDailyTransaction ? maxDailyTransaction : ''
});
