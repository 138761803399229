import './OpenOrdersTable.scss';

import OpenOrdersTable from './OpenOrdersTable';
import { connect } from 'react-redux';
import { rejectPaymentRequest, rejectPaymentRequestSuccess } from '../../../redux/actions/websocket';
const mapStateToProps = state => ({
  openOrders: state.websocket?.openOrders,
  socket: state.websocket.websocket
});

const mapDispatchToProps = {
  rejectPaymentRequest,
  rejectPaymentRequestSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrdersTable);
