import React from 'react';
import { Form } from 'react-bootstrap';
import AssetPositions from '../AssetPositions';
import DatePicker, { dateSinglePickerStart } from '../../../components/DatePicker';
import './LimitOrder.scss';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../LoadingIndicator';
import { useOrderForm } from '../useOrderForm';
import { parseCurrency } from '../trade-helpers';
import TradeButton from '../TradeButton';
import { padArray, BOOK_SIZE, byOrderPriceAsc, byOrderPriceDesc } from '../../OrderBook/orderBookHelpers';
import VLSelect from '../../VLSelect';
import VLInput from '../../VLInput/VLInput';

const TIF_OPTIONS = Object.freeze([
  { label: 'GTC', value: 'GTC' },
  { label: 'IOC', value: 'IOC' },
  { label: 'GTT', value: 'GTT' },
  { label: 'FOK', value: 'FOK' },
  { label: 'DAY', value: 'DAY' }
]);

const LimitOrder = ({
  userData,
  account,
  side,
  pair,
  balance,
  makeLimitOrderRequest,
  websocket,
  lmt,
  updateTransactionsDB,
  cryptoRates,
  allBooks,
  assetPair,
  isDisabled
}) => {
  const sell = padArray(
    allBooks[assetPair?.security]
      ?.filter(o => o.side === 'S' && !o.ioi)
      .slice(0, BOOK_SIZE)
      .sort(byOrderPriceAsc) ?? []
  ).reverse();
  const buy = padArray(
    allBooks[assetPair?.security]
      ?.filter(o => o.side === 'B' && !o.ioi)
      .slice(0, BOOK_SIZE)
      .sort(byOrderPriceDesc) ?? []
  );
  const minSellPrice = sell[sell.length - 1]?.price;
  const maxBuyPrice = buy[0]?.price;
  const {
    formState,
    totalValueDisplay,
    setLoading,
    resetForm,
    handleBlur,
    handleChange,
    handleCheckedChange,
    handleDateChange,
    handleCheckbox,
    handleTifChange,
    validateForm
  } = useOrderForm({
    side,
    balance,
    pair,
    minSellPrice: minSellPrice,
    maxBuyPrice: maxBuyPrice,
    tifOptions: TIF_OPTIONS,
    initialPrice: lmt?.price,
    initialAmount: lmt?.qty
  });

  const handleSubmit = e => {
    if (!validateForm()) return;

    let data = {
      socket: websocket,
      account,
      firm: userData.firm,
      pair: pair.join(''),
      side,
      asset: pair[0],
      quantity: parseCurrency(formState.amount),
      price: parseCurrency(formState.price),
      tif: formState.tif.value === 'DAY' ? 'GTT' : formState.tif.value, // DAY is special version of GTT
      expDate: formState.expDate ? new Date(formState.expDate).getTime() : undefined,
      stopPrice: formState.stopValue ? parseCurrency(formState.stopValue) : null,
      icebergQuantity: formState.icebergValue ? parseCurrency(formState.icebergValue) : null,
      postOnly: formState.selectedType === 'postOnly',
      successCallback,
      errorCallback
    };

    setLoading(true);

    makeLimitOrderRequest(data);
  };

  const successCallback = data => {
    toast.success(i18next.t('toasts.success'));
    updateTransactionsDB({
      ref: data.refno,
      type: 'exchange',
      cryptoRates: cryptoRates.data
    });
    resetForm();
  };

  const errorCallback = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.result}`);

    setLoading(false);
  };

  return (
    <div className="limit-order">
      {formState.isLoading ? <LoadingIndicator /> : null}
      <AssetPositions
        positions={[
          {
            asset: pair[0],
            value: getLocalisedNumericString(balance?.[pair[0]], true, getDecimalByFraction(FRACTIONS[pair[0]]))
          },
          {
            asset: pair[1],
            value: getLocalisedNumericString(balance?.[pair[1]], true, getDecimalByFraction(FRACTIONS[pair[1]]))
          }
        ]}
      />
      <form>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Price:</p>
            <VLInput
              type="price"
              name="price"
              placeholder="Price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['price'] || ''}
              maxLength="20"
              autoComplete="off"
              autoFocus
              dataAsset={pair[1]}
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.price}
            />
            {formState.errors?.price ? (
              <span className="system-form-control-error-text">{formState.errors?.price}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Amount:</p>
            <VLInput
              type="amount"
              name="amount"
              placeholder="Amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['amount'] || ''}
              maxLength="20"
              autoComplete="off"
              dataAsset={pair[0]}
              assetLabel={pair[0]}
              size="s"
              error={formState.errors?.amount}
            />
            {formState.errors?.amount ? (
              <span className="system-form-control-error-text">{formState.errors?.amount}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Approximate total:</p>
            <VLInput
              type="total"
              name="total"
              onChange={handleChange}
              onBlur={handleBlur}
              value={totalValueDisplay}
              maxLength="20"
              autoComplete="off"
              readOnly
              dataAsset={pair[1]}
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.total}
            />
            {formState.errors?.total ? (
              <span className="system-form-control-error-text">{formState.errors?.total}</span>
            ) : null}
          </div>
        </div>
        <div className="options-container">
          <div>
            <div className="option-wrapper">
              <Form.Check
                type="radio"
                className="vl-checkbox"
                id={`limit-checkbox`}
                label={'Limit'}
                name={'limit'}
                checked={formState.selectedType === 'limit'}
                onChange={handleCheckedChange}
              />
            </div>
            <div className="option-wrapper">
              <Form.Check
                type="radio"
                className="vl-checkbox"
                id={`postOnly-checkbox`}
                label={'Post only'}
                name={'postOnly'}
                checked={formState.selectedType === 'postOnly'}
                onChange={handleCheckedChange}
                disabled={formState.tif?.value === 'FOK' || formState.tif?.value === 'IOC'}
              />
            </div>
            <div className="option-wrapper">
              <Form.Check
                className="vl-checkbox"
                type="radio"
                id={`iceberg-checkbox`}
                label={'Iceberg'}
                name={'iceberg'}
                checked={formState.selectedType === 'iceberg'}
                onChange={handleCheckedChange}
                disabled={formState.tif?.value === 'FOK' || formState.tif?.value === 'IOC'}
              />
              {formState.selectedType === 'iceberg' ? (
                <div className="system-form-control-box">
                  <VLInput
                    type="price"
                    name="icebergValue"
                    placeholder="Amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formState['icebergValue'] || ''}
                    maxLength="20"
                    autoComplete="off"
                    autoFocus
                    dataAsset={pair[0]}
                    size="s"
                    error={formState.errors?.icebergValue}
                  />
                  {formState.errors?.icebergValue ? (
                    <span className="system-form-control-error-text">{formState.errors?.icebergValue}</span>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="option-wrapper">
              <Form.Check
                type="radio"
                className="vl-checkbox"
                id={`stop-checkbox`}
                label={'Stop'}
                name={'stop'}
                onChange={handleCheckedChange}
                checked={formState.selectedType === 'stop'}
              />
              {formState.selectedType === 'stop' ? (
                <div className="system-form-control-box">
                  <VLInput
                    type="price"
                    name="stopValue"
                    placeholder="Price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formState['stopValue'] || ''}
                    maxLength="20"
                    autoComplete="off"
                    autoFocus
                    dataAsset={pair[1]}
                    size="s"
                    error={formState.errors?.stopValue}
                  />
                  {formState.errors?.stopValue ? (
                    <span className="system-form-control-error-text">{formState.errors?.stopValue}</span>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="select-wrapper">
              <label>TIF:</label>
              <div className="select-field">
                <VLSelect
                  borderless
                  transparent
                  aria-labelledby="tif"
                  name="tif"
                  options={TIF_OPTIONS}
                  onChange={value => handleTifChange('tif', value)}
                  value={formState.tif}
                  isOptionDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        {formState.tif?.value === 'GTT' ? (
          <div className="date-control-wrapper">
            <p className="system-form-control-label">Exp. time:</p>
            <div
              className={`system-form-control sfcb-transparent sfcb-date date-picker--small date-picker--modal ${
                formState.errors?.expDate ? 'error' : ''
              }`}
            >
              <DatePicker
                options={dateSinglePickerStart({
                  opens: 'center',
                  drops: 'up',
                  parentEl: '.date-picker--small'
                })}
                onClick={handleDateChange}
                minDate={new Date()}
                date={formState.expDate}
              />
            </div>
            {formState.errors?.expDate ? (
              <span className="system-form-control-error-text">{formState.errors?.expDate}</span>
            ) : null}
          </div>
        ) : null}
        {formState.errors?.acknowledge && (
          <div className="system-form-control-error-text">
            <label>Please acknowledge:</label>

            <div className="first-acknowledge">
              <input
                className="checkbox"
                type={'checkbox'}
                onChange={handleCheckbox}
                checked={formState.acknowledgeCheck}
              />
              {formState.errors?.acknowledge}
            </div>
            <div className="second-acknowledge">
              <div style={{ width: '21px' }}></div>
              {`Quote at the time of order verification ${
                side === 'S'
                  ? getLocalisedNumericString(maxBuyPrice, true, 2)
                  : getLocalisedNumericString(minSellPrice, true, 2)
              }`}
            </div>
          </div>
        )}

        <TradeButton
          onClick={handleSubmit}
          isDisabled={formState.isLoading || isDisabled}
          asset={pair[0]}
          side={side}
        />
      </form>
    </div>
  );
};

export default LimitOrder;
