import React, { useState } from 'react';

import { Table } from 'react-bootstrap';

import { getAssetNameFromSecurityField, getLocalisedNumericString, moneyFormat, sort } from '../../utils/generalUtils';
import LoadingIndicator from '../LoadingIndicator';
import TabGroup, { useTabs } from '../TradeOrder/TabGroup';
import { ALL_PAIRS_TABLE, DESCENDING, MODAL_CODES, USD_PAIRS_TABLE } from '../../enums/validation';
import './MarketsTable.scss';
import { history } from '../../configureStore';
import { MdOutlineTrendingUp, MdSwapHoriz } from 'react-icons/md';
import i18next from 'i18next';
import TableSortButtons from '../TableSortButtonsComponent/TableSortButtons';
import { shouldAllowTransactions } from '../../utils/roleCheckers';
import VLButton from '../Buttons/VLButton';

const NONE = '';
const FIRST_ASSET = 'first_asset';
const MARKET_CAP = 'marketCap';
const NUMBER_OF_DECIMALS_24H_CHANGE_FIELD = 2;

const MARKET_TABS = [
  { id: 0, label: 'All markets', value: 'All Markets' },
  { id: 1, label: 'USD(S) markets', value: 'USD' },
  { id: 2, label: 'BTC markets', value: 'BTC' },
  { id: 3, label: 'ETH markets', value: 'ETH' },
  { id: 4, label: 'USD markets', value: 'USD' }
];

const findAsset = (assets, selectedAsset) => {
  return assets.find(asset => asset.security === selectedAsset);
};

const extractDataForParticularPairTable = (tableType, activeTab, tabs, marketData24All) => {
  if (!marketData24All.data.length) {
    return;
  }

  if (tableType === USD_PAIRS_TABLE) {
    return marketData24All.data.filter(pair => pair?.second_asset === 'USD');
  }
  if (activeTab === 0) {
    return marketData24All.data;
  }
  const selectedTab = tabs[activeTab];
  if (activeTab === 1) {
    return marketData24All.data.filter(pair => pair.second_asset === 'USDC' && pair.first_asset !== 'USD');
  } else {
    return marketData24All.data.filter(pair => pair.second_asset === selectedTab.value);
  }
};

const redirectToCryptoAssetDetails = asset => {
  history.push(`/asset/${asset}`);
};

const searchMarketsTableData = (tableType, activeTab, selectedAsset, assets) => {
  if (selectedAsset === '' || selectedAsset.value === 'USD') return assets;
  if (tableType === USD_PAIRS_TABLE) {
    return assets?.filter(asset => {
      return asset.first_asset === selectedAsset.value;
    });
  } else if (tableType === ALL_PAIRS_TABLE) {
    return assets?.filter(asset => {
      return asset.security === selectedAsset.value;
    });
  }
  return assets;
};

const MarketsTable = ({
  marketData24All,
  tableType,
  assetPairs,
  selectedAsset,
  updateAssetPair,
  clearSearchInput,
  toggleModal,
  setSelectedSecurity,
  accounts,
  userData,
  currentAccountNumber
}) => {
  const [nameSortingOrder, setSortingOrderName] = useState('');
  const [marketCapSortingOrder, setSortingOrderMarketCap] = useState(DESCENDING);
  const selectedAccount = accounts.data.find(account => account.account === currentAccountNumber);

  const canTransact = shouldAllowTransactions(userData, selectedAccount);

  const updateColumnSortStatus = (order, column) => {
    if (column === FIRST_ASSET) {
      setSortingOrderName(order);
      setSortingOrderMarketCap(NONE);
    } else if (column === MARKET_CAP) {
      setSortingOrderName(NONE);
      setSortingOrderMarketCap(order);
    } else {
      setSortingOrderName(NONE);
      setSortingOrderMarketCap(NONE);
    }
  };

  const getSortedAssets = assets => {
    if (nameSortingOrder !== NONE || marketCapSortingOrder !== NONE) {
      const column = nameSortingOrder !== NONE ? FIRST_ASSET : MARKET_CAP;
      const order = column === FIRST_ASSET ? nameSortingOrder : marketCapSortingOrder;
      const sortedAssets = sort(assets, order, column);
      return sortedAssets;
    }
    return assets;
  };

  const determineSortDirection = accent => {
    if (accent > 0) return ' price-volume-bar-value-green';
    else if (accent < 0) return ' price-volume-bar-value-red';
    else return '';
  };

  const renderTable = (tableType, rows) => {
    return (
      <Table borderless responsive className="markets-table vl-table" size="sm">
        <thead>
          <tr>
            <th></th>
            {tableType === ALL_PAIRS_TABLE && <th></th>}
            <th className="align-right">
              {' '}
              <div className="markets-table-name-header">
                <div>Name</div>
                <div className="sorting-arrows-header">
                  <TableSortButtons
                    updateColumnSortStatus={updateColumnSortStatus}
                    sortingOrder={nameSortingOrder}
                    tableType={FIRST_ASSET}
                  />
                </div>
              </div>
            </th>
            <th className="align-right table-header-without-sort">
              <div>
                {' '}
                Price{' '}
                {tableType === USD_PAIRS_TABLE || (tableType === ALL_PAIRS_TABLE && activeTab === 4) ? '(USD)' : ''}
              </div>
            </th>
            <th className="align-right table-header-without-sort"> 24h change </th>
            <th className="align-right table-header-without-sort">
              {' '}
              24h high{' '}
              {tableType === USD_PAIRS_TABLE || (tableType === ALL_PAIRS_TABLE && activeTab === 4) ? '(USD)' : ''}
            </th>
            <th className="align-right table-header-without-sort">
              {' '}
              24h low{' '}
              {tableType === USD_PAIRS_TABLE || (tableType === ALL_PAIRS_TABLE && activeTab === 4) ? '(USD)' : ''}
            </th>
            <th className="align-right">
              {' '}
              <div className="markets-table-name-header market-cap-justify-content">
                Market cap (USD)
                <div className="sorting-arrows-header">
                  <TableSortButtons
                    updateColumnSortStatus={updateColumnSortStatus}
                    sortingOrder={marketCapSortingOrder}
                    tableType={MARKET_CAP}
                  />
                </div>
              </div>
            </th>
            <th className="align-right" colSpan={2}></th>
          </tr>
        </thead>
        <tbody>
          {rows.map(assetData => {
            const accent = determineSortDirection(assetData?.change);
            return (
              <>
                {assetData?.first_asset && assetData?.second_asset && (
                  <tr
                    onClick={() =>
                      assetData?.first_asset !== 'USD' &&
                      tableType === USD_PAIRS_TABLE &&
                      redirectToCryptoAssetDetails(assetData?.first_asset.toLowerCase())
                    }
                  >
                    <td>
                      <div>
                        <img
                          src={require(`../../assets/svg/crypto/${assetData?.first_asset?.toLowerCase()}.svg`)}
                          alt={`${assetData?.first_asset}`}
                        />
                      </div>
                    </td>
                    {tableType === ALL_PAIRS_TABLE && (
                      <td>
                        <div>
                          <img
                            src={require(`../../assets/svg/crypto/${assetData?.second_asset?.toLowerCase()}.svg`)}
                            alt={`${assetData?.second_asset}`}
                          />
                        </div>
                      </td>
                    )}
                    <td>
                      <div className="align-left">
                        <div className="markets-asset-name">
                          {tableType === USD_PAIRS_TABLE && (
                            <div className="full-asset-name-markets-tab">
                              <b>{getAssetNameFromSecurityField(assetData)}</b>
                            </div>
                          )}
                          {tableType === USD_PAIRS_TABLE && <div>{assetData?.first_asset}</div>}
                          {tableType === ALL_PAIRS_TABLE && (
                            <div>{assetData?.first_asset + '/' + assetData.second_asset}</div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="align-right">
                        {assetData.second_asset === 'USD' ? '$' : ''} {moneyFormat(assetData.openPrice)}
                      </div>
                    </td>
                    <td>
                      <div className={'align-right' + accent}>
                        {getLocalisedNumericString(assetData.changePercent, true, NUMBER_OF_DECIMALS_24H_CHANGE_FIELD)}{' '}
                        %
                      </div>
                    </td>
                    <td>
                      <div className="align-right">
                        {assetData.second_asset === 'USD' ? '$' : ''} {moneyFormat(assetData.highPrice)}
                      </div>
                    </td>
                    <td>
                      <div className="align-right">
                        {assetData.second_asset === 'USD' ? '$' : ''} {moneyFormat(assetData.lowPrice)}
                      </div>
                    </td>
                    <td>
                      <div className="align-right">$ {moneyFormat(assetData.marketCap)}</div>
                    </td>
                    {tableType === USD_PAIRS_TABLE && (
                      <td className="markets-table-td">
                        <div className="grey-td-accounts-global">
                          <div className="markets-table-buttons">
                            <VLButton
                              size="m"
                              onClick={e => {
                                setSelectedSecurity(assetData.first_asset + '-' + assetData.second_asset);
                                toggleModal(MODAL_CODES.EXCHANGE);
                                e.stopPropagation();
                              }}
                              disabled={!canTransact}
                              text={i18next.t('transactionButtonsBar.exchange')}
                              leftIcon={<MdSwapHoriz className="bordered-button-icon exchange" />}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                    <td className="markets-table-td">
                      <div className="grey-td-accounts-global">
                        <div
                          className={`markets-table-buttons ${
                            tableType === USD_PAIRS_TABLE ? '' : 'trade-button-markets-tab-center'
                          }`}
                        >
                          <VLButton
                            size="m"
                            onClick={e => {
                              handleTradeButtonClick(
                                tableType === ALL_PAIRS_TABLE ? assetData.security : assetData.first_asset + 'USD'
                              );
                              e.stopPropagation();
                            }}
                            text="Trade"
                            leftIcon={<MdOutlineTrendingUp className="bordered-button-icon" />}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleTradeButtonClick = selectedAsset => {
    const asset = findAsset(assetPairs, selectedAsset);
    updateAssetPair(asset);
    history.push('trade');
  };

  const { activeTab, handleTabClick } = useTabs(MARKET_TABS, MARKET_TABS[0].id);
  const myHandleTabClick = id => {
    clearSearchInput();
    handleTabClick(id);
  };

  const marketsTableData = extractDataForParticularPairTable(tableType, activeTab, MARKET_TABS, marketData24All);

  const marketsTableDataFiltered = searchMarketsTableData(tableType, activeTab, selectedAsset, marketsTableData);

  const sortedData = getSortedAssets(marketsTableDataFiltered);

  const loading = marketData24All.data.loading || !marketData24All.data.length;

  const renderBody = () => {
    if (tableType === USD_PAIRS_TABLE) {
      return renderTable(tableType, sortedData);
    }
    return (
      <>
        <div className="tab-group-wrapper">
          {<TabGroup onTabClick={myHandleTabClick} activeTab={activeTab} tabs={MARKET_TABS} />}
          <div className="inline-div">&nbsp;</div>
        </div>
        {renderTable(tableType, marketsTableDataFiltered)}
      </>
    );
  };

  return <>{loading ? <LoadingIndicator /> : renderBody()}</>;
};

export default MarketsTable;
