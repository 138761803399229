import LimitOrder from './LimitOrder';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { makeLimitOrderRequest } from '../../../redux/actions/websocket';
import { updateTransactionsDB } from '../../../redux/actions/transactions';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  userData: state.login.profileDetails.data,
  cryptoRates: state.transactions.cryptoRates,
  allBooks: state.websocket.allBooks,
  assetPair: state.trade.selectedPair
});

const mapDispatchToProps = {
  makeLimitOrderRequest,
  updateTransactionsDB
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(LimitOrder);
