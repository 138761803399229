export const getAccountTypeDisplayName = account => {
  if (!account?.attr?.type) return '';

  if ((account.attr.type ?? '').toLowerCase() === 'corporate') {
    //only for ui purposes institutional will be displayed instead corporate;
    // behind the scenes corporate value is going to be used
    return 'Institutional';
  }
  if ((account.attr.type ?? '').toLowerCase() === 'individual') {
    return 'Individual';
  }

  return '';
};
