import { Modal } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { AccountFilterContext } from '../../../contexts/AccountFilterContext';
import SearchAccountsInput from '../../SearchAccountsInput';

class CreateAccountModal extends PureComponent {
  state = {
    userInput: undefined,
    selectedAccount: ''
  };
  static contextType = AccountFilterContext;
  componentDidUpdate(prevProps) {
    const accountNumber = this.props.currentAccountNumber;

    if (prevProps.createContactResponse !== this.props.createContactResponse) {
      if (this.props.createContactResponse?.message === 'error') {
        toast.error(this.props.createContactResponse?.reason);
      } else {
        toast.success(this.props.createContactResponse?.reason);
        this.props.onClose();

        this.props.getContactsRequestsRequest({
          currentAccount: accountNumber
        });
      }
    }
  }

  handleOnChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  isDisabled = () => {
    if (isNaN(this.state.userInput) === false && this.state.userInput !== '') {
      return false;
    } else {
      return true;
    }
  };
  setSelectedAcc = acc => {
    const accountNumber = this.props.currentAccountNumber;
    this.setState(
      {
        selectedAccount: acc
      },
      () => {
        this.props.createContactRequest({
          receiverAccount: this.state.selectedAccount,
          requesterAccount: accountNumber
        });
      }
    );
  };
  render() {
    return (
      <Modal className="create-account-modal common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add a new contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchAccountsInput
            onAccountSelected={this.setSelectedAcc}
            selectedAccountId={this.state.selectedAccount}
            ignoreAccounts={[]}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateAccountModal;
