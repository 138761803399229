import React, { PureComponent } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import i18next from 'i18next';
import VLButton from '../../components/Buttons/VLButton';
import VLInput from '../../components/VLInput/VLInput';
class Forgot extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        email: ''
      }
    };
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({
      params: {
        ...this.state.params,
        [name]: value
      }
    });
  };

  handleSendResetPasswordEmail = () => {
    this.props.forgotPasswordRequest(this.state.params);
  };

  render() {
    const { email } = this.state.params;
    const disabled = email ? false : true;

    return (
      <Container fluid className="system-container">
        <Row noGutters className="system-row">
          <Col md={4} className="system-side-image-col forgot">
            <div className="system-slogan-container">
              <div className="system-vaultLink">VaultLink</div>
              <div className="system-slogan">{i18next.t('LOGIN.real-time')}</div>
            </div>
          </Col>
          <Col className="system-control-column">
            <div className="system-card-container">
              <Row noGutters className="system-bottom-header">
                {i18next.t('LOGIN.resetPassword')}
              </Row>
              <Row noGutters>
                <div className="system-form-control-box">
                  <div className="placeholder"> {i18next.t('LOGIN.eMail')}</div>
                  <VLInput onChange={this.handleOnChange} value={email} size="s" name="email" />
                </div>
              </Row>
              <Row noGutters className="system-button-row">
                <VLButton
                  disabled={disabled}
                  onClick={this.handleSendResetPasswordEmail}
                  text={i18next.t('LOGIN.RESETPASSWORD')}
                  width="100%"
                />
              </Row>

              <div className="system-bottom-text">{i18next.t('LOGIN.sendPasswordReset')}</div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Forgot;
