import React from 'react';
import i18next from 'i18next';
import { findFiatCurrencySymbol, getLocalisedDecimalString, replaceCommas } from '../../../utils/generalUtils';
import './PreviewDetails.scss';
import { Row, Col } from 'react-bootstrap';
import VLButton from '../../Buttons/VLButton';

const PreviewDetails = ({
  accountNumber,
  sendAssetData,
  receiveAssetData,
  receiveAmountWithFee,
  exchangePrice,
  buySellFee,
  onBack,
  onComplete
}) => {
  const getFormattedAmount = (amount, asset) => {
    return (
      findFiatCurrencySymbol(asset) + getLocalisedDecimalString(replaceCommas(amount.toString()), asset) + ' ' + asset
    );
  };

  return (
    <div className="buy-sell-preview-details-container">
      <div className="buy">
        <p className="ed-pretitle">{i18next.t('exchangeModal.ed-pretitleSend')}</p>
      </div>
      <div className="buy-amount">
        <h1 className="external-deposit-h1-label exchange-space-bottom">
          {getFormattedAmount(sendAssetData.sendAmount, sendAssetData.sendAsset)}
        </h1>
      </div>
      <div className="sell">
        <p className="ed-pretitle">{i18next.t('exchangeModal.ed-pretitleReceive')}</p>
      </div>
      <div className="sell-amount">
        <h1 className="external-deposit-h1-label exchange-space-bottom">
          {getFormattedAmount(receiveAmountWithFee, receiveAssetData.receiveAsset)}
        </h1>
      </div>
      <Row>
        <Col className="ed-pretitle-small">{i18next.t('exchangeModal.account')}</Col>
        <Col className="external-deposit-title-label col-6">{accountNumber}</Col>
      </Row>
      <Row>
        <Col className="ed-pretitle-small">{i18next.t('exchangeModal.exchangePrice')}</Col>
        <Col className="external-deposit-title-label col-6">
          {getFormattedAmount(exchangePrice, receiveAssetData.receiveAsset)}
        </Col>
      </Row>
      <Row>
        <Col className="ed-pretitle-small">{i18next.t('exchangeModal.vaultLinkfee')}</Col>
        <Col className="external-deposit-title-label col-6">
          {getFormattedAmount(buySellFee, sendAssetData.sendAsset)}
        </Col>
      </Row>
      <Row>
        <Col className="ed-pretitle-small">{i18next.t('exchangeModal.totalValue')}</Col>
        <Col className="external-deposit-title-label col-6">
          {getFormattedAmount(sendAssetData.sendAmount, sendAssetData.sendAsset)}
        </Col>
      </Row>
      <div className="preview-details-footer">
        <hr className="horizontal-line" />
        <p className="external-deposit-central-text ed-text">{i18next.t('exchangeModal.disclaimer')}</p>
        <div style={{ width: '100%', display: 'inline-block', marginBottom: '2.3%' }}>
          <VLButton width="100%" onClick={onComplete} text="Confirm" />

          <VLButton variant={'outline'} text="Return" size="m" width="100%" onClick={onBack} />
        </div>
      </div>
    </div>
  );
};

export default PreviewDetails;
