import React, { PureComponent } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import i18next from 'i18next';
import VLButton from '../../components/Buttons/VLButton';
import VLInput from '../../components/VLInput/VLInput';
class Reset extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        password: '',
        cpassword: ''
      }
    };
  }

  componentDidMount() {
    this.handleParseUrl();
  }

  handleParseUrl = () => {
    const { search } = this.props.history.location;
    if (search) {
      const splitSearchA = search.split('?token=');
      const dataFromUrl = splitSearchA[1].split('&email=');

      const token = dataFromUrl[0];
      const email = dataFromUrl[1];

      this.setState({
        params: {
          ...this.state,
          email: email,
          token: token
        }
      });
    }
  };

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({
      params: {
        ...this.state.params,
        [name]: value
      }
    });
  };

  handleResetPassword = () => {
    this.props.resetPasswordRequest(this.state.params);
  };

  render() {
    const { password, cpassword } = this.state.params;
    const disabled = password === cpassword && password && cpassword ? false : true;

    return (
      <Container fluid className="system-container">
        <Row noGutters className="system-row">
          <Col md={4} className="system-side-image-col reset">
            <div className="system-slogan-container">
              <div className="system-vaultLink">VaultLink</div>
              <div className="system-slogan">{i18next.t('LOGIN.real-time')}</div>
            </div>
          </Col>
          <Col className="system-control-column">
            <div className="system-card-container">
              <Row noGutters className="system-top-header">
                {i18next.t('LOGIN.setNew')}
              </Row>
              <Row noGutters className="system-bottom-header">
                {i18next.t('LOGIN.password')}
              </Row>
              <Row noGutters>
                <div className="system-form-control-box">
                  <div className="placeholder"> {i18next.t('LOGIN.password')}</div>
                  <Row noGutters className="form-control-row">
                    <VLInput
                      onChange={this.handleOnChange}
                      value={password}
                      className="password"
                      size="s"
                      type="password"
                      name="password"
                    />
                  </Row>
                </div>
              </Row>
              <Row noGutters>
                <div className="system-form-control-box">
                  <div className="placeholder"> {i18next.t('LOGIN.confirmPassword')}</div>
                  <Row noGutters className="form-control-row">
                    <VLInput
                      onChange={this.handleOnChange}
                      value={cpassword}
                      className="password"
                      size="s"
                      type="password"
                      name="cpassword"
                    />
                  </Row>
                </div>
              </Row>
              <Row noGutters className="system-button-row">
                <VLButton
                  width="100%"
                  disabled={disabled}
                  onClick={this.handleResetPassword}
                  text={i18next.t('LOGIN.changePassword')}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Reset;
