import { Badge, Col, Row, Table } from 'react-bootstrap';
import { MdDelete, MdFileDownload, MdFileUpload, MdKeyboardBackspace } from 'react-icons/md';
import Select from 'react-select';
import React, { PureComponent } from 'react';
import {
  capitalizeFirstLetter,
  getAuthCookie,
  formatDate,
  handleDownload,
  moneyFormat,
  getLocalisedNumericString
} from '../../utils/generalUtils';
import { createFirmAndSearchParamsObject, createRiskSettingObject } from '../../utils/requestHelpers';
import CreateAccountModal from '../../components/modals/CreateAccountModal';
import LoadingIndicator from '../../components/LoadingIndicator';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { decodeRole } from '../../utils/roleCheckers';
import { SUPER_USER_TYPES } from '../../enums/roles';
import { AMOUNT_REGEX, COMMENT, FRACTIONS } from '../../enums/validation';
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import URLS_CTS from '../../redux/constants/urlsCTS';
import UploadReportModal from '../../components/modals/UploadReportModal';
import { getDecimalByFraction } from '../../utils/transactionsHelpers';
import InformationPopover from '../../components/InformationPopover';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';
import i18next from 'i18next';
import VLIconButton from '../../components/Buttons/VLIconButton';

const defaultConfirmationFunction = () => console.log();

class TransactionDetailsAdmin extends PureComponent {
  state = {
    showCreateUserModal: false,
    showCreateAccountModal: false,
    showConfirmationModal: false,
    showRiskModal: false,
    confirmationType: 'firm',
    objectToDelete: null,
    confirmationOnSuccessFunk: defaultConfirmationFunction,
    firmName: '',
    totalAccountPages: 0,
    totalUsersPages: 0,
    usersPageNumber: 0,
    accountsPageNumber: 0,
    positions: [],
    positionsLoaded: false,
    checkingAccountName: false,
    accountForRiskSetting: null,
    currentScreen: 'accounts',
    displayModal: null,
    inputFormatErrorRiskScore: false,
    inputFormatErrorComment: false,
    isEditingRiskScore: false,
    isEditingComment: false,
    showConfirmationModalConfirmation: false,
    uploadReportUserId: null,
    deleteReportUserId: null,
    reportsLength: null,
    bta_status: {
      bta_status: '',
      id: ''
    }
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.fetchExternalCryptoTransactionById({
        id: this.props.match.params.id
      });
    }
    this.setState(
      {
        firmName: this.props.match.params.id,
        transactionId: this.props.match.params.id
      },
      () => {
        const firm = {
          firm: this.state.firmName
        };
        this.props.getFirmDetailsRequest(firm);
        this.props.getFirmAdminAccountsRequest(firm);
        this.props.getFirmUsersRequest(firm);
        this.props.getAssets();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let totalAccountPages = this.state.totalAccountPages;
    let totalUsersPages = this.state.totalUsersPages;
    let newAccUpdatePositions = false;

    this.setState(
      {
        totalAccountPages,
        totalUsersPages,
        usersPageNumber: this.state.usersPageNumber > totalUsersPages ? totalUsersPages : this.state.usersPageNumber,
        accountsPageNumber:
          this.state.accountsPageNumber > totalAccountPages ? totalAccountPages : this.state.accountsPageNumber,
        positionsLoaded: !newAccUpdatePositions
      },
      () => {
        this.loadAssetPos();
      }
    );
  }

  toggleModalSettings = modalCode => {
    this.setState({ displayModal: modalCode });
  };

  loadAssetPos = () => {
    const accountIds = this.props.firmAdminAccounts.data?.data?.map(acc => acc.account);

    if (
      this.props.websocket?.readyState === 1 &&
      this.props.firmAdminAccounts.data?.data &&
      !this.state.positionsLoaded
    ) {
      this.props.queryPositionsFirmDetails({
        socket: this.props.websocket,
        userid: accountIds,
        firm: this.props.match.params.id,
        accounts: accountIds,
        successCallback: data => {
          this.setState({
            positions: data,
            positionsLoaded: true
          });
        },
        errorCallback: data => {}
      });
    }
  };

  resetConfirmation = () => {
    this.setState({
      confirmationOnSuccessFunk: defaultConfirmationFunction,
      confirmationType: 'firm',
      showConfirmationModal: false,
      objectToDelete: null
    });
  };

  toggleModal = fieldName => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`]
    });
  };

  handlePageClick = (data, field) => {
    this.setState({
      [`${field}PageNumber`]: data.selected
    });
  };

  handleReturnToTransactionsList = () => {
    this.props.history.push('/BTA/transactions');
  };

  handleCreateAccount = data => {
    this.props.checkAccountName({
      data: createFirmAndSearchParamsObject(this.state.firmName, data.name),
      successCallback: () => {
        this.props.createAccountRequest({ ...data, firm: this.state.firmName }, { firm: this.state.firmName });
        toast.success(`${capitalizeFirstLetter(data.type)} ${data.name} created`);
        this.toggleModal('CreateAccount');
        this.setState({
          checkingAccountName: false
        });
      },
      errorCallback: () => {
        toast.error('Account with that name already exists');
        this.setState({
          checkingAccountName: false
        });
      }
    });
    this.setState({
      checkingAccountName: true
    });
  };

  handleCreateUser = data => {
    const actionData = {
      data: data,
      firm: this.state.firmName,
      successCallback: data => {
        this.toggleModal('CreateUser');
      },
      errorCallback: () => {
        toast.error('Email already taken');
        // toast.error(i18next.t('toasts.error'));
      }
    };
    this.props.createAdminAccountRequest(actionData);
  };

  handleDeleteFirm = () => {
    this.props.deleteFirmRequest({
      //   firm: this.state.objectToDelete.firm
      firm: this.state.objectToDelete
    });
    this.props.history.push('/administration');
  };

  handleDeleteUser = () => {
    this.props.deleteAdminAccountRequest(
      {
        userid: this.state.objectToDelete.userid
      },
      { firm: this.state.firmName }
    );
    this.resetConfirmation();
  };

  handleDeleteAccount = () => {
    this.props.deleteAccountRequest(
      {
        account: this.state.objectToDelete.account,
        secondary: this.state.objectToDelete.secondary_account
      },
      { firm: this.state.firmName }
    );
    this.resetConfirmation();
  };

  updateAccountActive = account => {
    const newActiveStatus = account.active_account === 'Y' ? null : 'Y';
    const data = {
      activeAccount: newActiveStatus,
      washCheck: account.washcheck,
      duplicateOrder: account.DupOrd,
      maxDailyTransaction: account.totalallowamount,
      maxPerTransaction: account.maxdollaramount
    };
    this.updateRiskSettings(data, account);
  };

  updateRiskSettings = (data, account = null) => {
    const dataObject = createRiskSettingObject(
      data.washCheck,
      data.activeAccount,
      data.duplicateOrder,
      data.maxDailyTransaction,
      data.maxPerTransaction
    );
    this.props.updateRiskSettings({
      firm: this.state.firmName,
      account: account ? account.account : this.state.accountForRiskSetting?.account,
      socket: this.props.websocket,
      dataObject: dataObject,
      successCallback: data => {
        toast.success(
          i18n.t('firmDetails.updatedRisk') +
            ` ${account ? account.attr?.name : this.state.accountForRiskSetting.attr.name} ` +
            i18n.t('firmDetails.account')
        );
        const firm = {
          firm: this.state.firmName
        };
        this.setState(
          {
            accountForRiskSetting: null,
            showRiskModal: false
          },
          () => {
            this.props.getFirmDetailsRequest(firm);
            this.props.getFirmAdminAccountsRequest(firm);
            this.props.getFirmUsersRequest(firm);
            this.props.getAssets();
          }
        );
      },
      errorCallback: error => {
        toast.error(`Error updating risk setting: ${error}`);
      }
    });
  };

  toggleRiskModal = account => {
    this.setState({
      showRiskModal: true,
      accountForRiskSetting: account
    });
  };

  handleStatusUpdateSuccess = () => {
    this.setState({ showConfirmationModalConfirmation: false });
  };

  handleBtaStatusChange = () => {
    this.props.updateStatusExternalCryptoTrasactions({
      'id': this.state.bta_status.id,
      'bta-status': this.state.bta_status.bta_status
    });
    this.handleStatusUpdateSuccess();
  };

  changeBtaStatus = (id, target) => {
    this.setState({
      bta_status: { bta_status: target.value, id: id },
      showConfirmationModalConfirmation: true
    });
  };

  toggleConfirmationModal = (data, type) => {
    const funct =
      type === 'firm' ? this.handleDeleteFirm : type === 'account' ? this.handleDeleteAccount : this.handleDeleteUser;

    this.setState({
      showConfirmationModal: true,
      confirmationType: type,
      objectToDelete: data,
      confirmationOnSuccessFunk: funct
    });
  };

  handleRiskScoreChange = (id, target) => {
    if (target.target.name === 'score') {
      this.setState({
        inputFormatErrorRiskScore: false
      });
      if (AMOUNT_REGEX.test(target.target.value)) {
        this.props.createExternalTransactionRiskScore({
          'id': id,
          'risk-score': target.target.value
        });
      } else {
        this.setState({
          inputFormatErrorRiskScore: true
        });
      }
    } else {
      if (COMMENT.test(target.target.value)) {
        this.setState({
          inputFormatErrorComment: false
        });
        this.props.createExternalTransactionRiskScore({
          id: id,
          comment: target.target.value
        });
      } else {
        this.setState({
          inputFormatErrorComment: true
        });
      }
    }
  };

  renderTransactionDetails = () => {
    const { loaded, loading } = this.props.firmDetails;
    const transactionDetails = this.props.transactionDetails;

    const totalValue = transactionDetails.amount * transactionDetails.crypto_value;

    const noDecimals = getDecimalByFraction(FRACTIONS['USD']);

    const transactionDate = transactionDetails.timestamp || transactionDetails.created_date;
    if (loaded) {
      return (
        <div className="user-table">
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">
                Transaction hash
              </label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails?.hash ? transactionDetails.hash : '-'}
              </label>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">Time</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDate ? formatDate(transactionDate) : '-'}
              </label>
            </Col>
          </Row>{' '}
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">From</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.source}
              </label>
            </Col>
          </Row>{' '}
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">To</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.destination}{' '}
              </label>
            </Col>
          </Row>{' '}
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">Amount</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.amount}{' '}
              </label>
            </Col>
          </Row>{' '}
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">Asset</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.crypto?.toUpperCase()}{' '}
              </label>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">Fee</label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.fee ? transactionDetails.fee : '-'}{' '}
              </label>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="header-col-transaction">
              <div className="information-popover-content transaction-details-title section-header firm-details-name-label">
                <span>Price per asset</span>
                <InformationPopover
                  placement="right"
                  text={i18next.t('transactionDetailsModal.pricePerAssetInformation')}
                />
              </div>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.crypto_value ? `$ ${moneyFormat(transactionDetails.crypto_value)}` : '-'}{' '}
              </label>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="header-col-transaction">
              <label className="transaction-details-title section-header firm-details-name-label">
                Total USD value
              </label>
            </Col>
            <Col className="header-col">
              <label className="transaction-details-data section-header firm-details-name-label">
                {transactionDetails.amount && transactionDetails.crypto_value
                  ? `$ ${moneyFormat(getLocalisedNumericString(totalValue, true, noDecimals))}`
                  : '-'}{' '}
              </label>
            </Col>
          </Row>
        </div>
      );
    } else if (loading) {
      return <LoadingIndicator />;
    }
  };

  handleReportDeleteConfirmed = () => {
    this.setState({
      isLoading: true,
      deleteReportUserId: null
    });
    this.props.deleteTransactionCryptoBtaReportRequest({
      transactionId: this.state.deleteReportUserId,
      successCallback: () => {
        toast.success('Report deleted successfully');
        this.setState({
          isLoading: false
        });
        this.props.fetchExternalCryptoTransactionById({
          id: this.props.transactionDetails.id
        });
      },
      errorCallback: () => {
        toast.error('Error deleting report');
        this.setState({
          isLoading: false
        });
      }
    });
  };

  renderTransactionTable = () => {
    const { loaded, loading } = this.props.firmAdminAccounts;

    if (loaded) {
      return (
        <div className="user-table">
          <Col>
            <Table className="transaction-details-table" responsive>
              <thead>
                <tr>
                  <th className="center-aligned">TXN ID</th>
                  <th className="center-aligned">Account number</th>
                  <th className="center-aligned">Type</th>
                  <th className="center-aligned">Risk score</th>
                  <th className="center-aligned">BTA status</th>
                  <th className="center-aligned">Report</th>
                  <th className="center-aligned">Comments</th>
                </tr>
              </thead>
              <tbody>{this.renderFirmAccount()}</tbody>
            </Table>
            {this.state.uploadReportUserId ? (
              <UploadReportModal
                id={this.state.uploadReportUserId}
                isOpen={this.state.uploadReportUserId}
                onCompleted={() => {
                  this.setState({
                    uploadReportUserId: null
                  });
                  this.props.fetchExternalCryptoTransactionById({
                    id: this.props.transactionDetails.id
                  });
                }}
                onClose={() => {
                  this.setState({
                    uploadReportUserId: null
                  });
                }}
                uploadCallback={this.props.uploadTransactionCryptoBtaReportRequest}
              />
            ) : null}
            <GeneralConfirmModal
              isOpen={this.state.deleteReportUserId}
              title="Confirm deleting report"
              text="Are you sure you want to delete this report?"
              onConfirm={this.handleReportDeleteConfirmed}
              onCancel={() => {
                this.setState({
                  deleteReportUserId: null
                });
              }}
            />
            {this.state.inputFormatErrorRiskScore && (
              <div className="system-form-control-box-error-text">
                <span className="system-form-control-error-text">
                  <p>The format in which you entered the Risk Score is incorrect.</p>
                </span>
              </div>
            )}
            {this.state.inputFormatErrorComment && (
              <div className="system-form-control-box-error-text">
                <span className="system-form-control-error-text">
                  <p>The format in which you entered the Comment is incorrect.</p>
                </span>
              </div>
            )}
          </Col>

          {this.state.showCreateAccountModal && (
            <CreateAccountModal
              onClose={() => this.toggleModal('CreateAccount')}
              onSuccess={this.handleCreateAccount}
              checkingAccountName={this.state.checkingAccountName}
            />
          )}
        </div>
      );
    } else if (loading) {
      return <LoadingIndicator />;
    }
  };

  getStatusData = () => [
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'rejected',
      label: 'Rejected'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'more-info',
      label: 'More info'
    },
    {
      value: 'critical',
      label: 'Critical'
    }
  ];

  handleReportDownload = id => {
    const token = getAuthCookie();
    handleDownload(
      `${process.env.REACT_APP_BASE_URL_CRYPTO}${URLS_CTS.DOWNLOAD_CRYPTO_REPORT}/${id}`,
      {
        credentials: 'include',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          Accept: '*/*'
        })
      },
      'filename'
    );
  };

  renderReportActions = (transactionDetails, currentUser) => {
    if (!transactionDetails) return null;

    const downloadOption = (
      <a rel="noreferrer" href="#" onClick={() => this.handleReportDownload(transactionDetails.id)}>
        <VLIconButton Icon={MdFileDownload} variant="borderless" />
      </a>
    );

    const uploadOption = (
      <VLIconButton
        onClick={() => {
          this.setState({
            uploadReportUserId: transactionDetails.id
          });
        }}
        Icon={MdFileUpload}
        variant="borderless"
      />
    );

    const deleteOption = (
      <VLIconButton
        onClick={() => {
          this.setState({
            deleteReportUserId: transactionDetails.id
          });
        }}
        Icon={MdDelete}
        variant="borderless"
      />
    );

    // changing reports after bank manager approval is not allowed
    if (transactionDetails?.status !== 'pending') {
      return transactionDetails.has_report ? downloadOption : 'Not uploaded';
    }
    // only bta manager (superuser - vl admin) can upload report
    if (
      currentUser.superAdminType === SUPER_USER_TYPES.BTA_MANAGER ||
      currentUser.superAdminType === SUPER_USER_TYPES.VL_ADMIN
    ) {
      return (
        <>
          {transactionDetails.has_report ? (
            <>
              {downloadOption}
              {deleteOption}
            </>
          ) : (
            uploadOption
          )}
        </>
      );
    }

    // bank manager can only download reports
    if (currentUser.superAdminType === SUPER_USER_TYPES.BANK_MANAGER) {
      return transactionDetails.has_report ? downloadOption : 'Not uploaded';
    }

    return 'N/A';
  };

  renderFirmAccount = () => {
    const transactionDetails = this.props.transactionDetails;
    const userPermissions = this.props.profileDetails?.data ? decodeRole(this.props.profileDetails.data.roles) : '';
    return (
      <>
        <tr className="transaction-table-tr">
          <td className="td">
            <div className="grey-td-report">{transactionDetails.custodian_transaction_id}</div>
          </td>
          <td className="td">
            <div className="grey-td-report">{transactionDetails.userInfo?.restricted_attr?.access_list[0].account}</div>
          </td>
          <td className="td">
            <div className="grey-td-report">{capitalizeFirstLetter(transactionDetails.type)}</div>
          </td>
          <td className="td">
            <div className="grey-td-report">
              {userPermissions.superAdminType === SUPER_USER_TYPES.BTA_MANAGER ||
              userPermissions.superAdminType === SUPER_USER_TYPES.VL_ADMIN ? (
                this.state.isEditingRiskScore ? (
                  <input
                    autoFocus
                    className="input"
                    name="score"
                    defaultValue={transactionDetails.riskScore ? transactionDetails.riskScore : ''}
                    onBlur={e => {
                      this.handleRiskScoreChange(transactionDetails.id, e);
                      this.setState({ isEditingRiskScore: false });
                    }}
                  />
                ) : (
                  <span
                    onClick={() => {
                      this.setState({ isEditingRiskScore: true });
                    }}
                  >
                    {transactionDetails.riskScore ? transactionDetails.riskScore : '-'}
                  </span>
                )
              ) : (
                transactionDetails.riskScore
              )}
            </div>
          </td>
          <td className="td">
            {' '}
            <div className="grey-td-report">
              {(userPermissions.superAdminType === SUPER_USER_TYPES.BTA_MANAGER ||
                userPermissions.superAdminType === SUPER_USER_TYPES.VL_ADMIN) &&
              (transactionDetails?.['bta-status'] === 'pending' ||
                transactionDetails?.['bta-status'] === 'more-info') ? (
                <Select
                  key={this.state.bta_status.bta_status}
                  classNamePrefix="select-form"
                  name="status"
                  defaultValue={this.findBtaStatus(transactionDetails?.['bta-status'])}
                  options={this.getStatusData()}
                  isSearchable={false}
                  onChange={e => {
                    this.changeBtaStatus(transactionDetails.id, e);
                  }}
                  value={this.findBtaStatus(transactionDetails?.['bta-status'])}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                />
              ) : (
                <Badge pill className={'status-badge-' + transactionDetails?.['bta-status']}>
                  {capitalizeFirstLetter(transactionDetails?.['bta-status']).replace('-', ' ')}
                </Badge>
              )}
            </div>
          </td>
          <td className="td">
            <div className="grey-td-report">{this.renderReportActions(transactionDetails, userPermissions)}</div>
          </td>
          <td className="td-comment">
            <div className="grey-td-report">
              {this.state.isEditingComment ? (
                <input
                  autoFocus
                  className="input"
                  name="comment"
                  defaultValue={transactionDetails.comment ? transactionDetails.comment : ''}
                  onBlur={e => {
                    this.handleRiskScoreChange(transactionDetails.id, e);
                    this.setState({ isEditingComment: false });
                  }}
                />
              ) : (
                <span
                  className="span-comment"
                  style={{ width: '350px' }}
                  onClick={() => {
                    this.setState({ isEditingComment: true });
                  }}
                >
                  {transactionDetails.comment ? transactionDetails.comment : '-'}
                </span>
              )}
            </div>
          </td>
        </tr>
      </>
    );
  };
  changeStatusScreen = screen => {
    this.setState({ currentScreen: screen });
  };

  findBtaStatus = bta_status => {
    const result = this.getStatusData().filter(element => element.value === bta_status);
    return result[0];
  };

  renderBody = () => {
    switch (this.state.currentScreen) {
      case 'accounts':
        return (
          <React.Fragment>
            <Card internalSpacing="0" spacing="0px 0px 16px 0px" borderless>
              {this.renderTransactionDetails()}
            </Card>
            <Card internalSpacing="0" borderless>
              {this.renderTransactionTable()}
            </Card>
          </React.Fragment>
        );

      default:
        return <></>;
    }
  };

  toggleModalConfirmation = () => {
    this.setState({
      showConfirmationModalConfirmation: !this.state.showConfirmationModalConfirmation
    });
  };

  getTitleModalConfirmation = bta_status => {
    switch (bta_status) {
      case 'approved':
        return 'APPROVE';
      case 'rejected':
        return 'REJECT';
      case 'more-info':
        return 'MORE INFO';
      case 'critical':
        return 'CRITICAL';
      default:
        return 'PENDING';
    }
  };

  render() {
    const { data, loaded, loading } = this.props.firmDetails;
    if (loading || !loaded) {
      return <LoadingIndicator />;
    }
    if (loaded) {
      return (
        <React.Fragment>
          {this.state.showConfirmationModalConfirmation && (
            <GeneralConfirmModal
              isOpen={this.state.showConfirmationModalConfirmation}
              title={`${this.getTitleModalConfirmation(this.state.bta_status.bta_status)}`}
              text={`Are you sure you want to change BTA Status for this transaction to ${this.state.bta_status.bta_status.toUpperCase()}?`}
              onConfirm={() => this.handleBtaStatusChange()}
              onCancel={() => this.toggleModalConfirmation('Confirmation')}
            />
          )}
          <Row noGutters onClick={this.handleReturnToTransactionsList} className="return-button">
            <MdKeyboardBackspace />
            {'Return to transactions'}
          </Row>
          <div className="container-user-details">
            <div className="firm-details chassis-interior-content">
              <Row className="title-row">
                <PageTitle text={data.details?.firm || 'Transaction details'} />
              </Row>
              {this.renderBody()}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default TransactionDetailsAdmin;
