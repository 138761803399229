import VLButton from '../../Buttons/VLButton';
import './TradeSideSwitch.scss';

const TradeSideSwitch = ({ side, onSideChange }) => {
  return (
    <div className="trade-side-switch">
      <VLButton
        size="m"
        variant={`${side === 'S' ? 'success-outline' : 'success'}`}
        onClick={() => onSideChange('B')}
        text="Buy"
      />

      <VLButton
        size="m"
        variant={`${side === 'B' ? 'danger-outline' : 'danger'}`}
        onClick={() => onSideChange('S')}
        text="Sell"
      />
    </div>
  );
};

export default TradeSideSwitch;
