import * as COMPONENT from '../containers';

import React, { Fragment, PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from '../components/Footer';
import LoadingIndicator from '../components/LoadingIndicator';
import { ToastContainer } from 'react-toastify';
import { getAuthCookie } from '../utils/generalUtils';
import PeriodicalPriceUpdateComponent from '../components/PeriodicalPriceUpdateComponent';
import PeriodicalContactUpdateComponent from '../components/PeriodicalContactUpdateComponent';

class Routes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userData: this.props.userData
    };
  }

  componentDidMount() {
    if (getAuthCookie()) {
      this.handleAutoLogin();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        userData: this.props.userData
      });
    }
  }

  handleAutoLogin = () => this.props.getAccountRequest();

  handleDetermineRoutes = () => {
    //console.log('USER DATA', this.props);
    if (this.state.userData) {
      const { loaded, loading } = this.state.userData;

      let routes;
      if (loaded) {
        //console.log('renderLoggedInRoutes');
        // if the user is logged in
        routes = this.renderLoggedInRoutes();
      } else if (loading) {
        //console.log('renderLoading');
        routes = this.renderNotLoggedInRoutes();
      } else {
        // if the user is not logged in,
        //console.log('renderNotLoggedInRoutes', this.props.userData);
        routes = this.renderNotLoggedInRoutes();
      }

      return routes;
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  renderNotLoggedInRoutes = () => {
    return (
      <Switch onUpdate={this.handleScrollToTop}>
        <Route exact path="/login" component={COMPONENT.Login} />
        <Route exact path="/forgot" component={COMPONENT.Forgot} />
        <Route exact path="/reset" component={COMPONENT.Reset} />
        <Route exact path="/support" component={COMPONENT.Support} />
        <Route exact path="/validateDeviceLocation" component={COMPONENT.DeviceLocationGuard} />
        <Route component={COMPONENT.Login} />
      </Switch>
    );
  };

  renderLoggedInRoutes = () => {
    return (
      <>
        <Switch onUpdate={this.handleScrollToTop}>
          <Route component={COMPONENT.Chassis} />
        </Switch>
        <PeriodicalContactUpdateComponent />
        <PeriodicalPriceUpdateComponent />
        <Footer />
      </>
    );
  };

  renderLoading = () => <LoadingIndicator />;

  render() {
    const routes = this.handleDetermineRoutes();
    return (
      <Fragment>
        <div className="app-container">
          <ToastContainer hideProgressBar={true} autoClose={3000} />
          {routes}
        </div>
      </Fragment>
    );
  }
}

export default Routes;
