import React from 'react';
import { Modal } from 'react-bootstrap';
import VLButton from '../../Buttons/VLButton';
import './GeneralConfirmModal.scss';

const GeneralConfirmModal = ({ isOpen, title, text, onConfirm, onCancel, style, contentClassName }) => {
  return (
    <Modal
      className="confirmation-modal common-modal"
      contentClassName={contentClassName}
      centered
      show={isOpen}
      onHide={onCancel}
      style={style}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirmation-modal-body">
        <p className="confirmation-modal-text">{text}</p>
      </Modal.Body>
      {onConfirm ? (
        <Modal.Footer className="transaction-confirmation-modal-footer">
          <div className="sfcb-button-wrapper">
            <VLButton
              width="100%"
              onClick={onConfirm}
              text="Confirm"
            />
          </div>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default GeneralConfirmModal;
