import { all, fork } from 'redux-saga/effects';

import administrationSaga from './watchers/administration';
import analyticsSaga from './watchers/analytics';
import loginSaga from './watchers/login';
import settingsSaga from './watchers/settings';
import transactionsSaga from './watchers/transactions';
import transactionsCTSSaga from './watchers/transactionsExternal';
import websocketSaga from './watchers/websocket';
import tradeSaga from './watchers/trade';
import balanceSaga from './watchers/balance';

export default function* root() {
  yield all([
    fork(loginSaga),
    fork(websocketSaga),
    fork(settingsSaga),
    fork(tradeSaga),
    fork(transactionsSaga),
    fork(administrationSaga),
    fork(analyticsSaga),
    fork(transactionsCTSSaga),
    fork(balanceSaga)
  ]);
}
