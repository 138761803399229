import './PreviewDepositStep.scss';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import LoadingWrapper from '../../../components/LoadingWrapper/LoadingWrapper';
import { formatDate, handleLastUpdateTimestamp } from '../../../utils/generalUtils';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';

const PreviewDepositStep = ({ isLoading, onSubmit, onClose, asset, amount, account }) => {
  const currentDate = formatDate(new Date(), {
    dateOnly: true
  });

  return (
    <>
      <LoadingWrapper isLoading={isLoading}>
        <div>
          <div className="ed-info-wrapper">
            <p className="ed-pretitle">
              {i18next.t('externalDepositWithdraw.paragraphInTitleType') +
                'deposit' +
                i18next.t('externalDepositWithdraw.paragraphInTitleType2')}
            </p>
            <h2 className="external-deposit-h1-label">
              <span className="ed-asset">{asset}</span>${amount}
            </h2>
          </div>
          <div className="submit-data-div">
            <Row>
              <Col className="deposit-preview-title col-2"></Col>
              <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.destinationAccount')}</Col>
              <Col className="external-deposit-title-label source-account col-6">{account}</Col>
            </Row>
            <Row>
              <Col className="deposit-preview-title col-2"></Col>
              <Col className="ed-pretitle-small">{i18next.t('externalDepositWithdraw.depositDate')}</Col>
              <Col className="external-deposit-title-label source-account col-6">{`${currentDate}`}</Col>
            </Row>

            <hr className="horizontal-line" />

            <p className="external-deposit-central-text ed-text">
              <br />
              {i18next.t('externalDepositWithdraw.disclaimer') +
                ' deposit' +
                i18next.t('externalDepositWithdraw.disclaimer2')}
            </p>
          </div>
          <Row className="external-deposit-footer-bar">
            <VLButton
              width="100%"
              disabled={isLoading}
              onClick={onSubmit}
              text="Request deposit"
            />

            <p className="sfcb-button-text">{handleLastUpdateTimestamp()}</p>
          </Row>
        </div>
      </LoadingWrapper>
    </>
  );
};

export default PreviewDepositStep;
