/* eslint-disable import/no-anonymous-default-export */
import * as CONSTANTS from '../constants/login';

const initialState = {
  userData: {
    data: {},
    accountData: {},
    loading: false,
    loaded: false,
    error: ''
  },
  profileDetails: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  userPermissions: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.GET_USER_PROFILE:
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_PROFILE_SUCCESS: {
      const userProfile = action.data.data[0];
      return {
        ...state,
        userData: {
          ...state.userData,
          data: {
            ...state.userData.data,
            ...userProfile
          }
        },
        profileDetails: {
          loading: false,
          loaded: true,
          data: userProfile,
          error: ''
        }
      };
    }

    case CONSTANTS.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };
    ///////////////////////////////////////
    case CONSTANTS.EDIT_PROFILE_SUCCESS: {
      const userProfile = action.data.data;
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          loading: false,
          loaded: true,
          data: {
            ...state.profileDetails.data,
            attr: {
              ...userProfile
            }
          },
          error: ''
        }
      };
    }

    ////////////////////////////////
    case CONSTANTS.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: true,
          data: { ...action.data },
          error: ''
        }
      };

    case CONSTANTS.GET_ACCOUNT_ERROR:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    case CONSTANTS.LOGIN_REQUEST:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
          loaded: false,
          error: ''
        }
      };

    case CONSTANTS.LOGIN_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.LOGIN_ERROR:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      };

    case CONSTANTS.LOGIN_RESET:
      return {
        ...state,
        userData: {
          ...initialState.userData
        }
      };
    /////////////////////////////////////////////////////////////////
    case CONSTANTS.GET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions
        }
      };
    case CONSTANTS.GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions,
          loading: false,
          loaded: true,
          data: action.data.data,
          error: ''
        }
      };

    case CONSTANTS.GET_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions,
          loading: false,
          loaded: true,
          data: {},
          error: action.error.message
        }
      };

    default:
      return state;
  }
};
