export const EMAIL_REGEX =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#-/.,^-_`~()<>\\|";:'])[A-Za-z\d@$!%*?&#-/.,^-_`~()<>|";:']{8,}$/;

export const AMOUNT_REGEX = /^[0-9,]*[.]{0,1}[0-9]*$/;

export const FIRM_CODE_REGEX = /^[a-zA-Z0-9&-]*$/;

export const FIRM_NAME_REGEX = /^[a-zA-Z0-9& -]*$/;

export const ACCOUNT_NAME_REGEX = /^[a-zA-Z0-9&-]*$/;

export const FIRST_LAST_NAME_REGEX = /^[a-zA-Z0-9& -]*$/;

export const COMMENT = /^[a-zA-Z0-9& -]*$/;

export const DAYS_MAP = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7
};

export const DARK_MODE = 'dark';
export const LIGHT_MODE = 'light';

export const ACTIVATED = 'Y';
export const DEACTIVATED = 'N';

export const USER_DISPLAY = 'user';
export const ACCOUNT_DISPLAY = 'account';

export const REMOVE_LIMIT = 'REMOVE_LIMIT';
export const UPDATE_LIMIT = 'UPDATE_LIMIT';

export const NO_TIMELOCK = 'N/A';
export const REMOVE_TIMELOCK = 'REMOVE_TIMELOCK';
export const DEFAULT_START_TIMELOCK = '9:00';
export const DEFAULT_END_TIMELOCK = '17:00';

export const DEPOSIT_TYPE = 'deposit';
export const WITHDRAW_TYPE = 'withdraw';
export const SEND_TYPE = 'send';
export const SCHEDULED_SEND_TYPE = 'scheduled_transfer';
export const REQUEST_TYPE = 'request';
export const ESCROW_TYPE = 'escrow';
export const NEGOTIATION_TYPE = 'negotiation';
export const TO_BANK = 'to-bank';
export const TO_NETWORK = 'to-network';
export const STAKE = 'stake';
export const EXIT_STAKE = 'exit-stake';

export const USD_MAX_AMOUNT = 25000;
export const BUY_SELL_FEE = 0.01;

export const PENDING = 'pending';
export const SUCCESS = 'success';
export const ERROR = 'error';

export const MAX_DESCRIPTION_DISPLAY_LEN = 48;
export const MAX_TRANSACTION_COUNTERPARTY_LEN = 16;
export const MAX_DASH_TRANSACTION_ROWS = 20;
export const MAX_ROWS_IN_TABLE = 10;

export const SHOW_ALL_ACCOUNTS_FLAG = 'all';
export const SHOW_ALL_FIRMS_FLAG = 'allFirms';
export const SHOW_ALL_CONTACT_FLAG = 'allAccounts';

export const SELECT_STATUS_FLAG = 'selectStatus';
export const SELECT_RANGE_FLAG = 'selectRange';
export const SELECT_COUNTERPARTY_FLAG = 'counterparty';
export const SEARCH_DESCRIPTION = 'description';

export const SEARCH_TYPE_FIRMS = 'firms';
export const SEARCH_TYPE_ACCOUNTS = 'accounts';
export const FILTER_CATEGORY = 'category';
export const FILTER_ASSET = 'asset';
export const FILTER_STATUS = 'status';
export const FILTER_BTA_STATUS = 'btaStatus';
export const FILTER_ACCOUNT = 'account';
export const FILTER_ACTION = 'action';
export const FILTER_AMOUNT_RANGE = 'amountRange';
export const FILTER_DESCRIPTION = 'description';
export const FILTER_ENTERED_BY = 'enteredBy';
export const FILTER_TRADE_ID = 'tradeID';
export const FILTER_TYPE = 'type';
export const FILTER_COUNTERPARTY = 'counterparty';
export const FILTER_FIRM = 'firm';
export const FILTER_SIDE = 'sendOrReceive';
export const FILTER_ROLE = 'role';
export const FILTER_OPTIONS = 'options';
export const ACCOUNT_TYPE = 'type';
export const FILTER_FIRM_USER = 'firmuser';
export const FILTER_FIRM_ADMIN = 'firmadmin';
export const FILTER_VALUE = 'value';
/// ACCOUNT FILTER
export const DATA_RANGE = 'Date range';
export const USER_ID = 'User ID';
export const NAME = 'Name';
export const MANAGER = 'Manager';
export const SCORE = 'Score';
export const TIER = 'Tier';
export const TYPE = 'Type';
export const ORDER_STATUS = 'Order_status';
export const BTA_STATUS = 'BTA_status';
export const AMOUNT = 'Amount';
export const ASSET = 'Asset';
export const VALUE = 'Value';
export const FBO = 'FBO';
export const STATUS = 'BM_status';
export const TXNID = 'TXN ID';
export const FILTER_AMOUNT_COUNTERPARTY = 'amountCounterparty';
export const FILTER_ASSET_COUNTERPARTY = 'assetCounterparty';
export const FILTER_VALUE_COUNTERPARTY = 'valueCounterparty';
export const ACCOUNT_NUMBER = 'Account number';
///RISK SETTINGS
export const TRANSACTIONTABLE_FILTER_LIST = [TIER, TYPE, AMOUNT, ASSET, VALUE, FBO, STATUS];
export const TRANSACTIONTABLE_FILTER_LIST_OTHER = [TYPE, ASSET];
export const TRANSACTIONTABLE_FILTER_LIST_CANCELED = [ORDER_STATUS, ASSET];
export const TRANSACTIONTABLE_FILTER_LIST_PENDING = [TYPE, ASSET, BTA_STATUS];
export const TRANSACTIONTABLE_FILTER_LIST_REVIEW = [TYPE, ASSET, BTA_STATUS, STATUS];

export const TRANSACTIONTABLE_FILTER_LIST_PENDING_USD = [TYPE];
export const TRANSACTIONTABLE_FILTER_LIST_REVIEWED_USD = [TYPE, STATUS];
export const TRANSACTION_SEARCH_LIST = [TXNID, ACCOUNT_NUMBER];
export const TRANSACTION_SEARCH_LIST_OTHER = [TXNID];
export const STAKING_SEARCH_LIST = [TXNID, ACCOUNT_NUMBER];

export const MIN_PER_TRANSACTION = 'minPerTransaction';
export const MAX_PER_TRANSACTION = 'maxPerTransaction';
export const MAX_DAILY_TRANSACTION = 'maxDailyTransaction';

export const WASH_CHECK = 'washCheck';
export const RISK_CHECK = 'riskCheck';
export const ACTIVE_ACCOUNT = 'activeAccount';
export const DUPLICATE_ORDER_CHECK = 'duplicateOrder';

export const MANAGEMENT_FILTER_LIST = [FILTER_ROLE];

export const MANAGEMENT_FILTER_LABEL = {
  [FILTER_ROLE]: 'Role'
};

export const ACCOUNT_TYPE_LABEL = {
  [ACCOUNT_TYPE]: 'Account type'
};

export const CONTACT_FILTER_OPTION = [ACCOUNT_TYPE];
export const CONTACT_SEARCH_OPTIONS = [ACCOUNT_NUMBER];

export const CONTACTS_FILTER_LABELS = {
  [FILTER_OPTIONS]: 'Options',
  [ACCOUNT_TYPE]: 'Type'
};

export const DASHBOARD_FILTER_LIST = [
  // //   FILTER_CATEGORY,
  FILTER_TYPE,
  AMOUNT,
  FILTER_ASSET,
  FILTER_VALUE,
  FILTER_COUNTERPARTY,
  FILTER_AMOUNT_COUNTERPARTY,
  FILTER_ASSET_COUNTERPARTY,
  FILTER_VALUE_COUNTERPARTY,
  STATUS
];

export const DASHBOARD_SEARCH_LIST = [TXNID];

export const REPORTS_FILTER_LIST = [
  FILTER_TYPE,
  FILTER_FIRM,
  FILTER_ASSET,
  FILTER_CATEGORY,
  FILTER_ACCOUNT,
  FILTER_SIDE
];

export const RISK_SET_INPUT_LIST = [MAX_PER_TRANSACTION, MAX_DAILY_TRANSACTION];

export const RISK_SET_CHECKBOX_LIST = [WASH_CHECK, ACTIVE_ACCOUNT, DUPLICATE_ORDER_CHECK];

export const RISK_SET_CHECKBOX_LABELS = {
  [WASH_CHECK]: 'Wash Check',
  [RISK_CHECK]: 'Risk Check',
  [DUPLICATE_ORDER_CHECK]: 'Duplicate Order Check',
  [ACTIVE_ACCOUNT]: 'Active Account'
};

export const RISK_SET_INPUT_LABELS = {
  [MAX_PER_TRANSACTION]: 'Max amount per Transaction',
  [MAX_DAILY_TRANSACTION]: 'Max daily Transaction'
};

export const REPORT_FILTER_LABELS = {
  [FILTER_TYPE]: 'Report type',
  [FILTER_SIDE]: 'Send/Receive',
  [FILTER_ASSET]: 'Asset',
  [FILTER_FIRM]: 'Firm',
  [FILTER_CATEGORY]: 'Category',
  [FILTER_ACCOUNT]: 'Account'
};

export const DASHBOARD_SEARCH_LABELS = {
  [FILTER_ENTERED_BY]: 'enteredBy',
  [FILTER_TRADE_ID]: 'tradeID',
  [FILTER_DESCRIPTION]: 'description'
};

export const DASHBOARD_DROPDOWN_LABELS = {
  [FILTER_ACTION]: 'Action',
  [FILTER_STATUS]: 'Status',
  [FILTER_ASSET]: 'Asset',
  [FILTER_AMOUNT_RANGE]: 'Amount Range',
  [FILTER_COUNTERPARTY]: 'Counterparty'
};

export const PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_CRYPTO = [FILTER_TYPE, FILTER_STATUS];
export const PAYMENT_REQUESTS_FILTER_LIST_DEPOSIT_WITHDRAWAL_USD = [FILTER_TYPE, FILTER_STATUS];
export const PAYMENT_REQUESTS_FILTER_LIST_OTHER = [FILTER_TYPE];

export const REQUEST_ACTION_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound'
};

export const TRANSACTION_API_TYPES = {
  WITHDRAW: 'withdraw',
  IOI: 'ioi',
  WITHDRAWAL: 'withdrawal',
  DEPOSIT: 'deposit',
  TRADE: 'trade',
  SEND: 'send',
  RECIEVE: 'recieve', // IT'S A TYPO
  TO_BANK: 'to-bank',
  TO_NETWORK: 'to-network',
  EXCHANGE: 'exchange',
  ESCROW: 'escrow',
  FINISHED_ESCROW: 'Finished Escrow',
  STAKING: 'staking',
  STAKING_REWARD: 'staking-reward',
  LIMIT: 'limit'
};

export const PENDING_API_TYPES = {
  ESCROW: 'escrow',
  REQUEST: 'request',
  SCHEDULED_TRANSFER: 'scheduled_transfer'
};

export const ROLE_API_TYPES = {
  USER: 'firmuser',
  ADMIN: 'firmadmin'
};

export const ROLE_API_LABELS = {
  [FILTER_FIRM_ADMIN]: 'Admin',
  [FILTER_FIRM_USER]: 'User'
};

export const PAYMENTS_TABS = {
  ALL: 'all',
  REQUESTS: 'requests',
  ESCROW: 'escrow',
  SCHEDULED: 'scheduled_transfer',
  APPROVAL: 'approval',
  REPEATING: 'repeating',
  FUTURE: 'future'
};

export const REPORT_TABS = {
  ALL: 'all',
  CORE_BANKING: 'core',
  LARGE_TRANSFERS: 'largeTransfers',
  RECONCILIATION: 'reconciliation',
  OVER_10K: 'over10k'
};

export const REPORT_TABS_LABELS = {
  ALL: 'All',
  CORE_BANKING: 'Core Banking - Transfers & Settlements',
  LARGE_TRANSFERS: 'Large Transfers Report',
  RECONCILIATION: 'Reconciliation Reports',
  OVER_10K: 'Currency Transaction Reports'
};

export const FRACTIONS = {
  USD: 100,
  BTC: 100000000,
  ETH: 100000000,

  USDC: 10000,
  FIL: 10000,

  BCH: 10000000,
  LTC: 10000000,
  ETC: 10000000,

  VLC$: 100
};

export const ZERO_ASSET_FORMAT = {
  USD: '0.00',
  BTC: '0.00000000',
  ETH: '0.00000000',

  USDC: '0.00',
  FIL: '0.00',

  BCH: '0.00000',
  VLC$: '0.00'
};

export const SECURITY_CODES = {
  USD: 'USD',
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC',
  FIL: 'FIL',
  BCH: 'BCH',
  ETC: 'ETC',
  LTC: 'LTC',
  VLC$: 'VLC$'
};

export const SECURITY_CODE_NAME_MAP = {
  USD: 'US Dollar',
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  FIL: 'Filecoin',
  BCH: 'Bitcoin Cash',
  ETC: 'Ethereum Classic',
  LTC: 'Litecoin',
  USDC: 'USD Coin',
  VLC$: 'VL$'
};

/**
 * CMT supported securities for showing on the Trade tab.
 */
export const CMT_SECURITIES = {
  BCHBTC: {
    base: 'BCH',
    quote: 'BTC',
    pair: 'BCHBTC'
  },
  BCHETH: {
    base: 'BCH',
    quote: 'ETH',
    pair: 'BCHETH'
  },
  BCHUSD: {
    base: 'BCH',
    quote: 'USD',
    pair: 'BCHUSD'
  },
  BTCUSD: {
    base: 'BTC',
    quote: 'USD',
    pair: 'BTCUSD'
  },
  BTCUSDC: {
    base: 'BTC',
    quote: 'USDC',
    pair: 'BTCUSDC'
  },
  ETCBTC: {
    base: 'ETC',
    quote: 'BTC',
    pair: 'ETCBTC'
  },
  ETCETH: {
    base: 'ETC',
    quote: 'ETH',
    pair: 'ETCETH'
  },
  ETCUSD: {
    base: 'ETC',
    quote: 'USD',
    pair: 'ETCUSD'
  },
  ETHBTC: {
    base: 'ETH',
    quote: 'BTC',
    pair: 'ETHBTC'
  },
  ETHUSD: {
    base: 'ETH',
    quote: 'USD',
    pair: 'ETHUSD'
  },
  ETHUSDC: {
    base: 'ETH',
    quote: 'USDC',
    pair: 'ETHUSDC'
  },
  FILBTC: {
    base: 'FIL',
    quote: 'BTC',
    pair: 'FILBTC'
  },
  FILETH: {
    base: 'FIL',
    quote: 'ETH',
    pair: 'FILETH'
  },
  FILUSD: {
    base: 'FIL',
    quote: 'USD',
    pair: 'FILUSD'
  },
  LTCBTC: {
    base: 'LTC',
    quote: 'BTC',
    pair: 'LTCBTC'
  },
  LTCETH: {
    base: 'LTC',
    quote: 'ETH',
    pair: 'LTCETH'
  },
  LTCUSD: {
    base: 'LTC',
    quote: 'USD',
    pair: 'LTCUSD'
  },
  USDCUSD: {
    base: 'USDC',
    quote: 'USD',
    pair: 'USDCUSD'
  }
};

/**
 * Checks if the FI security pair is supported by CMT.
 *
 * @param pair {{security: string, pair_first:string, pair_second: string}} - The pair to check.
 * @return {boolean} - True if the pair is supported by CMT, false otherwise.
 */
export const isCmtSupported = pair => !!CMT_SECURITIES[pair.security];

export const SECURITY_CODE_PAIRS_MAP = {
  BCHBTC: 'BCHBTC',
  BCHETH: 'BCHETH',
  BCHUSD: 'BCHUSD',
  BTCUSD: 'BTCUSD',
  BTCUSDC: 'BTCUSDC',
  ETCBTC: 'ETCBTC',
  ETCETH: 'ETCETH',
  ETCUSD: 'ETCUSD',
  ETHBTC: 'ETHBTC',
  ETHUSD: 'ETHUSD',
  ETHUSDC: 'ETHUSDC',
  FILBTC: 'FILBTC',
  FILETH: 'FILETH',
  FILUSD: 'FILUSD',
  LTCBTC: 'LTCBTC',
  LTCETH: 'LTCETH',
  LTCUSD: 'LTCUSD',
  USDCUSD: 'USDCUSD'
};

export const IGNORED_PAIRS = ['BTCETC', 'BTCFIL', 'BTCLTC', 'ETHFIL', 'ETHLTC', 'FILLTC', 'USDUSDC'];

export const WALLET_SUPPORTING_ASSET = {
  ETH: ['USDC']
};

export const SECURITY_FEE_CURRENCY = {
  BTC: 'Sat/B',
  BCH: 'Sat/B',
  LTC: 'Sat/B',
  DASH: 'Sat/B',
  ETH: 'Gwei',
  ETC: 'Gwei',
  PAX: 'Gwei',
  USDC: 'Gwei',
  FIL: 'FIL', // Not sure if correct
  USDT: 'Gwei',
  QCAD: 'Gwei',
  EURS: 'Gwei',
  BRLM: 'Gwei',
  PAXG: 'Gwei',
  CDAG: 'Gwei',
  XRP: 'XRP/op',
  XLM: 'XLM/op',
  XMR: 'Atomic Units/B',
  AION: 'Amp'
};

export const SECURITY_FEE_CURRENCY_FRACTIONS = {
  BTC: 0.00000001,
  ETH: 0.000000001
};

export const MODAL_CODES = {
  IOI: 'ioi',
  WITHDRAW: 'withdraw',
  DEPOSIT: 'deposit',
  TRADE: 'trade',
  SEND: 'send',
  REQUEST: 'request',
  EXCHANGE: 'exchange',
  ESCROW: 'escrow',
  DETAILS: 'details',
  SCHEDULED_TRANSFER: 'scheduled_transfer',
  STAKE: 'stake',
  EXIT_STAKE: 'exit_stake',
  SETTINGS: 'settings'
};

export const MODAL_STEPS = {
  DETAILS: 'details',
  ACCEPT: 'accept',
  CANCEL: 'cancel',
  ACCEPT__SUCCESS: 'accept-success',
  CANCEL__SUCCESS: 'cancel-success'
};

export const EXPORT__TYPE = {
  CSV: 'CSV',
  PDF: 'PDF'
};

export const FIAT_SYMBOLS = {
  ETH: 'ETH',
  BTC: 'BTC',
  FIL: 'FIL',
  USDC: 'USDC',
  BCH: 'BCH',
  ETC: 'ETC',
  LTC: 'LTC',
  USD: '$'
};

export const PEN = 'PEN';
export const CLOCK = 'CLOCK';
export const LABEL = 'LABEL';
export const SAVE = 'SAVE';

export const STAKING_ERROS = {
  INSUFFICIENT_FUNDS: 'inssuficient_funds',
  NON_MULTIPLE_32_UP: 'non_multiple_32_up',
  NON_MULTIPLE_32_DOWN: 'non_multiple_32_down'
};

export const PENDING_TRANSACTIONS = {
  filter: 'pending',
  page: '0,1000,createdDate' //TO DO
};

export const NON_PENDING_TRANSACTIONS = {
  filter: 'non-pending',
  page: '0,1000,createdDate' //TO DO
};

export const CRYPTO_MODAL_CONFIRMATION = 'crypto';
export const USD_MODAL_CONFIRMATION = 'usd';

export const USD_PAIRS_TABLE = 'USD PAIRS';
export const ALL_PAIRS_TABLE = 'ALL PAIRS';

export const ASCENDING = 'ASCENDING';
export const DESCENDING = 'DESCENDING';

export const USA_STATES = [
  'Alaska',
  'Alabama',
  'Arkansas',
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'District of Columbia',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Iowa',
  'Idaho',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Massachusetts',
  'Maryland',
  'Maine',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Mississippi',
  'Montana',
  'North Carolina',
  'North Dakota',
  'Nebraska',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'Nevada',
  'New York',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Virginia',
  'Vermont',
  'Washington',
  'Wisconsin',
  'West Virginia',
  'Wyoming'
];

export const RISK_CONTROL_SETTINGS_FIELDS = [
  'Daily limit notional',
  'Per transaction limit notional',
  'Max away percentage',
  'Duplicate order',
  'Speed check'
];

export const RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER = {
  'Daily Limit Notional': 'totalallowamount',
  'Per Transaction Limit Notional': 'totalallowqty',
  'Max Away Percentage': 'percentagelimit',
  'Duplicate Order': 'DupOrd',
  'Speed Check': 'SpeedChk'
};

export const RISK_CONTROL_SETTINGS_DESCRIPTION_MAPPER = {
  'Daily limit notional':
    'The maximum dollar value for trades for the day, taking into consideration any pending orders.',
  'Per transaction limit notional':
    'The maximum size of an order, as measured by the total dollar value of the order (price * order quantity).',
  'Max away percentage': 'The maximum price deviation allowed for each order.',
  'Duplicate order': 'A throttle for the number or orders passing through the system in a given second.',
  'Speed check': 'The maximum numbers of orders passing through the system in a given second.'
};
