import { connect } from 'react-redux';
import DashboardTransactionsTables from './DashboardTransactionsTables';
import { getAccountTransactions } from '../../redux/actions/transactions';
import {
  fetchExternalPendingCryptoTrasactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchExternalUSDReviewedTransactions
} from '../../redux/actions/transactionsExternal';

const mapStateToProps = state => ({
  internalExecutedTransactions: state.transactions.events,
  assetPairs: state.trade.assetPairs,
  transactionsPendingCrypto: state.externalTransactions.externalTransactionsPending,
  transactionsPendingUSD: state.externalTransactions.externalTransactionsUsdPending,
  transactionsReviewedCrypto: state.externalTransactions.externalTransactionsReviewed,
  transactionsReviewedUSD: state.externalTransactions.externalTransactionsUsdReviewed,
  profileDetails: state.login.profileDetails,
  requests: state.websocket.requests,
  escrows: state.websocket.escrows,
  scheduled: state.websocket.scheduled,
  user: state.login.userData.data,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getAccountTransactions,
  fetchExternalPendingCryptoTrasactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchExternalUSDReviewedTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTransactionsTables);
