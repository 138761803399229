import './Dashboard.scss';

import {
  resetTransactionHash,
  toggleModal,
  searchRecipientAccounts,
  getTradingAssets,
  getAccountTransactions,
  getDepositAccounts
} from '../../redux/actions/transactions';
import { getUserProfile } from '../../redux/actions/login';
import { getContactsRequest, getAccountDetailsRequest } from '../../redux/actions/administration';
import {
  fetchExternalPendingCryptoTrasactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchExternalUSDReviewedTransactions
} from '../../redux/actions/transactionsExternal';

import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { fetchAssetPairs } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  displayModal: state.transactions.displayModal,
  assets: state.transactions.tradingAssets,
  assetPairs: state.trade.assetPairs,
  profileDetails: state.login.profileDetails,
  ioiOutbound: state.websocket.ioiOutbound,
  ioiInbound: state.websocket.ioiInbound,
  accounts: state.transactions.accounts,
  accountPositions: state.websocket.positions,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  toggleModal,
  resetTransactionHash,
  searchRecipientAccounts,
  getDepositAccounts,
  getUserProfile,
  getContactsRequest,
  getTradingAssets,
  fetchAssetPairs,
  getAccountTransactions,
  fetchExternalPendingCryptoTrasactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchExternalUSDReviewedTransactions,
  getAccountDetailsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
