import { Dropdown, DropdownButton } from 'react-bootstrap';

import { getAuthCookie, handleDownload } from '../../utils/generalUtils';

import URLS from '../../redux/constants/urls';
import URLS_CTS from '../../redux/constants/urlsCTS';
import URLS_USD from '../../redux/constants/urlsUSD';
import { MdSystemUpdateAlt } from 'react-icons/md';
import React from 'react';
import i18next from 'i18next';

const PDF = 'pdf';
const CSV = 'csv';

const ExportButton = ({ isExportTitle, Icon, suffix, itemActionTitle, type, params, userAccountNumber }) => {
  const generateQuery = () => {
    let generateQuery = '';
    for (const key in params) {
      if (generateQuery) {
        generateQuery += params[key] ? '&' + key + '=' + params[key] : '';
      } else {
        generateQuery += params[key] ? key + '=' + params[key] : '';
      }
    }
    return generateQuery;
  };

  const reportURLS = exportType => {
    exportType = exportType?.toUpperCase();

    return {
      pendingUsers: `${process.env.REACT_APP_BASE_URL}${URLS.GET_USERS_REPORT}?pendingOnboardingUsers=true&`,
      nonPendingUsers: `${process.env.REACT_APP_BASE_URL}${URLS.GET_USERS_REPORT}?`,
      users: `${process.env.REACT_APP_BASE_URL}${URLS.GET_USERS_REPORT}?allUsers=true&`,
      other: `${process.env.REACT_APP_BASE_URL}${URLS.GET_TRANSACTIONS_REPORT}?account=${userAccountNumber}&`,
      otherUserSide: `${process.env.REACT_APP_BASE_URL}${URLS.GET_TRANSACTIONS_REPORT}?account=${userAccountNumber}&`,
      otherOpen: `${process.env.REACT_APP_BASE_URL}${URLS.OPEN_ORDERS_REPORT}?userid=${userAccountNumber}&`,
      otherCanceled: `${process.env.REACT_APP_BASE_URL}${URLS.CANCELED_ORDERS_REPORT}?userid=${userAccountNumber}&`,

      crypto_pending: `${process.env.REACT_APP_BASE_URL_CRYPTO}${
        URLS_CTS.EXPORT_TRANSACTIONS_CRYPTO
      }?filter=pending&exportType=${exportType}&${generateQuery()}`,
      crypto_reviewed: `${process.env.REACT_APP_BASE_URL_CRYPTO}${
        URLS_CTS.EXPORT_TRANSACTIONS_CRYPTO
      }?filter=non-pending&exportType=${exportType}&${generateQuery()}`,
      usd_pending: `${process.env.REACT_APP_BASE_URL_USD}${
        URLS_USD.EXPORT_TRANSACTIONS_USD
      }?transactionStatus=PENDING&exportType=${exportType}&${generateQuery()}`,
      usd_reviewed: `${process.env.REACT_APP_BASE_URL_USD}${
        URLS_USD.EXPORT_TRANSACTIONS_USD
      }?transactionStatus=COMPLETED&exportType=${exportType}&${generateQuery()}`,
      staked: `${process.env.REACT_APP_BASE_URL_CRYPTO}${
        URLS_CTS.EXPORT_TRANSACTIONS_STAKING
      }?exportType=${exportType}&${generateQuery()}`,
      currency_transaction_report: `${process.env.REACT_APP_BASE_URL_USD}${
        URLS_USD.EXPORT_CTR
      }?type=${exportType}&${generateQuery()}`,

      depositwithdrawalusdUserSide: `${process.env.REACT_APP_BASE_URL_USD}${
        URLS_USD.EXPORT_TRANSACTIONS_USD
      }?exportType=${exportType}&${generateQuery()}`,
      depositwithdrawalcryptoUserSide: `${process.env.REACT_APP_BASE_URL_CRYPTO}${
        URLS_CTS.EXPORT_TRANSACTIONS_CRYPTO
      }?exportType=${exportType}&${generateQuery()}`
    };
  };

  const handleCSV = () => {
    const token = getAuthCookie();
    if (
      type.includes('other') ||
      type === 'pendingUsers' ||
      type === 'nonPendingUsers' ||
      type === 'otherUserSide' ||
      type === 'users'
    ) {
      handleDownload(
        `${reportURLS(CSV)[type]}csv=true&${generateQuery()}`,
        {
          credentials: 'include',
          headers: new Headers({
            Cookie: `tok_vaultlink=${token}`,
            Accept: 'application/csv'
          })
        },
        'filename'
      );
    } else {
      handleDownload(
        `${reportURLS(CSV)[type]}`,
        {
          headers: new Headers({
            Accept: '*/*',
            Authorization: `Bearer ${token}`
          })
        },
        'filename'
      );
    }
  };

  const handlePDF = () => {
    const token = getAuthCookie();
    if (
      type.includes('other') ||
      type === 'pendingUsers' ||
      type === 'nonPendingUsers' ||
      type === 'otherUserSide' ||
      type === 'users'
    ) {
      handleDownload(
        `${reportURLS(PDF)[type]}pdf=true&${generateQuery()}`,
        {
          credentials: 'include',
          headers: new Headers({
            Cookie: `tok_vaultlink=${token}`,
            Accept: 'application/pdf'
          })
        },
        'filename'
      );
    } else {
      handleDownload(
        `${reportURLS(PDF)[type]}`,
        {
          headers: new Headers({
            Accept: '*/*',
            Authorization: `Bearer ${token}`
          })
        },
        'filename'
      );
    }
  };

  return (
    <div className="export-button">
      <DropdownButton
        title={
          <div className={`export-btn-title ${suffix ? `export-btn-title--${suffix}` : ''}`}>
            <div className={`transactions-icon-border-box ${suffix ? `transactions-icon-border-box--${suffix}` : ''}`}>
              {Icon ? (
                <Icon
                  size="1.5em"
                  className={`transactions-download-icon-custom ${
                    suffix ? `transactions-download-icon-custom--${suffix}` : ''
                  }`}
                />
              ) : (
                <MdSystemUpdateAlt
                  size="1.2rem"
                  className={`transactions-download-icon ${suffix ? `transactions-download-icon--${suffix}` : ''}`}
                />
              )}
            </div>
            <span className={`export-btn-text ${suffix ? `export-btn-text--${suffix}` : ''}`}>
              {isExportTitle && i18next.t('export.export')}
            </span>
          </div>
        }
        className={`transactions-filter-button-dropdown ${
          suffix ? `transactions-filter-button-dropdown--${suffix}` : ''
        }`}
      >
        <Dropdown.Item
          as="button"
          className={`transactions-filter-button-dropdown-item ${
            suffix ? `transactions-filter-button-dropdown-item--${suffix}` : ''
          }`}
        >
          {' '}
          <a rel="noreferrer" href="#" onClick={handleCSV}>
            {itemActionTitle ? itemActionTitle + ' CSV' : i18next.t('export.export') + i18next.t('export.csv')}{' '}
          </a>
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className={`transactions-filter-button-dropdown-item ${
            suffix ? `transactions-filter-button-dropdown-item--${suffix}` : ''
          }`}
        >
          <a rel="noreferrer" href="#" onClick={handlePDF}>
            {' '}
            {itemActionTitle ? itemActionTitle + ' PDF' : i18next.t('export.export') + i18next.t('export.pdf')}
          </a>
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default ExportButton;
