import * as COMPONENT from '../index';
import { DARK_MODE, LIGHT_MODE } from '../../enums/validation';
import { Container, Form, NavDropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { MdAccountCircle, MdNotifications, MdWifi } from 'react-icons/md';
import React, { PureComponent } from 'react';
import PrivateRoute from '../../routes/PrivateRoute';
import { ROLES } from '../../enums/roles';
import { SearchQueryProvider } from '../../contexts/SearchQueryContext';
import Sidebar from '../../components/Sidebar';
import { handleLastUpdateTimestamp } from '../../utils/generalUtils';
import { hasRole } from '../../utils/roleCheckers';
import i18next from 'i18next';
import i18n from '../../i18n';
import swal from 'sweetalert2';
import AdministratorAccounts from '../../components/AdministratorAccounts';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import { INSIDE_APP_SECTIONS, SIDEBAR_SECTIONS } from '../../enums/paths';
import { AccountDropdown } from '../../components/DashboardAccountDropdown';

class Chassis extends PureComponent {
  static contextType = AccountFilterContext;

  constructor(props) {
    super(props);

    this.state = {
      userData: this.props.userData,
      hasNotification: true,
      sideMenuOpen: false
    };
  }
  changeLanguage = language => {
    i18n.changeLanguage(language);
  };

  componentDidMount() {
    this.props.getUserProfile();
    this.props.getUserPermissions();

    if (this.props.mode === DARK_MODE) {
      document.body.classList.remove(LIGHT_MODE);
      document.body.classList.add(DARK_MODE);
    } else {
      document.body.classList.add(LIGHT_MODE);
      document.body.classList.remove(DARK_MODE);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mode !== prevProps.mode) {
      document.body.classList.toggle(LIGHT_MODE);
      document.body.classList.toggle(DARK_MODE);
    }
  }

  handleNavbarClick = href => {
    this.props.history.push(href);
  };

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  renderTooltip = props => (
    <Tooltip id="socket-issues-tooltip" className="socket-issues-tooltip" {...props}>
      Websocket connection issues
    </Tooltip>
  );
  alertNotification = () => {
    swal.fire({
      title: 'There are currently 2 PENDING ACCOUNT(S)',
      timer: '2000',
      position: 'top-end',
      confirmButtonColor: '#7396b9',
      showConfirmButton: true
    });
  };

  logout = () => {
    this.props.setCurrentAccountNumber('');
    this.props.logoutRequest();
  };

  renderUserBar = () => {
    if (this.props.userData.loaded) {
      const { first_name, last_name } = this.state.userData.data;
      const name = `${first_name || ''} ${last_name || ''}`;
      const today = handleLastUpdateTimestamp(this.props.lastUpdateTimestamp);
      // const title = <MdPowerSettingsNew className="power-icon" />;

      const title = (
        <div className="topbar-title-div">
          <div className="username-div">
            <div className="welcome-text">{i18next.t('chassis.welcome')},</div>
            <div className="username">{name}</div>
          </div>
          <MdAccountCircle className="chassis-topbar-profile-image chassis-topbar-profile-image-avatar" />
        </div>
      );
      const { role } = this.props.userData.data;

      return (
        <Row noGutters className="chassis-topbar">
          {hasRole(this.props.userData?.data, ROLES.SUPER_USER) ? (
            <div className="vaultlink-logo-term spaced-apart">VaultLink</div>
          ) : (
            <div className="spaced-apart">
              <AccountDropdown />
            </div>
          )}
          <div className="topbar-content-div spaced-apart">
            {this.props.connectionError && (
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip}>
                <MdWifi className="socket-issues-icon" />
              </OverlayTrigger>
            )}
            <div className="todays-date spaced-apart">
              <svg
                className="todays-date-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="update" transform="translate(0.077)">
                  <path
                    id="Icon_material-update"
                    data-name="Icon material-update"
                    d="M20.269,10.737H14.329l2.4-2.47a6.166,6.166,0,0,0-8.655-.088,6.022,6.022,0,0,0,0,8.576,6.149,6.149,0,0,0,8.655,0,5.705,5.705,0,0,0,1.787-4.284h1.752a8.182,8.182,0,0,1-2.313,5.51,7.931,7.931,0,0,1-11.143,0A7.743,7.743,0,0,1,6.8,6.962a7.873,7.873,0,0,1,11.081,0L20.269,4.5ZM12.823,8.88V12.6l3.066,1.822-.631,1.06L11.509,13.26V8.88Z"
                    transform="translate(-0.502 -0.5)"
                  />
                  <rect
                    id="Rectangle_780"
                    data-name="Rectangle 780"
                    width="24"
                    height="24"
                    transform="translate(-0.077)"
                    fill="none"
                  />
                </g>
              </svg>
              {/* <img className="todays-date-icon" src={update} alt="update"/> */}
              {i18next.t('chassis.lastUpdate')}: {today}
            </div>
            <div className="chassis-topbar-toggle-div spaced-apart">
              <Form.Switch
                id="darkmode-toggle"
                label=""
                checked={this.props.mode === DARK_MODE}
                onChange={this.props.toggleDarkMode}
              />
            </div>
            {role === ROLES.SUPER_USER && (
              <div
                className={`chassis-topbar-profile-has-notification
             ${this.state.hasNotification && 'active'}`}
              >
                <MdNotifications
                  onClick={this.alertNotification}
                  className="chassis-topbar-profile-image chassis-topbar-profile-image-bell"
                />
              </div>
            )}

            <div className="user-system-container">
              <NavDropdown title={title} className="chassis-topbar-navdropdown">
                <NavDropdown.Item onClick={() => this.handleNavbarClick('/profile')}>
                  {i18next.t('chassis.profile')}
                </NavDropdown.Item>
                {hasRole(this.state.userData?.data, ROLES.SUPER_USER) && (
                  <NavDropdown.Item onClick={() => this.handleNavbarClick('/firms')}>
                    {i18next.t('chassis.management')}
                  </NavDropdown.Item>
                )}
                {hasRole(this.state.userData?.data, ROLES.SUPER_USER) && (
                  <NavDropdown.Item onClick={() => this.handleNavbarClick('/gatekeeper')}>Policy</NavDropdown.Item>
                )}

                <NavDropdown.Item onClick={() => this.logout()}>{i18next.t('chassis.logout')}</NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </Row>
      );
    }
  };

  renderUserDropdown = () => {};

  handleMenuShrink = () => {
    this.setState(prev => ({ sideMenuOpen: !prev.sideMenuOpen }));
  };

  render() {
    const features = this.props.accountPermissions.data.features;

    return (
      <Container fluid className="system-container">
        <Row noGutters>
          {this.props.accountPermissions.loaded && (
            <Sidebar
              userData={this.state.userData}
              history={this.props.history}
              location={this.props.location}
              features={features}
              sideMenuOpen={this.state.sideMenuOpen}
              handleMenuShrink={this.handleMenuShrink}
            />
          )}

          <Container fluid className={`chassis-pane ${this.state.sideMenuOpen ? 'menu-shrink' : ''}`}>
            {this.renderUserBar()}
            <div className="chassis-content-pane">
              <PrivateRoute
                exact
                path="/"
                hasAccess={features?.[SIDEBAR_SECTIONS.ONBOARDING]}
                component={AdministratorAccounts}
                componentProps={{ ...this.props, user: this.state.userData }}
              />
              <PrivateRoute
                exact
                path="/"
                hasAccess={features?.[SIDEBAR_SECTIONS.DASHBOARD]}
                component={COMPONENT.Dashboard}
                componentProps={{ ...this.props, user: this.state.userData }}
              />
              <PrivateRoute
                exact
                path="/firms"
                hasAccess={features?.[SIDEBAR_SECTIONS.FIRMS]}
                component={COMPONENT.Administration}
              />
              <PrivateRoute
                roles={[ROLES.SUPER_USER]}
                exact
                path="/accounts"
                componentProps={{
                  pageSize: 12
                }}
                hasAccess={features?.[SIDEBAR_SECTIONS.ALL_ACCOUNTS]}
                component={COMPONENT.Accounts}
              />
              <PrivateRoute
                roles={[ROLES.SUPER_USER]}
                exact
                path="/users"
                componentProps={{
                  pageSize: 12
                }}
                hasAccess={features?.[SIDEBAR_SECTIONS.ALL_USERS]}
                component={COMPONENT.Users}
              />

              <PrivateRoute
                exact
                path="/asset/:asset"
                component={COMPONENT.CryptoAssetDetails}
                hasAccess={features?.[INSIDE_APP_SECTIONS.CRYPTO_ASSET_DETAILS]}
              />
              <PrivateRoute
                exact
                path="/transactions"
                component={COMPONENT.Payments}
                componentProps={{ ...this.props, user: this.state.userData }}
                hasAccess={features?.[SIDEBAR_SECTIONS.PAYMENTS]}
              />
              <PrivateRoute exact path="/profile" component={COMPONENT.Profile} hasAccess={true} />
              <PrivateRoute exact path="/profile/twofactor" component={COMPONENT.TwoFactorAuth} hasAccess={true} />

              <PrivateRoute
                exact
                path="/payments/deposit/:id"
                component={COMPONENT.DepositRedeem}
                hasAccess={features?.[INSIDE_APP_SECTIONS.DEPOSIT_ADMIN]}
              />
              <PrivateRoute
                exact
                path="/payments/withdraw/:id"
                component={COMPONENT.DepositRedeem}
                hasAccess={features?.[INSIDE_APP_SECTIONS.WITHDRAW_ADMIN]}
              />
              <PrivateRoute
                exact
                path="/BTA/transactions"
                hasAccess={features?.[SIDEBAR_SECTIONS.TRANSACTIONS_BTA]}
                component={COMPONENT.TransactionsAdmin}
              />
              <PrivateRoute exact path="/reports" component={COMPONENT.Reports} hasAccess={true} />
              <PrivateRoute
                exact
                path="/markets"
                component={COMPONENT.Markets}
                componentProps={{ ...this.props, user: this.state.userData }}
                hasAccess={features?.[SIDEBAR_SECTIONS.MARKETS]}
              />

              <PrivateRoute
                exact
                path="/firms/:firmName"
                component={COMPONENT.FirmDetails}
                hasAccess={features?.[INSIDE_APP_SECTIONS.FIRM_DETAILS]}
              />
              <PrivateRoute
                exact
                path="/accounts/:accountId"
                component={COMPONENT.AccountDetails}
                hasAccess={features?.[INSIDE_APP_SECTIONS.ACCOUNT_DETAILS]}
              />

              <PrivateRoute
                exact
                path="/users/:userId"
                component={COMPONENT.UserDetails}
                hasAccess={features?.[INSIDE_APP_SECTIONS.USER_DETAILS]}
              />
              <PrivateRoute
                exact
                path="/transaction/:id"
                component={COMPONENT.TransactionDetailsAdmin}
                hasAccess={features?.[INSIDE_APP_SECTIONS.TRANSACTION_DETAILS_ADMIN]}
              />
              <PrivateRoute
                exact
                path="/wallets"
                component={COMPONENT.Wallets}
                hasAccess={features?.[SIDEBAR_SECTIONS.WALLETS]}
              />
              <PrivateRoute
                exact
                path="/contacts"
                component={COMPONENT.Contacts}
                hasAccess={features?.[SIDEBAR_SECTIONS.CONTACTS]}
              />

              <PrivateRoute exact path="/help" component={COMPONENT.Support} hasAccess={true} />

              <PrivateRoute
                exact
                path="/trade"
                component={COMPONENT.Trade}
                hasAccess={features?.[SIDEBAR_SECTIONS.TRADE]}
              />

              <PrivateRoute
                exact
                path="/users"
                component={COMPONENT.Users}
                hasAccess={features?.[SIDEBAR_SECTIONS.USERS]}
                componentProps={{
                  pageSize: 12,
                  firm: this.props.userData.data?.firm
                }}
                withContextProvider={SearchQueryProvider}
              />
              <PrivateRoute
                exact
                path="/accounts"
                componentProps={{
                  pageSize: 12
                }}
                component={COMPONENT.Accounts}
                hasAccess={features?.[SIDEBAR_SECTIONS.FIRM_ACCOUNTS]}
              />
            </div>
          </Container>
        </Row>
      </Container>
    );
  }
}

export default Chassis;
