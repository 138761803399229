import { useState } from 'react';

import { Badge, Table } from 'react-bootstrap';
import i18next from 'i18next';
import { MdInfo, MdCheckBox, MdAdd, MdArrowBack } from 'react-icons/md';

import { capitalizeFirstLetter, formatDate } from '../../utils/generalUtils';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import './DashboardTransactionExternalTable.scss';

const TRANSACTION_TYPES = {
  deposit: i18next.t('dashboardExternalTransactionsTable.deposit'),
  withdraw: i18next.t('dashboardExternalTransactionsTable.withdraw')
};

const ARROWS_MAP = {
  withdraw: MdArrowBack,
  deposit: MdAdd
};

const mapUsdTransactionsData = transaction => {
  return {
    id: transaction.id,
    created_date: transaction.created_date,
    type: transaction.type.toLowerCase(),
    amount: transaction.amount,
    security: 'USD',
    status: transaction.status,
    userInfo: transaction.fiUserInfo
  };
};

const renderHeadings = () => {
  return (
    <>
      <th className="align-left">{i18next.t('dashboardExternalTransactionsTable.date')}</th>
      <th className="align-left">{i18next.t('dashboardExternalTransactionsTable.action')}</th>
      <th>{i18next.t('dashboardExternalTransactionsTable.asset')}</th>
      <th className="align-right">{i18next.t('dashboardExternalTransactionsTable.amount')}</th>
      <th className="status-header-dashboard-external-table">
        {i18next.t('dashboardExternalTransactionsTable.status')}
      </th>
    </>
  );
};

const getTransactionIcon = transactionType => {
  return ARROWS_MAP[transactionType];
};

const DashboardTransactionExternalTable = ({
  transactions,
  title,
  assetsPairs,
  toggleTransactionDetails,
  isReviewedTable
}) => {
  const [dataPageNumber, setDataPageNumber] = useState(0);

  const handlePageClick = () => {
    setDataPageNumber(dataPageNumber + 1);
  };

  const buildDashboardTableRow = transaction => {
    const type = transaction.type?.toLowerCase();
    const transactionType = TRANSACTION_TYPES[type];
    const ArrowComponent = getTransactionIcon(type);
    return (
      <tr
        key={transaction.id}
        onClick={() => {
          !transaction.crypto
            ? toggleTransactionDetails(mapUsdTransactionsData(transaction))
            : toggleTransactionDetails(transaction);
        }}
      >
        <td className="align-left">
          <div>
            <p className="vertical-alignment">
              {isReviewedTable ? formatDate(transaction.bm_status_modified_on) : formatDate(transaction.created_date)}
            </p>
          </div>
        </td>
        <td>
          <div>
            <div className="dashboard-dark-icon-row">
              <div className="transactions-flipped-icon-background">
                <ArrowComponent size="1.5em" className={`transaction-row-icon`} />
              </div>

              <span className={`pending-td-p transaction-td-p default text-align`}>{transactionType}</span>
            </div>
          </div>
        </td>
        <td>
          <div>
            <span className="pending-td-p--bold">{transaction.crypto?.toUpperCase() || 'USD'}</span>
          </div>
        </td>
        <td>
          <div className="amount-field-dashboard-external-table">
            <span className="pending-td-p--bold">{transaction.amount || '.'}</span>
          </div>
        </td>
        <td>
          <div className="external-transactions-table-status-td">
            <span className="pending-td-p--bold">
              <Badge
                pill
                className={`status-field 
                  ${'status-badge-' + transaction?.['status'].toLowerCase()}
                `}
              >
                {capitalizeFirstLetter(transaction.status.toLowerCase()) || 'N/A'}
              </Badge>
            </span>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="dashboard-pending-table-container">
        <div className="pending-title-container">
          {isReviewedTable ? (
            <MdCheckBox className="external-transactions-table-complete-icon" />
          ) : (
            <MdInfo className="pending-icon" />
          )}
          <h4 className="dashboard-pending-title">{title}</h4>
        </div>

        <InfiniteScroll className="dashboard-pending-table-wrap" loadMore={handlePageClick}>
          {transactions?.length === 0 || !assetsPairs?.data.length ? (
            <span className="dashboard-pending-table-message">
              {i18next.t('dashboardExternalTransactionsTable.thereAreNoPT')}
            </span>
          ) : (
            <Table borderless responsive className="vl-table external-dashboard-table">
              <thead>
                <tr>{renderHeadings()}</tr>
              </thead>
              <tbody>{transactions?.map(transaction => buildDashboardTableRow(transaction))}</tbody>
            </Table>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default DashboardTransactionExternalTable;
