export const SOCKET_MESSAGE_TYPES = Object.freeze({
  CHALLENGE: 'challenge',
  LOGIN: 'login',
  POSITION: 'position',
  HEARTBEAT: 'heartbeat',
  SUBSCRIBE: 'subscribe',
  ORDER: 'order',
  BOOK: 'book',
  SCHEDULED_TRANSFER: 'scheduled_transfer',
  RISK_SETTINGS: 'risksetting_query'
});

export const SOCKET_MESSAGE_STATUS = Object.freeze({
  OK: 'OK'
});
