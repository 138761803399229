import { Modal } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import i18next from 'i18next';
import BuySell from '../../BuySell';
import './BuySellModal.scss';

class BuySellModal extends PureComponent {
  onCloseModal = () => {
    this.props.toggleModal(null);
  };

  render() {
    return (
      <Modal className="common-modal" centered show onHide={this.onCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('exchangeModal.exchange')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-sell-position">
            <BuySell fixedSendAsset={this.props.fixedSendAsset} fixedReceiveAsset={this.props.fixedReceiveAsset} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default BuySellModal;
