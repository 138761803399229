import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Col, Modal, Row } from 'react-bootstrap';
import DatePicker, { dateSinglePickerStart } from '../../components/DatePicker';
import { MdKeyboardBackspace } from 'react-icons/md';
import EscrowSummary from '../../components/EscrowSummary/EscrowSummary';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import RoundButton from '../../components/RoundButton';
import AccountNameCodeOption from '../../components/AccountNameCodeOption/AccountNameCodeOption';
import TransactionSuccessScreen from '../../components/TransactionSuccessScreen';
import { AMOUNT_REGEX, SEARCH_TYPE_ACCOUNTS, SEARCH_TYPE_FIRMS, ZERO_ASSET_FORMAT } from '../../enums/validation';
import {
  getSelectedOption,
  handleLastUpdateTimestamp,
  moneyFormat,
  parseObjectWithSamePropertyForDropdown,
  replaceCommas,
  splitDateTime
} from '../../utils/generalUtils';
import { onlyNumbers, validateAmount } from '../../utils/validators';
import { getDecimalByAsset, getSideByAssetPair } from '../../utils/transactionsHelpers';
import { getTotalBalanceFormatting } from '../../utils/DOMHelpers';
import { toast } from 'react-toastify';
import { TRANSACTION_SCREENS } from '../../enums/paths';
import { AccountFilterContext } from '../../contexts/AccountFilterContext';
import 'flatpickr/dist/themes/material_blue.css';
import i18next from 'i18next';
import VLButton from '../../components/Buttons/VLButton';
import VLSelect from '../../components/VLSelect';
import VLInput from '../../components/VLInput';

const { day, month, year } = splitDateTime(new Date(), true);
const todaysDate = `${month}/${day}/${year}`;

const initialState = {
  source: '',
  counterparty: '',
  sourceAsset: '',
  counterpartyAsset: '',
  sourceAmountObject: {
    value: '',
    display: '',
    error: false
  },
  counterpartyAmount: '',
  counterpartyError: '',
  counterpartyFirm: '',
  expirationDate: '',
  privateNote: '',
  publicNote: '',
  showCounterpartyModal: false,
  maxDecimal: 0,
  maxDecimalReceive: 0,
  isSending: false,
  firmObject: null
};

class Escrow extends PureComponent {
  static contextType = AccountFilterContext;

  constructor(props) {
    super(props);
    this.sourceAmountRef = React.createRef();
    this.counterpartyAmountRef = React.createRef();
  }
  state = {
    ...initialState,
    currentScreen: TRANSACTION_SCREENS.DETAILS,
    minDate: todaysDate
  };

  composeAssetOptionComponent = ({ value, label }) => {
    return (
      <div className="form-select-label-wrap">
        <img
          className="deposit-withdraw-currency-icon"
          src={require(`../../assets/svg/crypto/${value.toLowerCase()}.svg`)}
          alt={label}
        />
        <span className="form-select-text">{label}</span>
      </div>
    );
  };

  componentDidMount() {
    if (!this.props.assetsPairs.loaded) {
      this.props.getAssetPairs();
    }
    this.props.getDepositAccounts();
    this.props.getTradingAssets();
    this.triggerSearch(undefined, SEARCH_TYPE_FIRMS);

    this.setState({ source: this.props.currentAccountNumber });
  }

  switchScreen = screenType => this.setState({ currentScreen: screenType });

  handleBackButton = () => {
    if (this.state.currentScreen === TRANSACTION_SCREENS.SUCCESS) this.switchScreen(TRANSACTION_SCREENS.CONFIRM);
    if (this.state.currentScreen === TRANSACTION_SCREENS.CONFIRM) this.switchScreen(TRANSACTION_SCREENS.DETAILS);
  };

  backToDashboard = () => {
    this.props.toggleModal(null);
  };

  toggleCounterpartyModal = () =>
    this.setState({
      showCounterpartyModal: !this.state.showCounterpartyModal
    });

  handleFormControlChange = (a, b) => {
    const handleFormInput = (name, value) => {
      this.setState({
        [name]: value
      });
    };

    if (a.target) {
      handleFormInput(a.target.name, a.target.value);
    } else {
      handleFormInput(b.name, a.value);
    }
  };

  handleSelect = (option, name) => {
    const decimal = getDecimalByAsset(this.props.assets?.data, option?.value || '');

    this.setState(
      {
        [name]: option?.value,
        maxDecimalReceive: name === 'counterpartyAsset' ? decimal : this.state.maxDecimalReceive,
        maxDecimal: name === 'sourceAsset' ? decimal : this.state.maxDecimal,
        firmObject: name === 'counterpartyFirm' ? option : this.state.firmObject
      },
      () => {
        if (name === 'counterpartyFirm') {
          this.triggerSearch(null, SEARCH_TYPE_ACCOUNTS);
        } else if (name === 'sourceAsset' || name === 'counterpartyAsset') {
          let assetAccountBalance = this.state.positions?.VLN?.[this.state.sourceAsset];
          // validating only source amount
          let validationObject = validateAmount(
            replaceCommas(this.state.sourceAmountObject.value),
            assetAccountBalance,
            this.state.maxDecimal
          );
          if (name === 'sourceAsset') this.sourceAmountRef.current.focus();
          else this.counterpartyAmountRef.current.focus();
          this.setState({
            sourceAmountObject: {
              ...validationObject,
              value: moneyFormat(validationObject.value)
            }
          });
        }
      }
    );
  };

  validateAmount = field => {
    const sourceAccount = this.state.source;
    const selectedAsset = this.state[`${field}Asset`];
    const assetAccountBalance = this.props.positions?.[sourceAccount]?.[selectedAsset];

    let validationObject = validateAmount(
      field === 'counterparty' ? this.state.counterpartyAmount : this.state[`${field}AmountObject`].value,
      assetAccountBalance,
      field === 'counterparty' ? this.state.maxDecimalReceive : this.state.maxDecimal,
      field === 'counterparty'
    );
    if (field === 'counterparty') {
      this.validateAmount('source');
      this.setState({
        counterpartyAmount: validationObject.value,
        counterpartyError: validationObject.error
      });
    } else {
      this.setState({
        [`${field}AmountObject`]: { ...validationObject }
      });
    }

    this.setState(
      field === 'source'
        ? {
            sourceAmountObject: {
              ...this.state.sourceAmountObject,
              value: replaceCommas(this.state.sourceAmountObject.value)
            }
          }
        : {
            counterpartyAmount: replaceCommas(this.state.counterpartyAmount)
          },
      () => {
        const sourceAccount = this.state.source;
        const selectedAsset = this.state[`${field}Asset`];
        const assetAccountBalance = this.props.positions?.[sourceAccount]?.[selectedAsset];

        let validationObject = validateAmount(
          field === 'counterparty' ? this.state.counterpartyAmount : this.state[`${field}AmountObject`].value,
          assetAccountBalance,
          field === 'counterparty' ? this.state.maxDecimalReceive : this.state.maxDecimal,
          field === 'counterparty'
        );
        if (field === 'counterparty') {
          this.setState({
            counterpartyAmount: moneyFormat(validationObject.value),
            counterpartyError: validationObject.error
          });
        } else {
          this.setState({
            [`${field}AmountObject`]: {
              ...validationObject,
              value: moneyFormat(validationObject.value)
            }
          });
        }
      }
    );
  };

  handleAmountChange = ({ target }) => {
    if (AMOUNT_REGEX.test(target.value)) {
      if (target.name === 'sourceAmount') {
        this.setState({
          sourceAmountObject: {
            ...this.state.sourceAmountObject,
            value: moneyFormat(replaceCommas(target.value))
          }
        });
      } else {
        this.setState({
          counterpartyAmount: moneyFormat(replaceCommas(target.value))
        });
      }
    }
  };

  handleDateChange = date =>
    this.setState(
      {
        expirationDate: date[0]
      },
      () => {
        this.props.parentDate({
          expirationDate: date[0]
        });
      }
    );

  onSuccessModalClick = data => {
    this.setState(
      {
        ...this.state,
        ...data
      },
      () => this.toggleCounterpartyModal()
    );
  };

  successTrigger = data => {
    toast.success(i18next.t('toasts.success'));
    this.setState({
      currentScreen: TRANSACTION_SCREENS.SUCCESS,
      transactionID: data.refno,
      isSending: false
    });
  };

  errorTrigger = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.result}`);
    this.setState({
      isSending: false
    });
  };

  //TODO: update when endpoint is exposed
  submitForm = () => {
    const assetsPairs = this.props.assetsPairs.data;
    const { sourceAsset, counterpartyAsset } = this.state;
    const { side } = getSideByAssetPair(assetsPairs, sourceAsset, counterpartyAsset);

    let data = {
      socket: this.props.websocket,
      source: this.state.source,
      sourceAsset: this.state.sourceAsset,
      exptime: new Date(this.state.expirationDate).getTime(),
      sourceAmount: replaceCommas(this.state.sourceAmountObject.value),
      recipientAmount: onlyNumbers(replaceCommas(this.state.counterpartyAmount)),
      recipient: this.state.counterparty,
      recipientAsset: this.state.counterpartyAsset,
      side,
      //   expryDate: this.state.expirationDate,
      publicNote: this.state.publicNote,
      privateNote: this.state.privateNote,
      clientorderid: this.props.currentAccountNumber,
      successCallback: this.successTrigger,
      errorCallback: this.errorTrigger
    };

    this.props.makeEscrowRequest(data);
    this.setState({
      isSending: true
    });
  };

  formatOptionLabel = ({ value, label }) => {
    return <AccountNameCodeOption label={label} key={value} />;
  };

  handleDatePicker = date => {};

  triggerSearch = (data, type) => {
    // TODO: Search for contacts
  };

  filterAssetOptions = field => {
    const baseAssetOptions = parseObjectWithSamePropertyForDropdown(this.props.assets?.data, 'security');
    let sourceAssets = [];
    if (field) {
      const index = baseAssetOptions.findIndex(option => option.value === field);
      sourceAssets = baseAssetOptions.slice(0, index);
      sourceAssets = sourceAssets.concat(baseAssetOptions.slice(index + 1, baseAssetOptions.length));
    } else {
      sourceAssets = [...baseAssetOptions];
    }
    return sourceAssets;
  };
  getContactFilters = () => {
    const dropdownData =
      this.props?.contacts != null &&
      this.props?.contacts?.map(company => {
        return {
          value: company.accountNumber,
          label: company.accountNumber
        };
      });
    return dropdownData;
  };
  redirectToContacts = () => {
    this.props.history?.push('/contacts');
  };
  renderDetailsForm = () => {
    const sourceAssets = this.filterAssetOptions(this.state.counterpartyAsset);
    const counterpartyAssets = this.filterAssetOptions(this.state.sourceAsset);
    const selectedAsset = getSelectedOption(sourceAssets, this.state.sourceAsset);
    const selectedCounterpartyAsset = getSelectedOption(counterpartyAssets, this.state.counterpartyAsset);

    let selectedRecipient = getSelectedOption(this.getContactFilters(), this.state.counterparty);

    return (
      <>
        <Row>
          <Col>
            {' '}
            <p className="system-form-control-placeholder">{i18next.t('escrow.selectedAccount')}</p>
            <div className="system-form-control-box sfcb-transparent">
              <VLInput name="source" value={this.state.source} size="s" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props?.contacts.length > 0 ? (
              <React.Fragment>
                <p className="system-form-control-placeholder">{i18next.t('escrow.selectRecipientAccount')}</p>
                <div className="relative-box sfcb-transparent">
                  <VLSelect
                    options={this.getContactFilters()}
                    placeholder={this.state.firmObject === null ? 'Select...' : this.getContactFilters()?.[0]?.label}
                    onChange={option => this.handleSelect(option, 'counterparty')}
                    value={selectedRecipient}
                    isSearchable={true}
                    isClearable={true}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="system-form-control-placeholder">You do not have any contacts yet.</div>

                <div className="system-form-control-box relative-box sfcb-transparent">
                  <VLButton
                    onClick={() => {
                      this.redirectToContacts();
                    }}
                    text={'Add a new contact'}
                    size="l"
                  />
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('escrow.amountToSend')}</p>
            <div
              className={`system-form-control-box-custom ${
                this.state.sourceAmountObject.error ? 'system-form-control-box--error' : ''
              } sfcb-ammount sfcb-transparent`}
            >
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('escrow.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="sourceAsset"
                options={sourceAssets}
                onChange={option => this.handleSelect(option, 'sourceAsset')}
                value={selectedAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
              />
              <VLInput
                name="sourceAmount"
                placeholder=""
                value={this.state.sourceAmountObject.value}
                onChange={this.handleAmountChange}
                onBlur={() => this.validateAmount('source')}
                maxLength="20"
                disabled={!selectedAsset}
                noBorder
                hasBackground
                width="70%"
                innerRef={this.sourceAmountRef}
              />
            </div>
            {(this.state.asset || this.state.sourceAsset) && (
              <>
                <div className="escrow-info-text">{i18next.t('escrow.totalAmount')}</div>
                <div className="escrow-info-number">
                  {getTotalBalanceFormatting(
                    this.props.positions?.[this.state.source]?.[this.state.sourceAsset],
                    this.state.sourceAsset
                  )}
                </div>
              </>
            )}
            {this.state.sourceAmountObject.error && (
              <div>
                <span className="system-form-control-error-text">{i18next.t('escrow.eror-text')}</span>
              </div>
            )}
          </Col>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('escrow.amountToReceive')}</p>
            <div className="system-form-control-box-custom sfcb-ammount sfcb-transparent">
              <Select
                className="deposit-form-select-blue"
                placeholder={i18next.t('escrow.selectAsset')}
                classNamePrefix="deposit-asset"
                isClearable={true}
                isSearchable={true}
                name="counterpartyAsset"
                options={counterpartyAssets}
                onChange={option => this.handleSelect(option, 'counterpartyAsset')}
                value={selectedCounterpartyAsset}
                formatOptionLabel={this.composeAssetOptionComponent}
              />
              <VLInput
                name="counterpartyAmount"
                placeholder=""
                value={this.state.counterpartyAmount}
                onChange={this.handleAmountChange}
                onBlur={() => this.validateAmount('counterparty')}
                maxLength="20"
                disabled={!selectedCounterpartyAsset}
                noBorder
                hasBackground
                width="70%"
                innerRef={this.counterpartyAmountRef}
              />
            </div>
            {this.state.counterpartyError && (
              <div>
                <span className="system-form-control-error-text">{i18next.t('escrow.eror-text-zero')}</span>
              </div>
            )}
          </Col>
        </Row>
        <div className="horizontal-line-div"></div>
        <Row>
          <Col>
            <p className="system-form-control-placeholder">{i18next.t('escrow.expireBy')}</p>
            <div className="system-form-control-box sfcb-transparent sfcb-date escrow-date-parent date-picker--small date-picker--modal">
              <DatePicker
                options={dateSinglePickerStart({
                  parentEl: '.escrow-date-parent',
                  date: this.props.expirationDate
                })}
                onClick={date => this.handleDateChange(date, 'start')}
                minDate={this.state.minDate}
                date={this.state.expirationDate}
              />
            </div>
          </Col>
          <Col>
            <div className="sfcb-flex">
              <div>
                <p className="system-form-control-placeholder">{i18next.t('escrow.counterpartyMsg')}</p>
                <div className="system-form-control-box sfcb-transparent">
                  <VLInput
                    name="publicNote"
                    value={this.state.publicNote}
                    onChange={e => this.handleFormControlChange(e)}
                    maxLength="128"
                    size="s"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderSummary = () => <EscrowSummary {...this.state} accountContacts={this.props.contacts} />;

  isFormComplete = () => {
    const {
      source,
      sourceAsset,
      sourceAmountObject,
      counterparty,
      counterpartyAsset,
      counterpartyAmount,
      counterpartyError
    } = this.state;

    const isComplete =
      [source, sourceAsset, sourceAmountObject.value, counterparty, counterpartyAsset, counterpartyAmount].every(
        element => !!element === true
      ) &&
      sourceAsset !== counterpartyAsset &&
      sourceAmountObject.value !== ZERO_ASSET_FORMAT[sourceAsset] &&
      counterpartyAmount !== ZERO_ASSET_FORMAT[counterpartyAsset] &&
      !sourceAmountObject.error &&
      !counterpartyError;

    return isComplete;
  };

  render() {
    const { currentScreen } = this.state;
    const isDetailsDisplay = currentScreen === TRANSACTION_SCREENS.DETAILS;
    const isSuccessScreen = currentScreen === TRANSACTION_SCREENS.SUCCESS;
    const isLoading = !this.props.assets?.loaded && !this.props.accounts?.loaded;

    const isComplete = this.isFormComplete();

    return (
      <Modal
        className="common-modal"
        dialogClassName={`escrow-modal ${isSuccessScreen ? 'success' : ''} ${
          isDetailsDisplay ? 'confirm-screen-class' : ''
        }`}
        centered
        show
        onHide={this.backToDashboard}
        backdrop="static"
      >
        {!isSuccessScreen && (
          <Modal.Header closeButton>
            {!isDetailsDisplay && (
              <MdKeyboardBackspace
                onClick={this.handleBackButton}
                className="external-deposit-back-button"
                size="2.5em"
              />
            )}
            <Modal.Title>{i18next.t('escrow.title')}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <LoadingWrapper isLoading={isLoading} waitDurationSeconds={60 * 1.5}>
            <div className="deposit-container">
              {isSuccessScreen ? (
                <TransactionSuccessScreen
                  type={this.state.transactionType}
                  amount={this.state.sourceAmountObject.display}
                  asset={this.state.sourceAsset}
                  counterpartyAmount={this.state.counterpartyAmount}
                  counterpartyAsset={this.state.counterpartyAsset}
                  history={this.props.history}
                  requestID={this.state.transactionID}
                  specialRedirectFunction={this.backToDashboard}
                  notStandalone
                />
              ) : (
                <>
                  <Row noGutters className="deposit-header">
                    <RoundButton
                      isActive={isDetailsDisplay}
                      onClick={() => this.switchScreen(TRANSACTION_SCREENS.DETAILS)}
                      number={1}
                      text={i18next.t('escrow.details')}
                    />
                    <RoundButton
                      isActive={!isDetailsDisplay}
                      // isDisabled={true}
                      number={2}
                      text={i18next.t('escrow.preview')}
                    />
                  </Row>
                  {isDetailsDisplay && (
                    <div className="info-text">
                      {i18next.t('escrow.info-text')} <b>{i18next.t('escrow.escrow-bold')}</b>,{' '}
                      {i18next.t('escrow.info-text2')}
                    </div>
                  )}

                  {isDetailsDisplay ? this.renderDetailsForm() : this.renderSummary()}
                  <div className="sfcb-button-wrapper">
                    {isDetailsDisplay && (
                      <>
                        <VLButton
                          disabled={!isComplete}
                          onClick={() => this.switchScreen(TRANSACTION_SCREENS.CONFIRM)}
                          text={i18next.t('escrow.confirm')}
                        />
                      </>
                    )}
                    {this.state.currentScreen === TRANSACTION_SCREENS.CONFIRM && (
                      <>
                        <div className="sfcb-button-inner">
                          <VLButton
                            width="100%"
                            onClick={this.submitForm}
                            disabled={this.state.isSending}
                            text={i18next.t('escrow.confirmEscrow')}
                          />
                          <p className="sfcb-button-text">{handleLastUpdateTimestamp()}</p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </LoadingWrapper>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Escrow;
