import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { MdRefresh } from 'react-icons/md';
import React, { PureComponent } from 'react';
import EditUserProfile from '../../components/modals/EditUserProfile';
import { IoMdKey } from 'react-icons/io';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import MediaQuery from 'react-responsive';
import Card from '../Card';
import defaultThumbnail from '../../images/profile-photo.png';
import i18next from 'i18next';
import { getRoleDisplayName } from '../../utils/generalUtils';
import VLButton from '../../components/Buttons/VLButton';

class Profile extends PureComponent {
  state = {
    showEditProfileModal: false,
    showResetPasswordModal: false,
    showTwoFactorAuthentication: false
  };

  componentDidMount() {
    this.props.getUserProfile(this.props.profileDetails.data);
  }

  handleBackButton = () => {
    this.props.history.goBack();
  };

  onEditProfileClick = () =>
    this.setState({
      showEditProfileModal: !this.state.showEditProfileModal
    });

  onResetPassword = () => {};

  onTwoFactor = () => {
    this.props.history.push(`${this.props.match.path}/twofactor`);
  };

  parseEditPropsData = () => ({
    email: this.props.profileDetails.data?.attr?.email,
    first: this.props.profileDetails.data?.attr?.first_name,
    last: this.props.profileDetails.data?.attr?.last_name,
    phone: this.props.profileDetails.data?.attr?.phone,
    image: this.props.profileDetails.data?.attr?.image || defaultThumbnail
  });

  onEditSuccess = data => {
    //TODO send edit request
    this.props.updateUser({
      userid: this.props.userData.data?.userId,
      ...data
    });
    this.onEditProfileClick();
  };

  getUserRole = user => {
    return getRoleDisplayName(user.roles);
  };

  render() {
    const user = this.props.profileDetails.data;
    const isLoading = this.props.profileDetails.loading;
    return (
      <LoadingWrapper isLoading={isLoading}>
        <Card>
          <div className="profile">
            {this.state.showEditProfileModal && (
              <React.Fragment>
                <EditUserProfile
                  data={this.parseEditPropsData()}
                  onSuccess={this.onEditSuccess}
                  onClose={this.onEditProfileClick}
                />
                <span className="profile-title dashboard-transactions-header">{i18next.t('profile.profile')}</span>
              </React.Fragment>
            )}

            <Container className="profile-container">
              <Row className="profile-container-row">
                <Col className="profile-info-container profile-container-col">
                  <div className="profile-image-wrap">
                    <img src={user?.attr?.image || defaultThumbnail} className="profile-image" alt="User profile" />
                  </div>

                  <div>
                    <h5 className="profile-text">
                      {user?.attr?.first_name} {user?.attr?.last_name}
                    </h5>
                    <div className="profile-text profile-text-info">
                      {i18next.t('profile.eMail') + ` ${user?.attr?.email}`}
                    </div>
                    <div className="profile-text profile-text-info">{i18next.t('profile.firm') + ` ${user?.firm}`}</div>
                  </div>
                </Col>
                <MediaQuery minWidth={1280}>
                  <Col className="profile-buttons-col profile-container-col">
                    <div className="profile-right-content">
                      <VLButton
                        text={i18next.t('profile.resetPassword')}
                        onClick={this.onResetPassword}
                        leftIcon={<MdRefresh />}
                      />

                      <VLButton
                        text={i18next.t('profile.two-factor')}
                        onClick={this.onTwoFactor}
                        leftIcon={<IoMdKey />}
                      />
                    </div>
                  </Col>
                </MediaQuery>
              </Row>
              <Row className="profile-container-row">
                <ListGroup className="profile-details-container">
                  <ListGroup.Item key="profile-phone" className="transactions-list-item"></ListGroup.Item>
                  <ListGroup.Item className="transactions-list-item">
                    <Row className="profile-table-item-row transactions-item-row">
                      <Col className="profile-table-item transactions-item-regular-text">
                        {i18next.t('profile.role')}:
                      </Col>
                      <Col className="profile-table-item transactions-item-regular-text">
                        {user ? this.getUserRole(user) : '-'}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Row>
            </Container>
          </div>
        </Card>
      </LoadingWrapper>
    );
  }
}

export default Profile;
