import { Container, Modal, Row } from 'react-bootstrap';
import {
  DEPOSIT_TYPE,
  ESCROW_TYPE,
  REQUEST_TYPE,
  SCHEDULED_SEND_TYPE,
  SEND_TYPE,
  WITHDRAW_TYPE
} from '../../enums/validation';

import { MdClose } from 'react-icons/md';
import React from 'react';
import { capitalizeFirstLetter, findFiatCurrencySymbol } from '../../utils/generalUtils';
import i18next from 'i18next';
import VLButton from '../Buttons/VLButton';
const TransactionCancelScreen = props => {
  const redirectToDashboard = () => {
    props.history.push('/');
  };

  const getSpecificData = () => {
    switch (props.type) {
      case DEPOSIT_TYPE:
        return {
          middleRow: (
            <Row className="summary-big-text summary-darker-text">
              {props.asset} {props.amount}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.deposit'),
          lastRowStart: i18next.t('transactionSuccessScreen.deposits')
        };
      case WITHDRAW_TYPE:
        return {
          middleRow: (
            <Row className="summary-big-text summary-darker-text">
              {props.asset} {props.amount}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.redemption'),
          lastRowStart: i18next.t('transactionSuccessScreen.redemptions')
        };
      case SEND_TYPE:
      case SCHEDULED_SEND_TYPE:
        return {
          middleRow: (
            <Row className="ed-transaction-wrapper">
              <div className="external-deposit-h1-label">
                {props.asset || props.sendAsset} {props.amount || props.sendAmount}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.to')}</span>
              <div className="external-deposit-h1-label">{props.counterparty}</div>
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.transfer'),
          lastRowStart: i18next.t('transactionSuccessScreen.Transfer')
        };
      case REQUEST_TYPE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {props.sendAsset} {props.sendAmount}
              </div>
              {props.counterparty && (
                <>
                  <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.to')}</span>
                  <div className="external-deposit-h1-label">{props.counterparty}</div>
                </>
              )}
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.request'),
          lastRowStart: capitalizeFirstLetter(i18next.t('transactionSuccessScreen.request'))
        };
      case ESCROW_TYPE:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(props.sendAsset)} {props.sendAmount} {props.sendAsset}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.for')}</span>
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(props.receiveAsset)} {props.receiveAmount} {props.receiveAsset}
              </div>
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.escrow'),
          lastRowStart: i18next.t('transactionSuccessScreen.Escrow')
        };
      default:
        return {
          middleRow: (
            <Row className="">
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(props.sendAsset)} {props.sendAmount} {props.sendAsset}
              </div>
              <span className="ed-pretitle">{i18next.t('transactionSuccessScreen.for')}</span>
              <div className="external-deposit-h1-label">
                {findFiatCurrencySymbol(props.receiveAsset)} {props.receiveAmount} {props.receiveAsset}
              </div>
            </Row>
          ),
          transferType: i18next.t('transactionSuccessScreen.escrow'),
          lastRowStart: i18next.t('transactionSuccessScreen.Escrow')
        };
    }
  };
  const buildTransactionSummary = () => {
    const { middleRow, transferType, lastRowStart } = getSpecificData();

    return (
      <>
        <div className="ed-pretitle">
          {i18next.t('toasts.transactionCancelScreen') +
            `  ${
              lastRowStart === 'Escrow'
                ? i18next.t('transactionSuccessScreen.an')
                : i18next.t('transactionSuccessScreen.a')
            } ${transferType} ` +
            i18next.t('toasts.of')}
        </div>
        <div className="ed-success">{middleRow}</div>
        {/* <Row className="transaction-success-disclaimer summary-label-text">
					{`${lastRowStart} submitted and approved to the extent such approval is
					required`}
				</Row> */}
      </>
    );
  };

  const buildModalWrapper = children => {
    return (
      <Modal
        className="common-modal"
        centered
        show
        onHide={props.onCloseModal}
        backdrop="static"
        animation={props.notStandalone ? true : false}
      >
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  };

  const content = (
    <Container className="transaction-success-container">
      <Row className="transaction-error-icon-container">
        <MdClose size="3em" />
      </Row>
      {buildTransactionSummary()}
      <Row className="transaction-success-disclaimer summary-label-text">{i18next.t('toasts.details')}</Row>
      <Row className="summary-button-wrapper">
        <VLButton
          size="l"
          onClick={
            props.specialRedirectFunction
              ? props.specialRedirectFunction
              : redirectToDashboard
          }
          text={i18next.t('toasts.back-to') + i18next.t('toasts.dashboard')}
        />
      </Row>
      <Row className="summary-label-text">
        <span className="ed-row-label">{i18next.t('toasts.requestId')}</span>{' '}
        <span className="summary-darker-text">{props.requestID || ''}</span>
      </Row>
    </Container>
  );

  if (props.notStandalone) {
    return content;
  } else {
    return buildModalWrapper(content);
  }
};

export default TransactionCancelScreen;
