import { enableTwoFactor, disableTwoFactor, getUserProfile } from '../../redux/actions/login';

import { withRouter } from 'react-router-dom';
import TwoFactorAuth from './TwoFactorAuth';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  userData: state.login.userData,
  userProfile: state.login.profileDetails
});

const mapDispatchToProps = {
  enableTwoFactor,
  disableTwoFactor,
  getUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TwoFactorAuth));
