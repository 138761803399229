import * as ACTIONS from '../../actions/transactionsExternal';
import * as CONSTANTS from '../../constants/transactionsExternal';
import { put, takeLatest } from 'redux-saga/effects';
import { default as URLS_CTS } from '../../constants/urlsCTS';
import { default as URLS_USD } from '../../constants/urlsUSD';
import RequestBuilder from '../../../request/RequestBuilder';

const DEFAULT_PAGEABLE_CREATED_DATE = {
  page: '0,1000,createdDate'
};

const DEFAULT_PAGEABLE_REQUESTED_AT = {
  page: '0,1000,requestedAt'
};

const getUsdPageableData = status => {
  if (status)
    return {
      ...DEFAULT_PAGEABLE_REQUESTED_AT,
      transactionStatus: status
    };

  return DEFAULT_PAGEABLE_REQUESTED_AT;
};

function* fetchExternalPendingCryptoTransactions(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data || DEFAULT_PAGEABLE_CREATED_DATE)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchExternalPendingCryptoTrasactionsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchExternalPendingCryptoTrasactionsError(err));
  }
}

function* fetchExternalReviewedCryptoTransactions(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data || DEFAULT_PAGEABLE_CREATED_DATE)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchExternalReviewedCryptoTrasactionsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchExternalReviewedCryptoTrasactionsError(err));
  }
}

function* fetchExternalUsdTransactions(action) {
  try {
    const response = yield RequestBuilder.usd()
      .get(URLS_USD[action.type])
      .urlParams(action.data || getUsdPageableData())
      .build()
      .map(res => res.data)
      .toEffect();

    action.successCallback?.(response);
    yield put(ACTIONS.fetchExternalUSDTransactionsSuccess(response));
  } catch (response) {
    action.errorCallback?.(response.error);
    yield put(ACTIONS.fetchExternalUSDTransactionsError(response.error));
  }
}

function* fetchExternalUsdPendingTransactions(action) {
  try {
    const response = yield RequestBuilder.usd()
      .get(URLS_USD[action.type])
      .urlParams(action.data || getUsdPageableData('PENDING'))
      .build()
      .map(res => res.data)
      .toEffect();

    action.successCallback?.(response);
    yield put(ACTIONS.fetchExternalUSDPendingTransactionsSuccess(response));
  } catch (response) {
    action.errorCallback?.(response.error);
    yield put(ACTIONS.fetchExternalUSDPendingTransactionsError(response.error));
  }
}

function* fetchExternalUsdReviewedTransactions(action) {
  try {
    const response = yield RequestBuilder.usd()
      .get(URLS_USD[action.type])
      .urlParams(action.data || getUsdPageableData('COMPLETED'))
      .build()
      .map(res => res.data)
      .toEffect();

    action.successCallback?.(response);
    yield put(ACTIONS.fetchExternalUSDReviewedTransactionsSuccess(response));
  } catch (response) {
    action.errorCallback?.(response.error);
    yield put(ACTIONS.fetchExternalUSDReviewedTransactionsError(response.error));
  }
}

function* fetchStakingTransactions(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data || DEFAULT_PAGEABLE_CREATED_DATE)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchStakingRequestSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchStakingRequestError(err));
  }
}

function* fetchStakingRewardsSummary(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data || DEFAULT_PAGEABLE_CREATED_DATE)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchStakingRewardsSummarySuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchStakingRewardsSummaryError(err));
  }
}

function* fetchStakingRewardsTransactions(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data || DEFAULT_PAGEABLE_CREATED_DATE)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchStakingRewardsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchStakingRewardsError(err));
  }
}

function* addStaking(action) {
  try {
    const response = yield RequestBuilder.cts()
      .post(URLS_CTS[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    if (action.data.error) {
      action.data.errorCallback(action.data.message);
      return;
    }
    action.data.successCallback(response);
  } catch (err) {
    action.data.errorCallback(err);
  }
}

function* exitStaking(action) {
  try {
    const response = yield RequestBuilder.cts()
      .post(URLS_CTS[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    if (action.data.error) {
      action.data.errorCallback?.(action.data.message);
      return;
    }
    action.data.successCallback?.(response);
  } catch (err) {
    action.data.errorCallback?.(err);
  }
}

function* updateStatusExternalCryptoTransactions(action) {
  try {
    const id = action.data.id;
    delete action.data.id;
    const url = `${URLS_CTS[action.type]}/${id}`;

    const response = yield RequestBuilder.cts()
      .patch(url)
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updateStatusExternalCryptoTrasactionsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.updateStatusExternalCryptoTrasactionsError(err));
  }
}

function* fetchExternalCryptoTransactionById(action) {
  try {
    const id = action.data.id;
    delete action.data.id;
    const url = `${URLS_CTS[action.type]}/${id}`;

    const response = yield RequestBuilder.cts()
      .get(url)
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchExternalCryptoTransactionByIdSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchExternalCryptoTransactionByIdError(err));
  }
}

function* fetchInternalWallets(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchInternalWalletsSuccess(response));
  } catch (err) {
    if (err.response.status === 404) {
      yield put(ACTIONS.fetchInternalWalletsSuccess(null));
    } else {
      action.data.errorCallback(err.response.data.error);
      yield put(ACTIONS.fetchInternalWalletsError(err));
    }
  }
}

function* fetchExternalTransactionSupportedAssets(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchExternalTransactionSupportedAssetsSuccess(response));
  } catch (response) {
    action.data?.errorCallback?.(response.error);
    yield put(ACTIONS.fetchExternalTransactionSupportedAssetsError(response));
  }
}

function* addInternalWallets(action) {
  try {
    const response = yield RequestBuilder.cts()
      .post(URLS_CTS[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response);
  } catch (err) {
    action.data.errorCallback(err);
  }
}

function* createExternalCryptoTransaction(action) {
  try {
    const response = yield RequestBuilder.cts()
      .post(URLS_CTS[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback?.(response);
  } catch (err) {
    action.data.errorCallback?.(err);
  }
}

function* createExternalTransactionRiskScore(action) {
  try {
    const id = action.data.id;
    delete action.data.id;

    let path = 'comment';
    if (action.data?.['risk-score']) {
      path = 'risk-score';
    }
    const url = `${URLS_CTS[action.type]}/${id}/${path}`;

    const response = yield RequestBuilder.cts()
      .put(url)
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.createExternalTransactionRiskScoreSuccess(response));
  } catch (err) {
    yield put(ACTIONS.createExternalTransactionRiskScoreError(err));
  }
}

function* createExternalDepositUSD(action) {
  try {
    const response = yield RequestBuilder.usd()
      .post(URLS_USD[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    if (action.data.error) {
      action.data.errorCallback(action.data.message);
    } else if (response?.processing_status === 'FAILED') {
      action.data.errorCallback('Failed to execute transaction.');
    } else {
      action.data.successCallback(response);
    }
  } catch (err) {
    action.data.errorCallback(err);
  }
}

// TODO: Maybe a single method can be used to handle withdraw and deposit.
//  since the implementation is the same.
function* createExternalWithdrawUSD(action) {
  try {
    const response = yield RequestBuilder.usd()
      .post(URLS_USD[action.type])
      .data(action.data.payload)
      .build()
      .map(res => res.data)
      .toEffect();

    if (action.data.error) {
      action.data.errorCallback(action.data.message);
    } else if (response?.processing_status === 'FAILED') {
      action.data.errorCallback('Failed to execute transaction.');
    } else {
      action.data.successCallback(response);
    }
  } catch (err) {
    action.data.errorCallback(err);
  }
}

function* updateStatusExternalTransactionsUSD(action) {
  try {
    const id = action.data.id;
    const type = action.data.type;

    delete action.data.id;
    delete action.data.type;

    const path = type === 'withdraw' ? 'withdrawals' : 'deposits';

    const url = `${URLS_USD[action.type]}/${path}/${id}`;

    const response = yield RequestBuilder.usd()
      .patch(url)
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updateStatusExternalUSDTransactionsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.updateStatusExternalUSDTransactionsError(err));
  }
}

function* uploadTransactionCryptoBtaReport(action) {
  try {
    const body = { file: action.data.file };
    const url = `${URLS_CTS[action.type]}/${action.data.id}`;

    const response = yield RequestBuilder.cts()
      .post(url)
      .formData(body)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback(response.message);
    yield put(ACTIONS.uploadTransactionCryptoBtaReportSuccess(response));
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.uploadTransactionCryptoBtaReportError(err));
  }
}

function* deleteTransactionCryptoBtaReport(action) {
  try {
    const url = `${URLS_CTS[action.type]}/${action.data.transactionId}`;

    yield RequestBuilder.cts()
      .delete(url)
      .build()
      .map(res => res.data)
      .toEffect();

    action.data.successCallback();
  } catch (err) {
    action.data.errorCallback(err);
    yield put(ACTIONS.deleteTransactionCryptoBtaReportError(err));
  }
}

function* getTransactionFee(action) {
  try {
    const response = yield RequestBuilder.cts()
      .get(URLS_CTS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getTransactionFeeSuccess(response));
  } catch (err) {
    if (err.response.status === 404) {
      yield put(ACTIONS.getTransactionFeeSuccess(null));
    } else {
      action.data.errorCallback(err.response.data.message);
      yield put(ACTIONS.getTransactionFeeError(err));
    }
  }
}

export default function* transactionsCTSSaga() {
  yield takeLatest(CONSTANTS.FETCH_SUPPORTED_ASSETS_REQUEST, fetchExternalTransactionSupportedAssets);
  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS, fetchExternalPendingCryptoTransactions);
  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS, fetchExternalReviewedCryptoTransactions);
  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS, fetchExternalUsdTransactions);
  yield takeLatest(CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS, updateStatusExternalCryptoTransactions);
  yield takeLatest(CONSTANTS.FETCH_STAKING_REQUEST, fetchStakingTransactions);
  yield takeLatest(CONSTANTS.FETCH_STAKING_REWARDS_REQUEST, fetchStakingRewardsTransactions);
  yield takeLatest(CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_REQUEST, fetchStakingRewardsSummary);
  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID, fetchExternalCryptoTransactionById);
  yield takeLatest(CONSTANTS.FETCH_INTERNAL_WALLETS_REQUEST, fetchInternalWallets);

  yield takeLatest(CONSTANTS.ADD_INTERNAL_WALLETS_REQUEST, addInternalWallets);
  yield takeLatest(CONSTANTS.CREATE_EXTERNAL_TRANSACTION_REQUEST, createExternalCryptoTransaction);
  yield takeLatest(CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE, createExternalTransactionRiskScore);
  yield takeLatest(CONSTANTS.ADD_STAKING_REQUEST, addStaking);
  yield takeLatest(CONSTANTS.EXIT_STAKING_REQUEST, exitStaking);

  yield takeLatest(CONSTANTS.CREATE_USD_DEPOSIT_REQUEST, createExternalDepositUSD);

  yield takeLatest(CONSTANTS.CREATE_USD_WITHDRAW_REQUEST, createExternalWithdrawUSD);

  yield takeLatest(CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS, updateStatusExternalTransactionsUSD);

  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS, fetchExternalUsdPendingTransactions);

  yield takeLatest(CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS, fetchExternalUsdReviewedTransactions);

  yield takeLatest(CONSTANTS.UPLOAD_CRYPTO_REPORT, uploadTransactionCryptoBtaReport);

  yield takeLatest(CONSTANTS.DELETE_CRYPTO_REPORT, deleteTransactionCryptoBtaReport);

  yield takeLatest(CONSTANTS.TRANSACTIONS_FEE_REQUEST, getTransactionFee);
}
