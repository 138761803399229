import './ListTransactionsContainer.scss';

import ListTransactionsContainer from './ListTransactionsContainer';
import { connect } from 'react-redux';
import { getAccountTransactions } from '../../redux/actions/transactions';

const mapStateToProps = state => ({
  events: state.transactions.miniListEvents
});

const mapDispatchToProps = {
  getAccountTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTransactionsContainer);
