import CreateRecipientModal from './CreateRecipientModal';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  createContactRequest,
  getContactsRequest,
  getContactsRequestsRequest
} from '../../../redux/actions/administration';
import './CreateRecipientModal.scss';

const mapStateToProps = state => ({
  createContactResponse: state.administration.createContact,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  createContactRequest,
  getContactsRequest,
  getContactsRequestsRequest
};
export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateRecipientModal);
