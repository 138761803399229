import PropTypes from 'prop-types';
import './Card.scss';

/**
 * Content wrapper
 *
 * @param {String} width - Value representing card width. Default value is 'auto'.
 * @param {String} height - Value representing card height. Default value is 'auto'.
 * @param {String} spacing - Value representing card margin. Default value is 0px.
 * @param {String} internalSpacing - Value representing card padding. Default value is 20px on all sides.
 * @param {Boolean} borderless - Removes border from the card. Cards have bordered layout by default.
 * @param {Boolean} transparent - Removes background color from the card. Default color depends on the set value and is not transparent.
 */
const Card = ({ width, height, spacing, internalSpacing, borderless, transparent, children }) => {
  const style = {
    width: width,
    height: height,
    margin: spacing,
    padding: internalSpacing,
    border: borderless && 'none',
    backgroundColor: transparent && 'transparent'
  };

  return (
    <div className="card" style={style}>
      {children}
    </div>
  );
};

Card.propTypes = {
  /** Card content */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]),

  /** Card width */
  width: PropTypes.string,

  /** Card height */
  height: PropTypes.string,

  /** Card margin */
  spacing: PropTypes.string,

  /** Card padding */
  internalSpacing: PropTypes.string,

  /** Remove borders from the card */
  borderless: PropTypes.bool,

  /** Remove background color from the card */
  transparent: PropTypes.bool
};

Card.defaultProps = {
  width: 'auto',
  height: 'auto',
  spacing: '0px',
  internalSpacing: '20px 20px',
  borderless: false,
  transparent: false
};

export default Card;
