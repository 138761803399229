import React, { PureComponent } from 'react';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { Row, Col, Table, Form } from 'react-bootstrap';

import i18next from 'i18next';
import Pagination from '../../components/Pagination/Pagination';
import { formatDate, getRoleDisplayName } from '../../utils/generalUtils';
import LoadingIndicator from '../../components/LoadingIndicator';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/modals/ConfirmationModal/ConfirmationModal';
import { ACTIVATED } from '../../enums/validation';
import { BsPlus } from 'react-icons/bs';
import CssFilterTransition from '../../components/CssFilterTransition';
import debounce from 'lodash.debounce';
import { calculateTotalPages } from '../../utils/paging';
import Card from '../Card';
import PageTitle from '../../components/PageTitle';
import './Users.scss';
import VLButton from '../../components/Buttons/VLButton';
import VLIconButton from '../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import CreateUserModal from '../../components/modals/CreateUserModal';
import VLInput from '../../components/VLInput/VLInput';

const USER_ROW_LIMIT = 8;

class Users extends PureComponent {
  state = {
    usersPageNumber: 0,
    showCreateUserModal: false
  };

  rowLimit = this.props.pageSize || USER_ROW_LIMIT;

  componentDidMount() {
    this.props.getFirmUsersRequest(this.props.firm ? { firm: this.props.firm } : undefined);
  }

  componentDidUpdate(prevProps) {
    this.rowLimit = this.props.pageSize || USER_ROW_LIMIT;

    if (prevProps.users.data?.data?.length !== this.props.users.data?.data?.length) {
      const newTotalPages = calculateTotalPages(this.props.users.data?.data, this.rowLimit);

      // Make sure current page in range after filtering
      if (this.state.usersPageNumber >= newTotalPages) {
        this.setState({ usersPageNumber: 0 });
      }
    }
  }

  renderHeaderBar = () => {
    return (
      <Row className="user-control-container transactions-filters-container row no-gutters">
        <PageTitle text="Users" styleClass="dashboard-transactions-header" />

        <div className="inline-justifier">
          <VLIconButton
            Icon={FaFilter}
            onClick={() => {
              this.setState(s => ({
                displayFilters: !s.displayFilters
              }));
            }}
          />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>
      </Row>
    );
  };

  clearFilters = () => {
    this.setState(
      {
        nameSearch: '',
        emailSearch: '',
        firmSearch: ''
      },
      () => {
        this.handleSearchDebounced();
      }
    );
  };

  getFilteredUsers = () => {
    const payload = {};
    if (this.state.nameSearch) {
      payload.nameSearch = this.state.nameSearch;
    }
    if (this.state.emailSearch) {
      payload.emailSearch = this.state.emailSearch;
    }
    if (this.state.firmSearch) {
      payload.firmSearch = this.state.firmSearch;
    }
    if (this.props.firm) {
      payload.firm = this.props.firm;
    }

    this.props.getFirmUsersRequest(payload);
  };

  handleSearchDebounced = debounce(this.getFilteredUsers, 500);

  handleSearchFilter = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.handleSearchDebounced();
  };

  renderFilters = () => {
    return (
      <div>
        <div className="administration-filter">
          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Name</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="nameSearch"
                value={this.state.nameSearch}
                onChange={this.handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>

          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Email</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="emailSearch"
                value={this.state.emailSearch}
                onChange={this.handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>

          {!this.props.firm ? (
            <div className={`user-form-control-container`}>
              <Form.Label className="search-user-form-label">Firm</Form.Label>
              <div className="user-form-control-row">
                <VLInput
                  size="s"
                  type="text"
                  name="firmSearch"
                  value={this.state.firmSearch}
                  onChange={this.handleSearchFilter}
                  placeholder={'Search'}
                  isSearch
                />
              </div>
            </div>
          ) : null}
        </div>

        <VLButton
          text={i18next.t('transactionsFilters.clearFilter')}
          variant="clear"
          size={'m'}
          rightIcon={<BsPlus />}
          onClick={this.clearFilters}
        />
      </div>
    );
  };

  handleCreateUser = data => {
    const actionData = {
      payload: data,
      successCallback: data => {
        this.toggleModal('CreateUser');
        this.props.getFirmUsersRequest(this.props.firm ? { firm: this.props.firm } : undefined);
      },
      errorCallback: () => {
        toast.error('Email already taken');
      }
    };
    this.props.createUserRequest(actionData);
  };

  handleDeleteUser = () => {
    this.props.deleteAdminAccountRequest(
      {
        userid: this.state.objectToDelete.userid
      },
      { firm: this.props.firm ?? undefined }
    );
    this.resetConfirmation();
  };

  toggleConfirmationModal = (data, type) => {
    this.setState({
      showConfirmationModal: true,
      confirmationType: type,
      objectToDelete: data,
      confirmationOnSuccessFunk: this.handleDeleteUser
    });
  };

  handlePageClick = (data, field) => {
    this.setState({
      [`${field}PageNumber`]: data.selected
    });
  };

  toggleModal = fieldName => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`]
    });
  };

  resetConfirmation = () => {
    this.setState({
      confirmationOnSuccessFunk: () => undefined,
      confirmationType: 'firm',
      showConfirmationModal: false,
      objectToDelete: null
    });
  };

  toggleActiveFlag = data => {
    const payload = {
      userid: data.userid
    };

    this.props.toggleUserActive({
      payload,
      successCallback: () => {
        this.getFilteredUsers();
      }
    });
  };

  renderFirmUser = user => {
    const role = getRoleDisplayName(user.roles);

    return (
      <tr key={user.account}>
        <td>
          <div className="align-left">{user.attr ? `${user.attr.first_name} ${user.attr.last_name}` : user.userid}</div>
        </td>
        <td>
          <div className="align-left">{user.attr ? user.attr.email : '-'}</div>
        </td>
        <td>
          <div className="align-left">{role || '-'}</div>
        </td>
        <td>
          <div className="align-left">{user.firm}</div>
        </td>
        <td>
          <div className=" center-aligned-complex">
            <Form.Switch
              className="table-switch"
              size="2rem"
              type="switch"
              id={`custom-switch ${user.userid}`}
              label=""
              checked={user.active === ACTIVATED}
              onChange={() => this.toggleActiveFlag(user)}
            />
          </div>
        </td>
        <td>
          <div className="align-left">{user.lastLogin ? formatDate(user.lastLogin) : '-'}</div>
        </td>
        <td className="align-center">
          <div>
            <VLIconButton
              Icon={MdRemoveRedEye}
              variant="borderless"
              onClick={() => {
                this.props.history.push(`/users/${user.userid}`);
              }}
            />
          </div>
        </td>
        <td className="align-center">
          <div>
            {this.props.canEditUsers && (
              <VLIconButton
                Icon={MdDelete}
                variant="borderless"
                onClick={() => this.toggleConfirmationModal(user, 'user')}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { data, loading } = this.props.users;
    const startIndex = this.state.usersPageNumber * this.rowLimit;
    const usersList = (data?.data || []).slice(startIndex, startIndex + this.rowLimit);

    return (
      <Card>
        <Row noGutters className="firm-item">
          <Col>
            {this.renderHeaderBar()}
            <CssFilterTransition component={this.renderFilters} isOpen={this.state.displayFilters} />
            <div>
              {loading ? (
                <div className="users-loading">
                  <LoadingIndicator />
                </div>
              ) : null}

              {!loading && usersList.length ? (
                <Table className="vl-table" borderless responsive>
                  <thead>
                    <tr>
                      <th className="align-left">{i18next.t('firmDetails.name')}</th>
                      <th className="align-left">{i18next.t('firmDetails.eMail')}</th>
                      <th className="align-left">{i18next.t('firmDetails.role')}</th>
                      <th className="align-left">{i18next.t('firmDetails.firm')}</th>
                      <th>{i18next.t('firmDetails.active')}</th>
                      <th className="align-left">{i18next.t('firmDetails.lastLogin')}</th>
                      <th>{i18next.t('firmDetails.details')}</th>
                      {this.props.canEditUsers && <th>Delete</th>}
                    </tr>
                  </thead>
                  <tbody>{usersList.map(user => this.renderFirmUser(user))}</tbody>
                </Table>
              ) : null}

              {!loading && !usersList.length ? (
                <Row noGutters className="centered-row">
                  <span className="users-table-empty-text">There are no users that match the criteria</span>
                </Row>
              ) : null}
            </div>

            <div className="pagination">
              <Pagination
                totalPages={Math.ceil(this.props.users.data?.data?.length / this.rowLimit)}
                handlePageClick={data => this.handlePageClick(data, 'users')}
                dataPageNumber={this.state.usersPageNumber}
              />
            </div>

            <Row noGutters className="centered-row">
              {this.props.canEditUsers && (
                <VLButton onClick={() => this.toggleModal('CreateUser')} text={'Create user'} rightIcon={<IoMdAdd />} />
              )}{' '}
            </Row>
            {this.state.showConfirmationModal && (
              <ConfirmationModal
                assetType={this.state.confirmationType}
                onSuccess={this.state.confirmationOnSuccessFunk}
                onClose={this.resetConfirmation}
              />
            )}
            {this.state.showCreateUserModal && (
              <CreateUserModal onClose={() => this.toggleModal('CreateUser')} onCreateUser={this.handleCreateUser} />
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}
export default Users;
