import { applyMiddleware, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createBrowserHistory } from '../node_modules/react-router-dom/node_modules/history';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './redux/reducers';
import { routerMiddleware } from 'react-router-redux';
import sagas from './redux/sagas';

export const history = createBrowserHistory();

const devToolsOptions = {
  trace: true
};

const composeEnhancers = composeWithDevTools(devToolsOptions);

const sagaMiddleware = createSagaMiddleware();

const middleware = [routerMiddleware(history), sagaMiddleware];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

sagaMiddleware.run(sagas);

export default { store };
