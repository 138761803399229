export const FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS = 'FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS';
export const FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_SUCCESS = 'FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_SUCCESS';
export const FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_ERROR = 'FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_ERROR';
export const FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS = 'FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS';
export const FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_SUCCESS =
  'FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_SUCCESS';
export const FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_ERROR = 'FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_ERROR';
export const UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS = 'UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS';
export const UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_SUCCESS = 'UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_SUCCESS';
export const UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_ERROR = 'UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_ERROR';
export const FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID = 'FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID';
export const FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_SUCCESS = 'FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_SUCCESS';
export const FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_ERROR = 'FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_ERROR';

export const FETCH_STAKING_REQUEST = 'FETCH_STAKING_REQUEST';
export const FETCH_STAKING_REQUEST_SUCCESS = 'FETCH_STAKING_REQUEST_SUCCESS';
export const FETCH_STAKING_REQUEST_ERROR = 'FETCH_STAKING_REQUEST_ERROR';

export const FETCH_EXTERNAL_USD_TRANSACTIONS = 'FETCH_EXTERNAL_USD_TRANSACTIONS';
export const FETCH_EXTERNAL_USD_TRANSACTIONS_SUCCESS = 'FETCH_EXTERNAL_USD_TRANSACTIONS_SUCCESS';
export const FETCH_EXTERNAL_USD_TRANSACTIONS_ERROR = 'FETCH_EXTERNAL_USD_TRANSACTIONS_ERROR';
export const FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS = 'FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS';
export const FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_SUCCESS = 'FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_SUCCESS';
export const FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_ERROR = 'FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_ERROR';
export const FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS = 'FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS';
export const FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_SUCCESS = 'FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_SUCCESS';
export const FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_ERROR = 'FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_ERROR';
export const UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS = 'UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS';
export const UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_SUCCESS = 'UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_SUCCESS';
export const UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_ERROR = 'UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_ERROR';

export const ADD_STAKING_REQUEST = 'ADD_STAKING_REQUEST';
export const EXIT_STAKING_REQUEST = 'EXIT_STAKING_REQUEST';

export const FETCH_SUPPORTED_ASSETS_REQUEST = 'FETCH_SUPPORTED_ASSETS_REQUEST';
export const FETCH_SUPPORTED_ASSETS_SUCCESS = 'FETCH_SUPPORTED_ASSETS_SUCCESS';
export const FETCH_SUPPORTED_ASSETS_ERROR = 'FETCH_SUPPORTED_ASSETS_ERROR';

export const FETCH_INTERNAL_WALLETS_REQUEST = 'FETCH_INTERNAL_WALLETS_REQUEST';
export const FETCH_INTERNAL_WALLETS_SUCCESS = 'FETCH_INTERNAL_WALLETS_SUCCESS';
export const FETCH_INTERNAL_WALLETS_ERROR = 'FETCH_INTERNAL_WALLETS_ERROR';

export const ADD_INTERNAL_WALLETS_REQUEST = 'ADD_INTERNAL_WALLETS_REQUEST';

export const CREATE_EXTERNAL_TRANSACTION_REQUEST = 'CREATE_EXTERNAL_TRANSACTION_REQUEST';

export const CREATE_EXTERNAL_TRANSACTION_RISK_SCORE = 'CREATE_EXTERNAL_TRANSACTION_RISK_SCORE';
export const CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_SUCCESS = 'CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_SUCCESS';
export const CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_ERROR = 'CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_ERROR';

export const CREATE_USD_DEPOSIT_REQUEST = 'CREATE_USD_DEPOSIT_REQUEST';
export const CREATE_USD_WITHDRAW_REQUEST = 'CREATE_USD_WITHDRAW_REQUEST';

export const EXPORT_TRANSACTIONS_USD = 'EXPORT_TRANSACTIONS_USD';
export const EXPORT_TRANSACTIONS_CRYPTO = 'EXPORT_TRANSACTIONS_CRYPTO';
export const EXPORT_TRANSACTIONS_STAKING = 'EXPORT_TRANSACTIONS_STAKING';
export const EXPORT_CTR = 'EXPORT_CTR';

////////////////////////////////////////////////////////////////////
export const UPLOAD_CRYPTO_REPORT = 'UPLOAD_CRYPTO_REPORT';
export const UPLOAD_CRYPTO_REPORT_SUCCESS = 'UPLOAD_CRYPTO_REPORT_SUCCESS';
export const UPLOAD_CRYPTO_REPORT_ERROR = 'UPLOAD_CRYPTO_REPORT_ERROR';

////////////////////////////////////////////////////////////////////

export const DELETE_CRYPTO_REPORT = 'DELETE_CRYPTO_REPORT';
export const DELETE_CRYPTO_REPORT_SUCCESS = 'DELETE_CRYPTO_REPORT_SUCCESS';
export const DELETE_CRYPTO_REPORT_ERROR = 'DELETE_CRYPTO_REPORT_ERROR';

/////////////////////////////////////////////////////////////////////

export const DOWNLOAD_CRYPTO_REPORT = 'DOWNLOAD_CRYPTO_REPORT';
export const GET_CRYPTO_REPORT_LIST = 'GET_CRYPTO_REPORT_LIST';

/////////////////////////////////////////////////////////////////////

export const FETCH_STAKING_REWARDS_REQUEST = 'FETCH_STAKING_REWARDS_REQUEST';
export const FETCH_STAKING_REWARDS_SUCCESS = 'FETCH_STAKING_REWARDS_SUCCESS';
export const FETCH_STAKING_REWARDS_ERROR = 'FETCH_STAKING_REWARDS_ERROR';

/////////////////////////////////////////////////////////////////////

export const FETCH_STAKING_REWARDS_SUMMARY_REQUEST = 'FETCH_STAKING_REWARDS_SUMMARY_REQUEST';
export const FETCH_STAKING_REWARDS_SUMMARY_SUCCESS = 'FETCH_STAKING_REWARDS_SUMMARY_SUCCESS';
export const FETCH_STAKING_REWARDS_SUMMARY_ERROR = 'FETCH_STAKING_REWARDS_SUMMARY_ERROR';

/////////////////////////////////////////////////////////////////////

export const TRANSACTIONS_FEE_REQUEST = 'TRANSACTIONS_FEE_REQUEST';
export const TRANSACTIONS_FEE_SUCCESS = 'TRANSACTIONS_FEE_SUCCESS';
export const TRANSACTIONS_FEE_ERROR = 'TRANSACTIONS_FEE_ERROR';
