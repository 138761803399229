import * as CONSTANTS from '../constants/transactionsExternal';

export const fetchExternalPendingCryptoTrasactions = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS,
  data
});
export const fetchExternalPendingCryptoTrasactionsSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_SUCCESS,
  data
});
export const fetchExternalPendingCryptoTrasactionsError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_PENDING_CRYPTO_TRANSACTIONS_ERROR,
  error
});

export const fetchExternalReviewedCryptoTrasactions = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS,
  data
});
export const fetchExternalReviewedCryptoTrasactionsSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_SUCCESS,
  data
});
export const fetchExternalReviewedCryptoTrasactionsError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_REVIEWED_CRYPTO_TRANSACTIONS_ERROR,
  error
});

export const updateStatusExternalCryptoTrasactions = data => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS,
  data
});
export const updateStatusExternalCryptoTrasactionsSuccess = data => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_SUCCESS,
  data
});
export const updateStatusExternalCryptoTrasactionsError = error => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_CRYPTO_TRANSACTIONS_ERROR,
  error
});

export const fetchExternalCryptoTransactionById = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID,
  data
});
export const fetchExternalCryptoTransactionByIdSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_SUCCESS,
  data
});
export const fetchExternalCryptoTransactionByIdError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_CRYPTO_TRANSACTION_BY_ID_ERROR,
  error
});

export const fetchStakingRequest = data => ({
  type: CONSTANTS.FETCH_STAKING_REQUEST,
  data
});
export const fetchStakingRequestSuccess = data => ({
  type: CONSTANTS.FETCH_STAKING_REQUEST_SUCCESS,
  data
});
export const fetchStakingRequestError = error => ({
  type: CONSTANTS.FETCH_STAKING_REQUEST_ERROR,
  error
});

export const fetchExternalUSDTransactions = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS,
  data
});
export const fetchExternalUSDTransactionsSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS_SUCCESS,
  data
});
export const fetchExternalUSDTransactionsError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_TRANSACTIONS_ERROR,
  error
});

export const fetchExternalUSDPendingTransactions = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS,
  data
});
export const fetchExternalUSDPendingTransactionsSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_SUCCESS,
  data
});
export const fetchExternalUSDPendingTransactionsError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_PENDING_TRANSACTIONS_ERROR,
  error
});

export const fetchExternalUSDReviewedTransactions = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS,
  data
});
export const fetchExternalUSDReviewedTransactionsSuccess = data => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_SUCCESS,
  data
});
export const fetchExternalUSDReviewedTransactionsError = error => ({
  type: CONSTANTS.FETCH_EXTERNAL_USD_REVIEWED_TRANSACTIONS_ERROR,
  error
});

export const updateStatusExternalUSDTransactions = data => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS,
  data
});
export const updateStatusExternalUSDTransactionsSuccess = data => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_SUCCESS,
  data
});
export const updateStatusExternalUSDTransactionsError = error => ({
  type: CONSTANTS.UPDATE_STATUS_EXTERNAL_USD_TRANSACTIONS_ERROR,
  error
});

export const addStakingRequest = data => ({
  type: CONSTANTS.ADD_STAKING_REQUEST,
  data
});

export const exitStakingRequest = data => ({
  type: CONSTANTS.EXIT_STAKING_REQUEST,
  data
});

export const fetchStakingRewardsRequest = data => ({
  type: CONSTANTS.FETCH_STAKING_REWARDS_REQUEST,
  data
});

export const fetchStakingRewardsSuccess = data => ({
  type: CONSTANTS.FETCH_STAKING_REWARDS_SUCCESS,
  data
});

export const fetchStakingRewardsError = data => ({
  type: CONSTANTS.FETCH_STAKING_REWARDS_ERROR,
  data
});

export const fetchStakingRewardsSummaryRequest = data => {
  return {
    type: CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_REQUEST,
    data
  };
};

export const fetchStakingRewardsSummarySuccess = data => ({
  type: CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_SUCCESS,
  data
});

export const fetchStakingRewardsSummaryError = data => ({
  type: CONSTANTS.FETCH_STAKING_REWARDS_SUMMARY_ERROR,
  data
});

export const fetchExternalTransactionSupportedAssets = data => ({
  type: CONSTANTS.FETCH_SUPPORTED_ASSETS_REQUEST,
  data
});
export const fetchExternalTransactionSupportedAssetsSuccess = data => ({
  type: CONSTANTS.FETCH_SUPPORTED_ASSETS_SUCCESS,
  data
});
export const fetchExternalTransactionSupportedAssetsError = error => ({
  type: CONSTANTS.FETCH_SUPPORTED_ASSETS_ERROR,
  error
});

export const fetchInternalWalletsRequest = data => ({
  type: CONSTANTS.FETCH_INTERNAL_WALLETS_REQUEST,
  data
});
export const fetchInternalWalletsSuccess = data => ({
  type: CONSTANTS.FETCH_INTERNAL_WALLETS_SUCCESS,
  data
});
export const fetchInternalWalletsError = error => ({
  type: CONSTANTS.FETCH_INTERNAL_WALLETS_ERROR,
  error
});

export const addInternalWalletsRequest = data => ({
  type: CONSTANTS.ADD_INTERNAL_WALLETS_REQUEST,
  data
});

export const createExternalTransactionRequest = data => ({
  type: CONSTANTS.CREATE_EXTERNAL_TRANSACTION_REQUEST,
  data
});

export const createExternalTransactionRiskScore = data => ({
  type: CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE,
  data
});

export const createExternalTransactionRiskScoreSuccess = data => ({
  type: CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_SUCCESS,
  data
});

export const createExternalTransactionRiskScoreError = data => ({
  type: CONSTANTS.CREATE_EXTERNAL_TRANSACTION_RISK_SCORE_ERROR,
  data
});

export const createExternalDepositUSDRequest = data => ({
  type: CONSTANTS.CREATE_USD_DEPOSIT_REQUEST,
  data
});

export const createExternalWithdrawUSDRequest = data => ({
  type: CONSTANTS.CREATE_USD_WITHDRAW_REQUEST,
  data
});

export const uploadTransactionCryptoBtaReportRequest = data => ({
  type: CONSTANTS.UPLOAD_CRYPTO_REPORT,
  data
});

export const uploadTransactionCryptoBtaReportSuccess = data => ({
  type: CONSTANTS.UPLOAD_CRYPTO_REPORT_SUCCESS,
  data
});

export const uploadTransactionCryptoBtaReportError = error => ({
  type: CONSTANTS.UPLOAD_CRYPTO_REPORT_ERROR,
  error
});

export const deleteTransactionCryptoBtaReportRequest = data => ({
  type: CONSTANTS.DELETE_CRYPTO_REPORT,
  data
});

export const deleteTransactionCryptoBtaReportSuccess = data => ({
  type: CONSTANTS.DELETE_CRYPTO_REPORT_SUCCESS,
  data
});

export const deleteTransactionCryptoBtaReportError = error => ({
  type: CONSTANTS.DELETE_CRYPTO_REPORT_ERROR,
  error
});

export const getTransactionFeeRequest = data => ({
  type: CONSTANTS.TRANSACTIONS_FEE_REQUEST,
  data
});

export const getTransactionFeeSuccess = data => ({
  type: CONSTANTS.TRANSACTIONS_FEE_SUCCESS,
  data
});

export const getTransactionFeeError = data => ({
  type: CONSTANTS.TRANSACTIONS_FEE_ERROR,
  data
});
