import IOI from './IOI';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { makeIOIV2Request } from '../../../redux/actions/websocket';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  userData: state.login.profileDetails.data,
  allBooks: state.websocket.allBooks,
  assetPair: state.trade.selectedPair
});

const mapDispatchToProps = {
  makeIOIV2Request
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(IOI);
