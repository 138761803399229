export const FETCH_CHART_DATA = 'FETCH_CHART_DATA ';
export const FETCH_CHART_DATA_SUCCESS = 'FETCH_CHART_DATA_SUCCESS ';
export const FETCH_CHART_DATA_ERROR = 'FETCH_CHART_DATA_ERROR ';

export const FETCH_24H_MARKET_DATA = 'FETCH_24H_MARKET_DATA ';
export const FETCH_24H_MARKET_DATA_SUCCESS = 'FETCH_24H_MARKET_DATA_SUCCESS ';
export const FETCH_24H_MARKET_DATA_ERROR = 'FETCH_24H_MARKET_DATA_ERROR ';

export const FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST = 'FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST ';
export const FETCH_24H_MARKET_DATA_ALL_ASSETS_SUCCESS = 'FETCH_24H_MARKET_DATA_ALL_ASSETS_SUCCESS ';
export const FETCH_24H_MARKET_DATA_ALL_ASSETS_ERROR = 'FETCH_24H_MARKET_DATA_ALL_ASSETS_ERROR ';

export const UPDATE_ASSET_PAIR = 'UPDATE_ASSET_PAIR ';

export const FETCH_TRADE_ASSET_PAIRS = 'FETCH_TRADE_ASSET_PAIRS';
export const FETCH_TRADE_ASSET_PAIRS_SUCCESS = 'FETCH_TRADE_ASSET_PAIRS_SUCCESS';
export const FETCH_TRADE_ASSET_PAIRS_ERROR = 'FETCH_TRADE_ASSET_PAIRS_ERROR';

export const SET_SELECTED_IOI = 'SET_SELECTED_IOI';

export const SET_SELECTED_LMT = 'SET_SELECTED_LMT';

export const GET_IOI = 'GET_IOI';
export const GET_IOI_SUCCESS = 'GET_IOI_SUCCESS';
export const GET_IOI_ERROR = 'GET_IOI_ERROR';

export const ADD_IOI = 'ADD_IOI';
