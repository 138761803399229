import './AccountsTableAdmin.scss';
import '../DatePicker/DatePicker.scss';
import { Badge, Form, Row, Table } from 'react-bootstrap';
import { FILTER_ASSET, FILTER_STATUS, FILTER_BTA_STATUS, FILTER_TYPE, TIER } from '../../enums/validation';
import URLS from '../../redux/constants/urls';
import DatePicker, { dateTransactionsOptions } from '../DatePicker';
import CssFilterTransition from '../CssFilterTransition';
import { BsPlus } from 'react-icons/bs';
import { MdDelete, MdFileDownload, MdFileUpload, MdRefresh } from 'react-icons/md';
import MediaQuery from 'react-responsive';
import ExportButton from '../ExportButton';
import UploadReportModal from '../modals/UploadReportModal';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import Select from 'react-select';
import Pagination from '../Pagination/Pagination';
import LoadingIndicator from '../LoadingIndicator';
import { calculatePageSlice, calculateTotalPages } from '../../utils/paging';
import {
  capitalizeFirstLetter,
  createDefaultContainsFilterFn,
  createDefaultDateRangeFilterFn,
  formatDate
} from '../../utils/generalUtils';
import { toast } from 'react-toastify';
import { decodeRole } from '../../utils/roleCheckers';
import { SUPER_USER_TYPES } from '../../enums/roles';
import GeneralConfirmModal from '../modals/GeneralConfirmModal/GeneralConfirmModal';
import VLButton from '../Buttons/VLButton';
import VLSelect from '../VLSelect';
import VLIconButton from '../Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import PageTitle from '../PageTitle';
import VLInput from '../VLInput';

const PAGE_SIZE = 5;

const SELECT_FILTERS = [
  { label: 'Type', key: 'type' },
  { label: 'BTA status', key: 'btaStatus' },
  { label: 'BM status', key: 'status' }
];

const SEARCH_FILTERS = [
  { label: 'Pseudo account number', key: 'userId' },
  { label: 'Name', key: 'fullName' }
];

const APPROVAL_SELECT_VALUES = [
  {
    value: 'APPROVE',
    label: 'Approve'
  },
  {
    value: 'REJECT',
    label: 'Reject'
  }
];

const accountFilteringFns = {
  userId: createDefaultContainsFilterFn,
  fullName: createDefaultContainsFilterFn,
  type: createDefaultContainsFilterFn,
  status: createDefaultContainsFilterFn,
  btaStatus: createDefaultContainsFilterFn,
  date: createDefaultDateRangeFilterFn
};

const initialFiltersState = {
  userId: { value: '' },
  fullName: { value: '' },
  type: { value: '' },
  status: { value: '' },
  btaStatus: { value: '' },
  date: { value: '' }
};

class AccountsTableAdmin extends PureComponent {
  state = {
    filters: {
      ...initialFiltersState
    },
    accounts: this.props.accounts,
    typingTimeout: 0,
    displayFilters: false,
    currentPage: 0,
    isLoading: false,
    uploadReportUserId: null,
    deleteReportUserId: null
  };

  handleFilterChange = (target, data) => {
    this.setState(
      s => ({
        filters: {
          ...s.filters,
          [data.name]: {
            value: target.value,
            label: target.label,
            name: data.name
          }
        }
      }),
      () => {
        this.props.triggerFilters(this.state.filters, this.props.pendingAccounts);
      }
    );
  };

  filterEventDataByCategory = () => {
    return this.state.accounts;
  };

  clearFilters = () => {
    this.setState(
      {
        filters: { ...initialFiltersState },
        accounts: this.props.accounts
      },
      () => {
        this.props.triggerFilters(this.state.filters, this.props.pendingAccounts);
      }
    );
  };

  handleSearchChanges = ({ target }) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState(s => ({
      filters: { ...s.filters, [target.name]: { value: target.value } },
      typingTimeout: setTimeout(() => {
        this.props.triggerFilters(this.state.filters, this.props.pendingAccounts);
      }, 1000)
    }));
  };

  getType = () => [
    { value: '', label: 'Select' },
    { value: '', label: 'All' },
    {
      value: 'SCA',
      label: 'SCA'
    },
    {
      value: 'PCA',
      label: 'PCA'
    }
  ];

  getStatusData = () => [
    { value: '', label: 'Select' },
    { value: '', label: 'All' },
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'rejected',
      label: 'Rejected'
    }
  ];

  getFilterTier = () => [
    { value: '', label: 'Select' },

    {
      value: '1',
      label: '1'
    },
    {
      value: '2',
      label: '2'
    },
    {
      value: '3',
      label: '3'
    },
    {
      value: '4',
      label: '4'
    }
  ];

  FILTER_DROPDOWN_OPTIONS = {
    [FILTER_TYPE]: this.getType,
    [FILTER_STATUS]: this.getStatusData,
    [FILTER_BTA_STATUS]: this.getStatusData,
    [TIER]: this.getFilterTier
  };

  handleStatusUpdateSuccess = () => {
    this.props.onReload(this.state.filters, this.props.pendingAccounts);
    this.setState({ isLoading: false });
    toast.success("Account's status updated successfully");
  };

  handleStatusUpdateError = () => {
    this.setState({ isLoading: false });
    toast.error('An error occurred while updating account status');
  };

  handleApproval = (option, userEmail) => {
    this.setState({ isLoading: true });
    if (option.value === 'APPROVE') {
      this.props.acceptOnboardingUserRequest({
        userId: userEmail,
        successCallback: this.handleStatusUpdateSuccess,
        errorCallback: this.handleStatusUpdateError
      });
    } else if (option.value === 'REJECT') {
      this.props.declineOnboardingUserRequest({
        userId: userEmail,
        successCallback: this.handleStatusUpdateSuccess,
        errorCallback: this.handleStatusUpdateError
      });
    }
  };

  triggerFilters = () => {
    let filteredAccounts = this.props.accounts;

    Object.keys(this.state.filters).forEach(filterKey => {
      const value = this.state.filters[filterKey].value;

      if (!value) return;

      filteredAccounts = filteredAccounts.filter(accountFilteringFns[filterKey](value, filterKey));
    });

    const newTotalPages = calculateTotalPages(filteredAccounts, PAGE_SIZE);

    this.setState({ accounts: filteredAccounts }, () => {
      // Make sure current page in range after filtering
      if (this.state.currentPage >= newTotalPages) {
        this.setState({ currentPage: newTotalPages - 1 });
      } else if (this.state.currentPage < 0 && newTotalPages > 0) {
        this.setState({ currentPage: 0 });
      }
    });
  };

  handleDateChange = date => {
    this.setState(
      s => ({
        filters: {
          ...s.filters,
          date: {
            value: date
          }
        }
      }),
      () => {
        this.props.triggerFilters(this.state.filters, this.props.pendingAccounts);
      }
    );
  };

  buildFilters = () => {
    if (FILTER_ASSET === 'asset') {
      i18next.t('transactionsFilters.counterparty3');
    }
    const dropdowns = SELECT_FILTERS.map(filter => {
      if (filter.key === 'status' && this.props.pendingAccounts) {
        return;
      }
      const filterOptionsShort = this.FILTER_DROPDOWN_OPTIONS[filter.key](true);
      const filterOptionsLong = this.FILTER_DROPDOWN_OPTIONS[filter.key]();

      return (
        <div key={filter.label}>
          <MediaQuery maxWidth={1960}>
            <div className="filter-control-wrapper">
              <Form.Label className="search-user-form-label search">{filter.label}</Form.Label>
              <VLSelect
                name={filter.key}
                options={filterOptionsShort.slice(1)}
                onChange={this.handleFilterChange}
                menuPortalTarget={document.body}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1961}>
            <div className="filter-control-wrapper">
              <Form.Label className="search-user-form-label search">{filter.label}</Form.Label>
              <VLSelect
                name={filter.key}
                options={filterOptionsLong.slice(1)}
                onChange={this.handleFilterChange}
                menuPortalTarget={document.body}
              />
            </div>
          </MediaQuery>
        </div>
      );
    });

    const searches = SEARCH_FILTERS.map((filter, index) => {
      return (
        <div className="filter-control-wrapper" key={filter.label}>
          <Form.Label className="search-user-form-label search">{filter.label}</Form.Label>
          <div className="user-form-control-wrap">
            <VLInput
              name={filter.key}
              placeholder="Search"
              value={this.state.filters[filter.key].value}
              onChange={this.handleSearchChanges}
              size="s"
              isSearch
            />
          </div>
        </div>
      );
    });

    const dateFilter = (
      <div className="date-filter-control-wrapper" key={searches}>
        <Form.Label className="search-user-form-label">{i18next.t('transactionsFilters.dataRange')}</Form.Label>
        <div className="user-form-control-wrap">
          <DatePicker
            key={'accounts'}
            onClick={this.handleDateChange}
            options={{
              ...dateTransactionsOptions,
              drops: 'down',
              parentEl: '.payments-date-parent'
            }}
            date={this.state.filters.date?.value}
          />
        </div>
        <div>
          <VLButton
            text={i18next.t('transactionsFilters.clearFilter')}
            variant="clear"
            size={'m'}
            rightIcon={<BsPlus />}
            onClick={this.clearFilters}
          />
        </div>
      </div>
    );
    return [dateFilter, ...searches, ...dropdowns];
  };

  toggleFilters = () => this.setState({ displayFilters: !this.state.displayFilters });
  formatExportParams = () => {
    return {
      userId: this.state.filters.userId.value,
      name: this.state.filters.fullName.value,
      type: this.state.filters.type.value,
      btaStatus: this.state.filters.btaStatus.value,
      status: this.props.pendingAccounts ? null : this.state.filters.status.value,
      from: this.state.filters.date.value[0] && this.state.filters.date.value[0],
      to: this.state.filters.date.value[1] && this.state.filters.date.value[1]
    };
  };

  returnButtonBar = toogle => {
    return (
      <Row className="user-control-container transactions-filters-container row no-gutters">
        <PageTitle text={this.props.title} styleClass="dashboard-transactions-header" />
        <div className="inline-justifier">
          <VLIconButton
            Icon={MdRefresh}
            onClick={() => this.props.onReload(this.state.filters, this.props.pendingAccounts)}
          />
          <div className="vl-button-out-text">Reload</div>
        </div>
        <div className="inline-justifier">
          <VLIconButton Icon={FaFilter} onClick={toogle} />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>
        <div className="transactions-filter-button-container">
          <ExportButton
            isExportTitle
            title="transactions"
            type={this.props.pendingAccounts ? 'pendingUsers' : 'nonPendingUsers'}
            params={this.formatExportParams()}
            transactionListData={this.filterEventDataByCategory}
            transactionTableType={'accounts'}
          />
        </div>
      </Row>
    );
  };
  returnFilter = display => {
    return <CssFilterTransition component={this.buildFilters} isOpen={display}></CssFilterTransition>;
  };

  handlePageClick = data => {
    this.setState({
      currentPage: data.selected
    });
  };

  handleReportDeleteConfirmed = () => {
    this.setState({
      deleteReportUserId: null,
      isLoading: true
    });
    this.props.deleteBtaReportRequest({
      userId: this.state.deleteReportUserId,
      successCallback: () => {
        toast.success('Report deleted successfully');
        this.setState({
          isLoading: false
        });
      },
      errorCallback: () => {
        toast.error('Error deleting report');
        this.setState({
          isLoading: false
        });
      }
    });
  };

  canApproveStatus = (userToApprove, currentUser) => {
    if (!userToApprove || !currentUser) return false;
    if (userToApprove.btaStatus !== 'APPROVED') return false;
    if (userToApprove.status !== 'PENDING') return false;

    const userPermissions = decodeRole(currentUser.roles);
    if (
      userPermissions.superAdminType !== SUPER_USER_TYPES.BANK_MANAGER &&
      userPermissions.superAdminType !== SUPER_USER_TYPES.VL_ADMIN
    ) {
      return false;
    }

    return true;
  };

  renderReportActions = ({ onboardingUser, reportId, currentUser }) => {
    if (!currentUser) return null;

    const userPermissions = decodeRole(currentUser.roles);

    const downloadOption = (
      <a
        download
        target="_blank"
        rel="noreferrer"
        href={`${process.env.REACT_APP_BASE_URL}${URLS.DOWNLOAD_BTA_REPORT_REQUEST}/${reportId}`}
      >
        <VLIconButton Icon={MdFileDownload} variant="borderless" />
      </a>
    );

    const uploadOption = (
      <VLIconButton
        onClick={() => {
          this.setState({
            uploadReportUserId: onboardingUser.userId
          });
        }}
        Icon={MdFileUpload}
        variant="borderless"
      />
    );

    const deleteOption = (
      <VLIconButton
        onClick={() => {
          this.setState({
            deleteReportUserId: onboardingUser.userId
          });
        }}
        Icon={MdDelete}
        variant="borderless"
      />
    );

    // changing reports after bank manager approval is not allowed
    if (onboardingUser.status !== 'PENDING') {
      return reportId ? downloadOption : 'Not uploaded';
    }

    // only bta manager (superuser - vl admin) can upload report
    if (
      userPermissions.superAdminType === SUPER_USER_TYPES.BTA_MANAGER ||
      userPermissions.superAdminType === SUPER_USER_TYPES.VL_ADMIN
    ) {
      return (
        <>
          {reportId ? (
            <>
              {downloadOption}
              {deleteOption}
            </>
          ) : (
            uploadOption
          )}
        </>
      );
    }

    // bank manager can only download reports
    if (userPermissions.superAdminType === SUPER_USER_TYPES.BANK_MANAGER) {
      return reportId ? downloadOption : 'Not uploaded';
    }

    return 'N/A';
  };

  render() {
    const accounts = this.props.accounts;

    const totalPages = calculateTotalPages(accounts, PAGE_SIZE);
    const dataToShow = calculatePageSlice(accounts, PAGE_SIZE, this.state.currentPage);

    const currentUser = this.props.profileDetails.data;

    return (
      <React.Fragment>
        {(this.props.isLoading || this.state.isLoading) && <LoadingIndicator />}
        {this.returnButtonBar(this.toggleFilters)}
        <div className="overflowX-scroll-wrapper">
          <div className="scroll-content-wrapper">
            {this.returnFilter(this.state.displayFilters)}
            <Table borderless responsive className="accounts-table vl-table">
              <thead>
                <tr>
                  <th className="align-left">
                    <div className="accounts-date-th">Date</div>
                  </th>
                  <th className="align-left">Pseudo account number</th>
                  <th className="align-left">Name</th>
                  <th className="align-left">Score</th>
                  <th className="align-left">Tier</th>
                  <th className="align-left">Type</th>
                  <th className="align-left">BTA status</th>
                  <th>Report</th>
                  <th>BM status</th>
                </tr>
              </thead>
              <tbody>
                {dataToShow.map(userAccount => {
                  return (
                    <tr key={userAccount.email}>
                      <td>
                        <div className="align-left">
                          {userAccount.date ? formatDate(new Date(userAccount.date)) : 'N/A'}
                        </div>
                      </td>
                      <td>
                        <div className="align-left">{userAccount.secondaryAccount}</div>
                      </td>
                      <td>
                        <div className="align-left">{userAccount.fullName}</div>
                      </td>
                      <td>
                        <div className="align-left">{userAccount.riskScore || 'N/A'}</div>
                      </td>
                      <td>
                        <div
                          style={{
                            textAlignLast: 'center !important',
                            alignContent: 'center'
                          }}
                          className="align-left"
                        >
                          {userAccount.tier || 'N/A'}
                        </div>
                      </td>
                      <td>
                        <div className="align-left">{userAccount.type}</div>
                      </td>
                      <td>
                        <div className="align-left">
                          <Badge pill className={'status-badge-' + userAccount.btaStatus.toLowerCase()}>
                            {capitalizeFirstLetter(userAccount.btaStatus.toLowerCase()).replace('-', ' ')}
                          </Badge>
                        </div>
                      </td>
                      <td>
                        <div className="accounts-table-report-actions table-content-center">
                          {this.renderReportActions({
                            onboardingUser: userAccount,
                            reportId: userAccount.reportId,
                            currentUser
                          })}
                        </div>
                      </td>
                      <td>
                        <div className="grey-td-accounts-global table-content-center">
                          {this.canApproveStatus(userAccount, currentUser) ? (
                            <Select
                              classNamePrefix="select-form"
                              name="status"
                              options={APPROVAL_SELECT_VALUES}
                              isSearchable={false}
                              placeholder={'Select'}
                              isDisabled={this.state.isLoading}
                              menuPortalTarget={document.body}
                              onChange={e => {
                                this.handleApproval(e, userAccount.email);
                              }}
                              value={userAccount.status}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          ) : (
                            <Badge pill className={'status-badge-' + userAccount.status.toLowerCase()}>
                              {capitalizeFirstLetter(userAccount.status.toLowerCase()).replace('-', ' ')}
                            </Badge>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {this.state.uploadReportUserId ? (
              <UploadReportModal
                id={this.state.uploadReportUserId}
                isOpen={this.state.uploadReportUserId}
                onCompleted={() => {
                  this.setState({
                    uploadReportUserId: null
                  });
                }}
                onClose={() => {
                  this.setState({
                    uploadReportUserId: null
                  });
                }}
                uploadCallback={this.props.uploadBtaReportRequest}
              />
            ) : null}
            <GeneralConfirmModal
              isOpen={this.state.deleteReportUserId}
              title="Confirm deleting report"
              text="Are you sure you want to delete this report?"
              onConfirm={this.handleReportDeleteConfirmed}
              onCancel={() => {
                this.setState({
                  deleteReportUserId: null
                });
              }}
            />
            {totalPages && totalPages > 1 ? (
              <Pagination
                totalPages={totalPages}
                handlePageClick={this.handlePageClick}
                dataPageNumber={this.state.currentPage}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountsTableAdmin;
