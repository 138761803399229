import React, { PureComponent } from 'react';

import { Modal } from 'react-bootstrap';
import i18next from 'i18next';

import './ConfirmationSourceModal.scss';
import VLButton from '../../Buttons/VLButton';

class ConfirmationSourceModal extends PureComponent {
  getTitle = () => {
    const { asset = 'BTC' } = this.props;
    return i18next.t(`confirmationSourceModal.${asset.toLowerCase()}.title`) ?? '';
  };

  getBody = () => {
    const { asset = 'BTC' } = this.props;
    return i18next.t(`confirmationSourceModal.${asset.toLowerCase()}.description`) ?? '';
  };

  render() {
    return (
      <Modal
        className="confirmation-modal common-modal"
        centered
        show
        onHide={this.props.onClose}
        backdrop="static"
        contentClassName="confirm-source"
      >
        <Modal.Header closeButton>
          <Modal.Title> {this.getTitle()} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmation-modal-body">
          <p className="confirmation-modal-text">{this.getBody()}</p>
        </Modal.Body>
        <Modal.Footer className="transaction-confirmation-modal-footer">
          <div className="sfcb-button-wrapper">
            <VLButton
              width="100%"
              onClick={this.props.handleSelect}
              text={i18next.t('confirmationSourceModal.confirmation')}
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationSourceModal;
