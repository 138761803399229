import React, { PureComponent } from 'react';
import { SHOW_ALL_ACCOUNTS_FLAG } from '../../../enums/validation';
import { AccountFilterContext } from '../../../contexts/AccountFilterContext';
import i18next from 'i18next';
import { buildAccountDropdownWithDisabledField } from '../../../utils/generalUtils';
import './AccountDropdown.scss';
import VLSelect from '../../VLSelect';

class AccountDropdown extends PureComponent {
  state = {
    selectedAccount: SHOW_ALL_ACCOUNTS_FLAG,
    displayAccountList: false,
    isSuperAdminView: false
  };

  static contextType = AccountFilterContext;

  componentDidMount() {
    this.props.getDepositAccounts({
      access: 'R'
    });
  }

  getFirstActiveAccount = accounts => {
    return accounts.find(a => a.active_account === 'Y');
  };

  componentDidUpdate(prevProps) {
    if (prevProps.accounts.data !== this.props.accounts.data) {
      if (!this.props.currentAccountNumber || this.props.currentAccountNumber === SHOW_ALL_ACCOUNTS_FLAG) {
        const firstAccount = this.getFirstActiveAccount(this.props.accounts.data);
        if (firstAccount) {
          this.handleSelectChange({ value: firstAccount.account });
        }
      }
    }
  }

  handleSelectChange = option => {
    this.props.setCurrentAccountNumber(option.value);
    this.props.getBalance({ account: option.value });
    this.props.getContactsRequest({ currentAccount: option.value });
  };

  render() {
    let accountOptions = buildAccountDropdownWithDisabledField(this.props.accounts?.data, 'account', 'account');

    const selectedAccount = accountOptions.find(a => a.value === this.props.currentAccountNumber);

    return (
      <div className="AccountDropdown">
        <div>{i18next.t('dashboardAccountDropdown.accountNo')}:</div>
        <div className="account-dropdown">
          <VLSelect
            borderless
            transparent
            placeholder={i18next.t('dashboardAccountDropdown.selectAccount')}
            isSearchable={true}
            name="selectedAccount"
            options={accountOptions}
            onChange={option => this.handleSelectChange(option, 'selectedAccount')}
            value={selectedAccount}
          />
        </div>
      </div>
    );
  }
}

export default AccountDropdown;
