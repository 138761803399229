import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VLButton from '../../components/Buttons/VLButton/VLButton';
import { MdKeyboardBackspace, MdAdd, MdDelete } from 'react-icons/md';
import QRCodeSVG from 'qrcode.react';
import './TwoFactor.scss';
import VerifyTwoFactorModal from '../../components/modals/VerifyTwoFactorModal';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Prompt } from 'react-router-dom';

/*
  Two factor authentication flow should be fixed by the FI in order to be implemented properly.
  TFA should be enabled only after the users has successfully scanned the QR code and verified that 
  everything works as expected. At this moment 2FA is enable as soon as QR code is generated.
*/

const TwoFactorAuth = ({ history, userProfile, enableTwoFactor, disableTwoFactor, getUserProfile }) => {
  const [QRCode, setQRCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isTwoFactorEnabled = userProfile?.data && userProfile.data?.use2fa === 'Y';

  const handleBackButton = () => {
    history.goBack();
  };

  const handleTwoFactorEnable = () => {
    setIsLoading(true);
    enableTwoFactor({
      successCallback: ({ data }) => {
        setQRCode(data.qrCode);
        setIsLoading(false);
        toast.success('Two Factor Authentication enabled, please scan code');
        getUserProfile();
      },
      errorCallback: () => {
        toast.error(i18next.t('toasts.error'));
        setIsLoading(false);
      }
    });
  };

  const handleTwoFactorDisable = () => {
    setIsLoading(true);
    disableTwoFactor({
      successCallback: () => {
        setQRCode('');
        setIsLoading(false);
        toast.success('Two Factor Authentication disabled');
        getUserProfile();
      },
      errorCallback: () => {
        toast.error(i18next.t('toasts.error'));
        setIsLoading(false);
      }
    });
  };

  const handleVerify = value => {
    // TODO: Send verification request
  };

  const renderQR = () => {
    return (
      <>
        <QRCodeSVG value={QRCode} />
        <ol className="two-factor-qr-code-description">
          <li>In the Google Authenticator app tap the + button</li>
          <li>Choose Scan a QR code</li>
          {/* <li>Click Verify TFA and enter generated code</li> */}
          <li>Use the app to generate code on the next login</li>
        </ol>
        <div className="two-factor-button-verify-wrapper">
          {/* <RegularButton
            styleType="profile"
            text={'Verify TFA'}
            onClick={() => {
              setIsModalOpen(true);
            }}
          /> */}
        </div>
        <VerifyTwoFactorModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onVerify={handleVerify}
        />
      </>
    );
  };

  if (!userProfile?.data) return null;

  return (
    <div className="two-factor">
      {isLoading ? <LoadingIndicator /> : null}
      <Row>
        <Col className="profile-title-bar">
          <MdKeyboardBackspace onClick={handleBackButton} className="profile-icon-arrow deposit-header-item" />
          <span className="profile-title dashboard-transactions-header">Two Factor Authentication</span>
        </Col>
      </Row>
      <Container className="two-factor-container">
        <Row>
          <Col className="two-factor-col" xl={6} xs={12}>
            <p>
              Setup TFA with Google Authenticator app to get free verification codes. It works even if your phone is
              offline. First, download Google Authenticator from the Google Play Store or the iOS App Store.
            </p>
            {isTwoFactorEnabled ? (
              <VLButton
                text={'Disable Google Authenticator'}
                onClick={handleTwoFactorDisable}
                leftIcon={<MdDelete />}
                disabled={isLoading}
              />
            ) : (
              <VLButton
                text={'Enable Google Authenticator'}
                onClick={handleTwoFactorEnable}
                leftIcon={<MdAdd />}
                disabled={isLoading}
              />
            )}
          </Col>
          <Col className="two-factor-col two-factor-col-right" xl={6} xs={12}>
            {QRCode ? renderQR() : null}
            <Prompt message="If you have successfully scanned the QR code click OK. Otherwise please click Cancel and disable Two Factor Authentication." />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TwoFactorAuth;
