import { connect } from 'react-redux';
import { validateDeviceLocationRequest } from '../../redux/actions/login';
import DeviceLocationGuard from './DeviceLocationGuard';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  validateDeviceLocationRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceLocationGuard);
