import * as CONSTANTS from '../constants/login';

export const getAccountRequest = data => ({
  type: CONSTANTS.GET_ACCOUNT_REQUEST,
  data
});

export const getAccountSuccess = data => ({
  type: CONSTANTS.GET_ACCOUNT_SUCCESS,
  data
});

export const getAccountError = error => ({
  type: CONSTANTS.GET_ACCOUNT_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const loginRequest = data => ({
  type: CONSTANTS.LOGIN_REQUEST,
  data
});

export const loginSuccess = data => ({
  type: CONSTANTS.LOGIN_SUCCESS,
  data
});

export const loginError = error => ({
  type: CONSTANTS.LOGIN_ERROR,
  error
});

export const loginReset = data => ({
  type: CONSTANTS.LOGIN_RESET,
  data
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const logoutRequest = data => ({
  type: CONSTANTS.LOGOUT_REQUEST,
  data
});

export const logoutSuccess = data => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
  data
});

export const logoutError = error => ({
  type: CONSTANTS.LOGOUT_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const forgotPasswordRequest = data => ({
  type: CONSTANTS.FORGOT_PASSWORD_REQUEST,
  data
});

export const forgotPasswordSuccess = data => ({
  type: CONSTANTS.FORGOT_PASSWORD_SUCCESS,
  data
});

export const forgotPasswordError = error => ({
  type: CONSTANTS.FORGOT_PASSWORD_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const resetPasswordRequest = data => ({
  type: CONSTANTS.RESET_PASSWORD_REQUEST,
  data
});

export const resetPasswordSuccess = data => ({
  type: CONSTANTS.RESET_PASSWORD_SUCCESS,
  data
});

export const resetPasswordError = error => ({
  type: CONSTANTS.RESET_PASSWORD_ERROR,
  error
});

export const updateUser = data => ({
  type: CONSTANTS.EDIT_PROFILE,
  data
});

export const updateUserSuccess = data => ({
  type: CONSTANTS.EDIT_PROFILE_SUCCESS,
  data
});

export const updateUserError = error => ({
  type: CONSTANTS.EDIT_PROFILE_ERROR,
  error
});

export const getUserProfile = data => ({
  type: CONSTANTS.GET_USER_PROFILE,
  data
});

export const getUserProfileSuccess = data => ({
  type: CONSTANTS.GET_USER_PROFILE_SUCCESS,
  data
});

export const getUserProfileError = error => ({
  type: CONSTANTS.GET_USER_PROFILE_ERROR,
  error
});

export const enableTwoFactor = data => ({
  type: CONSTANTS.ENABLE_TWO_FACTOR_REQUEST,
  data
});

export const enableTwoFactorSuccess = data => ({
  type: CONSTANTS.ENABLE_TWO_FACTOR_SUCCESS,
  data
});

export const enableTwoFactorError = error => ({
  type: CONSTANTS.ENABLE_TWO_FACTOR_ERROR,
  error
});

export const disableTwoFactor = data => ({
  type: CONSTANTS.DISABLE_TWO_FACTOR_REQUEST,
  data
});

export const disableTwoFactorSuccess = data => ({
  type: CONSTANTS.DISABLE_TWO_FACTOR_SUCCESS,
  data
});

export const disableTwoFactorError = error => ({
  type: CONSTANTS.DISABLE_TWO_FACTOR_ERROR,
  error
});

export const contactSupportRequest = data => ({
  type: CONSTANTS.CONTACT_SUPPORT_REQUEST,
  data
});

export const contactSupportSuccess = data => ({
  type: CONSTANTS.CONTACT_SUPPORT_SUCCESS,
  data
});

export const contactSupportError = error => ({
  type: CONSTANTS.CONTACT_SUPPORT_ERROR,
  error
});

export const getUserPermissions = data => ({
  type: CONSTANTS.GET_USER_PERMISSIONS,
  data
});
export const getUserPermissionsSuccess = data => ({
  type: CONSTANTS.GET_USER_PERMISSIONS_SUCCESS,
  data
});

export const getUserPermissionsError = error => ({
  type: CONSTANTS.GET_USER_PERMISSIONS_ERROR,
  error
});

export const validateDeviceLocationRequest = data => ({
  type: CONSTANTS.VALIDATE_DEVICE_LOCATION_REQUEST,
  data
});

export const validateDeviceLocationSuccess = data => ({
  type: CONSTANTS.VALIDATE_DEVICE_LOCATION_SUCCESS,
  data
});

export const validateDeviceLocationError = error => ({
  type: CONSTANTS.VALIDATE_DEVICE_LOCATION_ERROR,
  error
});
