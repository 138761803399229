import { Modal, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import i18next from 'i18next';
import { FIRM_CODE_REGEX, FIRM_NAME_REGEX } from '../../../enums/validation';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput/VLInput';
class CreateFirmModal extends PureComponent {
  state = {
    data: {
      firm: '',
      description: ''
    }
  };

  handleOnChange = ({ target }, type) => {
    if (
      (type === 'firmCode' && FIRM_CODE_REGEX.test(target.value)) ||
      (type === 'firmName' && FIRM_NAME_REGEX.test(target.value))
    ) {
      this.setState({
        data: {
          ...this.state.data,
          [target.name]: target.value
        }
      });
    }
  };

  handleCreateFirm = () => {
    this.props.onSuccess(this.state.data);
  };

  render() {
    const { firm, description } = this.state.data;
    const disabled = firm && description ? false : true;
    return (
      <Modal className="common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('createFirm.createF')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row noGutters className="row-create-firm">
            <div className="system-form-control-placeholder">
              {i18next.t('createFirm.firmCode')} <span className="system-form-control-error-text">*</span>
            </div>
            <div className="system-form-control-box sfcb-transparent-create-firm">
              <VLInput
                onChange={e => this.handleOnChange(e, 'firmCode')}
                value={firm}
                size="s"
                name="firm"
                type="text"
              />
            </div>
            {this.props.createFirmError.error?.reason && (
              <div className="system-form-control-box-error-text">
                <div className="system-form-control-error-text">{this.props.createFirmError.error?.reason}</div>
              </div>
            )}
          </Row>
          <div>
            <div className="system-form-control-placeholder">
              {i18next.t('createFirm.firmName')} <span className="system-form-control-error-text">*</span>
            </div>
            <div>
              <VLInput
                onChange={e => this.handleOnChange(e, 'firmName')}
                value={description}
                size="s"
                name="description"
              />
            </div>
          </div>
          <Row noGutters className="exchange-space-top">
            <p className="mandatory-text">
              {i18next.t('createFirm.fields')} '<span className="mandatory-star">*</span>'{' '}
              {i18next.t('createFirm.mandatory')}
            </p>
          </Row>
          <VLButton
            disabled={disabled}
            onClick={this.handleCreateFirm}
            width="100%"
            text={i18next.t('createFirm.create')}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateFirmModal;
