import { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { LIGHT_MODE, SECURITY_CODES } from '../../../enums/validation';
import LoadingIndicator from '../../LoadingIndicator';
import AdvancedChartRange from '../AdvancedChart/AdvancedChartRange';
import CryptoAssetChartDetails from './CryptoAssetChartDetails/CryptoAssetChartDetails';
import { chartColors, formatData } from './CryptoAssetChartConfiguration';
import { moneyFormat } from '../../../utils/generalUtils';
import './CryptoAssetChart.scss';

const CryptoAssetChart = ({ mode, loading, data, cryptoAsset, onTimeSelect, marketData24 }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [currentUsdPrice, setCurrentUsdPrice] = useState('');
  const [currentUsdPriceTimestamp, setCurrentUsdPriceTimestamp] = useState('');
  const [selectedRange, setSelectedRange] = useState(AdvancedChartRange.HOUR);
  const rangeChoices = [
    ...AdvancedChartRange.getRangeChoices().slice(1, 5),
    ...AdvancedChartRange.getRangeChoices().slice(7)
  ];

  const getBackColor = mode => (mode === LIGHT_MODE ? '#ffffff' : '#193b5b');
  const getTextColor = mode => (mode === LIGHT_MODE ? '#0d036b' : '#f9fbfd');

  const chartContainerRef = useRef();
  const chartTooltipRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const backgroundColor = getBackColor(mode);
    const textColor = getTextColor(mode);

    const chart = createChart(chartContainerRef.current, {
      layout: {
        backgroundColor,
        textColor
      },
      crosshair: {
        vertLine: {
          labelVisible: false
        },
        horzLine: {
          labelVisible: false
        }
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      },
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        timeVisible: true,
        borderVisible: false
      },
      rightPriceScale: {
        visible: false
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight
    });

    const formattedData = formatData(data);

    const chartSeries = chart.addAreaSeries({
      lineColor: chartColors[cryptoAsset].lineColor,
      topColor: chartColors[cryptoAsset].areaTopColor,
      bottomColor: chartColors[cryptoAsset].areaBottomColor
    });

    if (loading) {
      chartSeries.setData([]);
    } else {
      chartSeries.setData(formattedData);

      if (formattedData.length > 0) {
        const range = selectedRange.getRange();
        chart.timeScale().setVisibleRange(range);
        chart.timeScale().fitContent();
      }
    }

    window.addEventListener('resize', handleResize);

    const toolTipWidth = 30;
    const toolTipHeight = 100;
    const toolTipMargin = 50;

    chart.subscribeCrosshairMove(param => {
      if (param.point === undefined || !param.time || param.point.x < 0 || param.point.y < 0) {
        setIsTooltipVisible(false);
        return;
      }

      setIsTooltipVisible(true);

      const price = param.seriesPrices.get(chartSeries);
      setCurrentUsdPrice(moneyFormat(price));

      setCurrentUsdPriceTimestamp(getFormattedDateAndTime(param.time * 1000));

      const coordinate = chartSeries.priceToCoordinate(price);
      let shiftedCoordinate;

      if (param.point.x < 100) {
        shiftedCoordinate = param.point.x + 160;
      } else if (param.point.x > 930) {
        shiftedCoordinate = param.point.x - 30;
      } else {
        shiftedCoordinate = param.point.x + 50;
      }

      if (coordinate === null) {
        return;
      }

      shiftedCoordinate = Math.max(
        0,
        Math.min(chartContainerRef.current.clientWidth - toolTipWidth, shiftedCoordinate)
      );

      const coordinateY =
        coordinate - toolTipHeight - toolTipMargin > 0
          ? coordinate - toolTipHeight - toolTipMargin
          : Math.max(
              0,
              Math.min(
                chartContainerRef.current.clientHeight - toolTipHeight - toolTipMargin,
                coordinate + toolTipMargin
              )
            );

      chartTooltipRef.current.style.left = shiftedCoordinate + 'px';
      chartTooltipRef.current.style.top = coordinateY + 200 + 'px';
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [cryptoAsset, data, loading, mode, selectedRange]);

  const getFormattedDateAndTime = date => {
    const isoDate = new Date(date).toISOString();
    const formattedDate = isoDate.substring(0, 19).replace('T', ' ');

    return formattedDate;
  };

  const handleTimeSelect = selectedRange => {
    setSelectedRange(selectedRange);
    onTimeSelect(selectedRange);
  };

  return (
    <div className="chart-container">
      {!marketData24?.loading ? (
        <CryptoAssetChartDetails
          rangeChoices={rangeChoices}
          selectedRange={selectedRange}
          onTimeSelect={handleTimeSelect}
          cryptoData={marketData24?.data}
        />
      ) : (
        <div className="loading-crypto-information">
          <LoadingIndicator />
        </div>
      )}
      {loading && (
        <div className="loading-chart">
          <LoadingIndicator />
        </div>
      )}
      <div ref={chartContainerRef} className="chart" id="chart">
        <div
          ref={chartTooltipRef}
          className={`chart-tooltip ${isTooltipVisible ? 'enable-visibility' : 'disable-visibility'}`}
        >
          <div className="chart-point-data">
            <span className={`chart-asset-pair-label ${cryptoAsset}`}>
              &#11044; {cryptoAsset.toUpperCase() + SECURITY_CODES.USD}
            </span>
            <span className="price">{'$ ' + currentUsdPrice}</span>
            <span>{currentUsdPriceTimestamp}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoAssetChart;
