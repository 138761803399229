import PropTypes from 'prop-types';
import { toggleShowPassword } from '../../utils/DOMHelpers';
import { AiFillEye } from 'react-icons/ai';
import { MdSearch } from 'react-icons/md';

const VLInput = ({
  isSearch,
  hasBackground,
  noBorder,
  width,
  size,
  dataAsset,
  assetLabel,
  innerRef,
  error,
  ...inputProps
}) => {
  return (
    <div
      className={`vl-input-mask ${size ? 'vl-input-mask__size-' + size : ''}`}
      style={{
        width: width
      }}
    >
      <input
        {...inputProps}
        className={`vl-input ${isSearch ? 'vl-input-search' : ''} ${hasBackground ? 'vl-input-background' : ''} ${
          noBorder ? 'vl-input-no-border' : ''
        } ${inputProps.className ?? ''} ${error ? 'error' : ''}`}
        data-asset={dataAsset}
        ref={innerRef}
      />
      {inputProps?.type === 'password' ? (
        <AiFillEye className="system-password-eye" onClick={() => toggleShowPassword('password')} />
      ) : (
        ''
      )}
      {isSearch ? <MdSearch className="search-icon" /> : ''}
      {assetLabel ? <span className="assetLabel">{assetLabel}</span> : ''}
    </div>
  );
};

VLInput.propTypes = {
  inputProps: PropTypes.object,
  isSearch: PropTypes.bool,
  hasBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
  height: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  customClasses: PropTypes.string,
  dataAsset: PropTypes.string,
  assetLabel: PropTypes.string,
  innerRef: PropTypes.any,
  error: PropTypes.any
};
VLInput.defaultProps = {
  type: 'text'
};

export default VLInput;
