import React, { useEffect, useState } from 'react';
import { EXCHANGE_SCREENS } from '../../enums/paths';
import BuySellForm from './BuySellForm/BuySellForm';
import PreviewDetails from './PreviewDetails/PreviewDetails';
import CompleteDetails from './CompleteDetails/CompleteDetails';
import FailedTransaction from './FailedTransaction/FailedTransaction';
import { BUY_SELL_FEE, SECURITY_CODES } from '../../enums/validation';
import { getSideByAssetPair } from '../../utils/transactionsHelpers';
import { getLocalisedDecimalString, replaceCommas } from '../../utils/generalUtils';
import LoadingIndicator from '../LoadingIndicator';
import './BuySell.scss';

/**
 * For Crypto Asset Details Page fixedSendAsset and fixedReceiveAsset parameters
 * are required.
 * When not provided, this component will have default behaviour (no selected assets).
 *
 */
const BuySell = ({
  isDisabled,
  fixedSendAsset,
  fixedReceiveAsset,
  socket,
  positions,
  assets,
  assetsPairs,
  cryptoRates,
  allBooks,
  exchangeAssets,
  getTradingAssets,
  getAssetPairs,
  updateTransactionsDB,
  profileDetails,
  getAccountTransactions,
  currentAccountNumber
}) => {
  const [currentScreen, setCurrentScreen] = useState(EXCHANGE_SCREENS.INPUT_FORM);

  const [sendAssetData, setSendAssetData] = useState({
    sendAsset: '',
    sendAmount: ''
  });

  const [receiveAssetData, setReceiveAssetData] = useState({
    receiveAsset: '',
    receiveAmount: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [transactionId, setTransactionId] = useState();

  useEffect(() => {
    if (!assets?.loaded) getTradingAssets();
    if (!assetsPairs?.loaded) getAssetPairs();
  }, [getTradingAssets, getAssetPairs]);

  useEffect(() => {
    if (
      SECURITY_CODES[fixedSendAsset] &&
      SECURITY_CODES[fixedReceiveAsset] &&
      SECURITY_CODES[fixedSendAsset] !== SECURITY_CODES[fixedReceiveAsset]
    ) {
      setSendAssetData(prev => ({
        ...prev,
        sendAsset: SECURITY_CODES[fixedSendAsset]
      }));
      setReceiveAssetData(prev => ({
        ...prev,
        receiveAsset: SECURITY_CODES[fixedReceiveAsset]
      }));
    }
  }, [fixedSendAsset, fixedReceiveAsset]);

  const calculateBuySellFee = sendAmount => {
    return BUY_SELL_FEE * Number(replaceCommas(sendAmount));
  };

  const calculateExchangePrice = (receiveAmount, receiveAsset, sendAmount) => {
    const amountWithoutFormat = replaceCommas(receiveAmount.toString());
    const amountWithFee = amountWithoutFormat - amountWithoutFormat * BUY_SELL_FEE;

    return amountWithFee / Number(replaceCommas(sendAmount));
  };

  const calculateAmountWithFee = (amount, asset) => {
    const amountWithoutFormat = replaceCommas(amount.toString());
    const amountWithFee = amountWithoutFormat - amountWithoutFormat * BUY_SELL_FEE;

    return getLocalisedDecimalString(amountWithFee, asset);
  };

  const submitTransactionRequest = () => {
    const { pair, side } = getSideByAssetPair(assetsPairs.data, sendAssetData.sendAsset, receiveAssetData.receiveAsset);

    setIsLoading(true);

    exchangeAssets({
      socket: socket,
      side,
      security: pair.security,
      amount: replaceCommas(sendAssetData.sendAmount.toString()),
      account: currentAccountNumber,
      successCallback: data => {
        setTransactionId(data.refno);
        updateTransactionsDB({
          ref: data.refno,
          type: 'exchange',
          cryptoRates
        });

        if (data.refno) {
          setIsLoading(false);
          setCurrentScreen(EXCHANGE_SCREENS.SUCCESS);
          setTimeout(() => {
            getAccountTransactions({
              account: currentAccountNumber,
              firm: profileDetails.data.firm
            });
          }, 5000);
        }
      },
      errorCallback: data => {
        setIsLoading(false);
        setRequestErrorMessage(data.result);
      }
    });
  };

  const returnToBuySell = () => {
    setRequestErrorMessage('');
    setCurrentScreen(EXCHANGE_SCREENS.INPUT_FORM);
  };

  const previewBuySell = () => {
    setCurrentScreen(EXCHANGE_SCREENS.PREVIEW);
  };

  const completeBuySell = () => {
    submitTransactionRequest();
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case EXCHANGE_SCREENS.INPUT_FORM:
        return (
          <BuySellForm
            isDisabled={isDisabled}
            accountNumber={currentAccountNumber}
            assets={assets}
            positions={positions}
            assetsPairs={assetsPairs}
            cryptoRates={cryptoRates}
            allBooks={allBooks}
            sendAssetData={sendAssetData}
            receiveAssetData={receiveAssetData}
            onSendAssetDataChange={setSendAssetData}
            onReceiveAssetDataChange={setReceiveAssetData}
            onPreview={previewBuySell}
            hasFixedAssets={fixedSendAsset && fixedReceiveAsset}
          />
        );
      case EXCHANGE_SCREENS.PREVIEW:
        return (
          <PreviewDetails
            accountNumber={currentAccountNumber}
            sendAssetData={sendAssetData}
            receiveAssetData={receiveAssetData}
            receiveAmountWithFee={calculateAmountWithFee(receiveAssetData.receiveAmount, receiveAssetData.receiveAsset)}
            exchangePrice={calculateExchangePrice(
              receiveAssetData.receiveAmount,
              receiveAssetData.receiveAsset,
              sendAssetData.sendAmount
            )}
            buySellFee={calculateBuySellFee(sendAssetData.sendAmount)}
            onBack={returnToBuySell}
            onComplete={completeBuySell}
            submitTransactionRequest={submitTransactionRequest}
          />
        );
      case EXCHANGE_SCREENS.SUCCESS:
        return (
          <CompleteDetails
            sendAssetData={sendAssetData}
            receiveAssetData={receiveAssetData}
            exchangePrice={calculateAmountWithFee(sendAssetData.sendAmount, sendAssetData.sendAsset)}
            receiveAmountWithFee={calculateAmountWithFee(receiveAssetData.receiveAmount, receiveAssetData.receiveAsset)}
            transactionId={transactionId}
            onBack={returnToBuySell}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="buy-sell-container">
      {isLoading ? (
        <div className="loading">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {requestErrorMessage ? (
            <FailedTransaction onBack={returnToBuySell} />
          ) : (
            <div>{renderScreen()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default BuySell;
