import Markets from './Markets';

import { getUserProfile } from '../../redux/actions/login';
import { getContactsRequest } from '../../redux/actions/administration';
import { getTradingAssets, getAssetPairs, getAssets } from '../../redux/actions/transactions';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { fetchAssetPairs, fetch24hDataForAllAssets } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  assetPairs: state.trade.assetPairs.data,
  marketData24All: state.trade.marketData24All,
  assets: state.transactions.assets,
  assetsPairs: state.transactions.assetPairs,
  displayModal: state.transactions.displayModal
});

const mapDispatchToProps = {
  getUserProfile,
  getContactsRequest,
  getTradingAssets,
  fetchAssetPairs,
  fetch24hDataForAllAssets,
  getAssetPairs,
  getAssets
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Markets);
