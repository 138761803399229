import { connect } from 'react-redux';
import Routes from './Routes';
import { getAccountRequest } from '../redux/actions/login';

const mapStateToProps = state => ({
  userData: state.login.userData
});

const mapDispatchToProps = {
  getAccountRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
