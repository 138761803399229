import * as CONSTANTS from '../constants/analytics';

export const fetchAllAnalyticsData = data => ({
  type: CONSTANTS.FETCH_ANALYTICS_DATA_ALL,
  data
});

export const fetchAllAnalyticsDataSuccess = data => ({
  type: CONSTANTS.FETCH_ANALYTICS_DATA_ALL_SUCCESS,
  data
});

export const fetchAllAnalyticsDataError = error => ({
  type: CONSTANTS.FETCH_ANALYTICS_DATA_ALL_ERROR,
  error
});

export const fetchSpecificAnalyticsData = data => ({
  type: CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA,
  data
});

export const fetchSpecificAnalyticsDataSuccess = data => ({
  type: CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA_SUCCESS,
  data
});

export const fetchSpecificAnalyticsDataError = data => ({
  type: CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA_ERROR,
  data
});

export const getTransactionById = data => ({
  type: CONSTANTS.GET_TRANSACTION_BY_ID,
  data
});

export const getTransactionByIdSuccess = data => ({
  type: CONSTANTS.GET_TRANSACTION_BY_ID_SUCCESS,
  data
});

export const getTransactionByIdError = error => ({
  type: CONSTANTS.GET_TRANSACTION_BY_ID_ERROR,
  error
});
