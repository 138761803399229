import * as CONSTANTS from '../constants/websocket';

export const connectSocket = data => ({
  type: CONSTANTS.INIT_WEBSOCKET,
  data: {
    url: data.endpoint,
    token: data.token
  }
});

export const setSocket = data => {
  let payload = data;
  return {
    type: CONSTANTS.SET_WEBSOCKET,
    data: payload
  };
};

export const passNewMessage = (type, data) => ({
  type,
  data
});

export const makeTransferRequest = data => ({
  type: CONSTANTS.SEND_TRANSFER,
  data
});

export const makeOrderRequest = data => ({
  type: CONSTANTS.REQUEST_TRANSFER,
  data
});

export const makeDepositRequest = data => ({
  type: CONSTANTS.MAKE_DEPOSIT,
  data
});

export const makeRedeemRequest = data => ({
  type: CONSTANTS.MAKE_REDEEM,
  data
});

export const makeEscrowRequest = data => ({
  type: CONSTANTS.MAKE_ESCROW,
  data
});

export const makeIOIRequest = data => ({
  type: CONSTANTS.MAKE_IOI,
  data
});

export const makeIOIV2Request = data => ({
  type: CONSTANTS.MAKE_IOI_V2,
  data
});

export const makeCounterOrderRequest = data => ({
  type: CONSTANTS.MAKE_COUNTER_ORDER,
  data
});

export const makeRequestOrder = data => ({
  type: CONSTANTS.MAKE_REQUEST_ORDER,
  data
});

export const makeToBankRequest = data => ({
  type: CONSTANTS.TO_BANK_REQUEST,
  data
});

export const makeToNetworkRequest = data => ({
  type: CONSTANTS.TO_NETWORK_REQUEST,
  data
});

export const makeLimitOrderRequest = data => ({
  type: CONSTANTS.MAKE_LIMIT_ORDER,
  data
});

export const makeMarketOrderRequest = data => ({
  type: CONSTANTS.MAKE_MARKET_ORDER,
  data
});

////////////////////////////////////////
export const acceptPaymentRequest = data => ({
  type: CONSTANTS.ACCEPT_REQUEST,
  data
});

export const acceptPaymentRequestSuccess = data => ({
  type: CONSTANTS.ACCEPT_REQUEST_SUCCESS,
  data
});

export const rejectPaymentRequest = data => ({
  type: CONSTANTS.REJECT_REQUEST,
  data
});

export const rejectPaymentRequestSuccess = data => ({
  type: CONSTANTS.REJECT_REQUEST_SUCCESS,
  data
});

///////////////////////////////////////////////////

export const queryPositions = data => ({
  type: CONSTANTS.QUERY_POSITION,
  data
});

export const queryPositionsSuccess = data => ({
  type: CONSTANTS.QUERY_POSITION_SUCCESS,
  data
});

export const queryPositionsFirmDetails = data => ({
  type: CONSTANTS.QUERY_POSITION_FIRM_DETAILS,
  data
});

////////////////////////////////////////////////
export const updateRiskSettings = data => ({
  type: CONSTANTS.UPDATE_RISK_SETTINGS,
  data
});

export const queryRiskSettings = data => ({
  type: CONSTANTS.QUERY_RISK_SETTINGS,
  data
});
export const queryRiskSettingsSuccess = data => ({
  type: CONSTANTS.QUERY_RISK_SETTINGS_SUCCESS,
  data
});
export const checkIfUserExists = data => ({
  type: CONSTANTS.CHECK_IF_USER_EXISTS,
  data
});

export const addAlert = data => ({
  type: CONSTANTS.ADD_ALERT,
  data
});

export const queryAlerts = data => ({
  type: CONSTANTS.QUERY_ALERTS,
  data
});
//////////////////////////////////////////////////
//////BALANCE
export const queryBalanceWalletPos = data => ({
  type: CONSTANTS.QUERY_BALANCE_WALLET_POS,
  data
});

export const getAssetWalletAddress = data => ({
  type: CONSTANTS.GET_ASSET_WALLET_ADDRESS,
  data
});

export const getFeesPerAsset = data => ({
  type: CONSTANTS.GET_FEES_PER_ASSET,
  data
});

export const sendBalanceWithdrawal = data => ({
  type: CONSTANTS.SEND_BALANCE_WITHDRAWAL,
  data
});

export const sendTransactionHash = data => ({
  type: CONSTANTS.SEND_TRANSACTION_HASH,
  data
});

export const exchangeAssets = data => ({
  type: CONSTANTS.EXCHANGE_CURRENCY,
  data
});
