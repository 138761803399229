import { Badge, Form, Table } from 'react-bootstrap';
import {
  FILTER_ASSET,
  SHOW_ALL_ACCOUNTS_FLAG,
  TRANSACTIONTABLE_FILTER_LIST_OTHER,
  TRANSACTIONTABLE_FILTER_LIST_REVIEW,
  TRANSACTIONTABLE_FILTER_LIST_PENDING,
  TRANSACTIONTABLE_FILTER_LIST_PENDING_USD,
  TRANSACTIONTABLE_FILTER_LIST_REVIEWED_USD,
  TRANSACTION_SEARCH_LIST,
  TRANSACTION_SEARCH_LIST_OTHER,
  CRYPTO_MODAL_CONFIRMATION,
  USD_MODAL_CONFIRMATION
} from '../../enums/validation';

import { capitalizeFirstLetter, formatDate, getLocalisedNumericString } from '../../utils/generalUtils';
import DatePicker, { dateTransactionsOptions } from '../DatePicker';
import CssFilterTransition from '../CssFilterTransition';
import { BsPlus } from 'react-icons/bs';

import { parseObjectWithSamePropertyForDropdown } from '../../utils/generalUtils';
import MediaQuery from 'react-responsive';
import ExportButton from '../ExportButton';
import { MdRefresh, MdRemoveRedEye } from 'react-icons/md';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import Select from 'react-select';
import Pagination from '../Pagination/Pagination';
import { decodeRole } from '../../utils/roleCheckers';
import { SUPER_USER_TYPES } from '../../enums/roles';
import { getExecutedBy, getTransactionID } from '../../utils/transactionsHelpers';
import { withRouter } from 'react-router';
import GeneralConfirmModal from '../modals/GeneralConfirmModal/GeneralConfirmModal';
import LoadingIndicator from '../LoadingIndicator';
import { calculatePageSlice, calculateTotalPages } from '../../utils/paging';
import {
  FILTER_DROPDOWN_OPTIONS_DEPOSIT_WITHDRAW_TRANSACTIONS,
  FILTER_DROPDOWN_OPTIONS_OTHER_TRANSACTIONS,
  getStatusDataReviewedOptions,
  getTableStatusData
} from '../../utils/transactionsFilterOptions.js';
import {
  mapFilterDepositWithdrawalUSD,
  mapFilterExternalTransactionsData,
  mapFilterInternalTransactionsData
} from './QueryMapper';
import VLButton from '../Buttons/VLButton';
import VLSelect from '../VLSelect';
import VLIconButton from '../Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import VLInput from '../VLInput';

const itemPerTable = 12;

const initialState = {
  name: '',
  manager: '',
  score: '',
  range: [],
  txnid: '',
  userid: '',
  tier: '',
  Type: '',
  amount: '',
  Asset: '',
  value: '',
  fbo: '',
  dates: '',
  accountnumber: '',
  BTA_Status: '',
  BM_Status: '',
  Order_Status: ''
};

class TransactionsTableAdmin extends PureComponent {
  state = {
    ...initialState,
    pendingAccounts: this.props.accounts,
    typingTimeout: 0,
    displayFilters: false,
    displayFiltersSec: false,
    dataPageNumber: 0,
    totalPages: 0,

    showConfirmationModal: false,
    status: {},
    statusUpdateModalData: {
      id: '',
      type: '',
      status: '',
      transactionType: ''
    }
  };

  handleFilterChange = (target, data) => {
    this.setState(
      {
        [data.name]: {
          value: target.value,
          label: target.label,
          name: data.name
        }
      },
      () => {
        this.triggerFilter();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.accounts !== this.props.accounts) {
      this.setState({ transactions: this.props.accounts });
    }
  }

  handleStatusChange = () => {
    this.state.statusUpdateModalData.transactionType === CRYPTO_MODAL_CONFIRMATION
      ? this.props.updateStatus({
          id: this.state.statusUpdateModalData.id,
          status: this.state.statusUpdateModalData.status
        })
      : this.props.updateStatus({
          id: this.state.statusUpdateModalData.id,
          type: this.state.statusUpdateModalData.type,
          status: this.state.statusUpdateModalData.status
        });
    this.toggleModalConfirmation();
  };

  changeStatus = (id, target) => {
    this.setState({
      statusUpdateModalData: {
        id: id,
        status: target.value,
        transactionType: CRYPTO_MODAL_CONFIRMATION
      },
      showConfirmationModal: true
    });
  };

  changeStatusUSD = (id, type, target) => {
    this.setState({
      statusUpdateModalData: {
        id: id,
        type: type,
        status: target.value.toUpperCase(),
        transactionType: USD_MODAL_CONFIRMATION
      },
      showConfirmationModal: true
    });
  };

  getAssetsDropdownData = short => {
    let assetOptions = parseObjectWithSamePropertyForDropdown(this.props.assets?.data, 'security');
    assetOptions?.unshift(
      {
        label: short ? i18next.t('transactionsFilters.s') : 'Select assets',
        value: SHOW_ALL_ACCOUNTS_FLAG
      },
      {
        label: i18next.t('transactionsFilters.allAssets'),
        value: SHOW_ALL_ACCOUNTS_FLAG
      }
    );
    return assetOptions;
  };

  filterEventDataByCategory = () => {
    return this.props.accounts;
  };
  clearFilters = () => {
    this.setState(
      {
        ...initialState
      },
      () => {
        this.triggerFilter();
      }
    );
  };

  handleSearchChanges = ({ target }) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      [target.name]: target.value,
      typingTimeout: setTimeout(() => {
        this.triggerFilter();
      }, 1000)
    });
  };

  triggerFilter = () => {
    if (this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') {
      this.props.filterData(mapFilterExternalTransactionsData(this.state));
    } else if (this.props.transactionType === 'pendingUSD' || this.props.transactionType === 'reviewedUSD') {
      this.props.filterData(mapFilterDepositWithdrawalUSD(this.state));
    } else if (this.isInternalTransaction(this.props.transactionType)) {
      this.props.filterData(mapFilterInternalTransactionsData(this.state));
    }
  };
  formatExportParams = () => {
    if (this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') {
      return mapFilterExternalTransactionsData(this.state);
    } else if (this.props.transactionType === 'pendingUSD' || this.props.transactionType === 'reviewedUSD') {
      return mapFilterDepositWithdrawalUSD(this.state);
    } else if (this.isInternalTransaction(this.props.transactionType)) {
      return mapFilterInternalTransactionsData(this.state);
    }
  };

  handleDateChange = date => {
    var dateFrom = new Date(date[0]);
    var dateTo = new Date(date[1]);
    var isoDateFrom = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString();
    var isoDateTo = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString();
    this.setState(
      {
        range: date,
        dates: isoDateFrom + '&' + isoDateTo
      },
      () => {
        this.triggerFilter();
      }
    );
  };
  redirectToDetails = (id, type) => {
    if (type === 'DEPOSIT' || type === 'WITHDRAW') {
      this.props.history.push(`/transaction/${id}`);
    }
  };

  handlePageClick = data => {
    this.setState({
      dataPageNumber: data.selected
    });
  };

  getPagedData = () => {
    if (this.props.accounts.length > 0) {
      const newTotalPages = calculateTotalPages(this.props.accounts, itemPerTable);

      if (this.state.dataPageNumber >= newTotalPages) {
        this.setState({ dataPageNumber: this.state.dataPageNumber - 1 });
      } else if (this.state.dataPageNumber < 0 && newTotalPages > 0) {
        this.setState({ dataPageNumber: 0 });
      }

      return calculatePageSlice(this.props.accounts, itemPerTable, this.state.dataPageNumber);
    } else {
      return [];
    }
  };
  getFilterOptionsByType = transactionType => {
    if (!this.isInternalTransaction(transactionType)) {
      return FILTER_DROPDOWN_OPTIONS_DEPOSIT_WITHDRAW_TRANSACTIONS(transactionType);
      //TEMPORARY SOLUTION
    } else {
      return FILTER_DROPDOWN_OPTIONS_OTHER_TRANSACTIONS(transactionType);
    }
  };

  buildFilters = () => {
    if (FILTER_ASSET === 'asset') {
      i18next.t('transactionsFilters.counterparty3');
    }

    var filters;
    //TEMPORARY SOLUTION

    if (this.isInternalTransaction(this.props.transactionType)) {
      filters = TRANSACTIONTABLE_FILTER_LIST_OTHER;
    } else if (this.props.transactionType === 'pending') {
      filters = TRANSACTIONTABLE_FILTER_LIST_PENDING;
    } else if (this.props.transactionType === 'reviewed') {
      filters = TRANSACTIONTABLE_FILTER_LIST_REVIEW;
    } else if (this.props.transactionType === 'pendingUSD') {
      filters = TRANSACTIONTABLE_FILTER_LIST_PENDING_USD;
    } else {
      filters = TRANSACTIONTABLE_FILTER_LIST_REVIEWED_USD;
    }

    const filterst = filters.map(filter => {
      const filterOptionsShort = this.getFilterOptionsByType(this.props.transactionType)[filter]();
      const filterOptionsLong = this.getFilterOptionsByType(this.props.transactionType)[filter]();

      return (
        <div key={filter}>
          <MediaQuery maxWidth={1960}>
            <div className="row-width filter-field-expand">
              <Form.Label className="search-user-form-label search">
                {filter === 'BTA_Status' ? 'BTA status' : filter.replace('_', ' ')}
              </Form.Label>
              <VLSelect
                name={filter}
                options={filterOptionsShort && filterOptionsShort?.slice(1)}
                onChange={this.handleFilterChange}
                menuPortalTarget={document.body}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1961}>
            <div className="row-width">
              <Form.Label className="search-user-form-label search">
                {filter === 'BTA_Status' ? 'BTA status' : filter}
              </Form.Label>
              <VLSelect
                name={filter}
                options={filterOptionsLong}
                onChange={this.handleFilterChange}
                menuPortalTarget={document.body}
              />
            </div>
          </MediaQuery>
        </div>
      );
    });

    let searchFilters = [];
    if (!this.isInternalTransaction(this.props.transactionType)) {
      searchFilters = TRANSACTION_SEARCH_LIST;
    } else {
      searchFilters = TRANSACTION_SEARCH_LIST_OTHER;
    }

    const searches = searchFilters.map((filter, index) => {
      console.log('filter', filter);
      return (
        <div className="user-form-control-container row-width" key={index}>
          <Form.Label className="search-user-form-label search">{filter}</Form.Label>
          <div className="user-form-control-wrap">
            <VLInput
              type="text"
              name={filter.toLowerCase().replace(/\s+/g, '')}
              placeholder="Search"
              value={this.state[filter.replace(/\s+/g, '').toLowerCase()]}
              onChange={this.handleSearchChanges}
              size="s"
              isSearch
            />
          </div>
        </div>
      );
    });

    filterst.unshift(searches);
    filterst.unshift(
      <div className="user-form-control-container row-width">
        <Form.Label className="transactions-date-range row-width" key={searches}>
          <div className="transactions-date-range-container parent date-parent">
            <Form.Label className="search-user-form-label">{i18next.t('transactionsFilters.dataRange')}</Form.Label>
            <div className="user-form-control-wrap">
              <DatePicker
                onClick={this.handleDateChange}
                options={{
                  ...dateTransactionsOptions,
                  opens: 'right',
                  drops: 'down',
                  parentEl: '.payments-date-parent'
                }}
                date={this.state.range}
              />
            </div>
          </div>
          <div>
            <VLButton
              text={i18next.t('transactionsFilters.clearFilter')}
              variant="clear"
              size={'m'}
              rightIcon={<BsPlus />}
              onClick={this.clearFilters}
            />
          </div>
        </Form.Label>
      </div>
    );
    return filterst;
  };
  toggleFilters = () => {
    this.setState({ displayFilters: !this.state.displayFilters });
  };
  togleFilterSec = () => this.setState({ displayFiltersSec: !this.state.displayFiltersSec });

  getTierExternalTransactions = transaction => {
    return transaction.userInfo?.attr?.bta_tier ? transaction.userInfo?.attr?.bta_tier : '-';
  };

  getTierInternalTransactions = transaction => {
    return transaction.tier ? transaction.tier : '-';
  };

  renderStatus = (transactionType, transaction) => {
    const userPermissions = this.props.superAdminType?.data ? decodeRole(this.props.superAdminType.data.roles) : '';

    if (transactionType === 'reviewed') {
      return (
        <Badge pill className={'status-badge-' + transaction.status}>
          {capitalizeFirstLetter(transaction.status.toLowerCase())}
        </Badge>
      );
    } else if (transactionType === 'pending') {
      return this.canUserUpdateTransactionStatus(transaction?.['bta-status'], userPermissions) ? (
        <Select
          key={this.state.showConfirmationModal}
          classNamePrefix="select-form"
          name="status"
          defaultValue={getStatusDataReviewedOptions[0]}
          options={getTableStatusData()}
          isSearchable={false}
          onChange={e => {
            this.changeStatus(transaction.id, e);
          }}
          value={this.state.statusUpdateModalData.status || getStatusDataReviewedOptions[0]}
          placeholder={'Select'}
          menuPortalTarget={document.body}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
        />
      ) : (
        <Badge pill className={'status-badge-' + transaction.status}>
          {capitalizeFirstLetter(transaction.status)}
        </Badge>
      );
    } else if (this.props.transactionType === 'pendingUSD') {
      return (
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Select
            key={this.state.showConfirmationModal}
            classNamePrefix="select-form"
            name="status"
            defaultValue={getStatusDataReviewedOptions[0]}
            options={getTableStatusData()}
            isSearchable={false}
            onChange={e => {
              this.changeStatusUSD(transaction.id, transaction.type, e);
            }}
            value={this.state.statusUpdateModalData.status || getStatusDataReviewedOptions[0]}
            placeholder={'Select'}
            menuPortalTarget={document.body}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
          />
        </div>
      );
    } else if (this.props.transactionType === 'reviewedUSD') {
      return (
        <Badge pill className={'status-badge-' + transaction.status.toLowerCase()}>
          {capitalizeFirstLetter(transaction.status.toLowerCase())}
        </Badge>
      );
    } else {
      return <></>;
    }
  };

  mapTransactionsTableAdminTypeToExportButtonType = type => {
    switch (type) {
      case 'pending':
        return 'crypto_pending';
      case 'reviewed':
        return 'crypto_reviewed';
      case 'pendingUSD':
        return 'usd_pending';
      case 'reviewedUSD':
        return 'usd_reviewed';
      case 'other':
        return 'other';
      case 'otherOpen':
        return 'otherOpen';
      case 'otherCanceled':
        return 'otherCanceled';
      default:
        return '';
    }
  };

  returnButtonBar = toogle => {
    return (
      <div className="button-bar-wrapper">
        {this.props.searchAccountInput ?? null}

        <div className="filter-alignment transactions-filters-container button-bar-wrapper row no-gutters">
          <div className="inline-justifier">
            <VLIconButton onClick={this.triggerFilter} outText="Reload" Icon={MdRefresh} />
            <div className="vl-button-out-text">Reload</div>
          </div>

          <div className="inline-justifier">
            <VLIconButton Icon={FaFilter} onClick={toogle} />
            <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
          </div>

          <div className="transactions-filter-button-container">
            <ExportButton
              isExportTitle
              title="transactions"
              type={this.mapTransactionsTableAdminTypeToExportButtonType(this.props.transactionType)}
              transactionListData={this.filterEventDataByCategory}
              params={this.formatExportParams()}
              userAccountNumber={this.props.userAccountNumber}
            />
          </div>
        </div>
      </div>
    );
  };
  returnFilter = display => {
    return <CssFilterTransition component={this.buildFilters} isOpen={display}></CssFilterTransition>;
  };
  canUserUpdateTransactionStatus = (bta_status, userPermissions) => {
    return (
      (userPermissions.superAdminType === SUPER_USER_TYPES.BANK_MANAGER ||
        userPermissions.superAdminType === SUPER_USER_TYPES.VL_ADMIN) &&
      bta_status === 'approved'
    );
  };

  mapTransactionType = transaction => {
    if (this.isInternalTransaction(this.props.transactionType)) {
      return {
        ...transaction,
        counterfirm: transaction.fbo,
        type: transaction.transactionType,
        time: new Date(transaction.time).getTime(),
        security: transaction.security
      };
    } else {
      return {
        ...transaction,
        counterfirm: transaction.fbo
      };
    }
  };

  toggleModal = transaction => {
    this.props.toggleTransactionDetails(this.mapTransactionType(transaction));
  };
  getTitleModalConfirmation = status => {
    switch (status) {
      case 'APPROVED':
        return 'APPROVE';
      case 'REJECTED':
        return 'REJECT';
      default:
        return 'PENDING';
    }
  };
  toggleModalConfirmation = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal
    });
  };
  isInternalTransaction = transactionType => {
    return transactionType.includes('other');
  };

  render() {
    const pagedData = this.getPagedData();
    return (
      <React.Fragment>
        {this.state.showConfirmationModal && (
          <GeneralConfirmModal
            isOpen={this.state.showConfirmationModal}
            title={`${this.getTitleModalConfirmation(this.state.statusUpdateModalData.status)}`}
            text={`Are you sure you want to change Status for this transaction to ${this.state.statusUpdateModalData.status.toUpperCase()}?`}
            onConfirm={() => this.handleStatusChange()}
            onCancel={() => this.toggleModalConfirmation('Confirmation')}
          />
        )}
        {this.returnButtonBar(this.toggleFilters)}

        <div className="overflowX-scroll-transactions">
          {this.returnFilter(this.state.displayFilters)}
          {this.props.accounts.length > 0 && (
            <>
              <Table borderless responsive size="sm" className="vl-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Account number</th>
                    <th>Executed by</th>
                    <th>TXN ID</th>
                    {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                      <th>Risk score</th>
                    )}
                    {!this.isInternalTransaction(this.props.transactionType) && <th>Tier</th>}
                    <th>Type</th>
                    <th>Asset</th>
                    <th>Amount</th>

                    {this.isInternalTransaction(this.props.transactionType) && <th>FBO</th>}
                    {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                      <th>BTA status</th>
                    )}
                    {!this.isInternalTransaction(this.props.transactionType) && <th>BM status</th>}
                    {(this.props.transactionType === 'reviewed' || this.props.transactionType === 'reviewedUSD') && (
                      <th className="vl-table-header-medium">Execution status</th>
                    )}
                    {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                      <th>Details</th>
                    )}
                    {this.isInternalTransaction(this.props.transactionType) && (
                      <>
                        <th>Counterparty asset</th>
                        <th>Counterparty amount</th>
                      </>
                    )}
                  </tr>
                </thead>
                {pagedData.map(m => {
                  const formattedDate = !this.isInternalTransaction(this.props.transactionType)
                    ? formatDate(m.created_date ?? m.time)
                    : formatDate(m.time);

                  const accountNumber = m.account || m.userInfo?.restricted_attr?.access_list[0].account;

                  const transactionId = !this.isInternalTransaction(this.props.transactionType)
                    ? m.id
                    : getTransactionID(m.traderefno, m.transactionType);

                  return (
                    <tbody key={m.id}>
                      <tr onClick={() => this.toggleModal(m)}>
                        <td>{formattedDate}</td>
                        <td>{accountNumber}</td>
                        <td>{getExecutedBy(m)}</td>
                        <td>
                          {transactionId.substring(0, 4) + '...' + transactionId.substring(transactionId.length - 4)}
                        </td>
                        {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                          <td>{m.riskScore ? m.riskScore : '-'}</td>
                        )}
                        {!this.isInternalTransaction(this.props.transactionType) && (
                          <td>{this.getTierExternalTransactions(m)}</td>
                        )}

                        <td>
                          {!this.isInternalTransaction(this.props.transactionType)
                            ? capitalizeFirstLetter(m.type)
                            : transactionTypeOther(m.transactionType)}
                        </td>
                        <td>
                          {!this.isInternalTransaction(this.props.transactionType)
                            ? m.crypto.toUpperCase()
                            : m.sendSecurity}
                        </td>
                        {this.props.transactionType === 'pendingUSD' || this.props.transactionType === 'reviewedUSD' ? (
                          <td>$ {getLocalisedNumericString(m.amount, true)}</td>
                        ) : (
                          <td>{m.amount}</td>
                        )}

                        {this.isInternalTransaction(this.props.transactionType) && <td>{m.fbo ? m.fbo : '-'}</td>}
                        {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                          <td>
                            <Badge pill className={'status-badge-' + m?.['bta-status']}>
                              {capitalizeFirstLetter(m?.['bta-status'].toLowerCase()).replace('-', ' ')}
                            </Badge>
                          </td>
                        )}
                        {!this.isInternalTransaction(this.props.transactionType) && (
                          <td>{this.renderStatus(this.props.transactionType, m)}</td>
                        )}
                        {(this.props.transactionType === 'reviewed' ||
                          this.props.transactionType === 'reviewedUSD') && (
                          <td className="td-crypto">
                            {capitalizeFirstLetter(m.processing_status.toLowerCase().replace('_', ' '))}
                          </td>
                        )}
                        {(this.props.transactionType === 'pending' || this.props.transactionType === 'reviewed') && (
                          <td
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <VLIconButton
                              onClick={() => this.redirectToDetails(m.id, m.type?.toUpperCase())}
                              Icon={MdRemoveRedEye}
                              variant="borderless"
                            />
                          </td>
                        )}
                        {this.isInternalTransaction(this.props.transactionType) && (
                          <>
                            <td>{m.assetCounterparty ? m.assetCounterparty : '-'}</td>
                            <td>{m.amountCounterparty ? m.amountCounterparty : '-'}</td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
              <div className="pagination pagination-pending"></div>
            </>
          )}

          {this.props.isLoading && <LoadingIndicator />}
          {this.props.loaded && this.props.accounts.length === 0 && (
            <div
              className="
            center-aligned"
            >
              No transaction data
            </div>
          )}
          {this.props.loaded && this.props.accounts.length > itemPerTable && (
            <Pagination
              totalPages={this.props.accounts.length / itemPerTable}
              handlePageClick={this.handlePageClick}
              dataPageNumber={this.state.dataPageNumber}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const transactionTypeOther = type => {
  return type ? capitalizeFirstLetter(type) : '-';
};

export default withRouter(TransactionsTableAdmin);
