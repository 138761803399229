export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const UPDATE_VALUE_ACTION = 'UPDATE_VALUE_ACTION';
export const SET_ERROR_ACTION = 'SET_ERROR_ACTION';
export const CLEAR_ERRORS_ACTION = 'CLEAR_ERRORS_ACTION';

export const SET_HAS_WALLETS = 'SET_HAS_WALLETS';
export const SET_HAS_EXCHANGES = 'SET_HAS_EXCHANGES';

/**
 * List related action are made generic in order to support wallets list and
 * exchanges list as reducer logic is exactly the same.
 * listName needs to be provided when the action is called, so it will
 * operate on the correct list
 */
export const ADD_LIST_ITEM_ACTION = 'ADD_LIST_ITEM_ACTION';
export const REMOVE_LIST_ITEM_ACTION = 'REMOVE_LIST_ITEM_ACTION';
export const UPDATE_LIST_ITEM_VALUE_ACTION = 'UPDATE_LIST_ITEM_VALUE_ACTION';
export const SET_LIST_ITEM_ERROR_ACTION = 'SET_LIST_ITEM_ERROR_ACTION';
export const CLEAR_LIST_ITEM_ERRORS_ACTION = 'CLEAR_LIST_ITEM_ERRORS_ACTION';
export const REMOVE_ALL_LIST_ITEMS_ACTION = 'REMOVE_ALL_LIST_ITEMS_ACTION';

export const createActions = dispatch => ({
  setLoading: value => {
    dispatch({ type: SET_LOADING_ACTION, payload: { value } });
  },
  updateValue: (field, value) => {
    dispatch({ type: UPDATE_VALUE_ACTION, payload: { field, value } });
  },
  setError: (field, error) => {
    dispatch({ type: SET_ERROR_ACTION, payload: { field, error } });
  },
  clearErrors: () => {
    dispatch({ type: CLEAR_ERRORS_ACTION });
  },
  setHasWallets: value => {
    dispatch({ type: SET_HAS_WALLETS, payload: { value } });
  },
  setHasExchanges: value => {
    dispatch({ type: SET_HAS_EXCHANGES, payload: { value } });
  },
  addListItem: listName => {
    dispatch({ type: ADD_LIST_ITEM_ACTION, payload: { listName } });
  },
  removeListItem: (listName, index) => {
    dispatch({ type: REMOVE_LIST_ITEM_ACTION, payload: { listName, index } });
  },
  removeAllListItems: listName => {
    dispatch({ type: REMOVE_ALL_LIST_ITEMS_ACTION, payload: { listName } });
  },
  updateListItemValue: (listName, index, field, value) => {
    dispatch({
      type: UPDATE_LIST_ITEM_VALUE_ACTION,
      payload: { listName, index, field, value }
    });
  },
  updateListItemError: (listName, index, field, error) => {
    dispatch({
      type: SET_LIST_ITEM_ERROR_ACTION,
      payload: { listName, index, field, error }
    });
  },
  clearListItemErrors: listName => {
    dispatch({ type: CLEAR_LIST_ITEM_ERRORS_ACTION, payload: { listName } });
  }
});
