import {
  rejectPaymentRequest,
  rejectPaymentRequestSuccess,
  acceptPaymentRequest,
  acceptPaymentRequestSuccess
} from '../../../redux/actions/websocket';

import IOIModal from './IOIModal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getAssetPairs, updateTransactionsDB } from '../../../redux/actions/transactions';
import { getUserProfile } from '../../../redux/actions/login';
import { makeIOIRequest, makeCounterOrderRequest } from '../../../redux/actions/websocket';
import './IOIModal.scss';

const mapStateToProps = state => ({
  websocket: state.websocket.websocket,
  cryptoRates: state.transactions.cryptoRates,
  positions: state.websocket.positions,
  assetsPairs: state.transactions.assetsPairs,
  userData: state.login.profileDetails.data
});

const mapDispatchToProps = {
  getAssetPairs,
  getUserProfile,
  makeIOIRequest,
  makeCounterOrderRequest,
  rejectPaymentRequest,
  rejectPaymentRequestSuccess,
  acceptPaymentRequest,
  acceptPaymentRequestSuccess,
  updateTransactionsDB
};

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(IOIModal);
