export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGIN_RESET = 'LOGIN_RESET';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const ENABLE_TWO_FACTOR_REQUEST = 'ENABLE_TWO_FACTOR_REQUEST';
export const ENABLE_TWO_FACTOR_SUCCESS = 'ENABLE_TWO_FACTOR_SUCCESS';
export const ENABLE_TWO_FACTOR_ERROR = 'ENABLE_TWO_FACTOR_ERROR';
export const DISABLE_TWO_FACTOR_REQUEST = 'DISABLE_TWO_FACTOR_REQUEST';
export const DISABLE_TWO_FACTOR_SUCCESS = 'DISABLE_TWO_FACTOR_SUCCESS';
export const DISABLE_TWO_FACTOR_ERROR = 'DISABLE_TWO_FACTOR_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const CONTACT_SUPPORT_REQUEST = 'CONTACT_SUPPORT_REQUEST';
export const CONTACT_SUPPORT_SUCCESS = 'CONTACT_SUPPORT_SUCCESS';
export const CONTACT_SUPPORT_ERROR = 'CONTACT_SUPPORT_ERROR';

export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const VALIDATE_DEVICE_LOCATION_REQUEST = 'VALIDATE_DEVICE_LOCATION_REQUEST';
export const VALIDATE_DEVICE_LOCATION_SUCCESS = 'VALIDATE_DEVICE_LOCATION_SUCCESS';
export const VALIDATE_DEVICE_LOCATION_ERROR = 'VALIDATE_DEVICE_LOCATION_ERROR';
