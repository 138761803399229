import './UploadReportModal.scss';
import UploadReportModal from './UploadReportModal';
import { connect } from 'react-redux';

const mapDispatchToProps = {};
const mapStateToProps = state => ({
  profileDetails: state.login.profileDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadReportModal);
