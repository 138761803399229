import { Container, Modal, Row } from 'react-bootstrap';
import { MdDone } from 'react-icons/md';
import React, { PureComponent } from 'react';

import { isDollarAsset } from '../../../utils/validators';
import { REQUEST_TYPE } from '../../../enums/validation';
import i18next from 'i18next';
class AcceptTransactionModal extends PureComponent {
  buildModalWrapper = children => {
    return (
      <Modal className="common-modal" centered onHide={this.props.onCloseModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>{i18next.t('acceptTransactionModal.accept')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  };

  renderSummary = () => {
    console.log(this.props);
    const inTotalSideS = this.props.side === 'B' ? i18next.t('acceptTransactionModal.inTotal') : '';
    const inTotalSideB = this.props.side === 'S' ? i18next.t('acceptTransactionModal.inTotal') : '';
    console.log(inTotalSideS, inTotalSideB);
    return (
      <>
        <div className="ed-row">
          <p className="ed-pretitle">
            {i18next.t('acceptTransactionModal.agreeing')}{' '}
            {this.props.isInbound && this.props.type !== REQUEST_TYPE
              ? i18next.t('acceptTransactionModal.receive') + inTotalSideS
              : i18next.t('acceptTransactionModal.send') + inTotalSideB}
            :
          </p>
          <h1 className="external-deposit-h1-label exchange-space-bottom">{`${
            isDollarAsset(this.props.sendAsset) ? '$' : ''
          }  ${this.props.sendAmount} ${this.props.sendAsset}`}</h1>
        </div>
        {this.props.isEscrow && (
          <>
            <div className="ed-row">
              <p className="ed-pretitle">
                {' '}
                {i18next.t('acceptTransactionModal.inExchange')}{' '}
                {this.props.isInbound
                  ? i18next.t('acceptTransactionModal.send') + inTotalSideB
                  : i18next.t('acceptTransactionModal.receive') + inTotalSideS}
                :
              </p>
              <h1 className="external-deposit-h1-label exchange-space-bottom">{`${
                isDollarAsset(this.props.receiveAsset) ? '$' : ''
              } ${this.props.receiveAmount}${' '}${this.props.receiveAsset}`}</h1>
            </div>
          </>
        )}
        <div className="ed-row">
          <div className="ed-row-container">
            <span className="ed-row-label escrow-label">{i18next.t('acceptTransactionModal.toAccount')}</span>
            <span className="summary-h3-text summary-darker-text">{`${this.props.account || ''}`}</span>
          </div>
        </div>

        {/* <Row>
          <Col>
            <p className="escrow-label">
              VaultLink fee <MdHelp size="1.2em" />
            </p>
          </Col>
          <Col>
            <p className="summary-h3-text summary-darker-text">{`${this.state.fee}`}</p>
          </Col>
        </Row> */}
      </>
    );
  };

  render() {
    console.log('this.props', this.props);
    const content = (
      <>
        <Container className="transaction-success-container transaction-cancel-container">
          <Row className="transaction-success-icon-container">
            <MdDone size="3em" />
          </Row>
          <Row>{this.renderSummary()}</Row>
          <p className="transaction-notion-text transaction-cancel-text summary-label-text ">
            {i18next.t('acceptTransactionModal.pleaseConfirm')}{' '}
            <b>{i18next.t('acceptTransactionModal.onceAccepted')}</b>
          </p>
        </Container>
      </>
    );
    if (this.props.notStandalone) {
      return content;
    } else {
      return this.buildModalWrapper(content);
    }
  }
}

export default AcceptTransactionModal;
