import * as ACTIONS from '../../actions/analytics';
import * as CONSTANTS from '../../constants/analytics';
import { put, takeLatest } from 'redux-saga/effects';
import { default as URLS } from '../../constants/urls';
import RequestBuilder from '../../../request/RequestBuilder';

function* fetchAnalyticsData(action) {
  try {
    const responseData = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(
      ACTIONS.fetchAllAnalyticsDataSuccess({
        data: responseData.data.transactions
      })
    );
  } catch (err) {
    yield put(ACTIONS.fetchAllAnalyticsDataError(err));
  }
}

function* getTransactionById(action) {
  try {
    const params = {
      txnid: action.data.txnid,
      type: action.data.type
    };

    const responseData = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(params)
      .build()
      .map(res => res.data)
      .toEffect();

    // merging two objects because one object has customized display values
    action.data.successCallback(Object.assign(responseData.transaction, action.data.transaction));
    yield put(
      ACTIONS.getTransactionByIdSuccess({
        data: responseData.transaction
      })
    );
  } catch (err) {
    yield put(ACTIONS.getTransactionByIdError(err));
  }
}

function* fetchSpecificData(action) {
  const field = action.data?.field;

  try {
    const responseData = yield RequestBuilder.default()
      .get(URLS[action.type])
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(
      ACTIONS.fetchSpecificAnalyticsDataSuccess({
        field: field,
        data: responseData.data.transactions
      })
    );
  } catch (err) {
    yield put(ACTIONS.fetchSpecificAnalyticsDataError({ field: field, error: err }));
  }
}

export default function* analyticsSaga() {
  yield takeLatest(CONSTANTS.FETCH_ANALYTICS_DATA_ALL, fetchAnalyticsData);
  yield takeLatest(CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA, fetchSpecificData);
  yield takeLatest(CONSTANTS.GET_TRANSACTION_BY_ID, getTransactionById);
}
