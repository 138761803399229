import { Container, FormControl, Modal, Row } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import { MdClose } from 'react-icons/md';
import i18next from 'i18next';
class CancelTransactionModal extends PureComponent {
  buildModalWrapper = children => {
    return (
      <Modal className="common-modal" centered show onHide={this.props.onCloseModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>{i18next.t('cancelTransactionModal.cancel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  };
  render() {
    const content = (
      <>
        <Container className="transaction-success-container transaction-cancel-container">
          <Row className="transaction-error-icon-container">
            <MdClose size="3em" />
          </Row>
          <Row className="summary-label-text transaction-cancel-text">
            {i18next.t('cancelTransactionModal.pleaseConfirm')}{' '}
            <b>{i18next.t('cancelTransactionModal.onceCancelled')}</b>
          </Row>
          <div className="transaction-textarea-wrapper">
            <div className="system-form-control-placeholder">{i18next.t('cancelTransactionModal.reason')}</div>
            <div className="transaction-textarea">
              <FormControl
                onChange={this.props.handleDescriptionChange}
                className="system-form-control"
                size="sm"
                name="description"
                as="textarea"
                rows={3}
              />
            </div>
          </div>
        </Container>
      </>
    );
    if (this.props.notStandalone) {
      return content;
    } else {
      return this.buildModalWrapper(content);
    }
  }
}

export default CancelTransactionModal;
