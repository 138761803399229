import { Accordion, Container, Row, Table } from 'react-bootstrap';
import { FRACTIONS } from '../../enums/validation';
import { MdKeyboardArrowDown } from 'react-icons/md';
import React, { PureComponent } from 'react';
import { getLocalisedNumericString } from '../../utils/generalUtils';
import { getDecimalByFraction } from '../../utils/transactionsHelpers';
import { getSecurityLabel } from '../../utils/DOMHelpers';
import i18next from 'i18next';
import LoadingIndicator from '../LoadingIndicator';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import DonutChart from '../Charts/donutChart/DonutChart';

/**
 * AccountPositionsOverview
 *
 * Props:
 * @param assets - from redux
 * @param cryptoRates: from redux
 * @param rewards: from redux
 * @param account: {Object} Account object we are showing positions for
 * @param positions: {Object} Positions for the account (normalized like so: {BTC: 123, ETH: 123})
 * @param onBuySell: {function} Optional callback if BuySell option is required
 * @param userData: {Object} Only required if onBuySell is provided
 * @param assetActionEnabled: {boolean} open asset details page on row click
 *
 * @returns jsx
 */
class AccountPositionsOverview extends PureComponent {
  state = {
    positions: [],
    positionsLoaded: false,
    positionsLoading: false
  };

  componentDidMount() {
    this.props.getAssets();
    this.props.fetchStakingRewardsSummaryRequest();
  }

  isLoading = () => {
    return this.props.rewards.loading || this.props.assets.loading || !this.props.account || !this.props.positions;
  };

  isError = () => {
    return this.props.rewards.error || this.props.assets.error;
  };

  getPositionData = () => {
    if (!this.props.positions || !this.props.positions[this.props.account.account]) return {};

    return this.props.positions[this.props.account.account];
  };

  getOpenPositionsData = () => {
    if (!this.props.openPositions || !this.props.openPositions[this.props.account.account]) return {};

    return this.props.openPositions[this.props.account.account];
  };

  mapPositionData = () => {
    const positionData = this.getPositionData();
    const openPositionsData = this.getOpenPositionsData();

    const filteredAssets = this.props.assets.data.filter(asset => asset?.security?.indexOf(' ') === -1);
    return filteredAssets.map(assetData => {
      const asset = assetData?.security;
      const amount = positionData[assetData?.security] ? Number(positionData[assetData?.security]) : 0;
      const openAmount = openPositionsData[assetData?.security] ? Number(openPositionsData[assetData?.security]) : 0;
      const price = asset === 'USD' ? 1 : this.props.cryptoRates.data[asset.toLowerCase()] ?? 0;
      const usdTotal = amount * price;
      let reserved = undefined;
      const currUSD24hBefore = Number(assetData?.currUSD24hBefore);

      const stakedAsset = this.props.assets?.data?.find(a => a.security === `${asset} STAKED`);

      const rewards = this.props.rewards.data;
      // rewards info need to have asset info to avoid hardcoding ETH
      if (stakedAsset || rewards.total?.[asset]?.amount) {
        const amountStaked = positionData[stakedAsset?.security] ? Number(positionData[stakedAsset?.security]) : 0;
        const amountRewards = rewards.total?.[asset]?.amount ?? 0;
        const amountReserved = amountStaked + amountRewards;
        const usdTotalStaked = amountReserved * price;

        reserved = {
          amount: amountReserved,
          price,
          usdTotal: usdTotalStaked
        };
      }

      return {
        asset,
        reserved,
        available: {
          amount,
          price,
          usdTotal,
          currUSD24hBefore
        },
        open: openAmount
      };
    });
  };

  redirectToCryptoAssetDetails = asset => {
    this.props.history.push(`/asset/${asset}`);
  };

  renderPositionRows = positionData => {
    if (Object.keys(positionData).length === 0) {
      return null;
    }

    const totalValueInUSD = positionData
      .filter(position => position.available.amount > 0)
      .reduce((partialSum, position) => partialSum + position.available.amount * position.available.price, 0);

    return positionData
      .filter(position => position.available.amount > 0)
      .map(position => {
        const asset = position.asset;
        let { amount, price = 0, usdTotal } = position.available;

        const openAmount = position.open;

        const noDecimals = getDecimalByFraction(FRACTIONS[asset]) || 0;
        const USDDecimal = getDecimalByFraction(FRACTIONS.USD) || 0;

        const amountReserved = position?.reserved?.amount || 0;
        const usdTotalReserved = position?.reserved?.usdTotal || 0;

        const shouldBeExpandable = position.reserved?.amount > 0;

        const priceChangePercentage = this.calculatePriceChangePercentage(price, position.available.currUSD24hBefore);

        const assetPercentOfTotal = usdTotal / totalValueInUSD;
        const restOfAssetPercentOfTotal = (totalValueInUSD - usdTotal) / totalValueInUSD;

        const donutData = [
          {
            name: asset,
            y: assetPercentOfTotal
          },
          {
            name: 'Total (USD)',
            y: restOfAssetPercentOfTotal
          }
        ];

        return (
          <tr
            key={asset}
            className="dash-table-tr"
            onClick={() =>
              position?.asset !== 'USD' && this.props.assetActionEnabled
                ? this.redirectToCryptoAssetDetails(position?.asset.toLowerCase())
                : undefined
            }
          >
            <td>
              <div className="dashboard-td">
                <img
                  className="dashboard-currency-icon"
                  src={require(`../../assets/svg/crypto/${position?.asset.toLowerCase()}.svg`)}
                  alt={`${position?.asset}`}
                />
              </div>
            </td>

            <td>
              <div className="dashboard-td ">
                {shouldBeExpandable ? (
                  <Accordion.Toggle
                    onClick={e => e.stopPropagation()}
                    as={props => (
                      <span {...props}>
                        {getSecurityLabel(asset)} <MdKeyboardArrowDown size={20} />
                      </span>
                    )}
                    variant="link"
                    eventKey={asset}
                  />
                ) : (
                  getSecurityLabel(asset)
                )}
              </div>
              <Accordion.Collapse eventKey={asset}>
                <>
                  <div className="dashboard-td ">Available</div>
                  <div className="dashboard-td ">Unavailable</div>
                </>
              </Accordion.Collapse>
            </td>

            <td>
              <div className="dashboard-td">{getLocalisedNumericString(amount + amountReserved, true, noDecimals)}</div>
              <Accordion.Collapse eventKey={asset}>
                <>
                  <div className="dashboard-td">{getLocalisedNumericString(amount, true, noDecimals)}</div>
                  <div className="dashboard-td">{getLocalisedNumericString(amountReserved, true, noDecimals)}</div>
                </>
              </Accordion.Collapse>
            </td>
            {this.props.openPositions ? (
              <td>
                <div className="dashboard-td">{getLocalisedNumericString(openAmount, true, noDecimals)}</div>
              </td>
            ) : null}
            <td>
              <div className="dashboard-td">
                {getSecurityLabel(asset) !== 'USD'
                  ? `$ ${getLocalisedNumericString(
                      price,
                      true,
                      asset === 'USDC' || asset === 'FIL' ? noDecimals : USDDecimal
                    )}`
                  : '-'}
              </div>
              <Accordion.Collapse eventKey={asset}>
                <>
                  <div className="dashboard-td">
                    {getSecurityLabel(asset) !== 'USD'
                      ? `$ ${getLocalisedNumericString(price, true, USDDecimal)}`
                      : '-'}
                  </div>
                  <div className="dashboard-td">
                    {getSecurityLabel(asset) !== 'USD'
                      ? `$ ${getLocalisedNumericString(price, true, USDDecimal)}`
                      : '-'}
                  </div>
                </>
              </Accordion.Collapse>
            </td>
            <td>
              {getSecurityLabel(asset) !== 'USD' ? (
                <div
                  className={`dashboard-td assets-change-percentage
              ${priceChangePercentage < 0 ? 'assets-change-percentage-less' : 'assets-change-percentage-more'}`}
                >
                  {priceChangePercentage + '%'}
                </div>
              ) : (
                <div className="dashboard-td">-</div>
              )}
            </td>
            <td>
              <div className="dashboard-td">
                $ {getLocalisedNumericString(usdTotal + usdTotalReserved, true, USDDecimal)}
              </div>
              <Accordion.Collapse eventKey={asset}>
                <>
                  <div className="dashboard-td">$ {getLocalisedNumericString(usdTotal, true, USDDecimal)}</div>
                  <div className="dashboard-td">$ {getLocalisedNumericString(usdTotalReserved, true, USDDecimal)}</div>
                </>
              </Accordion.Collapse>
            </td>
            <td>
              <div className="dashboard-td">
                <DonutChart allowChartUpdate={true} containerProps={{ className: 'pie-chart' }} series={donutData} />
              </div>
            </td>
          </tr>
        );
      });
  };

  renderPositionsTable = filteredPositionData => {
    const nonNullPositions = filteredPositionData.filter(position => position.available.amount > 0);

    if (this.isError()) {
      <div className="positions-loading-wrapper">Error loading data</div>;
    }

    return (
      <InfiniteScroll className="dashboard-positions-table-wrap">
        {nonNullPositions.length === 0 ? (
          <div className="dashboard-positions-table-message">
            <span>{i18next.t('accountPositions.doesntOwnAssets')}</span>
          </div>
        ) : (
          <Table responsive className="dash-table">
            <thead>
              <tr>
                <th></th>
                <th className="column-extend-s column-positions-name">
                  <span>{i18next.t('accountPositions.name')}</span>
                </th>
                <th className="column-extend-m">
                  <span>{i18next.t('accountPositions.amount')}</span>
                </th>
                {this.props.openPositions ? (
                  <th className="column-extend-m">
                    <span>{i18next.t('accountPositions.openPos')}</span>
                  </th>
                ) : null}
                <th className="column-extend-s">
                  <span>{i18next.t('accountPositions.price')}</span>
                </th>
                <th className="column-extend-s">
                  <span>{i18next.t('accountPositions.changePercent24h')}</span>
                </th>
                <th className="column-extend-m">
                  <span>{i18next.t('accountPositions.usdTotal')}</span>
                </th>
                <th className="column-extend-m">
                  <span>{i18next.t('accountPositions.allocation')}</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.renderPositionRows(filteredPositionData)}</tbody>
          </Table>
        )}
      </InfiniteScroll>
    );
  };

  calculatePriceChangePercentage = (priceCurrent, price24hBefore) => {
    if (!priceCurrent || !price24hBefore) {
      return 0;
    }
    return (((+priceCurrent - +price24hBefore) / +price24hBefore) * 100).toFixed(2);
  };

  render() {
    if (this.isLoading()) {
      return (
        <div className="positions-loading-wrapper">
          <LoadingIndicator />
        </div>
      );
    }

    const filteredPositionData = this.mapPositionData();

    return (
      <Accordion>
        <Container fluid>
          <Row noGutters className="account-positions-table">
            {this.renderPositionsTable(filteredPositionData)}
          </Row>
        </Container>
      </Accordion>
    );
  }
}

export default withRouter(AccountPositionsOverview);
