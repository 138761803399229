import {
  FRACTIONS,
  MAX_TRANSACTION_COUNTERPARTY_LEN,
  PENDING_API_TYPES,
  SECURITY_CODES,
  SECURITY_CODE_NAME_MAP,
  TRANSACTION_API_TYPES
} from '../enums/validation';
import { getCellShortenedString, getLocalisedNumericString } from './generalUtils';

export const getTransactionType = transaction => {
  const { transactionType } = transaction;
  if (transactionType === TRANSACTION_API_TYPES.SEND) {
    return 'send';
  }
  if (transactionType === TRANSACTION_API_TYPES.RECIEVE) {
    return 'received';
  }
  if (transactionType === TRANSACTION_API_TYPES.TO_BANK) {
    return 'to-bank';
  }
  if (transactionType === TRANSACTION_API_TYPES.TO_NETWORK) {
    return 'to-network';
  }
  if (transactionType === TRANSACTION_API_TYPES.WITHDRAW) {
    return 'withdraw';
  }

  if (transactionType === TRANSACTION_API_TYPES.DEPOSIT) {
    return 'deposit';
  }
  if (transactionType === TRANSACTION_API_TYPES.EXCHANGE) {
    return 'exchange';
  }
  if (transactionType === TRANSACTION_API_TYPES.ESCROW) {
    return 'escrow';
  }
  if (transactionType === TRANSACTION_API_TYPES.FINISHED_ESCROW) {
    return 'Finished Escrow';
  }

  return transactionType;
};

// GET SPECIFIC FIELD
export const getCounterpartyField = (transaction, fullField = false, firmName = false) => {
  if (transaction.external_address) {
    if (fullField) return transaction.external_address;
    else return getCellShortenedString(transaction.external_address, MAX_TRANSACTION_COUNTERPARTY_LEN);
  } else if (transaction.counterfirm?.includes(':')) return transaction.counterfirm;
  else if (transaction.counteraccount?.includes(':')) return transaction.counteraccount;
  else return transaction.counteraccount;
};

export const getProcessedByFirm = transaction => {
  const { traderefno } = transaction;
  return traderefno.split(':')[1];
};

export const flipSide = side => {
  return side === 'B' ? 'S' : 'B';
};

export const getProcessedBy = transaction => {
  const { traderefno } = transaction;
  const counterParty = traderefno.split(':')[0];
  return !counterParty || counterParty.toLowerCase() === 'null' ? '' : counterParty;
};
export const getCryptoName = crypto => {
  return SECURITY_CODE_NAME_MAP[crypto] || crypto;
};
export const getTransactionID = (tradeRefNo, transactionType) => {
  if (!tradeRefNo) return '';

  if (transactionType === 'send') {
    let tradeParts = '';
    if (tradeRefNo.includes(':')) {
      tradeParts = tradeRefNo.split(':');
      return tradeParts[tradeParts.length - 3] + ':' + tradeParts[tradeParts.length - 2];
    }
    return tradeRefNo;
  } else if (
    transactionType === PENDING_API_TYPES.ESCROW ||
    transactionType === PENDING_API_TYPES.REQUEST ||
    transactionType === TRANSACTION_API_TYPES.IOI
  ) {
    const tradeParts = tradeRefNo.split('_');
    return tradeParts[tradeParts.length - 1];
  } else {
    return tradeRefNo;
  }
  // TO DO - EXCHANGE TXN ID
  // if (!tradeRefNo) return '';

  // const tradeParts = tradeRefNo.split(':');

  // return getCellShortenedString(
  //   tradeParts[tradeParts.length - 1],
  //   MAX_TRANSACTION_COUNTERPARTY_LEN
  // );
};

export const getPublicMessage = messageData => {
  if (!messageData) return '';
  //INFO: messageData = 'publicNote|privateNote'
  const parts = messageData.split('|');
  return parts[0];
};

export const getPrivateMessage = messageData => {
  if (!messageData) return '';
  //INFO: messageData = 'publicNote|privateNote'
  const parts = messageData.split('|');
  return parts[1];
};

export const getPriceExchange = messageData => {
  return !messageData.security.includes('USD') ? messageData.execprice : '$ ' + messageData.execprice;
};

//SPECIFIC MESSAGE TYPE
export const isReceivedEvent = event => event === 'received' || event === 'exchange-receive';
export const isUSDAsset = asset => {
  if (asset === 'USD') return true;
};
export const isSendTransaction = transaction => transaction.type === TRANSACTION_API_TYPES.SEND;

export const isRequestMessage = order => order.price === '1' || order.price === '0';

export const isInbound = order => order.inbound === true;

export const containUSD = order => order.security.includes('USD');

export const isSelling = order => order.side === 'S';

export const isVLN = category => category === 'VLN';

export const isExchangeTransaction = transaction => transaction.type === TRANSACTION_API_TYPES.EXCHANGE;

export const isEscrow = transaction => transaction.type === TRANSACTION_API_TYPES.ESCROW;

// alias
export const isExchange = isExchangeTransaction;

export const isReceive = transaction => transaction.type === TRANSACTION_API_TYPES.RECIEVE;

export const isCrypto = transaction => isCryptoWithdraw(transaction) || isCryptoDeposit(transaction);

export const isCryptoDeposit = transaction => transaction.type === TRANSACTION_API_TYPES.DEPOSIT;

export const isCryptoWithdraw = transaction => transaction.type === TRANSACTION_API_TYPES.WITHDRAW;

export const isStaking = transaction => transaction.type === TRANSACTION_API_TYPES.STAKING;

export const isEscrowFinishedTransaction = transaction => transaction.type === TRANSACTION_API_TYPES.ESCROW;

//FILTER REQUEST AND ESCROW
export const getRequestsForAccount = (requests, accountID) => {
  return requests.filter(request => request.firm === accountID || request.brokers === accountID);
};

export const getEscrowsForAccount = (escrows, accountID) => {
  return escrows.filter(escrow => escrow.firm === accountID || escrow.brokers === accountID);
};

export const getScheduledTransfersForAccount = (transfers, accountID) => {
  return transfers.filter(transfer => transfer.account === accountID || transfer.counteraccount === accountID);
};

export const getAccountData = (accountId, accountsObject) => {
  //   let exists = accountsObject?.vln?.find(
  //     account => account.account === accountId
  //   );

  //   if (!exists) {
  //     exists = accountsObject?.vlb?.find(
  //       account => account.account === accountId
  //     );
  //   }
  let exists = accountsObject.find(account => account.account === accountId);

  return exists;
};

//DECIMAL AND ASSET DATA
export const filterAssetsBasedOnPositionData = (assets, positionObject) =>
  assets.filter(
    asset =>
      asset.security &&
      positionObject?.hasOwnProperty(asset.security) &&
      positionObject[asset.security] &&
      positionObject[asset.security] !== '0'
  );

export const getDecimalByAsset = (assets, selectedAsset) => {
  const assetData = assets.find(asset => asset.security === selectedAsset);
  //   const fractionBase =
  //     assetData.fractionbase === 1 ? 100 : assetData.fractionbase;
  const fractionBase = FRACTIONS[assetData?.security];
  return assetData ? getDecimalByFraction(fractionBase) : 0;
};

export const getDecimalByFraction = fraction => {
  const number = fraction === 1 ? 1 / 100 : 1 / fraction;
  return getDecimalCount(number);
};

const getDecimalCount = value => {
  let text = value.toString();
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    let [, trail] = text.split('e-');
    let deg = parseInt(trail, 10);
    return deg;
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1]?.length || 0;
  }
  return 0;
};

export const getDifferenceOfAssets = (firstAsset, secondAsset, pair, cryptoRates, side, allBooks, value) => {
  const books = getMarketData(side, allBooks[pair.security]);

  const amountToReceive = getAmountToReceive(books, side, value);

  if (amountToReceive === 0) {
    const sendCurrUSD = cryptoRates.data[firstAsset.toLowerCase()] || '1';
    const receiveCurrUSD = cryptoRates.data[secondAsset.toLowerCase()] || '1';

    return (Number(sendCurrUSD) / Number(receiveCurrUSD)).toFixed(8) * Number(value);
  }
  return amountToReceive;
};

export const getMarketData = (side, books) => {
  return books?.filter(b => b.side !== side);
};

/**
 * Returns the transaction side(BUY/SELL) based on the asset pair.
 *
 * @param assetsPairs {{security:string, pair_first:string, pair_second:string}[]} - All redux-stored asset pairs provided by FI.
 * @param assetToSendOrPair {string} - The asset to send or asset pair.
 * @param assetToReceive {string?} - The asset to receive (optional).
 * @return {{pair:{security:string, pair_first:string, pair_second:string}, side:string}}
 */
export const getSideByAssetPair = (assetsPairs, assetToSendOrPair, assetToReceive) => {
  const [BUY, SELL] = ['B', 'S'];
  // If only one argument is provided, then it is the asset pair.
  const assetPair = !!assetToReceive ? assetToSendOrPair + assetToReceive : assetToSendOrPair;

  const foundSell = assetsPairs.find(pair => pair.security === assetPair);
  if (foundSell) {
    return { pair: foundSell, side: SELL };
  }

  // All pairs send by FI are by default SELL side. If we don't find the pair
  // that means that this is a BUY transaction.
  const foundBuy = assetsPairs.find(({ pair_first, pair_second }) => pair_second + pair_first === assetPair);
  if (foundBuy) {
    return { pair: foundBuy, side: BUY };
  }

  throw new Error(`Unsupported exchange pair: ${assetPair}`);
};

const getAmountToReceive = (books, side, sendInput) => {
  let amountToReceive = 0;

  if (side === 'S') {
    books
      ?.filter(book => !book.ioi)
      .sort((a, b) => b.price - a.price)
      .forEach(b => {
        if (sendInput === 0) {
          return;
        }
        if (sendInput >= b.qty) {
          sendInput = sendInput - b.qty;
          amountToReceive = amountToReceive + b.qty * b.price;
        } else if (sendInput < b.qty) {
          amountToReceive = amountToReceive + sendInput * b.price;
          sendInput = 0;
        }
      });
  } else {
    books
      ?.filter(book => !book.ioi)
      .sort((a, b) => a.price - b.price)
      .forEach(b => {
        if (sendInput === 0) {
          return;
        }
        if (parseFloat(sendInput) < parseFloat(b.price * b.qty)) {
          amountToReceive = amountToReceive + parseFloat(parseFloat(sendInput) / parseFloat(b.price));

          sendInput = 0;
          return;
        } else if (sendInput >= b.price * b.qty) {
          sendInput = sendInput - b.price * b.qty;

          amountToReceive = amountToReceive + parseFloat(b.qty);
        }
      });
  }
  if (sendInput === 0) {
    return amountToReceive;
  } else {
    return 0;
  }
};

export const displayExchangeRate = (firstAsset, secondAsset, assets, currUSDObject) => {
  const firstData = assets?.find(asset => asset.security === firstAsset);
  const secondData = assets?.find(asset => asset.security === secondAsset);

  if (!firstData || !secondData) {
    return '';
  }

  let fiatData = '';
  let nonFiatData = '';

  if (firstData.attr?.label === 'fiat') {
    fiatData = firstData;
    nonFiatData = secondData;
  } else if (secondData.attr?.label === 'fiat') {
    fiatData = secondData;
    nonFiatData = firstData;
  }
  const currUSD = assets?.find(asset => asset.security === nonFiatData.security)?.currUSD;

  if (fiatData) {
    return `1${nonFiatData.security} = $ ${currUSD || 1} ${fiatData.security} `;
  }

  const sendCurrUSD = assets?.find(asset => asset.security === firstData.security).currUSD || '1'; //firstData.currUSD;
  const receiveCurrUSD = assets?.find(asset => asset.security === secondData.security).currUSD || '1'; //secondData.currUSD;

  const difference = (Number(sendCurrUSD) / Number(receiveCurrUSD)).toFixed(8);

  return `1 ${firstData.security} = ${difference} ${secondData.security}`;
};

export const getToAndFromExchangeAsset = transaction => {
  let fromSecurity = '';
  let toSecurity = '';
  if (transaction.side === 'S') {
    if (!transaction.security.includes('USD')) {
      fromSecurity = transaction.security.substr(3, 3);
      toSecurity = transaction.security.substr(0, 3);
    } else {
      fromSecurity = transaction.security.substr(0, 3);
      toSecurity = transaction.security.substr(3, 3);
    }
  } else if (transaction.side === 'B') {
    if (!transaction.security.includes('USD')) {
      fromSecurity = transaction.security.substr(0, 3);
      toSecurity = transaction.security.substr(3, 3);
    } else {
      fromSecurity = transaction.security.substr(3, 3);
      toSecurity = transaction.security.substr(0, 3);
    }
  }
  return { fromSecurity, toSecurity };
};

/**
 * This method is an effort to refactor {@link getAmounts}.
 *
 * @param transaction
 * @param type
 * @param isPendingTransaction
 * @param assetPairs
 * @return {{receiveAmount: string, amount: string, receiveCryptoAmount: string, sendSecurity: string, receiveSecurity: string, sendCryptoAmount: string, status: string}}
 */
export const getAmountsNew = (transaction, type, isPendingTransaction, assetPairs) => {
  let sendAmount = '';
  let receiveAmount = '';
  let sendSecurity = '';
  let receiveSecurity = '';
  let receiveCryptoAmount = '';
  let sendCryptoAmount = '';
  let status = '';

  const side = transaction.side;

  const isSell = side === 'S';
  const isEscrow = (type === 'order' && isPair(transaction.security)) || type === 'escrow';
  const isScheduled = type === PENDING_API_TYPES.SCHEDULED_TRANSFER;
  const isRequest = type === 'order' && isAsset(transaction.security);

  let pair;
  try {
    pair = getSideByAssetPair(assetPairs, transaction.security).pair;
  } catch (e) {
    // For some transactions we cannot use this function to extract the pair
    // and turns out that for those transactions we don't need to use it.
    // Rather than refactoring the whole method we just catch the error and
    // continue with the execution.
  }

  if (isPendingTransaction) {
    status = 'PENDING';
    if (isEscrow) {
      if (isSell && transaction.inbound) {
        sendAmount = parseFloat(transaction.qty) * parseFloat(transaction.price);
        receiveAmount = parseFloat(transaction.qty);

        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
      } else {
        sendAmount = parseFloat(transaction.qty);
        receiveAmount = parseFloat(transaction.qty) * parseFloat(transaction.price);

        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second;
      }
    }

    if (isScheduled) {
      sendAmount = parseFloat(transaction.qty);
      sendSecurity = transaction.security;
    }

    if (isRequest) {
      const amount = parseFloat(transaction.qty || transaction.execqty);
      if (transaction.inbound) {
        sendSecurity = transaction.security;
        sendAmount = amount;
      } else {
        receiveAmount = amount;
        receiveSecurity = transaction.security;
      }
    }
  } else if (type === TRANSACTION_API_TYPES.EXCHANGE) {
    if (isSell) {
      sendSecurity = pair?.pair_first;
      receiveSecurity = pair?.pair_second;
      sendAmount = parseFloat(transaction.amount);
      receiveAmount = sendAmount * parseFloat(transaction.execprice);
    } else {
      sendSecurity = pair?.pair_second;
      receiveSecurity = pair?.pair_first;
      receiveAmount = parseFloat(transaction.amount);
      sendAmount = receiveAmount * parseFloat(transaction.execprice);
    }

    // Is this valid?
    if (transaction.transactionType === TRANSACTION_API_TYPES.EXCHANGE) {
      sendSecurity = pair?.pair_first;
      receiveSecurity = pair?.pair_second;
      sendAmount = parseFloat(transaction.amount);
      receiveAmount = parseFloat(transaction.amountCounterparty);
    }
  } else if (
    type === TRANSACTION_API_TYPES.ESCROW ||
    type === TRANSACTION_API_TYPES.FINISHED_ESCROW ||
    type === TRANSACTION_API_TYPES.IOI ||
    type === TRANSACTION_API_TYPES.LIMIT
  ) {
    if (!transaction.ismaker) {
      if (isSell) {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second || transaction.assetCounterparty;
        sendAmount = parseFloat(transaction.execqty || transaction.amount);
        receiveAmount = transaction.execqty * transaction.execprice;
        sendCryptoAmount = transaction.cryptoValue;
      } else {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        sendAmount = transaction.execprice * transaction.execqty;
        receiveAmount = parseFloat(transaction.execqty);
        receiveCryptoAmount = transaction.cryptoValue;
      }
    } else {
      if (isSell) {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second;
        sendAmount = transaction.execqty;
        receiveAmount = transaction.execqty * transaction.execprice;
        sendCryptoAmount = transaction.cryptoValue;
      } else {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        sendAmount = transaction.execqty * transaction.execprice;
        receiveAmount = transaction.execqty;
        receiveCryptoAmount = transaction.cryptoValue;
      }
    }
  } else {
    if (type === TRANSACTION_API_TYPES.SEND) {
      sendSecurity = transaction.security || transaction.crypto.toUpperCase();
      sendAmount = parseFloat(transaction.execqty || transaction.amount);
    }
    if (type === TRANSACTION_API_TYPES.RECIEVE) {
      receiveSecurity = transaction.security || transaction.crypto.toUpperCase();
      receiveAmount = parseFloat(transaction.execqty || transaction.amount);
    }
  }
  if (type === PENDING_API_TYPES.REQUEST) {
    if (transaction.ismaker) {
      receiveAmount = parseFloat(transaction.qty || transaction.execqty);
      receiveSecurity = transaction.security;
    } else {
      sendSecurity = transaction.security;
      sendAmount = parseFloat(transaction.qty || transaction.execqty || transaction.amount);
    }
  }
  return {
    status,
    amount: sendAmount,
    sendSecurity,
    receiveAmount,
    receiveSecurity,
    sendCryptoAmount,
    receiveCryptoAmount
  };
};

// DETAILS MODAL HELPERS
export const getAmounts = (transaction, type, isPendingTransaction, assetPairs) => {
  let amount = '';
  let receiveAmount = '';
  let sendSecurity = '';
  let receiveSecurity = '';
  let sendDecimal = '';
  let receiveDecimal = '';
  let receiveCryptoAmount = '';
  let sendCryptoAmount = '';
  let transactionType = '';
  let status = '';

  let pair;
  try {
    pair = getSideByAssetPair(assetPairs, transaction.security).pair;
  } catch (e) {
    // For some transactions we cannot use this function to extract the pair
    // and turns out that for those transactions we don't need to use it.
    // Rather than refactoring the whole method we just catch the error and
    // continue with the execution.
  }

  if (isPendingTransaction) {
    status = 'PENDING';
    const isEscrow = (type === 'order' && isPair(transaction.security)) || type === 'escrow';
    const isScheduled = type === PENDING_API_TYPES.SCHEDULED_TRANSFER;
    const isRequest = type === 'order' && isAsset(transaction.security);

    if (isEscrow) {
      transactionType = 'Escrow';
      const isSellingEscrow = isSelling(transaction);
      if (!isSellingEscrow) {
        if (!transaction.inbound) {
          sendSecurity = pair?.pair_second;
          receiveSecurity = pair?.pair_first;
          if (!pair?.security?.includes('USD')) {
            sendSecurity = pair?.pair_first;
            receiveSecurity = pair?.pair_second;
          }

          sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
          receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
          receiveAmount = getLocalisedNumericString(transaction.qty, false, receiveDecimal || 0);
          amount = getLocalisedNumericString(transaction.qty * transaction.price, false, sendDecimal || 0);
        } else {
          sendSecurity = pair?.pair_first;
          receiveSecurity = pair?.pair_second;
          if (!pair?.security.includes('USD')) {
            sendSecurity = pair?.pair_second;
            receiveSecurity = pair?.pair_first;
          }
          sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
          receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
          receiveAmount = getLocalisedNumericString(transaction.price * transaction.qty, false, sendDecimal || 0);
          amount = getLocalisedNumericString(transaction.qty, false, receiveDecimal || 0);
        }
      } else {
        if (!transaction.inbound) {
          sendSecurity = pair?.pair_first;
          receiveSecurity = pair?.pair_second;
          if (!pair?.security.includes('USD')) {
            sendSecurity = pair?.pair_second;
            receiveSecurity = pair?.pair_first;
          }
          sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
          receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
          receiveAmount = getLocalisedNumericString(transaction.price * transaction.qty, false, sendDecimal || 0);
          amount = getLocalisedNumericString(transaction.qty, false, receiveDecimal || 0);
        } else {
          sendSecurity = pair?.pair_second;
          receiveSecurity = pair?.pair_first;
          if (!pair?.security.includes('USD')) {
            sendSecurity = pair?.pair_first;
            receiveSecurity = pair?.pair_second;
          }
          sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
          receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
          receiveAmount = getLocalisedNumericString(transaction.qty, false, receiveDecimal || 0);
          amount = getLocalisedNumericString(transaction.price * transaction.qty, false, sendDecimal || 0);
        }
      }
    }
    if (isScheduled) {
      transactionType = 'Scheduled Transfer';
      sendDecimal = getDecimalByFraction(FRACTIONS[transaction.security]);
      amount = getLocalisedNumericString(transaction.qty, false, sendDecimal || 0);

      sendSecurity = transaction.security;
      receiveSecurity = '';
    }
    if (isRequest) {
      transactionType = 'Request';
      if (!transaction.inbound) {
        receiveAmount = getLocalisedNumericString(
          transaction.qty || transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[transaction.security])
        );
        receiveSecurity = transaction.security;
      } else {
        sendSecurity = transaction.security;
        amount = getLocalisedNumericString(
          transaction.qty || transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[transaction.security])
        );
      }
    }
  } else if (type === TRANSACTION_API_TYPES.EXCHANGE) {
    if (isSelling(transaction)) {
      sendSecurity = pair?.pair_first;
      receiveSecurity = pair?.pair_second;
      amount = getLocalisedNumericString(transaction.amount, false, getDecimalByFraction(FRACTIONS[pair?.pair_first]));
      sendCryptoAmount = getLocalisedNumericString(
        transaction.execprice * transaction.amount,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );
      receiveAmount = Number(amount) * Number(transaction.execprice);
    } else {
      sendSecurity = pair?.pair_second;
      receiveSecurity = pair?.pair_first;
      receiveAmount = transaction.amount;

      amount = getLocalisedNumericString(
        receiveAmount * transaction.execprice,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );

      receiveCryptoAmount = getLocalisedNumericString(
        receiveAmount * transaction.execprice,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );
    }
    if (transaction.transactionType === TRANSACTION_API_TYPES.EXCHANGE) {
      sendSecurity = pair?.pair_first;
      receiveSecurity = pair?.pair_second;
      amount = getLocalisedNumericString(transaction.amount, false, getDecimalByFraction(FRACTIONS[sendSecurity]));

      receiveAmount = getLocalisedNumericString(
        transaction.amountCounterparty,
        false,
        getDecimalByFraction(FRACTIONS[receiveSecurity])
      );
    }
  } else if (
    type === TRANSACTION_API_TYPES.ESCROW ||
    type === TRANSACTION_API_TYPES.FINISHED_ESCROW ||
    type === TRANSACTION_API_TYPES.IOI ||
    type === TRANSACTION_API_TYPES.LIMIT
  ) {
    if (!transaction.ismaker) {
      if (transaction.side === 'B') {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        amount = getLocalisedNumericString(
          transaction.execprice * transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      } else {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second || transaction.assetCounterparty;
        amount = getLocalisedNumericString(
          transaction.execqty || transaction.amount,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first || sendSecurity])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice || transaction.amountCounterparty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second || receiveSecurity])
        );
        sendCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      }
    } else {
      if (transaction.side === 'S') {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second;
        amount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        sendCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      } else {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        amount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      }
    }
  } else {
    if (type === TRANSACTION_API_TYPES.SEND) {
      sendSecurity = transaction.security || transaction.crypto.toUpperCase();
      amount = getLocalisedNumericString(
        transaction.execqty || transaction.amount,
        false,
        getDecimalByFraction(FRACTIONS[sendSecurity])
      );
    }
    if (type === TRANSACTION_API_TYPES.RECIEVE) {
      receiveSecurity = transaction.security || transaction.crypto.toUpperCase();
      receiveAmount = getLocalisedNumericString(
        transaction.execqty || transaction.amount,
        false,
        getDecimalByFraction(FRACTIONS[receiveSecurity])
      );
    }
  }
  if (type === PENDING_API_TYPES.REQUEST) {
    if (transaction.ismaker) {
      receiveAmount = getLocalisedNumericString(
        transaction.qty || transaction.execqty,
        false,
        getDecimalByFraction(FRACTIONS[transaction.security])
      );
      receiveSecurity = transaction.security;
    } else {
      sendSecurity = transaction.security;
      amount = getLocalisedNumericString(
        transaction.qty || transaction.execqty || transaction.amount,
        false,
        getDecimalByFraction(FRACTIONS[transaction.security])
      );
    }
  }
  return {
    status,
    amount,
    sendSecurity,
    receiveAmount,
    receiveSecurity,
    sendCryptoAmount,
    receiveCryptoAmount,
    transactionType
  };
};

export const getSeparatedAssetsFromConcatenatedPair = (concatenatedPair, assets) => {
  return assets?.find(asset => asset.security === concatenatedPair);
};

const getUserIdFromTradeRefNo = refno => refno.split(':')[0];

/**
 * This function is used to determine if possible who has executed the transaction.
 * In some cases we do not have the information.
 *
 * @param type {string} - type of transaction
 * @param transaction {any} - transaction object
 * @returns {string} - who executed the transaction
 */
export const getExecutedBy = ({ type, ...transaction }) => {
  if (transaction.userid === '_NEGOT') return 'N/A';

  if (type === TRANSACTION_API_TYPES.EXCHANGE) {
    return transaction.userid;
  } else if (type === TRANSACTION_API_TYPES.ESCROW) {
    return transaction.userid;
  } else if (
    type === TRANSACTION_API_TYPES.FINISHED_ESCROW ||
    type === TRANSACTION_API_TYPES.IOI ||
    type === TRANSACTION_API_TYPES.LIMIT
  ) {
    return transaction.ismaker ? transaction.userid : transaction.counterparty;
  } else if (type === PENDING_API_TYPES.REQUEST) {
    return transaction.userid;
  } else if (type === TRANSACTION_API_TYPES.SEND || type === TRANSACTION_API_TYPES.RECIEVE) {
    return getUserIdFromTradeRefNo(transaction.traderefno);
  } else if (type === TRANSACTION_API_TYPES.STAKING_REWARD) {
    return transaction.userid;
  } else if (
    type === TRANSACTION_API_TYPES.WITHDRAW ||
    type === TRANSACTION_API_TYPES.DEPOSIT ||
    type === TRANSACTION_API_TYPES.STAKING
  ) {
    return transaction.userInfo?.account ?? '-';
  } else {
    return '-';
  }
};

export const formatTransactionsforTables = (transaction, type, assetPairs) => {
  let amount = '';
  let receiveAmount = '';
  let sendSecurity = '';
  let receiveSecurity = '';
  let receiveCryptoAmount = '';
  let sendCryptoAmount = '';
  let transactionType = '';
  let executedBy = getExecutedBy(transaction);

  let pair = undefined;
  try {
    pair = getSideByAssetPair(assetPairs, transaction.security).pair;
  } catch (e) {
    /*ignored*/
  }

  if (type === TRANSACTION_API_TYPES.EXCHANGE) {
    if (isSelling(transaction)) {
      sendSecurity = pair?.pair_first;
      receiveSecurity = pair?.pair_second;
      amount = getLocalisedNumericString(
        transaction.amount ?? transaction.execqty,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_first])
      );
      sendCryptoAmount = getLocalisedNumericString(
        transaction.execprice * transaction.amount,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_first])
      );
      receiveAmount = getLocalisedNumericString(
        Number(amount) * Number(transaction.execprice),
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );
    } else {
      sendSecurity = pair?.pair_second;
      receiveSecurity = pair?.pair_first;
      receiveAmount = getLocalisedNumericString(
        transaction.execqty,

        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_first])
      );
      amount = getLocalisedNumericString(
        Number(receiveAmount) * Number(transaction.execprice),
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );

      receiveCryptoAmount = getLocalisedNumericString(
        receiveAmount * transaction.execprice,
        false,
        getDecimalByFraction(FRACTIONS[pair?.pair_second])
      );
    }
  } else if (
    type === TRANSACTION_API_TYPES.ESCROW ||
    type === TRANSACTION_API_TYPES.FINISHED_ESCROW ||
    type === TRANSACTION_API_TYPES.IOI ||
    type === TRANSACTION_API_TYPES.LIMIT
  ) {
    if (!transaction.ismaker) {
      if (transaction.side === 'B') {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        amount = getLocalisedNumericString(
          transaction.execprice * transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      } else {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second;
        amount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        sendCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      }
    } else {
      if (transaction.side === 'S') {
        sendSecurity = pair?.pair_first;
        receiveSecurity = pair?.pair_second;
        amount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        sendCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      } else {
        sendSecurity = pair?.pair_second;
        receiveSecurity = pair?.pair_first;
        amount = getLocalisedNumericString(
          transaction.execqty * transaction.execprice,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
        receiveAmount = getLocalisedNumericString(
          transaction.execqty,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_first])
        );
        receiveCryptoAmount = getLocalisedNumericString(
          transaction.cryptoValue,
          false,
          getDecimalByFraction(FRACTIONS[pair?.pair_second])
        );
      }
    }
  } else if (
    type === TRANSACTION_API_TYPES.SEND ||
    transaction.type === TRANSACTION_API_TYPES.WITHDRAW ||
    type === TRANSACTION_API_TYPES.RECIEVE ||
    type === TRANSACTION_API_TYPES.DEPOSIT ||
    type === PENDING_API_TYPES.REQUEST
  ) {
    sendSecurity = transaction.security || transaction.crypto.toUpperCase();
    amount = getLocalisedNumericString(
      transaction.execqty || transaction.amount,
      false,
      getDecimalByFraction(FRACTIONS[sendSecurity])
    );
  }

  return {
    amount,
    sendSecurity,
    receiveAmount,
    receiveSecurity,
    sendCryptoAmount,
    receiveCryptoAmount,
    transactionType,
    executedBy
  };
};

/**
 * Checks if the security is an asset pair (consisting of two assets).
 * Assuming that the asset length is minimum of 3 and a maximum of 4 characters.
 *
 * @param security {string} - security name (BTC, BTCUSD, ETHUSDC etc.).
 * @return {boolean} - true if security is a crypto pair.
 */
export const isPair = security => 6 <= security.length && security.length <= 8;

/**
 * Checks if the security is an asset and not an asset pair.
 *
 * @param security {string} - security name (BTC, BTCUSD, ETHUSDC etc.).
 * @return {boolean} - true if security is a cryptocurrency.
 */
export const isAsset = security => !isPair(security);

/**
 * Checks if the security is an asset pair containing the USD asset.
 *
 * @param security {string} - security name (BTC, BTCUSD, ETHUSDC etc.).
 * @return {boolean} - true if security is a pair containing USD.
 */
export const isUSDPair = security => {
  if (!isPair(security)) return false;
  const endsWith = security.endsWith(SECURITY_CODES.USD);
  const startsWith = security.startsWith(SECURITY_CODES.USD);
  const leftover = security.substring(0, security.length - SECURITY_CODES.USD.length);
  const isLeftoverKnownAsset = Object.values(SECURITY_CODES).includes(leftover);

  // If the asset ends with USD in cases like BTCUSD or USDCUSD we can be sure that
  // it is an asset pair containing USD.
  // On the other hand, if the asset starts with USD in cases like USDCBTC or USDBTC
  // we need to check whether the leftover after removing the USD (CBTC and BTC respectively)
  // is a known asset. Only in that case we can be sure.
  return endsWith || (startsWith && isLeftoverKnownAsset);
};

/**
 * Extracts the first asset from the pair and returns it
 *
 * @param pair {string} - pair name (BTCETH, BTCUSD, ETHUSDC etc.).
 * @return {string} - string value of the asset(security) from SECURITY_CODES
 */
export const getFirstAssetFromPair = pair => {
  const security = Object.keys(SECURITY_CODES).filter(
    key => key !== SECURITY_CODES.USD && pair.startsWith(SECURITY_CODES[key])
  );

  if (security.length) {
    return SECURITY_CODES[security[0]];
  }

  return null;
};
