import i18next from 'i18next';
import { Row } from 'react-bootstrap';
import { MdDoneAll } from 'react-icons/md';
import { findFiatCurrencySymbol, getLocalisedNumericString } from '../../../utils/generalUtils';
import { isDollarAsset } from '../../../utils/validators';
import VLButton from '../../Buttons/VLButton';
import './CompleteDetails.scss';

const CompleteDetails = ({
  sendAssetData,
  receiveAssetData,
  exchangePrice,
  receiveAmountWithFee,
  transactionId,
  onBack
}) => {
  return (
    <div className="transaction-success-container">
      <Row className="transaction-success-icon-container success-icon">
        <MdDoneAll size="3em" />
      </Row>
      <Row>
        <p className="ed-pretitle">{i18next.t('exchangeModal.successfullExchange')}</p>
      </Row>
      <Row>
        <h1 className="external-deposit-h1-label">
          {`
            ${findFiatCurrencySymbol(sendAssetData.sendAsset)}
            ${isDollarAsset(sendAssetData.sendAsset) ? getLocalisedNumericString(exchangePrice) : exchangePrice}
            ${sendAssetData.sendAsset}
          `}
        </h1>
      </Row>
      <Row>
        <p className="ed-pretitle">{i18next.t('exchangeModal.for')}</p>
      </Row>
      <Row>
        <h1 className="external-deposit-h1-label">
          {`
            ${findFiatCurrencySymbol(receiveAssetData.receiveAsset)}
            ${receiveAmountWithFee} ${receiveAssetData.receiveAsset}
          `}
        </h1>
      </Row>
      <div className="transaction-success-disclaimer summary-label-text exchange-info-wrapper">
        <p>{i18next.t('exchangeModal.viewDetails')}</p>
        <p>{i18next.t('exchangeModal.transactionid') + ` ${transactionId}`}</p>
      </div>
      <Row className="buttons">
        <VLButton size={'l'} onClick={() => onBack()} text={'Back to Buy/Sell'} />
      </Row>
    </div>
  );
};

export default CompleteDetails;
