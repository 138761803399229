import * as CONSTANTS from '../constants/administration';

export const getListOfFirmsRequest = data => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_REQUEST,
  data
});

export const getListOfFirmsSuccess = data => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_SUCCESS,
  data
});

export const getListOfFirmsError = error => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_ERROR,
  error
});

///////////////////////////////////////////////
//////////////////////////////////////////////

export const createContactRequest = data => ({
  type: CONSTANTS.CREATE_CONTACT_REQUEST,
  data
});
export const createContactSuccess = data => ({
  type: CONSTANTS.CREATE_CONTACT_SUCCESS,
  data
});
export const createContactError = data => ({
  type: CONSTANTS.CREATE_CONTACT_ERROR,
  data
});
export const acceptContactRequest = data => ({
  type: CONSTANTS.ACCEPT_CONTACT_REQUEST,
  data
});
export const acceptContactSuccess = data => ({
  type: CONSTANTS.ACCEPT_CONTACT_SUCCESS,
  data
});
export const acceptContactError = data => ({
  type: CONSTANTS.ACCEPT_CONTACT_ERROR,
  data
});
export const markContactNotificationsAsReadRequest = data => ({
  type: CONSTANTS.MARK_CONTACT_NOTIFICATIONS_AS_READ_REQUEST,
  data
});
export const markContactNotificationsAsReadSuccess = data => ({
  type: CONSTANTS.MARK_CONTACT_NOTIFICATIONS_AS_READ_SUCCESS,
  data
});
export const markContactNotificationsAsReadError = data => ({
  type: CONSTANTS.MARK_CONTACT_NOTIFICATIONS_AS_READ_ERROR,
  data
});
export const declineContactRequest = data => ({
  type: CONSTANTS.DECLINE_CONTACT_REQUEST,
  data
});
export const declineContactSuccess = data => ({
  type: CONSTANTS.DECLINE_CONTACT_SUCCESS,
  data
});
export const declineContactError = data => ({
  type: CONSTANTS.DECLINE_CONTACT_ERROR,
  data
});

export const deleteContactRequest = data => ({
  type: CONSTANTS.DELETE_CONTACT_REQUEST,
  data
});
export const deleteContactSuccess = data => ({
  type: CONSTANTS.DELETE_CONTACT_SUCCESS,
  data
});
export const deleteContactError = data => ({
  type: CONSTANTS.DELETE_CONTACT_ERROR,
  data
});

export const cancelContactRequest = data => ({
  type: CONSTANTS.CANCEL_CONTACT_REQUEST,
  data
});
export const cancelContactSuccess = data => ({
  type: CONSTANTS.CANCEL_CONTACT_SUCCESS,
  data
});
export const cancelContactError = data => ({
  type: CONSTANTS.CANCEL_CONTACT_ERROR,
  data
});

export const getListOfFirmAccounts = data => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS,
  data
});

export const getListOfFirmAccountsSuccess = data => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS_SUCCESS,
  data
});

export const getListOfFirmAccountsError = error => ({
  type: CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const createFirmRequest = data => ({
  type: CONSTANTS.CREATE_FIRM_REQUEST,
  data
});

export const createFirmSuccess = data => ({
  type: CONSTANTS.CREATE_FIRM_SUCCESS,
  data
});

export const createFirmError = error => ({
  type: CONSTANTS.CREATE_FIRM_ERROR,
  error
});

export const deleteFirmRequest = data => ({
  type: CONSTANTS.DELETE_FIRM_REQUEST,
  data
});

export const deleteFirmSuccess = data => ({
  type: CONSTANTS.DELETE_FIRM_REQUEST_SUCCESS,
  data
});

export const deleteFirmError = error => ({
  type: CONSTANTS.DELETE_FIRM_REQUEST_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getFirmDetailsRequest = data => ({
  type: CONSTANTS.GET_FIRM_DETAILS_REQUEST,
  data
});

export const getFirmDetailsSuccess = data => ({
  type: CONSTANTS.GET_FIRM_DETAILS_SUCCESS,
  data
});

export const getFirmDetailsError = error => ({
  type: CONSTANTS.GET_FIRM_DETAILS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getFirmAdminAccountsRequest = data => ({
  type: CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_REQUEST,
  data
});

export const getFirmAdminAccountsSuccess = data => ({
  type: CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_SUCCESS,
  data
});

export const getFirmAdminAccountsError = error => ({
  type: CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getFirmUsersRequest = data => ({
  type: CONSTANTS.GET_FIRM_USERS_REQUEST,
  data
});

export const getFirmUsersSuccess = data => ({
  type: CONSTANTS.GET_FIRM_USERS_SUCCESS,
  data
});

export const getFirmUsersError = error => ({
  type: CONSTANTS.GET_FIRM_USERS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getUserSuggestionsRequest = data => ({
  type: CONSTANTS.GET_USER_SUGGESTIONS_REQUEST,
  data
});

export const getUserSuggestionsSuccess = data => ({
  type: CONSTANTS.GET_USER_SUGGESTIONS_SUCCESS,
  data
});

export const getUserSuggestionsError = error => ({
  type: CONSTANTS.GET_USER_SUGGESTIONS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getAccountSuggestionsRequest = data => ({
  type: CONSTANTS.GET_ACCOUNT_SUGGESTIONS_REQUEST,
  data
});

export const getAccountSuggestionsSuccess = data => ({
  type: CONSTANTS.GET_ACCOUNT_SUGGESTIONS_SUCCESS,
  data
});

export const getAccountSuggestionsError = error => ({
  type: CONSTANTS.GET_ACCOUNT_SUGGESTIONS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getUserDetailsRequest = data => ({
  type: CONSTANTS.GET_USER_DETAILS_REQUEST,
  data
});

export const getUserDetailsSuccess = data => ({
  type: CONSTANTS.GET_USER_DETAILS_SUCCESS,
  data
});

export const getUserDetailsError = error => ({
  type: CONSTANTS.GET_USER_DETAILS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const createAccountRequest = (data, firm) => ({
  type: CONSTANTS.CREATE_ACCOUNT_REQUEST,
  data,
  firm
});

export const createAccountSuccess = data => ({
  type: CONSTANTS.CREATE_ACCOUNT_SUCCESS,
  data
});

export const createAccountError = error => ({
  type: CONSTANTS.CREATE_ACCOUNT_ERROR,
  error
});

export const deleteAccountRequest = (data, firm) => ({
  type: CONSTANTS.DELETE_ACCOUNT_REQUEST,
  data,
  firm
});

export const deleteAccountSuccess = data => ({
  type: CONSTANTS.DELETE_ACCOUNT_REQUEST_SUCCESS,
  data
});

export const deleteAccountError = error => ({
  type: CONSTANTS.DELETE_ACCOUNT_REQUEST_ERROR,
  error
});

export const registerWalletForAccount = data => ({
  type: CONSTANTS.REGISTER_WALLET_FOR_ACCOUNT,
  data
});
export const registerWalletForAccountSuccess = data => ({
  type: CONSTANTS.REGISTER_WALLET_FOR_ACCOUNT_SUCCESS,
  data
});
export const registerWalletForAccountError = error => ({
  type: CONSTANTS.REGISTER_WALLET_FOR_ACCOUNT_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const createAdminAccountRequest = (data, firm) => ({
  type: CONSTANTS.CREATE_ADMIN_ACCOUNT_REQUEST,
  data,
  firm
});

export const createAdminAccountSuccess = data => ({
  type: CONSTANTS.CREATE_ADMIN_ACCOUNT_SUCCESS,
  data
});

export const createAdminAccountError = error => ({
  type: CONSTANTS.CREATE_ADMIN_ACCOUNT_ERROR,
  error
});

export const deleteAdminAccountRequest = (data, firm) => ({
  type: CONSTANTS.DELETE_ADMIN_ACCOUNT_REQUEST,
  data,
  firm
});

export const deleteAdminAccountSuccess = data => ({
  type: CONSTANTS.DELETE_ADMIN_ACCOUNT_SUCCESS,
  data
});

export const deleteAdminAccountError = error => ({
  type: CONSTANTS.DELETE_ADMIN_ACCOUNT_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const clearAllFirmInfo = () => ({
  type: CONSTANTS.CLEAR_ALL_FIRM_INFO
});

export const resetCreateContactResponse = () => ({
  type: CONSTANTS.RESET_CREATE_CONTACT_RESPONSE
});
export const resetDeleteContactResponse = () => ({
  type: CONSTANTS.RESET_DELETE_CONTACT_RESPONSE
});
export const resetAcceptContactResponse = () => ({
  type: CONSTANTS.RESET_ACCEPT_CONTACT_RESPONSE
});
export const resetDeclineContactResponse = () => ({
  type: CONSTANTS.RESET_DECLINE_CONTACT_RESPONSE
});
export const resetCancelContactResponse = () => ({
  type: CONSTANTS.RESET_CANCEL_CONTACT_RESPONSE
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getOnboardingUsersRequest = data => ({
  type: CONSTANTS.GET_ONBOARDING_USERS_REQUEST,
  data
});

export const getOnboardingUsersSuccess = data => ({
  type: CONSTANTS.GET_ONBOARDING_USERS_SUCCESS,
  data
});
export const getPendingOnboardingUsersRequest = data => ({
  type: CONSTANTS.GET_PENDING_ONBOARDING_USERS_REQUEST,
  data
});
export const getPendingOnboardingUsersSuccess = data => ({
  type: CONSTANTS.GET_PENDING_ONBOARDING_USERS_SUCCESS,
  data
});
export const getPendingOnboardingUsersError = error => ({
  type: CONSTANTS.GET_PENDING_ONBOARDING_USERS_ERROR,
  error
});

export const getOnboardingUsersError = error => ({
  type: CONSTANTS.GET_ONBOARDING_USERS_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const acceptOnboardingUserRequest = data => ({
  type: CONSTANTS.ACCEPT_ONBOARDING_USER_REQUEST,
  data
});

export const acceptOnboardingUserSuccess = data => ({
  type: CONSTANTS.ACCEPT_ONBOARDING_USER_SUCCESS,
  data
});

export const declineOnboardingUserRequest = data => ({
  type: CONSTANTS.DECLINE_ONBOARDING_USER_REQUEST,
  data
});

export const declineOnboardingUserSuccess = data => ({
  type: CONSTANTS.DECLINE_ONBOARDING_USER_SUCCESS,
  data
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const getAccountDetailsRequest = data => ({
  type: CONSTANTS.GET_ACCOUNT_DETAILS_REQUEST,
  data
});

export const getAccountDetailsSuccess = data => ({
  type: CONSTANTS.GET_ACCOUNT_DETAILS_SUCCESS,
  data
});

export const getAccountDetailsError = error => ({
  type: CONSTANTS.GET_ACCOUNT_DETAILS_ERROR,
  error
});

export const getContactsRequest = data => ({
  type: CONSTANTS.GET_CONTACTS_REQUEST,
  data
});

export const getContactsSuccess = data => ({
  type: CONSTANTS.GET_CONTACTS_SUCCESS,
  data
});

export const getContactsError = error => ({
  type: CONSTANTS.GET_CONTACTS_ERROR,
  error
});
////////////////////////////////////////////////////////////////////////////////
export const getContactsRequestsRequest = data => ({
  type: CONSTANTS.GET_CONTACTS_REQUESTS_REQUEST,
  data
});

export const getContactsRequestsSuccess = data => ({
  type: CONSTANTS.GET_CONTACTS_REQUESTS_SUCCESS,
  data
});

export const getContactsRequestsError = error => ({
  type: CONSTANTS.GET_CONTACTS_REQUESTS_ERROR,
  error
});
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const uploadBtaReportRequest = data => ({
  type: CONSTANTS.UPLOAD_BTA_REPORT_REQUEST,
  data
});

export const uploadBtaReportSuccess = data => ({
  type: CONSTANTS.UPLOAD_BTA_REPORT_SUCCESS,
  data
});

export const uploadBtaReportError = error => ({
  type: CONSTANTS.UPLOAD_BTA_REPORT_ERROR,
  error
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const deleteBtaReportRequest = data => ({
  type: CONSTANTS.DELETE_BTA_REPORT_REQUEST,
  data
});

export const deleteBtaReportSuccess = data => ({
  type: CONSTANTS.DELETE_BTA_REPORT_SUCCESS,
  data
});

export const deleteBtaReportError = error => ({
  type: CONSTANTS.DELETE_BTA_REPORT_ERROR,
  error
});

/////////////////////////////////////////////////////////////////////////////

export const setCurrentAccountNumber = data => ({
  type: CONSTANTS.SET_CURRENT_ACCOUNT_NUMBER,
  data
});

export const setCurrentAccountsFirm = data => ({
  type: CONSTANTS.SET_CURRENT_ACCOUNTS_FIRM,
  data
});
