import { Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import VerificationInput from './VerificationInput';
import './VerifyTwoFactorModal.scss';
import VLButton from '../../Buttons/VLButton';

const VerifyTwoFactorModal = ({ isOpen, hasError, onClose, onVerify }) => {
  const [value, setValue] = useState('');
  const handleConfirm = () => {
    onVerify(value);
  };

  const handleInputChange = value => {
    setValue(value);
  };

  return (
    <Modal className="common-modal" centered show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{'Enter Authenticator App Generated Code'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="verify-modal-row">
          <div className="verify-modal-input-wrapper">
            <VerificationInput onChange={handleInputChange} hasError={hasError} />
          </div>
          <VLButton
            disabled={!value || value.length !== 6}
            onClick={handleConfirm}
            width="100%"
            text={'Verify'}
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyTwoFactorModal;
