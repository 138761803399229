import * as React from 'react';
import { SHOW_ALL_ACCOUNTS_FLAG } from '../enums/validation';

let AccountFilterContext = React.createContext();

const getSelectedAccountId = accountFilter => {
  if (accountFilter === SHOW_ALL_ACCOUNTS_FLAG) return null;

  return accountFilter || null;
};

function AccountFilterProvider(props) {
  const [accountFilter, setAccountFilter] = React.useState('');
  const [firmFilter, setFirmFilter] = React.useState('');

  let value = {
    accountFilter,
    setAccountFilter,
    firmFilter,
    setFirmFilter
  };

  return <AccountFilterContext.Provider value={value}>{props.children}</AccountFilterContext.Provider>;
}

let AccountFilterConsumer = AccountFilterContext.Consumer;

export { AccountFilterContext, AccountFilterProvider, AccountFilterConsumer, getSelectedAccountId };
