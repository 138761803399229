import React, { useEffect, useState } from 'react';
import '../TradeOrder/TradeOrder.scss';
import './OrderBlotter.scss';
import TabGroup, { useTabs } from '../TradeOrder/TabGroup';
import OpenOrdersTable from './OpenOrdersTable';
import OrderHistoryTable from './OrderHistoryTable/OrderHistoryTable';
import CanceledOrdersTable from './CanceledOrdersTable';

import IOIsTable from './IOIsTable';
import { MODAL_CODES } from '../../enums/validation';
import IOIModal from '../modals/IOIModal';
const tabs = [
  { id: 1, label: 'Open orders' },
  { id: 2 },
  { id: 3, label: 'Order history' },

  { id: 4, label: 'Canceled orders' }
];

const OrderBlotter = ({
  getOrderHistory,
  orderHistory,
  refreshOrderHistory,
  user,
  getUserProfile,
  ioiInbound,
  getTradingAssets,
  toggleModal,
  displayModal,
  resetTransactionHash,
  assets
}) => {
  const { activeTab, handleTabClick } = useTabs(tabs, tabs[0].id);
  const [ioi, setIOI] = useState(null);
  const accountNumber = user.restricted_attr?.access_list[0].account;
  useEffect(() => {
    setTimeout(() => {
      getUserProfile();
      getTradingAssets();
      getOrderHistory({ userId: accountNumber });
    }, 4000);
  }, [refreshOrderHistory, accountNumber, getOrderHistory, getTradingAssets, getUserProfile]);
  const toggleIoIModal = (transaction = null) => {
    if (!transaction) {
      toggleModal(null);
    }
    setIOI(transaction);
    toggleModal(transaction ? MODAL_CODES.IOI : null);
    resetTransactionHash();
  };
  const renderOrder = activeTab => {
    tabs[1].label = (
      <React.Fragment>
        <span>IOIs</span>
        {ioiInbound.length > 0 && <div class="badge-danger ms-2 notification">{ioiInbound.length}</div>}
      </React.Fragment>
    );
    if (activeTab === 1) return <OpenOrdersTable />;
    if (activeTab === 2) {
      return <IOIsTable toggleTransactionDetails={toggleIoIModal} />;
    }

    if (activeTab === 3) return <OrderHistoryTable orderHistory={orderHistory.data} />;

    if (activeTab === 4) return <CanceledOrdersTable />;
    return 'Unknown order type';
  };
  return (
    <div className="trade-order">
      <div className="tab-group-wrapper">
        <TabGroup onTabClick={handleTabClick} activeTab={activeTab} tabs={tabs} />
        <div className="inline-div">&nbsp;</div>
      </div>

      {renderOrder(activeTab)}
      {displayModal === MODAL_CODES.IOI && <IOIModal assets={assets} transaction={ioi} toggleModal={toggleIoIModal} />}
    </div>
  );
};
export default OrderBlotter;
