import './AdministratorAccounts.scss';
import '../DatePicker/DatePicker.scss';
import { Col, Container, Row } from 'react-bootstrap';

import React, { PureComponent } from 'react';
import AccountsTableAdmin from '../AccountsTableAdmin';
import Card from '../../containers/Card';

class AdministratorAccounts extends PureComponent {
  componentDidMount() {
    this.props.getOnboardingUsersRequest();

    this.props.getPendingOnboardingUsersRequest({
      pendingOnboardingUsers: true
    });
  }

  triggerFilter = (data, isPendingAccounts) => {
    const filterObject = {
      userId: data.userId.value,
      name: data.fullName.value,
      type: data.type.value,
      btaStatus: data.btaStatus.value,
      status: isPendingAccounts ? null : data.status.value,
      from: data.date.value[0] && data.date.value[0],
      to: data.date.value[1] && data.date.value[1]
    };

    if (isPendingAccounts) {
      this.props.getPendingOnboardingUsersRequest({
        pendingOnboardingUsers: true,
        ...filterObject
      });
    } else {
      this.props.getOnboardingUsersRequest(filterObject);
    }
  };
  renderTables = () => {
    const pending = this.props.pendingOnboardingUsers?.data;
    const nonPending = this.props.onboardingUsers?.data;
    return (
      <>
        <AccountsTableAdmin
          pendingAccounts={true}
          title="Pending account users"
          accounts={pending}
          onReload={this.triggerFilter}
          triggerFilters={this.triggerFilter}
          isLoading={this.props.pendingOnboardingUsers?.loading}
        />

        <AccountsTableAdmin
          pendingAccounts={false}
          title="Reviewed account users"
          accounts={nonPending}
          onReload={this.triggerFilter}
          triggerFilters={this.triggerFilter}
          isLoading={this.props.onboardingUsers?.loading}
        />
      </>
    );
  };

  render() {
    return (
      <Card>
        <Col className="dashboard-col chassis-interior-content">
          <Row noGutters>
            <Container fluid>{this.renderTables()}</Container>
          </Row>
        </Col>
      </Card>
    );
  }
}

export default AdministratorAccounts;
