import { getAccountDetailsRequest } from '../../redux/actions/administration';
import { getTradingAssets } from '../../redux/actions/transactions';
import { connect } from 'react-redux';
import AccountDetails from './AccountDetails';
import './AccountDetails.scss';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  accountDetails: state.administration.accountDetails,
  assets: state.transactions.tradingAssets,
  websocket: state.websocket.websocket,
  socketConnected: state.websocket.socketConnected,
  cryptoRates: state.transactions.cryptoRates,
  users: state.settings.users
});

const mapDispatchToProps = {
  getAccountDetailsRequest,
  getTradingAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountDetails));
