import React from 'react';
import './VLButton.scss';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const VLButton = ({
  text,
  onClick,
  size,
  width,
  rightIcon,
  leftIcon,
  variant,
  disabled,
  visibility
}) => {
  return (
    <Button
      className={`vlbtn-primary__${size} ${variant ? 'vlbtn-' + variant : ''}`}
      onClick={onClick}
      style={{
        width: width,
        visibility: visibility,
        opacity: disabled && '0.5'
      }}
      disabled={disabled}
    >
      {' '}
      {leftIcon}
      {text}
      {rightIcon}
    </Button>
  );
};

VLButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['m', 'l']),
  width: PropTypes.string,
  variant: PropTypes.oneOf([
    null,
    'outline',
    'success',
    'danger',
    'success-outline',
    'danger-outline',
    'clear'
  ]),
  disabled: PropTypes.bool,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element
};
VLButton.defaultProps = {
  size: 'l'
};

export default VLButton;
