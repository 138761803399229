export const buildTransferPayload = (data, uniqueID) => {
  let payloadObject = {
    type: 'transfer',
    toaccount: data.recipient,
    fromaccount: data.source,
    security: data.asset,
    amount: data.amount,
    negotiate: 'true',
    CLT_REQID: uniqueID,
    clientid: `${data.publicNote || '-'}|${data.privateNote || '-'}`,
    side: 'B',
    comment: data.publicNote ? data.publicNote : ''
  };
  if (data.effectiveTime) {
    payloadObject.effectivetime = data.effectiveTime;
  }
  if (data.repeat) {
    payloadObject.timefrequency = data.repeatFrequency || null;
    if (data.endDate) {
      payloadObject.endtime = data.endDate;
    } else {
      payloadObject.repeatingnum = 1;
    }
  }
  return payloadObject;
};

export const buildRequestPayload = (data, uniqueID) => ({
  type: 'addorder',
  brokers: data.recipient,
  userid: data.source,
  security: data.asset,
  qty: data.amount,
  negotiate: 'true',
  price: '1',
  CLT_REQID: uniqueID,
  ordertype: 'LMT',
  clientorderid: data.clientorderid,
  side: 'B',
  comment: data.publicNote ? data.publicNote : ''
});

export const buildDepositPayload = (data, uniqueID) => ({
  type: 'deposit',
  userid: data.account,
  security: data.symbol,
  amount: data.amount,
  CLT_REQID: uniqueID,
  side: 'B'
});

export const buildRedeemPayload = (data, uniqueID) => ({
  type: 'withdraw',
  userid: data.account,
  security: data.symbol,
  amount: data.amount,
  CLT_REQID: uniqueID,
  side: 'B',
  beneficiary_entity: true
});

export const buildEscrowPayload = (data, uniqueID) => {
  let tif = 'GTC';
  if (data.exptime) {
    tif = 'GTT';
  }
  return {
    type: 'addorder',
    brokers: data.recipient,
    userid: data.source,
    qty: data.side === 'B' ? data.recipientAmount : data.sourceAmount,
    negotiate: 'true',
    price:
      data.side === 'B'
        ? parseFloat(parseFloat(data.sourceAmount) / parseFloat(data.recipientAmount)).toString()
        : parseFloat(parseFloat(data.recipientAmount) / parseFloat(data.sourceAmount)).toString(),
    CLT_REQID: uniqueID,
    ordertype: 'LMT',
    side: data.side, // buying: 'B', selling: 'S'
    security: 'n/a',
    alias: `${data.recipientAsset}-${data.sourceAsset}`,
    clientorderid: data.clientorderid,
    nonnegotiable: true,
    tif: tif,
    exptime: data.exptime,
    comment: data.publicNote ? data.publicNote : ''
  };
};

export const buildIOIPayload = (data, uniqueID) => {
  let tif = 'GTC';
  if (data.exptime) {
    tif = 'GTT';
  }
  const payload = {
    type: 'addorder',
    CLT_REQID: uniqueID,
    brokers: data.targetBrokers,
    firm: data.firm,
    userid: data.userId,
    qty: data.quantity,
    price: data.price,
    ordertype: 'LMT',
    security: data.pair,
    side: data.side,
    negotiate: 'true',
    tif: tif,
    exptime: data?.exptime
  };

  // TODO: Legacy, should be removed when IOI feature is removed from dashboard
  if (data.recipientAccount) {
    payload.clientorderid = data.userId;
    payload.brokers = data.recipientAccount;
  }

  return payload;
};

export const buildIOIV2Payload = (data, uniqueID) => {
  return {
    type: 'addorder',
    CLT_REQID: uniqueID,
    userid: data.account,
    qty: data.quantity,
    price: data.price,
    security: data.pair,
    side: data.side,
    firm: data.firm,
    tif: data.tif,
    exptime: data.expDate,
    ioi: true
  };
};

export const buildCounterOrderPayload = (data, uniqueID) => {
  let tif = 'GTC';
  if (data.exptime) {
    tif = 'GTT';
  }
  return {
    type: 'counterorder',
    CLT_REQID: uniqueID,
    userid: data.userId,
    refno: data.refno,
    price: data.price,
    side: data.side,
    qty: data.qty,
    security: data.security,
    tif,
    exptime: data.exptime
  };
};

export const buildToBankPayment = (data, uniqueID) => ({
  type: 'transfer',
  toaccount: data.recipient,
  fromaccount: data.source,
  security: data.asset,
  amount: data.amount,
  CLT_REQID: uniqueID,
  side: 'B'
});

export const buildToNetworkPayment = (data, uniqueID) => ({
  type: 'transfer',
  toaccount: data.recipient,
  fromaccount: data.source,
  security: data.asset,
  amount: data.amount,
  CLT_REQID: uniqueID,
  side: 'B'
});

export const buildAcceptPayment = (data, uniqueID) => ({
  type: 'executeorder',
  security: data.security,
  refno: data.refno,
  qty: data.qty,
  price: data.price,
  side: data.side,
  CLT_REQID: uniqueID,
  userid: data.userid
});

export const buildRejectPayment = (data, uniqueID) => ({
  type: data.type,
  security: data.security,
  refno: data.refno,
  CLT_REQID: uniqueID,
  userid: data.userid
});

export const buildQueryPos = (data, uniqueID) => ({
  type: 'querypos',
  userid: data.userid,
  firm: data.firm,
  CLT_REQID: uniqueID
});

export const buildBalanceQueryPos = (data, uniqueID) => ({
  type: 'querybalancepos',
  account: data.account,
  firm: data.firm,
  CLT_REQID: uniqueID
});

export const buildAssetWalletAddress = (data, uniqueID) => ({
  type: 'querybalanceexternaladd',
  security: data.security,
  firm: data.firm,
  account: data.account,
  CLT_REQID: uniqueID
});

export const buildFeeForAssetPayload = (data, uniqueID) => ({
  type: 'querybalancefees',
  firm: data.firm,
  account: data.account,
  CLT_REQID: uniqueID
});

export const buildBalanceWithdrawalPayload = (data, uniqueID) => ({
  type: 'balancewithdraw',
  account: data.account,
  firm: data.firm,
  security: data.security,
  amount: data.amount,
  externaccount: data.walletID,
  isexternal: true,
  CLT_REQID: uniqueID
  // benEnt: data.benEnt
});

export const buildExchangePayload = (data, uniqueID) => {
  if (data.side === 'B') {
    return {
      type: 'addorder',

      userid: data.account,

      amount: data.amount,

      CLT_REQID: uniqueID,
      ordertype: 'MKT',
      side: 'B', // buying: 'B', selling: 'S'

      security: data.security
    };
  } else {
    return {
      type: 'addorder',

      userid: data.account,

      qty: data.amount,

      CLT_REQID: uniqueID,
      ordertype: 'MKT',
      side: 'S', // buying: 'B', selling: 'S'

      security: data.security
    };
  }
};

export const buildTransactionHashPayload = (data, uniqueID) => ({
  type: 'querybalancetran',
  security: data.security,
  blockhash: data.blockhash,
  account: data.account,
  firm: data.firm,
  CLT_REQID: uniqueID
});

export const buildUpdateRiskPayload = (data, uniqueID) => ({
  ...data.dataObject,
  type: 'account_update',
  CLT_REQID: uniqueID,
  firm: data.firm,
  trader: data.account
});

export const buildAddAlert = (data, uniqueID) => ({
  type: 'addalert',
  CLT_REQID: uniqueID,
  firm: data.firm,
  trader: data.account,
  errordetails: data.errordetails
});

export const buildQueryAlerts = (data, uniqueID) => ({
  type: 'queryalerts',
  filter: {
    account: data.account
  },
  CLT_REQID: uniqueID
});

export const buildQueryRiskPayload = (data, uniqueID) => ({
  type: 'risksetting_query',
  CLT_REQID: uniqueID,
  firm: data.firm,
  trader: data.account
});
export const buildQueryIfUserExists = (data, uniqueID) => ({
  type: 'queryexistingusers',
  CLT_REQID: uniqueID,
  userid: data.email
});

// TRADE

export const buildLimitOrderPayload = (data, uniqueID) => {
  const payload = {
    type: 'addorder',
    CLT_REQID: uniqueID,
    ordertype: 'LMT',
    firm: data.firm,
    userid: data.account,
    qty: data.quantity,
    price: data.price,
    security: data.pair,
    side: data.side,
    tif: data.tif,
    exptime: data.expDate,
    wash_check: true
  };

  if (data.stopPrice) {
    payload.stoptype = 'STOP';
    payload.stopprice = data.stopPrice;
  }

  if (data.icebergQuantity) {
    payload.displayqty = data.icebergQuantity;
  }

  if (data.postOnly) {
    payload.alo = true;
  }

  return payload;
};

export const buildMarketOrderPayload = (data, uniqueID) => {
  const payload = {
    type: 'addorder',
    CLT_REQID: uniqueID,
    ordertype: 'MKT',
    firm: data.firm,
    userid: data.account,
    security: data.pair,
    side: data.side,
    tif: data.tif,
    exptime: data.expdate,
    wash_check: true
  };

  if (data.side === 'B') {
    payload.amount = data.total;
  } else {
    payload.qty = data.amount;
  }

  return payload;
};

export const isWebsocketReady = websocket => websocket?.readyState === 1;
