import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormControl } from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import { toast } from 'react-toastify';
import VLButton from '../../components/Buttons/VLButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import VLInput from '../../components/VLInput/VLInput';
import VLSelect from '../../components/VLSelect';
import { isValidEmailFormat } from '../../utils/validators';
import './Support.scss';
import { letsGetStartedOptions } from './supportData';

const initialState = {
  letsGetStarted: null,
  isLoading: false,
  isSubmitted: false,
  error: ''
};

const Support = ({ contactSupportRequest, history }) => {
  const [formState, setFormState] = useState(initialState);

  // We want to reset the form when first select's value is changed
  useEffect(() => {
    setFormState({ ...initialState, letsGetStarted: formState.letsGetStarted });
  }, [formState.letsGetStarted]);

  const handleSelectChange = (name, value) => {
    setFormState(s => ({
      ...s,
      [name]: value
    }));
  };

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      error: {
        ...formState.error,
        [e.currentTarget.name]: ''
      }
    });
  };

  const isFormValid = () => {
    let error = {};

    if (!isValidEmailFormat(formState['email'])) {
      error = {
        ...error,
        email: 'Please enter valid email'
      };
    }

    if (!formState['subject']) {
      error = {
        ...error,
        subject: 'Required'
      };
    }

    if (!formState['description']) {
      error = {
        ...error,
        description: 'Required'
      };
    }

    setFormState({
      ...formState,
      error
    });

    return Object.keys(error).length === 0;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }
    const playload = {
      category: formState.letsGetStarted.value,
      issue: formState.issue.value,
      email: formState.email,
      subject: formState.subject,
      description: formState.description
    };

    contactSupportRequest({
      data: playload,
      successCallback: () => {
        toast.success('Submitted successfully');
        setFormState({
          ...formState,
          isSubmitted: true
        });
      },
      errorCallback: error => {
        toast.error(error);
        setFormState({
          ...formState,
          isLoading: false
        });
      }
    });

    setFormState({
      ...formState,
      isLoading: true
    });
  };

  const areInputsVisible = formState['issue'];
  const isSubmitDisabled = !areInputsVisible || formState.isSubmitted || formState.isLoading;

  return (
    <Container fluid className="contact-support">
      <Row>
        <Col xs lg="2">
          <div
            className="back-link-wrapper"
            onClick={() => {
              history.goBack();
            }}
          >
            <MdKeyboardBackspace />
            <span>Back</span>
          </div>
        </Col>
        <Col>
          <h3 className="center-align">Contact support</h3>
        </Col>
        <Col xs lg="2"></Col>
      </Row>
      <Row className="narrow">
        <Col>
          <form>
            <p id="letsGetStarted" className="system-form-control-placeholder">
              Let's get started
            </p>
            <div className="sfcb-transparent">
              <VLSelect
                aria-labelledby="letsGetStarted"
                placeholder={'Please select'}
                name="letsGetStarted"
                options={letsGetStartedOptions}
                onChange={value => handleSelectChange('letsGetStarted', value)}
                value={formState.letsGetStarted}
                isOptionDisabled={false}
              />
            </div>

            {formState['letsGetStarted']?.subOptions ? (
              <>
                <p id="issue" className="system-form-control-placeholder">
                  What is your issue about?
                </p>
                <div className="sfcb-transparent">
                  <VLSelect
                    aria-labelledby="issue"
                    placeholder={'Please select'}
                    name="issue"
                    options={formState['letsGetStarted'].subOptions}
                    onChange={value => handleSelectChange('issue', value)}
                    value={formState['issue'] || null}
                    isOptionDisabled={false}
                  />
                </div>
              </>
            ) : null}
            {areInputsVisible ? (
              <>
                <div>
                  <p className="system-form-control-placeholder">Email *</p>
                  <div className="system-form-control-box">
                    <VLInput
                      type="email"
                      name="email"
                      placeholder="Please enter email"
                      onChange={handleChange}
                      value={formState['email'] || ''}
                      maxLength={200}
                      autoComplete={'off'}
                      size="s"
                      error={formState.error?.email}
                    />
                    {formState.error?.email ? (
                      <span className="system-form-control-error-text">{formState.error?.email}</span>
                    ) : null}
                  </div>
                </div>
                <div>
                  <p className="system-form-control-placeholder">Subject *</p>
                  <div className="system-form-control-box">
                    <VLInput
                      type="text"
                      name="subject"
                      placeholder="Please enter subject"
                      value={formState['subject'] || ''}
                      onChange={handleChange}
                      maxLength={200}
                      autoComplete={'off'}
                      size="s"
                      error={formState.error?.subject}
                    />
                    {formState.error?.subject ? (
                      <div class="system-form-control-box-error-text">
                        <span class="system-form-control-error-text">{formState.error?.subject}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <p className="system-form-control-placeholder">Description *</p>
                  <div style={{ height: 'auto' }} className="system-form-control-box">
                    <FormControl
                      as="textarea"
                      rows={2}
                      name="description"
                      resize="none"
                      className={`system-form-control no-resize ${formState.error?.description ? 'error' : ''}`}
                      placeholder="Please enter description"
                      value={formState['description'] || ''}
                      onChange={handleChange}
                      maxLength={400}
                      autoComplete={'off'}
                    />
                    {formState.error?.description ? (
                      <span className="system-form-control-error-text">{formState.error?.description}</span>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </form>
          {formState.isLoading ? <LoadingIndicator /> : null}
          {formState.isSubmitted ? (
            <GeneralConfirmModal
              title={'Support Case Created'}
              text="The details about your issue have been submitted successfully. The support team will contact you via email within 1-2 business days regarding this matter."
              onCancel={() => {
                setFormState(initialState);
              }}
              isOpen={true}
            />
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: 'center', display: 'flex' }}>
          <VLButton disabled={isSubmitDisabled} onClick={handleSubmit} text="Submit" />
        </Col>
      </Row>
    </Container>
  );
};

export default Support;
