import CryptoAssetDetails from './CryptoAssetDetails';

import { connect } from 'react-redux';
import { fetchChartData, fetch24hData } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  chartData: state.trade.chartData,
  marketData24: state.trade.marketData24,
  userData: state.login.userData,
  accounts: state.transactions.accounts,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  fetchChartData,
  fetch24hData
};

export default connect(mapStateToProps, mapDispatchToProps)(CryptoAssetDetails);
