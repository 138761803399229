import React, { useState } from 'react';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { Row, Col, Table, Form } from 'react-bootstrap';
import i18next from 'i18next';
import { formatDate, getRoleDisplayName } from '../../../utils/generalUtils';
import { BsPlus } from 'react-icons/bs';
import CssFilterTransition from '../../../components/CssFilterTransition';
import {
  getTimeLock,
  hasApproveRights,
  hasTimeLock,
  hasTransactRights,
  hasViewRights
} from '../../../utils/roleCheckers';
import { AUTHORIZATION_KEYS } from '../../../enums/roles';
import TimeLockModal from '../../../components/modals/TimeLockModal';
import { TiDelete } from 'react-icons/ti';
import GeneralConfirmModal from '../../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import { filterByEmail, filterByName } from '../../../utils/user';
import SearchUsersModal from '../../../components/modals/SearchUsersModal';
import { useAccountPermissions } from '../useAccountPermissions';
import './AccountUsers.scss';
import VLButton from '../../../components/Buttons/VLButton';
import VLIconButton from '../../../components/Buttons/VLIconButton';
import { FaFilter } from 'react-icons/fa';
import { AiOutlineClockCircle } from 'react-icons/ai';
import Card from '../../Card';
import { IoMdAdd } from 'react-icons/io';
import VLInput from '../../../components/VLInput';

const AccountUsers = ({ account, users, onChange, updatePermissions, history, canEditUsers }) => {
  const {
    timelockAccessData,
    removeAccessData,
    handleAccessSwitch,
    handleAddViewPermission,
    handleRemoveAllPermissions,
    setTimelockAccessData,
    removeTimeLock,
    setRemoveAccessData,
    updateTimeLock
  } = useAccountPermissions({
    onPermissionsUpdated: onChange,
    updatePermissionsFn: updatePermissions
  });
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const clearFilters = () => {
    setFilters({});
  };

  const getFilteredUsers = users => {
    let filteredUsers = [...users];

    if (filters.nameSearch) {
      filteredUsers = filterByName(filteredUsers, filters.nameSearch);
    }
    if (filters.emailSearch) {
      filteredUsers = filterByEmail(filteredUsers, filters.emailSearch);
    }

    return filteredUsers;
  };

  const handleSearchFilter = e => {
    setFilters({
      [e.target.name]: e.target.value
    });
  };

  const resetConfirmation = () => {
    setRemoveAccessData(null);
  };

  const redirectToUserDetails = userId => {
    history.push(`/users/${userId}`);
  };

  const renderHeaderBar = () => {
    return (
      <Row className="user-control-container transactions-filters-container row no-gutters">
        <h4 className="dashboard-transactions-header">Users</h4>
        <div className="inline-justifier">
          <VLIconButton
            Icon={FaFilter}
            onClick={() => {
              setShowFilters(s => !s);
            }}
          />
          <div className={`vl-button-out-text`}>{<React.Fragment>{'Filter'}</React.Fragment>}</div>
        </div>
      </Row>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        <div className="administration-filter">
          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Name</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="nameSearch"
                value={filters.nameSearch}
                onChange={handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>

          <div className={`user-form-control-container`}>
            <Form.Label className="search-user-form-label">Email</Form.Label>
            <div className="user-form-control-row">
              <VLInput
                size="s"
                type="text"
                name="emailSearch"
                value={filters.emailSearch}
                onChange={handleSearchFilter}
                placeholder={'Search'}
                isSearch
              />
            </div>
          </div>
        </div>

        <VLButton
          text={i18next.t('transactionsFilters.clearFilter')}
          variant="clear"
          size={'m'}
          rightIcon={<BsPlus />}
          onClick={clearFilters}
        />
      </div>
    );
  };

  const renderFirmUser = user => {
    const role = getRoleDisplayName(user.roles);

    return (
      <tr key={user.account}>
        <td>
          <div className="align-left">{user.attr ? `${user.attr.first_name} ${user.attr.last_name}` : user.userid}</div>
        </td>
        <td>
          <div className="align-left">{user.attr ? user.attr.email : '-'}</div>
        </td>
        <td>
          <div>{role || '-'}</div>
        </td>
        <td>
          <div>{user.firm}</div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              id={`${AUTHORIZATION_KEYS.VIEW}|${user.userid}|${account.account}`}
              checked={hasViewRights(account, user)}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              checked={hasTransactRights(account, user)}
              id={`${AUTHORIZATION_KEYS.TRANSACTION}|${user.userid}|${account.account}`}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div>
            <Form.Switch
              className="table-switch"
              label=""
              checked={hasApproveRights(account, user)}
              id={`${AUTHORIZATION_KEYS.APPROVE}|${user.userid}|${account.account}`}
              onChange={handleAccessSwitch}
            />
          </div>
        </td>
        <td>
          <div className="align-center">
            <VLIconButton
              Icon={AiOutlineClockCircle}
              onClick={() => {
                setTimelockAccessData({ user, account });
              }}
            />
            {hasTimeLock(user, account) ? (
              <VLIconButton
                Icon={TiDelete}
                onClick={() => removeTimeLock(user.userid, account.account)}
                variant="borderless"
              />
            ) : null}
          </div>
        </td>
        <td>
          <div className="align-left">{user.lastLogin ? formatDate(user.lastLogin) : '-'}</div>
        </td>
        <td>
          <div className="align-center">
            <VLIconButton
              Icon={MdRemoveRedEye}
              variant="borderless"
              onClick={() => redirectToUserDetails(user.userid)}
            />
          </div>
        </td>
        {canEditUsers && (
          <td>
            <div className="align-center">
              {' '}
              <VLIconButton
                Icon={MdDelete}
                variant="borderless"
                onClick={() => setRemoveAccessData({ user, account })}
              />
            </div>
          </td>
        )}
      </tr>
    );
  };

  const usersList = getFilteredUsers(users);
  return (
    <Card>
      <Row noGutters className="firm-item">
        <Col>
          {renderHeaderBar()}
          <CssFilterTransition component={renderFilters} isOpen={showFilters} />
          <div>
            {usersList.length ? (
              <Table className="vl-table" borderless responsive>
                <thead>
                  <tr>
                    <th className="align-left">{i18next.t('firmDetails.name')}</th>
                    <th className="align-left">{i18next.t('firmDetails.eMail')}</th>
                    <th>{i18next.t('firmDetails.role')}</th>
                    <th>{i18next.t('firmDetails.firm')}</th>
                    <th>View</th>
                    <th>Transact</th>
                    <th>Approve</th>
                    <th>{i18next.t('firmDetails.timeLock')}</th>
                    <th className="align-left">{i18next.t('firmDetails.lastLogin')}</th>
                    <th>{i18next.t('firmDetails.details')}</th>
                    {canEditUsers && <th>Delete</th>}
                  </tr>
                </thead>
                <tbody>{usersList.map(user => renderFirmUser(user))}</tbody>
              </Table>
            ) : null}

            {!usersList.length ? (
              <Row noGutters className="centered-row">
                <span className="users-table-empty-text">There are no users that match the criteria</span>
              </Row>
            ) : null}
          </div>
          <Row noGutters className="centered-row">
            {canEditUsers && (
              <VLButton onClick={() => setShowAddUserModal(true)} size="l" text="Add user" rightIcon={<IoMdAdd />} />
            )}
          </Row>
          {removeAccessData && (
            <GeneralConfirmModal
              title={'Remove User'}
              text={'Are you sure you want to remove user rights for this account?'}
              onConfirm={() => {
                handleRemoveAllPermissions({
                  userId: removeAccessData.user.userid,
                  accountId: removeAccessData.account.account
                });
              }}
              onCancel={resetConfirmation}
              isOpen={removeAccessData}
            />
          )}
          {timelockAccessData && (
            <TimeLockModal
              onClose={() => setTimelockAccessData(null)}
              timeString={getTimeLock(timelockAccessData.user, timelockAccessData.account)}
            />
          )}

          {showAddUserModal && (
            <SearchUsersModal
              isOpen={true}
              title="Search users"
              submitText={'Add'}
              onClose={() => setShowAddUserModal(false)}
              onComplete={userId => {
                setShowAddUserModal(false);
                handleAddViewPermission(userId, account.account);
              }}
              firm={account?.firm}
              ignoreUsers={users}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
export default AccountUsers;
