import React from 'react';
import './AssetPositions.scss';

const AssetPositions = ({ positions }) => {
  return (
    <div className="asset-positions">
      {positions.map(({ asset, value }) => (
        <div key={asset} className="asset-positions__item">
          <span>{`${asset} available`}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
};

export default AssetPositions;
