export const INIT_WEBSOCKET = 'INIT_WEBSOCKET';
export const CLOSE_WEBSOCKET = 'CLOSE_WEBSOCKET';
export const SET_WEBSOCKET = 'SET_WEBSOCKET';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SET_WEBSOCKET_ERROR = 'SET_WEBSOCKET_ERROR';
export const REMOVE_WEBSOCKET_ERROR = 'REMOVE_WEBSOCKET_ERROR';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const RECEIVED_SOCKET_MSG = 'RECEIVED_SOCKET_MSG';
export const POSITION_MSG = 'POSITION_MSG';
export const ORDER_UPDATE_MSG = 'ORDER_UPDATE_MSG';
export const BOOK_UPDATE_MSG = 'BOOK_UPDATE_MSG';
export const SCHEDULED_SEND_UPDATE_MSG = 'SCHEDULED_SEND_UPDATE_MSG';

export const SEND_TRANSFER = 'SEND_TRANSFER';
export const REQUEST_TRANSFER = 'REQUEST_TRANSFER';
export const MAKE_DEPOSIT = 'MAKE_DEPOSIT';
export const MAKE_REDEEM = 'MAKE_REDEEM';
export const MAKE_ESCROW = 'MAKE_ESCROW';
export const MAKE_IOI = 'MAKE_IOI';
export const MAKE_IOI_V2 = 'MAKE_IOI_V2';
export const MAKE_COUNTER_ORDER = 'MAKE_COUNTER_ORDER';
export const TO_BANK_REQUEST = 'TO_BANK_REQUEST';
export const TO_NETWORK_REQUEST = 'TO_NETWORK_REQUEST';
export const MAKE_REQUEST_ORDER = 'MAKE_REQUEST_ORDER';
export const MAKE_LIMIT_ORDER = 'MAKE_LIMIT_ORDER';
export const MAKE_MARKET_ORDER = 'MAKE_MARKET_ORDER';

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const ACCEPT_REQUEST_SUCCESS = 'ACCEPT_REQUEST_SUCCESS';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const REJECT_REQUEST_SUCCESS = 'REJECT_REQUEST_SUCCESS';

///////////////////////////////////////////////////////////////////////////
export const QUERY_POSITION = 'QUERY_POSITION';
export const QUERY_POSITION_SUCCESS = 'QUERY_POSITION_SUCCESS';
export const QUERY_POSITION_FIRM_DETAILS = 'QUERY_POSITION_FIRM_DETAILS';

/////////////////////////////////////////////////////////////////////////
export const UPDATE_RISK_SETTINGS = 'UPDATE_RISK_SETTINGS';
export const QUERY_RISK_SETTINGS = 'QUERY_RISK_SETTINGS';
export const QUERY_RISK_SETTINGS_SUCCESS = 'QUERY_RISK_SETTINGS_SUCCESS';
export const ADD_ALERT = 'ADD_ALERT';
export const QUERY_ALERTS = 'QUERY_ALERTS';

////////////////////////////////////////////////////////////////////////
export const QUERY_BALANCE_WALLET_POS = 'QUERY_BALANCE_WALLET_POS';
export const GET_ASSET_WALLET_ADDRESS = 'GET_ASSET_WALLET_ADDRESS';
export const GET_FEES_PER_ASSET = 'GET_FEES_PER_ASSET';
export const SEND_BALANCE_WITHDRAWAL = 'SEND_BALANCE_WITHDRAWAL';
export const SEND_TRANSACTION_HASH = 'SEND_TRANSACTION_HASH';
export const CHECK_IF_USER_EXISTS = 'CHECK_IF_USER_EXISTS';

/////////////////////////////////////////////////////////////////////
export const EXCHANGE_CURRENCY = 'EXCHANGE_CURRENCY';
