import { Table } from 'react-bootstrap';
import DatePicker, { dateSinglePickerEnd, dateSinglePickerStart } from '../../components/DatePicker';
import { REPORT_TABS } from '../../enums/validation';
import React, { PureComponent } from 'react';

import Card from '../Card';
import ExportButton from '../../components/ExportButton';
import { IoMdCheckmark } from 'react-icons/io';
import PageTitle from '../../components/PageTitle';
import Select from 'react-select';
import i18next from 'i18next';
import { splitDateTime, getDateFromUtc } from '../../utils/generalUtils';
import { hasRole } from '../../utils/roleCheckers';
import { ROLES } from '../../enums/roles';

const { day, month, year } = splitDateTime(new Date(), true);
const todaysDate = `${month}/${day}/${year}`;
const TODAY = 'today';
const YESTERDAY = 'yesterday';
const LAST_30_DAYS = 'last_30_days';
const LAST_6_MONTHS = 'last_6_months';
const YEAR_TO_DATE = 'year_to_date';
const CUSTOM = 'custom';

const CRYPTO_PENDING = 'crypto_pending';
const CRYPTO_REVIEWED = 'crypto_reviewed';
const USD_PENDING = 'usd_pending';
const USD_REVIEWED = 'usd_reviewed';
const OTHER = 'other';
const STAKED = 'staked';

const TRANSACTIONS = 'transactions';
const USERS = 'users';
const CURRENCY_TRANSACTION_REPORT = 'currency_transaction_report';
class Reports extends PureComponent {
  state = {
    displayFilters: false,
    type: '',
    category: '',
    firm: '',
    account: '',
    asset: '',
    sendOrReceive: '',
    enabledate: '',
    range: '',
    dateRange: { value: '', label: '' },
    data: [],
    minDate: new Date(),
    startDate: todaysDate,
    endDate: todaysDate,
    reportType: { value: '', label: '' },
    transactionName: { value: '', label: '' }
  };

  componentDidMount() {
    this.props.getAssets();
    this.setState({
      selectedTab: REPORT_TABS.ALL
    });
  }

  handleOnTabChange = tab =>
    this.setState(
      {
        selectedTab: tab
      },
      () => {
        console.log('report tab changed to', this.state.selectedTab);
        //TODO: change data
      }
    );

  toggleFilters = () => this.setState({ displayFilters: !this.state.displayFilters });

  handleDateChange = (dateParam, type) => {
    var date = new Date(dateParam[0]);
    if (type === 'end') {
      date = new Date(date.setDate(date.getDate() + 1));
    }
    var isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.setState({
      [`${type}Date`]: isoDate
    });
    console.log(isoDate);
  };
  calculateDates = dateRange => {
    var today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    var start;
    switch (dateRange) {
      case TODAY:
        start = today;
        break;
      case YESTERDAY:
        start = new Date(today.setDate(today.getDate() - 1));
        break;
      case LAST_30_DAYS:
        start = new Date(today.setDate(today.getDate() - 30));
        break;
      case LAST_6_MONTHS:
        start = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case YEAR_TO_DATE:
        start = new Date(year, 0, 1);
        break;
      default:
        start = today;
        break;
    }

    var end = new Date();
    end.setUTCHours(21, 59, 59, 999);
    this.setState({
      startDate: new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString(),
      endDate: new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString()
    });
  };
  handleDateRangeChange = target => {
    this.calculateDates(target.value);
    this.setState({
      dateRange: {
        value: target.value,
        label: target.value.replaceAll('_', ' ').toUpperCase()
      }
    });
  };
  handleTransactionTypeChange = target => {
    this.setState({
      reportType: {
        value: target.value,
        label: target.value.replaceAll('_', ' ').toUpperCase()
      },
      transactionName: { value: '', label: '' },
      dateRange: { value: '', label: '' }
    });
  };
  handleTransactionNameChange = target => {
    this.setState({
      transactionName: {
        value: target.value,
        label: target.value.replaceAll('_', ' ').toUpperCase()
      }
    });
  };
  renderHeadings = () => (
    <>
      <th
        style={{
          borderTop: '0px transparent',
          borderBottom: '0px',
          width: '15%'
        }}
        className="align-left"
      >
        Report type{' '}
      </th>
      {this.state.reportType.value === TRANSACTIONS && (
        <th
          style={{
            borderTop: '0px transparent',
            borderBottom: '0px',
            width: '15%'
          }}
          className="align-left"
        >
          Report name{' '}
        </th>
      )}
      <th
        style={{
          borderTop: '0px transparent',
          borderBottom: '0px',
          width: '15%'
        }}
        className="align-left"
      >
        Date range{' '}
      </th>
      <th
        style={{
          borderTop: '0px transparent',
          borderBottom: '0px',
          width: '20%'
        }}
        className="align-left"
      >
        From <text className="th-date-text">mm/dd/yyyy</text>{' '}
      </th>

      <th
        style={{
          borderTop: '0px transparent',
          borderBottom: '0px',
          width: '20%'
        }}
        className="align-left"
      >
        To <text className="th-date-text">mm/dd/yyyy</text>{' '}
      </th>
      <th
        style={{
          borderTop: '0px transparent',
          borderBottom: '0px'
        }}
      ></th>
    </>
  );
  getTransactionNameData = () => [
    {
      value: CRYPTO_PENDING,
      label: (
        <div>
          {this.state.transactionName.value === CRYPTO_PENDING && <IoMdCheckmark />}
          Crypto Pending Transactions
        </div>
      )
    },
    {
      value: CRYPTO_REVIEWED,
      label: (
        <div>
          {this.state.transactionName.value === CRYPTO_REVIEWED && <IoMdCheckmark />}
          Crypto Reviewed Transactions
        </div>
      )
    },
    {
      value: USD_PENDING,
      label: (
        <div>
          {this.state.transactionName.value === USD_PENDING && <IoMdCheckmark />}
          USD Pending Transactions
        </div>
      )
    },
    {
      value: USD_REVIEWED,
      label: (
        <div>
          {this.state.transactionName.value === USD_REVIEWED && <IoMdCheckmark />}
          USD Reviewed Transactions
        </div>
      )
    },
    {
      value: OTHER,
      label: (
        <div>
          {this.state.transactionName.value === OTHER && <IoMdCheckmark />}
          Other Transactions
        </div>
      )
    },
    {
      value: STAKED,
      label: (
        <div>
          {this.state.transactionName.value === STAKED && <IoMdCheckmark />}
          Staked Transactions
        </div>
      )
    }
  ];
  getStatusData = () => [
    {
      value: TODAY,
      label: (
        <div>
          {this.state.dateRange.value === TODAY && <IoMdCheckmark />}
          Today
        </div>
      )
    },
    {
      value: YESTERDAY,
      label: (
        <div>
          {this.state.dateRange.value === YESTERDAY && <IoMdCheckmark />}
          Yesterday
        </div>
      )
    },
    {
      value: LAST_30_DAYS,
      label: (
        <div>
          {this.state.dateRange.value === LAST_30_DAYS && <IoMdCheckmark />}
          Last 30 days
        </div>
      )
    },
    {
      value: LAST_6_MONTHS,
      label: (
        <div>
          {this.state.dateRange.value === LAST_6_MONTHS && <IoMdCheckmark />}
          Last 6 months
        </div>
      )
    },
    {
      value: YEAR_TO_DATE,
      label: (
        <div>
          {this.state.dateRange.value === YEAR_TO_DATE && <IoMdCheckmark />}
          Year to date
        </div>
      )
    },
    {
      value: CUSTOM,
      label: (
        <div>
          {this.state.dateRange.value === CUSTOM && <IoMdCheckmark />}
          Custom
        </div>
      )
    }
  ];

  getTransactionTypeDataSuperadmin = () => [
    {
      value: TRANSACTIONS,
      label: (
        <div>
          {this.state.reportType.value === TRANSACTIONS && <IoMdCheckmark />}
          Transactions
        </div>
      )
    },
    {
      value: USERS,
      label: (
        <div>
          {this.state.reportType.value === USERS && <IoMdCheckmark />}
          Users
        </div>
      )
    },
    {
      value: CURRENCY_TRANSACTION_REPORT,
      label: (
        <div>
          {this.state.reportType.value === CURRENCY_TRANSACTION_REPORT && <IoMdCheckmark />}
          Currency transaction report
        </div>
      )
    }
  ];

  getTransactionTypeDataFirmUser = () => [
    {
      value: TRANSACTIONS,
      label: (
        <div>
          {this.state.reportType.value === TRANSACTIONS && <IoMdCheckmark />}
          Transactions
        </div>
      )
    }
  ];

  checkIsDateRangeFilled = () => {
    if (
      this.state.dateRange.value === TODAY ||
      this.state.dateRange.value === YESTERDAY ||
      this.state.dateRange.value === LAST_30_DAYS ||
      this.state.dateRange.value === LAST_6_MONTHS ||
      this.state.dateRange.value === YEAR_TO_DATE
    )
      return true;
    else if (this.state.dateRange.value === CUSTOM && this.state.startDate && this.state.endDate) return true;
    return false;
  };

  checkAreAllFieldsFilled = () => {
    if (this.state.reportType.value === USERS || this.state.reportType.value === CURRENCY_TRANSACTION_REPORT)
      return this.checkIsDateRangeFilled();
    else if (this.state.reportType.value === TRANSACTIONS) {
      if (this.state.transactionName.value) return this.checkIsDateRangeFilled();
    }
    return false;
  };

  hasRoleUser = () => {
    return this.state.transactionName.value === OTHER && hasRole(this.props.userData?.data, ROLES.USER)
      ? 'otherUserSide'
      : this.state.transactionName.value;
  };

  determineReportType = () => {
    return this.state.reportType.value === TRANSACTIONS ? this.hasRoleUser() : this.state.reportType.value;
  };

  buildMockRows = () => {
    const userAccount = this.props.userData?.data.restricted_attr?.access_list[0]?.account;
    const isRoleFirmUser = hasRole(this.props.userData?.data, ROLES.USER);
    const params =
      this.state.transactionName.value === OTHER && !hasRole(this.props.userData?.data, ROLES.USER)
        ? {
            dates: this.state.startDate + '%26' + this.state.endDate
          }
        : { from: this.state.startDate, to: this.state.endDate };
    const areAllFieldsFilled = this.checkAreAllFieldsFilled();
    return [1].map(_ => (
      <tr key={_}>
        <td style={{ borderTop: 'none', width: '15%' }}>
          <div className="reports-table-text">
            <Select
              classNamePrefix="user-form-select-reports-date"
              name="reportType"
              onChange={this.handleTransactionTypeChange}
              defaultValue={
                isRoleFirmUser ? this.getTransactionTypeDataFirmUser()[0] : this.getTransactionTypeDataSuperadmin()[0]
              }
              autosize={true}
              value={this.state.reportType}
              options={isRoleFirmUser ? this.getTransactionTypeDataFirmUser() : this.getTransactionTypeDataSuperadmin()}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 1000 })
              }}
              //onChange={this.handleStatusChange}
            />
          </div>
        </td>
        {this.state.reportType.value === TRANSACTIONS && (
          <td style={{ borderTop: 'none', width: '15%' }}>
            <div className={`reports-table-text`}>
              <Select
                classNamePrefix="user-form-select-reports-date"
                name="transactionName"
                onChange={this.handleTransactionNameChange}
                defaultValue={this.getTransactionNameData()[0]}
                value={this.state.transactionName}
                options={this.getTransactionNameData()}
                isDisabled={this.state.reportType.value !== TRANSACTIONS}
                //onChange={this.handleStatusChange}
              />
            </div>
          </td>
        )}
        <td style={{ borderTop: 'none', width: '15%' }}>
          <div className="reports-table-text">
            <Select
              classNamePrefix="user-form-select-reports-date"
              name="dateRange"
              onChange={this.handleDateRangeChange}
              defaultValue={this.getStatusData()[0]}
              value={this.state.dateRange}
              options={this.getStatusData()}
              //onChange={this.handleStatusChange}
            />
          </div>
        </td>

        {/* Processed By */}
        <td style={{ borderTop: 'none' }}>
          <div
            className={`${
              this.state.dateRange.value !== CUSTOM ? 'send-grey-out ' : null
            }reports-table-text system-form-control-box-reports sfcb-date send-date-parent--send date-picker--small date-picker--modal
            `}
          >
            <DatePicker
              className="daterangepicker calendar-table-reports"
              options={dateSinglePickerStart({
                drops: 'down',
                parentEl: '.send-date-parent--send',
                date: this.state.startDate,
                css: '.calendar-table-reports'
              })}
              onClick={date => this.handleDateChange(date, 'start')}
              disabled={this.state.dateRange.value !== CUSTOM}
              defaultValue={'--/--/----'}
              date={this.state.startDate}
              maxDate={todaysDate}
            />
          </div>
        </td>
        <td style={{ borderTop: 'none' }}>
          <div
            className={`${
              this.state.dateRange.value !== CUSTOM ? 'send-grey-out ' : null
            }reports-table-text system-form-control-box-reports sfcb-date send-date-parent--end date-picker--small date-picker--modal`}
          >
            <DatePicker
              options={dateSinglePickerEnd({
                drops: 'down',
                parentEl: '.send-date-parent--end',
                date: this.state.endDate
              })}
              onClick={date => this.handleDateChange(date, 'end')}
              disabled={true}
              minDate={getDateFromUtc(this.state.startDate)}
              date={this.state.endDate}
              enabled={false}
              maxDate={todaysDate}
              // value={this.props.endDate}
            />
          </div>
        </td>
        <td style={{ borderTop: 'none' }}>
          <div
            className={`reports-table-text reports-table-text--center ${areAllFieldsFilled ? null : 'send-grey-out '}`}
          >
            {' '}
            <ExportButton
              isExportTitle
              title="Report"
              transactionListData={() => {
                return this.state.data;
              }}
              type={this.determineReportType()}
              params={params}
              userAccountNumber={userAccount}
            />
          </div>
        </td>
      </tr>
    ));
  };

  render() {
    return (
      <Card>
        <div className="administration">
          <div className="reports-button-bar-item">
            <PageTitle text={i18next.t('reports.title')} />
          </div>
          <div className="reports-content-body">
            <Table className="vl-table reports-table">
              <thead>
                <tr>{this.renderHeadings()}</tr>
              </thead>
              <tbody>{this.buildMockRows()}</tbody>
            </Table>
          </div>
        </div>
      </Card>
    );
  }
}

export default Reports;
