import { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import DashboardTransactionExternalTable from '../../components/DashboardTransactionExternalTable';
import DashboardTransactionInternalTable from '../../components/DashboardTransactionInternalTable';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getSelectedAccountId } from '../../contexts/AccountFilterContext';
import { PRODUCT_TYPES } from '../../enums/roles';
import { NON_PENDING_TRANSACTIONS, PENDING_TRANSACTIONS } from '../../enums/validation';
import {
  getFirstFiveElementFromArray,
  getLocalisedDecimalString,
  sortByDateDescending
} from '../../utils/generalUtils';
import { checkHasAuths, decodeRole } from '../../utils/roleCheckers';
import { getAmountsNew } from '../../utils/transactionsHelpers';
import Card from '../Card';
import './DashboardTransactionsTables.scss';

const ESCROW = 'escrow';
const REQUEST = 'request';
const SCHEDULED_TRANSFER = 'scheduled_transfer';

const mergeUsdAndCryptoTransactionsListsIntoOneSortedByDate = (firstList, secondList, field) => {
  let mergedTransactionsList = firstList.concat(secondList);
  let filteredMergedTransactionsList = mergedTransactionsList.filter(transaction => transaction[field]);
  return filteredMergedTransactionsList.sort((a, b) => {
    return b[field]?.localeCompare(a[field]);
  });
};

const DashboardTransactionsTables = ({
  internalExecutedTransactions,
  assetPairs,
  transactionsPendingCrypto,
  transactionsPendingUSD,
  transactionsReviewedCrypto,
  transactionsReviewedUSD,
  profileDetails,
  requests,
  escrows,
  scheduled,
  toggleTransactionDetails,
  user,
  getAccountTransactions,
  fetchExternalPendingCryptoTrasactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalUSDReviewedTransactions,
  currentAccountNumber
}) => {

  useEffect(() => {
    if (currentAccountNumber && getSelectedAccountId(currentAccountNumber)) {
      getAccountTransactions({
        account: currentAccountNumber,
        firm: profileDetails.data.firm
      });
      fetchExternalPendingCryptoTrasactions({
        ...PENDING_TRANSACTIONS,
        account: currentAccountNumber
      });
      fetchExternalReviewedCryptoTrasactions({
        ...NON_PENDING_TRANSACTIONS,
        account: currentAccountNumber
      });
      fetchExternalUSDPendingTransactions({
        page: '0,1000,requestedAt',
        transactionStatus: 'PENDING',
        account: currentAccountNumber
      });
      fetchExternalUSDReviewedTransactions({
        page: '0,1000,requestedAt',
        transactionStatus: 'COMPLETED',
        account: currentAccountNumber
      });
    }
  }, [currentAccountNumber]);

  const getPendingInternalTransactions = () => {
    let all = [];
    all = all.concat(escrows);
    all = all.concat(requests);
    all = all.concat(scheduled);

    return {
      transactions: all
    };
  };

  const getTransactionType = transaction => {
    const isEscrow = escrows.find(escrow => escrow.refno === transaction.refno);
    const isRequest = requests.find(request => request.refno === transaction.refno);

    if (isEscrow) return ESCROW;
    else if (isRequest) return REQUEST;
    else return SCHEDULED_TRANSFER;
  };

  const sortedInternalExecutedTransactions = sortByDateDescending(internalExecutedTransactions.data);
  const recentlyExecutedInternalTransactions = getFirstFiveElementFromArray(sortedInternalExecutedTransactions);
  const recentlyCompletedInternalTransfers = recentlyExecutedInternalTransactions.map(transaction => {
    let { amount, receiveAmount, sendSecurity, receiveSecurity } = getAmountsNew(
      transaction,
      transaction.type,
      false,
      assetPairs.data
    );
    return {
      ...transaction,
      transactionAmount: amount ? getLocalisedDecimalString(amount, sendSecurity) : '',
      sendSecurity,
      receiveAmount: receiveAmount ? getLocalisedDecimalString(receiveAmount, receiveSecurity) : '',
      receiveSecurity,
      transactionType: transaction.type
    };
  });

  const filteredPendingExternalTransactions = transactionsPendingCrypto.data?.filter(
    element => element.status === 'pending'
  );
  const pendingExternalTransactions = mergeUsdAndCryptoTransactionsListsIntoOneSortedByDate(
    filteredPendingExternalTransactions,
    transactionsPendingUSD.data,
    'created_date'
  );

  const reviewedExternalTransactions = mergeUsdAndCryptoTransactionsListsIntoOneSortedByDate(
    transactionsReviewedCrypto.data,
    transactionsReviewedUSD.data,
    'bm_status_modified_on'
  );
  const recentlyReviewedExternalTransfers = getFirstFiveElementFromArray(reviewedExternalTransactions);

  const sortedDataPendingInternalTransactions = sortByDateDescending(getPendingInternalTransactions().transactions);
  const filteredPendingInternalTransactions = sortedDataPendingInternalTransactions.filter(
    transaction =>
      transaction.clientorderid === currentAccountNumber ||
      transaction.brokers === currentAccountNumber ||
      transaction.type === SCHEDULED_TRANSFER
  );
  const pendingInternalTransfers = filteredPendingInternalTransactions.map(transaction => {
    const { amount, receiveAmount, sendSecurity, receiveSecurity } = getAmountsNew(
      transaction,
      transaction.type,
      true,
      assetPairs.data
    );
    const transactionType = getTransactionType(transaction);
    return {
      ...transaction,
      transactionAmount: amount ? getLocalisedDecimalString(amount, sendSecurity) : '',
      sendSecurity,
      receiveAmount: receiveAmount ? getLocalisedDecimalString(receiveAmount, receiveSecurity) : '',
      receiveSecurity,
      transactionType
    };
  });

  return (
    <>
      <Row className="dashboard-transactions-table-row">
        <div className={`dashboard-col-custom-pending dashboard-transactions-table dashboard-transactions-spinner`}>
          {transactionsPendingCrypto.loading || transactionsPendingUSD.loading || assetPairs.loading ? (
            <LoadingIndicator />
          ) : (
            checkHasAuths(profileDetails.data) && (
              <Card internalSpacing="0">
                <DashboardTransactionExternalTable
                  toggleTransactionDetails={toggleTransactionDetails}
                  assetsPairs={assetPairs}
                  userData={profileDetails.data}
                  title={'Pending external transfers'}
                  transactions={pendingExternalTransactions}
                  isReviewedTable={false}
                />
              </Card>
            )
          )}
        </div>
        <div className={`dashboard-col-custom-pending dashboard-transactions-table dashboard-transactions-spinner`}>
          {!profileDetails.data ? (
            <LoadingIndicator />
          ) : (
            checkHasAuths(profileDetails.data) && (
              <Card internalSpacing="0">
                <DashboardTransactionInternalTable
                  transactions={pendingInternalTransfers}
                  toggleTransactionDetails={toggleTransactionDetails}
                  assetsPairs={assetPairs}
                  userData={profileDetails.data}
                  title={'Pending internal transfers'}
                  isReviewedTable={false}
                />
              </Card>
            )
          )}
        </div>
      </Row>
      <Row className="dashboard-transactions-table-row">
        <div className={`dashboard-col-custom-pending dashboard-transactions-table dashboard-transactions-spinner`}>
          {transactionsReviewedCrypto.loading || transactionsReviewedUSD.loading || assetPairs.loading ? (
            <LoadingIndicator />
          ) : (
            checkHasAuths(profileDetails.data) && (
              <Card internalSpacing="0">
                <DashboardTransactionExternalTable
                  toggleTransactionDetails={toggleTransactionDetails}
                  assetsPairs={assetPairs}
                  userData={profileDetails.data}
                  title={'Recently reviewed external transfers'}
                  transactions={recentlyReviewedExternalTransfers}
                  isReviewedTable={true}
                />
              </Card>
            )
          )}
        </div>
        <div className={`dashboard-col-custom-pending dashboard-transactions-table dashboard-transactions-spinner`}>
          {internalExecutedTransactions.loading || assetPairs.loading ? (
            <LoadingIndicator />
          ) : (
            checkHasAuths(profileDetails.data) && (
              <Card internalSpacing="0">
                <DashboardTransactionInternalTable
                  transactions={recentlyCompletedInternalTransfers}
                  toggleTransactionDetails={toggleTransactionDetails}
                  assetsPairs={assetPairs}
                  userData={profileDetails.data}
                  title={'Recently completed internal transfers'}
                  isReviewedTable={true}
                />
              </Card>
            )
          )}
        </div>
      </Row>
    </>
  );
};

export default DashboardTransactionsTables;
