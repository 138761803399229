import { getOnboardingUsersRequest, getPendingOnboardingUsersRequest } from '../../redux/actions/administration';
import { connect } from 'react-redux';
import AdministratorAccounts from './AdministratorAccounts';

const mapDispatchToProps = {
  getOnboardingUsersRequest,
  getPendingOnboardingUsersRequest
};
const mapStateToProps = state => ({
  onboardingUsers: state.administration.onboardingUsers,
  pendingOnboardingUsers: state.administration.pendingOnboardingUsers
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorAccounts);
