import { connect } from 'react-redux';
import UserAccounts from './UserAccounts';
import { updatePermissions, updateWhitelist } from '../../../redux/actions/settings';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  canEditAccounts: state.login.userPermissions.data.permissions?.canEditAccounts
});

const mapDispatchToProps = {
  updatePermissions,
  updateWhitelist
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserAccounts));
