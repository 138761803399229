import { Row, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import CreateRecipitentModal from '../../components/modals/CreateRecipitentModal';
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal/GeneralConfirmModal';
import './ContactsTable.scss';
import { sort as sortContacts } from '../../utils/sort';
import { SORT_ORDER } from '../../enums/sort';
import { regularCorporateUserCheck } from '../../utils/roleCheckers';
import { getContactTypeDisplayName } from '../../utils/contacts';
import VLButton from '../Buttons/VLButton';
import VLIconButton from '../Buttons/VLIconButton';

const ContactsTable = ({ contacts, onRemoveContact, userRole, currentAccount, accounts }) => {
  const [showModals, setShowModals] = useState({
    showConfirmationModal: false,
    showCreateRecipientModal: false
  });
  const [sort, setSort] = useState({});
  const [contact, setContact] = useState({});

  const renderHeadings = () => {
    return (
      <thead>
        <tr>
          <th>
            <div className="sort-tr">
              <span>Name</span>
              <div className="arrow-container">
                <i
                  className={`sort-arrow-asc ${sort?.order === SORT_ORDER.ASCENDING ? 'sort-arrow-asc-selected' : ''}`}
                  onClick={() => setSort({ order: SORT_ORDER.ASCENDING, fieldName: 'name' })}
                ></i>
                <i
                  className={`sort-arrow-desc ${
                    sort?.order === SORT_ORDER.DESCENDING ? ' sort-arrow-desc-selected' : ''
                  }`}
                  onClick={() => setSort({ order: SORT_ORDER.DESCENDING, fieldName: 'name' })}
                ></i>
              </div>
            </div>
          </th>
          <th>
            <div className="sort-tr">
              <span>Account number</span>
              <div className="arrow-container">
                <i
                  className={`sort-arrow-asc ${sort?.order === SORT_ORDER.ASCENDING ? 'sort-arrow-asc-selected' : ''}`}
                  onClick={() =>
                    setSort({
                      order: SORT_ORDER.ASCENDING,
                      fieldName: 'accountNumber'
                    })
                  }
                ></i>
                <i
                  className={`sort-arrow-desc ${
                    sort?.order === SORT_ORDER.DESCENDING ? ' sort-arrow-desc-selected' : ''
                  }`}
                  onClick={() =>
                    setSort({
                      order: SORT_ORDER.DESCENDING,
                      fieldName: 'accountNumber'
                    })
                  }
                ></i>
              </div>
            </div>
          </th>
          <th>Firm</th>
          <th>Type</th>
          <th>Owner name</th>
          <th>Owner email</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };

  const toggleModal = (fieldName, contactRequest) => {
    setShowModals(prevState => ({
      ...prevState,
      [`show${fieldName}Modal`]: !prevState?.[`show${fieldName}Modal`]
    }));
    setContact(contactRequest);
  };
  let sortedContacts = JSON.parse(JSON.stringify(contacts.data));
  sortedContacts = sortContacts(sortedContacts, sort?.fieldName, sort?.order);
  return (
    <React.Fragment>
      {sortedContacts?.length > 0 ? (
        <Table borderless responsive className="vl-table" size="sm">
          {renderHeadings()}
          <tbody>
            {sortedContacts?.map(contact => {
              return (
                <tr key={contact.accountNumber}>
                  <td> {contact?.name || '-'}</td>
                  <td>{contact?.accountNumber}</td>
                  <td>{contact?.firm}</td>
                  <td>{getContactTypeDisplayName(contact)}</td>
                  <td>{contact?.ownerName}</td>
                  <td>{contact?.ownerEmail}</td>
                  <td>
                    <div className="contacts-delete-btn-align">
                      <VLIconButton
                        Icon={MdDelete}
                        variant="borderless"
                        onClick={() => toggleModal('Confirmation', contact)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="no-existing-contacts-message">
          <span>There are no existing contacts</span>
        </div>
      )}
      {currentAccount &&
        !regularCorporateUserCheck(
          userRole,
          accounts?.find(accountObj => accountObj?.account === currentAccount)
        ) && (
          <Row className="add-contact-btn-wrapper">
            <VLButton onClick={() => toggleModal('CreateRecipient')} text="Add contact" rightIcon={<IoMdAdd />} />
          </Row>
        )}

      {showModals.showConfirmationModal && (
        <GeneralConfirmModal
          isOpen={showModals.showConfirmationModal}
          title={'Delete this contact'}
          text={'Are you sure you want to delete this contact?'}
          onConfirm={() => {
            onRemoveContact(contact);
            toggleModal('Confirmation');
          }}
          onCancel={() => toggleModal('Confirmation')}
        />
      )}

      {showModals.showCreateRecipientModal && <CreateRecipitentModal onClose={() => toggleModal('CreateRecipient')} />}
    </React.Fragment>
  );
};

export default ContactsTable;
