import React, { PureComponent } from 'react';
import { Container, Row } from 'react-bootstrap';
import Card from '../Card';
import MediaQuery from 'react-responsive';
import { MODAL_CODES, NON_PENDING_TRANSACTIONS, PENDING_TRANSACTIONS } from '../../enums/validation';
import AccountPositionsOverview from '../../components/AccountPositionsOverview';
import Escrow from '../Escrow';
import BuySellModal from '../../components/modals/BuySellModal';
import GraphDashboardContainer from '../../components/GraphDashboardContainer';
import { SendAndRequest } from '..';
import TransactionButtonsBar from '../../components/TransactionButtonsBar';
import TransactionDetailsModal from '../../components/modals/TransactionDetailsModal';
import { getSelectedAccountId } from '../../contexts/AccountFilterContext';
import StakeAndExitStake from '../StakeAndExitStake';
import IOIModal from '../../components/modals/IOIModal';
import DashboardTransactionsTables from '../DashboardTransactionsTables';
import { isNotLoaded } from '../../utils/generalUtils';
import i18next from 'i18next';

class Dashboard extends PureComponent {
  state = {
    transaction: null,
    ioi: null,
    listOfAccounts: [],
    showTransactionDetailsModal: true
  };

  componentDidMount() {
    if (isNotLoaded(this.props.assets)) this.props.getTradingAssets();

    if (isNotLoaded(this.props.assetPairs)) this.props.fetchAssetPairs();

    if (isNotLoaded(this.props.profileDetails)) this.props.getUserProfile();

    if (isNotLoaded(this.props.accounts)) this.props.getDepositAccounts();

    // TODO: check if these are already loaded so that we do not call them
    //  over and over again
    if (this.props.currentAccountNumber && getSelectedAccountId(this.props.currentAccountNumber)) {
      this.props.getContactsRequest({
        currentAccount: this.props.currentAccountNumber
      });
      this.props.getAccountTransactions({
        firm: this.props.profileDetails.data.firm,
        account: this.props.currentAccountNumber
      });
      this.props.fetchExternalPendingCryptoTrasactions({
        ...PENDING_TRANSACTIONS,
        account: this.props.currentAccountNumber
      });
      this.props.fetchExternalUSDPendingTransactions({
        account: this.props.currentAccountNumber
      });
      this.props.fetchExternalReviewedCryptoTrasactions({
        ...NON_PENDING_TRANSACTIONS,
        account: this.props.currentAccountNumber
      });
      this.props.fetchExternalUSDReviewedTransactions({
        account: this.props.currentAccountNumber
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profileDetails.data !== prevProps.profileDetails.data) {
      if (this.props.currentAccountNumber && getSelectedAccountId(this.props.currentAccountNumber)) {
        this.props.getAccountTransactions({
          account: this.props.currentAccountNumber,
          firm: this.props.profileDetails.data.firm
        });
      }
    }
  }

  parentDate = (data, callback = null) =>
    this.setState(
      {
        ...data
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );

  toggleTransactionDetails = (transaction = null) => {
    if (!transaction) {
      this.props.toggleModal(null);
    }
    this.setState(
      {
        transaction: transaction
      },
      () => {
        this.props.toggleModal(transaction ? MODAL_CODES.DETAILS : null);
        this.props.resetTransactionHash();
      }
    );
  };

  render() {
    const { displayModal } = this.props;
    const selectedAccountId = getSelectedAccountId(this.props.currentAccountNumber);
    const selectedAccount = this.props.accounts.data?.find(a => a.account === selectedAccountId);

    return (
      <Card borderless transparent internalSpacing="0">
        <Container fluid className="dashboard-container-fluid">
          <Row className="dashboard-row">
            <div className="main-widget-container">
              <div className="widget-col-1">
                <div>
                  <MediaQuery maxWidth={1749}>
                    <Card spacing="8px 0px">
                      <GraphDashboardContainer {...this.props} />
                    </Card>
                  </MediaQuery>
                  <Card>
                    <TransactionButtonsBar {...this.props} userData={this.props.profileDetails.data} isDashboard />
                  </Card>
                  <Card spacing="8px 0px">
                    <div className="dashboard-positions-border-div dashboard-positions-wrap">
                      <h4 className="dashboard-positions-header">{i18next.t('accountPositions.yourAssets')}</h4>
                      <AccountPositionsOverview
                        account={selectedAccount}
                        userData={this.props.profileDetails.data}
                        onBuySell={this.props.toggleModal}
                        positions={this.props.accountPositions}
                        assetActionEnabled={true}
                      />
                    </div>
                  </Card>
                </div>
              </div>

              <div className="widget-col-2">
                <MediaQuery minWidth={1750}>
                  <Card>
                    <GraphDashboardContainer {...this.props} />
                  </Card>
                </MediaQuery>
              </div>
            </div>
          </Row>
          <DashboardTransactionsTables toggleTransactionDetails={this.toggleTransactionDetails} />
        </Container>

        {displayModal === MODAL_CODES.EXCHANGE && <BuySellModal />}
        {(displayModal === MODAL_CODES.SEND || displayModal === MODAL_CODES.REQUEST) && (
          <SendAndRequest type={displayModal} history={this.props.history} />
        )}
        {displayModal === MODAL_CODES.ESCROW && (
          <Escrow {...this.state} parentDate={this.parentDate} type={displayModal} history={this.props.history} />
        )}
        {displayModal === MODAL_CODES.DETAILS && (
          <TransactionDetailsModal
            {...this.props}
            accountList={this.state.listOfAccounts}
            transaction={this.state.transaction}
            user={this.props.profileDetails.data}
            onCloseModal={() => {
              this.toggleTransactionDetails(null);
            }}
          />
        )}
        {displayModal === MODAL_CODES.STAKE && <StakeAndExitStake type={displayModal} />}
        {displayModal === MODAL_CODES.EXIT_STAKE && <StakeAndExitStake type={displayModal} />}
        {displayModal === MODAL_CODES.IOI && (
          <IOIModal assets={this.props.assets} transaction={this.state.ioi} toggleModal={this.toggleIoIModal} />
        )}
      </Card>
    );
  }
}

export default Dashboard;
