import { FormControl, Row, Table } from 'react-bootstrap';
import { MdRemoveRedEye, MdSearch } from 'react-icons/md';
import React, { PureComponent } from 'react';

import ConfirmationModal from '../../components/modals/ConfirmationModal/ConfirmationModal';
import CreateFirmModal from '../../components/modals/CreateFirmModal';
import LoadingIndicator from '../../components/LoadingIndicator';
import Pagination from '../../components/Pagination/Pagination';
import { getCellShortenedString } from '../../utils/generalUtils';
import i18next from 'i18next';
import VLButton from '../../components/Buttons/VLButton';

import PageTitle from '../../components/PageTitle';
import Card from '../Card';
import { IoMdAdd } from 'react-icons/io';

const ROW_LIMIT = 13;
const defaultConfirmationFunction = () => console.log();

class Administration extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        q: ''
      },
      totalPages: this.props.listOfFirms.loaded ? Math.ceil(this.props.listOfFirms.data.data.length / ROW_LIMIT) : 0,
      dataPageNumber: 0,
      showCreateFirmModal: false,
      objectToDelete: null,
      showConfirmationModal: false,
      confirmationType: 'firm',
      createFirmError: this.props.createFirmError,
      confirmationOnSuccessFunk: defaultConfirmationFunction
    };
  }

  componentDidMount() {
    this.props.getListOfFirmsRequest(this.state.params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listOfFirms.data?.data !== this.props.listOfFirms.data?.data) {
      this.setState({
        totalPages: Math.ceil(this.props.listOfFirms.data.data.length / ROW_LIMIT) || 0
      });
    }
    if (prevProps.createFirmError !== this.props.createFirmError) {
      this.setState({ createFirmError: this.props.createFirmError }, () => {
        if (this.state.createFirmError.error == null) {
          this.setState({ createFirmError: { error: null } }, () => {
            this.toggleModal('CreateFirm');
          });
        }
      });
    }
  }

  resetConfirmation = () => {
    this.setState({
      confirmationType: 'firm',
      showConfirmationModal: false,
      confirmationOnSuccessFunk: defaultConfirmationFunction,
      objectToDelete: null
    });
  };

  handlePageClick = data => {
    this.setState({
      dataPageNumber: data.selected
    });
  };

  toggleModal = fieldName => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`]
    });
  };

  handleCreateFirm = data => {
    this.props.createFirmRequest(data);
  };

  handleSearch = e => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value
        }
      },
      () => this.props.getListOfFirmsRequest(this.state.params)
    );
  };

  handleDeleteFirm = () => {
    this.props.deleteFirmRequest({
      firm: this.state.objectToDelete.firm
    });
    this.resetConfirmation();
  };

  toggleConfirmationModal = (data, type) => {
    this.setState({
      showConfirmationModal: true,
      confirmationType: type,
      objectToDelete: data,
      confirmationOnSuccessFunk: this.handleDeleteFirm
    });
  };

  handleSeeFirmDetails = firm => {
    this.props.history.push(`/firms/${firm.firm}`);
  };

  renderTable = () => {
    let content;
    if (this.props.listOfFirms.loaded) {
      const sortedFirmArray = [...this.props.listOfFirms.data?.data];
      sortedFirmArray.sort((a, b) => a.firm.localeCompare(b.firm));
      const startIndex = this.state.dataPageNumber * ROW_LIMIT;
      const sortedFirmArraySliced = sortedFirmArray.slice(startIndex, startIndex + ROW_LIMIT);

      content = sortedFirmArraySliced.map(firm => this.renderTableItem(firm));
    } else if (!this.props.listOfFirms.loading) {
      content = (
        <tr>
          <td colSpan="3">
            <LoadingIndicator />
          </td>
        </tr>
      );
    }

    return (
      <Table borderless className="administration-table vl-table">
        <thead>
          <tr>
            <th className="align-left">{i18next.t('administration.firm')}</th>
            <th className="align-left">{i18next.t('administration.desc')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderTableItem = data => {
    return (
      <tr key={Math.random()}>
        <td>
          <div className="align-left">{data.firm}</div>
        </td>
        <td>
          <div className="align-left">{getCellShortenedString(data.description) || '-'}</div>
        </td>
        <td>
          <div>
            <VLButton
              onClick={() => this.handleSeeFirmDetails(data)}
              className="bordered-button administration-create-btn administration-detail-btn white-td white-td--blue"
              text={<span>{i18next.t('administration.details')}</span>}
              leftIcon={<MdRemoveRedEye className="administration-details-icon" />}
              size="m"
              variant="outline"
            />
          </div>
        </td>
      </tr>
    );
  };

  render() {
    if (!this.props.listOfFirms.loaded) return <LoadingIndicator />;

    return (
      <Card internalSpacing="0" borderless>
        <div className="administration user-table">
          <Row noGutters className="user-control-container administration-container">
            <PageTitle text={i18next.t('administration.firms')} styleClass="administration-title" />
            <div className="user-form-control-container administration-form-container">
              <div className="user-form-control-container row-width">
                <div className="user-form-control-wrap">
                  <FormControl
                    type="text"
                    name="q"
                    className="user-form-control search-user-form-control--border-gray search"
                    placeholder={i18next.t('administration.searchBy')}
                    value={this.state.params.q}
                    onChange={this.handleSearch}
                  />
                  <MdSearch className="user-form-search-icon user-form-search-icon--color-gray" />
                </div>
              </div>
            </div>
          </Row>
          {this.renderTable()}
          <div className="pagination">
            <Pagination
              totalPages={this.state.totalPages}
              handlePageClick={this.handlePageClick}
              dataPageNumber={this.state.dataPageNumber}
            />
          </div>
          {this.props.canEditFirms && (
            <Row noGutters className="centered-row">
              <VLButton
                onClick={() => this.toggleModal('CreateFirm')}
                text={i18next.t('administration.createF')}
                rightIcon={<IoMdAdd />}
              />
            </Row>
          )}

          {this.state.showCreateFirmModal && (
            <CreateFirmModal
              onClose={() => this.toggleModal('CreateFirm')}
              onSuccess={this.handleCreateFirm}
              createFirmError={this.state.createFirmError}
            />
          )}

          {this.state.showConfirmationModal && (
            <ConfirmationModal
              assetType={this.state.confirmationType}
              onSuccess={this.state.confirmationOnSuccessFunk}
              onClose={this.resetConfirmation}
            />
          )}
        </div>

        {this.state.showCreateFirmModal && (
          <CreateFirmModal
            onClose={() => this.toggleModal('CreateFirm')}
            onSuccess={this.handleCreateFirm}
            createFirmError={this.state.createFirmError}
          />
        )}
      </Card>
    );
  }
}

export default Administration;
