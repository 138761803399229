import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { ROLES } from '../../enums/roles';
import AdvancedChart, { AdvancedChartType } from '../Charts/AdvancedChart/AdvancedChart';
import DashboardAccountUsdEquivalent from '../DashboardAccountDropdown/DashboardAccountUsdEquivalent';
import AdvancedChartRange from '../Charts/AdvancedChart/AdvancedChartRange';
import { getLocalisedDecimalString } from '../../utils/generalUtils';
import { DARK_MODE } from '../../enums/validation';

const CHART_COLORS = {
  dark: {
    lineColor: '#7396b9',
    topColor: 'rgb(8, 93, 169)',
    bottomColor: 'rgb(8, 93, 169, 0.0)'
  },
  light: {
    lineColor: '#085da9',
    topColor: 'rgb(115, 150, 185, 0.7)',
    bottomColor: 'rgb(115, 150, 185, 0.05)'
  }
};

/**
 * Maps users account balance to an object usable by the {@link AdvancedChart}.
 *
 * @param balance {{ts:string, totalUSD:number}} - The users account balance record.
 * @param balance.ts {string} - The timestamp of when balance was recorded.
 * @param balance.totalUSD {number} - The total USD equivalent of the users account balance at given timestamp.
 * @return {{time: number, value: *}} - Chart usable data point (time should be in seconds).
 */
const balanceToChartData = balance => ({
  time: new Date(balance.ts).getTime() / 1000,
  value: balance.totalUSD
});

function GraphDashboardContainer({ user, balance, mode, getBalance, currentAccountNumber }) {
  const [data, setData] = useState([]);
  const [timeframe, setTimeframe] = useState(AdvancedChartRange.WEEK);
  const [preview, setPreview] = useState(null);

  const handleChartHover = ({ time, seriesPrices }) => {
    // Few no-ops just to optimize the performance a bit.
    if (!seriesPrices || !time) return;
    if (preview?.time === time) return;
    const { value } = seriesPrices.values().next();
    setPreview({ value, time: time * 1000 });
  };

  const handleChartMouseLeave = () => setPreview(null);

  /**
   * @param value {number} - price to format
   * @return {string}  - formatted price
   */
  const priceFormat = value => `$ ${getLocalisedDecimalString(value, 'USD')}`;

  useEffect(() => {
    if (!balance || !currentAccountNumber) return;

    setData(balance?.data[currentAccountNumber] ?? []);
  }, [balance, currentAccountNumber]);

  useEffect(() => {
    getBalance({ account: currentAccountNumber });
  }, [getBalance, currentAccountNumber]);

  if (user.data.role === ROLES.SUPER_USER) return null;

  const backgroundColor = mode === DARK_MODE ? '#193b5b' : '#fff';
  const borderColor = mode === DARK_MODE ? '#193b5b' : '#fff';

  return (
    <div className="dashboard-graph-container">
      <Row>
        <Col lg={12}>
          <DashboardAccountUsdEquivalent timeframe={timeframe} preview={preview} />
        </Col>
      </Row>
      <Row className="dashboard-graph-wrap">
        <Col lg={12}>
          <AdvancedChart
            rangeChoices={AdvancedChartRange.getRangeChoices().slice(3)}
            selectedRange={timeframe}
            height={380}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            priceLineVisible={false}
            priceScaleVisible={false}
            alignButtons="right"
            priceFormat={priceFormat}
            onMouseLeave={handleChartMouseLeave}
            onHover={handleChartHover}
            mode={mode}
            loading={balance.loading}
            type={AdvancedChartType.AREA}
            areaColors={CHART_COLORS}
            data={data.map(balanceToChartData)}
            onTimeSelect={setTimeframe}
          />
        </Col>
      </Row>
    </div>
  );
}

export default GraphDashboardContainer;
