import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { FRACTIONS } from '../../enums/validation';
import { TIME_ZONE } from '../../enums/time';
import { getLocalisedNumericString } from '../../utils/generalUtils';
import { getDecimalByFraction } from '../../utils/transactionsHelpers';
import { formatCurrency, parseCurrency } from './trade-helpers';
import moment from 'moment';
const initialFormState = {
  isLoading: false,
  isSubmitted: false,
  amount: '',
  price: '',
  errors: {},
  icebergValue: '',
  stopValue: '',
  expDate: null,

  selectedType: 'limit',

  acknowledgeCheck: false
};

const useOrderForm = ({
  side,
  balance,
  pair,
  tifOptions,
  minSellPrice,
  maxBuyPrice,
  initialPrice = 0,
  initialAmount = 0
}) => {
  const [formState, setFormState] = useState({
    ...initialFormState,
    tif: tifOptions[0],
    expTime: moment().set({ hours: '0', minutes: '0', seconds: '0' }),
    price: initialPrice ? formatCurrency(initialPrice, getDecimalByFraction(FRACTIONS[pair[1]]), true) : undefined,
    amount: initialAmount ? formatCurrency(initialAmount, getDecimalByFraction(FRACTIONS[pair[0]]), true) : undefined
  });

  useEffect(() => {
    // Clean up expDate and expTime
    setFormState(s => ({
      ...s,
      expDate: null,
      expTime: moment().set({ hours: '0', minutes: '0', seconds: '0' })
    }));

    // When tif is 'FOK' or 'IOC', 'Post Only' and 'Iceberg' options should be disabled
    if (formState.tif?.value === 'FOK' || formState.tif?.value === 'IOC') {
      setFormState(s => ({
        ...s,
        postOnly: false,
        iceberg: false
      }));
    }

    // Tif 'DAY' is actually GTT with expDate set to end of day
    if (formState.tif?.value === 'DAY') {
      setFormState(s => ({
        ...s,
        expDate: DateTime.fromJSDate(new Date(), { zone: TIME_ZONE }).endOf('day').toMillis()
      }));
    }
  }, [formState.tif]);

  // Clean-up iceberg input if Iceberg option is unchecked
  useEffect(() => {
    if (!formState.iceberg) {
      setFormState(s => ({
        ...s,
        errors: {
          ...s.errors,
          icebergValue: ''
        }
      }));
    }
  }, [formState.iceberg]);

  // Clean-up stop input if Stop option is unchecked
  useEffect(() => {
    if (!formState.stop) {
      setFormState(s => ({
        ...s,
        errors: {
          ...s.errors,
          stopValue: ''
        }
      }));
    }
  }, [formState.stop]);

  const totalValue = parseCurrency(formState.price) * parseCurrency(formState.amount);
  const totalValueDisplay = getLocalisedNumericString(totalValue, true, getDecimalByFraction(FRACTIONS[pair[1] || 0]));

  const handleChange = e => {
    if (!e.target.dataset.asset) {
      throw new Error('Missing asset attribute on input');
    }

    const formattedValue = formatCurrency(
      e.target.value,
      getDecimalByFraction(FRACTIONS[e.target.dataset.asset]),
      false
    );

    setFormState({
      ...formState,
      [e.currentTarget.name]: formattedValue,
      errors: {
        ...formState.errors,
        [e.currentTarget.name]: '',
        checked: false,
        total: ''
      }
    });
  };

  const handleBlur = e => {
    const formattedValue = formatCurrency(
      e.target.value,
      getDecimalByFraction(FRACTIONS[e.target.dataset.asset]),
      true
    );

    setFormState({
      ...formState,
      [e.currentTarget.name]: formattedValue
    });
  };

  const handleCheckedChange = e => {
    setFormState({
      ...formState,
      selectedType: e.currentTarget.name,
      errors: {
        ...formState.errors
      }
    });
  };

  const handleTifChange = (name, value) => {
    setFormState(s => ({
      ...s,
      [name]: value
    }));
  };
  function handleExpTime(e) {
    setFormState({
      ...formState,
      expDate:
        e?.format('hh:mm:ss') === moment().set({ hours: '0', minutes: '0', seconds: '0' }).format('hh:mm:ss') ? null : e
    });
  }

  const handleDateChange = date => {
    setFormState({
      ...formState,
      expDate: date[1]
    });
  };
  const handleTimeChange = time => {
    setFormState({
      ...formState,
      expTime: time.target.value,
      errors: {
        ...formState.errors,
        expTime: '',
        expDate: null
      }
    });
  };

  const resetForm = () => {
    setFormState({
      ...initialFormState,
      tif: tifOptions[0]
    });
  };

  const setLoading = value => {
    setFormState(s => ({
      ...s,
      isLoading: value
    }));
  };
  const handleCheckbox = () => {
    setFormState(s => ({
      ...s,
      acknowledgeCheck: true
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formState.price || parseCurrency(formState.price) === 0) {
      errors.price = 'Please enter a price';
    } else {
      if (side === 'B' && totalValue > balance[pair[1]]) {
        errors.total = 'Value is more than available balance';
      }
    }
    if (side === 'B' && minSellPrice && parseCurrency(formState.price) > parseCurrency(minSellPrice)) {
      errors.crossMarket = `Buy IOI price of ${formState.price}
       is higher than the lowest ask price of ${getLocalisedNumericString(minSellPrice, true, 2)}`;
      errors.acknowledge = `Please Note: This order is executable because the buy limit price
       of ${formState.price} is higher than the lowest ask price of ${getLocalisedNumericString(
        minSellPrice,
        true,
        2
      )}. Please verify
       you have entered you intended limit price.`;
    }
    if (side === 'S' && maxBuyPrice && parseCurrency(formState.price) < parseCurrency(maxBuyPrice)) {
      errors.crossMarket = `Sell IOI price of ${formState.price}
      is lower than the highest bid price of ${getLocalisedNumericString(maxBuyPrice, true, 2)}`;
      errors.acknowledge = `Please Note: This order is executable because the sell limit price
      of ${formState.price} is lower than the highest bid price of ${getLocalisedNumericString(
        maxBuyPrice,
        true,
        2
      )}. Please verify
      you have entered you intended limit price.`;
    }

    if (!formState.amount || parseCurrency(formState.amount) === 0) {
      errors.amount = 'Please enter an amount';
    } else {
      if (side === 'S' && parseCurrency(formState.amount) > balance[pair[0]]) {
        errors.amount = 'Value is more than available balance';
      }
    }

    if (formState.selectedType === 'iceberg') {
      if (!formState.icebergValue || parseCurrency(formState.icebergValue) === 0) {
        errors.icebergValue = 'Please enter a value';
      }
    }

    if (formState.selectedType === 'stop') {
      if (!formState.stopValue || parseCurrency(formState.stopValue) === 0) {
        errors.stopValue = 'Please enter a value';
      }
    }

    if (formState.tif?.value === 'GTT') {
      if (!formState?.expDate) {
        errors.expDate = 'Please choose expire time';
      }
    }

    if (formState.acknowledgeCheck || !errors.acknowledge) {
      delete errors.acknowledge;
      delete errors.crossMarket;
    }
    console.log(moment(formState.expDate).isValid());
    setFormState({
      ...formState,
      errors
    });

    return Object.keys(errors).length === 0;
  };

  return {
    formState,
    totalValueDisplay,
    handleChange,
    handleBlur,
    handleCheckedChange,
    handleTifChange,
    handleExpTime,
    handleDateChange,
    handleTimeChange,
    handleCheckbox,
    resetForm,
    setLoading,
    validateForm
  };
};

export { useOrderForm };
