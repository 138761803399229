import React from 'react';
import { FRACTIONS } from '../../../enums/validation';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import LoadingIndicator from '../../LoadingIndicator';
import AssetPositions from '../AssetPositions';
import { parseCurrency } from '../trade-helpers';
import TradeButton from '../TradeButton';
import { useOrderForm } from '../useOrderForm';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import './NegotiateOrder.scss';
import VLSelect from '../../VLSelect';

import VLInput from '../../VLInput/VLInput';
const TIF_OPTIONS = Object.freeze([
  { label: 'GTC', value: 'GTC' },

  { label: 'GTT', value: 'GTT' }
]);

const NegotiateOrder = ({
  userData,
  account,
  side,
  pair,
  balance,
  makeNegotiationRequest,
  websocket,
  ioi,
  ioiBrokers,
  setSelectedLmt,
  setSelectedIoi,
  isDisabled
}) => {
  const {
    formState,
    totalValueDisplay,
    setLoading,
    resetForm,
    handleBlur,
    handleChange,
    handleExpTime,
    handleTifChange,
    validateForm
  } = useOrderForm({
    side,
    balance,
    pair,
    tifOptions: TIF_OPTIONS,
    initialPrice: ioi?.price,
    initialAmount: ioi?.qty
  });

  const handleSubmit = e => {
    if (!validateForm()) return;
    if (!ioi) {
      toast.error('Please select IOI to before submitting an offer');
      return;
    }
    const expTime = new Date().setHours(
      new Date().getHours() + parseInt(formState.expDate?.get('hours')),
      new Date().getMinutes() + parseInt(formState.expDate?.get('minutes')),
      new Date().getSeconds() + parseInt(formState.expDate?.get('seconds')),
      0
    );

    let data = {
      socket: websocket,
      userId: account,
      firm: userData.firm,
      targetBrokers: ioiBrokers.toString(),
      pair: pair.join(''),
      side,
      exptime: formState.tif.value === 'GTT' && expTime,
      asset: pair[0],
      quantity: parseCurrency(formState.amount),
      price: parseCurrency(formState.price),
      successCallback,
      errorCallback
    };

    setLoading(true);

    makeNegotiationRequest(data);
  };

  const successCallback = data => {
    toast.success(i18next.t('toasts.success'));

    resetForm();
    setSelectedIoi(null);
    setSelectedLmt(null);
  };

  const errorCallback = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.result}`);

    setLoading(false);
  };

  return (
    <div className="negotiate-order">
      {formState.isLoading ? <LoadingIndicator /> : null}
      <AssetPositions
        positions={[
          {
            asset: pair[0],
            value: getLocalisedNumericString(balance[pair[0]], true, getDecimalByFraction(FRACTIONS[pair[0]]))
          },
          {
            asset: pair[1],
            value: getLocalisedNumericString(balance[pair[1]], true, getDecimalByFraction(FRACTIONS[pair[1]]))
          }
        ]}
      />
      <form>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Price:</p>
            <VLInput
              type="price"
              name="price"
              placeholder="Price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['price'] || ''}
              maxLength="20"
              autoComplete="off"
              dataAsset={pair[1]}
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.price}
            />
            {formState.errors?.price ? (
              <span className="system-form-control-error-text">{formState.errors?.price}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Amount:</p>
            <VLInput
              type="amount"
              name="amount"
              placeholder="Amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['amount'] || ''}
              maxLength="20"
              autoComplete="off"
              dataAsset={pair[0]}
              assetLabel={pair[0]}
              size="s"
              error={formState.errors?.amount}
            />
            {formState.errors?.amount ? (
              <span className="system-form-control-error-text">{formState.errors?.amount}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Approximate total:</p>
            <VLInput
              type="total"
              name="total"
              onChange={handleChange}
              onBlur={handleBlur}
              value={totalValueDisplay}
              maxLength="20"
              autoComplete="off"
              readOnly
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.total}
            />
            {formState.errors?.total ? (
              <span className="system-form-control-error-text">{formState.errors?.total}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="select-wrapper">
            <label>TIF:</label>
            <div className="select-field">
              <VLSelect
                borderless
                transparent
                aria-labelledby="tif"
                name="tif"
                options={TIF_OPTIONS}
                onChange={value => handleTifChange('tif', value)}
                value={formState.tif}
                isOptionDisabled={false}
              />
            </div>
          </div>
        </div>
        {formState.tif.value === 'GTT' ? (
          <div className="date-control-wrapper">
            <p className="system-form-control-label">Expires in</p>
            <div className="expire-time-wrapper">
              <div>
                <TimePicker
                  value={
                    !formState.expDate ? moment().set({ hours: '0', minutes: '0', seconds: '0' }) : formState.expDate
                  }
                  onChange={handleExpTime}
                  showSecond={true}
                  allowEmpty
                />
              </div>
            </div>
          </div>
        ) : null}
        {formState.errors?.expDate ? (
          <span className="system-form-control-error-text">{formState.errors?.expDate}</span>
        ) : null}

        <TradeButton
          onClick={handleSubmit}
          isDisabled={formState.isLoading || isDisabled}
          asset={pair[0]}
          side={side}
        />
      </form>
    </div>
  );
};

export default NegotiateOrder;
