import * as ADMINISTRATION_CONSTANTS from './administration';
import * as ANALYTICS_CONSTANTS from './analytics';
import * as LOGIN_CONSTANTS from './login';
import * as SETTINGS_CONSTANTS from './settings';
import * as TRANSACTIONS_CONSTANTS from './transactions';
import * as TRADE_CONSTANTS from './trade';
import * as BALANCE_CONSTANTS from './balance';

const URLS = {
  [LOGIN_CONSTANTS.EDIT_PROFILE]: '/firmadmin/users/update',
  [LOGIN_CONSTANTS.GET_USER_PROFILE]: '/account/details',
  [LOGIN_CONSTANTS.GET_USER_PERMISSIONS]: '/account/permissions',

  [LOGIN_CONSTANTS.ENABLE_TWO_FACTOR_REQUEST]: '/account/twoFactor/authapp/enable',
  [LOGIN_CONSTANTS.DISABLE_TWO_FACTOR_REQUEST]: '/account/twoFactor/authapp/disable',
  [LOGIN_CONSTANTS.CONTACT_SUPPORT_REQUEST]: '/account/support',
  [SETTINGS_CONSTANTS.FETCH_USERS]: '/firmadmin/users/search',
  [SETTINGS_CONSTANTS.FETCH_ACCOUNTS]: '/firmadmin/accounts/search',
  [SETTINGS_CONSTANTS.UPDATE_USER_PERMISSIONS]: '/firmadmin/users/updatePermission',
  [SETTINGS_CONSTANTS.UPDATE_USER_WHITELIST]: '/firmadmin/users/updateWhitelist',
  [SETTINGS_CONSTANTS.TOGGLE_USER_ACTIVE]: '/firmadmin/users/toggleActive',
  [SETTINGS_CONSTANTS.CREATE_USER]: '/firmadmin/users/create',
  [SETTINGS_CONSTANTS.CREATE_ACCOUNT]: '/firmadmin/accounts/create',
  [SETTINGS_CONSTANTS.SEARCH_ACCOUNTS]: '/firmadmin/accounts/search',
  [SETTINGS_CONSTANTS.CHECK_ACCOUNT_NAME]: '/firmadmin/accounts/search',
  [SETTINGS_CONSTANTS.SEARCH_FIRMS]: '/firmuser/firms/search',
  [SETTINGS_CONSTANTS.GET_FIRM_USER_DETAILS_REQUEST]: '/firmadmin/users',
  /////////////////////////////////////////////////////
  [TRADE_CONSTANTS.FETCH_CHART_DATA]: '/marketprice',
  [TRADE_CONSTANTS.FETCH_24H_MARKET_DATA]: '/marketprice/24h',
  [TRADE_CONSTANTS.FETCH_24H_MARKET_DATA_ALL_ASSETS_REQUEST]: '/marketprice/24h/all',
  [TRADE_CONSTANTS.FETCH_TRADE_ASSET_PAIRS]: '/firmuser/symbols',
  /////////////////////////////////////////////////////
  [TRANSACTIONS_CONSTANTS.FETCH_ASSETS]: '/firmuser/symbols',
  [TRANSACTIONS_CONSTANTS.FETCH_TRADING_ASSETS]: '/firmuser/symbols',
  [TRANSACTIONS_CONSTANTS.GET_TRANSACTIONS_REPORT]: '/firmuser/transactions/report',
  [TRANSACTIONS_CONSTANTS.FETCH_ASSETS_BY_PRODUCT]: '/firmuser/symbols/byproduct',
  [TRANSACTIONS_CONSTANTS.FETCH_ASSET_PAIRS]: '/firmuser/symbols',
  [TRANSACTIONS_CONSTANTS.FETCH_DEPOSIT_ACCOUNTS]: '/firmuser/accounts',
  [TRANSACTIONS_CONSTANTS.SEND_DEPOSIT]: '/firmuser/deposit',
  [TRANSACTIONS_CONSTANTS.SEND_REDEEM]: '/firmuser/redeem',
  [TRANSACTIONS_CONSTANTS.FETCH_ACCOUNT_EVENTS]: '/firmuser/activity',
  [TRANSACTIONS_CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL]: '/firmuser/activity',
  [TRANSACTIONS_CONSTANTS.FETCH_FIRMS]: '/firmuser/firms/search',
  [SETTINGS_CONSTANTS.CREATE_WALLET]: '/firmuser/wallet/create',
  [SETTINGS_CONSTANTS.DELETE_WALLET]: '/firmuser/wallet/delete',
  [TRANSACTIONS_CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM]: '/firmuser/accounts/search',
  [TRANSACTIONS_CONSTANTS.GET_PRICES]: '/price',
  [TRANSACTIONS_CONSTANTS.UPDATE_PRICES]: '/price',
  [TRANSACTIONS_CONSTANTS.GET_ALL_PRICES]: '/price',
  [TRANSACTIONS_CONSTANTS.UPDATE_TRANSACTION_DB]: '/historic/add',
  [TRANSACTIONS_CONSTANTS.FETCH_ORDER_HISTORY]: '/firmuser/order/history',
  [TRANSACTIONS_CONSTANTS.FETCH_TRADE_HISTORY]: '/firmuser/trade/history',
  [TRADE_CONSTANTS.GET_IOI]: '/firmuser/ioi',

  [TRADE_CONSTANTS.ADD_IOI]: '/firmuser/ioi/add',
  //////////////////////////////////////////////////////////////////////////////
  [ADMINISTRATION_CONSTANTS.GET_LIST_OF_FIRMS_REQUEST]: '/firmuser/firms/search',
  [ADMINISTRATION_CONSTANTS.GET_CONTACTS_REQUEST]: '/firmuser/contacts',
  [ADMINISTRATION_CONSTANTS.GET_CONTACTS_REQUESTS_REQUEST]: '/firmuser/contacts/requests',
  [ADMINISTRATION_CONSTANTS.GET_FIRM_USERS_REQUEST]: '/firmuser/users/search',
  [ADMINISTRATION_CONSTANTS.GET_FIRM_DETAILS_REQUEST]: '/superadmin/firms/details',
  [ADMINISTRATION_CONSTANTS.GET_FIRM_ADMIN_ACCOUNTS_REQUEST]: '/firmuser/accounts/search',
  [ADMINISTRATION_CONSTANTS.GET_FIRM_USERS_REQUEST]: '/firmuser/users/search',

  [ADMINISTRATION_CONSTANTS.GET_USER_SUGGESTIONS_REQUEST]: '/firmuser/suggestions/user',
  [ADMINISTRATION_CONSTANTS.GET_ACCOUNT_SUGGESTIONS_REQUEST]: '/firmuser/suggestions/account',

  [ADMINISTRATION_CONSTANTS.CREATE_CONTACT_REQUEST]: '/firmuser/contact/create',
  [ADMINISTRATION_CONSTANTS.DELETE_CONTACT_REQUEST]: '/firmuser/contact/delete',
  [ADMINISTRATION_CONSTANTS.CANCEL_CONTACT_REQUEST]: '/firmuser/contact/request/cancel',
  [ADMINISTRATION_CONSTANTS.ACCEPT_CONTACT_REQUEST]: '/firmuser/contact/request/accept',
  [ADMINISTRATION_CONSTANTS.DECLINE_CONTACT_REQUEST]: '/firmuser/contact/request/decline',
  [ADMINISTRATION_CONSTANTS.MARK_CONTACT_NOTIFICATIONS_AS_READ_REQUEST]: '/firmuser/contact/notification',
  [ADMINISTRATION_CONSTANTS.CREATE_FIRM_REQUEST]: '/superadmin/firms/create',
  [ADMINISTRATION_CONSTANTS.CREATE_ADMIN_ACCOUNT_REQUEST]: '/superadmin/createAdmin',
  [ADMINISTRATION_CONSTANTS.CREATE_ACCOUNT_REQUEST]: '/firmadmin/accounts/create',
  [ADMINISTRATION_CONSTANTS.DELETE_ACCOUNT_REQUEST]: '/firmadmin/accounts/delete',
  [ADMINISTRATION_CONSTANTS.DELETE_ADMIN_ACCOUNT_REQUEST]: '/firmadmin/users/delete',
  [ADMINISTRATION_CONSTANTS.DELETE_FIRM_REQUEST]: '/superadmin/firms/delete',
  [ADMINISTRATION_CONSTANTS.GET_LIST_OF_FIRMS_ACCOUNTS]: '/superadmin/accounts',
  [ADMINISTRATION_CONSTANTS.REGISTER_WALLET_FOR_ACCOUNT]: '/superadmin/registerWallet',
  [ADMINISTRATION_CONSTANTS.GET_ONBOARDING_USERS_REQUEST]: '/superadmin/users/search',
  [ADMINISTRATION_CONSTANTS.GET_USERS_REPORT]: '/superadmin/users/reports',
  [ADMINISTRATION_CONSTANTS.GET_USER_DETAILS_REQUEST]: '/superadmin/users',
  [ADMINISTRATION_CONSTANTS.GET_PENDING_ONBOARDING_USERS_REQUEST]: '/superadmin/users/search',
  [ADMINISTRATION_CONSTANTS.ACCEPT_ONBOARDING_USER_REQUEST]: '/superadmin/users/approve',
  [ADMINISTRATION_CONSTANTS.DECLINE_ONBOARDING_USER_REQUEST]: '/superadmin/users/reject',
  [ADMINISTRATION_CONSTANTS.GET_ACCOUNT_DETAILS_REQUEST]: '/firmadmin/accounts',
  [ADMINISTRATION_CONSTANTS.UPLOAD_BTA_REPORT_REQUEST]: '/superadmin/users/report/add',
  [ADMINISTRATION_CONSTANTS.DELETE_BTA_REPORT_REQUEST]: '/superadmin/users/report/delete',
  [ADMINISTRATION_CONSTANTS.DOWNLOAD_BTA_REPORT_REQUEST]: '/superadmin/users/report',
  [TRANSACTIONS_CONSTANTS.FETCH_CANCELED_ORDERS]: '/superadmin/orders/canceled',
  [TRANSACTIONS_CONSTANTS.FETCH_OPEN_ORDERS]: '/superadmin/orders/open',
  [TRANSACTIONS_CONSTANTS.OPEN_ORDERS_REPORT]: '/superadmin/orders/open/report',
  [TRANSACTIONS_CONSTANTS.CANCELED_ORDERS_REPORT]: '/superadmin/orders/canceled/report',

  [ANALYTICS_CONSTANTS.FETCH_ANALYTICS_DATA_ALL]: '/historic/fetch',
  [ANALYTICS_CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA]: '/historic/fetch',
  [ANALYTICS_CONSTANTS.EXPORT_TRANSACTIONS]: '/historic/export',
  [ANALYTICS_CONSTANTS.GET_TRANSACTION_BY_ID]: '/historic/transaction',

  [BALANCE_CONSTANTS.GET_BALANCE]: '/balance'
};

export default URLS;
