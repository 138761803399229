import { Table } from 'react-bootstrap';
import {
  formatDate,
  isValidDate,
  extractAssetsFromSecurityCode,
  getLocalisedNumericString
} from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import './CanceledOrdersTable.scss';

const CanceledOrdersTable = ({ canceledOrders }) => {
  return (
    <div className="overflow">
      {canceledOrders.length > 0 ? (
        <Table responsive borderless>
          <thead>
            {' '}
            <tr className="order-blotter-tables__th">
              <th>Asset</th>
              <th>Side</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Stop price</th>
              <th>Txn ID</th>
              <th>TIF</th>
              <th>Expire date</th>
              <th>Type</th>
            </tr>
          </thead>

          <tbody>
            {' '}
            {canceledOrders.map(order => {
              const expdate = formatDate(new Date(parseInt(order.exptime)), {
                dateOnly: true
              });

              const assets = extractAssetsFromSecurityCode(order.security);

              return (
                <tr className="order-blotter-tables__td">
                  <td>{order.security}</td>
                  <td className={`order-blotter-tables__${order.side}`}>{order.side}</td>
                  <td>{getLocalisedNumericString(order.qty, false, getDecimalByFraction(FRACTIONS[assets?.base]))}</td>
                  <td>
                    {' '}
                    {getLocalisedNumericString(order.price, false, getDecimalByFraction(FRACTIONS[assets?.quote]))}
                  </td>
                  <td>{order.stopprice || 'N/A'}</td>
                  <td>{order.refno}</td>
                  <td>{order.tif}</td>
                  <td>{isValidDate(new Date(parseInt(order.exptime))) ? expdate : 'N/A'}</td>
                  <td>{order.type}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="no-orders-msg">No canceled orders</div>
      )}
    </div>
  );
};
export default CanceledOrdersTable;
