import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import {
  extractDaysFromMillisecondTime,
  extractHoursFromMillisecondTime,
  extractMinutesFromMillisecondTime,
  extractSecondsFromMillisecondTime
} from '../../utils/timeUtils';
import './Timer.scss';

/**
 * Countdown timer
 *
 * @param {Integer} startTime - Date in milliseconds representing the date in the future used to calculate the remaining time.
 * @param {Integer} changeInterval - Interval representing change frequency of the timer.
 * @param {String} timezone - Represents time zone for the timer. Default time zone is 'America/New York'.
 */
const Timer = ({ startTime, changeInterval, timezone = 'America/New_York' }) => {
  const countdownDate = DateTime.fromMillis(startTime, { zone: timezone });
  const currentDate = DateTime.local({ zone: timezone }).ts;

  const [countdown, setCountdown] = useState(countdownDate - currentDate);
  const countdownTimer = useRef(null);

  const stopCountdownTimer = () => {
    clearInterval(countdownTimer.current);
  };

  useEffect(() => {
    countdownTimer.current = setInterval(() => {
      setCountdown(countdown => countdown - 1000);
    }, changeInterval);

    return () => stopCountdownTimer();
  }, [countdown, changeInterval]);

  useEffect(() => {
    if (countdown < changeInterval) {
      stopCountdownTimer();
    }
  }, [changeInterval, countdown]);

  /**
   * Extracts days, hours, minutes and seconds from time in milliseconds.
   * @returns {Map<String, Number} - Map containing information about extracted days, hours, minutes and seconds.
   */
  const extractTime = () => {
    const days = extractDaysFromMillisecondTime(countdown);
    const hours = extractHoursFromMillisecondTime(countdown);
    const minutes = extractMinutesFromMillisecondTime(countdown);
    const seconds = extractSecondsFromMillisecondTime(countdown);

    return new Map([
      ['days', days],
      ['hours', hours],
      ['minutes', minutes],
      ['seconds', seconds]
    ]);
  };

  /**
   * Extracts days, hours, minutes and seconds from time in milliseconds.
   * @param {Map<String, Number} time - Map containing information about days, hours, minutes and seconds.
   * @returns {String} - Formatted time.
   */
  const getFormattedTime = time => {
    const days = time.get('days');
    const hours = time.get('hours');
    const minutes = time.get('minutes');
    const seconds = time.get('seconds');

    const formattedDays = days < 10 ? '0' + days : days;
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

    return formattedDays + ':' + formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
  };

  /**
   * Checks if countdown is near expiration.
   * @returns {Boolean} - Indicator whether is near expiration or not.
   */
  const checkIfNearExpiration = () => {
    const hours = extractHoursFromMillisecondTime(countdown);
    if (hours < 12) {
      return true;
    }

    return false;
  };

  /**
   * Extracts days, hours, minutes and seconds from time in milliseconds and returns formatted time for {@link Timer}.
   * @returns {String} - Formatted time.
   */
  const getTime = () => {
    const time = extractTime();
    const formattedTime = getFormattedTime(time);

    return formattedTime;
  };

  return <span className={`${checkIfNearExpiration() ? 'expires-warning' : ''}`}>{getTime()}</span>;
};

export default Timer;
