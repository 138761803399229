import DashboardAccountUsdEquivalent from './DashboardAccountUsdEquivalent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts.data,
  assets: state.transactions.tradingAssets.data,
  positions: state.websocket.positions,
  balance: state.balance,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

export default compose(withTranslation('translations'), connect(mapStateToProps, {}))(DashboardAccountUsdEquivalent);
