import currency from 'currency.js';

/**
 * Generic money formatter class. It wraps `currency.js` ({@link https://currency.js.org}) library providing a
 * simple interface for formatting money values. It also provides a builder
 * pattern for configuring the formatter.
 *
 * @example Format a USD value:
 * const formatter = MoneyFormat.default().build();
 *
 * const formatted = formatter.format(123.456);
 * console.log(formatted); // $123.46
 *
 * @example <caption>Format a BTC value:</caption>
 * const formatter = MoneyFormat.builder()
 *  .currency(Currency.BTC)
 *  .build();
 *
 * const formatted = formatter.format(1.234567890);
 * console.log(formatted); // BTC1.23456789
 *
 * @example <caption>Dynamically configured currency formatter:</caption>
 * const asset = "btc";
 * const formatter = MoneyFormat.asset(asset).build();
 *
 * const formatted = formatter.format(1.234567890);
 * console.log(formatted); // BTC1.23456789
 *
 * @example Rounding utility:
 * const rounded = formatter.round(1.234567890, 2);
 * console.log(rounded); // 1.23
 *
 */
export default class MoneyFormat {
  /**
   * @param symbol {string} - Symbol to use for the currency. String shown before the number. E.g. "$".
   * @param precision {number} - Number of decimal places to use.
   * @param separator {string} - Separator between the whole and decimal parts of the number.
   * @param decimal {string} - Decimal point character. Usually a dot.
   * @param pattern {string} - Pattern to use for formatting the number. See `currency.js` docs for more info. E.g. "!#".
   */
  constructor(symbol, precision, separator, decimal, pattern) {
    this._precision = precision;
    this._separator = separator;
    this._decimal = decimal;
    this._symbol = symbol;
    this._pattern = pattern;
  }

  /**
   * Formats the number using the configured settings.
   *
   * @param num {number} - Number to format.
   * @param precision {number?} - Number of decimal places to use.
   * @returns {string} - Formatted number.
   */
  format(num, precision = this._precision) {
    return currency(num, {
      precision: precision,
      separator: this._separator,
      decimal: this._decimal,
      pattern: this._pattern,
      negativePattern: '-' + this._pattern,
      symbol: this._symbol
    }).format();
  }

  /**
   * @returns {Builder} - Builder for configuring the formatter.
   */
  static builder() {
    return new Builder();
  }

  /**
   * Factory method for creating a formatter for USD.
   *
   * @returns {Builder} - Formatter for USD.
   */
  static default() {
    return new Builder().currency(Currency.USD);
  }

  /**
   * Factory method for creating a formatter for any asset.
   *
   * @param asset {string} - Asset to create the formatter for.
   * @returns {Builder} - Formatter for the asset.
   * @throws {Error} - If the asset is not supported.
   */
  static asset(asset) {
    if (!asset) throw new Error('Asset is undefined');

    return new Builder().currency(asset);
  }

  /**
   * Utility method for rounding a number to a given precision.
   *
   * @param num {number} - Number to round.
   * @param precision {number?} - Number of decimal places to use. Defaults to 2.
   * @returns {string} - Rounded number.
   */
  static round(num, precision = 2) {
    return new Builder().build().format(num, precision);
  }
}

/**
 * Utility class for keeping information about a different currencies.
 */
export class Currency {
  static USD = new Currency(2, 'USD', '$');
  static BTC = new Currency(8, 'BTC', 'BTC');
  static ETH = new Currency(8, 'ETH', 'ETH');
  static USDC = new Currency(8, 'USDC', 'USDC');
  static FIL = new Currency(4, 'FIL', 'FIL');
  static BCH = new Currency(4, 'BCH', 'BCH');
  static ETC = new Currency(8, 'ETC', 'ETC');
  static LTC = new Currency(8, 'LTC', 'LTC');

  /**
   * @param precision {number} - Number of decimal places to use.
   * @param currency {string} - Currency code.
   * @param symbol {string} - Symbol to use for the currency.
   */
  constructor(precision, currency, symbol) {
    this.precision = precision;
    this.currency = currency;
    this.symbol = symbol;
  }

  /**
   * @returns {Currency} - Default currency.
   */
  static default() {
    return Currency.USD;
  }

  /**
   * Finds a currency by its code.
   *
   * @param asset {string} - Asset to get the currency for.
   * @returns {*|Currency} - Currency for the asset.
   * @throws {Error} - If the currency is not found.
   */
  static forAsset(asset) {
    return this[asset.toUpperCase()] ?? Currency.default();
  }
}

/**
 * Builder class for configuring the formatter.
 */
class Builder {
  constructor() {
    this._precision = 2;
    this._separator = ',';
    this._decimal = '.';
    this._symbol = '';
    this._pattern = '!#';
  }

  /**
   * @param num {number} - Number of decimal places to use.
   * @returns {Builder} - Builder for chaining.
   */
  precision(num) {
    if (num instanceof Currency) {
      this._precision = num.precision;
    } else {
      this._precision = num;
    }
    return this;
  }

  /**
   * @param chr {string} - Separator between the whole and decimal parts of the number.
   * @returns {Builder} - Builder for chaining.
   */
  separator(chr) {
    this._separator = chr;
    return this;
  }

  /**
   * @param chr {string} - Decimal point character.
   * @returns {Builder} - Builder for chaining.
   */
  decimal(chr) {
    this._decimal = chr;
    return this;
  }

  /**
   * @param str {string} - pattern to use for formatting the number. See `currency.js` docs for more info.
   * @returns {Builder} - Builder for chaining.
   */
  pattern(str) {
    this._pattern = str;
    return this;
  }

  /**
   * @param str {string|Currency} - Finds the currency by its code and sets the
   * symbol and precision accordingly, or if a `Currency` is passed, sets the
   * symbol and precision from the `Currency` object.
   * @returns {Builder} - Builder for chaining.
   */
  currency(str) {
    if (str instanceof Currency) {
      this._precision = str.precision;
      this._symbol = str.symbol;
    } else {
      str = Currency.forAsset(str);
      this._precision = str.precision;
      this._symbol = str.symbol;
    }

    return this;
  }

  /**
   * @param str {string} - Symbol to use for the currency.
   * @returns {Builder} - Builder for chaining.
   */
  symbol(str) {
    this._symbol = str;
    return this;
  }

  /**
   * @returns {MoneyFormat} - Formatter with the configured settings.
   */
  build() {
    return new MoneyFormat(this._symbol, this._precision, this._separator, this._decimal, this._pattern);
  }
}
