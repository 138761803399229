import React from 'react';
import { FRACTIONS } from '../../../enums/validation';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import LoadingIndicator from '../../LoadingIndicator';
import AssetPositions from '../AssetPositions';
import { useOrderForm } from '../useOrderForm';
import { parseCurrency } from '../trade-helpers';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import TimePicker from 'rc-time-picker';
import { padArray, BOOK_SIZE, byOrderPriceAsc, byOrderPriceDesc } from '../../OrderBook/orderBookHelpers';
import moment from 'moment';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput';
import VLSelect from '../../VLSelect';
const TIF_OPTIONS = Object.freeze([
  { label: 'GTT', value: 'GTT' },
  { label: 'DAY', value: 'DAY' }
]);

const IOI = ({
  userData,
  account,
  side,
  pair,
  balance,
  makeIOIV2Request,
  websocket,
  allBooks,
  assetPair,
  isDisabled
}) => {
  const sell = padArray(
    allBooks[assetPair?.security]
      ?.filter(o => o.side === 'S' && !o.ioi)
      .slice(0, BOOK_SIZE)
      .sort(byOrderPriceAsc) ?? []
  ).reverse();
  const buy = padArray(
    allBooks[assetPair?.security]
      ?.filter(o => o.side === 'B' && !o.ioi)
      .slice(0, BOOK_SIZE)
      .sort(byOrderPriceDesc) ?? []
  );
  const minSellPrice = sell[sell.length - 1]?.price;
  const maxBuyPrice = buy[0]?.price;

  const {
    formState,
    totalValueDisplay,
    setLoading,
    resetForm,
    handleBlur,
    handleChange,
    handleExpTime,

    handleTifChange,
    validateForm
  } = useOrderForm({
    side,
    balance,

    minSellPrice: minSellPrice,
    maxBuyPrice: maxBuyPrice,
    pair,
    tifOptions: TIF_OPTIONS
  });

  const handleSubmit = e => {
    if (!validateForm()) return;
    let expTime = new Date();
    if (formState.tif.value !== 'DAY') {
      expTime = new Date().setHours(
        new Date().getHours() + parseInt(formState.expDate?.get('hours')),
        new Date().getMinutes() + parseInt(formState.expDate?.get('minutes')),
        new Date().getSeconds() + parseInt(formState.expDate?.get('seconds')),
        0
      );
    }

    let data = {
      socket: websocket,
      account,
      firm: userData.firm,
      pair: pair.join(''),
      side,
      asset: pair[0],
      quantity: parseCurrency(formState.amount),
      price: parseCurrency(formState.price),
      tif: formState.tif?.value === 'DAY' ? 'GTT' : formState.tif.value, // DAY is special version of GTT
      expDate: formState.tif?.value === 'DAY' ? formState.expDate : expTime,
      successCallback,
      errorCallback
    };

    setLoading(true);

    makeIOIV2Request(data);
  };

  const successCallback = data => {
    toast.success(i18next.t('toasts.success'));

    resetForm();
  };

  const errorCallback = error => {
    toast.error(i18next.t('toasts.error2') + ` ${error?.result}`);

    setLoading(false);
  };
  return (
    <div className="ioi-order">
      {formState.isLoading ? <LoadingIndicator /> : null}
      <AssetPositions
        positions={[
          {
            asset: pair[0],
            value: getLocalisedNumericString(balance?.[pair[0]], true, getDecimalByFraction(FRACTIONS[pair[0]]))
          },
          {
            asset: pair[1],
            value: getLocalisedNumericString(balance[pair[1]], true, getDecimalByFraction(FRACTIONS[pair[1]]))
          }
        ]}
      />
      <form>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Price:</p>
            <VLInput
              type="price"
              name="price"
              placeholder="Price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['price'] || ''}
              maxLength="20"
              autoComplete="off"
              autoFocus
              dataAsset={pair[1]}
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.price}
            />
            {formState.errors?.price ? (
              <span className="system-form-control-error-text">{formState.errors?.price}</span>
            ) : null}
            {formState.errors?.crossMarket ? (
              <span className="system-form-control-error-text">{formState.errors?.crossMarket}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Amount:</p>
            <VLInput
              type="amount"
              name="amount"
              placeholder="Amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formState['amount'] || ''}
              maxLength="20"
              autoComplete="off"
              dataAsset={pair[0]}
              assetLabel={pair[0]}
              size="s"
              error={formState.errors?.amount}
            />
            {formState.errors?.amount ? (
              <span className="system-form-control-error-text">{formState.errors?.amount}</span>
            ) : null}
          </div>
        </div>
        <div>
          <div className="system-form-control-box">
            <p className="system-form-control-label">Approximate total:</p>
            <VLInput
              type="total"
              name="total"
              value={totalValueDisplay}
              maxLength="20"
              autoComplete="off"
              readOnly
              assetLabel={pair[1]}
              size="s"
              error={formState.errors?.total}
            />
            {formState.errors?.total ? (
              <span className="system-form-control-error-text">{formState.errors?.total}</span>
            ) : null}
          </div>
        </div>
        <div className="options-container">
          <div></div>
          <div>
            <div className="select-wrapper">
              <label>TIF:</label>
              <div className="select-field">
                <VLSelect
                  borderless
                  transparent
                  aria-labelledby="tif"
                  name="tif"
                  options={TIF_OPTIONS}
                  onChange={value => handleTifChange('tif', value)}
                  value={formState.tif}
                  isOptionDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        {formState.tif?.value === 'GTT' ? (
          <div className="date-control-wrapper">
            <p className="system-form-control-label">Expires in</p>
            <div className="expire-time-wrapper">
              <div id="txt">
                <TimePicker
                  value={
                    !formState.expDate || parseInt(formState.expDate)
                      ? moment().set({ hours: '0', minutes: '0', seconds: '0' })
                      : formState.expDate
                  }
                  onChange={handleExpTime}
                  showSecond={true}
                  allowEmpty
                />
              </div>
            </div>
          </div>
        ) : null}
        {formState.errors?.expDate ? (
          <span className="system-form-control-error-text">{formState.errors?.expDate}</span>
        ) : null}
        <div style={{ display: 'flex', marginTop: '40px' }}>
          <VLButton
            onClick={handleSubmit}
            disabled={formState.isLoading || isDisabled}
            text={'Submit'}
            size="l"
            width="100%"
          />
        </div>
      </form>
    </div>
  );
};

export default IOI;
