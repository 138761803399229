import {
  CMT_SECURITIES,
  FIAT_SYMBOLS,
  FRACTIONS,
  MAX_DESCRIPTION_DISPLAY_LEN,
  SECURITY_CODE_NAME_MAP,
  SECURITY_FEE_CURRENCY_FRACTIONS,
  SHOW_ALL_ACCOUNTS_FLAG,
  ZERO_ASSET_FORMAT
} from '../enums/validation';
import { decodeRole } from './roleCheckers';
import { ROLES, SUPER_USER_TYPES } from '../enums/roles';
import { TIME_ZONE } from '../enums/time';
import { getSecurityLabel } from './DOMHelpers';
import { onlyNumbers } from './validators';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { getDecimalByFraction } from './transactionsHelpers';
import Cookies from 'js-cookie';

export const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

/**
 * @deprecated use {@link MoneyFormat} instead.
 */
export const getLocalisedDecimalString = (value, asset) => {
  const decimal = getDecimalByFraction(FRACTIONS[asset] ?? FRACTIONS['BTC']);
  return getLocalisedNumericString(value, true, decimal);
};

/**
 * @deprecated use {@link MoneyFormat} instead.
 */
export const getLocalisedNumericString = (num, isDecimal = false, maxDecimals = 2) => {
  const numbers = isDecimal ? num : onlyNumbers(num);

  //TODO pass localisation param from redux or i18n
  let localisedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: maxDecimals,
    maximumFractionDigits: maxDecimals,
    signDisplay: 'never'
  }).format(numbers);
  localisedNumber = localisedNumber.substring(1);

  return localisedNumber;
};

export const getUsdFormattedValue = val => {
  if (!val) return '';

  return '$ ' + moneyFormat(val);
};

/**
 * @deprecated use {@link MoneyFormat} instead.
 */
export const formatMoney = (amount, decimalFlag = false, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalFlag
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {}
};

export const getCellShortenedString = (string, length) => {
  if (!string) {
    return '.';
  }
  let lengthCheck = length ? length : MAX_DESCRIPTION_DISPLAY_LEN;

  if (string.length > lengthCheck) {
    return `${string.slice(0, lengthCheck)} ...`;
  }

  return string;
};

export const formatTodaysDate = () => {
  const date = new Date();
  const month = date.toLocaleString('en-us', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
};

//Extracts property from object for both label and value of dropdown option, or if it's security from asset, applies special label function to label
export const parseObjectWithSamePropertyForDropdown = (objects, property) => {
  if (property === 'security') {
    return objects
      ?.map(object => {
        const label = getSecurityLabel(object);
        return {
          value: object?.[property],
          label: label
        };
      })
      .sort((a, b) => {
        // This makes USD appear first in all dropdowns
        if (a.label === 'USD') return -1;
        if (b.label === 'USD') return 1;
        return a.label.localeCompare(b.label);
      });
  } else
    return objects?.map(object => ({
      value: object[property],
      label: object[property]
    }));
};

export const buildAccountDropdownWithDisabledField = (accounts, valueProperty, labelProperty) => {
  let baseAccountOptions = parseObjectWithValueAndLabelProperyForDropdown(accounts, valueProperty, labelProperty);

  let accountOptionsWithDisabled = baseAccountOptions.map(option => {
    let account = accounts.find(account => account.account === option.value);
    return {
      ...option,
      disabled: account?.active_account !== 'Y'
    };
  });

  return accountOptionsWithDisabled;
};

// builds dropdown options from an objects array
// gets specific label and value property from object, it can be nested with a syntax of field.field2.field3
export const parseObjectWithValueAndLabelProperyForDropdown = (objects, valueProperty, labelProperty) =>
  objects?.map(object => ({
    value: getNestedValue(object, valueProperty),
    label: getNestedValue(object, labelProperty)
  })) || [];

export const parseObjectWithValueAndComplexLabelForDropdown = (
  objects,
  valueProp,
  labelProps,
  template,
  defaultValuesForLabelProps
) =>
  objects?.map(object => {
    const nestedValues = labelProps.map(label => getNestedValue(object, label));
    const label = template.replace(/\${(.*?)}/g, (x, g) => nestedValues[g] || defaultValuesForLabelProps[g]);
    return {
      value: getNestedValue(object, valueProp),
      label
    };
  });

// valuesMapString -> field1.field2.field3
export const getNestedValue = (object, valuesMapString, separatorValue = '.') => {
  const fields = valuesMapString.split(separatorValue);
  return fields.reduce((object, field) => object?.[field], object);
};

//gets specific elements of array, and the specified (delta) distance between them
// so every third, or every fourth, etc
export const getNthElementsOfArray = (oldArray, delta, offset) => {
  let newArray = [];
  for (let i = offset; i < oldArray.length; i = i + delta) {
    newArray.push(oldArray[i]);
  }
  return newArray;
};

/**
 * Sums up all positions and returns the total USD equivalent values.
 *
 * @param positions {any[]} - account positions
 * @param accounts {any[]} - accounts
 * @param assets {any[]} - VL supported assets
 * @param selectedAccount {string} - selected account
 * @return {number} - total USD equivalent value
 */
export const getTotalUsdEquivalent = (positions, accounts, assets, selectedAccount) => {
  if (!assets || !positions || !selectedAccount) return 0;

  let positionsData = {};
  if (selectedAccount === SHOW_ALL_ACCOUNTS_FLAG) {
    positionsData = getPositionDataFromAllAccounts(assets, positions || {}, accounts || []);
  } else {
    positionsData = getPositionDataForSpecificAccount(assets, positions || {}, accounts || [], selectedAccount);
  }

  return Object.keys(positionsData.VLN).reduce((acc, curr) => {
    const VLNAmount = Number(positionsData.VLN[curr]) ?? 0;
    if (VLNAmount === 0) return acc;

    const asset = assets.find(asset => asset.security === curr);
    if (!asset || !asset.currUSD) return acc;

    return acc + VLNAmount * asset.currUSD;
  }, 0);
};

export const getPositionDataForSpecificAccount = (assets, positionsObject, accountsArray, accountId) => {
  let aggregated = {
    VLB: {},
    VLN: {}
  };
  //get accounts from position object in redux
  const accounts = Object.keys(positionsObject);

  let exists = accountsArray?.find(account => account.account === accountId);
  const accountSelected = accounts.find(account => account === accountId);

  //checks whether there is any position data for selected account

  if (!exists) {
    return aggregated;
  }

  //lists through all the assets to get specific position data
  (assets || []).forEach(asset => {
    //we're only handling VLN accounts (legacy check, leftover when we had VLB and VLN accounts)
    const isVLN = !!accountsArray.find(vlnAccount => vlnAccount.account === exists.account);
    const positionData = positionsObject[accountSelected] || {};
    // get assets of specific account position object
    const currencies = Object.keys(positionData);
    const currency = asset.security;
    //get VLB position data (legacy functionality)
    const secondary = exists.secondary_account;
    const secondaryPositionData = positionsObject[secondary] || {};
    const secondaryCurrencies = Object.keys(secondaryPositionData);
    if (currencies.includes(currency) || secondaryCurrencies.includes(currency)) {
      if (isVLN) {
        aggregated.VLN[currency] = positionData[currency];
        aggregated.VLB[currency] = secondaryPositionData?.[currency] || '0';
      } else {
        aggregated.VLB[currency] = positionData[currency];
        aggregated.VLN[currency] = secondaryPositionData?.[currency] || '0';
      }
    }
  });

  return aggregated;
};

export const getPositionDataFromAllAccounts = (assets, positionsObject, accountsArray) => {
  let aggregated = {
    VLB: {},
    VLN: {}
  };
  const accounts = Object.keys(positionsObject);
  // if there are no accounts in positions object, there's nothing to parse
  if (!accounts.length) return aggregated;

  (assets || []).forEach(asset => {
    accounts.forEach(account => {
      const isVLN = !!accountsArray.find(vlbAccount => vlbAccount.account === account);
      const positionData = positionsObject[account];
      const currencies = Object.keys(positionData);
      const currency = asset?.security;
      if (currencies.includes(currency)) {
        if (isVLN) {
          // check whether there's already data for the symbol/asset, if so add to it
          if (aggregated.VLN.hasOwnProperty(currency)) {
            aggregated.VLN[currency] = parseInt(aggregated.VLN[currency], 10) + parseInt(positionData[currency], 10);
          } else {
            //if data doesn't exist, create it in the object
            aggregated.VLN[currency] = positionData[currency] || 0;
          }
        } else {
          if (aggregated.VLB.hasOwnProperty(currency)) {
            aggregated.VLB[currency] = parseInt(aggregated.VLB[currency], 10) + parseInt(positionData[currency], 10);
          } else {
            aggregated.VLB[currency] = positionData[currency] || 0;
          }
        }
      }
    });
  });

  return aggregated;
};

/**
 * Factory function to create  a reduce method callback to split a list into
 * sub-lists of length `size`.
 *
 * @param size {number} - the size of each sub-list
 * @returns {function(Array<Array<any>>, Array<any>): Array<Array<any>>} - the
 *  reduce function callback
 */
export const sublistCallbackFactory =
  (size = 2) =>
  (acc, curr) => {
    const last = acc[acc.length - 1];
    if (last && last.length < size) {
      last.push(curr);
    } else {
      acc.push([curr]);
    }

    return acc;
  };

export const allFieldsNonNull = obj => {
  if (!obj) return false;

  return Object.keys(obj).every(key => key && obj[key]);
};

/**
 * calculateFirmAssetsUSD will make a sum of all the assets held by the account represented as USD equivalent value
 *
 * @param {Array} positions - All positions held by the account in form [{security: "BTC", value: 123.2313}, {security: "ETC", value: 123.2313}]
 * @param {Object} rates - All prices stated in USD for all supported assets
 * @returns
 */
export const calculateFirmAssetsUSD = (positions, rates) => {
  let sum = 0;
  if (!positions.length || !rates) return sum;

  positions.forEach(position => {
    let rate = rates[position.security.toLowerCase()] ?? 1;

    const usdAmount = Number(position.value) * Number(rate);
    sum += usdAmount;
  });

  return sum;
};

export const getUSDFromPositions = positions => {
  let usdValue = 0;
  positions.forEach(position => {
    if (position.security === 'USD') {
      usdValue = position.curpos;
    }
  });
  return usdValue;
};

// calculates position into usd
export const calculateAssetUSD = (position, assets) => {
  let sum = 0;
  if (!position || !assets.length) return sum;
  //for position, get symbol/asset and calculate the current position with the currUSD amount
  const asset = assets.find(element => element.security === position.security);
  const usdAmount = Number(position.curpos) * Number(asset?.currUSD || 0);

  return usdAmount;
};

export const formatDateFromString = dateString => formatDate(new Date(dateString));

export const formatDateFromTimestamp = timestamp => {
  if (!timestamp) return '';

  if (typeof timestamp === 'string') {
    return formatDate(parseInt(timestamp));
  }

  return formatDate(new Date(timestamp));
};

// EST timezone is hardcoded on the FE per request
// but this can be changed if needed to dynamically obtain local timezone
export const formatDate = (date, { dateOnly, timeOnly, customFormat } = {}) => {
  let dateTime = null;

  if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date, { zone: TIME_ZONE });
  }
  if (typeof date === 'string') {
    dateTime = DateTime.fromISO(date, { zone: TIME_ZONE });
  }

  if (typeof date === 'number') {
    dateTime = DateTime.fromMillis(date, { zone: TIME_ZONE });
  }

  if (!dateTime) {
    throw new Error(`date should be either js Date or ISO 8601 string ${date}`);
  }

  if (customFormat) dateTime.toFormat(customFormat);

  if (dateOnly) return dateTime.toFormat('MM/dd/yyyy');
  if (timeOnly) return dateTime.toFormat('HH:mm:ss');

  return dateTime.toFormat('MM/dd/yyyy HH:mm:ss');
};

export const splitDateTime = (date, shortFormat = false) => {
  if (!date) {
    return { day: '', month: '', year: '' };
  }
  const day = `${date.getDate()}`.length > 1 ? date.getDate() : `0${date.getDate()}`;
  const month = shortFormat
    ? date.getMonth() >= 9
      ? date.getMonth() + 1
      : `0${date.getMonth() + 1}`
    : date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
  const seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  return { day, month, year, hours, minutes, seconds };
};

export const getDateFromUtc = d => {
  var date = new Date(d);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return (d = mm + '/' + dd + '/' + yyyy);
};

export const formatTime = date => {
  if (!date) {
    return '';
  }
  const time = date.toLocaleString('en-us', {
    hour: '2-digit',
    minute: 'numeric',
    hour12: true
  });

  return time;
};

export const from12hourTimeToDateTime = timestamp => {
  let d = new Date().getTime();
  let timeReg = /(\d+)\:(\d+)/;
  let parts = timestamp.match(timeReg);
  let hours = parseInt(parts[1], 10);

  let minutes = parseInt(parts[2], 10);

  let date = new Date(d);

  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export const capitalizeFirstLetter = string => (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');

export const capitalizeAll = str =>
  str
    ?.split(/[\s_]+/)
    .map(capitalizeFirstLetter)
    .join(' ');

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

/**
 * Comparator that performs localeCompare string comparison.
 *
 * @param a {string}
 * @param b {string}
 * @return {number}
 */
export const localeCompare = (a, b) => a.localeCompare(b);

//sorts transactions of different types by date
export const sortByDateDescending = array => {
  if (!!!array?.length) {
    return [];
  }
  // optimization, to not create new Date while sorting, we create an array with the Date object already set
  //different transaction types have different time fields
  const tempDataArray = array.map(event => {
    const date = new Date();
    date.setTime(event.time);
    if (!isValidDate(date)) {
      date.setTime(parseInt(event.enttime));
      if (!isValidDate(date)) {
        date.setTime(event.trdtime);
      }
      if (!isValidDate(date)) {
        date.setTime(new Date(event.created_date).getTime());
      }
    }

    return {
      ...event,
      date
    };
  });
  return tempDataArray
    .slice()
    .sort((a, b) => {
      if (isNaN(a.date.getTime())) {
        return 1;
      } else if (isNaN(b.date.getTime())) {
        return -1;
      } else {
        return a.date - b.date;
      }
    })
    .reverse();
};

export const getAccountNameFromId = (accounts, id) =>
  accounts?.find(account => account.account === id)?.attr?.name || '';

export const extractNameAndCodeFromLabel = label => {
  const split = label.split('(');
  const substring = label.substring(label.indexOf('(') + 1, label.indexOf(')'));

  return { name: split[0], code: substring };
};

export const getSelectedOption = (options, selectedValue) => options?.find(option => option.value === selectedValue);

export const getCharacterFirstLast = (val, side = 'last', length = 4) => {
  let slicedVal = '';
  if (side === 'first') {
    slicedVal = val.slice(0, length);
  } else if (side === 'last') {
    slicedVal = val.slice(val.length - 4);
  }
  return slicedVal;
};

export const setColorMode = color => {
  localStorage.setItem('colorMode', color);
};

export const getColorMode = () => {
  return localStorage.getItem('colorMode');
};

export const validateTimeStamp = timestamp => {
  return new Date(+timestamp).getTime() > 0;
};

export const findFiatCurrencySymbol = fiat => {
  const fiatToUpper = fiat && fiat.toUpperCase();
  return FIAT_SYMBOLS[fiatToUpper] === FIAT_SYMBOLS.USD ? ` ${FIAT_SYMBOLS[fiatToUpper]} ` : '';
};

export const handleLastUpdateTimestamp = (lastDate = new Date()) => {
  const date = lastDate;
  if (!date) return '';
  const { day, month, year } = splitDateTime(date, true);
  const hours = date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const isZeroAssetFormat = value => {
  let isValidFormat = false;
  Object.keys(ZERO_ASSET_FORMAT).forEach(val => {
    if (ZERO_ASSET_FORMAT[val] === value) {
      isValidFormat = true;
    }
  });
  return isValidFormat;
};

export const convertCryptoFee = ({ fee, asset }) => {
  const expNum = fee * SECURITY_FEE_CURRENCY_FRACTIONS[asset];

  return expNum.toFixed(10);
};

/**
 * @deprecated use {@link MoneyFormat} instead.
 */
export const moneyFormat = x => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const replaceCommas = amount => {
  return amount.replace(/,/g, '');
};

export const getRoleDisplayName = role => {
  const userPermissions = decodeRole(role);

  let displayValue = '';
  if (userPermissions.role === ROLES.USER) {
    displayValue = i18next.t('general.roles.firmUser');
  }
  if (userPermissions.role === ROLES.ADMIN) {
    displayValue = i18next.t('general.roles.firmAdmin');
  }
  if (userPermissions.role === ROLES.SUPER_USER) {
    displayValue = i18next.t('general.roles.superUser');
    if (userPermissions.superAdminType === SUPER_USER_TYPES.BTA_MANAGER) {
      displayValue = i18next.t('general.roles.btaManager');
    }
    if (userPermissions.superAdminType === SUPER_USER_TYPES.BANK_MANAGER) {
      displayValue = i18next.t('general.roles.bankManager');
    }
  }

  return displayValue;
};

export const getClosestMultiple32 = value => {
  var n = value;
  n = n + parseInt(32 / 2, 10);
  n = n - (n % 32);
  if (value < n) {
    n = n - 32;
  }
  return n;
};

export const createDefaultContainsFilterFn = (filterValue, fieldName) => el => {
  if (!filterValue) return true;
  if (!el[fieldName]) return false;

  return el[fieldName].toLowerCase().includes(filterValue.toLowerCase());
};

export const createDefaultDateRangeFilterFn = (filterValue, fieldName) => el => {
  if (!filterValue || filterValue.length === 0) return true;
  if (!el[fieldName]) return false;

  const date = new Date(el[fieldName]);
  if (date > filterValue?.[0] && date < filterValue?.[1]) {
    return true;
  }

  return false;
};

/**
 * Extracts FI authentication token from Cookies.
 *
 * @returns {string} authentication token.
 */
export const getAuthToken = () => {
  return Cookies.get(process.env.REACT_APP_AUTH_COOKIE);
};

/**
 * @deprecated use getAuthToken instead
 */
export const getAuthCookie = () => {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${process.env.REACT_APP_AUTH_COOKIE}=`))
    ?.split('=')[1];
};

export const isContentTypeJSON = response => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType === 'application/json') {
    return true;
  } else {
    return false;
  }
};

const determineContentType = response => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType === 'application/pdf') {
    return '.pdf';
  } else if (contentType && (contentType.includes('application/csv') || contentType.includes('text/csv'))) {
    return '.csv';
  } else {
    return '.pdf';
  }
};

export const handleDownload = (url, options, filename) => {
  var contentType = '';
  fetch(url, options)
    .then(response => {
      contentType = determineContentType(response);
      return response.blob();
    })
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = filename + contentType; // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
};

export const getStartEndTime = (timeframe, lastKnown) => {
  let endtime = new Date();
  let starttime = new Date();

  if (timeframe.toLowerCase() === 'custom') {
    starttime = new Date(lastKnown);
    starttime.setMinutes(starttime.getMinutes() + 1);
    return {
      endtime,
      starttime
    };
  }

  if (timeframe.toLowerCase() === '1m') {
    starttime.setMonth(starttime.getMonth() - 1);
  } else if (timeframe.toLowerCase() === '1w') {
    starttime.setDate(starttime.getDate() - 7);
  } else if (timeframe.toLowerCase() === '1d') {
    starttime.setDate(starttime.getDate() - 1);
  } else if (timeframe.toLowerCase() === '24h') {
    starttime.setDate(starttime.getDate() - 1);
  }

  return {
    endtime,
    starttime
  };
};

export const getAssetNameFromSecurityField = asset => {
  return SECURITY_CODE_NAME_MAP[asset.first_asset];
};

export const extractAssetsFromSecurityCode = security => {
  return CMT_SECURITIES[security];
};

export const removeDuplicates = array => {
  return array.filter((value, index) => array.indexOf(value) === index);
};

const ASCENDING = 'ASCENDING';

export const sort = (elements, order, column) => {
  if (order === ASCENDING) {
    elements?.sort(function (a, b) {
      return a?.[column] < b?.[column] ? -1 : a?.[column] > b?.[column] ? 1 : 0;
    });
  } else {
    elements?.sort(function (a, b) {
      return a?.[column] < b?.[column] ? 1 : a?.[column] > b?.[column] ? -1 : 0;
    });
  }
  return elements;
};

export const getFirstFiveElementFromArray = array => array.slice(0, 5);

/**
 * Checks whether the redux data has been loaded or not.
 *
 * @param data {any[] | {loaded:boolean}}}? - Redux data or an array of any data
 * @returns {boolean} - True if the data has been loaded, false otherwise
 */
export const isNotLoaded = data => {
  return !data || !data.loaded || data.length === 0;
};
