import { fetchStakingRequest, fetchStakingRewardsRequest } from '../../redux/actions/transactionsExternal';
import StakingAndRewardsTableAdmin from './StakingAndRewardsTableAdmin';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  events: state.transactions.events
});

const mapDispatchToProps = {
  fetchStakingRequest,
  fetchStakingRewardsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(StakingAndRewardsTableAdmin);
