import VLInput from '../../VLInput/VLInput';
import VLSelect from '../../VLSelect';

export const renderInput = ({ name, label, isRequired, value, onChange, error }) => {
  return (
    <div>
      <p className="system-form-control-placeholder">
        {label}
        {isRequired ? <span className="sfc-mandatory-field">*</span> : null}
      </p>
      <div className="sfcb-transparent">
        <VLInput onChange={onChange} value={value} size="s" name={name} type="text" maxLength={50} error={error} />
        {error ? <span style={{ color: 'red' }}>{error}</span> : null}
      </div>
    </div>
  );
};

export const renderSelect = ({ name, label, isRequired, options, onChange, value, error }) => {
  return (
    <div>
      <p className="system-form-control-placeholder">
        {label}
        {isRequired ? <span className="sfc-mandatory-field">*</span> : null}
      </p>
      <div className="create-new-user-control-box system-form-control-box sfcb-transparent">
        <VLSelect
          className={`system-form-control ${error ? 'error' : ''}`}
          name={name}
          value={value}
          options={options}
          onChange={onChange}
        />
        {error ? <span className="create-account-modal-error">{error}</span> : null}
      </div>
    </div>
  );
};

export const mapValuesListToOptions = values => {
  return values.map(v => ({ label: v, value: v }));
};
