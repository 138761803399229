// Helper constants used for time range generation.
const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const SIX_HOURS = 6 * HOUR;
const TWO_DAYS = 2 * DAY;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const THREE_MONTHS = 3 * MONTH;
const SIX_MONTHS = 6 * MONTH;
const YEAR = 365 * DAY;

/**
 * Enum class representing valid time ranges for the AdvancedChart.
 */
export default class AdvancedChartRange {
  static MINUTE = new AdvancedChartRange('1m', 'MINUTE');
  static HOUR = new AdvancedChartRange('1H', 'HOUR');
  static DAY = new AdvancedChartRange('1D', 'DAY');
  static WEEK = new AdvancedChartRange('1W', 'WEEK');
  static MONTH = new AdvancedChartRange('1M', 'MONTH');
  static THREE_MONTHS = new AdvancedChartRange('3M', 'THREE_MONTHS');
  static SIX_MONTHS = new AdvancedChartRange('6M', 'SIX_MONTHS');
  static YEAR = new AdvancedChartRange('1Y', 'YEAR');
  static ALL = new AdvancedChartRange('ALL', 'ALL');

  static getRangeChoices() {
    return [
      AdvancedChartRange.MINUTE,
      AdvancedChartRange.HOUR,
      AdvancedChartRange.DAY,
      AdvancedChartRange.WEEK,
      AdvancedChartRange.MONTH,
      AdvancedChartRange.THREE_MONTHS,
      AdvancedChartRange.SIX_MONTHS,
      AdvancedChartRange.YEAR,
      AdvancedChartRange.ALL
    ];
  }

  label = null;
  value = null;

  constructor(label, value) {
    this.label = label;
    this.value = value;
  }

  /**
   * Returns a from-to range in seconds for the given time period. Range is taken
   * from the current date.
   * @return {{from: number, to: number}}
   */
  getRange() {
    return AdvancedChartRange.parseRange(this.value);
  }

  getStart(time = new Date()) {
    const retval = new Date();
    retval.setMilliseconds(0);
    switch (this.value) {
      case AdvancedChartRange.MINUTE.value:
        retval.setTime(time.getTime() - MINUTE);
        break;
      case AdvancedChartRange.HOUR.value:
        retval.setTime(time.getTime() - HOUR);
        break;
      case AdvancedChartRange.DAY.value:
        retval.setTime(time.getTime() - DAY);
        break;
      case AdvancedChartRange.WEEK.value:
        retval.setTime(time.getTime() - WEEK);
        break;
      case AdvancedChartRange.MONTH.value:
        retval.setTime(time.getTime() - MONTH);
        break;
      case AdvancedChartRange.THREE_MONTHS.value:
        retval.setTime(time.getTime() - THREE_MONTHS);
        break;
      case AdvancedChartRange.SIX_MONTHS.value:
        retval.setTime(time.getTime() - SIX_MONTHS);
        break;
      case AdvancedChartRange.YEAR.value:
        retval.setTime(time.getTime() - YEAR);
        break;
      default:
        retval.setTime(0);
    }
    return retval;
  }

  /**
   * Utility method to get the time range for the chart. Time range is based on
   * the selected time period. This function tries to generate a range that makes
   * sense for the selected time period given the data available.
   *
   * @param value
   * @returns {{from: number, to: number}}
   */
  static parseRange(value) {
    const to = new Date();
    to.setMilliseconds(0);
    const from = new Date();
    from.setMilliseconds(0);
    switch (value) {
      case AdvancedChartRange.MINUTE.value:
        from.setTime(to.getTime() - SIX_HOURS);
        break;
      case AdvancedChartRange.HOUR.value:
        from.setTime(to.getTime() - TWO_DAYS);
        break;
      case AdvancedChartRange.DAY.value:
        from.setTime(to.getTime() - MONTH);
        break;
      case AdvancedChartRange.WEEK.value:
        from.setTime(to.getTime() - THREE_MONTHS);
        break;
      case AdvancedChartRange.YEAR.value:
        from.setTime(to.getTime() - YEAR);
        break;
      default:
        from.setTime(0);
    }
    // Chart excepts time as seconds
    return { from: from.getTime() / 1000, to: to.getTime() / 1000 };
  }

  equals(other) {
    return this.value === other.value;
  }

  toString() {
    return this.value;
  }
}
