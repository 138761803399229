import * as CONSTANTS from '../constants/analytics';

const initialState = {
  pieAssets: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  pieTransactions: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  lineAssets: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  lineTransactions: {
    data: [],
    loaded: false,
    loading: false,
    error: ''
  },
  transactionById: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ANALYTICS_DATA_ALL: {
      return {
        ...state,
        pieAssets: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        },
        pieTransactions: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        },
        lineAssets: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        },
        lineTransactions: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_ANALYTICS_DATA_ALL_SUCCESS: {
      const data = action.data.data;
      return {
        ...state,
        pieAssets: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        },
        pieTransactions: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        },
        lineAssets: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        },
        lineTransactions: {
          data: [...data],
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_ANALYTICS_DATA_ALL_ERROR: {
      return {
        ...state,
        pieAssets: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        },
        pieTransactions: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        },
        lineAssets: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        },
        lineTransactions: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    }

    case CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA: {
      const field = action.data.field;
      return {
        ...state,
        [field]: {
          ...state[field],
          data: [],
          loading: true,
          loaded: false,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA_SUCCESS: {
      const field = action.data.field;
      return {
        ...state,
        [field]: {
          ...state[field],
          data: [...action.data.data],
          loading: false,
          loaded: true,
          error: ''
        }
      };
    }
    case CONSTANTS.FETCH_SPECIFIC_ANALYTICS_DATA_ERROR: {
      const field = action.data.field;
      return {
        ...state,
        [field]: {
          ...state[field],
          data: [],
          loading: false,
          loaded: false,
          error: action.data.error
        }
      };
    }
    case CONSTANTS.GET_TRANSACTION_BY_ID: {
      return {
        ...state,
        transactionById: {
          data: [],
          loaded: false,
          loading: true,
          error: ''
        }
      };
    }
    case CONSTANTS.GET_TRANSACTION_BY_ID_SUCCESS: {
      const data = action.data.data;
      return {
        ...state,
        transactionById: {
          data: data,
          loaded: true,
          loading: false,
          error: ''
        }
      };
    }
    case CONSTANTS.GET_TRANSACTION_BY_ID_ERROR:
      return {
        ...state,
        transactionById: {
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };

    default:
      return state;
  }
};
