import './AreasplineChart.scss';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PureComponent } from 'react';
import React from 'react';

const LINE_COLOR_ASSET = {
  BTC: '#F7931A',
  ETH: '#1a60f7',
  VL$: '#51555e',
  USD: '#85bb65'
};
const LINE_COLOR_TRANSACTIONS = '#11CF3D';

class AreasplineChart extends PureComponent {
  state = {
    lineHistoricAssetData: [
      {
        name: 'Btc',
        data: [
          [1621980000000, 37976.28],

          [1621980060000, 38066.49],

          [1621980120000, 38007.46],

          [1621980180000, 38035.86],

          [1621980240000, 37985],

          [1621980300000, 37976.29],

          [1621980360000, 38171.58],

          [1621980480000, 38039.34],

          [1621980540000, 38044.99],

          [1621980600000, 38034.66],

          [1621980660000, 38064.54],

          [1621980720000, 38155.9],

          [1621980780000, 38139.37],

          [1621980840000, 38176.31],

          [1621980900000, 38165.92],

          [1621980960000, 38099.98],

          [1621981020000, 38068.68],

          [1621981080000, 38088.32],

          [1621981140000, 38173.24],

          [1621981200000, 38201.31],

          [1621981260000, 38169.36],

          [1621981320000, 38104.78],

          [1621981380000, 38111.45],

          [1621981440000, 38162.42],

          [1621981500000, 38112.42],

          [1621981560000, 38079.23],

          [1621981620000, 38116.09],

          [1621981680000, 38157.85],

          [1621981740000, 38206.61],

          [1621981800000, 38290.27],

          [1621981860000, 38245.11],

          [1621981920000, 38148.69],

          [1621981980000, 38184.97],

          [1621982040000, 38142],

          [1621982100000, 38160.63],

          [1621982160000, 38126.7],

          [1621982220000, 38148.42],

          [1621982280000, 38153.59],

          [1621982340000, 38200.83],

          [1621982400000, 38222.12],

          [1621982460000, 38212.91],

          [1621982520000, 38220],

          [1621982580000, 38237.8],

          [1621982640000, 38263.69],

          [1621982700000, 38407.39],

          [1621982760000, 38538.95],

          [1621982820000, 38505.5],

          [1621989360000, 38070.49],

          [1621989420000, 38077.94],

          [1621989480000, 38105.25],

          [1621989540000, 38139.36],

          [1621989600000, 38120.7],

          [1621989660000, 38062.38],

          [1621989720000, 38056.87],

          [1621989780000, 38078.05],

          [1621989840000, 38040.99],

          [1621989900000, 38013.73],

          [1621989960000, 38095.03],

          [1621990020000, 38123.87],

          [1621990080000, 38068.17],

          [1621990140000, 38147.86],

          [1621990200000, 38161.81],

          [1621990260000, 38211.78],

          [1621990320000, 38172.81],

          [1621994160000, 38741.13],

          [1621994220000, 38748.39],

          [1621994280000, 38748.15],

          [1621994340000, 38696.9]
        ]
      },
      {
        name: 'Eth',
        data: [
          [1621980000000, 37976.28],

          [1621980060000, 37066.49],

          [1621980120000, 37007.46],

          [1621980180000, 37035.86],

          [1621980240000, 36985],

          [1621980300000, 36976.29],

          [1621980360000, 37171.58],

          [1621980480000, 37039.34],

          [1621980540000, 37044.99],

          [1621980600000, 37034.66],

          [1621980660000, 37064.54],

          [1621980720000, 37155.9],

          [1621980780000, 37139.37],

          [1621980840000, 37176.31],

          [1621980900000, 37165.92],

          [1621980960000, 37099.98],

          [1621981020000, 37068.68],

          [1621981080000, 37088.32],

          [1621981140000, 37173.24],

          [1621981200000, 37201.31],

          [1621981260000, 37169.36],

          [1621981320000, 37104.78],

          [1621981380000, 37111.45],

          [1621981440000, 37162.42],

          [1621981500000, 37112.42],

          [1621981560000, 37079.23],

          [1621981620000, 37116.09],

          [1621981680000, 37157.85],

          [1621981740000, 37206.61],

          [1621981800000, 37290.27],

          [1621981860000, 37245.11],

          [1621981920000, 37148.69],

          [1621981980000, 37184.97],

          [1621982040000, 37142],

          [1621982100000, 37160.63],

          [1621982160000, 37126.7],

          [1621982220000, 37148.42],

          [1621982280000, 37153.59],

          [1621982340000, 37200.83],

          [1621982400000, 37222.12],

          [1621982460000, 37212.91],

          [1621982520000, 37220],

          [1621982580000, 37237.8],

          [1621982640000, 37263.69],

          [1621982700000, 37407.39],

          [1621982760000, 37538.95],

          [1621982820000, 37505.5],

          [1621989360000, 37070.49],

          [1621989420000, 37077.94],

          [1621989480000, 37105.25],

          [1621989540000, 37239.36],

          [1621989600000, 37320.7],

          [1621989660000, 37562.38],

          [1621989720000, 37756.87],

          [1621989780000, 37878.05],

          [1621989840000, 38040.99],

          [1621989900000, 38013.73],

          [1621989960000, 38095.03],

          [1621990020000, 38123.87],

          [1621990080000, 38068.17],

          [1621990140000, 38147.86],

          [1621990200000, 38161.81],

          [1621990260000, 38211.78],

          [1621990320000, 38172.81],

          [1621994160000, 38741.13],

          [1621994220000, 38748.39],

          [1621994280000, 38748.15],

          [1621994340000, 38696.9]
        ]
      }
    ],
    lineNumTransactionAssetHistoricData: [
      {
        name: 'Vl$',
        data: [
          [1621982880000, 38693.88],

          [1621982940000, 38615.7],

          [1621983000000, 38646.51],

          [1621983060000, 38538.93],

          [1621983120000, 38547.35],

          [1621983180000, 38532.89],

          [1621983240000, 38511.18],

          [1621983300000, 38529.47],

          [1621983360000, 38404.06],

          [1621983420000, 38480.23],

          [1621983480000, 38470.61],

          [1621983540000, 38377.36],

          [1621983600000, 38439.52],

          [1621983660000, 38402.63],

          [1621983720000, 38374.39],

          [1621983780000, 38353.5],

          [1621983840000, 38329.66],

          [1621983900000, 38323.69],

          [1621983960000, 38326.59],

          [1621984020000, 38329.63],

          [1621984080000, 38304.2],

          [1621984140000, 38273.67],

          [1621984200000, 38237.44],

          [1621984260000, 38194.82],

          [1621984320000, 38229.31],

          [1621984380000, 38143.26],

          [1621984440000, 38213.6],

          [1621984500000, 38252.42],

          [1621984560000, 38300],

          [1621984620000, 38211.35],

          [1621984680000, 38240.82],

          [1621984740000, 38248.62],

          [1621984800000, 38267.77],

          [1621984860000, 38311.57],

          [1621984920000, 38319.02],

          [1621984980000, 38384.03],

          [1621985040000, 38353.2],

          [1621985100000, 38309.51],

          [1621985160000, 38334.91],

          [1621985220000, 38367.03],

          [1621985280000, 38316.51],

          [1621985340000, 38290.61],

          [1621985400000, 38283.31]
        ]
      }
    ]
  };
  buildLineSeries = (first = false, mockName, data) => {
    const color = first ? LINE_COLOR_ASSET : LINE_COLOR_TRANSACTIONS;
    const securityName = data[0].name;
    let baseSeriesObject = {
      name: mockName,
      // data: [...this.state.lineNumTransactionAssetHistoricData],
      color: color,
      showInLegend: false,
      gapSize: 5,
      tooltip: {
        valueDecimals: 2
      },
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, Highcharts.color(color).setOpacity(0.8).get('rgba')],
          [1, Highcharts.color(color).setOpacity(0.2).get('rgba')]
        ]
      },
      threshold: null
    };
    if (!first) {
      // baseSeriesObject.data = [
      //   ...this.state.lineNumTransactionAssetHistoricData[0].data
      // ];
      baseSeriesObject = {
        ...baseSeriesObject,
        data: [...this.state.lineNumTransactionAssetHistoricData[0].data],
        name: this.state.lineNumTransactionAssetHistoricData[0].name
      };
      return [baseSeriesObject];
    } else {
      return data.map(data => {
        return {
          ...baseSeriesObject,
          data: [...data.data],
          color: color[securityName],
          name: data.name,
          fillColor: {
            ...baseSeriesObject.fillColor,
            stops: [
              [0, Highcharts.color(color[securityName]).setOpacity(0.8).get('rgba')],
              [1, Highcharts.color(color[securityName]).setOpacity(0.2).get('rgba')]
            ]
          }
        };
      });
    }
  };

  getMockLineOptions = (first = false, data) => {
    const mockName = first ? 'Asset Type Value' : 'Transactions per Asset Type';
    const options = {
      chart: {
        type: 'areaspline',
        styleMode: true,
        height: 229,
        // height: 85,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 0,
        animation: Highcharts.svg,
        backgroundColor: 'transparent',
        events: {
          //   load: function () {
          //     // set up the updating of the chart each second
          //     if (
          //       self.state.timeframe.toLowerCase() == '24h' ||
          //       self.state.timeframe.toLowerCase() == '1d'
          //     ) {
          //       self.chartOnloadFunction(this, self);
          //     }
          //   }
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        valueDecimals: 2
        // split: true
      },
      title: {
        // enabled: false,
        // text: mockName,
        text: '',
        align: 'left',
        x: 30,
        y: 30,
        style: {
          color: '#0D036B'
        }
      },
      accessibility: {
        announceNewData: {
          enabled: true,
          minAnnounceInterval: 15000,
          announcementFormatter: function (allSeries, newSeries, newPoint) {
            if (newPoint) {
              return 'New point added. Value: ' + newPoint.y;
            }
            return false;
          }
        }
      },
      xAxis: {
        // lineWidth: 0,
        type: 'datetime',
        // tickWidth: 0,
        minPadding: 0,
        maxPadding: 0,
        labels: {
          enabled: false
        },
        title: {
          enabled: false
        }
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          // enabled: false
        },
        title: {
          enabled: true,
          text: 'Price'
        }
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      },
      series: [...this.buildLineSeries(first, mockName, data)],
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        },
        series: {
          marker: {
            enabled: true
          }
        }
      },
      credits: {
        enabled: false
      },
      rangeSelector: {
        allButtonsEnabled: true
      }
    };

    return options;
  };

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.getMockLineOptions(this.props.mockMultipleLine, this.props.data)}
        allowChartUpdate={true}
        {...this.props}
      />
    );
  }
}

export default AreasplineChart;
