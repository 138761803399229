import { Table } from 'react-bootstrap';
import Timer from '../../Timer';
import {
  formatDate,
  isValidDate,
  sortByDateDescending,
  getLocalisedNumericString,
  extractAssetsFromSecurityCode
} from '../../../utils/generalUtils';
import { getDecimalByFraction } from '../../../utils/transactionsHelpers';
import { FRACTIONS } from '../../../enums/validation';
import { TIME_ZONE } from '../../../enums/time';

const IOIsTable = props => {
  let iois = [];
  iois = iois.concat(props.openOrders.filter(o => o.ioi));
  iois = iois.concat(props.ioiInbound);

  return (
    <div className="overflow">
      {iois.length > 0 ? (
        <Table responsive borderless>
          <thead>
            <tr className="order-blotter-tables__th">
              <th>Asset</th>
              <th>Side</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Txn ID</th>
              <th>TIF</th>
              <th>Expire date</th>
              <th>Expires in</th>
              <th>Type</th>
            </tr>
          </thead>

          <tbody>
            {sortByDateDescending(iois)?.map(order => {
              const expdate = formatDate(new Date(parseInt(order.exptime)), {});
              const assets = extractAssetsFromSecurityCode(order.security);
              return (
                <tr
                  onClick={() => props.toggleTransactionDetails(order)}
                  key={order.refno}
                  className={`order-blotter-tables__td qms-item ${order.type === 'QUOTE' && 'blink'}`}
                >
                  <td>{order.security}</td>
                  <td className={`order-blotter-tables__${order.side}`}>{order.side}</td>

                  <td>
                    {getLocalisedNumericString(order.liveqty, false, getDecimalByFraction(FRACTIONS[assets?.base]))}
                  </td>
                  <td>
                    {' '}
                    {getLocalisedNumericString(order.price, false, getDecimalByFraction(FRACTIONS[assets?.quote]))}
                  </td>
                  <td>{order.refno}</td>
                  <td>{order.tif}</td>
                  <td>{isValidDate(new Date(parseInt(order.exptime))) ? expdate : 'N/A'}</td>
                  <td>
                    {isValidDate(new Date(parseInt(order.exptime))) ? (
                      <Timer startTime={parseInt(order.exptime)} changeInterval={1000} timezone={TIME_ZONE} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td>{order.type}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="no-orders-msg">No open IOIs</div>
      )}
    </div>
  );
};
export default IOIsTable;
