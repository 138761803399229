import React from 'react';
import i18next from 'i18next';
const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-disclaimer">{i18next.t('footer.F')}</p>
    </footer>
  );
};

export default Footer;
