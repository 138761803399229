import AccountUsdEquivalent from './AccountUsdEquivalent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  assets: state.transactions.tradingAssets.data,
  cryptoRates: state.transactions.cryptoRates,
  rewards: state.externalTransactions.stakingRewardsSummary
});

const mapDispatchToProps = {};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountUsdEquivalent);
