import './ExternalWithdraw.scss';
import { getAssetWalletAddress, getFeesPerAsset, queryBalanceWalletPos } from '../../redux/actions/websocket';
import { getAssets, getAssetsByProduct, getDepositAccounts, toggleModal } from '../../redux/actions/transactions';
import {
  createExternalTransactionRequest,
  createExternalWithdrawUSDRequest,
  fetchExternalTransactionSupportedAssets,
  getTransactionFeeRequest
} from '../../redux/actions/transactionsExternal';
import ExternalWithdraw from './ExternalWithdraw';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  user: state.login.userData?.data,
  accounts: state.transactions.accounts,
  assets: state.transactions.assetsByProduct,
  websocket: state.websocket.websocket,
  positions: state.websocket.positions,
  cryptoRates: state.transactions.cryptoRates,
  cryptoTransactionFee: state.externalTransactions.cryptoTransactionFee.data,
  supportedAssets: state.externalTransactions.supportedAssets,
  currentAccountNumber: state.administration.currentAccountNumber.data
});

const mapDispatchToProps = {
  getAssets,
  toggleModal,
  queryBalanceWalletPos,
  getAssetWalletAddress,
  getFeesPerAsset,
  getAssetsByProduct,
  createExternalTransactionRequest,
  createExternalWithdrawUSDRequest,
  getTransactionFeeRequest,
  getDepositAccounts,
  fetchExternalTransactionSupportedAssets: fetchExternalTransactionSupportedAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalWithdraw);
