import './TransactionSuccessScreen.scss';

import TransactionSuccessScreen from './TransactionSuccessScreen';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resetTransactionHash } from '../../redux/actions/transactions';
import { withTranslation } from 'react-i18next';
const mapDispatchToProps = {
  resetTransactionHash
};

export default compose(withTranslation('translations'), connect(null, mapDispatchToProps))(TransactionSuccessScreen);
