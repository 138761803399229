import {
  UPDATE_VALUE_ACTION,
  SET_ERROR_ACTION,
  CLEAR_ERRORS_ACTION,
  ADD_LIST_ITEM_ACTION,
  REMOVE_LIST_ITEM_ACTION,
  UPDATE_LIST_ITEM_VALUE_ACTION,
  SET_LIST_ITEM_ERROR_ACTION,
  REMOVE_ALL_LIST_ITEMS_ACTION,
  CLEAR_LIST_ITEM_ERRORS_ACTION,
  SET_HAS_WALLETS,
  SET_HAS_EXCHANGES,
  SET_LOADING_ACTION
} from './actions';

// WALLETS_LIST_NAME and EXCHANGES_LIST_NAME with generic list actions, see description in the actions.js
export const WALLETS_LIST_NAME = 'wallets';
export const EXCHANGES_LIST_NAME = 'exchanges';

export const HAS_EXCHANGES_VALUES = {
  YES: 'yes',
  NO: 'no'
};

export const HAS_WALLETS_VALUES = {
  YES: 'yes',
  NO: 'no'
};

const defaultListStates = {
  [WALLETS_LIST_NAME]: {
    cryptoAsset: null,
    address: '',
    nickname: '',
    errors: {}
  },
  [EXCHANGES_LIST_NAME]: {
    nickname: '',
    errors: {}
  }
};

export const defaultState = {
  accountName: '',
  address1: '',
  address2: '',
  city: '',
  product: '',
  state: '',
  postalCode: '',
  country: '',
  primaryUse: '',
  depositsUsd: null,
  depositsNumber: null,
  depositsSource: null,
  withdrawalsUsd: null,
  withdrawalsNumber: null,
  hasWalletsOption: undefined, // possible values are undefined, true and false (undefined communicates that value was not set yet)
  hasExchangesOption: undefined, // possible values are undefined, true and false (undefined communicates that value was not set yet)
  [WALLETS_LIST_NAME]: [],
  [EXCHANGES_LIST_NAME]: [defaultListStates[EXCHANGES_LIST_NAME]],
  errors: {},
  isLoading: false,
  isProductReadOnly: false
};

export const createAccountReducer = (state, { type, payload }) => {
  if (type === UPDATE_VALUE_ACTION) {
    return {
      ...state,
      [payload.field]: payload.value
    };
  }
  if (type === SET_ERROR_ACTION) {
    return {
      ...state,
      errors: {
        ...state.errors,
        [payload.field]: payload.error
      }
    };
  }
  if (type === CLEAR_ERRORS_ACTION) {
    return {
      ...state,
      errors: defaultState.errors
    };
  }
  if (type === ADD_LIST_ITEM_ACTION) {
    return {
      ...state,
      [payload.listName]: [...state[payload.listName], defaultListStates[payload.listName]]
    };
  }
  if (type === REMOVE_LIST_ITEM_ACTION) {
    return {
      ...state,
      [payload.listName]: state[payload.listName].filter((_, index) => index !== payload.index)
    };
  }
  if (type === UPDATE_LIST_ITEM_VALUE_ACTION) {
    return {
      ...state,
      [payload.listName]: state[payload.listName].map((item, index) => {
        if (index === payload.index) {
          return {
            ...state[payload.listName][index],
            [payload.field]: payload.value
          };
        }
        return item;
      })
    };
  }
  if (type === SET_LIST_ITEM_ERROR_ACTION) {
    return {
      ...state,
      [payload.listName]: state[payload.listName].map((item, index) => {
        if (index === payload.index) {
          return {
            ...state[payload.listName][index],
            errors: {
              ...state[payload.listName][index].errors,
              [payload.field]: payload.error
            }
          };
        }
        return item;
      })
    };
  }
  if (type === CLEAR_LIST_ITEM_ERRORS_ACTION) {
    return {
      ...state,
      [payload.listName]: state[payload.listName].map(item => {
        return {
          ...item,
          errors: {}
        };
      })
    };
  }
  if (type === REMOVE_ALL_LIST_ITEMS_ACTION) {
    return {
      ...state,
      [payload.listName]: []
    };
  }
  if (type === SET_HAS_WALLETS) {
    const wallets = payload.value === HAS_WALLETS_VALUES.YES ? [defaultListStates[WALLETS_LIST_NAME]] : [];
    return {
      ...state,
      hasWalletsOption: payload.value,
      wallets
    };
  }
  if (type === SET_HAS_EXCHANGES) {
    const exchanges = payload.value === HAS_EXCHANGES_VALUES.YES ? [defaultListStates[EXCHANGES_LIST_NAME]] : [];
    return {
      ...state,
      hasExchangesOption: payload.value,
      exchanges
    };
  }

  if (type === SET_LOADING_ACTION) {
    return {
      ...state,
      isLoading: payload.value
    };
  }

  throw Error(`createAccountReducer: unknown action type ${type}`);
};
