export const ROLES = Object.freeze({
  SUPER_USER: 'superadmin',
  ADMIN: 'firmadmin',
  USER: 'firmuser',
  TRANSACTIONAL: 'Transactional',
  SETUP: 'Setup',
  All: 'all'
});

export const PRODUCT_TYPES = Object.freeze({
  PCA: 'PCA',
  SCA: 'SCA'
});
export const ACCOUNT_TYPES = Object.freeze({
  CORPORATE: 'Corporate',
  INDIVIDUAL: 'Individual'
});

export const SUPER_USER_TYPES = Object.freeze({
  BTA_MANAGER: 'BTA_MANAGER',
  BANK_MANAGER: 'BANK_MANAGER',
  VL_ADMIN: 'VL_ADMIN'
});

export const AUTHORIZATION_KEYS = Object.freeze({
  VIEW: 'VIEW',
  TRANSACTION: 'TRANSACTION',
  APPROVE: 'APPROVE',
  WHITELIST: 'WHITELIST'
});

export const AUTHORIZATIONS = Object.freeze({
  [AUTHORIZATION_KEYS.VIEW]: 'R',
  [AUTHORIZATION_KEYS.TRANSACTION]: 'W',
  [AUTHORIZATION_KEYS.APPROVE]: 'A',
  [AUTHORIZATION_KEYS.WHITELIST]: 'Whitelist'
});

export const TURN_OFF_AUTH_KEY = Object.freeze({
  [AUTHORIZATION_KEYS.VIEW]: 'D',
  [AUTHORIZATION_KEYS.TRANSACTION]: 'R',
  [AUTHORIZATION_KEYS.APPROVE]: 'W'
});
