import * as React from 'react';
import { useEffect, useState } from 'react';
import AdvancedChart from '../Charts/AdvancedChart/AdvancedChart';
import AdvancedChartRange from '../Charts/AdvancedChart/AdvancedChartRange';
import { getLocalisedDecimalString } from '../../utils/generalUtils';

export default function TradeChart({ fetchChartData, chartData, selectedPair, mode }) {
  const [time, setTime] = useState(AdvancedChartRange.MINUTE);

  useEffect(() => {
    if (selectedPair) {
      const { security } = selectedPair;
      fetchChartData({ security, time: time.value });
    }
  }, [selectedPair, time]);

  return (
    <>
      <AdvancedChart
        mode={mode}
        loading={chartData.loading}
        data={chartData.data}
        priceFormat={value => getLocalisedDecimalString(value, selectedPair?.pair_second ?? 'USD')}
        onTimeSelect={setTime}
      />
    </>
  );
}
