import * as React from 'react';
import { PureComponent } from 'react';
import { createChart } from 'lightweight-charts';
import { LIGHT_MODE } from '../../../enums/validation';
import './AdvancedChart.scss';
import LoadingIndicator from '../../LoadingIndicator';
import AdvancedChartRange from './AdvancedChartRange';
import { getLocalisedDecimalString } from '../../../utils/generalUtils';
import PropTypes from 'prop-types';
import VLButton from '../../Buttons/VLButton';

const getGridColor = mode => (mode === LIGHT_MODE ? '#ffffff' : '#9DA5AF');
const getBackColor = mode => (mode === LIGHT_MODE ? '#e5edf6' : '#001f3d');
const getTextColor = mode => (mode === LIGHT_MODE ? '#0d036b' : '#f9fbfd');
const getUpDownColors = () => ({ upColor: '#47e29f', downColor: '#fc6a7e' });

/**
 * Enum class representing the different with which {@link AdvancedChart} can be
 * configured.
 */
export class AdvancedChartType {
  static CANDLESTICK = 'candlestick';
  static LINE = 'line';
  static BAR = 'bar';
  static AREA = 'area';
}

/**
 * AdvancedChart component that is used in the Trade view.
 */
class AdvancedChart extends PureComponent {
  chart = null;

  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.handleTimeSelect = this.handleTimeSelect.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      selectedRange: props.selectedRange
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.applyOptions();

    // We only need to change the visibleRange if the selectedRange has changed.
    const rangeChanged = this.state.selectedRange && !prevState.selectedRange.equals(this.state.selectedRange);
    const dataChanged = prevProps.data.length !== this.props.data.length && this.props.data.length > 0;

    if (dataChanged || rangeChanged) {
      const range = this.state.selectedRange.getRange();
      this.chart.timeScale().setVisibleRange(range);
    }
    if (rangeChanged) {
      this.props.onMouseLeave(null);
    }
  }

  componentDidMount() {
    this.chart = createChart(this.props.containerId, {
      width: this.props.width ?? this.chartContainerRef.current.clientWidth,
      height: this.props.height ?? this.chartContainerRef.current.clientHeight
    });

    const throttle = callback => {
      let time = undefined;
      return function (params) {
        if (time !== params.time) {
          callback(params);
          time = params.time;
        }
      };
    };

    this.onHoverCallback = throttle(this.props.onHover);
    this.chart.subscribeCrosshairMove(this.onHoverCallback);
    this.chartContainerRef.current.addEventListener('mouseleave', this.props.onMouseLeave);

    // If there is a need for other types of series to be displayed. Otherwise,
    // use candlestick series.
    switch (this.props.type) {
      case AdvancedChartType.BAR:
        this.series = this.chart.addBarSeries({
          thinBars: true,
          ...getUpDownColors()
        });
        break;
      case AdvancedChartType.LINE:
        this.series = this.chart.addLineSeries();
        break;
      case AdvancedChartType.AREA:
        this.series = this.chart.addAreaSeries();
        break;
      default:
        this.series = this.chart.addCandlestickSeries({ ...getUpDownColors() });
    }
    this.series.applyOptions({
      priceFormat: { type: 'custom', formatter: this.props.priceFormat },
      priceLineVisible: this.props.priceLineVisible
    });

    window.addEventListener('resize', this.handleResize);

    this.applyOptions();
  }

  componentWillUnmount() {
    // Like good citizens, we clean up after ourselves.
    this.chartContainerRef.current.removeEventListener('mouseleave', this.props.onMouseLeave);
    if (this.chart !== null) {
      this.chart.unsubscribeCrosshairMove(this.onHoverCallback);
      this.chart.remove();
      this.chart = null;
    }
    window.removeEventListener('resize', this.handleResize);
  }

  applyOptions() {
    const borderColor = this.props.borderColor ?? getGridColor(this.props.mode);
    const backgroundColor = this.props.backgroundColor ?? getBackColor(this.props.mode);
    const textColor = this.props.textColor ?? getTextColor(this.props.mode);

    if (this.props.areaColors) {
      this.series.applyOptions({
        ...this.props.areaColors[this.props.mode]
      });
    }

    this.chart.applyOptions({
      layout: {
        backgroundColor,
        textColor
      },
      grid: {
        horzLines: { color: borderColor },
        vertLines: { color: borderColor }
      },
      rightPriceScale: {
        borderColor,
        visible: this.props.priceScaleVisible
      },
      timeScale: {
        // Prevents scrolling off the data populated area.
        fixLeftEdge: true,
        fixRightEdge: true,
        borderColor,
        timeVisible: true
      }
    });

    this.series.setData((this.props.data ?? []).sort((a, b) => a.time - b.time));
  }

  handleResize() {
    this.chart.applyOptions({
      width: this.chartContainerRef.current.clientWidth
    });
  }

  handleTimeSelect(selectedRange) {
    this.setState({ selectedRange }, () => this.props.onTimeSelect(selectedRange));
  }

  render() {
    return (
      <div className="AdvancedChartWrapper" ref={this.chartContainerRef}>
        <div className="time-select">
          {this.props.rangeChoices.map(range => (
            <VLButton
              size="m"
              width="40px"
              variant={!this.state.selectedRange.equals(range) ? 'outline' : ''}
              onClick={() => this.handleTimeSelect(range)}
              text={range.label}
            />
          ))}
        </div>
        {this.props.loading && (
          <div className="chart-loading-indicator">
            <LoadingIndicator />
          </div>
        )}
        <div id={this.props.containerId} className={'AdvancedChart'} />
      </div>
    );
  }
}

AdvancedChart.propTypes = {
  /** @type Requireable<(price: number) => string>
   *  Function that formats the price shown in the price scale */
  priceFormat: PropTypes.func,

  /** Array of data to be displayed in the chart */
  data: PropTypes.array,

  /** Function that is called when the user selects a time range */
  onTimeSelect: PropTypes.func,

  /** Boolean that determines whether the price scale is visible */
  priceScaleVisible: PropTypes.bool,

  /** String that determines the type of chart to be displayed */
  type: PropTypes.oneOfType(AdvancedChartType),

  /** Boolean that determines whether the chart is loading */
  loading: PropTypes.bool,

  /** Array of {@link AdvancedChartRange} that determines the time ranges that
   * the user can select from */
  rangeChoices: PropTypes.array,

  /** @type Requireable<() => void>
   * Function that is called when the user moves the mouse out of the chart */
  onMouseLeave: PropTypes.func,

  /**
   * @type Requireable<(param: MouseEventParams) => void>
   * Function that is called when the user moves the mouse over the chart */
  onHover: PropTypes.func,

  /** String that determines the alignment of the range select buttons */
  alignButtons: PropTypes.oneOf(['left', 'right']),

  /** {@link AdvancedChartRange} that determines the initial time range */
  selectedRange: PropTypes.instanceOf(AdvancedChartRange),

  /** Number that determines the width of the chart */
  width: PropTypes.number,

  /** Boolean that determines whether the price line is visible */
  priceLineVisible: PropTypes.bool,

  /** String that determines the id of the container that the chart is
   * displayed in */
  containerId: PropTypes.string,

  /** Number that determines the height of the chart */
  height: PropTypes.number,

  /** String that determines the dark or light mode of the chart */
  mode: PropTypes.oneOf(['light', 'dark']),

  /** Colors of the area series when the chart is in {@link AdvancedChartType.AREA} mode */
  areaColors: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      lineColor: PropTypes.string,
      topColor: PropTypes.string,
      bottomColor: PropTypes.string
    })
  })
};

AdvancedChart.defaultProps = {
  mode: 'light',
  containerId: 'advanced_chart_container',
  data: [],
  height: 340, // Should match the height of the chart container minus padding times two minus time-select height.
  width: undefined,
  type: AdvancedChartType.CANDLESTICK,
  rangeChoices: AdvancedChartRange.getRangeChoices().slice(0, 5),
  alignButtons: 'left',
  onTimeSelect: () => void 0,
  priceFormat: price => getLocalisedDecimalString(price),
  onHover: params => void 0,
  onMouseLeave: ev => void 0,
  priceLineVisible: true,
  priceScaleVisible: true,
  loading: false,
  selectedRange: AdvancedChartRange.MINUTE
};

export default AdvancedChart;
