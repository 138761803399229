import React from 'react';

import VLButton from '../../Buttons/VLButton';

const TradeButton = ({ isDisabled, side, asset, onClick }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <VLButton
        variant={`${side === 'B' ? 'success' : 'danger'}`}
        width="100%"
        onClick={onClick}
        disabled={isDisabled}
        text={`${side === 'B' ? 'Buy' : 'Sell'} ${asset}`}
      />
    </div>
  );
};

export default TradeButton;
