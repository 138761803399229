import DashboardAccountDropdown from './DashboardAccountDropdown';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  accounts: state.transactions.accounts,
  accountPositions: state.websocket.positions
});

export default compose(withTranslation('translations'), connect(mapStateToProps, {}))(DashboardAccountDropdown);

export { default as AccountDropdown } from './AccountDropdown';
export { default as AccountUsdEquivalent } from './AccountUsdEquivalent';
