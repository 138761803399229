import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translationEng from './locales/en/translation.json';
import translationEsp from './locales/esp/translation.json';
i18n
  .use(initReactI18next)
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    //lng: ['esp'], // TODO: might need to remove later on
    fallbackLng: 'en',
    react: {
      useSuspense: false
    },
    keySeparator: '.',

    interpolation: {
      escapeValue: false
    },

    resources: {
      en: {
        translations: translationEng
      },
      esp: {
        translations: translationEsp
      }
    },

    ns: ['translations'],
    ns1: ['translationsEsp'],
    defaultNS: 'translations'
  });

export default i18n;
