import { SORT_ORDER } from '../enums/sort';

export const sort = (content, sortField, sortOrder) => {
  return content.sort((first, second) => {
    const firstItem = first?.[sortField]?.toString()?.toUpperCase();
    const secondItem = second?.[sortField]?.toString()?.toUpperCase();

    if (firstItem < secondItem) {
      return sortOrder === SORT_ORDER.ASCENDING ? -1 : 1;
    }

    if (firstItem > secondItem) {
      return sortOrder === SORT_ORDER.ASCENDING ? 1 : -1;
    }

    return 0;
  });
};
