import { connect } from 'react-redux';
import Reset from './Reset';
import { resetPasswordRequest } from '../../redux/actions/login';
import './Reset.scss';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  resetPasswordRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
