import React, { PureComponent } from 'react';

import { extractNameAndCodeFromLabel } from '../../utils/generalUtils';

class AccountNameCodeOption extends PureComponent {
  parseLabelData = label => {
    const { name, code } = extractNameAndCodeFromLabel(label);
    return (
      <div>
        {name} <sub>({code})</sub>
      </div>
    );
  };

  render() {
    const { label } = this.props;
    return label.includes('(') ? this.parseLabelData(label) : <div>{label}</div>;
  }
}

export default AccountNameCodeOption;
