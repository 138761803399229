import { getDecimalByFraction, getSideByAssetPair, isSelling } from '../../../utils/transactionsHelpers';
import { getLocalisedNumericString } from '../../../utils/generalUtils';
import { FRACTIONS, PENDING_API_TYPES } from '../../../enums/validation';

const getAcceptScreenProps = (transaction, pairDatas, trType) => {
  const isEscrow = trType === PENDING_API_TYPES.ESCROW;
  const type = trType;
  let sendAmount = '';
  let sendAsset = '';
  let receiveAmount = '';
  let receiveAsset = '';
  let account = transaction.firm;
  let counterparty = transaction.counteraccount;

  if (isEscrow) {
    const { pair } = getSideByAssetPair(pairDatas, transaction.security);

    let sendDecimal = '';
    let receiveDecimal = '';
    const isSellingEscrow = isSelling(transaction);

    if (!isSellingEscrow) {
      if (!transaction.security.includes('USD')) {
        sendAsset = pair?.pair_first;
        receiveAsset = pair?.pair_second;
      } else {
        sendAsset = pair?.pair_second;
        receiveAsset = pair?.pair_first;
      }

      sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
      receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
      sendAmount = getLocalisedNumericString(
        transaction.qty * transaction.price,

        false,
        sendDecimal || 0
      );
      receiveAmount = getLocalisedNumericString(transaction.qty, false, receiveDecimal || 0);
    } else {
      if (!transaction.security.includes('USD')) {
        sendAsset = pair?.pair_second;
        receiveAsset = pair?.pair_first;
      } else {
        sendAsset = pair?.pair_first;
        receiveAsset = pair?.pair_second;
      }
      sendDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_first]);
      receiveDecimal = getDecimalByFraction(FRACTIONS[pair?.pair_second]);
      sendAmount = getLocalisedNumericString(
        transaction.qty,

        false,
        sendDecimal || 0
      );
      receiveAmount = getLocalisedNumericString(transaction.qty * transaction.price, false, receiveDecimal || 0);
    }

    // account = transaction.brokers;
  } else {
    // It's an inbound request transaction
    sendAmount = getLocalisedNumericString(
      transaction.qty,
      false,
      getDecimalByFraction(FRACTIONS[transaction.security]) || 0
    );
    sendAsset = transaction.security;
  }
  let side = transaction.side;
  return {
    sendAmount,
    sendAsset,
    receiveAmount,
    receiveAsset,
    account,
    isEscrow,
    type,
    side,
    counterparty
  };
};

export default getAcceptScreenProps;
