import {
  acceptPaymentRequest,
  acceptPaymentRequestSuccess,
  getAssetWalletAddress,
  getFeesPerAsset,
  rejectPaymentRequest,
  rejectPaymentRequestSuccess
} from '../../../redux/actions/websocket';

import TransactionDetailsModal from './TransactionDetailsModal.js';
import { connect } from 'react-redux';
import {
  getAssets,
  getDepositAccounts,
  getRecipientFirms,
  resetFields,
  searchRecipientAccounts,
  toggleModal,
  updateTransactionsDB,
  getAccountTransactions
} from '../../../redux/actions/transactions';
const mapStateToProps = state => ({
  accounts: state.transactions.accounts.data,
  assetPairs: state.trade.assetPairs.data,
  socket: state.websocket.websocket,
  firms: state.transactions.recipientFirms,
  requests: state.websocket.requests,
  escrows: state.websocket.escrows,
  updatingTransactionDb: state.transactions.updatingTransactionDb,
  assets: state.transactions.assets,
  positions: state.websocket.positions,
  cryptoRates: state.transactions.cryptoRates,
  transactionById: state.analytics.transactionById.data,
  profileDetails: state.login.profileDetails,
  currentAccountNumber: state.administration.currentAccountNumber.data,
  userData: state.login.userData.data
});

const mapDispatchToProps = {
  rejectPaymentRequest,
  rejectPaymentRequestSuccess,
  getRecipientFirms,
  acceptPaymentRequest,
  acceptPaymentRequestSuccess,
  getAssetWalletAddress,
  updateTransactionsDB,
  getFeesPerAsset,
  getDepositAccounts,
  getAssets,
  resetFields,
  toggleModal,
  searchRecipientAccounts,
  getAccountTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsModal);
