import i18next from 'i18next';
import { useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import {
  MdCallMade,
  MdInfo,
  MdSwapHoriz,
  MdCheckBox,
  MdCallReceived,
  MdOutlineScheduleSend
} from 'react-icons/md';
import { formatDate } from '../../utils/generalUtils';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import './DashboardTransactionInternalTable.scss';

const APPROVED = 'approved';
const COMPLETED = 'Completed';
const PENDING = 'Pending';

const ARROWS_MAP = {
  escrow: MdSwapHoriz,
  request: MdCallReceived,
  scheduled_transfer: MdOutlineScheduleSend,
  ioi: MdSwapHoriz,
  send: MdCallMade,
  exchange: MdSwapHoriz,
  recieve: MdCallMade
};

const TRANSACTION_TYPES = {
  escrow: i18next.t('dashboardInternalTransactionsTable.escrow'),
  request: i18next.t('dashboardInternalTransactionsTable.request'),
  ioi: i18next.t('dashboardInternalTransactionsTable.ioi'),
  send: i18next.t('dashboardInternalTransactionsTable.send'),
  exchange: i18next.t('dashboardInternalTransactionsTable.exchange'),
  scheduled_transfer: i18next.t('dashboardInternalTransactionsTable.scheduledTransfer'),
  recieve: i18next.t('dashboardInternalTransactionsTable.receive')
};

const renderHeadings = () => {
  return (
    <>
      <th className="first-row-headers align-left">{i18next.t('dashboardInternalTransactionsTable.date')}</th>
      <th className="first-row-headers align-left">{i18next.t('dashboardInternalTransactionsTable.action')}</th>
      <th>{i18next.t('dashboardInternalTransactionsTable.asset')}</th>
      <th className="first-row-headers">
        <div>Send</div>
      </th>
      <th>{i18next.t('dashboardInternalTransactionsTable.amount')}</th>
      <th>{i18next.t('dashboardInternalTransactionsTable.asset')}</th>
      <th className="first-row-headers-receive">Receive</th>
      <th>{i18next.t('dashboardInternalTransactionsTable.amount')}</th>
      <th className="first-row-headers">{'Status'}</th>
    </>
  );
};

const getRotationClass = transactionType => {
  if (transactionType === 'escrow') return ' escrow-icon-rotation';
  else if (transactionType === 'exchange') return ' exchange-icon-rotation';
  else return '';
};

const getTransactionIcon = transactionType => {
  return ARROWS_MAP[transactionType];
};

const DashboardTransactionInternalTable = ({
  transactions,
  title,
  assetsPairs,
  toggleTransactionDetails,
  isReviewedTable
}) => {
  const [dataPageNumber, setDataPageNumber] = useState(0);

  const handlePageClick = () => {
    setDataPageNumber(dataPageNumber + 1);
  };

  const buildDashboardTableRow = transaction => {
    const { transactionType, ...toggleTransactionObject } = transaction;
    const type = TRANSACTION_TYPES[transactionType];
    const ArrowComponent = getTransactionIcon(transactionType);
    const rotationClass = getRotationClass(transactionType);
    return (
      <tr
        key={transaction.refno}
        onClick={() => {
          toggleTransactionDetails(toggleTransactionObject);
        }}
      >
        <td className="horizontal-alignment align-left">
          <p>{formatDate(transaction.date)}</p>
        </td>
        <td>
          <div>
            <div className="dashboard-dark-icon-row">
              <div className="transactions-flipped-icon-background">
                <ArrowComponent size="1.5em" className={`transaction-row-icon ${rotationClass}`} />
              </div>
              <span className={`pending-td-p transaction-td-p default text-align`}>{type}</span>
            </div>
          </div>
        </td>
        <td>
          <div>
            <span className="pending-td-p--bold">{transaction.sendSecurity || '.'}</span>
          </div>
        </td>
        <td className="empty-column">
          <div></div>
        </td>
        <td>
          <div>
            <span className="pending-td-p--bold">{transaction.transactionAmount || '.'}</span>
          </div>
        </td>
        <td>
          <div>
            <span className="pending-td-p--bold">{transaction.receiveSecurity || '.'}</span>
          </div>
        </td>
        <td className="empty-column">
          <div></div>
        </td>
        <td>
          <div>
            <span className="pending-td-p--bold">{transaction.receiveAmount || '.'}</span>
          </div>
        </td>
        <td>
          <div className=" internal-transactions-table-status-td">
            <span className="pending-td-p--bold">
              <Badge
                pill
                className={`status-field
                  ${transaction.transactionStatus === APPROVED ? 'status-badge-approved' : 'status-badge-pending'}`}
              >
                {transaction.transactionStatus === APPROVED ? COMPLETED : PENDING}
              </Badge>
            </span>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="dashboard-pending-table-container">
        <div className="pending-title-container">
          {isReviewedTable ? (
            <MdCheckBox className="internal-transactions-table-complete-icon" />
          ) : (
            <MdInfo className="pending-icon" />
          )}
          <h4 className="dashboard-internal-transactions-title">{title}</h4>
        </div>

        <InfiniteScroll className="dashboard-pending-table-wrap" loadMore={handlePageClick}>
          {transactions?.length === 0 || !assetsPairs.data?.length ? (
            <span className="dashboard-pending-table-message">
              {i18next.t('dashboardInternalTransactionsTable.thereAreNoPT')}
            </span>
          ) : (
            <Table borderless responsive className="dashboard-internal-transactions-table vl-table">
              <thead className="internal-transactions-headers">
                <tr>{renderHeadings()}</tr>
              </thead>
              <tbody>{transactions?.map(transaction => buildDashboardTableRow(transaction))}</tbody>
            </Table>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default DashboardTransactionInternalTable;
