import { Modal } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { MdKeyboardBackspace } from 'react-icons/md';
import AccountDetailsStep from './AccountDetailsStep';
import AccountDepositsWithdrawalsStep from './AccountDepositsWithdrawalsStep';
import AccountWalletsStep from './AccountWalletsStep';
import AcknowledgmentStep from './AcknowledgmentStep';
import RoundButton from '../../RoundButton';
import { useCreateAccountSteps } from './useCreateAccountSteps';
import { CreateAccountContextProvider, useCreateAccountContext } from './context/CreateAccountContext';
import { withProvider } from '../../../utils/withProvider';
import AccountExchangesStep from './AccountExchangesStep';
import './CreateAccountModal.scss';
import LoadingIndicator from '../../LoadingIndicator';
import { toast } from 'react-toastify';
import { PRODUCT_TYPES } from '../../../enums/roles';

const STEPS = {
  DETAILS: 'Details',
  ACTIVITY: 'Activity',
  WALLETS: 'Wallets',
  EXCHANGES: 'Exchanges',
  ACKNOWLEDGEMENT: 'Acknowledgment'
};

const PRO_STEPS_ORDER = [STEPS.DETAILS, STEPS.ACTIVITY, STEPS.WALLETS, STEPS.EXCHANGES, STEPS.ACKNOWLEDGEMENT];

const STANDARD_STEPS_ORDER = [STEPS.DETAILS];

const CreateAccountModal = ({ onClose, onSuccess, createAccountRequest, firm, fixedProduct }) => {
  const containerRef = useRef();
  const { formState, actions } = useCreateAccountContext();
  const { isLoading, product } = formState;
  const { setLoading, updateValue } = actions;
  const steps = product === PRODUCT_TYPES.PCA ? PRO_STEPS_ORDER : STANDARD_STEPS_ORDER;

  const { isFirstStep, isLastStep, currentStep, handleNextStep, handlePreviousStep } = useCreateAccountSteps(steps);

  const handleCreateAccount = () => {
    const { isLoading, errors, hasWallets, hasExchanges, ...data } = formState;

    const payload = {
      data: { ...data, firm },
      successCallback: () => {
        setLoading(false);
        toast.success('Account created successfully');
        onSuccess?.();
      },
      errorCallback: err => {
        toast.error(err);
        setLoading(false);
      }
    };

    setLoading(true);
    createAccountRequest(payload);
  };

  const handleNextClick = () => {
    if (isLastStep) {
      handleCreateAccount();
      return;
    }
    handleNextStep();
  };

  // When fixedProduct is passed as prop, user is not allowed to change it
  // This is used for Corporate firms which can have either all PCA or all SCA users
  useEffect(() => {
    if (fixedProduct) {
      updateValue('product', fixedProduct);
      updateValue('isProductReadOnly', true);
    }
  }, [fixedProduct, updateValue]);

  // After each step change scroll to top, so first element is visible
  useEffect(() => {
    containerRef?.current?.scrollTo(0, 0);
  }, [currentStep]);

  const renderForm = () => {
    const confirmButtonText = isLastStep ? 'Create account' : 'Next';

    if (currentStep === STEPS.DETAILS)
      return <AccountDetailsStep onNext={handleNextClick} confirmButtonText={confirmButtonText} />;
    if (currentStep === STEPS.ACTIVITY)
      return <AccountDepositsWithdrawalsStep onNext={handleNextClick} confirmButtonText={confirmButtonText} />;
    if (currentStep === STEPS.WALLETS)
      return <AccountWalletsStep onNext={handleNextClick} confirmButtonText={confirmButtonText} />;
    if (currentStep === STEPS.EXCHANGES)
      return <AccountExchangesStep onNext={handleNextClick} confirmButtonText={confirmButtonText} />;
    if (currentStep === STEPS.ACKNOWLEDGEMENT)
      return <AcknowledgmentStep onNext={handleNextClick} confirmButtonText={confirmButtonText} />;

    return 'STEP NOT IMPLEMENTED';
  };

  const renderStepper = () => {
    return (
      <div className="create-account-modal-stepper">
        {steps.map((step, index) => (
          <RoundButton
            key={step}
            isActive={currentStep === step}
            onClick={e => {
              e.preventDefault();
            }}
            number={index + 1}
            text={step}
            size="sm"
          />
        ))}
      </div>
    );
  };

  if (!firm) return null;

  return (
    <Modal className="create-account-modal common-modal" centered show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        {!isFirstStep ? (
          <MdKeyboardBackspace onClick={handlePreviousStep} className="external-deposit-back-button" size="2.5em" />
        ) : null}
        <Modal.Title>{i18next.t('createAccountModal.create')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="create-account-modal-content" ref={containerRef}>
        {renderStepper()}
        {renderForm()}
        {isLoading ? (
          <div className="loading-wrapper">
            <LoadingIndicator />
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default withProvider(CreateAccountContextProvider)(CreateAccountModal);
