import { Row } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import React, { PureComponent } from 'react';
import LoadingWrapper from '../../LoadingWrapper/LoadingWrapper';
import i18next from 'i18next';
import { WALLET_SUPPORTING_ASSET } from '../../../enums/validation';
import VLButton from '../../Buttons/VLButton';
import VLInput from '../../VLInput';
import VLSelect from '../../VLSelect';

/**
 * Utility filter method callback that filters out deleted wallets.
 *
 * @param wallet {any} The wallet to check.
 * @return {boolean} Returns true if the wallet is not deleted.
 */
const nonDeleted = wallet => !wallet.deleted;

/**
 * Checks if wallet supports the given crypto asset in case where the crypto asset
 * is a derivative of the wallet's crypto asset. E.g. USDC is a derivative of ETH
 * and uses the same blockchain.
 *
 * @param wallet {any} The wallet to check.
 * @param cryptoAsset {string} The crypto asset to check.
 * @return {boolean} Returns true if the wallet supports the given crypto asset.
 */
const walletSupports = (wallet, cryptoAsset) => {
  if (!wallet['Crypto-asset']) return false;
  const asset = cryptoAsset.toUpperCase();
  const walletAsset = wallet['Crypto-asset'].toUpperCase();

  const isSupporting = WALLET_SUPPORTING_ASSET[walletAsset]?.indexOf(asset);
  // can be undefined
  if (isSupporting === undefined) return false;

  return isSupporting !== -1;
};

/**
 * Map method callback used to convert a wallet to a react-select option.
 *
 * @param wallet {any} The wallet to convert.
 * @return {{label:string, value:string}} react-select option object.
 */
const toWalletSelectOption = wallet => {
  // Exchanges don't have permanent addresses
  const label = wallet.Address ? `${wallet.Name} (${wallet.Address})` : wallet.Name;

  return {
    label,
    value: wallet
  };
};

/**
 * Factory method that returns a filter method callback to filter wallets by crypto asset.
 *
 * @param cryptoAsset {string} The crypto asset to check.
 * @return {function(*)} Returns a function that is to be used in the wallet/exchange filter.
 */
const supportsCryptoAsset = cryptoAsset => wallet =>
  !wallet['Crypto-asset'] ||
  wallet['Crypto-asset'].toLowerCase() === cryptoAsset.toLowerCase() ||
  walletSupports(wallet, cryptoAsset);

class WithdrawAddressStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      address: ''
    };
    this.walletRef = React.createRef();
  }

  redirectToWallets = () => {
    this.props.history.push('/wallets');
  };

  handleSelectWallet = e => {
    this.setState({ selectedOption: e.value, address: e.value.Address || '' });
    this.props.setAddress(e.value.Address || '');
  };

  handleAddressInputChange = e => {
    this.setState({ address: e.target.value });
    this.props.setAddress(e.target.value);
  };

  optionsAddresses = cryptoAsset => {
    return (this.props.account?.attr?.wallets ?? [])
      .concat(this.props.account?.attr?.exchangeWallets ?? [])
      .filter(nonDeleted)
      .filter(supportsCryptoAsset(cryptoAsset))
      .map(toWalletSelectOption);
  };

  shouldShowAddressInput = () => {
    // Exchanges don't have permanent addresses, so user needs to provide one
    return this.state.selectedOption?.['Wallet-type'] === 'Exchange';
  };

  render() {
    return (
      <LoadingWrapper isLoading={this.props.queryWalletInProgress} waitDurationSeconds={60 * 2}>
        <p className="external-withdraw-input-label">Select wallet</p>
        <Row className="withdraw-content-row">
          <VLSelect
            options={this.optionsAddresses(this.props.asset)}
            placeholder={i18next.t('exchangeDetailsForm.select')}
            isSearchable={true}
            value={
              this.state.selectedOption
                ? {
                    label: this.state.selectedOption.Name,
                    value: this.state.selectedOption.Name
                  }
                : null
            }
            name="address"
            onChange={e => this.handleSelectWallet(e)}
          />
          {this.shouldShowAddressInput() ? (
            <div>
              <p className="external-withdraw-input-label">{`Enter ${this.props.asset} address`}</p>
              <VLInput
                name="customAddress"
                value={this.state.privateNote}
                onChange={this.handleAddressInputChange}
                noBorder
                hasBackground
                size="l"
              />
            </div>
          ) : null}

          <div className="external-deposit-small-text">
            <div>
              <MdInfo size={'2em'} />
            </div>
            <div>
              <span>
                You can add a&nbsp;
                <span
                  onClick={() => this.redirectToWallets()}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  new wallet
                </span>
                &nbsp;if you don`t see the address you`d like to withdraw to.
              </span>
            </div>
          </div>
        </Row>
        <Row className="external-deposit-footer-bar">
          <VLButton
            width="100%"
            disabled={this.props.address === ''}
            onClick={this.props.onCompleted}
            text={'Continue'}
          />
        </Row>
      </LoadingWrapper>
    );
  }
}

export default WithdrawAddressStep;
