import './OrderBook.scss';
import OrderBook from './OrderBook';

import { connect } from 'react-redux';

const mapStateToProps = state => ({
  refreshOrderHistory: state.websocket.timestamp
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBook);
