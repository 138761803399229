import { Modal } from 'react-bootstrap';
import React, { PureComponent } from 'react';
import './ConfirmationModal.scss';
import i18next from 'i18next';
import VLButton from '../../Buttons/VLButton';
class ConfirmationModal extends PureComponent {
  getTitle = () => {
    switch (this.props.assetType) {
      case 'firm':
        return i18next.t('confrimationModal.firm');
      case 'account':
        return i18next.t('confrimationModal.account');
      case 'user':
        return i18next.t('confrimationModal.user');
      default:
        return i18next.t('confrimationModal.firm');
    }
  };

  render() {
    return (
      <Modal className="confirmation-modal common-modal" centered show onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {i18next.t('confrimationModal.delete') +
              `  ${this.getTitle()} ` +
              i18next.t('confrimationModal.confrimation')}
            ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmation-modal-body">
          <p className="confirmation-modal-text">
            {i18next.t('confrimationModal.question') + ` ${this.getTitle().toLowerCase()}?`}
          </p>
        </Modal.Body>
        <Modal.Footer className="transaction-confirmation-modal-footer">
          <div className="sfcb-button-wrapper">
            <VLButton text={i18next.t('cancelTransactionModal.CONFIRM')} width="100%" onClick={this.props.onSuccess} />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
