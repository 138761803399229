import './TransactionsAdmin.scss';

import {
  clearAllFirmInfo,
  createAccountRequest,
  createAdminAccountRequest,
  createFirmRequest,
  deleteAccountRequest,
  deleteAdminAccountRequest,
  deleteFirmRequest,
  getFirmAdminAccountsRequest,
  getFirmDetailsRequest,
  getFirmUsersRequest,
  getListOfFirmsRequest
} from '../../redux/actions/administration';
import {
  fetchExternalPendingCryptoTrasactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchStakingRequest,
  updateStatusExternalCryptoTrasactions,
  fetchExternalUSDTransactions,
  updateStatusExternalUSDTransactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalUSDReviewedTransactions,
  fetchStakingRewardsRequest
} from '../../redux/actions/transactionsExternal';
import { fetchAllAnalyticsData } from '../../redux/actions/analytics';
import { toggleModal, resetTransactionHash } from '../../redux/actions/transactions';
import {
  getAccountTransactions,
  clearAccountEvents,
  fetchCanceledOrders,
  clearCanceledOrders,
  fetchOpenOrders,
  clearOpenOrders
} from '../../redux/actions/transactions';
import TransactionsAdmin from './TransactionsAdmin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchAssetPairs } from '../../redux/actions/trade';

const mapStateToProps = state => ({
  assetPairs: state.trade.assetPairs,
  events: state.transactions.events,
  listOfFirms: state.administration.listOfFirms,
  firmDetails: state.administration.firmDetails,
  firmAdminAccounts: state.administration.firmAdminAccounts,
  firmUsers: state.administration.firmUsers,
  transactions: state.analytics.pieTransactions,
  displayModal: state.transactions.displayModal,
  canceledOrders: state.transactions.canceledOrders,
  openOrders: state.transactions.openOrders,

  user: state.login.userData.data,
  profileDetails: state.login.profileDetails,
  transactionExternalPending: state.externalTransactions.externalTransactionsPending,
  transactionExternalReviewed: state.externalTransactions.externalTransactionsReviewed,
  //externalTransactionUpdated:
  //  state.externalTransactions.externalTransactionUpdated.data,
  transactionDetails: state.externalTransactions.externalTransactionDetails,
  stakingTransactions: state.externalTransactions.staking,
  rewardTransactions: state.externalTransactions.stakingRewards,
  transactionsUSD: state.externalTransactions.externalTransactionsUsd,
  transactionsPendingUSD: state.externalTransactions.externalTransactionsUsdPending,
  transactionsReviewedUSD: state.externalTransactions.externalTransactionsUsdReviewed,
  externalTransactionUsdUpdate: state.externalTransactions.externalTransactionUsdUpdate
});

const mapDispatchToProps = {
  getListOfFirmsRequest,
  createFirmRequest,
  getFirmDetailsRequest,
  getFirmAdminAccountsRequest,
  getFirmUsersRequest,
  clearAllFirmInfo,
  createAccountRequest,
  createAdminAccountRequest,
  deleteAdminAccountRequest,
  deleteAccountRequest,
  deleteFirmRequest,
  fetchAllAnalyticsData,
  toggleModal,
  resetTransactionHash,
  fetchExternalPendingCryptoTrasactions,
  fetchExternalReviewedCryptoTrasactions,
  fetchStakingRequest,
  fetchStakingRewardsRequest,
  updateStatusExternalCryptoTrasactions,
  fetchExternalUSDTransactions,
  updateStatusExternalUSDTransactions,
  fetchExternalUSDPendingTransactions,
  fetchExternalUSDReviewedTransactions,
  getAccountTransactions,
  fetchAssetPairs,
  clearAccountEvents,
  fetchCanceledOrders,
  clearCanceledOrders,
  fetchOpenOrders,
  clearOpenOrders
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(TransactionsAdmin);
