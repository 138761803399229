import { Col, Row, Table } from 'react-bootstrap';
import React, { PureComponent } from 'react';

import AreasplineChart from '../../components/Charts/AreasplineChart/AreasplineChart';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';
import PageTitle from '../../components/PageTitle';
import PieChart from '../../components/Charts/PieChart';
import i18next from 'i18next';

const COLORS = {
  btc: '#f2a900',
  eth: '#3c3c3d',
  vl$: '#1d7cd2',
  usd: '#85bb65'
};

class Analytics extends PureComponent {
  componentDidMount() {
    this.props.fetchAllAnalyticsData();
  }

  buildPieAssetDataSet = () => {
    let data = this.props.pieAssets.data;
    const totalNumberOfTransactions = data.length;
    const numberOfBTC = data.filter(transaction => transaction.security.includes('BTC')).length;
    const numberOfETH = data.filter(transaction => transaction.security.includes('ETH')).length;
    const numberOfUSD = data.filter(transaction => transaction.security.includes('USD')).length;
    const numberOfVL$ = data.filter(transaction => transaction.security.includes('VL$')).length;

    const resultPercentage = {
      btc: (numberOfBTC / totalNumberOfTransactions) * 100,
      eth: (numberOfETH / totalNumberOfTransactions) * 100,
      usd: (numberOfUSD / totalNumberOfTransactions) * 100,
      vl$: (numberOfVL$ / totalNumberOfTransactions) * 100
    };
    return resultPercentage;
  };

  buildPieTransactionDataSet = () => {
    let data = this.props.pieTransactions.data;
    const totalNumberOfTransactions = data.length;
    const numberOfBTC = data.filter(transaction => transaction.security.includes('BTC')).length;
    const numberOfETH = data.filter(transaction => transaction.security.includes('ETH')).length;
    const numberOfUSD = data.filter(transaction => transaction.security.includes('USD')).length;
    const numberOfVL$ = data.filter(transaction => transaction.security.includes('VL$')).length;

    const resultPercentage = {
      btc: numberOfBTC,
      eth: numberOfETH,
      usd: numberOfUSD,
      vl$: numberOfVL$,
      total: totalNumberOfTransactions
    };
    return resultPercentage;
  };

  buildLineSeriesObject = (arrayOfData, security) => {
    let seriesObject = {
      name: security,
      data: []
    };

    seriesObject.data = arrayOfData.map(transaction => {
      let date = new Date(transaction.time);
      return [parseInt(date.getTime()), parseFloat(transaction.amount)];
    });

    return seriesObject;
  };

  buildLineTransactionDataSet = () => {
    let data = this.props.pieAssets.data;
    let securities = Object.keys(COLORS);
    let arrayOfArrays = [];
    arrayOfArrays.push(data.filter(transaction => transaction.security.includes('BTC')));
    arrayOfArrays.push(data.filter(transaction => transaction.security.includes('ETH')));
    arrayOfArrays.push(data.filter(transaction => transaction.security.includes('USD')));
    arrayOfArrays.push(data.filter(transaction => transaction.security.includes('VL$')));
    let resultArray = securities.map((security, index) => {
      return this.buildLineSeriesObject(arrayOfArrays[index], security.toUpperCase());
    });

    return resultArray;
  };

  getMockTable = () => {
    return (
      <Table className="analytics-table reports-table" responsive>
        <thead>
          <tr>
            <th className="reports-table-heading">{i18next.t('analytics.firmName')}</th>
            <th className="reports-table-heading">{i18next.t('analytics.totalAmount')}</th>
            <th className="reports-table-heading">{i18next.t('analytics.amountPerAsset')}</th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill(0)
            .map((item, i) => {
              const num = ++i;
              return (
                <tr key={num} className="transactions-table-tr">
                  <td className="center-aligned">
                    <div className="reports-table-text grey-td">Test Firm {num}</div>
                  </td>
                  <td>
                    <div className="reports-table-text grey-td">2{num},609,024</div>
                  </td>
                  <td>
                    <div className="reports-table-text grey-td">
                      2{num} BTC, 3{num} ETH
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  render() {
    const pieAssetData = this.buildPieAssetDataSet();
    const pieTransactionData = this.buildPieTransactionDataSet();
    const lineData = this.buildLineTransactionDataSet();
    const pieSeriesData1 = {
      name: 'Assets',
      colorByPoint: true,
      data: [
        {
          name: 'BTC',
          y: pieAssetData.btc,
          color: COLORS.btc
        },
        {
          name: 'ETH',
          y: pieAssetData.eth,
          color: COLORS.eth
        },
        {
          name: 'VL$',
          y: pieAssetData.vl$,
          color: COLORS.vl$
        },
        {
          name: 'USD',
          y: pieAssetData.usd,
          color: COLORS.usd
        }
      ]
    };
    const pieSeriesData2 = {
      name: 'Assets',
      colorByPoint: true,
      data: [
        {
          name: 'BTC',
          y: pieTransactionData.btc,
          color: COLORS.btc
        },
        {
          name: 'ETH',
          y: pieTransactionData.eth,
          color: COLORS.eth
        },
        {
          name: 'VL$',
          y: pieTransactionData.vl$,
          color: COLORS.vl$
        },
        {
          name: 'USD',
          y: pieTransactionData.usd,
          color: COLORS.usd
        }
      ]
    };
    return (
      <div className="analytics">
        <Row className="analytics-row analytics-row--line">
          <Col className="analytics-col user-table">
            <PageTitle type="light" styleClass="analytics-col-title" text={i18next.t('analytics.assetType')} />
            <AreasplineChart mockMultipleLine containerProps={{ className: 'areaspline-chart ch' }} data={lineData} />
          </Col>
          <Col className="analytics-col user-table">
            <PageTitle type="light" styleClass="analytics-col-title" text={i18next.t('analytics.transactionType')} />
            <AreasplineChart containerProps={{ className: 'areaspline-chart ch' }} data={lineData} />
          </Col>
        </Row>
        <Row className="analytics-row user-table">
          <PageTitle type="light" text={i18next.t('analytics.top10B')} />
          {/* <h4 className="analytics-table-title">Top 10 Accounts holding Crypto:</h4> */}
          {this.getMockTable()}
        </Row>
        <Row className="analytics-row analytics-row--row">
          <Col className="analytics-col user-table">
            <PageTitle aligned="center" type="light" text={i18next.t('analytics.assetsByType')} />
            <LoadingWrapper isLoading={this.props.pieAssets.loading}>
              <PieChart
                allowChartUpdate={true}
                containerProps={{ className: 'pie-chart' }}
                series={pieSeriesData1}
                // title="Assets by Type"
              />
            </LoadingWrapper>
          </Col>
          <Col className="analytics-col user-table">
            <PageTitle aligned="center" type="light" text={i18next.t('analytics.trans')} />
            <h3 className="analytics-col-subtitle">
              {i18next.t('analytics.totalNo') + ` (${pieTransactionData.total})`}
            </h3>
            <LoadingWrapper isLoading={this.props.pieTransactions.loading}>
              <PieChart
                allowChartUpdate={true}
                containerProps={{ className: 'pie-chart' }}
                series={pieSeriesData2}
                // title="Transactions"
              />
            </LoadingWrapper>
          </Col>
        </Row>
        <Row className="analytics-row user-table">
          <PageTitle type="light" text={i18next.t('analytics.top10A')} />
          {/* <h4 className="analytics-table-title">Top 10 Transactions by size in the last month:</h4> */}
          {this.getMockTable()}
        </Row>
      </div>
    );
  }
}

export default Analytics;
