import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { MdModeEdit } from 'react-icons/md';
import {
  RISK_CONTROL_SETTINGS_DESCRIPTION_MAPPER,
  RISK_CONTROL_SETTINGS_FIELDS,
  RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER
} from '../../enums/validation';
import './RiskControlSettings.scss';
import RiskControlSettingsModal from './RiskControlSettingsModal';
import { isWebsocketReady } from '../../utils/websocketHelper';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';
import VLButton from '../Buttons/VLButton';
import Card from '../../containers/Card';

const renderHeadings = () => {
  return (
    <>
      <tr>
        <th> Risk Control Setting</th>
        <th> Description</th>
        <th> Value </th>
        <th> Edit </th>
      </tr>
    </>
  );
};

const RiskControlSettings = ({ queryRiskSettings, websocket, accountDetails, riskControlSettings }) => {
  const [riskSettings, setRiskSettings] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRiskControlSetting, setSelectedRiskControlSetting] = useState({
    settingName: '',
    settingValue: ''
  });
  const { account, firm } = accountDetails;
  const history = useHistory();

  const queryRiskSettingsFunction = () => {
    if (!isWebsocketReady(websocket)) {
      return;
    }
    queryRiskSettings({
      account,
      firm,
      socket: websocket,
      successCallback: data => {
        setRiskSettings(data.data);
      },
      errorCallback: error => {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    queryRiskSettingsFunction();
  }, [account]);

  const updateRiskSettingsTable = () => {
    queryRiskSettingsFunction();
  };

  const redirectToGetHelp = () => {
    history.push(`/help`);
  };

  const toggleModal = (settingName, settingValue) => {
    setSelectedRiskControlSetting({
      settingName,
      settingValue
    });
    setIsModalOpen(!isModalOpen);
  };

  const renderBody = riskSettings => {
    if (riskControlSettings.loading) {
      return (
        <div className="chart-loading-indicator">
          <LoadingIndicator />
        </div>
      );
    }

    return RISK_CONTROL_SETTINGS_FIELDS.map(fieldName => {
      const riskSettingsFieldValue = riskSettings[RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER[fieldName]];
      const isValueZero = riskSettingsFieldValue === '0' || riskSettingsFieldValue === '0.0';
      return (
        <tr>
          <td>
            <div>{fieldName}</div>
          </td>
          <td>
            <div className="align-left">
              {RISK_CONTROL_SETTINGS_DESCRIPTION_MAPPER[fieldName]}
              <a href="#" className="get-help-link-risk-settings" onClick={redirectToGetHelp}>
                {' '}
                Learn more.
              </a>
            </div>
          </td>
          <td>
            <div>{riskSettingsFieldValue && !isValueZero ? riskSettingsFieldValue : '-'}</div>
          </td>
          <td>
            <div>
              <VLButton
                size="m"
                onClick={() => {
                  toggleModal(fieldName, riskSettings[RISK_CONTROL_SETTINGS_RESPONSE_FIELD_NAME_MAPPER[fieldName]]);
                }}
                text="Edit"
                leftIcon={<MdModeEdit />}
              />{' '}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card>
      <Container fluid className="risk-control-container">
        <div>
          <Row>
            <Table borderless responsive className="risk-control-settings-table vl-table" size="sm">
              <thead>{renderHeadings()}</thead>
              <tbody>{renderBody(riskSettings)}</tbody>
            </Table>
          </Row>
        </div>
        {isModalOpen && (
          <RiskControlSettingsModal
            toggleModal={toggleModal}
            selectedRiskSetting={selectedRiskControlSetting}
            updateRiskSettingsTable={updateRiskSettingsTable}
          />
        )}
      </Container>
    </Card>
  );
};

export default RiskControlSettings;
