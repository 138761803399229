import React from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const PieChart = props => {
  const getMockPieOptions = () => {
    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        styledMode: true
      },
      title: {
        text: props.title || ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },

      series: [{ ...props.series }],
      credits: {
        enabled: false
      }
    };

    return options;
  };

  return <HighchartsReact options={getMockPieOptions()} highcharts={Highcharts} {...props} />;
};

export default PieChart;
