import * as ACTIONS from '../../actions/transactions';
import * as CONSTANTS from '../../constants/transactions';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { default as URLS } from '../../constants/urls';
import { toast } from 'react-toastify';
import RequestBuilder from '../../../request/RequestBuilder';

function* getAssets(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAssetsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getAssetsError(err));
  }
}

function* getAssetsByProduct(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAssetsByProductSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getAssetsByProductError(err));
  }
}

function* getOrderHistory(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getOrderHistorySuccess(response));
  } catch (err) {
    yield put(ACTIONS.getOrderHistoryError(err));
  }
}

function* fetchCanceledOrders(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchCanceledOrdersSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchCanceledOrdersError(err));
  }
}

function* fetchOpenOrders(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.fetchOpenOrdersSuccess(response));
  } catch (err) {
    yield put(ACTIONS.fetchOpenOrdersError(err));
  }
}

function* getTradeHistory(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getTradeHistorySuccess(response));
  } catch (error) {
    yield put(ACTIONS.getTradeHistoryError(error));
  }
}

function* getAssetsPairs(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAssetPairsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getAssetPairsError(err));
  }
}

function* getTradingAssets(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getTradingAssetsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getTradingAssetsError(err));
  }
}

function* getAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getDepositAccountsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getDepositAccountsError(err));
  }
}

function* sendDepositOrRedeem(action) {
  const isDeposit = action.type === CONSTANTS.SEND_DEPOSIT;
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(isDeposit ? ACTIONS.sendDepositSuccess(response) : ACTIONS.sendRedeemSuccess(response));

    action.data.successCallback(response);
  } catch (err) {
    yield put(isDeposit ? ACTIONS.sendDepositError(err) : ACTIONS.sendRedeemError(err));
    action.data.errorCallback(err);
  }
}

function* getAccountTransactions(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getAccountEventsSuccess(response, action.forMiniList));
  } catch (err) {
    yield put(ACTIONS.getAccountEventsError(err));
  }
}

function* getAccountEventsFromAll(action) {
  try {
    const accounts = action.data;

    const object = accounts.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: RequestBuilder.default()
          .get(URLS[action.type])
          .urlParams(cur.data)
          .build()
          .map(res => res.data)
          .toEffect()
      }),
      {}
    );

    const responses = yield all({ ...object });

    const accountKeys = Object.keys(responses);
    const response = accountKeys.map(key => responses[key].data.slice()).flat();

    yield put(ACTIONS.getAccountEventsAllSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getAccountEventsAllError(err));
  }
}

function* getFirms(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.getRecipientFirmsSuccess(response));
  } catch (err) {
    yield put(ACTIONS.getRecipientFirmsError(err));
  }
}

function* searchFirmAccounts(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    // TODO: refactor
    action.data.success = response;

    yield put(ACTIONS.searchRecipientAccountsSuccess(response));
    if (response.message === 'success') {
      action.data.successCallback(response);
    }
  } catch (err) {
    yield put(ACTIONS.searchRecipientAccountsError(err));
  }
}

function* updateTransactionDB(action) {
  try {
    const response = yield RequestBuilder.default()
      .post(URLS[action.type])
      .data(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(ACTIONS.updateTransactionsDBSuccess(response));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.updatePricesError(err));
  }
}

function* getGraphPrices(action) {
  try {
    const url = `${URLS[action.type]}/${action.data.security}`;

    const response = yield RequestBuilder.default()
      .get(url)
      .urlParams(action.data.query)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(
      ACTIONS.getPricesSuccess({
        ...response,
        crypto: action.data.crypto
      })
    );
  } catch (err) {
    yield put(
      ACTIONS.getPricesError({
        ...err,
        crypto: action.data.crypto
      })
    );
  }
}

function* updatePrices(action) {
  try {
    const url = `${URLS[action.type]}/${action.data.security}`;

    const response = yield RequestBuilder.default()
      .get(url)
      .data(action.data.query)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(
      ACTIONS.getPricesSuccess({
        ...response,
        crypto: action?.data?.crypto
      })
    );
    if (action.data?.callback) {
      action.data.callback(response.prices);
    }
  } catch (err) {
    yield put(
      ACTIONS.getPricesError({
        ...err,
        crypto: action.data.crypto
      })
    );
  }
}

function* getAllPrices(action) {
  try {
    const response = yield RequestBuilder.default()
      .get(URLS[action.type])
      .urlParams(action.data)
      .build()
      .map(res => res.data)
      .toEffect();

    yield put(
      ACTIONS.getAllPricesSuccess({
        ...response
      })
    );
    if (action.data?.callback) {
      action.data.callback(response.prices);
    }
  } catch (err) {
    yield put(
      ACTIONS.getAllPricesError({
        err
      })
    );
  }
}

export default function* transactionsSaga() {
  yield takeLatest(CONSTANTS.FETCH_ASSETS, getAssets);
  yield takeLatest(CONSTANTS.FETCH_ASSETS_BY_PRODUCT, getAssetsByProduct);

  yield takeLatest(CONSTANTS.FETCH_ASSET_PAIRS, getAssetsPairs);
  yield takeLatest(CONSTANTS.FETCH_TRADING_ASSETS, getTradingAssets);
  yield takeLatest(CONSTANTS.FETCH_DEPOSIT_ACCOUNTS, getAccounts);
  yield takeLatest(CONSTANTS.SEND_DEPOSIT, sendDepositOrRedeem);
  yield takeLatest(CONSTANTS.SEND_REDEEM, sendDepositOrRedeem);
  yield takeLatest(CONSTANTS.FETCH_ACCOUNT_EVENTS, getAccountTransactions);
  yield takeLatest(CONSTANTS.FETCH_ACCOUNT_EVENTS_ALL, getAccountEventsFromAll);
  yield takeLatest(CONSTANTS.FETCH_ORDER_HISTORY, getOrderHistory);
  yield takeLatest(CONSTANTS.FETCH_TRADE_HISTORY, getTradeHistory);
  yield takeLatest(CONSTANTS.FETCH_FIRMS, getFirms);
  yield takeLatest(CONSTANTS.SEARCH_ACCOUNTS_OF_FIRM, searchFirmAccounts);
  yield takeLatest(CONSTANTS.UPDATE_TRANSACTION_DB, updateTransactionDB);
  yield takeEvery(CONSTANTS.GET_PRICES, getGraphPrices);
  yield takeEvery(CONSTANTS.UPDATE_PRICES, updatePrices);
  yield takeEvery(CONSTANTS.FETCH_CANCELED_ORDERS, fetchCanceledOrders);
  yield takeEvery(CONSTANTS.FETCH_OPEN_ORDERS, fetchOpenOrders);

  yield takeEvery(CONSTANTS.GET_ALL_PRICES, getAllPrices);
}
