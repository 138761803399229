import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';

/**
 * Popover displaying provided information
 *
 * @param {String} placement - Value representing position of the text when popover appears. Values available are 'right', 'left', 'top', 'bottom'. Default value is 'right'.
 * @param {String} text - Text to display in popover.
 */
const InformationPopover = ({ placement = 'right', text }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{text}</Tooltip>}>
      <MdInfo size="1.7rem" color="var(--column-header)" />
    </OverlayTrigger>
  );
};

export default InformationPopover;
