import '../MarketsTable/MarketsTable.scss';

const ASCENDING = 'ASCENDING';
const DESCENDING = 'DESCENDING';

const TableSortButtons = ({ sortingOrder, updateColumnSortStatus, tableType }) => {
  const updateStatus = order => {
    updateColumnSortStatus(order, tableType);
  };

  return (
    <div className="sorting-icons-div">
      <i
        className={`sort-arrow-asc ${sortingOrder === ASCENDING ? 'sort-arrow-asc-selected' : ''}`}
        onClick={() => {
          updateStatus(ASCENDING);
        }}
      ></i>
      <i
        className={`sort-arrow-desc ${sortingOrder === DESCENDING ? 'sort-arrow-desc-selected' : ''}`}
        onClick={() => {
          updateStatus(DESCENDING);
        }}
      ></i>
    </div>
  );
};

export default TableSortButtons;
