export const SIDEBAR_SECTIONS = {
  DASHBOARD: 'DASHBOARD',
  FIRMS: 'FIRMS',
  PAYMENTS: 'PAYMENTS',
  MARKETPLACE: 'MARKETPLACE',
  MESSAGES: 'messages',
  WALLETS: 'WALLETS',
  PROFILE: 'PROFILE',
  SETTINGS: 'SETTINGS',
  HELP: 'HELP',
  USERS: 'USERS',
  FIRM_ACCOUNTS: 'FIRM_ACCOUNTS',
  //SUPERADMIN SPECIFIC
  ALL_ACCOUNTS: 'ALL_ACCOUNTS',
  ALL_USERS: 'ALL_USERS',
  ONBOARDING: 'ONBOARDING',
  TRANSACTIONS_BTA: 'TRANSACTIONS_BTA',
  REPORTS: 'REPORTS',

  CONTACTS: 'CONTACTS',
  TRADE: 'TRADE',
  MARKETS: 'MARKETS'
};

export const INSIDE_APP_SECTIONS = {
  FIRM_DETAILS: 'FIRM_DETAILS',
  DEPOSIT_ADMIN: 'DEPOSIT_ADMIN',
  WITHDRAW_ADMIN: 'WITHDRAW_ADMIN',
  CRYPTO_ASSET_DETAILS: 'CRYPTO_ASSET_DETAILS',
  TRANSACTION_DETAILS_ADMIN: 'TRANSACTION_DETAILS_ADMIN',
  USER_DETAILS: 'USER_DETAILS',
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS'
};

export const APP_ROUTES_MAP = Object.freeze({
  [SIDEBAR_SECTIONS.FIRMS]: '/firms',
  [SIDEBAR_SECTIONS.ALL_ACCOUNTS]: '/accounts',
  [SIDEBAR_SECTIONS.ALL_USERS]: '/users',
  [SIDEBAR_SECTIONS.DASHBOARD]: '/',
  [SIDEBAR_SECTIONS.FIRM_ACCOUNTS]: '/settings/accounts',
  [SIDEBAR_SECTIONS.PAYMENTS]: '/transactions',
  [SIDEBAR_SECTIONS.MARKETPLACE]: '/marketplace',
  [SIDEBAR_SECTIONS.MESSAGES]: '/messages',
  [SIDEBAR_SECTIONS.WALLETS]: '/wallets',
  [SIDEBAR_SECTIONS.PROFILE]: '/profile',
  [SIDEBAR_SECTIONS.TRANSACTIONS_BTA]: '/BTA/transactions',
  [SIDEBAR_SECTIONS.HELP]: '/help',
  [SIDEBAR_SECTIONS.CONTACTS]: '/contacts',
  [SIDEBAR_SECTIONS.REPORTS]: '/reports',
  [SIDEBAR_SECTIONS.USERS]: '/users',
  [SIDEBAR_SECTIONS.FIRM_ACCOUNTS]: '/accounts',
  [SIDEBAR_SECTIONS.ONBOARDING]: '/',
  [SIDEBAR_SECTIONS.TRADE]: '/trade',
  [SIDEBAR_SECTIONS.MARKETS]: '/markets'
});

export const TRANSACTION_SCREENS = Object.freeze({
  DETAILS: 'details',
  CONFIRM: 'confirm',
  SUCCESS: 'success',
  CANCEL_SUCCESS: 'cancel-success'
});

export const EXTERNAL_TRANSACTION_SCREENS = Object.freeze({
  STEP_1: 'step1',
  STEP_2: 'step2',
  STEP_3: 'step3',
  STEP_4: 'step4',
  STEP_5: 'step5'
});

export const EXCHANGE_SCREENS = Object.freeze({
  INPUT_FORM: 'input-form',
  DETAILS: 'details',
  PREVIEW: 'preview',
  SUCCESS: 'success',
  CANCEL_SUCCESS: 'cancel-success'
});
